import { Component, Inject, OnInit } from '@angular/core';
import { OtherComponentService } from '../other-component.service';
import { MAT_DIALOG_DATA,MatDialogRef,MatDialog } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-choosemeal-option',
  templateUrl: './choosemeal-option.component.html',
  styleUrls: ['./choosemeal-option.component.scss']
})
export class ChoosemealOptionComponent implements OnInit {
 
  selecteditemCategory = null;
  mealsAvailable: any[] = [];
  constructor(
    private _otherComponentService : OtherComponentService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<string>,
    public dialog: MatDialog
  ) { }
   showloader : boolean = false;
  ngOnInit(): void {
   
    this.getUserAvailableMeals()
  }
  getUserAvailableMeals(){
    this.showloader = true;
    let payload = {
      strDate : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      planId : this.data.userPlanId
    }
    this._otherComponentService.getUserAvailableMeals(payload).subscribe((res : any)=>{
      
      this.mealsAvailable = res.data.filter((meal)=> {
        return meal.status;
      })
      this.showloader = false;
    }, (err)=> {

    })
  }
  showQRCode(){
  
    
    this.dialogRef.close({ selectedMealCategory: this.selecteditemCategory });
   
  }
}
