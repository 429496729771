import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { response } from 'express';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../../user-account/user-account.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  staffdetails:any=[];
  staffname:any;
  staffphoneno:any;
  staffemail:any;
  siteid:any;
  orderId:any;
  constructor(
    private landingComponentService: LandingComponentService, 
    private userAccountService: UserAccountService,
    @Inject(MAT_DIALOG_DATA) private data) { }
  fcid:any;
  ngOnInit(): void {
    this.fcid=this.data.fcid;
    this.siteid=this.data.siteid;
    this.stafflistdata();
  }

  stafflistdata(){
    this.landingComponentService.getSPOClist(this.fcid,this.siteid).subscribe(
      (response:any)=>{
        this.staffdetails=response.data;
        
      }
    )
  }
}
