<div class="order-summary-main-container">
  <div class="order-summary-header">
    <div class="back-btn">
      <img src="../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToPreviousPage()" />
    </div>
    <div class="heading-text" *ngIf="pathParam === 'corporate-order' || pathParam === 'guest-request'">Request Summary
    </div>
    <div class="heading-text" *ngIf="pathParam !== 'corporate-order' && pathParam !== 'guest-request'">Order Summary
    </div>
    <div class="helpinfo" (click)="gotohelpinfo(orderDetails.foodCourtId,orderDetails.siteId)">Help</div>
    <div class="refresh-icon">
      <img src="../../../../../../assets/images/common/refresh-page.svg" alt="Refresh Icon" (click)="refreshPage()" />
    </div>
  </div>
  <ng-container *ngIf="!showLoader; else loaderScreen">
    <ng-container *ngIf="!requestId; else showRequestData">
      <div class="order-summary-section">
        <div class="order-info">
          <div class="order-info-one">
            <div class="order-id">Order Id: {{ orderDetails?.orderNum }}</div>
            <div class="download-invoice-btn" (click)="downloadInvoice()">
              <button>Invoice Download</button>
            </div>
            <!-- <div class="order-type">Picked Up</div> -->
          </div>
          <div class="order-info-two">
            {{ orderDetails.createdTimestamp | date: "mediumDate":"UTC+0" }} at
            {{ orderDetails.createdTimestamp | date: "shortTime":"UTC+0" }}
          </div>
        </div>
        <div class="preorder-schedule-timing" *ngIf="orderDetails.preOrder">
          <div class="preorder-schedule__left-section">
            <div class="preorder-text">Pre-Order Scheduled On</div>
            <div class="schedule-timing">{{ orderDetails?.preOrderDate | date }} at {{ orderDetails?.preOrderStartTime |
              date:"shortTime" }} - {{ orderDetails?.preOrderEndTime | date:"shortTime" }}</div>
          </div>
          <div class="preorder-schedule__right-section">
            <img src="../../../../../../../assets/images/common/empty-menu.svg" alt="">
          </div>
        </div>
        <div class="user-orders-info-list">
          <div class="order-info-container">
            <div class="small-heading-text" *ngIf="pathParam === 'corporate-order' || pathParam === 'guest-request'">
              Your Request</div>
            <div class="small-heading-text" *ngIf="pathParam !== 'corporate-order' && pathParam !== 'guest-request'">
              Your Order</div>
            <div class="raise-ticket-btn" (click)="goToHelpDeskCategoryListingPage()">
              <button>Raise Ticket</button>
            </div>
          </div>
          <hr />
          <div class="delivery-status-section"
            *ngIf="orderDetails.orderType === 'DELIVERY' && orderDetails.orderdeliveryStatus !== 'CANCELLED'">
            <div class="status-heading">Delivery Status</div>
            <!-- <div class="status-value">
              {{ orderDetails.orderdeliveryStatus.split("_").join(" ") }}
            </div> -->
            <div class="status-text">
              <ng-container *ngIf="orderDetails.orderdeliveryStatus === 'NEW'">
                <span>Delivery Captain will be assigned shortly</span>
              </ng-container>
              <ng-container *ngIf="orderDetails.orderdeliveryStatus === 'ASSIGNED'">
                <span>Delivery Captain is on the way to pick up your order</span>
              </ng-container>
              <ng-container *ngIf="orderDetails.orderdeliveryStatus === 'ORDER_PICKED'">
                <span>Delivery Captain is out for Delivery</span>
              </ng-container>
              <ng-container *ngIf="orderDetails.orderdeliveryStatus === 'DELIVERED'">
                <span>Food is Delivered</span>
              </ng-container>
            </div>
          </div>
          <div class="order-list">
            <ng-container *ngFor="let order of orderDetails.vendorOrderDetails">
              <div>
                <div class="user-order-info">
                  <div class="left-section">
                    <div class="item-image-container">
                      <img [src]="
                          order.vendorImgUrl && order.vendorImgUrl !== 'string'
                            ? order.vendorImgUrl
                            : '../../../../../../assets/images/common/orders.svg'
                        " alt="" />
                    </div>
                    <div class="brand-name-status">
                      <div class="brand-name">
                        {{ order.vendorName | titlecase }}
                        <span *ngIf="orderDetails.preOrder" class="vendor-preorder-status"
                          [ngClass]="{'vendor-preorder-accepted': order.preOrderStatus === 'ACCEPTED',
                                      'vendor-preorder-rejected': order.preOrderStatus === 'REJECTED',
                                      'vendor-preorder-cancelled': order.preOrderStatus === 'CANCELLED',
                                      'vendor-preorder-pending': order.preOrderStatus === 'PENDING'}">({{order.preOrderStatus}})</span>
                      </div>
                      <div class="order-status"
                        [ngClass]="{'cancelled-order-status': order.orderStatus === 'CANCELLED'}">
                        {{
                        order.orderStatus === "PAST"
                        ? "DELIVERED"
                        : order.orderStatus
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="right-section" *ngIf="
                      order.orderStatus === 'READY' ||
                      order.orderStatus === 'PAST'
                    ">
                    <div class="kot-code">
                      Kot:
                      {{
                      orderDetails.orderType === "DELIVERY"
                      ? orderDetails.deliveryKot
                      : order.kot
                      }}
                    </div>
                    <div class="qr-code" (click)="displayQrCode(order)">
                      <span class="material-icons">qr_code</span>
                    </div>
                  </div>
                </div>
                <div class="user-order-item-list">
                  <ng-container *ngFor="let item of order.items">
                    <div class="user-order-item">
                      <div class="order-item-left">
                        <div class="item-quantity">{{ item.itemQuantity }}</div>
                        <div class="x-icon">X</div>
                        <!-- <div class="item-name">{{ item.itemName }}</div> -->

                        <div class="item-name">
                          <span *ngIf="
                              item.itemName.length < 25;
                              else showTrimmedNameQB
                            ">{{ item.itemName | titlecase }}
                            <span class="item-status" *ngIf="item.itemStatus === 'OUT_OF_STOCK'">Out of Stock</span>
                          </span>
                          <ng-template #showTrimmedNameQB>
                            <span class="trim-item-name">{{
                              item.itemName.substring(0, 24) | titlecase
                              }}..</span>
                            <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon>
                            <span class="item-status" *ngIf="item.itemStatus === 'OUT_OF_STOCK'">Out of Stock</span>
                          </ng-template>
                          <div class="customized-items-name"
                            *ngIf="item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0">
                            <ng-container
                              *ngFor="let customItems of item.orderedItemCustomizations; index as itemCount">
                              <span class="active-items-name" *ngIf="customItems.active">{{ customItems.name +
                                (item.orderedItemCustomizations.length - 1 > itemCount ? ', ' : '') | titlecase}}</span>
                              <span class="inactive-items-name" *ngIf="!customItems.active">{{ customItems.name +
                                (item.orderedItemCustomizations.length - 1 > itemCount ? ', ' : '') | titlecase}}</span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="item-price order-item-right">
                        <!-- &#8377; {{ ((item.totalPrice)/100) | number: "1.1-2" }} -->
                        &#8377; {{ ((item.markUpTotalPrice)) | number: "1.1-2" }}
                      </div>
                    </div>

                  </ng-container>
                  <hr />
                  <div class="user-order-price">
                    <div class="order-item-left">
                      <div class="item-name">
                        Items price from {{ order.vendorName | titlecase }}
                      </div>
                    </div>
                    <div class="item-price order-item-right">
                      <!-- &#8377; {{ order.totalPrice / 100 | number: "1.1-2" }} -->
                      &#8377; {{ order.markUpTotalPrice | number: "1.1-2" }}
                    </div>
                  </div>
                  <div class="user-order-price">
                    <div class="order-item-left">
                      <div class="item-name">
                        GST No. of {{ order.vendorName | titlecase }}
                      </div>
                    </div>
                    <div class="item-price order-item-right">
                      {{ order?.gstNo ? order?.gstNo : 'N/A' }}
                    </div>
                  </div>
                  <div class="user-order-price">
                    <div class="order-item-left">
                      <div class="item-name">
                        FSSAI No. of {{ order.vendorName | titlecase }}
                      </div>
                    </div>
                    <div class="item-price order-item-right">
                      {{ order?.fssaiNo ? order?.fssaiNo : 'N/A' }}
                    </div>
                  </div>
                  <div class="user-order-price">
                    <div class="order-item-left">
                      <div class="item-name">CGST Charges</div>
                    </div>
                    <div class="item-price order-item-right">
                      &#8377;
                      <!-- {{ (order.vendorTaxAmount * 0.5) / 100 | number: "1.1-2" }} -->
                      {{ (order.vendorTaxAmount * 0.5) | number: "1.1-2" }}
                    </div>
                  </div>
                  <div class="user-order-price">
                    <div class="order-item-left">
                      <div class="item-name">SGST Charges</div>
                    </div>
                    <div class="item-price order-item-right">
                      &#8377;
                      <!-- {{ (order.vendorTaxAmount * 0.5) / 100 | number: "1.1-2" }} -->
                      {{ (order.vendorTaxAmount * 0.5) | number: "1.1-2" }}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </ng-container>
          </div>
        </div>
        <div class="user-payment-info">
          <div class="total-amount-section">
            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Items price</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ orderDetails.breakup["Item Total"] / 100 | number: "1.1-2" }} -->
                {{ orderDetails.breakup["Item Total"] | number: "1.1-2" }}
              </div>
            </div>
            <div class="user-total-price" *ngIf="orderDetails.breakup['Item Discount']">
              <div class="order-item-left">
                <div class="item-name">Discount</div>
              </div>
              <div class="item-price order-item-right discounted-price">
                - &#8377;
                <!-- {{orderDetails.breakup["Item Discount"] / 100 | number: "1.1-2"}} -->
                {{orderDetails.breakup["Item Discount"] | number: "1.1-2"}}
              </div>
            </div>

            <div class="user-total-price" *ngIf="orderDetails.breakup['Packing Charges']">
              <div class="order-item-left">
                <div class="item-name">Packing Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ orderDetails.breakup["Packing Charges"] / 100 | number: "1.1-2"}} -->
                {{ orderDetails.breakup["Packing Charges"] | number: "1.1-2"}}
              </div>
            </div>
            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Taxes & Charges</div>
              </div>
              <div class="item-price order-item-right"
                *ngIf="orderDetails.breakup['Delivery Charges'] || orderDetails.breakup['Delivery Charges'] === 0; else showTaxAndChanges">
                &#8377;
                <!-- {{(orderDetails.breakup["Taxes & charge"] - (orderDetails.breakup["Delivery Charges"] * 0.18)) / 100 | number: "1.1-2"}} -->
                {{(orderDetails.breakup["Taxes & charge"] - (orderDetails.breakup["Delivery Charges"] * 0.18)) | number:
                "1.1-2"}}
              </div>
              <ng-template #showTaxAndChanges>
                <div class="item-price order-item-right">
                  &#8377;
                  <!-- {{ orderDetails.breakup["Taxes & charge"] / 100 | number: "1.1-2" }} -->
                  {{ orderDetails.breakup["Taxes & charge"] | number: "1.1-2" }}
                </div>
              </ng-template>
            </div>
            <div class="user-total-price"
              *ngIf="orderDetails.breakup['Delivery Charges'] || orderDetails.breakup['Delivery Charges'] === 0">
              <div class="order-item-left">
                <div class="item-name">Delivery Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ orderDetails.breakup["Delivery Charges"] / 100 | number: "1.1-2"}} -->
                {{ orderDetails.breakup["Delivery Charges"] | number: "1.1-2"}}
              </div>
            </div>
            <div class="user-total-price"
              *ngIf="orderDetails.breakup['Delivery Charges'] || orderDetails.breakup['Delivery Charges'] === 0">
              <div class="order-item-left">
                <div class="item-name">GST on Delivery Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ ((orderDetails.breakup["Delivery Charges"] * 0.18) / 100) | number: "1.1-2" }} -->
                {{ ((orderDetails.breakup["Delivery Charges"] * 0.18)) | number: "1.1-2" }}
              </div>
            </div>
            <div class="grand-total">
              <div class="total-text">Grand Total</div>
              <div class="total">
                &#8377;
                <!-- {{ orderDetails.totalPrice / 100 | number: "1.1-2" }} -->
                {{ orderDetails.totalPrice | number: "1.1-2" }}
              </div>
            </div>
            <div class="payment">
              <div class="payment-text">Payment</div>
              <div class="paid-amount">
                Paid:
                <!-- {{ orderDetails.paidAmount | number: "1.1-2" || 0 }}/-
                 -->

                &#8377;
                <!-- {{ orderDetails.totalPrice / 100 | number: "1.1-2" }} /- -->
                {{ orderDetails.totalPrice | number: "1.1-2" }} /-
              </div>
            </div>
            <hr />
          </div>
          <div class="points-section">
            <ng-container *ngIf="orderDetails.orderdeliveryStatus !== 'CANCELLED'">
              <div class="wallet-point" *ngIf="orderDetails.paymentDetails['wallet']">
                Wallet:
                {{
                (orderDetails.paymentDetails["wallet"] | number: "1.1-2") || "0.0"
                }}
              </div>
              <div class="isthara-point" *ngIf="orderDetails.paymentDetails['Isthara Points']">
                Isthara Points:
                {{
                (orderDetails.paymentDetails["Isthara Points"]
                | number: "1.1-2") || "0.0"
                }}
              </div>
              <div class="isthara-point" *ngIf="orderDetails.paymentDetails['upi']">
                UPI:
                {{
                (orderDetails.paymentDetails["upi"]
                | number: "1.1-2") || "0.0"
                }}
              </div>
            </ng-container>
            <ng-container *ngIf="orderDetails.orderdeliveryStatus === 'CANCELLED' || showPointsRefunded">
              <div class="wallet-point">
                Refunded Points:
                <!-- {{((
                  (orderDetails.paymentDetails["wallet"] ? orderDetails.paymentDetails["wallet"] : 0) +
                  (orderDetails.paymentDetails["Isthara Points"] ? orderDetails.paymentDetails["Isthara Points"] : 0) +
                  (orderDetails.paymentDetails["upi"] ? orderDetails.paymentDetails["upi"] : 0)) | number: "1.1-2") || "0.0"
                }} -->
                {{ orderDetails?.pointsRefunded | number: "1.1-2" }}
              </div>
            </ng-container>
            <div class="employee-point" *ngIf="orderDetails.paymentDetails['Employer Points']">
              Paid By Employer:
              {{
              (orderDetails.paymentDetails["Employer Points"]
              | number: "1.1-2") || "0.0"
              }}
            </div>
          </div>
          <div class="order-type-container">
            Order Type : <span class="order-type">{{ orderDetails.orderType.split('_').join(" ") }}</span>
          </div>
          <div class="delivery-options"
            *ngIf="orderDetails.orderType !== 'DELIVERY' && !orderDetails?.preOrder && orderDetails.orderdeliveryStatus !== 'CANCELLED' && !isOrderDelivered && orderDetails?.oneclickOrderType !== 'BTC'">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="orderType" (ngModelChange)="changeOrderType()">
              <ng-container *ngFor="let delivery of DELIVERY_OPTIONS">
                <mat-radio-button [value]="delivery.value" [color]="'primary'">{{
                  delivery.name
                  }}</mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </div>
          <div class="delivery-details"
            *ngIf="packagingCharges && orderDetails.orderType === 'DINE_IN' && orderType !== 'DINE_IN'">
            <div class="delivery-heading">Packaging Charges Details</div>
            <div class="delivery-fee-detail">
              <div class="fee-heading">Packaging Fee</div>
              <div class="fee-value">
                &#8377;
                <!-- {{ ( packagingCharges / 100 ) | number:'1.0-1' }} -->
                {{ ( packagingCharges) | number:'1.0-1' }}
              </div>
            </div>


          </div>
          <div class="delivery-details" *ngIf="showDeliverOrderOption && orderType === 'DELIVERY'">
            <div class="delivery-heading">Delivery Details</div>
            <div class="delivery-fee-detail">
              <div class="fee-heading">Delivery Fee</div>
              <div class="fee-value">
                &#8377;
                <!-- {{
                (orderDetails.totalPrice > orderDetails.minOrderAmount
                ? orderDetails.deliveryCharges / 100
                : orderDetails.minDeliveryCharges / 100
                ) | number: "1.1-2"
                }} -->
                {{
                (orderDetails.totalPrice > orderDetails.minOrderAmount
                ? orderDetails.deliveryCharges
                : orderDetails.minDeliveryCharges
                ) | number: "1.1-2"
                }}
              </div>
            </div>
            <div class="delivery-fee-detail">
              <div class="fee-heading">18% GST Fee</div>
              <div class="fee-value">
                &#8377;
                <!-- {{
                ((orderDetails.totalPrice > orderDetails.minOrderAmount
                ? orderDetails.deliveryCharges / 100
                : orderDetails.minDeliveryCharges / 100
                ) * 0.18) | number: "1.1-2"
                }} -->
                {{
                ((orderDetails.totalPrice > orderDetails.minOrderAmount
                ? orderDetails.deliveryCharges
                : orderDetails.minDeliveryCharges
                ) * 0.18) | number: "1.1-2"
                }}
              </div>
            </div>


          </div>
          <div class="location-details" *ngIf="
              showDeliverOrderOption &&
              orderType === 'DELIVERY' &&
              !deliveryAddress
            ">
            <div class="location-heading">You have not added any address</div>
            <div class="location-value" (click)="onAddLocation()">
              Add New Location
            </div>
          </div>
          <div class="address-details-section"
            *ngIf="orderDetails.orderType === 'DELIVERY' || (deliveryAddress && orderType === 'DELIVERY')">
            <div class="address-img">
              <img src="../../../../../../../assets/images/cart/default-address.svg" alt="Address" />
            </div>
            <div class="address-details">
              <div class="address-heading">
                Deliver to
                {{
                (orderDetails.deliveryAddress?.addressTitle | titlecase) ||
                (deliveryAddress?.addressTitle | titlecase)
                }}
              </div>
              <div class="address-value">
                {{
                (orderDetails.deliveryAddress?.address | titlecase) ||
                (deliveryAddress?.address | titlecase)
                }}
              </div>
            </div>
            <div class="change-address" (click)="onAddLocation()" *ngIf="!orderDetails.deliveryAddress">
              Change
            </div>
          </div>
          <a href="tel:{{ orderDetails.deliveryBoyDetails.mobile.mobile }}" *ngIf="orderDetails.deliveryBoyDetails">
            <div class="delivery-boy-details-section">
              <div class="delivery-boy-img">
                <img src="../../../../../../assets/images/dummy/dummy_user.svg" alt="" />
              </div>
              <div class="delivery-boy-details">
                <div class="delivery-boy-heading">Delivery Person Details</div>
                <div class="delivery-boy-value">
                  {{
                  orderDetails.deliveryBoyDetails.deliverBoyName | titlecase
                  }},
                  {{ orderDetails.deliveryBoyDetails.mobile.mobile }}
                </div>
              </div>
              <div class="delivery-boy-contact">
                <img src="../../../../../../assets/images/common/phone.svg" alt="" />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="cancel-btn-container"
        *ngIf="(orderType === 'DELIVERY' || showCancelButton) && orderDetails?.oneclickOrderType !== 'BTC'">
        <div class="redeem-points"
          *ngIf="orderDetails.orderType !== 'DELIVERY' && orderType !== 'DINE_IN' && userPoints.istharaPoints && !(orderDetails.orderType === 'PICK_UP' && orderType === 'PICK_UP')">
          <div class="points-container">
            <div class="points-heading">Redeem Points</div>
            <div class="points-value">
              <span>Available : </span><span><img src="../../../../../../assets/images/home-page/isthara-points.svg"
                  alt="" /></span>{{ userPoints.istharaPoints | number: "1.1-2" }}
            </div>
          </div>
          <div class="toggle">
            <mat-slide-toggle [color]="'primary'" (change)="toggleUserPoints($event)"
              [(ngModel)]="pointsToggleChecked"></mat-slide-toggle>
          </div>
        </div>
        <ng-container *ngIf="!orderDetails.deliveryAddress && orderType === 'DELIVERY' && showPaymentBtn">
          <button class="pay-deliver-amount" *ngIf="!buttonLoading; else loader" [disabled]="!deliveryAddress"
            (click)="doPaymentForDelivery()">
            Delivery Payable Amount &#8377;
            {{ orderDetails.payableAmount | number: "1.1-2" }}
          </button>
          <ng-template #loader>
            <button class="pay-deliver-amount">
              <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
            </button>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="orderType === 'PICK_UP' && showPaymentBtn && orderDetails.orderType !== 'PICK_UP'">
          <button class="pay-deliver-amount" (click)="doPaymentForSelfPickup()">
            Packaging Charges Payable Amount &#8377;
            {{ orderDetails.payableAmount | number: "1.0-2" }}
          </button>
          <ng-template #loader>
            <button class="pay-deliver-amount">
              <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
            </button>
          </ng-template>
        </ng-container>
        <!-- <button [disabled]="timer === 0" class="cancel-button">
          <span *ngIf="timer !== 0" (click)="cancelOrderPopup()"
            >Cancel Order in ({{ timer }} Sec)</span
          >
          <span *ngIf="timer === 0">Order Successfully Placed</span>
        </button> -->
        <button class="cancel-button" (click)="cancelOrderPopup('NORMAL_ORDER')"
          *ngIf="showCancelButton && timeLeftForCancel > 0 && !orderDetails.preOrder">
          Cancel Order ({{ timeLeftForCancel | number: '1.0-0'}} sec)
        </button>
        <button class="cancel-button" *ngIf="showCancelButton && orderDetails.preOrder"
          (click)="cancelOrderPopup('PRE_ORDER')">Cancel Order</button>
      </div>
      <!-- <form id="redirectForm" method="post" [action]="formDataView.paymentLink"> -->
      <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit

        production ===> https://www.cashfree.com/checkout/post/submit-->
      <!-- <input id="appId" type="hidden" name="appId" [value]="formDataView.appId" />
        <input type="hidden" name="orderId" [value]="formDataView.orderId" />
        <input type="hidden" name="orderAmount" [value]="formDataView.orderAmount" />
        <input type="hidden" name="orderCurrency" [value]="'INR'" />
        <input type="hidden" name="orderNote" [value]="'Order'" />
        <input type="hidden" name="customerName" [value]="formDataView.customerName" />
        <input type="hidden" name="customerEmail" [value]="formDataView.customerEmail" />
        <input type="hidden" name="customerPhone" [value]="formDataView.customerPhone" />
        <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
        <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
        <input type="hidden" name="signature" [value]="formDataView.signature" />
      </form> -->
    </ng-container>

    <ng-template #showRequestData>
      <div class="order-summary-section">

        <div class="order-info-container">
          <div class="order-info">
            <div class="order-info-one">
              <div class="small-heading-text">Your Request</div>
            </div>
            <div class="request-id">Request Id : {{requestDetails?.btcRequestDisplayId}}</div>
            <div class="order-info-two">
              {{ requestDetails.createdTimestamp | date: "mediumDate":"UTC+0" }} at
              {{ requestDetails.createdTimestamp | date: "shortTime":"UTC+0" }}
            </div>
          </div>
          <div class="guest-order-status"
            [ngClass]="{'rejected': requestDetails.requestStatus === 'REJECTED', 'rejected': requestDetails.requestStatus === 'CANCELLED', 'accepted': requestDetails.requestStatus === 'ACCEPTED'}">
            {{ requestDetails.requestStatus }}</div>
        </div>
        <div class="user-orders-info-list">
          <div class="order-list">
            <!-- <ng-container *ngFor="let vendor of vendorsList">
              <div>
                <div class="user-order-info">
                  <div class="left-section">
                    <div class="item-image-container">
                      <img
                        src="../../../../../../assets/images/common/default_restuarant.svg"
                        alt=""
                      />
                    </div>
                    <div class="brand-name-status">
                      <div class="brand-name">
                        {{ vendor }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="right-section"
                    *ngIf="
                      vendor[0]?.orderStatus === 'READY' ||
                      vendor[0]?.orderStatus === 'PAST'
                    "
                  >
                    <div class="kot-code">
                      Kot:
                      {{
                        requestDetails.orderType === "DELIVERY"
                          ? requestDetails.deliveryKot
                          : vendor[0].kot
                      }}
                    </div>
                    <div class="qr-code" (click)="displayQrCode(vendor)">
                      <span class="material-icons">qr_code</span>
                    </div>
                  </div>
                </div>
                <div class="user-order-item-list">
                  <ng-container *ngFor="let item of vendorDetailsForGuestOrdering[vendor]">
                    <div class="user-order-item">
                      <div class="order-item-left">
                        <div class="item-quantity">{{ item.itemQuantity }}</div>
                        <div class="x-icon">X</div>
                         <div class="item-name"
                          *ngIf="item.itemName.length < 25; else showTrimmedNameQB_">
                          <span>{{ item.itemName }}</span>
                        </div>

                         <ng-template #showTrimmedNameQB_>
                          <span class="trim-item-name"
                            >{{
                              item.itemName.substring(0, 24) | titlecase
                            }}..</span
                          >
                          <mat-icon (click)="openItemDetailBox(item)"
                            >add_box</mat-icon
                          >
                        </ng-template>
                        <div class="item-status" *ngIf="item.itemStatus === 'OUT_OF_STOCK'">Out of Stock</div>
                      </div>
                      <div class="item-price order-item-right">
                        &#8377; {{ ((item.price * item?.itemQuantity)/100) | number: "1.1-2" }}
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
              <hr />
            </ng-container> -->

            <div class="user-order-item-list">
              <ng-container *ngFor="let item of requestDetails.orderedItemsList">
                <div class="user-order-item">
                  <div class="order-item-left">
                    <div class="item-quantity">{{ item.itemQuantity }}</div>
                    <div class="x-icon">X</div>
                    <div class="item-name" *ngIf="item.itemName.length < 25; else showTrimmedNameQB_">
                      <span>{{ item.itemName }}</span>
                    </div>

                    <ng-template #showTrimmedNameQB_>
                      <span class="trim-item-name">{{
                        item.itemName.substring(0, 24) | titlecase
                        }}..</span>
                      <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon>
                    </ng-template>
                    <div class="item-status" *ngIf="item.itemStatus === 'OUT_OF_STOCK'">Out of Stock</div>
                  </div>
                  <div class="item-price order-item-right">
                    <!-- &#8377; {{ ((item.price * item?.itemQuantity)/100) | number: "1.1-2" }} -->
                    &#8377; {{ ((item.markUpPrice * item?.itemQuantity)) | number: "1.1-2" }}
                  </div>
                </div>
                <hr />
              </ng-container>

            </div>
          </div>
        </div>
        <div class="user-payment-info">
          <div class="total-amount-section">
            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Total Items Price</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ requestDetails.billDetails?.breakup["Item Total"] / 100 | number: "1.1-2" }} -->
                {{ requestDetails.billDetails?.breakup["Item Total"] | number: "1.1-2" }}
              </div>
            </div>

            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Taxes & Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ requestDetails.billDetails?.breakup["Taxes & charge"] / 100 | number: "1.1-2"}} -->
                {{ requestDetails.billDetails?.breakup["Taxes & charge"] | number: "1.1-2"}}
              </div>
            </div>

            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Item Discount</div>
              </div>
              <div class="item-price order-item-right discount-amount">
                - &#8377;
                <!-- {{ requestDetails.billDetails?.breakup["Item Discount"] / 100 | number: "1.1-2"}} -->
                {{ requestDetails.billDetails?.breakup["Item Discount"] | number: "1.1-2"}}
              </div>
            </div>
            <!--
            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Delivery Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                {{
                  requestDetails.billDetails?.breakup["Delivery Charges"] / 100 | number: "1.1-2"
                }}
              </div>
            </div>

            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">Packing Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                {{
                  requestDetails.billDetails?.breakup["Packing Charges"] / 100 | number: "1.1-2"
                }}
              </div>
            </div> -->

            <div class="user-total-price" *ngIf="requestDetails.billDetails?.breakup['Delivery Charges']">
              <div class="order-item-left">
                <div class="item-name">Delivery Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ requestDetails.billDetails?.breakup["Delivery Charges"] / 100 | number: "1.1-2" }} -->
                {{ requestDetails.billDetails?.breakup["Delivery Charges"] | number: "1.1-2" }}
              </div>
            </div>

            <div class="user-total-price" *ngIf="requestDetails.billDetails?.breakup['Packing Charges']">
              <div class="order-item-left">
                <div class="item-name">Packaging Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{ requestDetails.billDetails?.breakup["Packing Charges"] / 100 | number: "1.1-2"}} -->
                {{ requestDetails.billDetails?.breakup["Packing Charges"] | number: "1.1-2"}}
              </div>
            </div>
            <div class="user-total-price">
              <div class="order-item-left">
                <div class="item-name">GST on Delivery Charges</div>
              </div>
              <div class="item-price order-item-right">
                &#8377;
                <!-- {{((requestDetails.billDetails?.breakup["Delivery Charges"] * 0.18) / 100) | number: "1.1-2"}} -->
                {{((requestDetails.billDetails?.breakup["Delivery Charges"] * 0.18)) | number: "1.1-2"}}
              </div>
            </div>
            <div class="grand-total">
              <div class="total-text">Grand Total</div>
              <div class="total">
                <!-- &#8377; {{ requestDetails.billDetails.finalPrice / 100 | number: "1.1-2" }} -->
                &#8377; {{ requestDetails.billDetails.finalPrice | number: "1.1-2" }}
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div class="request-type">{{ requestDetails?.orderType.split('_').join(" ") | uppercase }}</div>

        <div class="guest-details" *ngIf="path_param === 'guest-request'">
          <div class="heading-text">Guest Details</div>
          <ng-container *ngFor="let guestDetails of requestDetails.guestDetails">
            <div class="guest-detail-container">
              <div class="guest-name">Name : {{ guestDetails?.name }}</div>
              <div class="guest-mobile">Mobile : {{ guestDetails?.mobile }}</div>
              <div class="guest-mail">Email : {{ (guestDetails?.email ? guestDetails?.email : "N/A") }}</div>
            </div>
          </ng-container>
        </div>

        <div class="request-action-btn" *ngIf="requestDetails.requestStatus === 'NEW'"
          [ngStyle]="{'width': (scrWidth > 600 ? '78%': '95%')}">
          <div class="guest-request-action-btn" *ngIf="pathParam === 'guest-request'"></div>
          <div class="btc-request-action-btn" *ngIf="pathParam === 'corporate-order'">
            <div class="remind-approval-btn-container" (click)="remindBTCOrderApproval()">
              <button class="btc__remind-arrpoval-btn" *ngIf="!btcRemindApprovalBtnLoader; else ReminderLoader"
                [disabled]="btcEditRequestBtnLoader || btcCancelRequestBtnLoader">Remind Approval Request</button>
              <ng-template #ReminderLoader>
                <button class="btc__remind-arrpoval-btn">
                  <i class="fa fa-spinner fa-spin"></i> Loading...
                </button>
              </ng-template>
            </div>
            <div class="cancel-edit-btn-container">
              <button class="btc__cancel-btn" (click)="cancelBTCOrderRequest()"
                *ngIf="!btcCancelRequestBtnLoader; else CancelRequestLoader"
                [disabled]="btcEditRequestBtnLoader || btcRemindApprovalBtnLoader">Cancel Request</button>
              <button class="btc__edit-order" (click)="editBTCOrderRequest()"
                *ngIf="!btcEditRequestBtnLoader; else EditRequestLoader"
                [disabled]="btcRemindApprovalBtnLoader || btcCancelRequestBtnLoader">Edit Request</button>
              <ng-template #CancelRequestLoader>
                <button class="btc__cancel-btn">
                  <i class="fa fa-spinner fa-spin"></i> Loading...
                </button>
              </ng-template>
              <ng-template #EditRequestLoader>
                <button class="btc__edit-order">
                  <i class="fa fa-spinner fa-spin"></i> Loading...
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="rejected-employee-info" *ngIf="requestDetails.requestStatus === 'REJECTED'">
          <div class="rejected-text">Rejected By</div>
          <div class="rejected-employee-name">Employee Name : {{ requestDetails.staffName }}</div>
          <div class="rejected-reason">Reason : {{ requestDetails.rejectReason | titlecase }}</div>
        </div>

      </div>
    </ng-template>
  </ng-container>
  <ng-template #loaderScreen>
    <div class="loader-img-container">
      <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
    </div>
  </ng-template>
</div>