<section class="image-container-section">
    <div class="content" *ngIf="!ticketDetailScreenLoader; else showScreenLoader">
        <div class="section-header">
                <div class="header__left-arrow-contaier" (click)="goToTicketView()">
                    <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt="">
                </div>
                <div class="header__primary-text">Images</div>
        </div>
        <div class="main-image-container">
            <img [src]="selectedImageUrl" alt="">
        </div>
        <div class="image-list">
            <ng-container *ngFor="let image of issueImagesList">
                <div class="issue-image-container" [ngClass]="{'selected-image' : image.status}" (click)="selectImage(image)">
                    <img [src]="image.image" alt="">
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template #showScreenLoader>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</section>