import { SigninSignupFlowComponent } from './components/erp-landing/authentication/signin-signup-flow/signin-signup-flow.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/home-page/home-page.component';
import { LandingComponentComponent } from './components/erp-landing/landing-component/landing-component.component';
import { CartComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/cart/cart.component';
import { SearchRestaurantsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/search-restaurants/search-restaurants.component';
import { RestaurantViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/restaurant-view/restaurant-view.component';
import { ChooseMealViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/choose-meal-view/choose-meal-view.component';
import { CoupensComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/coupens/coupens.component';
import { AccountPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-page/account-page.component';
import { UserOrderHistoryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-order-history/user-order-history.component';
import { UserAddressComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-address/user-address.component';
import { AccountEditComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-edit/account-edit.component';
import { ListUserAddressComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/list-user-address/list-user-address.component';
import { GuestOrderingFlowComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/guest-order-components/guest-ordering-flow/guest-ordering-flow.component';
import { PaymentPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/payment-page/payment-page.component';
import { UrlNotFoundPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/url-not-found-page/url-not-found-page.component';
import { UserPointsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-points/user-points.component';
import { OrderRatingPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/order-rating-page/order-rating-page.component';
import { AuthGuard } from './components/erp-landing/authentication/services/auth.guard';
import { OrderSummaryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/order-summary/order-summary.component';
import { AccountPageTabComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-page-tab/account-page-tab.component';
import { QrCodePageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/qr-code-page/qr-code-page.component';
import { FoodPreorderComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-preorder/food-preorder.component';
import { HelpdeskListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/helpdesk-listing/helpdesk-listing.component';
import { RaiseIssueComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/raise-issue/raise-issue.component';
import { HelpdeskCategoryListComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/helpdesk-category-list/helpdesk-category-list.component';
import { ViewTicketComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/view-ticket/view-ticket.component';
import { RechargePointsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/recharge-points/recharge-points.component';
import { RechargeSummaryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/recharge-summary/recharge-summary.component';
import { RateServiceComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/rate-service/rate-service.component';
import { ImageViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/image-view/image-view.component';
import { NotificationsListComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/notifications/notifications-list/notifications-list.component';
import { UserSettingsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-settings/user-settings.component';
import { ResturantDiscountScreenComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/discount-flow/resturant-discount-screen/resturant-discount-screen.component';
import { ContactUsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/contact-us/contact-us.component';
import { SubcriptionPlanListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/subcription-plan-listing/subcription-plan-listing.component';
import { SubcriptionPlanViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/subcription-plan-view/subcription-plan-view.component';
import { PlanSubscriptionPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/plan-subscription-page/plan-subscription-page.component';
import { UserFoodsubscriptionListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-foodsubscription-flow/user-foodsubscription-listing/user-foodsubscription-listing.component';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    component: SigninSignupFlowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'foodcourt/order/payment',
    component: PaymentPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: LandingComponentComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: ':foodcourt',
      //   pathMatch: 'full',
      // },
      // {
      //   path: 'home',
      //   component: HomePageComponent,
      // },
      {
        path: ':foodcourt',
        component: HomePageComponent,
      },
      {
        path: 'foodcourt/home',
        component: HomePageComponent
      },
      {
        path: 'discount/:type',
        component: ResturantDiscountScreenComponent
      },
      {
        path: 'search',
        component: SearchRestaurantsComponent,
      },
      {
        path: 'search/:foodcourt',
        component: SearchRestaurantsComponent,
      },
      {
        path: 'cart',
        component: CartComponent,
      },
      {
        path: 'cart/:foodcourt',
        component: CartComponent,
      },
      {
        path: 'restaurant',
        component: RestaurantViewComponent,
      },
      {
        path: 'restaurant/:foodcourt/:vendor',
        component: RestaurantViewComponent,
      },
      {
        path: 'food-subscription/list-plan',
        component: SubcriptionPlanListingComponent,
      },
      {
        path: 'food-subscription/view-plan',
        component: SubcriptionPlanViewComponent,
      },
      {
        path: 'food-subscription/plan-subscription',
        component: PlanSubscriptionPageComponent,
      },
      {
        path: 'choose-meal',
        component: ChooseMealViewComponent,
      },
      {
        path: 'choose-meal/:foodcourt/:type',
        component: ChooseMealViewComponent,
      },
      {
        path: 'choose-meal/:foodcourt/:type/:isPreOrderingType',
        component: ChooseMealViewComponent,
      },
      {
        path: 'user-cart/coupons',
        component: CoupensComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'guest-ordering-qr/:orderId',
        component: QrCodePageComponent
      },
      {
        path: 'user/preordering',
        component: FoodPreorderComponent
      },
      {
        path: 'account/user',
        component: AccountPageTabComponent
      },
      {
        path: 'profile/user',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: AccountPageComponent,
          },
          {
            path: 'edit',
            component: AccountEditComponent,
          },
          {
            path: 'points',
            component: UserPointsComponent,
          },
          {
            path: 'recharge-points',
            component: RechargePointsComponent,
          },
          {
            path: 'recharge-summary',
            component: RechargeSummaryComponent,
          },
          {
            path: 'order-history',
            component: UserOrderHistoryComponent,
            canDeactivate: [AuthGuard],
          },
          {
            path: 'order-history/rating',
            component: OrderRatingPageComponent,
          },
          {
            path: 'order-summary',
            component: OrderSummaryComponent,
            canDeactivate: [AuthGuard],
          },
          {
            path: 'order-summary/:summary_type',
            component: OrderSummaryComponent,
            canDeactivate: [AuthGuard],
          },
          {
            path: 'helpdesk-list',
            component: HelpdeskListingComponent,
          },
          {
            path: 'helpdesk-raise-issue/:type',
            component: RaiseIssueComponent,
          },
          {
            path: 'helpdesk-raise/:type',
            component: RaiseIssueComponent,
          },
          {
            path: 'helpdesk-rate-ticket',
            component: RateServiceComponent,
          },
          {
            path: 'helpdesk-category',
            component: HelpdeskCategoryListComponent,
          },
          {
            path: 'view-ticket',
            component: ViewTicketComponent,
          },
          {
            path: 'view-image/:image_type',
            component: ImageViewComponent,
          },
          {
            path: 'settings',
            component: UserSettingsComponent,
          },
          {
            path: 'contact-us',
            component: ContactUsComponent,
          },
          {
            path: 'user-foodsubscription-list',
            component: UserFoodsubscriptionListingComponent,
          },
        ],
      },
      {
        path: 'user/guest-order',
        component: GuestOrderingFlowComponent,
      },
      {
        path: 'user/notifications',
        canActivate: [AuthGuard],
        component: NotificationsListComponent
      },
      {
        path: 'user/notifications/:id',
        canActivate: [AuthGuard],
        component: NotificationsListComponent
      }
    ],
  },
  { path: 'error', component: UrlNotFoundPageComponent },
  {
    path: '**',
    pathMatch: 'full',
    component: UrlNotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
