<div class="section">
  <div class="coupon-section" *ngIf="!showLoader; else loadingScreen">
    <div class="header">
      <div class="header-menu">
        <div class="back-btn">
          <img
            src="./../../../../../../assets/images/common/back_arrow.svg"
            (click)="back()"
          />
        </div>
        <div class="header-left">
          <div class="main-path">Apply Coupon</div>
        </div>
      </div>
    </div>

    <div class="close-btn" *ngIf="scrWidth > 600">
      <img src="../../../../../../assets/images/common/close.svg" alt="" (click)="goToCart()">
    </div>
    <div class="search-coupon-code">
      <div class="offers-code">
        <input type="text" placeholder="Enter Your Coupon" class="offers-text" [(ngModel)]="couponCode" />
        <button type="button" class="apply-coupon" (click)="applyCouponCode()">Apply</button>
      </div>
    </div>

    <!-- <div class="search-coupon-container">
      <input type="text" placeholder="Enter Coupon Code">
      <button>Apply</button>
    </div> -->
    <ng-container *ngIf="applicableCouponsList.length > 0 || nonApplicableCouponsList.length > 0; else emptyCouponList">
      <div class="coupon-list-section">
        <div class="coupon-heading-text" *ngIf="applicableCouponsList.length > 0">Applicable Coupon</div>
        <ng-container *ngFor="let coupon of applicableCouponsList">
          <div class="coupon-container">
            <div class="left-circle"></div>
            <!-- <div class="right-circle"></div> -->
            <div class="coupon__left-section">
              <ng-container *ngIf="coupon.discountType === 'FIXED_AMOUNT'">Flat ₹{{ coupon.discountValue }}</ng-container>
              <ng-container *ngIf="coupon.discountType === 'PERCENTAGE'">Get {{ coupon.discountValue }}% Discount</ng-container>
              <ng-container *ngIf="coupon.discountType === 'FREEBIES'">{{ coupon.discountName | uppercase }}</ng-container>
            </div>
            <div class="coupon__right-section">
              <div class="coupon__right-section__heading">
                <div class="left-heading">{{ coupon.discountName | titlecase }}</div>
                <div class="apply-btn" (click)="applyCoupon(coupon)">Apply</div>
              </div>
              <div class="coupon__right-section__description">
                <!-- <ng-container *ngIf="coupon.discountType !== 'FREEBIES'">
                  Valid on total value of items worth ₹{{coupon?.discountConstraints?.minimumOrderAmount}} or more
                </ng-container>
                <ng-container *ngIf="coupon.discountType === 'FREEBIES'">
                  Valid on items applicable for {{ coupon.discountName | titlecase }}
                </ng-container> -->
                {{ coupon?.discountDescription | titlecase }}
              </div>
              <div class="coupon__right-section__footer">
                <div class="left-footer">{{ coupon.discountCouponCode | uppercase }}</div>
                <div class="view-more-btn" (click)="showDiscountDetails(coupon)">View more</div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="coupon-heading-text" *ngIf="nonApplicableCouponsList.length > 0">More Offers</div>
        <ng-container *ngFor="let coupon of nonApplicableCouponsList">
          <div class="coupon-container">
            <div class="left-circle"></div>
            <!-- <div class="right-circle"></div> -->
            <div class="coupon__left-section non-applicable">
              <ng-container *ngIf="coupon.discountType === 'FIXED_AMOUNT'">Flat ₹{{ coupon.discountValue }}</ng-container>
              <ng-container *ngIf="coupon.discountType === 'PERCENTAGE'">Get {{ coupon.discountValue }}% Discount</ng-container>
              <ng-container *ngIf="coupon.discountType === 'FREEBIES'">{{ coupon.discountName | uppercase }}</ng-container>
            </div>
            <div class="coupon__right-section">
              <div class="coupon__right-section__heading">
                <div class="left-heading">{{ coupon.discountName | titlecase }}</div>
                <div class="apply-btn disable-btn">Apply</div>
              </div>
              <div class="coupon__right-section__description">
                <!-- Valid on total value of items worth ₹{{coupon?.discountConstraints?.minimumOrderAmount}} or more -->
                
                {{ coupon?.discountDescription | titlecase }}
              </div>
              <div class="coupon__right-section__footer">
                <div class="left-footer">{{ coupon.discountCouponCode | uppercase }}</div>
                <div class="view-more-btn" (click)="showDiscountDetails(coupon)">View more</div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- <ng-container *ngFor="let coupon of couponsList">
          <div class="reedem-code">
            <div class="main-coupon">
              <div class="reedem-text">
                <div class="main">
                  <img
                    *ngIf="!coupon.discountImageUrl; else showAvatar"
                    src="./../../../../../../assets/images/common/isthara-mobile-logo.png"
                  />
                  <ng-template #showAvatar>
                    <img [src]="coupon.discountImageUrl" alt="" />
                  </ng-template>
                </div>
                <div class="reedem-text-text">
                  {{ coupon.discountName | uppercase }}
                </div>
              </div>
              <div
                class="apply"
                [ngClass]="{ 'disable-btn': !coupon.applicable }"
                (click)="applyCoupon(coupon)"
              >
                Apply
              </div>
            </div>

            <div class="apply-heading-code">
              <div
                class="apply-heading-main"
                *ngIf="coupon.discountType === 'PERCENTAGE'"
              >
                Get {{ coupon.discountValue }}% Discount Using
                {{ coupon.discountName | uppercase }}
              </div>
              <div
                class="apply-heading-main"
                *ngIf="coupon.discountType === 'FIXED_AMOUNT'"
              >
                Get Flat ₹{{ coupon.discountValue }} Off by Using
                {{ coupon.discountName | uppercase }}
              </div>
              <div
                class="apply-heading-sub"
                *ngIf="coupon?.discountConstraints?.maxRedemptions"
              >
                Maximum discount up to ₹{{
                  coupon?.discountConstraints?.maxRedemptions
                }}
                on orders Above ₹{{
                  coupon?.discountConstraints?.minimumOrderAmount
                }}
              </div>
              <div
                class="apply-heading-sub"
              >
                {{ coupon?.vendorName | titlecase }}
              </div>
              <div class="sub-text">
                <span *ngIf="coupon.applicable" class="offer-applicable-text">Offer Applicable</span>
                <span *ngIf="!coupon.applicable" class="offer-not-applicable-text">Offer Not Applicable</span>
              </div>
              
              <div
                class="apply-heading-link"
                (click)="toggleShowMore(coupon)"
                *ngIf="!coupon?.showMoreBtn"
              >
                View More
              </div>
              <div class="terms-and-condition" *ngIf="coupon?.showMoreBtn">
                <span class="heading-text">Terms & Conditions</span>
                <ul>
                  <li *ngIf="coupon.termsAndConditions['Only Once'] === true">
                      Coupon code can be applied only once.
                  </li>
                  <li *ngIf="coupon.forMrpItems && coupon.forNonMrp; else showOnlyApplicableMRPItem">
                      Coupon code is applicable for both MRP & NON-MRP Items.
                  </li>
                  <ng-template #showOnlyApplicableMRPItem>
                    <li *ngIf="coupon.forMrpItems">Coupon code is applicable for MRP items.</li>
                    <li *ngIf="coupon.forNonMrp">Coupon code is applicable for NON-MRP items.</li>
                  </ng-template>
                  <li *ngIf="coupon.termsAndConditions.Days?.length > 0">
                    Offer valid on
                    <ng-container *ngIf="coupon.termsAndConditions.Days?.length === 7; else showDaysForOrders">
                      <span>All Days.</span>
                    </ng-container>
                    <ng-template #showDaysForOrders>
                      <ng-container *ngFor="let day of coupon.termsAndConditions.Days">
                        <span>{{ day | titlecase }}, </span>
                      </ng-container>
                    </ng-template>
                  </li>
                  <li *ngIf="coupon.termsAndConditions['New User']">
                    Offer is valid for first time users.
                  </li>
                  <li *ngIf="coupon.discountConstraints.minimumOrderAmount">
                    Offer valid on minimum net transaction amount of &#8377;{{ coupon.discountConstraints.minimumOrderAmount }}.
                  </li>
                  <li *ngIf="coupon.termsAndConditions['Start Time'] && coupon.termsAndConditions['End Time']">
                    Coupon code can be applied only at {{ coupon.termsAndConditions['Start Time'] }} - {{ coupon.termsAndConditions['End Time'] }}.
                  </li>
                  <li *ngIf="coupon?.validTo">
                    Offer valid till {{ coupon?.validTo | date }}.
                  </li>
                  <li>
                    Other T&C may apply.
                  </li>
                  <ng-container
                    *ngIf="
                      coupon?.termsAndConditions.length > 0 &&
                      coupon?.termsAndConditions[0]
                    "
                  >
                    <ng-container
                      *ngFor="let term_condition of coupon?.termsAndConditions"
                    >
                      <li>{{ term_condition | titlecase }}</li>
                    </ng-container>
                  </ng-container>
                </ul>
                <div class="apply-heading-link" (click)="toggleShowMore(coupon)">
                  View Less
                </div>
              </div>
            </div>
          </div>
        </ng-container> -->
      </div>
    </ng-container>
    <ng-template #emptyCouponList>
      <div class="empty-part">
        <div class="no-image">
          <img
            src="../../../../../../assets/images/cart/empty-cart.svg"
            alt="empty-image"
          />
        </div>
        <div class="empty-heading">No coupon availabe</div>
        <!-- <div class="empty-heading">But good food is always cooking</div> -->
        <div class="empty-subtext">Go ahead, Order some more items</div>
        <div class="emptyrules-text">to see availabe coupons</div>
      </div>
      </ng-template>
  </div>

  <ng-template #loadingScreen>
    <div class="loading-img-container">
      <img
        src="../../../../../../assets/images/common/burger_loader.gif"
        alt=""
      />
    </div>
  </ng-template>

</div>
