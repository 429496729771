import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private http: HttpClient
  ) { }

  getUserNotifications(payload: any) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/notification/custom/ist/notification/history/${payload.userId}?startDate=${payload.startDate}&endDate=${payload.endDate}`
    )
  }

  updateNotificationClicked(payload: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/notification/custom/ist/notification/clicked/${payload.notificationId}?userId=${payload.userId}&date=${payload.date}`, {}
    )
  }

  deleteNotification(notificationHistoryId: string) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/notification/custom/ist/delete/${notificationHistoryId}`
    )
  }
}
