import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { FoodSubscriptionService } from '../food-subscription.service';
import { environment } from '../../../../../../../environments/environment';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SubscriptionTermsAndConditionsComponent } from '../../other-components/subscription-terms-and-conditions/subscription-terms-and-conditions.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-plan-subscription-page',
  templateUrl: './plan-subscription-page.component.html',
  styleUrls: ['./plan-subscription-page.component.scss']
})
export class PlanSubscriptionPageComponent implements OnInit {

  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private foodSubscriptionService: FoodSubscriptionService,
    private dbStorageService: PopupDbService,
    private bottomSheet: MatBottomSheet,
    @Optional() public dialogRef: MatDialogRef<PlanSubscriptionPageComponent>

  ) { }

  userPoints: any = {
    userIstharaPoints: 0,
    redeemIstharaPoints: 0
  };
  subscriptionPlanDetails;
  subscriptionStartDate: Date;
  subscriptionEndDate: Date;
  minimumNoofdays;

  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) { 
    this.scrWidth = window.innerWidth;
  }

  doPayWithIstharaPoint: boolean = false;

  isTermsAndConditionChecked: boolean = true;

  ngOnInit(): void {
    this.getScreenSize()
    // console.log(this.router, this.landingComponentService, this.foodSubscriptionService, this.db)
    this.subscriptionPlanDetails = this.landingComponentService.getSelectedSubscriptionPlan();

    if (!this.subscriptionPlanDetails) {
      let foodcourtName = this.landingComponentService.getFoodCourtName();
      this.landingComponentService.openSnackBarMessage("Please do not refresh the page!!");
      this.router.navigate([`${foodcourtName}`]);
    }
   
    this.minimumNoofdays = this.subscriptionPlanDetails.noOfDays;
    // console.log(this.minimumNoofdays);
    
    this.subscriptionStartDate = new Date(new Date().getTime() + 1*8.64*10000000);
    // this.subscriptionStartDate = new Date((new Date()).getTime() + (this.subscriptionPlanDetails.noOfDays*8.64*10000000));

    if (this.minimumNoofdays > 30) {

      this.subscriptionEndDate = new Date(this.subscriptionStartDate.getTime() + 30*8.64*10000000);
    } else {
      
      this.subscriptionEndDate = new Date(this.subscriptionStartDate.getTime() + (this.minimumNoofdays +1)*8.64*10000000);
    }

    this.calculateSubscriptionPrice();
    this.getUserPoints();
  }

  toggleUserPoints() {
    this.calculateSubscriptionPrice();
  }

  getUserPoints() {
    
    let userId = this.landingComponentService.getUserId();

    if (!userId) 
      return;
    this.foodSubscriptionService.getUserPoints(userId).subscribe((response:any)=> {
      
        this.userPoints.userIstharaPoints = response.data;
        this.userPoints.redeemIstharaPoints = response.data;
      
    }, (err)=> {
      this.landingComponentService.openSnackBarMessage("Failed to get User Isthara Points");
    })
  }

  calculateSubscriptionPrice() {

    if (((this.subscriptionEndDate.getTime() - this.subscriptionStartDate.getTime()) / (1000 * 3600 * 24)) < this.subscriptionPlanDetails.noOfDays) {
      this.landingComponentService.openSnackBarMessage("Date Range should be alteast " + this.subscriptionPlanDetails.noOfDays);
      this.subscriptionEndDate = new Date(this.subscriptionStartDate.getTime() + (this.subscriptionPlanDetails.noOfDays * 1000 * 3600 * 24));
      this.isTermsAndConditionChecked = false;
    }

    let dayWisePrice = this.subscriptionPlanDetails.price / 30;
    let noOfDays = Math.round((this.subscriptionEndDate.getTime() - this.subscriptionStartDate.getTime()) / (1000 * 3600 * 24)) + 1;
    
    this.subscriptionPlanDetails.noOfDays = noOfDays;

    this.subscriptionPlanDetails.totalPrice = dayWisePrice * noOfDays; 
    this.subscriptionPlanDetails.totalTax = (this.subscriptionPlanDetails.totalPrice * 0.05);
    this.isTermsAndConditionChecked = true;

    if (this.doPayWithIstharaPoint) {
      this.subscriptionPlanDetails.totalPayableAmount = this.subscriptionPlanDetails.totalPrice + this.subscriptionPlanDetails.totalTax - this.userPoints.redeemIstharaPoints; 
      if (this.subscriptionPlanDetails.totalPayableAmount < 0) {
        this.subscriptionPlanDetails.totalPayableAmount = 0;
        this.userPoints.redeemIstharaPoints -= (this.subscriptionPlanDetails.totalPrice + this.subscriptionPlanDetails.totalTax);
      }
      ;
    }
    else {
      this.subscriptionPlanDetails.totalPayableAmount = this.subscriptionPlanDetails.totalPrice + this.subscriptionPlanDetails.totalTax;
    }

    if (this.subscriptionPlanDetails.totalPrice < 0) {
      this.subscriptionEndDate = this.subscriptionStartDate;
      this.calculateSubscriptionPrice();
    }
  }

  goToSubscriptionDetailsPage() {
    if(this.scrWidth >= 600){
      this.dialogRef.close();
    }
    else{
        this.router.navigate(['food-subscription/list-plan']);
    }
  }

  showTermsAndCondition() {
    
    this.isTermsAndConditionChecked = true;
    const bottomSheetRef = this.bottomSheet.open(SubscriptionTermsAndConditionsComponent, {
      data: {termsAndConditions: this.subscriptionPlanDetails.termsAndConditions}
    });
  }

  doPaymentWithIstharaPoints() {
    let userName = this.landingComponentService.getUserName();
    let userMobileNumber = this.landingComponentService.getUserMobile();
    let userId = this.landingComponentService.getUserId();

    let data = {
      "orderAmount": this.subscriptionPlanDetails.totalPrice,
      "planId": this.subscriptionPlanDetails.planId,
      "userId": userId,
      "userMobileNumber": userMobileNumber,
      "userName": userName
    }
    ;
    this.foodSubscriptionService.subscribePlanForUserByIstharaPoints(data).subscribe((response: any)=> {
      ;
    }, (err)=> {

    })
  }
  doPaymentWithCashFree() {

    this.landingComponentService.setUserSubscriptionPlanId(this.subscriptionPlanDetails.planId);
    this.landingComponentService.setSubscriptionPlanOrderingDate(this.subscriptionStartDate, this.subscriptionEndDate);

    let email = this.landingComponentService.getUserEmail();
    let mobile = this.landingComponentService.getUserMobile();
    let userId = this.landingComponentService.getUserId();
    let userName = this.landingComponentService.getUserName();
    let orderAmount = this.subscriptionPlanDetails.totalPayableAmount;

    if (!userId) {
      if(this.dialogRef){
        this.dialogRef.close();
      }
      
      this.landingComponentService.openSnackBarMessage("Please login to Opt food subcription");
      this.router.navigate(['/auth']);
    }

    let data = {
      "email": email,
      "mobile": mobile,
      "orderAmount": this.subscriptionPlanDetails.totalPayableAmount * 100,
      "pointsToBePaid": this.userPoints.redeemIstharaPoints,
      "userId": userId,
      "username": userName
    }
    ;
    this.foodSubscriptionService.subscribePlanForUser(data).subscribe((response: any)=> {
      if(this.dialogRef){
        this.dialogRef.close();
      }
      this.dbStorageService.setPaymentFor('FOOD_SUBSCRIPTION');
      
      const paymentSessionId = response.data.orderSignature;
      let cashFree;
      if (environment.production) {
        cashFree = new cashfreeProd.Cashfree(paymentSessionId);
      } else {
        cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
      }
      cashFree.redirect();
      // const dialogRef = this.dialog.open(SuccessfullySubscribedPageComponent);
      // dialogRef.afterClosed().subscribe(()=> {
          
      //   this.router.navigate(['profile/user/user-foodsubscription-list']);
      // })
    }, (err)=> { 

    })
  }

  subscribePlan() {

    ;
    if (this.subscriptionPlanDetails.totalPayableAmount === 0) {
      this.doPaymentWithIstharaPoints();
    } else {
      this.doPaymentWithCashFree();
    }
  }

}
