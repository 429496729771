import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../../landing-component.service';
import { HelpdeskService } from '../helpdesk.service';
import { RateServiceComponent } from '../rate-service/rate-service.component';

@Component({
  selector: 'app-helpdesk-listing',
  templateUrl: './helpdesk-listing.component.html',
  styleUrls: ['./helpdesk-listing.component.scss']
})
export class HelpdeskListingComponent implements OnInit {

  constructor(
    private _router: Router,
    private _dbService : LandingComponentService,
    private _helpeDeskService : HelpdeskService,
    private _snackbar : MatSnackBar,
    private bottomSheet: MatBottomSheet,
  ) { }

  pageNo : number = 0;
  size: number = 10;

  ngOnInit(): void {
    this.getAllUserIssues('');
  }

  scrHeight;
  scrWidth;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 600) {
      this._router.navigate(['account/user'], {queryParams: {path: 'helpdesk'}});
    }
  }
  
  issueTypeListing = [
    { name: "All Issues", value: "ALL_ISSUES", status: true },
    { name: "Open Issues", value: "OPEN_ISSUES", status: false },
    { name: "Inprogress Issues", value: "INPROGRESS_ISSUES", status: false },
    { name: "Resolved Issues", value: "RESOLVED_ISSUES", status: false },
    { name: "Closed Issues", value: "CLOSED_ISSUES", status: false },
  ];
  openIssueTypeListing = [
    { name: "Open Issues", value: "OPEN_ISSUES", status: true },
    { name: "Reopened Issues", value: "REOPENED_ISSUES", status: false },
  ]
  issueListing = [];
  getAllLoader : boolean = false;
  selectedIssueType = 'ALL_ISSUES';
  selectedIssueInOpenIssue: string = "OPEN_ISSUES";

  getAllUserIssues(status) {
    this.getAllLoader = true;
    this._helpeDeskService.getAllHelpDeskTickets(this._dbService.getUserId(),this.pageNo, 500,status).subscribe(
      (res:any) => {
        this.issueListing = res.data;
        this.getAllLoader = false;
      },
      (err) => {
        this.getAllLoader = false;
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  getAllUserOpenIssues() {
    this.issueListing = [];
  }
  getAllUserResolvedIssue() {
    this.issueListing = [];
  }

  selectIssueType(issueType) {
    this.selectedIssueType = issueType.value;
    this.issueTypeListing.forEach((type)=> {
      type.status = false;
    })
    issueType.status = true;

    if (issueType.value === "ALL_ISSUES") {
      this.getAllUserIssues('');
    } else if (issueType.value === "OPEN_ISSUES") {
      this.getAllUserIssues('NEW');
    } else if (issueType.value === "INPROGRESS_ISSUES") {
      this.getAllUserIssues('IN_PROGRESS');
    } else if (issueType.value === 'RESOLVED_ISSUES') {
      this.getAllUserIssues('RESOLVED');
    } else if (issueType.value === 'CLOSED_ISSUES') {
      this.getAllUserIssues('CLOSED');
    } 
  }
  changeOpenIssue() {
    if (this.selectedIssueInOpenIssue === "REOPENED_ISSUES") {
      this.getAllUserIssues('RE_OPENED');
    } else if (this.selectedIssueInOpenIssue === "OPEN_ISSUES") {
      this.getAllUserIssues('OPEN');
    }
  }

  closeTicket(helpDeskDetails) {

    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: {ticketId: helpDeskDetails.helpdeskTicketId},
    });
    _bottomSheetRef.afterDismissed().subscribe((data)=> {
      
      if (data) {
        this._helpeDeskService.closeTicketById(helpDeskDetails.helpdeskTicketId).subscribe((response)=> {
        
          if (this.selectedIssueType === "ALL_ISSUES") {
            this.getAllUserIssues('');
          } else if (this.selectedIssueType === "RESOLVED_ISSUES") {
            this.getAllUserIssues('RESOLVED');
          } 
        }, (err)=> {
          this._dbService.openSnackBarMessage("Error while Closing the Ticket");
        })
      }
    })
  }

  rateResolvedTicket(helpDeskDetails) {
    // this._helpeDeskService.setHelpdeskTicketId(helpDeskDetails.helpdeskTicketId);
    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: {ticketId: helpDeskDetails.helpdeskTicketId},
    });
    _bottomSheetRef.afterDismissed().subscribe((data)=> {
      
      if (this.selectedIssueType === "ALL_ISSUES") {
        this.getAllUserIssues('');
      } else if (this.selectedIssueType === "RESOLVED_ISSUES") {
        this.getAllUserIssues('RESOLVED');
      } 

    })
  }


  goToBack(){
    this._router.navigate([`profile/user`])
  }
  goToCategoryListingPage() {
    this._router.navigate(['profile/user/helpdesk-category'])
  }

  goToTicketDetailPage(ticketDetails) {

    this._helpeDeskService.setHelpdeskTicketId(ticketDetails.helpdeskTicketId);
    this._router.navigate(['profile/user/view-ticket'])
  }

  // rateResolvedTicket(ticketDetails) {
  //   this._helpeDeskService.setHelpdeskTicketId(ticketDetails.helpdeskTicketId);
  //   this._router.navigate(['profile/user/helpdesk-rate-ticket']);
  // }
}
