import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';

@Component({
  selector: 'app-cart-footer',
  templateUrl: './cart-footer.component.html',
  styleUrls: ['./cart-footer.component.scss']
})
export class CartFooterComponent implements OnInit {

  @Input() cartDetails;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private landingComponentService: LandingComponentService
  ) { }

  changeBottomMargin: boolean = false;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params)=> {
      if (params?.vendor || params?.type)
        this.changeBottomMargin = true;
    })
    
  }

  viewCart() {

    let siteName = this.landingComponentService.getSiteName()?.split(" ").join("-").toLowerCase() || " ";
    let foodCourtName = this.landingComponentService.getFoodCourtName()?.split(" ").join("-").toLowerCase() || " ";

    this.router.navigate([`cart/${foodCourtName}`])
  }

}
