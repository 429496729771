import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import SecureLS from 'secure-ls';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LandingComponentService {
  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private router: Router) { }

  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });
  private isFirstTimeLoading: boolean = true;
  private recentSearchesList: any[] = [];
  private isRatingCompulasoryInCurrentFC: boolean = false;
  private isRatingCompulasoryInOrderingFC: boolean = false;
  itemQuantityChange = new Subject<boolean>();
  itemQuantityChangeFromCart = new Subject<boolean>();
  sidePanelContent = new Subject<String>();

  preOrderDetails;
  preOrderMenuType;

  private notificationClicked = new Subject<void>();

  private subscriptionPlanList: any[] = [];

  private discountType : string = null;

  private selectedSubscriptionPlan;

  openSnackBarMessage(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 2 * 1000,
    });
  }
  openSnackBarMessageIMP(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  setDiscountType(discountType) {
    this.discountType = discountType;
  }
  getDiscountType() {
    return this.discountType;
  }

  setIsRatingCompulsoryInCurrentFC(isCompulsory: boolean) {
    
    this.isRatingCompulasoryInCurrentFC = isCompulsory;
  }
  getIsRatingCompulsoryInCurrentFC() {
    return this.isRatingCompulasoryInCurrentFC;
  }
  setIsRatingCompulsoryInOrderingFC(isCompulsory: boolean) {
    
    this.isRatingCompulasoryInOrderingFC = isCompulsory;
  }
  getIsRatingCompulsoryInOrderingFC() {
    return this.isRatingCompulasoryInOrderingFC;
  }

  setIsBTCUpdateRequestFlow(body: any) {
    this._lsService.set('isBTCUpdateRequestFlow', body);
  }
  getIsBTCUpdateRequestFlow() {
    const isBTCUpdateRequestFlow = this._lsService.get('isBTCUpdateRequestFlow');
    if (isBTCUpdateRequestFlow) {
      return isBTCUpdateRequestFlow;
    }
    return null;
  }

  getUserSubscriptionPlanId() {
    const userSubscriptionPlanId = this._lsService.get('userSubscriptionPlanId');
    if (userSubscriptionPlanId) {
      return userSubscriptionPlanId;
    }
    return null;
  }

  setSubscriptionPlanOrderingDate(startDate, endDate) {
    this._lsService.set('userSubscriptionDate', {
      startDate: startDate,
      endDate: endDate
    });
  }
  getSubscriptionPlanOrderingDate() {
    const userSubscriptionDate = this._lsService.get('userSubscriptionDate');
    if (userSubscriptionDate) {
      return userSubscriptionDate;
    }
    return null;
  }

  setUserSubscriptionPlanId(planId) {
    this._lsService.set('userSubscriptionPlanId', planId);
  }

  setBTCRequestId(body: any) {
    this._lsService.set('btcRequestId', body);
  }
  getBTCRequestId() {
    const btcRequestId = this._lsService.get('btcRequestId');
    if (btcRequestId) {
      return btcRequestId;
    }
    return null;
  }

  setSubscriptionPlanListing(subscriptionList) {
    this.subscriptionPlanList = subscriptionList;
  }
  getSubscriptionPlanListing() {
    return this.subscriptionPlanList;
  }

  setSelectedSubscriptionPlan(selectedFoodSubscripion) {
    this.selectedSubscriptionPlan = selectedFoodSubscripion;
  }
  getSelectedSubscriptionPlan() {
    return this.selectedSubscriptionPlan;
  }

  setIsOrderPreOrderType(body: any) {
    this._lsService.set('isPreOrder', body);
  }
  getIsOrderPreOrderType() {
    const isPreOrder = this._lsService.get('isPreOrder');
    if (isPreOrder) {
      return isPreOrder;
    }
    return null;
  }

  setHasUserBTCAccessInCurrFC(body: any) {
    this._lsService.set('hasBTCAccessInCurrFC', body);
  }
  getHasUserBTCAccessInCurrFC() {
    const hasBTCAccessInCurrFC = this._lsService.get('hasBTCAccessInCurrFC');
    if (hasBTCAccessInCurrFC) {
      return hasBTCAccessInCurrFC;
    }
    return null;
  }

  setPreOrderMenuType(body: any) {
    this._lsService.set('preOrderingMenuType', body);
  }
  getPreOrderMenuType() {
    const preOrderingMenuType = this._lsService.get('preOrderingMenuType');
    if (preOrderingMenuType) {
      return preOrderingMenuType;
    }
    return null;
  }

  setFoodcourtHasPreOrdering(body: any) {
    this._lsService.set('foodCourtHasPreOrdering', body);
  }
  getFoodcourtHasPreOrdering() {
    const foodCourtHasPreOrdering = this._lsService.get('foodCourtHasPreOrdering');
    if (foodCourtHasPreOrdering) {
      return foodCourtHasPreOrdering;
    }
    return null;
  }

  setOrderingFoodcourtHasBTC(body: any) {
    this._lsService.set('orderingFoodCourtHasBTC', body);
  }
  getOrderingFoodcourtHasBTC() {
    const orderingFoodCourtHasBTC = this._lsService.get('orderingFoodCourtHasBTC');
    if (orderingFoodCourtHasBTC) {
      return orderingFoodCourtHasBTC;
    }
    return null;
  }

  setPreOrderDetails(body: any) {
    this._lsService.set('preOrderDetails', body);
  }
  getPreOrderDetails() {
    const preOrderDetails = this._lsService.get('preOrderDetails');
    if (preOrderDetails) {
      return preOrderDetails;
    }
    return null;
  }

  addItemToRecentSearches(item) {
    this.recentSearchesList.push(item);
  }
  getAllRecentSearchesList() {
    return this.recentSearchesList;
  }
  removeRecentSearchItem(index) {
    this.recentSearchesList.splice(index, 1);
  }

  getIsFirstTimeLoading() {
    return this.isFirstTimeLoading;
  }
  setIsFirstTimeLoading(value) {
    this.isFirstTimeLoading = value;
  }

  setSiteId(body: any) {
    this._lsService.set('siteId', body);
  }
  getSiteId() {
    const siteId = this._lsService.get('siteId');
    if (siteId) {
      return siteId;
    }
    return null;
  }
  setSiteName(body: any) {
    this._lsService.set('siteName', body);
  }
  getSiteName() {
    const siteName = this._lsService.get('siteName');
    if (siteName) {
      return siteName;
    }
    return null;
  }
  setFoodCourtName(body: any) {
    this._lsService.set('foodCourtName', body);
  }
  getFoodCourtName() {
    const foodCourtName = this._lsService.get('foodCourtName');
    if (foodCourtName) {
      return foodCourtName;
    }
    return null;
  }
  setFoodCourtId(body: any) {
    this._lsService.set('foodCourtId', body);
  }
  getFoodCourtId() {
    const foodCourtId = this._lsService.get('foodCourtId');
    if (foodCourtId) {
      return foodCourtId;
    }
    return null;
  }

  setOrderFoodCourtId(body: any) {
    this._lsService.set('orderFoodCourtId', body);
  }
  getOrderFoodCourtId() {
    const foodCourtId = this._lsService.get('orderFoodCourtId');
    if (foodCourtId) {
      return foodCourtId;
    }
    return null;
  }

  setOrderId(body: any) {
    this._lsService.set('orderId', body);
  }
  setToken(body: any) {
    localStorage.setItem('token', JSON.stringify(body));
  }

  setOrderFoodCourtName(body: any) {
    this._lsService.set('orderFoodCourtName', body);
  }
  getOrderFoodCourtName() {
    const foodCourtName = this._lsService.get('orderFoodCourtName');
    if (foodCourtName) {
      return foodCourtName;
    }
    return null;
  }

  setCashFreeId(body: any) {
    this._lsService.set('cashFreeId', body);
  }
  getCashFreeId() {
    const cashFreeId = this._lsService.get('cashFreeId');
    if (cashFreeId) {
      return cashFreeId;
    }
    return null;
  }

  setVendorId(body: any) {
    this._lsService.set('vendorId', body);
  }
  getVendorId() {
    const vendorId = this._lsService.get('vendorId');
    if (vendorId) {
      return vendorId;
    }
    return null;
  }

  setUserId(body: any) {
    this._lsService.set('userId', body);
  }
  getUserId() {
    const userId = this._lsService.get('userId');
    if (userId) {
      return userId;
    }
    return null;
  }

  setUserMobile(body: any) {
    this._lsService.set('userMobile', body);
  }
  getUserMobile() {
    const userMobile = this._lsService.get('userMobile');
    if (userMobile) {
      return userMobile;
    }
    return null;
  }

  setUserAddressForBTC(body: any) {
    this._lsService.set('userAddressForBTC', body);
  }
  getUserAddressForBTC() {
    const userAddressForBTC = this._lsService.get('userAddressForBTC');
    if (userAddressForBTC) {
      return userAddressForBTC;
    }
    return null;
  }
  setUserOrderTypeForBTC(body: any) {
    this._lsService.set('orderTypeForBTC', body);
  }
  getUserOrderTypeForBTC() {
    const orderTypeForBTC = this._lsService.get('orderTypeForBTC');
    if (orderTypeForBTC) {
      return orderTypeForBTC;
    }
    return null;
  }

  setPointsToBeDeducted(body: any) {
    this._lsService.set('pointsToBeDeducted', body);
  }
  getPointsToBeDeducted() {
    const pointsToBeDeducted = this._lsService.get('pointsToBeDeducted');
    if (pointsToBeDeducted) {
      return pointsToBeDeducted;
    }
    return null;
  }

  setUserName(body: any) {
    this._lsService.set('userName', body);
  }
  getUserName() {
    const UserName = this._lsService.get('userName');
    if (UserName) {
      return UserName;
    }
    return null;
  }

  setUserEmail(body: any) {
    this._lsService.set('userEmail', body);
  }
  getUserEmail() {
    const userEmail = this._lsService.get('userEmail');
    if (userEmail) {
      return userEmail;
    }
    return null;
  }

  setFirebaseToken(token: string) {
    this._lsService.set('fb_token', token);
  }

  getFirebaseToken() {
    const fb_token = this._lsService.get('fb_token');
    if (fb_token) {
      return fb_token;
    }
    return null;
  }
  
  setUserDeviceId(body: any) {
    this._lsService.set('userDeviceId', body);
  }
  getUserDeviceId() {
    const userDeviceId = this._lsService.get('userDeviceId');
    if (userDeviceId) {
      return userDeviceId;
    }
    return null;
  }

  getAllSites() {
    return this.http.get(
      environment.baseUrl + '/foodcourt/site/v1/ist/all/sites'
    );
  }
  getSitesByLocation(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/site/v2/ist/nearby/sites`,
      data
    );
  }
  getFoodCourtBySiteId(siteId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list/${siteId}/foodCourt`
    );
  }
  getFoodCourtDetailsById(foodCourtId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodCourtId}`
    );
  }

  getHasUserBTCAccessInFoodcourt(userId: string, foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/guestOrder/btc/user/verification/V1?fcId=${foodcourtId}&userId=${userId}`
    )
  }

  clearCartDataAPI(userId) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }
  addItemToCartAPI(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/user/ist/cart`,
      data
    );
  }

  getAllFoodcourts() {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/foodCourts`
    )
  }
  searchFoodCourtByName(fcName: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/foodCourt/ist/search?name=${fcName}&pageNo=0&size=10`)
  }

  getCartData(userId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }

  removeFromLocalStorage(body: any) {
    this._lsService.remove(body);
  }

  // set paymentFor

  // Body value as given below -

  // For CartPayment : 'CART_PAYMENT'
  // For DeliveryCharges : 'DELIVERY_PAYMENT'
  // For One-click payment : 'ONE_CLICK_PAYMENT'

  setPaymentFor(body: any) {
    this._lsService.set('paymentFor', body);
  }

  // Get paymentFor
  getPaymentFor() {
    const paymentFor = this._lsService.get('paymentFor');
    if (paymentFor) {
      return paymentFor;
    }
    return null;
  }

  isAPIError: boolean = false;
  showErrorInAPIPage() {
    this.isAPIError = true;
  }

  setNotificationClick() {
    this.notificationClicked.next();
  }

  getNotificationClickObs() {
    return this.notificationClicked;
  }

  getUserNotifications(payload: any) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/notification/custom/ist/notification/history/${payload.userId}?startDate=${payload.startDate}&endDate=${payload.endDate}`
    )
  }
  getSPOClist(fcId:any,siteId:any){
    return this.http.get(
      environment.baseUrl+`/foodcourt/spoc/ist/getByFc/list?fcId=${fcId}&siteId=${siteId}`
    )
  }
  getratingdata(fcId:any,userId:any){
    return this.http.get(
      environment.baseUrl+`/foodcourt/ratingReview/ist/get/order/rating?foodCourtId=${fcId}&userId=${userId}`
    )
  }
  setReviewOrderId(body: any) {
    this._lsService.set('reviewOrderId', body);
  }
}
