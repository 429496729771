import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import SecureLS from 'secure-ls';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderSummaryService {
  constructor(private http: HttpClient) {}

  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });

  setHelpdeskFoodCourtId(body: any) {
    this._lsService.set('helpdeskFoodCourtId', body);
  }
  getHelpdeskFoodCourtId() {
    const helpdeskFoodCourtId = this._lsService.get('helpdeskFoodCourtId');
    if (helpdeskFoodCourtId) {
      return helpdeskFoodCourtId;
    }
    return null;
  }

  getPackagingCharges(orderId, fcId, orderType) {
    return this.http.get(
      environment.baseUrl+ `/foodcourt/ist/order/get/charges/?foodCourtId=${fcId}&orderId=${orderId}&orderType=${orderType}`
    )
  }

  getOrderDetailByOrderId(orderId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/getOrder/${orderId}`
    );
  }
  getRequestDetailByRequestId(requestId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/guestOrder/request?requestId=${requestId}`
    )
  }

  getPreOrderDetailsByOrderId(orderId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/getPreOrder/${orderId}`
    )
  }

  getFoodocurtByFoodcourtId(foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }

  changePickupToDelivery(orderId, address, orderType, points) {
    return this.http.post(
      environment.baseUrl +
        `/foodcourt/ist/order/convert/toDelivery?orderId=${orderId}&orderType=${orderType}&pointsToBeDeducted=${points}`,
      address
    );
  }

  getUserRewardPoints(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  createPaymentTokenForDelivery(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/delivery/token/web`,
      data
    );
  }

  getSavedAddressesOfUser(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    );
  }

  getInvoiceURL(orderId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/orders/export/pdf?orderId=${orderId}`)
  }

  getBTCOrderSummary(requestId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/guestOrder/btc/requestId?requestId=${requestId}`
    )
  }

  sendReminderBTCOrderRequest(employerId: string, requestId: string) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/ist/guestOrder/request/reminder?employerId=${employerId}&requestId=${requestId}`, {}
    )
  }
  cancelBTCOrderRequest(requestId: string) {
    return this.http.put(environment.baseUrl + `/foodcourt/ist/guestOrder/btc?requestId=${requestId}&status=CANCELLED`, {})
  }


  // downloadInvoiceOfOrder(url) {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Accept', 'application/pdf');
  //   return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' });
  //   // return this.http.get(url, { responseType: 'blob' })
  // }

}
