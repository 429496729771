import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../../user-account/user-account.service';


@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {


  constructor(private router: Router,
    private bottomSheet: MatBottomSheet,
    private landingComponentService: LandingComponentService,
    private _userAccountService: UserAccountService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,) { }

  ngOnInit(): void {
  }

  items=[
    {active:false,img:"../../../../../../../assets/images/ratings/poor-quality.svg"},
    {active:false,img:"../../../../../../../assets/images/ratings/not-good.svg"},
    {active:false,img:"../../../../../../../assets/images/ratings/average.svg"},
    {active:false,img:"../../../../../../../assets/images/ratings/very-good.svg"},
    {active:false,img:"../../../../../../../assets/images/ratings/love-it.svg"}
  ];
  activeImg=[
    {img:"../../../../../../../assets/images/ratings/poor-quality.svg"},
    {img:"../../../../../../../assets/images/ratings/not-good-selected.svg"},
    {img:"../../../../../../../assets/images/ratings/average-selected.svg"},
    {img:"../../../../../../../assets/images/ratings/very-good-selected.svg"},
    {img:"../../../../../../../assets/images/ratings/love-it-selected.svg"},
  ]
  imgUrl=[
    {img:"../../../../../../../assets/images/ratings/poor-quality.svg"},
    {img:"../../../../../../../assets/images/ratings/not-good.svg"},
    {img:"../../../../../../../assets/images/ratings/average.svg"},
    {img:"../../../../../../../assets/images/ratings/very-good.svg"},
    {img:"../../../../../../../assets/images/ratings/love-it.svg"}
  ]
  onClick(i : any)
  {
    this.items.forEach((element, index) => {
      if(i === index) {
        element.active = true;
        this.items[index].img= this.activeImg[index].img;
      } 
      else {
        element.active = false;
        this.items[index].img= this.imgUrl[index].img;
      }
    })
    // this._userAccountService.getinfo_order("RATING");
    this.router.navigate(['profile/user/order-history/rating'])
    this.bottomSheet.dismiss();
    

  }

}
