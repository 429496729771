import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { GlobalCartServiceService } from '../../../cart-services/global-cart-service.service';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../../other-components/confirmation-popup/confirmation-popup.component';
import { IndexDBService } from '../../../indexDB-storage/index-db.service';
interface UserData {
  userName: string;
  countryCode: string;
  userMobile: string;
  userEmail: string;
  userId: string;
  profileImg: string;
}
@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  constructor(
    private router: Router,
    private _userAccountService: UserAccountService,
    private _popupDbService: PopupDbService,
    private landingComponentService: LandingComponentService,
    private storageService: IndexDBService,
    public platform: Platform,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //console.log(navigator);
    this.getScreenSize();
    this.userId = this._popupDbService.getUserId();
    //console.log(this.platform.ANDROID, this.platform.IOS);

    if (this.landingComponentService.getIsFirstTimeLoading())
      this.openDownloadAppPopup();

    if (this.platform.ANDROID) {
      this.appDownloadLink =
        'https://play.google.com/store/apps/details?id=com.tao.isthara';
    } else if (this.platform.IOS) {
      // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
      this.appDownloadLink = 'https://tinyurl.com/5n7vn985';
    }

    this.getUserDetails();
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    if (this.scrWidth >= 600) {
      this.router.navigate(["account/user"]);
    }
  }

  showOrderHistory() {
    this.router.navigate(['profile/user/order-history']);
  }
  openDownloadAppPopup() {
    this.dialog.open(ConfirmationPopupComponent, {
      width: '350px',
      height: 'auto',
      maxWidth: '100vw',
      maxHeight: '50vh',
      data: { type: 'DOWNLOAD_APP' },
    });
  }

  appDownloadLink: string =
    'https://play.google.com/store/apps/details?id=com.tao.isthara';
  showLoader: boolean = false;
  userId: string = null;
  userDetails: UserData;
  getUserDetails() {
    this.showLoader = true;
    this._userAccountService.getUserDetailsByUserId(this.userId).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.userDetails = {
          userName: res.data.fullName,
          countryCode: res.data.countryCode,
          userMobile: res.data.mobile,
          userEmail: res.data.email,
          userId: res.data.userId,
          profileImg: res.data.profilePicture,
        };
        this.showLoader = false;
        //console.log(this.userDetails);
      },
      (err) => {
        this.showLoader = false;
        if (err.status === 403) {
          this.landingComponentService.openSnackBarMessageIMP("Login Failed!! Plz Login");
          this.clearLocalStorageData();
          setTimeout(() => {
            this.router.navigate(['auth']);
          }, 500);
        }
        //console.log('Error while getting user details');
      }
    );
  }

  logoutUser() {
    this.dialog.open(ConfirmationPopupComponent, {
      width: '90vw',
      height: 'auto',
      maxWidth: '100vw',
      maxHeight: '50vh',
      data: { type: 'LOGOUT' },
    });
  }

  showUserPoints() {
    this.router.navigate(['profile/user/points']);
  }

  showUserHelpdeskTicket() {
    this.router.navigate(['profile/user/helpdesk-list']);
  }

  goToEditPage() {
    this.router.navigate(['profile/user/edit']);
  }
  showcontactuspage(){
    this.router.navigate(['profile/user/contact-us']);
  }
  goToUserSettings() {
    this.router.navigate(['profile/user/settings']);
  }
  goToUserFoodSubscriptionList() {
    this.router.navigate(['profile/user/user-foodsubscription-list']);
  }

  clearLocalStorageData() {
    // this.landingConsoleService.setFoodCourtId(null);
    // this.landingConsoleService.setFoodCourtName(null);

    this.storageService.clearAllItemsFromCart();
    this.landingComponentService.setOrderFoodCourtId(null);
    this.landingComponentService.setOrderFoodCourtName(null);

    this.landingComponentService.setVendorId(null);
    // this.landingConsoleService.setFirebaseToken(null);
    this.landingComponentService.setUserId(null);
    this.landingComponentService.setUserName(null);
    this.landingComponentService.setUserEmail(null);
    this.landingComponentService.setUserMobile(null);
    this.landingComponentService.setOrderId(null);
    this.landingComponentService.setToken(null);

    // window.location.reload();
  }
}
