<div class="bottom-sheet-section">
  <!-- <div class="foodcourt-list-container" *ngIf="!showSearchBar">
    <div class="bottom-sheet-header">
      <div class="heading-text">
        Select the Foodcourt from
        <span class="sitename">{{ siteName | titlecase }}</span>
      </div>
    </div>
    <div class="foodcourt-list">
      <ng-container *ngFor="let foodCourt of foodCourtList">
        <div class="foodcourt-item" (click)="selectFoodCourt(foodCourt)">
          <div>
            <span>{{ foodCourt?.foodCourtName | titlecase }}</span>
            <mat-icon *ngIf="foodCourt?.status">done</mat-icon>
          </div>
          <hr />
        </div>
      </ng-container>
    </div>
  </div> -->
  <div class="site-list">
    <div class="second-heading-text" *ngIf="!showSearchBar; else showSearch">
      <span>Select your Favourite FoodCourt</span>
      <mat-icon (click)="toggleSearchBar()">search</mat-icon>
    </div>
    <ng-template #showSearch>
      <div class="search-bar-container">
        <div class="input-container">
          <input type="text" placeholder="Search by FoodCourt Name" [(ngModel)]="searchFoodCourtName" (ngModelChange)="_searchFoodCourtByName()">
        </div>
        <div class="close-btn-container" (click)="toggleSearchBar()">
          <mat-icon>close</mat-icon>
        </div>
        <div class="search-btn-container" (click)="searchFoodCourtByName()">
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </ng-template>
    <!-- <ng-container *ngFor="let site of filteredSiteList">
      <div
        class="site-container"
        [ngClass]="{ 'site-active': site?.status }"
        (click)="getFoodCourtBySiteId(site.siteId)"
      >
        <div class="site-image">
          <img *ngIf="site?.siteImageUrl" loading="lazy" [src]="site?.siteImageUrl" alt="" />
          <img
            *ngIf="!site?.siteImageUrl"
            src="../../../../../../assets/images/dummy/dummy_img.png"
            alt=""
          />
        </div>
        <div class="site-content">
          <div
            class="site-name heading-text"
            [ngClass]="{ 'site-name-active': site.status }"
          >
            {{ site.siteName | titlecase }}
          </div>
          <div
            class="site-location small-text"
            [ngClass]="{ 'site-address-active': site.status }"
          >
            {{ site?.siteAddress | titlecase }}
          </div>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngFor="let foodcourt of filteredFoodCourtList">
      <div
        class="site-container"
        [ngClass]="{ 'site-active': foodcourt?.status }"
        (click)="foodCourtSelect(foodcourt)"
      >
        <div class="site-image">
          <img *ngIf="foodcourt?.siteImageUrl" loading="lazy" [src]="foodcourt?.siteImageUrl" alt="" />
          <img
            *ngIf="!foodcourt?.siteImageUrl"
            src="../../../../../../assets/images/dummy/dummy_img.png"
            alt=""
          />
        </div>
        <div class="site-content">
          <div
            class="site-name heading-text"
            [ngClass]="{ 'site-name-active': foodcourt.status }"
          >
            {{ foodcourt.foodCourtName | titlecase }}
          </div>
          <div
            class="site-location small-text"
            [ngClass]="{ 'site-address-active': foodcourt.status }"
          >
            {{ foodcourt?.siteAddress | titlecase }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
