import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertLocalDate'
})
export class ConvertLocalDatePipe implements PipeTransform {

  // formate: 12:00:00
  transform(localTime: string) {
    let localHourString = Number(localTime.split(':')[0]) % 13;
    let localMinuteString = localTime.split(':')[1];
    let formate = (localHourString > 12 ? 'am' : 'pm');
    return localHourString + " : " + localMinuteString + " " + formate;
  }

}
