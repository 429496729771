import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss'],
})
export class UserAddressComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<UserAddressComponent>,
    private landingComponentService: LandingComponentService,
    private userAccountService: UserAccountService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private _map: MapsAPILoader
  ) {
    this.mapsAPILoader = mapsAPILoader;
    this.ngZone = ngZone;
    this.wrapper = wrapper;
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit(): void {
    // this.getLocation();
    this.userId = this.landingComponentService.getUserId();
  }

  @ViewChild('AgmMap') AgmMap: any;
  @ViewChild('addresstext') addresstext: ElementRef;
  geocoder: any;
  zoom = 13;
  manualLat: number;
  manualLng: number;
  icon = {
    url: '../../../../../../../assets/images/common/home.svg',
    scaledSize: { height: 40, width: 40 },
  };

  userId: string;
  customerMobile: number;
  customerName: string;

  addressTitles = [
    { name: 'College', active: true },
    { name: 'Work', active: false },
    { name: 'Hostel', active: false },
    { name: 'Other', active: false },
  ];

  selectAddressTitle(title) {
    this.addressTitles.forEach((element) => {
      if (element.name === title) {
        element.active = true;
        this.locationDetail.geoLocation.title = title;
      } else {
        element.active = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  locationDetail = {
    addressLine: '',
    geoLocation: {
      latitude: 17.385,
      longitude: 78.4867,
      draggable: true,
      label: '',
      title: 'College',
    },
  };

  // async getLocation() {
  //   if (navigator.geolocation) {
  //     await navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         this.locationDetail.geoLocation.latitude = position.coords.longitude;
  //         this.locationDetail.geoLocation.longitude = position.coords.latitude;
  //       },
  //       () => {
  //         //console.log('IMPORTANT: User has blocked geolocation');
  //         this.locationDetail.geoLocation.latitude = 0;
  //         this.locationDetail.geoLocation.latitude = 0;
  //       }
  //     );
  //   } else {
  //     //console.log('No support for geolocation');
  //   }
  // }

  private getPlaceAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          componentRestrictions: { country: 'IN' },
          types: ['establishment', 'geocode'], // 'establishment' / 'address' / 'geocode'
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();

        this.ngZone.run(() => {
          this.locationDetail.geoLocation.latitude =
            place.geometry.location.lat();
          this.locationDetail.geoLocation.longitude =
            place.geometry.location.lng();
          this.locationDetail.addressLine = place.formatted_address;

          // this.decomposeAddressComponents([place]);
        });
        this.zoom = 18;
      });
    });
  }
  // decomposeAddressComponents(addressArray) {
  //   if (addressArray.length == 0) return false;
  //   let address = addressArray[0].address_components;
  //   this.spaceInfoData.address_level_1 = '';
  //   for (let element of address) {
  //     if (element.length == 0 && !element['types']) {
  //       continue;
  //     }

  //     if (element['types'].indexOf('street_number') > -1) {
  //       this.spaceInfoData.address_level_1 = element['long_name'];
  //       continue;
  //     }
  //     if (element['types'].indexOf('route') > -1) {
  //       this.spaceInfoData.address_level_1 += ', ' + element['long_name'];
  //       continue;
  //     }
  //     //sublocality_level_1
  //     if (element['types'].indexOf('sublocality_level_1') > -1) {
  //       this.spaceInfoData.locationDetail.locality = element['long_name'];
  //     }
  //     if (element['types'].indexOf('locality') > -1) {
  //       this.spaceInfoData.address_level_1 += ', ' + element['long_name'];
  //       this.spaceInfoData.locationDetail.city = element['long_name'];
  //       continue;
  //     }
  //     if (element['types'].indexOf('administrative_area_level_1') > -1) {
  //       this.spaceInfoData.address_level_1 += ', ' + element['long_name'];
  //       this.spaceInfoData.locationDetail.state = element['long_name'];
  //       continue;
  //     }
  //     if (element['types'].indexOf('country') > -1) {
  //       this.spaceInfoData.address_level_1 += ', ' + element['long_name'];
  //       this.spaceInfoData.address_country = element['long_name'];
  //       this.spaceInfoData.locationDetail.country = element['long_name'];
  //       continue;
  //     }
  //     if (element['types'].indexOf('postal_code') > -1) {
  //       this.spaceInfoData.address_level_1 += ', ' + element['long_name'];
  //       this.spaceInfoData.address_zip = element['long_name'];
  //       this.spaceInfoData.locationDetail.zipCode = element['long_name'];
  //       continue;
  //     }
  //   }
  //   this.ngZone.run(() => {
  //     this.spaceInfoData.locationDetail.addressLine1 =
  //       addressArray[0].name + ', ' + addressArray[0].vicinity;
  //     this.spaceInfoData.locationDetail.locality =
  //       this.spaceInfoData.locationDetail.locality;

  //     // this.data.name = this.location.address_level_1;
  //   });
  // }

  onMapReady(map) {
    //console.log('EVENT', map);
    map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
        style: google.maps.ZoomControlStyle.DEFAULT,
      },
    });
    //console.log(map);
  }

  geoLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((p) => {
        //// //console.log("what location is comming: ", p);
        this.locationDetail.geoLocation.latitude = p.coords.latitude;
        this.locationDetail.geoLocation.longitude = p.coords.longitude;
      });
    } else {
      // //console.log(`Geolocation is not supported by this browser.`);
    }
  }
  markerDragEnd(m: any) {
    this.manualLat = m.coords.lat;
    this.manualLng = m.coords.lng;
    this.locationDetail.geoLocation.latitude = m.coords.lat;
    this.locationDetail.geoLocation.longitude = m.coords.lng;
    this.findAddressByCoordinates();
  }

  // changeLatLng(e) {
  //   this.manualSearch(
  //     e,
  //     this.locationDetail.geoLocation.latitude,
  //     this.locationDetail.geoLocation.longitude
  //   );
  // }
  // manualSearch(e, lat: number, lng: number) {
  //   this.locationDetail.geoLocation.latitude = Number(lat); // 17.439171707562696 / 78.44837284603271;
  //   this.locationDetail.geoLocation.longitude = Number(lng); // 78.44837284603271;
  //   this.findAddressByCoordinates();
  // }
  findAddressByCoordinates() {
    this.geocoder.geocode(
      {
        location: {
          lat: this.locationDetail.geoLocation.latitude,
          lng: this.locationDetail.geoLocation.longitude,
        },
      },
      (results, status) => {
        // this.decomposeAddressComponents(results);
      }
    );
    //console.log(this.geocoder);
  }

  loading: boolean = false;
  addNewAddress() {
    this.loading = true;

    let data = {
      address: this.locationDetail.addressLine,
      addressTitle: this.locationDetail.geoLocation.title,
      geolocation: {
        latitude: this.locationDetail.geoLocation.latitude,
        longitude: this.locationDetail.geoLocation.longitude,
      },
      mobile: this.customerMobile,
      name: this.customerName,
      userId: this.userId,
    };
    //console.log(data);

    this.userAccountService.addNewAddressOfuser(data).subscribe(
      (res: any) => {
        this.loading = false;
        this.landingComponentService.openSnackBarMessage(
          'Successfully Added New Address',
          'Ok'
        );
        this.dialogRef.close('success');
        //console.log(res);
      },
      (err) => {
        this.loading = false;
        this.landingComponentService.openSnackBarMessage(
          'Failed to add address',
          'Close'
        );
        //console.log(err);
      }
    );
  }

  closePopUp() {
    this.dialogRef.close();
  }
}
