<div mat-dialog-content class="dialog-box">
    <div class="users" *ngFor="let item of staffdetails">
        <div class="userimg">
            <img src="../../../../../../../assets/images/common/user.svg">
        </div>
        <div class="info">
            <div class="name">{{item.staffName}}</div>
            <div class="contact">
                <div class="call-logo">
                    <img src="../../../../../../../assets/images/account-page-tab/contact_us.svg">
                </div>
                <div class="phoneno">
                    {{item.mobile.mobile}}
                </div>
                <div class="emailid">
                    <div class="email-logo">
                        <img src="../../../../../../../assets/images/common/email.svg">
                    </div>
                    <div class="email">
                        {{item.emailId}}
                    </div>
                </div>
            </div>
        </div>
    <ng-template #DefaultIstharaMobile>
        <div class="users">
            <div class="userimg">
                <img src="../../../../../../../assets/images/common/user.svg">
            </div>
            <div class="info">
                <div class="name">Isthara FoodCourt</div>
                <div class="contact">
                    <div class="call-logo">
                        <img src="../../../../../../../assets/images/account-page-tab/contact_us.svg">
                    </div>
                    <div class="phoneno">
                        <a href="tel: 8790858581"></a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #blankscreen>
    <div mat-dialog-content class="dialog-box">
        <div class="empty-users">
            <div class="detail-container">
                <div class="information">
                    <div class="userimg">
                        <img src="../../../../../../../assets/images/common/user.svg">
                    </div>
                    <div class="name">Isthara Food Court</div>
                </div>
                <div class="information">
                <div class="call-logo">
                    <img src="../../../../../../../assets/images/account-page-tab/contact_us.svg">
                </div>
                <div class="phoneno">8790858581</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>