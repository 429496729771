<div class="list-user-address-container">
  <div class="user-address__header">
    <div class="back-btn">
      <img
        src="../../../../../../../assets/images/common/back_arrow.svg"
        alt=""
        (click)="goToPreviousPage()"
      />
    </div>
    <div class="heading-text">Saved Locations</div>
  </div>

  <div class="user-address-list">
    <ng-container *ngFor="let address of addressList">
      <div class="address-card" (click)="selectAddress(address)">
        <div class="image-section">
          <img
            src="../../../../../../../assets/images/cart/default-address.svg"
            alt=""
          />
        </div>
        <div class="address-section">
          <div class="address-section-heading">
            <span *ngIf="address.addressTitle"
              >Deliver to {{ address.addressTitle }}</span
            >
          </div>
          <div class="address-section-text">{{ address.address }}</div>
        </div>
        <!-- <div class="is-address-selected" *ngIf="address.isSelected">
          <img
            src="../../../../../../../assets/images/common/edit_icon.svg"
            alt=""
          />
        </div> -->
      </div>
    </ng-container>
  </div>

  <div class="add-new-location" (click)="goToaddNewAddressPage()">
    +Add Another Location
  </div>
</div>
