import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../../landing-component.service';
import { HelpdeskSuccessPopupComponent } from '../helpdesk-success-popup/helpdesk-success-popup.component';
import { HelpdeskService } from '../helpdesk.service';
import { RateServiceComponent } from '../rate-service/rate-service.component';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss']
})
export class ViewTicketComponent implements OnInit {

  constructor(
    private landingComponentService: LandingComponentService,
    private helpdeskService: HelpdeskService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
  ) { }

  helpDeskTicketId: string;
  helpDeskDetails;

  closeIssueLoader: boolean = false;
  reopenIssueLoader: boolean = false;
  showReopenButton: boolean = true;

  ticketDetailScreenLoader: boolean = false;
  
  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.helpDeskTicketId = this.helpdeskService.getHelpdeskTicketId();
    this.getTicketDetailsById();
  }

  getTicketDetailsById() {
    this.ticketDetailScreenLoader = true;
    this.helpdeskService.getHelpDeskDetails(this.helpDeskTicketId).subscribe((response: any)=> {
      this.helpDeskDetails = response.data;

      this.helpDeskDetails.timeline?.forEach((timeline)=> {
        if (timeline.actionType.toLowerCase().includes("re-opened")) {
          this.showReopenButton = false;
        }
      })

      this.ticketDetailScreenLoader = false;
    }, (err)=> {
      this.ticketDetailScreenLoader = false;
    })
  }

  goToHelpDeskListingPage() {
    this.router.navigate(['profile/user/helpdesk-list']);
  }

  goToViewHelpdeskImagesPage(imageType) {
    if (this.scrWidth < 600) {
      if (imageType === 'resolvedImages') {
        this.router.navigate([`/profile/user/view-image/resolved-image`]);
      } else {
        this.router.navigate([`/profile/user/view-image/issue-image`]);
      }
    }
  }

  reopenTicket() {
    
    this.reopenIssueLoader = true;
    this.closeIssueLoader = false;
    this.helpdeskService.reopenTicketById(this.helpDeskTicketId).subscribe((response)=> {
      
      this.reopenIssueLoader = false;
      this.closeIssueLoader = false;
      this.router.navigate(['profile/user/helpdesk-list']);
    }, (err)=> {
      
      this.reopenIssueLoader = false;
      this.closeIssueLoader = false;
      this.landingComponentService.openSnackBarMessage("Failed to Reopen the Ticket");
    })
  }

  remindTicketAction() {
    let dialogRef = this.helpdeskService.openDialog(
      HelpdeskSuccessPopupComponent,
      '80vw',
      'auto',
      '100%',
      {data: {type: 'REMINDER', ticketId: this.helpDeskTicketId}})

    dialogRef.afterClosed().subscribe((data)=> {
      if (data) {
        this.landingComponentService.openSnackBarMessage("Ticket Reminder Sent");
      } else {
        this.landingComponentService.openSnackBarMessage("Failed to send Ticket Remainder");
      }
    })
  }

  closeTicket() {

    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: {ticketId: this.helpDeskTicketId},
    });
    _bottomSheetRef.afterDismissed().subscribe((data)=> {
      // this.router.navigate(['profile/user/helpdesk-list']);
      if (data) {
          this.reopenIssueLoader = false;
          this.closeIssueLoader = true;
          this.helpdeskService.closeTicketById(this.helpDeskTicketId).subscribe((response)=> {
          
            this.reopenIssueLoader = false;
            this.closeIssueLoader = false;
            this.router.navigate(['profile/user/helpdesk-list']);
          }, (err)=> {
            
            this.reopenIssueLoader = false;
            this.closeIssueLoader = false;
            this.landingComponentService.openSnackBarMessage("Error while Closing the Ticket");
          })
        }
    })
  
  }

  rateResolvedTicket() {
    // this.helpdeskService.setHelpdeskTicketId(this.helpDeskTicketId);
    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: {ticketId: this.helpDeskTicketId},
    });
    _bottomSheetRef.afterDismissed().subscribe((data)=> {
      if (data) {
        this.getTicketDetailsById();
      }
    })
  }


}
