import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { environment } from "../../../../../../../environments/environment";

@Component({
  selector: 'app-recharge-points',
  templateUrl: './recharge-points.component.html',
  styleUrls: ['./recharge-points.component.scss']
})
export class RechargePointsComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserAccountService,
    private dbStorageService: PopupDbService,
    private landingComponentService: LandingComponentService
  ) { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  paymentAmount: number;
  rechargePointsSelectionList = [
    { value: 100, status: false },
    { value: 500, status: false },
    { value: 1000, status: false },
    { value: 2000, status: false },
  ]

  selectPaymentAmountFromList(amount) {
    this.rechargePointsSelectionList.forEach((point) => {
      if (point.value === amount.value) {
        point.status = true;
      } else {
        point.status = false;
      }
    })

    this.paymentAmount = amount.value;
  }

  removeSelectedFieldStatus() {
    this.rechargePointsSelectionList.forEach((point) => {
      point.status = false;
    })
  }

  goToPointsHistoryPage() {

    this.router.navigate(["profile/user/points"]);
  }

  paymentLoader: boolean = false;
  buttonLoading: boolean = false;
  formDataView: any = {};
  payWithCashFree() {

    this.paymentLoader = true;
    let userEmailId = this.landingComponentService.getUserEmail();
    let userMobileName = this.landingComponentService.getUserMobile();
    let userId = this.landingComponentService.getUserId();
    let userName = this.landingComponentService.getUserName();

    let paymentDetails = {
      "email": (userEmailId ? userEmailId : "test@gmail.com"),
      "mobile": userMobileName,
      // "orderAmount": this.paymentAmount * 100,
      "orderAmount": this.paymentAmount * 100,
      "orderNote": "string",
      "pointsToBePaid": 0,
      "userId": userId,
      "username": userName
    }

    this.userService.createWalletPaymentToken(paymentDetails).subscribe(
      (pay: any) => {

        this.dbStorageService.setPaymentFor('WALLET_PAYMENT');
        this.dbStorageService.setOrderId(pay.data.fcOrderId);

        // this.formDataView.paymentLink = pay.data.paymentLink;
        // this.formDataView.orderId = pay.data.orderId;
        // this.formDataView.orderAmount = pay.data.orderAmount / 100;
        // this.formDataView.customerName = pay.data.payeeName;
        // this.formDataView.customerPhone = pay.data.payeeMobile;
        // this.formDataView.customerEmail = pay.data.payeeEmail;
        // this.formDataView.returnUrl = pay.data.returnUrl;
        // this.formDataView.notifyUrl = pay.data.notifyUrl;
        // this.formDataView.appId = pay.data.appId;
        // this.formDataView.signature = pay.data.orderSignature; //paymentToken
        // this.formDataView.orderCurrency = pay.data.paymentCurrency;
        // this.formDataView.orderNote = 'Order';

        // //console.log(this.formDataView);

        const paymentSessionId = pay.data.orderSignature;
        let cashFree;
        if (environment.production) {
          cashFree = new cashfreeProd.Cashfree(paymentSessionId);
        } else {
          cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
        }
        cashFree.redirect();

        // setTimeout(() => {
        //   // this.buttonLoading = false;
        //   document.forms['redirectForm'].submit();
        // }, 100);
      },
      (err) => {
        //console.log(err);
      }
    );
  }


}
