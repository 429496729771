<section class="order-rating-section">
  <div class="order-rating-header">
    <div class="back-btn">
      <img
        src="../../../../../../../assets/images/common/back_arrow.svg"
        alt=""
        (click)="goToOrderHistoryPage()"
      />
    </div>
    <div>
      <div class="heading-text">Order Id: {{ orderNumber }}</div>
      <div class="fc-rating-stars">
        <ng-container *ngFor="let _ of [].constructor(5); let i = index">
          <img
            *ngIf="selectedIcon?.value > i"
            src="../../../../../../../assets/images/ratings/rating-star-fill.svg"
            alt=""
          />
          <img
            *ngIf="selectedIcon?.value <= i || !selectedIcon"
            src="../../../../../../../assets/images/ratings/rating-star-empty.svg"
            alt=""
          />
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!showLoader; else loaderScreen">
    <div class="overall-food-review-section">
      <div class="heading-text">{{ selectedIcon?.name }}</div>
      <div class="rating-icons-big">
        <ng-container *ngFor="let icon of ratingIcons">
          <div class="rating-img-container" (click)="selectFCRatingIcon(icon)">
            <img
              *ngIf="!icon.status"
              src="../../../../../../../assets/images/ratings/{{
                icon.src
              }}.svg"
              alt=""
            />
            <img
              *ngIf="icon.status"
              src="../../../../../../../assets/images/ratings/{{
                icon.src
              }}-selected.svg"
              alt=""
            />
          </div>
        </ng-container>
      </div>
    </div>
    <div class="secondary-review-section">
      <div class="heading-text">What did it impress you with?</div>
      <div class="order-icons-big">
        <ng-container *ngFor="let icon of reasonIcons">
          <ng-container
            *ngIf="
              orderType !== 'DELIVERY' && icon.value === 'DELIVERY'
                ? false
                : true
            "
          >
            <div (click)="selectOrderReviewType(icon)">
              <div class="order-img-container">
                <img
                  *ngIf="!icon.status"
                  src="../../../../../../../assets/images/ratings/{{
                    icon.src
                  }}.svg"
                  alt=""
                  class="un-selected"
                />
                <img
                  *ngIf="icon.status"
                  src="../../../../../../../assets/images/ratings/{{
                    icon.src
                  }}-selected.svg"
                  alt=""
                />
              </div>
              <div class="small-text">{{ icon.name }}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-container *ngFor="let vendor of vendorList; let i = index">
      <div class="vendor-rating-main-section">
        <div class="vendor-rating-header">
          <img [src]="vendor?.vendorImgUrl" alt="vendor" *ngIf="vendor?.vendorImgUrl" />
          <img src="../../../../../../../assets/images/common/default_restuarant.svg" alt="" *ngIf="!vendor?.vendorImgUrl">
          <div class="vendor-name">{{ vendor.vendorName | titlecase }}</div>
        </div>
        <div class="vendor-rating-section">
          <ng-container *ngFor="let icon of vendor.ratingIcons">
            <div
              class="vendor-rating-img-container"
              (click)="selectVendorRating(icon, vendor, i)"
            >
              <img
                *ngIf="!icon.status"
                src="../../../../../../../assets/images/ratings/{{
                  icon.src
                }}.svg"
                alt=""
              />
              <img
                *ngIf="icon.status"
                src="../../../../../../../assets/images/ratings/{{
                  icon.src
                }}-selected.svg"
                alt=""
              />
            </div>
          </ng-container>
        </div>
        <div class="vendor-order-rating-section">
          <div class="vendor-order-icons-big">
            <ng-container *ngFor="let icon of vendor.reasonIcons">
              <div (click)="selectVendorOrderReviewType(icon, vendor, i)">
                <div class="order-img-container">
                  <img
                    *ngIf="!icon.status"
                    src="../../../../../../../assets/images/ratings/{{
                      icon.src
                    }}.svg"
                    alt=""
                  />
                  <img
                    *ngIf="icon.status"
                    src="../../../../../../../assets/images/ratings/{{
                      icon.src
                    }}-selected.svg"
                    alt=""
                  />
                </div>
                <div class="small-text">{{ icon.name }}</div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="item-rating-main-section">
          <ng-container
            *ngFor="let item of vendor.items; let itemIndex = index"
          >
            <div class="item-header">
              <img *ngIf="item.imgUrl" [src]="item.imgUrl" alt="" />
              <img
                *ngIf="!item.imgUrl"
                src="../../../../../../../assets/images/common/default_food_image.svg"
                alt=""
              />
              <div class="item-name">{{ item.itemName | titlecase }}</div>
            </div>
            <div class="item-rating-section">
              <div class="item-rating-img-section">
                <ng-container *ngFor="let icon of item.ratingIcons">
                  <div
                    class="item-rating-img-container"
                    (click)="selectItemRating(icon, item, i, itemIndex)"
                  >
                    <img
                      *ngIf="!icon.status"
                      src="../../../../../../../assets/images/ratings/{{
                        icon.src
                      }}.svg"
                      alt=""
                    />
                    <img
                      *ngIf="icon.status"
                      src="../../../../../../../assets/images/ratings/{{
                        icon.src
                      }}-selected.svg"
                      alt=""
                    />
                  </div>
                </ng-container>
              </div>
              <div class="item-review-text" (click)="showItemReviewFeild(item)">
                <span>Item Review</span>
                <img src="../../../../../../../assets/images/common/down_arrow.svg" alt="" *ngIf="!item.giveRating">
                <img src="../../../../../../../assets/images/common/down_arrow.svg" alt="" *ngIf="item.giveRating" class="vertical-rotate">
              </div>
              <textarea
                *ngIf="item.giveRating"
                [(ngModel)]="reviewContainer.vendorReviews[i].itemWiseRatingList[itemIndex].review"
                matInput
                placeholder="How much you liked {{ item?.itemName }}"
              ></textarea>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="bottom-section">
      <textarea
        matInput
        placeholder="How was your experience with food"
        [(ngModel)]="reviewContainer.review"
      ></textarea>
      <button
        class="submit-review"
        (click)="submitReview()"
        *ngIf="!buttonLoading; else buttonLoader"
      >
        Submit Review
      </button>
      <ng-template #buttonLoader>
        <button class="submit-review">
          <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
        </button>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #loaderScreen>
    <div class="loader-img-container">
      <img
        src="../../../../../../assets/images/common/burger_loader.gif"
        alt=""
      />
    </div>
  </ng-template>
</section>
