<section class="user-foodsubscription-listing">
    <div class="listing-heading">
        <div class="back-arrow-container" (click)="goToUserAccountPage()">
            <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
        </div>
        <div class="heading-text">Food Subscription</div>
    </div>
    <div class="foodsubsription-plan-type-list">
        <ng-container *ngFor="let subscriptionType of USER_SUBSCRIPTION_TYPE">
            <div class="plan-type" [ngClass]="{'active': subscriptionType.status}" (click)="changeUserSubscriptionListing(subscriptionType)">{{ subscriptionType.name | titlecase }}</div>
        </ng-container>
    </div>
    <div class="subscription-plan-list-container">
        <ng-container *ngIf="!mainLoader; else showLoadingScreen">
            <ng-container *ngIf="subscriptionList.length > 0; else emptyScreen">
                <ng-container *ngFor="let subscriptionPlan of subscriptionList">
                    <div class="subscription-card" (click)="openSubscriptionDetails(subscriptionPlan)">
                        <div class="subscription-card-text">
                            <img src="../../../../../../../assets/images/common/veg-item.svg" alt="">
                            {{ subscriptionPlan.title | uppercase }}
                        </div>
                        <div class="subscription-card-description">
                            <ul>
                                <li>{{ subscriptionPlan.desc | titlecase }}</li>
                            </ul>
                        </div>
                        <!-- <div class="subscription-card-timing">
                            <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                            <span>{{ subscriptionPlan.startTime | date:'shortTime' }} - {{ subscriptionPlan.endTime | date:'shortTime' }}</span>
                        </div> -->
                        <div class="subscription-card-footer">
                            <div class="subscription-price"> 
                                <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                                {{ subscriptionPlan.startDate | date }} - {{ subscriptionPlan.endDate | date }}
                            </div>
                            <button class="subscribe-btn" [ngClass]="{'expired': subscriptionPlan.subscriptionStatus === 'EXPIRED', 'active': subscriptionPlan.subscriptionStatus === 'ACTIVE'}">
                                <span>{{ subscriptionPlan.subscriptionStatus | titlecase }}</span>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #emptyScreen>
                <div class="empty-part">
                    <div class="no-image">
                    <img
                        src="../../../../../../assets/images/common/empty-order-list.svg"
                        alt="empty-image"
                    />
                    </div>
                    <div class="empty-heading">Subscription list empty</div>
                    <div class="empty-subtext">You can see your Subscription List here</div>
                    <!-- <div class="emptyrules-text">the menu</div> -->
                </div>
            </ng-template>
        </ng-container>
        <ng-template #showLoadingScreen>
            <div class="loader-img-container">
                <img
                  src="../../../../../../assets/images/common/burger_loader.gif"
                  alt=""
                />
            </div>
        </ng-template>
    </div>
</section>