<div class="transaction-history-top-section">
  <div class="header-text">Filters</div>
  <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closebtn()">
</div>
<div class="date-format">
  <div class="start-date">Startdate
  <mat-form-field appearance="outline">
    <mat-label>Select</mat-label>
    <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" >
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
</div>
<div class="end-date">Enddate
  <mat-form-field appearance="outline">
    <mat-label>Select</mat-label>
    <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  </div>
</div>
  <div class="main-status">
      <div class="occupancy">
        <div class="occupancy-text">Payment Type</div>

        <div class="down-arrow" (click)="employmentdownarrow()">
          <img
            src="./../../../../../../../assets/images/common/down_arrow.svg"
          />
        </div>
      </div>
      <div class="check-occupancy" *ngIf="displayMe">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          [(ngModel)]="rewardPointType"
          class="example-radio-group"
        >
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let season of seasons; let i = index"
            [value]="season.value"
            color="primary"
          >
            {{ season.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="button-group">
      <button
        class="apply-filter"
        (click)="applyFilter()"
        *ngIf="!isLoading"
      >
        Apply Filter
      </button>
      <button class="apply-filter" *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
      </button>
    </div>
