import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SigninSignupFlowComponent } from './components/erp-landing/authentication/signin-signup-flow/signin-signup-flow.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ErpMainInterceptor } from './components/erp-landing/authentication/services/erp-main.interceptor';
import { CountdownModule } from 'ngx-countdown';
import { LandingComponentComponent } from './components/erp-landing/landing-component/landing-component.component';
import { ConstMaterial } from './constant-material/constant-material/constant-material.module';
import { HomePageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/home-page/home-page.component';
import { SearchRestaurantsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/search-restaurants/search-restaurants.component';
import { FooterComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/footer/footer.component';
import { CartComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/cart/cart.component';
import { SiteFoodcourtListComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/site-foodcourt-list/site-foodcourt-list.component';
import { RestaurantViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/restaurant-view/restaurant-view.component';
import { ChooseMealViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/choose-meal-view/choose-meal-view.component';
import { CartFooterComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/cart-footer/cart-footer.component';
import { FilterItemsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/filter-items/filter-items.component';
import { CoupensComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/coupens/coupens.component';
import { AccountPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-page/account-page.component';
import { UserOrderHistoryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-order-history/user-order-history.component';
import { UserAddressComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-address/user-address.component';
import { OneClickPaymentComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/one-click-payment/one-click-payment.component';
import { OrderCancellationComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/order-cancellation/order-cancellation.component';
import { AccountEditComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-edit/account-edit.component';
import { ListUserAddressComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/list-user-address/list-user-address.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DisplayQrCodeComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/display-qr-code/display-qr-code.component';

import { GuestOrderingFlowComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/guest-order-components/guest-ordering-flow/guest-ordering-flow.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { environment } from '../environments/environment';

import { PaymentPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/payment-page/payment-page.component';
import { UrlNotFoundPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/url-not-found-page/url-not-found-page.component';
import { UserPointsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-points/user-points.component';
import { OrderRatingPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/order-rating-page/order-rating-page.component';
import { AuthGuard } from './components/erp-landing/authentication/services/auth.guard';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { OrderSummaryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/order-summary/order-summary.component';
import { OverallRatingPopupComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/overall-rating-popup/overall-rating-popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfirmationPopupComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/confirmation-popup/confirmation-popup.component';
import { APIErrorHandlingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/apierror-handling/apierror-handling.component';
import { AccountPageTabComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/account-page-tab/account-page-tab.component';
import { UserpointsfilterComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/userpointsfilter/userpointsfilter.component';
import { QrCodePageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/qr-code-page/qr-code-page.component';
import { DiscountDetailsBottomsheetComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/discount-details-bottomsheet/discount-details-bottomsheet.component';
import { CustomizeItemBottomsheetComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FoodPreorderComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-preorder/food-preorder.component';
import { PreorderConfirmationPopupComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-preorder/preorder-confirmation-popup/preorder-confirmation-popup.component';
import { HelpdeskListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/helpdesk-listing/helpdesk-listing.component';
import { RaiseIssueComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/raise-issue/raise-issue.component';
import { HelpdeskCategoryListComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/helpdesk-category-list/helpdesk-category-list.component';
import { ViewTicketComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/view-ticket/view-ticket.component';
import { HelpdeskSuccessPopupComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/helpdesk-success-popup/helpdesk-success-popup.component';
import { RechargePointsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/recharge-points/recharge-points.component';
import { RechargeSummaryComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/recharge-summary/recharge-summary.component';
import { RateServiceComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/rate-service/rate-service.component';
import { ImageViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/helpdesk-flow/image-view/image-view.component';
import { SuccessPopupComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-points/success-popup/success-popup.component';
import { NotificationsListComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/notifications/notifications-list/notifications-list.component';
import { NotificationDetailsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/notifications/notification-details/notification-details.component';
import { UserSettingsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-settings/user-settings.component';
import { ResturantDiscountScreenComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/discount-flow/resturant-discount-screen/resturant-discount-screen.component';
import { ContactUsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/contact-us/contact-us.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { RatingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/rating/rating.component';
import { HelpComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/help/help.component';
import { SubcriptionPlanListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/subcription-plan-listing/subcription-plan-listing.component';
import { SubcriptionPlanViewComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/subcription-plan-view/subcription-plan-view.component';
import { PlanSubscriptionPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/food-subscription-flow/plan-subscription-page/plan-subscription-page.component';
import { UserFoodsubscriptionListingComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/user-account/user-foodsubscription-flow/user-foodsubscription-listing/user-foodsubscription-listing.component';
import { ConvertLocalDatePipe } from './components/erp-landing/landing-component/utils/pipes/convert-local-date.pipe';
import { SuccessfullySubscribedPageComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/successfully-subscribed-page/successfully-subscribed-page.component';
import { DaysLeftPipe } from './components/erp-landing/landing-component/utils/pipes/days-left.pipe';
import { ChoosemealOptionComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/choosemeal-option/choosemeal-option.component';
import { SubscriptionTermsAndConditionsComponent } from './components/erp-landing/landing-component/majorFoodCourt-components/other-components/subscription-terms-and-conditions/subscription-terms-and-conditions.component';
// import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    SigninSignupFlowComponent,
    LandingComponentComponent,
    HomePageComponent,
    SearchRestaurantsComponent,
    FooterComponent,
    CartComponent,
    SiteFoodcourtListComponent,
    RestaurantViewComponent,
    ChooseMealViewComponent,
    CartFooterComponent,
    FilterItemsComponent,
    CoupensComponent,
    AccountPageComponent,
    UserOrderHistoryComponent,
    UserAddressComponent,
    OneClickPaymentComponent,
    OrderCancellationComponent,
    AccountEditComponent,
    ListUserAddressComponent,
    DisplayQrCodeComponent,
    GuestOrderingFlowComponent,
    PaymentPageComponent,
    UrlNotFoundPageComponent,
    UserPointsComponent,
    OrderRatingPageComponent,
    OrderSummaryComponent,
    OverallRatingPopupComponent,
    ConfirmationPopupComponent,
    APIErrorHandlingComponent,
    AccountPageTabComponent,
    UserpointsfilterComponent,
    QrCodePageComponent,
    DiscountDetailsBottomsheetComponent,
    CustomizeItemBottomsheetComponent,
    FoodPreorderComponent,
    PreorderConfirmationPopupComponent,
    HelpdeskListingComponent,
    RaiseIssueComponent,
    HelpdeskCategoryListComponent,
    ViewTicketComponent,
    HelpdeskSuccessPopupComponent,
    RechargePointsComponent,
    RechargeSummaryComponent,
    RateServiceComponent,
    ImageViewComponent,
    SuccessPopupComponent,
    NotificationsListComponent,
    NotificationDetailsComponent,
    UserSettingsComponent,
    ResturantDiscountScreenComponent,
    ContactUsComponent,
    RatingComponent,
    HelpComponent,
    SubcriptionPlanListingComponent,
    SubcriptionPlanViewComponent,
    PlanSubscriptionPageComponent,
    UserFoodsubscriptionListingComponent,
    ConvertLocalDatePipe,
    SuccessfullySubscribedPageComponent,
    DaysLeftPipe,
    ChoosemealOptionComponent,
    SubscriptionTermsAndConditionsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConstMaterial,
    HttpClientModule,
    CommonModule,
    CountdownModule,
    NgOtpInputModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places'],
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    SlickCarouselModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatSidenavModule,
    // MatDialogModule
  ],
  providers: [
    GoogleMapsAPIWrapper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErpMainInterceptor,
      multi: true,
    },
    AuthGuard,

    AsyncPipe,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
