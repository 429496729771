<div class="container"> 
    <div class="header">
        <div class="back-arrow">
            <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="Back Arrow" (click)="goToProfilePage()"/>
        </div>
        <div class="contact">
            Contact Us
        </div>
    </div>
    <div class="list">
        <mat-form-field appearance="outline" class="formfill">
            <mat-select [(ngModel)]="selected_name">
                <mat-option [value]="item.foodCourtId" *ngFor="let item of fcdetails;let i =index" (click)="gettinginfo(i)">{{item.foodCourtName | titlecase}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="detailinfo" *ngIf="details.length>0">
        <div class="detailslist" *ngFor="let item of details">
            <div class="detail-container">
                <div class="information">
                    <div class="info">{{item.staffName | titlecase}}</div>
                    <div class="image">
                        <img  src="../../../../../../../assets/images/common/user 1.svg">
                    </div>
                </div>
                <hr>
                <div class="information">
                    <a href="tel: {{ item.mobile.mobile }}"></a>
                    <!-- <div class="info">{{}}</div> -->
                <div class="image">
                    <img src="../../../../../../../assets/images/common/phonecall.svg">
                </div>
                </div>
                <hr>
                <div class="information">
                    <div class="info">{{item.emailId}}</div>
                    <div class="image">
                        <img src="../../../../../../../assets/images/common/email.svg">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="nulldetailsdiv" *ngIf="details.length===0">
        <div class="detailslist">
            <div class="detail-container">
                <div class="information">
                    <div class="image">
                        <img  src="../../../../../../../assets/images/common/user 1.svg">
                    </div>
                    <div class="info">Isthara Food Court</div>
                </div>
                <hr>
                <div class="information">
                    <div class="image">
                        <img src="../../../../../../../assets/images/common/phonecall.svg">
                    </div>
                    <div class="info">8790858581</div>
                </div>
            </div>
        </div>
    </div>
</div>