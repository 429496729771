import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { LandingComponentService } from '../../../landing-component.service';
import { OtherComponentService } from '../other-component.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { environment } from "../../../../../../../environments/environment";

@Component({
  selector: 'app-one-click-payment',
  templateUrl: './one-click-payment.component.html',
  styleUrls: ['./one-click-payment.component.scss'],
})
export class OneClickPaymentComponent implements OnInit {
  constructor(

    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _otherComponentService: OtherComponentService,
    private landingComponentService: LandingComponentService
  ) { }

  ngOnInit(): void {
    this._otherComponentService.getUserRewardPoints(this.data.userId).subscribe(
      (res: any) => {
        this.userData.istharaPoints = res.data;
        this.pointsToggleCheck = true;
        this.togglePointsValue();
      },
      (err) => {
        //console.log('Error while getting user points');
      }
    );
  }

  pointsToggleCheck: boolean = true;
  userData = {
    istharaPoints: 0,
    amtPayable: 0,
  };

  togglePointsValue() {
    if (this.userData.istharaPoints) {
      if (this.pointsToggleCheck) {
        this.userData.amtPayable =
          this.data.billDetails.totalPrice - this.userData.istharaPoints;
        if (this.userData.amtPayable < 0) {
          this.userData.amtPayable = 0;
        }
      } else {
        this.userData.amtPayable = this.data.billDetails.totalPrice;
      }
    } else {
      this.userData.amtPayable = this.data.billDetails.totalPrice;
    }
  }

  loading: boolean = false;
  doPaymentForOneClick() {

    this.loading = true;
    const userName = this.landingComponentService.getUserName();
    const userMobile = this.landingComponentService.getUserMobile();
    const userEmail = this.landingComponentService.getUserEmail();

    const data = {
      address: null,
      billDetails: {
        breakup: {
          // 'Taxes & charge': this.data.billDetails.tax * 100,
          // 'Item Total': this.data.billDetails.actualPrice * 100,
          'Taxes & charge': this.data.billDetails.tax,
          'Item Total': this.data.billDetails.actualPrice,
          'Item Discount': null,
          'Delivery Charges': 0,
          // 'Packing Charges': this.data.billDetails.packagingCharges * 100
          'Packing Charges': this.data.billDetails.packagingCharges
        },
        couponCode: null,
        couponId: null,
        // employeePrice: Number((this.data.billDetails.totalPrice * 100).toFixed(2)),
        employeePrice: Number((this.data.billDetails.totalPrice).toFixed(2)),
        // finalPrice:
        //   Number(((this.data.billDetails.actualPrice + this.data.billDetails.tax) * 100).toFixed(2)),
        finalPrice:
          Number(((this.data.billDetails.actualPrice + this.data.billDetails.tax)).toFixed(2)),
      },
      platform: "WEB",
      email: (userEmail ? userEmail : "test@gmail.com"),
      foodCourtId: this.data.orderDetails.foodCourtId,
      deliveryAvailable: false,
      itemsList: [
        {
          imgUrl: this.data.orderDetails.itemImageUrl,
          inStock: null,
          itemId: this.data.orderDetails.id,
          itemName: this.data.orderDetails.name,
          price: this.data.orderDetails.markUpPrice,
          taxPerItem: this.data.orderDetails.taxValue,
          taxPercentPerItem: this.data.orderDetails.taxPercentPerItem,
          packagingCharge: this.data.orderDetails.packagingCharge,
          veg: null,
          // mrpItem: this.data.orderDetails.mrpItem,
          markUpPrice: this.data.orderDetails.markUpPrice,
          markUpTaxPerItem: (this.data.orderDetails.markUpTaxValue ? this.data.orderDetails.markUpTaxValue : this.data.orderDetails.markUpTaxPerItem),
          markUpTotalPrice: this.data.orderDetails.markUpPrice * this.data.orderDetails.itemQuantity,
          markUpTotalTax: (this.data.orderDetails.markUpTaxValue ? this.data.orderDetails.markUpTaxValue : this.data.orderDetails.markUpTaxPerItem) * this.data.orderDetails.itemQuantity,

          taxCategory: this.data.orderDetails.taxCategory,
          vendorId: this.data.orderDetails.vendorId,
          vendorName: this.data.orderDetails.vendorName,
          itemQuantity: 1,
          foodCourtId: this.data.orderDetails.foodCourtId,
          itemStatus: "IN_STOCK",
          itemCategory: this.data.orderDetails.itemCategory
        },
      ],
      mealCategory: this.data.orderDetails.mealCategory[0],
      mobile: userMobile,
      orderType: 'DINE_IN',
      paymentBY: 'PARTIAL_PAYMENT',
      userId: this.data.userId,
      userName: userName,
    };

    if (this.userData.amtPayable === 0) {
      this.payWithIstharaPoints(data);
    } else {
      const mobilePaymentToken = {
        email: (userEmail ? userEmail : "test@gmail.com"),
        mobile: userMobile,
        // orderAmount: Math.ceil(Number((this.userData.amtPayable * 100).toFixed(2))),
        orderAmount: Math.ceil(Number((this.userData.amtPayable * 100).toFixed(2))),
        orderNote: 'Order',
        userId: this.data.userId,
        userName: userName,
        pointsToBePaid: (this.pointsToggleCheck ? this.userData?.istharaPoints : 0)
      };
      this.payWithCashfree(data, mobilePaymentToken);
    }
  }

  payWithIstharaPoints(data) {
    const isPointsPayment = true;

    this._otherComponentService
      .payForOneClickOrderWithPoints(data, isPointsPayment)
      .subscribe(
        (res: any) => {
          this.loading = false;
          //console.log(res);
          this.landingComponentService.setOrderId(res.data.orderId);
          this.bottomSheetRef.dismiss(res.data);
        },
        (err) => {
          this.loading = false;
          this.landingComponentService.setOrderId(null);
          //console.log(err);
        }
      );
  }

  formDataView: any = {};
  payWithCashfree(cartItems, mobilePaymentToken) {
    const payload = {
      cartItems: cartItems,
      mobilePaymentToken: mobilePaymentToken,
    };

    this._otherComponentService
      .payForOneClickOrderWithCashfree(payload)
      .subscribe(
        (pay: any) => {

          this.landingComponentService.setPaymentFor('ONE_CLICK_PAYMENT');
          this.landingComponentService.setOrderId(pay.data.fcOrderId);

          // this.formDataView.paymentLink = pay.data.paymentLink;
          // this.formDataView.orderId = pay.data.orderId;
          // this.formDataView.orderAmount = pay.data.orderAmount;
          // this.formDataView.customerName = pay.data.payeeName;
          // this.formDataView.customerPhone = pay.data.payeeMobile;
          // this.formDataView.customerEmail = pay.data.payeeEmail;
          // this.formDataView.returnUrl = pay.data.returnUrl; //pay.data.returnUrl; //http://localhost:4200/#/amount/${this.paymentObj.bookingId}
          // this.formDataView.notifyUrl = pay.data.notifyUrl;
          // this.formDataView.appId = pay.data.appId;
          // this.formDataView.signature = pay.data.orderSignature; //paymentToken
          // this.formDataView.orderCurrency = pay.data.paymentCurrency;
          // this.formDataView.orderNote = 'Order';

          const paymentSessionId = pay.data.orderSignature;
          let cashFree;
          if (environment.production) {
            cashFree = new cashfreeProd.Cashfree(paymentSessionId);
          } else {
            cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
          }
          cashFree.redirect();


          // setTimeout(() => {
          //   this.loading = false;
          //   document.forms['redirectForm'].submit();
          // }, 100);
        },
        (err) => {
          this.loading = false;
          //console.log(err);
          this.landingComponentService.setOrderId(null);
        }
      );
  }

  closeOneClickPayment() {
    this.bottomSheetRef.dismiss();
  }
}
