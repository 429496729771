<section class="guest-ordering-section">
  <div class="guest-ordering-header">
    <div class="back-btn">
      <img src="../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToPreviousPage()" />
    </div>
    <div class="heading-text" *ngIf="shownSection === 'GUEST_ORDERING'">
      Guest Ordering
    </div>
    <div class="heading-text" *ngIf="shownSection === 'ADD_GUESTS'">
      Add Guest Details
    </div>
    <div class="heading-text" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
      Order Preview
    </div>
  </div>

  <ng-container *ngIf="!showLoader; else loaderScreen">
    <section [ngClass]="
        shownSection === 'GUEST_ORDER_PREVIEW'
          ? 'total-guest-order-section'
          : ''
      ">
      <ng-container *ngIf="
          shownSection === 'GUEST_ORDERING' ||
          shownSection === 'GUEST_ORDER_PREVIEW'
        ">
        <!-- <ng-container *ngIf="!showLoader; else loaderScreen"> -->
        <div [ngClass]="
            shownSection === 'GUEST_ORDERING'
              ? 'one-click-order-section'
              : 'one-click-order-section-updated'
          ">
          <div class="food-details-heading" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
            Food Details
          </div>
          <div class="edit-icon" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
            <img src="../../../../../../../assets/images/common/edit_icon.svg" alt="Edit Icon"
              (click)="editDetails('GUEST_ORDERING')" />
          </div>
          <div class="one-click__card-section">
            <ng-container *ngFor="let order of menuDataForEmployees">
              <div class="one-click__card" [ngClass]="(order?.mealCategory[0] | lowercase) + '-theme'">
                <div class="meal-img">
                  <img src="../../../../../../assets/images/home-page/{{
                      order.mealCategory[0] | lowercase
                    }}.png" alt="" />
                </div>
                <div class="item-type-container">
                  <div class="image-container">
                    <img src="
                  ../../../../../../assets/images/home-page/{{
                        order.itemType.split('_').join('-') | lowercase
                      }}-item.svg
                " alt="" />
                  </div>
                  <div class="item-type">
                    {{ order.itemType.split("_").join("-") | titlecase }}
                  </div>
                </div>
                <div class="card-content">
                  <div class="card__heading-one">
                    {{ order?.mealCategory[0] | uppercase }}
                  </div>
                  <div class="card__item-list">
                    <ul>
                      <!-- <ng-container
                    *ngFor="let item of order?.itemList; let i = index"
                  > -->
                      <li>{{ order.name }}</li>
                      <!-- </ng-container> -->
                    </ul>
                    <!-- <span *ngIf="order?.isViewMore" class="view-more">View More</span> -->
                  </div>
                  <div class="card__price-section">
                    <div class="price-section">
                      <!-- <span class="discount-price" *ngIf="order?.employerMenu"
                  >&#8377;{{
                    (order?.price - order?.employerMenu[0].paidByEmployer) / 100
                      | number: "1.1-2"
                  }}</span
                > -->
                      <!-- <span class="actual-price" *ngIf="order?.price"
                        >&#8377;{{ order?.price / 100 | number: "1.2-2" }}</span
                      > -->
                      <span class="actual-price" *ngIf="order?.price">&#8377;{{ order?.price | number: "1.2-2" }}</span>
                    </div>

                    <div class="add-btn" *ngIf="
                        order.itemStatus === 'IN_STOCK' ||
                          order.itemStatus === 'VENDOR_AVAILABLE';
                        else QBOutOfStock
                      ">
                      <ng-container *ngIf="
                          !showRunTimeLoader ||
                            itemIdForLoading !== order.itemId;
                          else runTimeLoading
                        ">
                        <button class="add-item" *ngIf="order.quantity === 0" (click)="addItem(order)">
                          ADD
                        </button>
                        <div class="add-to-cart-btn" *ngIf="order.quantity !== 0" [ngClass]="
                            shownSection === 'GUEST_ORDER_PREVIEW'
                              ? 'disabled-btn'
                              : ''
                          ">
                          <button class="minus" (click)="decreaseItemCount(order)">
                            -
                          </button>
                          <span class="item-count">{{ order.quantity }}</span>
                          <button class="plus" (click)="increaseItemCount(order)">
                            +
                          </button>
                        </div>
                      </ng-container>
                    </div>
                    <ng-template #QBOutOfStock>
                      <div class="out-of-stock">Out of Stock</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="guest-ordering-footer" (click)="proceedToAddGuestPage()"
          *ngIf="shownSection !== 'GUEST_ORDER_PREVIEW'">
          <button [disabled]="selectedItems.length === 0">Proceed</button>
        </div>
        <!-- </ng-container> -->
      </ng-container>

      <ng-container *ngIf="
          shownSection === 'ADD_GUESTS' ||
          shownSection === 'GUEST_ORDER_PREVIEW'
        ">
        <!-- <ng-container *ngIf="!showLoader; else loaderScreen"> -->
        <div [ngClass]="
            shownSection === 'ADD_GUESTS'
              ? 'guest-main-form-section'
              : 'guest-main-form-section-updated'
          ">
          <div class="guest-details-form-heading" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
            Guest Details
          </div>
          <div class="edit-icon" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
            <img src="../../../../../../../assets/images/common/edit_icon.svg" alt="Edit Icon"
              (click)="editDetails('ADD_GUESTS')" />
          </div>
          <div class="guest-count-reason-form">
            <div class="full-field">
              <div class="field-heading">No. of Guests<sup>*</sup></div>
              <div class="field">
                <mat-form-field class="no-padding" appearance="outline">
                  <input matInput type="number" [(ngModel)]="guestDetailsFormValues.noOfGuests"
                    placeholder="Enter No. of Guests" required [readonly]="shownSection === 'GUEST_ORDER_PREVIEW'" />
                </mat-form-field>
              </div>
            </div>

            <div class="full-field">
              <div class="field">
                <mat-form-field class="no-padding" appearance="outline">
                  <mat-select [(ngModel)]="guestDetailsFormValues.selectedReason"
                    [disabled]="shownSection === 'GUEST_ORDER_PREVIEW'">
                    <ng-container *ngFor="let reason of guestCameReasonList">
                      <mat-option [value]="reason.value">{{
                        reason.name | titlecase
                        }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="full-field" *ngIf="guestDetailsFormValues.selectedReason === 'Other'">
              <div class="field">
                <mat-form-field class="no-padding" appearance="outline">
                  <input matInput type="text" [(ngModel)]="guestDetailsFormValues.otherReason"
                    placeholder="Enter other reason" required [readonly]="shownSection === 'GUEST_ORDER_PREVIEW'" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="guest-details-form-section">
            <div class="guest-details-form-heading" *ngIf="shownSection !== 'GUEST_ORDER_PREVIEW'">
              Enter Guest Details
            </div>

            <ng-container *ngFor="
                let guest of guestDetailsFormValues.guestDetails;
                index as i
              ">
              <div class="guest-details-block">
                <div class="delete-block-img" *ngIf="i > 0 && shownSection === 'ADD_GUESTS'">
                  <img src="../../../../../../../assets/images/common/close.svg" alt=""
                    (click)="deleteGuestDetailBlock(i)" />
                </div>
                <div class="block-heading">Guest {{ i + 1 }}</div>

                <div class="full-field">
                  <div class="field-heading">Guest Name<sup>*</sup></div>
                  <div class="field">
                    <mat-form-field class="no-padding" appearance="outline">
                      <input matInput type="text" [(ngModel)]="guest.name" placeholder="Enter Guest Name" required
                        [readonly]="shownSection === 'GUEST_ORDER_PREVIEW'" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="full-field">
                  <div class="field-heading">Mobile No<sup>*</sup></div>
                  <div class="field">
                    <mat-form-field class="no-padding" appearance="outline">
                      <input matInput type="number" [(ngModel)]="guest.mobile" placeholder="Enter Guest Mobile No"
                        pattern="^[6-9]\d{9}$|^[1-9]\d{9}$" onKeyPress="if(this.value.length==10) return false;"
                        required [readonly]="shownSection === 'GUEST_ORDER_PREVIEW'" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="full-field">
                  <div class="field-heading">Email</div>
                  <div class="field">
                    <mat-form-field class="no-padding" appearance="outline">
                      <input matInput type="email" [(ngModel)]="guest.email" placeholder="Enter Guest Email"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required
                        [readonly]="shownSection === 'GUEST_ORDER_PREVIEW'" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="add-another-buuton" (click)="addAnotherGuestBlock()"
              *ngIf="shownSection !== 'GUEST_ORDER_PREVIEW'">
              +Add Another
            </div>
          </div>
        </div>

        <div class="guest-ordering-footer" (click)="proceedToNextPage()" *ngIf="shownSection !== 'GUEST_ORDER_PREVIEW'">
          <button [disabled]="checkGuestDetailsFill()">Proceed</button>
        </div>
        <!-- </ng-container> -->
      </ng-container>
      <div class="guest-ordering-footer" *ngIf="shownSection === 'GUEST_ORDER_PREVIEW'">
        <button *ngIf="!showBtnLoader; else btnLoader" (click)="raiseGuestRequest()">
          Raise Guest Request
        </button>
        <ng-template #btnLoader>
          <button><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</button>
        </ng-template>
      </div>
    </section>
  </ng-container>
  <ng-template #loaderScreen>
    <div class="loader-img-container">
      <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
    </div>
  </ng-template>
  <ng-template #runTimeLoading>
    <button class="add-item">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
  </ng-template>
</section>