<section class="view-ticket-details-section">
    <ng-container *ngIf="!ticketDetailScreenLoader; else showScreenLoader">
        <div class="ticket-details__header-container">
            <div class="header-container__left-section" (click)="goToHelpDeskListingPage()">
                <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt="">
                <div class="header__primary-text">Track Issue</div>
            </div>
            <div class="header-container__right-section">
                <div class="ticket-status"
                [ngStyle]="{
                    color: 
                    helpDeskDetails.helpdeskDetails?.ticketStatus === 'NEW'
                    ? '#FF74AA'
                    : helpDeskDetails.helpdeskDetails?.ticketStatus === 'IN_PROGRESS'
                    ? '#FD9357'
                    : helpDeskDetails.helpdeskDetails?.ticketStatus ==='RESOLVED'
                    ? '#46948F'
                    : helpDeskDetails.helpdeskDetails?.ticketStatus === 'CLOSED'
                    ? '#28706C'
                    : helpDeskDetails.helpdeskDetails?.ticketStatus === 'RE_OPENED'
                    ? '#FF74AA'
                    : helpDeskDetails.helpdeskDetails?.ticketStatus === 'ASSIGNED'
                    ? '#FFD32D'
                    :null
                    }">
                    <img src="../../../../../../../../assets/images/help_desk/resolved-check.svg" alt="" class="issue-status-icon" *ngIf="helpDeskDetails.helpdeskDetails?.ticketStatus ==='RESOLVED' || helpDeskDetails.helpdeskDetails?.ticketStatus ==='CLOSED'">
                    <img src="../../../../../../../../assets/images/help_desk/inprogress-check.svg" alt="" class="issue-status-icon" *ngIf="helpDeskDetails.helpdeskDetails?.ticketStatus ==='IN_PROGRESS'">
                    {{ helpDeskDetails.helpdeskDetails.ticketStatus.split('_').join(' ') }}
                </div>
            </div>
        </div>
        <div class="ticket-overview-details">
            <div class="ticket-overview__left-section">
                <div class="ticket-image">
                    <img [src]="helpDeskDetails?.helpdeskDetails?.categoryImageUrl" alt="" *ngIf="helpDeskDetails?.helpdeskDetails?.categoryImageUrl">
                    
                    <div class="profile-photo">
                        <div class="display-letter" *ngIf="!helpDeskDetails?.helpdeskDetails?.categoryImageUrl">
                            {{ helpDeskDetails.helpdeskDetails.category.split("")[0] | uppercase }}
                        </div>
                    </div>
                </div>
                <div class="ticket-type-container">
                    <div class="ticket-type">{{ helpDeskDetails.helpdeskDetails?.category | uppercase }}</div>
                    <div class="ticket-title">{{ helpDeskDetails.helpdeskDetails?.subCategory | titlecase }}</div>
                </div>
            </div>
            <div class="ticket-overview__right-section">
                <span class="ticket-overview__ticket-id">Ticket Id: {{ helpDeskDetails?.helpdeskDetails?.helpdeskDisplayId }}</span>
            </div>
        </div>
        <div class="ticket-issue-details">
            <div class="ticket-issue__description-container" *ngIf="helpDeskDetails?.helpdeskDetails?.issueDescription">
                <div class="issue-details__primary-text">Issue Details</div>
                <hr />
                <div class="issue-details__secondary-text">{{ helpDeskDetails?.helpdeskDetails?.issueDescription | titlecase }}</div>
            </div>
            <div class="ticket-issue__image-container" *ngIf="helpDeskDetails?.helpdeskDetails?.helpdeskIssueImages?.length > 0">
                <div class="issue-details__primary-text">Issue Images</div>
                <hr />
                <div class="issue-image-list-container">
                    <ng-container *ngFor="let image of helpDeskDetails?.helpdeskDetails?.helpdeskIssueImages">
                        <div class="issue-image-container" (click)="goToViewHelpdeskImagesPage('issueImages')">
                            <img [src]="image" alt="">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="ticket-issue__image-container" *ngIf="helpDeskDetails?.helpdeskDetails?.helpdeskIssueResolvedImages?.length > 0">
                <div class="issue-details__primary-text">Issue Resolved Images</div>
                <hr />
                <div class="issue-image-list-container">
                    <ng-container *ngFor="let image of helpDeskDetails?.helpdeskDetails?.helpdeskIssueResolvedImages">
                        <div class="issue-image-container" (click)="goToViewHelpdeskImagesPage('resolvedImages')">
                            <img [src]="image" alt="">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="ticket-issue__description-container" *ngIf="helpDeskDetails?.helpdeskDetails?.resolvedComments">
                <div class="issue-details__primary-text">Issue Resolved Comment</div>
                <hr />
                <div class="issue-details__secondary-text">{{ helpDeskDetails?.helpdeskDetails?.resolvedComments | titlecase }}</div>
            </div>
            <div class="ticket-timeline" *ngIf="helpDeskDetails?.timeline.length > 0">
                <div class="ticket-timeline__primary-text">Issue Progress</div>
                <ng-container class="check">
                <div class="history-tl-container">
                    <ul class="tl">
                    <ng-container *ngFor="let timeline of helpDeskDetails?.timeline">
                        <li class="tl-item">
                            <div class="ticket-top-section">
                                <div class="ticket-heading">{{ timeline.heading | titlecase }}</div>
                                <!-- - <div class="ticket-created-on">{{ timeline.actionTimestamp | date }}</div> -->
                            </div>
                            <div class="ticket-bottom-section">
                                <div class="ticket-description">{{ timeline.description.split('on')[0] | titlecase }}</div>
                                <div class="ticket-status-timing">{{ timeline.actionTimestamp | date }} - {{ timeline.actionTimestamp | date:'shortTime' }}</div>
                            </div>
                        </li>
                    </ng-container>
                    </ul>
                </div>
                </ng-container>
            </div>
        </div>
        <div class="ticket-action-btn">
            <div class="ticket-open-close-btn">
                <!-- <div class="reopen-ticket-btn" *ngIf="helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' && showReopenButton">
                    <button (click)="reopenTicket()" *ngIf="!reopenIssueLoader" [disabled]="closeIssueLoader">Reopen Issue</button>
                    <button *ngIf="reopenIssueLoader">
                        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                    </button>
                </div> -->
                <!-- <div class="close-ticket-btn" *ngIf="helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' || helpDeskDetails.helpdeskDetails.ticketStatus === 'RE_OPENED'" [ngStyle]="{width: (helpDeskDetails.helpdeskDetails.ticketStatus === 'RE_OPENED' || (helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' && !showReopenButton))
                ? '100%'
                :'48%'}"> 
                    <button (click)="closeTicket()" *ngIf="!closeIssueLoader" [disabled]="reopenIssueLoader">Close Issue</button>
                    <button *ngIf="closeIssueLoader">
                        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                    </button>
                </div> -->
            </div>
            <!-- <div class="remind-ticket-btn" *ngIf="helpDeskDetails.helpdeskDetails.ticketStatus === 'NEW'">
                <button (click)="remindTicketAction()">Remind</button>
            </div> -->
            <div class="rate-issue-btn" *ngIf="(helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' || helpDeskDetails.helpdeskDetails.ticketStatus === 'CLOSED')">
                <ng-container *ngIf="helpDeskDetails?.serviceRating === 0"> 
                <!-- *ngIf="(helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' || helpDeskDetails.helpdeskDetails.ticketStatus === 'CLOSED')" -->
                    <button (click)="rateResolvedTicket()">Rate Now</button>
                </ng-container>
                <ng-container *ngIf="helpDeskDetails?.serviceRating > 0">
                    <div class="service-rating">
                        Rated :
                        <ng-container *ngFor="let rate of [].constructor(5); let index = index">
                            <img src="../../../../../../../../assets/images/ratings/rating-star-fill.svg" alt="" *ngIf="index < helpDeskDetails?.serviceRating">
                            <img src="../../../../../../../../assets/images/ratings/rating-star-empty.svg" alt="" *ngIf="index >= helpDeskDetails?.serviceRating">
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #showScreenLoader>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</section>