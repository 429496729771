import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalCartServiceService } from '../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { ConfirmationPopupComponent } from '../other-components/confirmation-popup/confirmation-popup.component';
import { CustomizeItemBottomsheetComponent } from '../other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';
import { DiscountDetailsBottomsheetComponent } from '../other-components/discount-details-bottomsheet/discount-details-bottomsheet.component';
import { FilterItemsComponent } from '../other-components/filter-items/filter-items.component';
import { RestaurantViewService } from './restaurant-view.service';

@Component({
  selector: 'app-restaurant-view',
  templateUrl: './restaurant-view.component.html',
  styleUrls: ['./restaurant-view.component.scss', './mq-restaurant-view.component.scss'],
})
export class RestaurantViewComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private landingComponentService: LandingComponentService,
    private restaurantService: RestaurantViewService,
    private globalCartService: GlobalCartServiceService,
    private indexDbService: IndexDBService,
  ) {}

  vendorDetailsAPIFailure: boolean = false;
  cuisineListAPIFailure: boolean = false;
  itemListAPIFailure: boolean = false;


  showSearchIcon: boolean = true;                   // to toggle search icon and search bar
  resturantItems: any[] = [];                      // all items in the resturants
  vendorDetails;                                  // to show vendor details in top section
  showRecommendedItemList: boolean = true;       // VARIABLE NOT IN USE
  // resturantListByCuisine: any[] = [];           // sorting resturantItems by cuisine 
  // CUISINE_LIST_COUNT: any[] = [];              // no of items in each cuisine type, used to show in menu popup
  // resturantListByCuisineCopy: any[] = [];
  CATEGORY_LIST_COUNT: any[] = [];
  discountType: string = null;
  resturantListByCategory: any = {};
  resturantListByCategoryCopy: any = {};

  CATEGORY_LIST: any[] = [];
  CATEGORIES_LIST_COUNT: any = {};
  // CUISINE_LIST = [];                               // all cuisines
  searchText: string = null;                      // text entered in search input field
  vendorId: string = null;                       // store vendorId of current resturant/vendor
  foodCourtId: string = null;                   // store foodcourtId of current vendor/resturant
  selectedMealType: string = null;             // store which meal type is selected by user of ALL, BREAKFAST, SNACKS, LUCH, DINNER
  isVegOnly: boolean = false;                 // store whether user have checked veg only toggle button
  showLoadingScreen: boolean = false;        // to show main loading screen. this variable is additionaly used to restrict other operations being performed while screen is loading. e.g: if screen is loading do not allow vegOnly to be toggled
  
  userCartData;                                 // stores all the current items stored in users cart i.e. IndexDB
  cartDetails = {                              // stores info of cart footer 
    noOfItems: 0,
    totalAmount: 0,
  };
  
  isIntoPreOrderingFlow: boolean = false;
  freebieTypeList = [];

  // used to get refrence of each section of cuisine for scrolling
  @ViewChildren("itemByCategoryList") itemByCategoryList: QueryList<ElementRef>;

  ngOnInit(): void {
    
    this.isIntoPreOrderingFlow = this.landingComponentService.getIsOrderPreOrderType();
    this.discountType = this.landingComponentService.getDiscountType();

    this.getScreenSize();
    if (this.scrWidth > 600) {

      this.landingComponentService.itemQuantityChangeFromCart.subscribe((response)=> {

        this.globalCartService.getUserCartData().then((res: any) => {
          this.userCartData = res.userCartData;
          this.cartDetails = res.cartPreviewData;
          this.getVendorFoodItemList();
        });
      })
    }

    // Vendor and Foodcourt Id is set in local storage in homepage, when we click on a resturant
    this.vendorId = this.landingComponentService.getVendorId();
    this.foodCourtId = this.landingComponentService.getFoodCourtId();

    this.getVendorDetails();
    this.getVendorFoodItemList(); // getUserCartData() -> getAllSubCategories() -> _getVendorFoodItemList_()
  }

  vendorCouponList = [];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite":true,
    "autoplay":true,
    "autoplaySpeed":2500,
    "dots":false,
    "arrows":true,
    "pauseOnHover":false
  };

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  // get all cuisine list -> with each cuisine created a key value pair in resturantListByCuisine to store item cuisine wise
  // getAllCuisines() {

  //   this.CUISINE_LIST = [];
  //   this.resturantListByCuisine = [];
  //   this.showLoadingScreen = true;
  //   this.cuisineListAPIFailure = false;

  //   this.restaurantService.getAllCuisines().subscribe(
  //     (response: any) => {
  //       response.data.map((element) => {
  //         this.resturantListByCuisine[element.toLowerCase()] = { content: [], status: true };
  //         this.CUISINE_LIST.push(element.toLowerCase());
  //       });

  //       this.showLoadingScreen = false;
  //       this._getVendorFoodItemList_();
  //     },
  //     (err) => {

  //       this.cuisineListAPIFailure = true;
  //       this.showLoadingScreen = false;
  //     }
  //   );
  // }

  CATEGORIES_LIST = [];
  SUBCATEGORY_LIST = [];
  getAllSubCategories() {
    this.showLoadingScreen = true;
    this.restaurantService.getAllSubCategories().subscribe((response:any)=> {
      response.data.content.forEach((subCategory)=> {
        if (!this.resturantListByCategory[subCategory.categoryName.toLowerCase()])
          this.resturantListByCategory[subCategory.categoryName.toLowerCase()] = { content: {}, status: true, count: 0 };

        this.resturantListByCategory[subCategory.categoryName.toLowerCase()].content[subCategory.subCategoryName.toLowerCase()] = { content: [], status: true };
      });
      this._getVendorFoodItemList_();

    }, (err)=> {

    })
  }

  getVendorDetails() {
    this.showLoadingScreen = true;
    this.vendorDetailsAPIFailure = false;

    this.restaurantService.getVendorDetails(this.vendorId).subscribe(
      (response: any) => {
        this.vendorDetails = response.data;
        this.getVendorCouponList();
        // this.showLoadingScreen = false;
      },
      (err) => {
        this.vendorDetailsAPIFailure = true;
        //console.log(err);
      }
    );
  }

  getVendorCouponList() {
    this.restaurantService.getAllCouponsForVendor(this.foodCourtId, this.vendorId).subscribe((response:any)=> {
      this.vendorCouponList = response.data.filter((coupon)=> {
        
        return ((coupon.discountType === this.discountType) || !this.discountType);
      });
      this.vendorCouponList.forEach((coupon:any)=> {
        if (coupon.freebieBuyType) {
          this.freebieTypeList.push({buyType: coupon.freebieBuyType, getType: coupon.freebieGetType, key: (coupon.freebieBuyType + "_" + coupon.freebieGetType), itemList: coupon.applicableItemsOrCategoriesList, status: false})
        }
      })
      this.freebieTypeList = [ ...new Set( this.freebieTypeList.map( obj => obj.key) ) ].map( key=> { return this.freebieTypeList.find(obj => obj.key === key) } );
    }, (err)=> {

    })
  }
  selectFreebieType(freebieCoupon) {
    
    this.showLoadingScreen = true;

    if (freebieCoupon.status) {
      freebieCoupon.status = false;
      this.resturantListByCategory = {...this.resturantListByCategoryCopy};
      
      this.CATEGORY_LIST = Object.keys(this.resturantListByCategory);
      this.CATEGORIES_LIST_COUNT = {};
      
      Object.keys(this.resturantListByCategory).forEach((category, idx)=> {
        this.CATEGORIES_LIST_COUNT[category.toLowerCase()] = { content: {}, status: true, index: idx, count: 0 };
          Object.keys(this.resturantListByCategory[category.toLowerCase()]?.content)?.forEach((subCategory)=> {
            this.CATEGORIES_LIST_COUNT[category.toLowerCase()].content[subCategory.toLowerCase()] = { count: this.resturantListByCategory[category.toLowerCase()]?.content[subCategory.toLowerCase()]?.content.length, status: true };
          })
      })
        
      this.showLoadingScreen = false;
      
    } else {
      this.freebieTypeList.forEach((coupon)=> {
        if (coupon.key === freebieCoupon.key) {
          coupon.status = true;
        } else {
          coupon.status = false;
        }
      })
      // let itemListByCuisine = this.resturantListByCategoryCopy;
      
      this.resturantListByCategory = {};
      Object.keys(this.resturantListByCategoryCopy).forEach((category:any)=> {
        
        if (!this.resturantListByCategory[category.toLowerCase()])
          this.resturantListByCategory[category.toLowerCase()] = { content: {}, status: true, count: 0 }

        Object.keys(this.resturantListByCategoryCopy[category.toLowerCase()].content).forEach((subCategory)=> {

          if (!this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()])
            this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()] = { content: [], status: true }

          this.resturantListByCategoryCopy[category.toLowerCase()].content[subCategory.toLowerCase()].content.forEach((item)=> {
            freebieCoupon.itemList.forEach((freebie)=> {
              if (freebie["itemId"] === item.id) {  
                this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()].content.push(item);
              }
            })
          })
        })
      })
      
      Object.keys(this.resturantListByCategory).forEach((category:any, categoryIndex)=> {
        Object.keys(this.resturantListByCategory[category.toLowerCase()].content).forEach((subCategory:any, subCategoryIndex)=> {
          
          if (this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()].content.length === 0) 
            delete  this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()];
        })

        this.resturantListByCategory[category.toLowerCase()].count = Object.keys(this.resturantListByCategory[category.toLowerCase()].content).length;
        if (Object.keys(this.resturantListByCategory[category.toLowerCase()].content).length === 0)
          delete this.resturantListByCategory[category.toLowerCase()];
      })

      
      this.CATEGORY_LIST = Object.keys(this.resturantListByCategory);
      this.CATEGORIES_LIST_COUNT = {};
      
      Object.keys(this.resturantListByCategory).forEach((category, idx)=> {
        this.CATEGORIES_LIST_COUNT[category.toLowerCase()] = { content: {}, status: true, index: idx };
          Object.keys(this.resturantListByCategory[category.toLowerCase()]?.content)?.forEach((subCategory)=> {
            this.CATEGORIES_LIST_COUNT[category.toLowerCase()].content[subCategory.toLowerCase()] = { count: this.resturantListByCategory[category]?.content[subCategory]?.content.length, status: true };
          })
      })


      // this.CUISINE_LIST.forEach((cuisine)=> {
        
      //   this.resturantListByCategory[cuisine] = { content: [], status: true };
      //   itemListByCuisine[cuisine].content.forEach((item)=> {
      //     freebieCoupon.itemList.forEach((freebie)=> {
      //       if (freebie["itemId"] === item.id) {  
      //         this.resturantListByCategory[cuisine].content.push(item);
      //       }
      //     })
      //     // if (freebieCoupon.itemList['itemId'].includes(item.id)) {
      //     // }
      //   })
      //   // this.resturantListByCuisine[cuisine].content = itemList;
      // })
        
      this.showLoadingScreen = false;
    }
  }

  itemIdForLoading: string = '';
  showRunTimeLoader: boolean = false;

  // add only when item belong to same food court condition check
  addItemAfterMultipleFoodcourtCheck(item) {

    const isPreOrderingType = this.landingComponentService.getIsOrderPreOrderType();
    if (isPreOrderingType) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.isIntoPreOrderingFlow = null;
          this.getVendorFoodItemList();
        } else {

        }
      });
    } else {

      let currentOrderFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
      if (currentOrderFoodCourtId && currentOrderFoodCourtId !== item.foodCourtId) {
        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '320px',
          height: 'auto',
          data: { type: '' },
        });
      dialogRef.afterClosed().subscribe((data) => {
        if (data?.isSuccess === true) {
          this.globalCartService.getAllCartData().then(()=> {
            this.addItemAfterCustomizationCheck(item, "ADD");
          })
        } else if (data?.isSuccess === false) {
          this.landingComponentService.openSnackBarMessage('Error Occured');
        }
      });
      } else {
        this.addItemAfterCustomizationCheck(item, "ADD");
      }
    }
  }

  // check whether the item has customization or not. If it has then open bottom sheet else add item
  addItemAfterCustomizationCheck(item, actionType) {

    // Item will be added after checking for customization if present open bottomsheet or else follow normal item addition flow
    if (item.customizationRequired) {
      // below condition is writen because sometime menuCustomizationList is null in item but item has cutomization 
      // in such scenario we need to call the api to get those customization data and store it in menuCustomizationList of item
      if(!item.menuCustomizationList || item.menuCustomizationList.length === 0) {
        this.restaurantService.getAllCustomizationsOfItem(item.id).subscribe((response:any) => {
          if(response.data && response.data.length > 0) {
            item.menuCustomizationList = response.data.map((element) => {
              let obj = Object.assign({}, element);
              obj.customizedItemsForMenuList.forEach((customItems) => {
                customItems.status = false;
              })

              return obj;
            });
          }

          this.openCustomizationBottomSheet(item, actionType);
        })
      } else {
        item.menuCustomizationList.forEach((element) => {
          element.customizedItemsForMenuList.forEach((customItems) => {
            customItems.status = false;
          })
        })

        // if item is for ADD then we need to create a variable orderedItemCustomizations else variable is already present
        if(actionType === 'ADD') {
          item.orderedItemCustomizations = null;
        }

        this.openCustomizationBottomSheet(item, actionType);
      }
    } else {
      if(actionType === 'ADD') {
          this.addItem(item)
        } else if(actionType === 'INCREASE') {
          this.increaseItemCount(item);
        }
    }
  }

  openCustomizationBottomSheet(item, actionType) {

    const dialogRef = this.bottomSheet.open(CustomizeItemBottomsheetComponent, {
      data: {data: item, edit: false },
    });
    dialogRef.afterDismissed().subscribe((response)=> {
      if(!response) return;
      item.orderedItemCustomizations = response.selectedCustomization;
      if(actionType === 'ADD') {
        this.addItem(item)
      } else if(actionType === 'INCREASE') {
        this.increaseItemCount(item);
      }
    });
  }

  openDiscountDetails(discountDetails) {
    
    const dialogRef = this.bottomSheet.open(DiscountDetailsBottomsheetComponent, {
      data: discountDetails,
      panelClass: 'no-padding',
    });
  }

  addItem(item) {
    // console.log(item);

    this.itemIdForLoading = item.id;

      item.showCustomQuantity++;
      // item.quantity = item.showCustomQuantity;
      item.itemQuantity = item.showCustomQuantity;
      this.showRunTimeLoader = true;
      let foodCourtId = this.landingComponentService.getFoodCourtId();
      let foodCourtName = this.landingComponentService.getFoodCourtName();
      this.landingComponentService.setOrderFoodCourtId(foodCourtId);
      this.landingComponentService.setOrderFoodCourtName(foodCourtName);

      this.globalCartService
      .addItemToIndexedDB(item, false)
      .then((response: any) => {
        this.getUserCartDataIndexDB();

        // this.landingComponentService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.quantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
      // this.globalCartService
      //   .addItemToCart(item)
      //   .then((response: any) => {
      //     this.showRunTimeLoader = false;
      //     this.landingComponentService.itemQuantityChange.next(true);
      //     this.cartDetails = response;
      //   })
      //   .catch((err) => {
      //     item.quantity -= 1;
      //     this.showRunTimeLoader = false;
      //     //console.log(err);
      //   });
    // }
  }
  increaseItemCount(item) {
    // console.log("increase", item);
    this.itemIdForLoading = item.id;
    this.showRunTimeLoader = true;

    item.showCustomQuantity++;
    // item.quantity = item.showCustomQuantity;
    // item.itemQuantity = item.showCustomQuantity;

    this.globalCartService.checkForCustomizedItemInIndexDb(item)
        .then((result: any) => {
          this.globalCartService
          .addItemToIndexedDB(item, result.isSameItemPresent)
          .then((response: any) => {
            this.getUserCartDataIndexDB();

            // this.landingComponentService.itemQuantityChangeFromCart.next(true);
          })
          .catch((err) => {
            this.showRunTimeLoader = false;
            item.showCustomQuantity -= 1;
            item.itemQuantity -= 1;
            // this.landingConsoleService.openSnackBarMessage(err);
          });
        })
    // this.globalCartService
    //   .increaseItemCount(item)
    //   .then((response: any) => {
    //     this.showRunTimeLoader = false;
    //     this.landingComponentService.itemQuantityChange.next(true);
    //     this.cartDetails = response;
    //   })
    //   .catch((err) => {
    //     item.quantity -= 1;
    //     this.showRunTimeLoader = false;
    //     //console.log(err);
    //   });
  }
  decreaseItemCount(item) {
    this.globalCartService.checkForCustomizedItemInIndexDb(item)
        .then((result: any) => {
          if(item.customizationRequired && result.customizedItemCount > 1) {
            this.dialog.open(ConfirmationPopupComponent, {
              width: '90vw',
              height: 'auto',
              maxWidth: '100vw',
              maxHeight: '50vh',
              data: { type: 'REMOVE_CUSTOMIZATION' },
            });
          } else {
            // console.log("decrease", item);
          item.showCustomQuantity--;
          // item.quantity = item.showCustomQuantity;
          item.itemQuantity = item.showCustomQuantity;
          this.showRunTimeLoader = true;
          // if (item.quantity > 0) {
            if(item.itemQuantity > 0) {
              this.globalCartService
                .addItemToIndexedDB(item, true)
                .then((response: any) => {
                  this.getUserCartDataIndexDB();

                  // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                })
                .catch((err) => {
                  this.showRunTimeLoader = false;
                  item.quantity += 1;
                  // this.landingConsoleService.openSnackBarMessage(err);
              });
            } else {
              this.globalCartService.removeItemFromCart(item)
                  .then(response => {
                    let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();

                    if(orderedFoodCourtId) {
                      this.getUserCartDataIndexDB();
                    } else {
                      this.showRunTimeLoader = false;
                    }
                    // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                  })
                  .catch(error => {
                    this.showRunTimeLoader = false;
                    item.quantity += 1;
                  })
            }
          // this.globalCartService
          //   .decreaseItemCount(item)
          //   .then((response: any) => {
          //     this.showRunTimeLoader = false;
          //     this.landingComponentService.itemQuantityChange.next(true);
          //     this.cartDetails = response;
          //   })
          //   .catch((err) => {
          //     item.quantity += 1;
          //     this.showRunTimeLoader = false;
          //     // this.landingConsoleService.openSnackBarMessage(err);
          //   });
          // }
          // else {
          //     this.globalCartService
          //     .removeItemFromCart(item)
          //     .then((response) => {
          //       this.showRunTimeLoader = false;
          //     })
          //     .catch((err) => {
          //       this.showRunTimeLoader = false;
          //       // this.landingConsoleService.openSnackBarMessage(err);
          //     });
          //   }
          }
        })
  }

  getUserCartDataIndexDB() {
    // this.showLoader = true;
    this.indexDbService.getAllItemFromCart().then((response) => {

      return new Promise((resolve, reject) => {
        resolve(response);
      })
    })
    .then((response: any) => {
      let userName = this.landingComponentService.getUserName();
      let userMobile = this.landingComponentService.getUserMobile();
      let userId = this.landingComponentService.getUserId();
      let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
      let btcRequestId = this.landingComponentService.getBTCRequestId();

      let deliveryAddress = this.landingComponentService.getUserAddressForBTC();
      let orderType = this.landingComponentService.getUserOrderTypeForBTC();

      if(orderedFoodCourtId) {
        if(userId){
        let data = {
          address: deliveryAddress,
          orderType: orderType,
          foodCourtId: orderedFoodCourtId,
          userId: userId,
          userName: userName,
          mobile: userMobile,
          platform: "WEB",
          btcRequestId: btcRequestId,
          itemsList: {},
        };
          data.itemsList = response;
          // console.log("cart item list", response);

          this.landingComponentService.addItemToCartAPI(data).subscribe(
            (res) => {
              this.resturantItems.forEach((item) => {
                item.showCustomQuantity = 0;
                item.itemId = item.id;
                response.forEach((cartItem) => {
                  if (cartItem.itemId === item.id) {
                    item.showCustomQuantity += cartItem.itemQuantity;
                    item.appItemId = cartItem.appItemId;
                    item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                  }
                })
              })
              this.landingComponentService.itemQuantityChange.next(true);
              this.showRunTimeLoader = false;
            },
            (err) => {
              // this.showRunTimeLoader = false;
              // this.showLoader = false;
              //console.log(err);
              this.handleErrorForAddingItems();
              this.landingComponentService.openSnackBarMessage(
                'Failed to add item to Cart'
              );
            }
          );
        } else {
          this.resturantItems.forEach((item) => {
            item.showCustomQuantity = 0;
            item.itemId = item.id;
            response.forEach((cartItem) => {
              if (cartItem.itemId === item.id) {
                item.showCustomQuantity += cartItem.itemQuantity;
                item.appItemId = cartItem.appItemId;
                item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            })
          })
          this.landingComponentService.itemQuantityChange.next(true);
          this.showRunTimeLoader = false;
        }
      } else {
        
        this.landingComponentService.itemQuantityChange.next(true);
        this.showRunTimeLoader = false;
      }
    })
  }

  handleErrorForAddingItems() {
    let userId = this.landingComponentService.getUserId();

    // Clearing local db and getting user cart data from backend and adding back to local db and maintaining the count of items
    this.indexDbService.clearAllItemsFromCart()
        .then((result) => {
          this.landingComponentService.getCartData(userId).subscribe(async (response : any) => {
            let orderFoodCourtId = response.data.foodCourtId;
            this.landingComponentService.setOrderFoodCourtId(orderFoodCourtId);

            // console.log("user cart from api", response);
            for(let item of response.data.itemsList) {
              await this.globalCartService.addItemToIndexedDB(item, false);
              // console.log("items adding in process");
            }

            this.resturantItems.forEach((item) => {
              item.showCustomQuantity = 0;
              item.itemId = item.id;
              response.forEach((cartItem) => {
                if (cartItem.itemId === item.id) {
                  item.showCustomQuantity += cartItem.itemQuantity;
                  item.appItemId = cartItem.appItemId;
                  item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              })
            })

            this.showRunTimeLoader = false;
            // console.log("cart added to items complete");
          }, error => {
            this.landingComponentService.openSnackBarMessage("Failed to add item to cart, please refresh once")
          })
        })
        .catch((error) => {
          this.showRunTimeLoader = false;
        })
  }

  showAllItemList() {
    if (this.showLoadingScreen)
      return;
    if (this.searchText.length === 0) this.getVendorFoodItemList();
  }

  searchVendorFoodItemList() {
    if (this.showLoadingScreen)
      return;
    this.getVendorFoodItemList();
  }
  getVendorFoodItemList() {

    this.showLoadingScreen = true;
    this.globalCartService.getUserCartData().then((response : any) => {
      this.userCartData = response.userCartData;
      this.cartDetails = response.cartPreviewData;
      this.showLoadingScreen = false;
      // this.getAllCuisines();
      this.getAllSubCategories();
    });
  }

  // get all food items of the vendor -> for each item check with userCartData to increase item count(if present)
  // -> store each item to their respective cuisine category -> maintain the no. of items in each cuisine that will be used to show in menu popup
  _getVendorFoodItemList_() {
    this.showLoadingScreen = true;
    this.itemListAPIFailure = false;

    let OUT_OF_STOCK_ITEMS = [];

    let data = {
      foodCourtId: this.foodCourtId,
      itemType: this.isVegOnly ? 'VEG' : null,
      mealCategory: this.selectedMealType,
      vendorId: this.vendorId,
      name: this.searchText,
    };
    this.restaurantService.getVendorFoodItemList(data).subscribe(
      (response: any) => {
        this.resturantItems = response.data;
        this.resturantItems.forEach((item) => {
          item.showCustomQuantity = 0;
          item.itemId = item.id;
          this.userCartData?.forEach((cartItem) => {
            if (cartItem.itemId === item.id) {
              item.showCustomQuantity += cartItem.itemQuantity;
              item.appItemId = cartItem.appItemId;
              item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
            }

            let url = item.itemImageUrl;
            let pattern = /^((http|https|ftp):\/\/)/;
            if (pattern.test(url)) {
              item.itemImageUrl = url;
            } else {
              item.itemImageUrl = '';
            }
          });
          
          if (!item.itemCategory)
            item.itemCategory = "Other";
            
          if (item.subCategoryMenuItem?.length === 0 || !item.subCategoryMenuItem)
            item.subCategoryMenuItem = ["Other"];

          if (item.itemStatus === "VENDOR_AVAILABLE" || item.itemStatus === "IN_STOCK") {

            this.resturantListByCategory[item.itemCategory.toLowerCase()]
            ?.content[item.subCategoryMenuItem[0].toLowerCase()]
            ?.content?.push(item);
            // item.subCategoryMenuItem.forEach((subCategory)=> {

            //   this.resturantListByCategory[item.itemCategory.toLowerCase()]
            //   ?.content[subCategory.toLowerCase()]
            //   ?.content?.push(item);
            // })
          }
          else
            OUT_OF_STOCK_ITEMS.push(item);
        });

        OUT_OF_STOCK_ITEMS.forEach((element)=> {

          this.resturantListByCategory[element.itemCategory.toLowerCase()]?.content[element.subCategoryMenuItem[0].toLowerCase()]?.content.push(element);
          // element.subCategoryMenuItem.forEach((subCategory)=> {
          //   this.resturantListByCategory[element.itemCategory.toLowerCase()]?.content[subCategory.toLowerCase()]?.content.push(element);
          // })
        })

        Object.keys(this.resturantListByCategory).forEach((category:any, categoryIndex)=> {
          Object.keys(this.resturantListByCategory[category.toLowerCase()].content).forEach((subCategory:any, subCategoryIndex)=> {
            
            if (this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()].content.length === 0) 
              delete  this.resturantListByCategory[category.toLowerCase()].content[subCategory.toLowerCase()];
          })
          this.resturantListByCategory[category.toLowerCase()].count = Object.keys(this.resturantListByCategory[category.toLowerCase()].content).length;
          if (Object.keys(this.resturantListByCategory[category.toLowerCase()].content).length === 0)
            delete this.resturantListByCategory[category.toLowerCase()];
        })

        this.CATEGORY_LIST = Object.keys(this.resturantListByCategory);
        this.CATEGORIES_LIST_COUNT = {};
        
        Object.keys(this.resturantListByCategory).forEach((category, idx)=> {
          this.CATEGORIES_LIST_COUNT[category.toLowerCase()] = { content: {}, status: true, index: idx, count: 0 };
            Object.keys(this.resturantListByCategory[category.toLowerCase()]?.content)?.forEach((subCategory)=> {
              this.CATEGORIES_LIST_COUNT[category.toLowerCase()].content[subCategory.toLowerCase()] = { count: this.resturantListByCategory[category.toLowerCase()]?.content[subCategory.toLowerCase()]?.content.length, status: true };
            })
        })
        // this.CUISINE_LIST_COUNT = this.CUISINE_LIST.map((response, index)=> {
        //   return {
        //     name: response,
        //     count: this.resturantListByCategory[response].content.length,
        //     value: index,
        //     status: false
        //   }
        // });
        // this.CUISINE_LIST_COUNT = this.CUISINE_LIST_COUNT.filter((response)=> {
        //   return response.count > 0;
        // });
        // if (this.CUISINE_LIST_COUNT.length > 0)
        //   this.CUISINE_LIST_COUNT[0].status = true;

        // Store to Copy
        this.resturantListByCategoryCopy = this.resturantListByCategory;
        this.showLoadingScreen = false;
      },
      (err) => {
        // this.landingComponentService.openSnackBarMessageIMP("Something Went Wrong! Plz try again in sometime");
        this.showLoadingScreen = false;
        this.itemListAPIFailure = true;
 
      }
    );
  }
  
  toggleDropdown(toggleData) {
    toggleData.status = !toggleData.status;
  }

  MEALS = [
    { name: 'All', value: null, status: true },
    { name: 'Breakfast', value: 'BREAKFAST', status: false },
    { name: 'Lunch', value: 'LUNCH', status: false },
    { name: 'Snacks', value: 'SNACKS', status: false },
    { name: 'Dinner', value: 'DINNER', status: false },
  ];

  openFilterFoodType() {
    let top = document.querySelector('.restaurant-view-section').clientHeight; 
    let left = Math.ceil((document.querySelector('.restaurant-view-section').clientWidth)/2);
    if(this.scrWidth < 600){
      const dialogRef = this.dialog.open(FilterItemsComponent, {
        width: '300px',
        height: '60vh',
        data: {data: this.CATEGORIES_LIST_COUNT}
      });
      dialogRef.afterClosed().subscribe((response)=> {
        if (response) {
          this.CATEGORIES_LIST_COUNT = response.data;
          this.itemByCategoryList.toArray()[response.selectedSubCategory.value.index].nativeElement.focus();
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(FilterItemsComponent, {
        width: '300px',
        height: '60vh',
        data: {data:this.CATEGORIES_LIST_COUNT,top:top,left:left}
      });
      dialogRef.afterClosed().subscribe((response)=> {
        if (response) {
          this.CATEGORIES_LIST_COUNT = response.data;
          this.itemByCategoryList.toArray()[response.selectedSubCategory.value.index].nativeElement.focus();
        }
      })
    }
  }
  toggleSearchBar() {
    // if searchText container some data that means user is closing the searchbar else it is being opened
    if (this.searchText) {
      this.globalCartService
        .getUserCartData()
        .then((response: any) => {
          this.userCartData = response.userCartData;
          this.cartDetails = response.cartPreviewData;
          // this.getAllCuisines();
          this.getAllSubCategories();
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    this.searchText = '';
    this.showSearchIcon = !this.showSearchIcon;
  }
  goToHome() {
    
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`${foodCourtName}`]);
  }
  getItemsByMealType(meal) {

    this.freebieTypeList.forEach((freebie)=> {
      freebie.status = false;
    })

    if (meal.value === this.selectedMealType || this.showLoadingScreen)
      return;

    this.MEALS.forEach((element) => {
      element.status = false;
    });
    meal.status = true;
    this.selectedMealType = meal.value;

    this.getVendorFoodItemList();
  }

  openItemDetailBox(item) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '320px',
      height: 'auto',
      data: { type: 'ITEM_DETAILS', itemDetails: item },
    });
    dialogRef.afterClosed().subscribe((data) => {});
  }
}



// ---------------------- COMMENTED (for later use) --------------------------------

  // searchVendorFoodItemList() {
  //   this.showLoadingScreen = true;
  //   // this.globalCartService.getUserCartData().then((response)=> {
  //   // this.cartDetails = response.cartPreviewData;
  //   // this.userCartData = response.userCartData;
  //   // }).catch((err)=> {
  //   //   //console.log(err);
  //   // })

  //   let data = {
  //     foodCourtId: this.foodCourtId,
  //     itemType: this.isVegOnly ? 'VEG' : null,
  //     mealCategory: this.selectedMealType,
  //     vendorId: this.vendorId,
  //     name: this.searchText,
  //   };
  //   this.restaurantService.getVendorFoodItemList(data).subscribe(
  //     (response: any) => {
  //       this.resturantItems = response.data;

  //       this.globalCartService.getUserCartData().then((data) => {
  //         this.cartDetails = data.cartPreviewData;
  //         this.userCartData = data.userCartData;

  //         this.resturantItems.forEach((item) => {
  //           item.quantity = 0;
  //           this.userCartData?.forEach((cartItem) => {
  //             if (cartItem.itemId === item.id)
  //               item.quantity = cartItem.itemQuantity;
  //           });

  //           let url = item.itemImageUrl;
  //           let pattern = /^((http|https|ftp):\/\/)/;
  //           if (pattern.test(url)) {
  //             item.itemImageUrl = url;
  //           } else {
  //             item.itemImageUrl = '';
  //           }
  //         });
  //         this.showLoadingScreen = false;
  //       });
  //     },
  //     (err) => {
  //       this.landingComponentService.openSnackBarMessageIMP("Something went wrong! Plz try again in sometime");
  //       //console.log(err);
  //     }
  //   );
  // }
