import { Component, OnInit } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  constructor(
    private userAccountService: UserAccountService,
    private landingService: LandingComponentService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userId = this.landingService.getUserId();
    this.getUserSettings();
  }

  userId: string;
  settingsData: any = {
    promoAlerts: false,
    orderStatusAlerts: false,
    istharaRewardPoints: 0,
  };

  showLoader: boolean = false;

  getUserSettings() {
    this.showLoader = true;

    this.userAccountService.getUserSettingsByUserId(this.userId).subscribe((response: any) => {
      this.settingsData = response.data;

      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.landingService.openSnackBarMessage("Getting error, please try again", "Ok");
      })
  }

  updatePermissionForNotification(permissionToggle: MatSlideToggle) {
    this.userAccountService.toggleNotificationForUser(this.userId, this.settingsData.promoAlerts).subscribe((response: any) => {
      this.landingService.openSnackBarMessage("Updated successfully", "Ok");
    },
      (error) => {
        permissionToggle.checked = !this.settingsData.promoAlerts;
        this.landingService.openSnackBarMessage("Failed to update, please try again", "Ok");
      })
  }

  goToUserProfile() {
    this.router.navigate(['/profile/user']);
  }
}
