import { Injectable } from '@angular/core';
declare var db: any;

@Injectable({
  providedIn: 'root',
})
export class IndexDBService {
  private STORAGE_NAME = 'Cart';

  constructor() {}

  addItemToCart(key, value) {
    return new Promise(async (resolve, reject) => {
      if (db != undefined) {
        const request = await db
          .transaction(this.STORAGE_NAME, 'readwrite')
          .objectStore(this.STORAGE_NAME)
          .put(value, key);

        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.result);
          } else {
            resolve('empty data');
          }
        };
        request.onerror = await function(event) {
          reject("no data");
        }
      }
    });
  }
  getAllItemFromCart() {
    return new Promise(async (resolve, reject) => {
      if (db != undefined) {
        const request = await db
          .transaction(this.STORAGE_NAME, 'readwrite')
          .objectStore(this.STORAGE_NAME)
          .getAll();

        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.target.result);
          } else {
            resolve('empty data');
          }
        };
        request.onerror = await function (event) {
          reject("no data");
        }
      }
    });
  }
  clearAllItemsFromCart() {
    return new Promise(async (resolve, reject) => {
      if (db != undefined) {
        const request = await db
          .transaction(this.STORAGE_NAME, 'readwrite')
          .objectStore(this.STORAGE_NAME)
          .clear();

        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.result);
          } else {
            resolve('empty data');
          }
        };
        request.onerror = await function(event) {
          reject("No data");
        }
      }
    });
  }

  removeItemFromCart(itemId) {
    return new Promise(async (resolve, reject) => {
      if (db != undefined) {
        const request = await db
          .transaction(this.STORAGE_NAME, 'readwrite')
          .objectStore(this.STORAGE_NAME)
          .delete(itemId);

        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.result);
          } else {
            resolve('empty data');
          }
        };
        request.onerror = await function(event) {
          reject("No data");
        }
      }
    });
  }
}
