<section class="pre-ordering-section">
    <div class="preordering-header-section">
        <div class="back-btn-container">
            <img src="../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToHomePage()">
        </div>
        <div class="heading-text">Pre-Order</div>
    </div>
    <div class="select-days-section">
        <div class="heading-sub-text">Select Days</div>
        <div class="days-list">
            <ng-container *ngFor="let day of preOrderingDays">
                <div [ngClass]="{'active': day.status}" (click)="selectPreOrderingDay(day)">{{ day.name }}</div>
            </ng-container>
        </div>
    </div>
    <div class="select-time-section">
        <div class="heading-sub-text">Select Time Slot</div>
        <div class="timeslot-list">
            <ng-container *ngFor="let slot of preOrderingTimeSlot">
                <ng-container *ngIf="!slot.isBlocked">
                    <div [ngClass]="{'active': slot.status}" (click)="selectTimeSlot(slot)">{{ slot.startTime | date:'shortTime' }} - {{ slot.endTime | date:'shortTime' }}</div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="select-meal-type-section">
        <div class="heading-sub-text">Select Your Meal</div>
        <div class="meal-type-list">
            <ng-container *ngFor="let meal of mealTypeList">
                <div class="image-container" (click)="goToMealsPage(meal)" [ngClass]="{'meal-disable': meal.isBlocked}">
                    <img src="../../../../../../assets/images/preordering-flow/{{ meal.value | lowercase }}-preordering.png" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</section>