import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountFlowService {

  constructor(
    private http: HttpClient
  ) { }

  getResturantListByFilter(fcId:string, menuType: string, searchRequest: string, type: string) {
    
    if (searchRequest && menuType) {
      return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/get/type/all?fcId=${fcId}&menuType=${menuType}&searchRequest=${searchRequest}&type=${type}`)
    } else if (!searchRequest && menuType) {
      return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/get/type/all?fcId=${fcId}&menuType=${menuType}&type=${type}`)  
    } else if (searchRequest && !menuType) {
      return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/get/type/all?fcId=${fcId}&searchRequest=${searchRequest}&type=${type}`)  
    } else {
      return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/get/type/all?fcId=${fcId}&type=${type}`)  
    }
  }
}
