import { Component, HostListener, Input, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubcriptionPlanViewComponent } from '../subcription-plan-view/subcription-plan-view.component';
import { PlanSubscriptionPageComponent } from '../plan-subscription-page/plan-subscription-page.component';
import { FoodSubscriptionService } from '../food-subscription.service';



@Component({
  selector: 'app-subcription-plan-listing',
  templateUrl: './subcription-plan-listing.component.html',
  styleUrls: ['./subcription-plan-listing.component.scss']
})
export class SubcriptionPlanListingComponent implements OnInit {
  
  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private dialog : MatDialog,
    private foodsubscription: FoodSubscriptionService,
    @Optional() public dialogRef : MatDialogRef<SubcriptionPlanViewComponent>,
    
  ) { }

  
  openPlanSubscriptionPage: boolean = false;

  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
  
    this.scrWidth = window.innerWidth;

  }



  subscriptionPlanList = [];
  filteredSubscriptionPlanList = [];
  selectedSubscriptionMealType: boolean = null;

  showBackBtn: boolean = true;

  @Input() subscriptionPlanListing: any[] = [];

  

  ngOnInit(): void {

    this.getScreenSize();

    let currentUrl = this.router.url.split('/');
    if (currentUrl.length === 2) {
      this.showBackBtn = false;
    }

    if (this.subscriptionPlanListing.length === 0) {
      this.subscriptionPlanList = this.landingComponentService.getSubscriptionPlanListing();
    
    } else {
      this.subscriptionPlanList = this.subscriptionPlanListing;
    }

    this.subscriptionPlanList.forEach((subsDetails)=> {
      subsDetails.showSeeMoreBtn = false;
    })

    if (this.subscriptionPlanList.length === 0) {
      let foodcourtName = this.landingComponentService.getFoodCourtName();
      this.router.navigate([`${foodcourtName}`]);
    }
    
    this.filteredSubscriptionPlanList = this.subscriptionPlanList;
   
  }

  goToHomePage() {
    let foodcourtName = this.landingComponentService.getFoodCourtName();
    this.router.navigate([`/${foodcourtName}`]);
  }
  
  openSubscriptionDetails(subscriptionPlan) {
    if (this.openPlanSubscriptionPage)
      return;

    this.landingComponentService.setSelectedSubscriptionPlan(subscriptionPlan);
  

    if(this.scrWidth >= 600){  
        this.dialog.open(SubcriptionPlanViewComponent,{
          width: '75vw',
          maxWidth: '100vw',
          panelClass: 'no-padding',
          minHeight : "95vh"  
        });
        }
        else{
               this.router.navigate(['/food-subscription/view-plan']);
          }

  }

  toggleSeeMoreBtn(subscriptionDetails) {
    
    this.openPlanSubscriptionPage = true;
    subscriptionDetails.showSeeMoreBtn = !subscriptionDetails.showSeeMoreBtn;
  }

  subscribePlan(subscriptionPlan) {

    
    this.openPlanSubscriptionPage = true;
    this.landingComponentService.setSelectedSubscriptionPlan(subscriptionPlan);

      
      if(this.scrWidth >= 600){
             const subscribePlanRef = this.dialog.open(PlanSubscriptionPageComponent,{
              width : "70vw",
              minHeight : "92vh",
              maxWidth : "100vw",
              panelClass: 'no-padding', 
            });
            subscribePlanRef.afterClosed().subscribe(()=> {
              this.openPlanSubscriptionPage = false;
            })
          // }
          

        // this.openSubscriptionDetailsPage = false;
        // this.dialogRef.close();
       
      }
      else{ 
        this.router.navigate(['/food-subscription/plan-subscription']); 
       }
  
   
    
  }

  modifySubscriptionList() {
    if (this.selectedSubscriptionMealType === null) {
      this.filteredSubscriptionPlanList = this.subscriptionPlanList;
    } else {
      this.filteredSubscriptionPlanList = this.subscriptionPlanList.filter((plan:any)=> {
        return plan.mealType === this.selectedSubscriptionMealType;
      })
    }
  }

  subscriptionTypeList = [
    { name: 'All', value: null, status: false },
    { name: 'Veg', value: "VEG", status: false },
    { name: 'Non Veg', value: "NON_VEG", status: false },
  ]

}
