import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-subscription-terms-and-conditions',
  templateUrl: './subscription-terms-and-conditions.component.html',
  styleUrls: ['./subscription-terms-and-conditions.component.scss']
})
export class SubscriptionTermsAndConditionsComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) { }

  termsAndConditions;
  ngOnInit(): void {
    this.termsAndConditions = this.data.termsAndConditions;
  }

}
