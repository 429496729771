<div class="search-container">
  <input type="text" [(ngModel)]="searchText" placeholder="Search by Item or Restaurant name"
    (keyup)="_serachItemsResturants()" />
  <img src="../../../../../../assets/images/search-page/search.svg" alt="" (click)="serachItemsResturants()" />
</div>
<div [ngClass]="
    cartDetails.noOfItems > 0 ? 'search-section-updated' : 'search-section'
  ">
  <div class="recent-searches" *ngIf="recentSearchesList.length > 0">
    <div class="heading-text">Recent Searches</div>
    <div class="searches-list">
      <ng-container *ngFor="let result of recentSearchesList; let i = index">
        <div class="recentsearch-icon" (click)="searchFromRecentSearches(result)">
          <div>{{ result | titlecase }}</div>
          <div class="close-img-container" (click)="removeItemFromRecentSearch(i)">
            <img src="../../../../../../assets/images/common/close.svg" alt="" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!showLoader; else showLoadingIcon">
    <ng-container *ngIf="showSearchResult; else showEmptySearchPage">
      <div class="search-result-container">
        <div class="food-items-list">
          <div class="list-heading" *ngIf="searchFoodItemList.length > 0">
            <span class="heading-text">Items</span>
            <!-- <img
              src="../../../../../../assets/images/common/down_arrow.svg"
              alt=""
            /> -->
          </div>
          <div class="vendor-item-list-container">
            <ng-container *ngFor="let item of searchFoodItemList">
              <div class="vendor-item-container">
                <div class="trending-icon-container" *ngIf="item.trending">
                  <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                </div>
                <div class="image-container">
                  <img *ngIf="item.itemImageUrl" loading="lazy" [src]="item.itemImageUrl" alt="" />
                  <img *ngIf="!item.itemImageUrl" src="../../../../../../assets/images/common/default_food_image.svg"
                    alt="" />
                </div>
                <div class="description">
                  <div class="item-resturant-name">
                    <div class="name" *ngIf="item.name.length <= 25; else showTrimmedName">
                      {{ item.name | titlecase }}
                    </div>
                    <ng-template #showTrimmedName>
                      <span class="name">{{ item.name.substring(0, 19) | titlecase }}..
                        <!-- <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon> -->
                      </span>
                    </ng-template>

                    <div class="price">
                      <span *ngIf="item?.taxCategory === 'MRP'" class="mrp-text">MRP : </span>
                      <!-- &#8377;{{ (item.price / 100) | number: "1.0-1" }} -->
                      &#8377;{{ (item.markUpPrice) | number: "1.0-1" }}
                    </div>
                  </div>
                  <div class="vendor-name">{{ item.vendorName | titlecase }}
                    <span *ngIf="item.customizationRequired"> - Customisable</span>
                  </div>
                  <div class="item-category">
                    {{ item.itemCategory | titlecase }}

                  </div>
                  <div class="action-btn-ratings">
                    <div class="rating">
                      <img src="../../../../../../assets/images/home-page/star.svg" alt="" />
                      <span>{{ item.rating | number: "1.0-1" }}</span>
                    </div>
                    <div class="add-btn" [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow}" *ngIf="
                        item.itemStatus === 'IN_STOCK' ||
                          item.itemStatus === 'VENDOR_AVAILABLE';
                        else OutOfStock
                      ">
                      <ng-container *ngIf="
                          !showRunTimeLoader || itemIdForLoading !== item.itemId;
                          else runTimeLoading;
                          else: runTimeLoading
                        ">
                        <button class="add-item" *ngIf="item?.showCustomQuantity === 0; else showItemIncDecSearch"
                          (click)="addItemAfterMultipleFoodcourtCheck(item)">
                          Add
                        </button>
                        <ng-template #showItemIncDecSearch>
                          <ng-container *ngIf="!isIntoPreOrderingFlow; else showFreezedCountSearch">
                            <div class="add-to-cart-btn">
                              <button class="minus" (click)="decreaseItemCount(item)">
                                -
                              </button>
                              <span class="item-count">{{ item.showCustomQuantity }}</span>
                              <button class="plus" (click)="addItemAfterCustomizationCheck(item, 'INCREASE')">
                                +
                              </button>
                            </div>
                          </ng-container>
                          <ng-template #showFreezedCountSearch>
                            <div class="add-to-cart-btn-freezed"
                              (click)="addItemAfterMultipleFoodcourtCheck(trendingItem)">
                              <button class="minus">-</button>
                              <span class="item-count">{{ item?.showCustomQuantity }}</span>
                              <button class="plus">+</button>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </div>
                    <ng-template #OutOfStock>
                      <div class="out-of-stock-btn">Out of Stock</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="vendors-list">
          <div class="list-heading" *ngIf="searchVendorList.length > 0">
            <span class="heading-text">Restaurants</span>
            <!-- <img src="../../../../../../assets/images/common/down_arrow.svg" alt=""> -->
          </div>
          <div class="vendor-item-list-container" *ngIf="searchVendorList.length > 0">
            <ng-container *ngFor="let vendor of searchVendorList">
              <div class="vendor-item-container">
                <div class="image-container">
                  <img *ngIf="vendor.imgUrl" loading="lazy" [src]="vendor.imgUrl" alt="">
                  <img *ngIf="!vendor.imgUrl" src="../../../../../../assets/images/common/default_restuarant.svg"
                    alt="">
                </div>
                <div class="description">
                  <div class="left-section">
                    <div class="item-resturant-name">
                      <div class="name">{{ vendor?.brandName | titlecase }}</div>
                      <div class="price" *ngIf="vendor?.startTime && vendor?.endTime">{{ vendor?.startTime }} - {{
                        vendor?.endTime }}</div>
                    </div>
                    <!-- <div class="vendor-name">{{ vendor.foodCourtName }}</div> -->
                    <!-- <div class="action-btn-ratings">
                                    <div class="rating">
                                        <img src="../../../../../../assets/images/home-page/star.svg" alt="">
                                        <span class="rating">{{ vendor.rating | number:'1.0-1' }}</span>
                                    </div>
                                </div> -->
                  </div>
                  <div class="right-section">
                    <button (click)="viewResturantDetails(vendor)">View</button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #showEmptySearchPage>
      <div class="empty-content-container">
        <div class="empty-search-img-container">
          <img src="../../../../../../assets/images/common/empty-search-page.svg" alt="">
        </div>
        <div class="primary-text">Oop's Empty</div>
        <div class="secondary-text">Look's like you haven't made your choices yet</div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #showLoadingIcon>
    <div class="loader-img-container">
      <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
    </div>
  </ng-template>

  <ng-container *ngIf="cartDetails.noOfItems > 0 && scrWidth < 600">
    <app-cart-footer [cartDetails]="cartDetails"></app-cart-footer>
  </ng-container>

  <ng-template #runTimeLoading>
    <button class="add-item">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
  </ng-template>
</div>