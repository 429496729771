import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { NotificationService } from './components/erp-landing/authentication/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'istharaErp';

  // message: any;
  constructor(
    private router: Router,
    private _notificationService: NotificationService
  ) {}
  ngOnInit(): void {
    this._notificationService.requestPermission();
    this._notificationService.receiveMessage();
    // this.message = this._notificationService.currentMessage;
  }
}
