import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { getMessaging, getToken } from 'firebase/messaging';
import { LandingComponentService } from '../../landing-component/landing-component.service';
import { environment } from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private landingComponentService: LandingComponentService
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  async requestPermission() {
    await this.angularFireMessaging.requestPermission.subscribe(
      async (response) => {
        //console.log(response);
        // if(response === 'granted') {
        const messaging = getMessaging();
        //console.log('messagin', messaging);
        await getToken(messaging, {
          vapidKey: environment.application_identity_Key,
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // ...

              //console.log('FB Token', currentToken);
              this.landingComponentService.setFirebaseToken(currentToken);
            } else {
              // Show permission request UI
              //console.log(
              //   'No registration token available. Request permission to generate one.'
              // );
              // ...
            }
          })
          .catch((err) => {
            //console.log('An error occurred while retrieving token. ', err);
            if (this.landingComponentService.getFirebaseToken()) {
            } else {
              this.landingComponentService.openSnackBarMessage(
                'Please allow notification to get notified about your order',
                'Ok'
              );
              this.landingComponentService.setFirebaseToken(null);
            }

            // ...
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      //console.log('new message received. ', payload);
      this.currentMessage.next(payload);

      this.showCustomNotification(payload);
    });
  }

  showCustomNotification(payload: any) {
    let notify_data = payload['notification'];
    let title = notify_data['title'];
    let options = {
      body: notify_data['body'],
      icon: '../../../../../../assets/images/dummy/dummy_img.png',
      badge: '',
      image: '../../../../../../assets/images/dummy/dummy_img.png',
      vibrate: [
        500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110,
        170, 40, 500,
      ],
    };
    //console.log('new message recived', notify_data);

    this.landingComponentService.openSnackBarMessage(options.body, 'Ok');

    // let notify: Notification = new Notification(title, options);
    // //console.log(notify);

    // notify.addEventListener('click', (event) => {
    //   //console.log('events', event);
    //   event.preventDefault();
    //   window.location.href =
    //     'https://isthara-food-court-dev.web.app/profile/user/order-summary';
    // });
  }
}
