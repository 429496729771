<section>
  <div class="back-btn" (click)="goToHome()">
    <img src="../../../../../assets/images/common/back_arrow.svg" alt="" />
  </div>
  <div class="main-section">
    <div class="sigin-door">
      <img
        src="./../../../../../assets/images/authentication/loginwindow.svg"
        alt="loginwindow"
      />
    </div>
    <div class="signin-card">
      <div class="logo">
        <img
          src="./../../../../../assets/images/common/isthara-mobile-logo.png"
          alt=""
        />
      </div>
      <div [ngSwitch]="viewMode" class="viewMode">
        <div *ngSwitchCase="'signIn'">
          
          <div class="signin-section">
            <div class="big-text">Sign In</div>
            <div class="sub-text">
              Welcome to Isthara ! Log In with your details
            </div>
            <div class="fields">
              <mat-form-field class="sfull-width" appearance="outline">
                <mat-label>{{ placeholder }}</mat-label>
                <div style="display: flex">
                  <input
                    matInput
                    [(ngModel)]="staffusername"
                    (ngModelChange)="expTestSignUp($event)"
                    required
                  /><mat-icon><span>perm_identity</span></mat-icon>
                </div>
              </mat-form-field>
            </div>
            <div class="fields" *ngIf="isLoginWithPassword">
              <form [formGroup]="signin" class="no-padding">
                <mat-form-field class="sfull-width" appearance="outline">
                  <input
                    matInput
                    placeholder="Password"
                    [type]="hide ? 'password' : 'text'"
                    formControlName="password"
                    required
                    [(ngModel)]="staffpassword"
                    (keyup.enter)="loginBtn()"
                  />
                  <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </mat-form-field>
              </form>
              <div
                class="forgot-password-text"
                (click)="changeViewMode('forgot-password')"
              >
                Forgot Password?
              </div>
            </div>
            <div class="tc-check">
              <mat-checkbox color="primary" [(ngModel)]="signInCheckBox"></mat-checkbox>
              <span>By signIn, you agreed to <a href="https://isthara.com/privacy-policy">Terms and Conditions</a></span>
            </div>
            <ng-template #loading>
              <div class="v-btn">
                <button
                  class="submit-btn"
                  (click)="loginBtn()"
                  [disabled]="
                    staffusername === '' ||
                    staffusername === null ||
                    (isLoginWithPassword ? !staffpassword : false) ||
                    staffusername < 999999999 || staffusername > 10000000000 ||
                    !signInCheckBox
                  "
                  [ngClass]="staffusername != '' ? 'submt-btn' : 'disabled-btn'"
                >
                  SIGN IN
                </button>
              </div>
            </ng-template>
            <div class="v-btn" *ngIf="loader; else loading">
              <button class="submit-btn">
                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
              </button>
            </div>

            <div class="or-seperator">
              <div class="line"></div>
              <div class="text">OR</div>
              <div class="line"></div>
            </div>
            <div class="fields" *ngIf="isLoginWithPassword">
              <div class="v-btn">
                <button class="login-with-pass-btn" (click)="toggleLoginWithPassword()">LOGIN WITH OTP</button>
              </div>
            </div>
            <div class="fields" *ngIf="!isLoginWithPassword">
              <div class="v-btn">
                <button class="login-with-pass-btn" (click)="toggleLoginWithPassword()">LOGIN WITH PASSWORD</button>
              </div>
            </div>
            <div class="sign-up-navigator">
              Dont't have an account?
              <span class="signup-text" (click)="changeViewMode('signUp')"
                >SIGN UP</span
              >
            </div>
            <!-- <div class="signup-section">
              <div class="sub-text">Don’t have an account yet?</div>
              <span class="signup" (click)="changeViewMode('signUp')">Sign Up</span>
            </div> -->
          </div>
        </div>
        <div *ngSwitchCase="'otp'">
          <div class="big-text">OTP Verification</div>
          <div class="revert">
            <!-- <img src="./../../../../assets/images/contactus/arrow.png" alt="" width="10px"> -->
            <div (click)="changeViewMode('signIn')">
              <span>(+91)</span>{{ (isInLoginPage ? staffusername : user.mobile) }}<span class="change">change</span>
            </div>
          </div>
          <div class="sub-text">
            Your OTP has been sent to your mobile number and email It may take
            sometime to recieve OTP.
          </div>
          <div class="otp-field" *ngIf="scrWidth < 600">
            <!-- <input
              id="codeBox1"
              type="text"
              pattern="\d*"
              #num1
              maxlength="1"
              (keyup)="move(num1, num2, $event)"
              (keydown)="back(num1,num1,$event)"
              [(ngModel)]="verifyotp.otp1"
            />
            <input
              id="codeBox2"
              #num2
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num2, num3, $event)"
              (keydown)="back(num2,num1,$event)"
              [(ngModel)]="verifyotp.otp2"
            />
            <input
              id="codeBox3"
              #num3
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num3, num4, $event)"
              (keydown)="back(num3,num2,$event)"
              [(ngModel)]="verifyotp.otp3"
            />
            <input
              id="codeBox4"
              #num4
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num4, num5, $event)"
              (keydown)="back(num4,num3,$event)"
              [(ngModel)]="verifyotp.otp4"
            />
            <input
              id="codeBox5"
              #num5
              type="text"
              pattern="\d*"
              maxlength="1"
              (keyup)="move(num5, num6, $event)"
              (keydown)="back(num5,num4,$event)"
              [(ngModel)]="verifyotp.otp5"
            />
            <input
              id="codeBox6"
              #num6
              type="text"
              pattern="\d*"
              maxlength="1"
              [(ngModel)]="verifyotp.otp6"
              (keyup)="move(num6, num6, $event)"
              (keydown)="back(num6,num5,$event)"
            /> -->
            <ng-otp-input
              (onInputChange)="onOtpEnter($event)"
              [config]="{ length: 6 }"
              [config]="config"
            ></ng-otp-input>
          </div>
          <div class="otp-field" *ngIf="scrWidth > 600">
            <ng-otp-input
              (onInputChange)="onOtpEnter($event)"
              [config]="{ length: 6 }"
              [config]="desktopConfig"
            ></ng-otp-input>
          </div>
          <div class="timer pull-right">
            <countdown
              #countdown
              [config]="{ leftTime: 120, format: 'mm:ss' }"
              (event)="handleCounter($event)"
            >
            </countdown>
          </div>
          <div class="resend-otp">
            <div class="sub-text">Didn't get OTP?</div>
            <button
              class="resendBtn"
              [disabled]="counterAction === 'start'"
              (click)="resend()"
            >
              Resend Now
            </button>
          </div>
          <div class="v-btn">
            <button
              class="verify-btn"
              (click)="verifyBtn()"
              *ngIf="!loader; else loading"
            >
              Verify Now
            </button>
            <ng-template #loading>
              <button class="verify-btn">
                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
              </button>
            </ng-template>
          </div>
        </div>
        <div *ngSwitchCase="'signUp'">
          <div class="big-text">Sign Up</div>
          <div class="sub-text" style="font-size: 12px">
            Create an account to start using Isthara
          </div>
          <div class="fields">
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Full Name</mat-label>
              <input
                matInput
                [(ngModel)]="user.fullName"
                #fullnameCheck="ngModel"
                required
              />
              <mat-error
                *ngIf="
                  !fullnameCheck.valid &&
                  (fullnameCheck.dirty || fullnameCheck.touched)
                "
              >
                Please Enter your full name
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                [(ngModel)]="user.mobile"
                #mobileCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                required
              />
              <mat-error
                *ngIf="
                  !mobileCheck.valid &&
                  (mobileCheck.dirty || mobileCheck.touched)
                "
              >
                Please Enter valid mobile number
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                matInput
                [(ngModel)]="user.email"
                #emailCheck="ngModel"
                pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"

              />
              <mat-error
                *ngIf="
                  !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
                "
              >
                Please Enter Valid Email Id
              </mat-error>
            </mat-form-field>
              <div class="tc-check">
                <mat-checkbox color="primary" [(ngModel)]="signUpCheckBox"></mat-checkbox>
                <span>By signup, you agreed to <a href="https://isthara.com/privacy-policy">Terms and Conditions</a></span>
              </div>
            <div class="v-btn">
              <button
                class="verify-btn"
                (click)="registerBtn()"
                [disabled]="!fullnameCheck.valid || !mobileCheck.valid || !signUpCheckBox"
                >
                SIGN UP
              </button>
            </div>
            <div class="sign-up-navigator">
              Already have an account?
              <span class="signup-text" (click)="changeViewMode('signIn')"
                >Sign In</span
              >
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'forgot-password'">
          <div class="big-text">Forgot Password?</div>
          <div class="sub-text" style="font-size: 14px">
            Just enter the username you have used to login before. We'll send
            you OTP.
          </div>
          <div class="fields">
            <mat-form-field class="sfull-width" appearance="outline">
              <mat-label>Username</mat-label>
              <input 
                matInput 
                [(ngModel)]="staffusername"
                (ngModelChange)="expTestSignUp($event)"
                required 
              />
            </mat-form-field>
            <div class="v-btn">
              <button class="verify-btn" (click)="resetPassword()">
                Submit
              </button>
            </div>
          </div>
          <div class="sign-up-navigator">
            <span class="signup-text" (click)="changeViewMode('signIn')"
              >Remember Password?</span
            >
          </div>
        </div>
        <div *ngSwitchCase="'set-password'">
          
          <div class="skip-btn" (click)="skipEnteringPassword()">Skip</div>
          <div class="big-text">Set Password</div>
          <div class="sub-text" style="font-size: 14px">
            Set the new Password for your account so you can login
          </div>
          
          <div class="fields">
            <form [formGroup]="signin" class="no-padding">
              <mat-form-field class="sfull-width" appearance="outline">
                <input
                  matInput
                  placeholder="Enter Password"
                  [type]="hidePassword ? 'password' : 'text'"
                  formControlName="password"
                  required
                  [(ngModel)]="newUserpassword"
                />
                <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="eye-icon">{{
                  hidePassword ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
              <mat-form-field class="sfull-width" appearance="outline">
                <input
                  matInput
                  placeholder="Confirm Password"
                  [type]="hideConfirmPassword ? 'password' : 'text'"
                  formControlName="password"
                  required
                  [(ngModel)]="confirmUserPassword"
                />
                <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" class="eye-icon">{{
                  hideConfirmPassword ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
            </form>
          </div>
          
          <div class="fields">
            <div class="v-btn">
              <button class="login-with-pass-btn" (click)="loginWithPassword()" style="margin-top: 20px;width: 100%;" [disabled]="confirmUserPassword != newUserpassword ">SET PASSWORD</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
