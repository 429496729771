import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { DiscountDetailsBottomsheetComponent } from '../other-components/discount-details-bottomsheet/discount-details-bottomsheet.component';
import { CouponsService } from './coupons.service';

@Component({
  selector: 'app-coupens',
  templateUrl: './coupens.component.html',
  styleUrls: ['./coupens.component.scss', './mq-coupens.component.scss'],
})
export class CoupensComponent implements OnInit {
  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private _popupDbService: PopupDbService,
    private _couponService: CouponsService,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    
    this.userId = this._popupDbService.getUserId();

    this.getScreenSize();
    this.getCouponListForUser();
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  couponCode: string = '';
  showLoader: boolean = true;
  userId: string = '';
  applicableCouponsList: any[] = [];
  nonApplicableCouponsList: any[] = [];

  applyCouponCode() {

    this.applicableCouponsList.forEach((coupon)=> {
      if (coupon.discountCouponCode.split("-").join("").toUpperCase() === this.couponCode.toUpperCase().split("-").join("")) {
        this.applyCoupon(coupon); 
      }
    })
  }

  getCouponListForUser() {
    this.showLoader = true;
    this._couponService
      .getCouponsByUserId(this.userId)
      .subscribe((res: any) => {
        //console.log(res);
        res.data.forEach((coupon)=> {
          if (coupon.applicable) {
            this.applicableCouponsList.push(coupon);
          } else {
            this.nonApplicableCouponsList.push(coupon);
          }
        });
        // this.couponsList = this.couponsList.map((coupon) => {
        //   return {
        //     ...coupon,
        //     showMoreBtn: false,
        //   };
        // });
        this.showLoader = false;
      });
  }

  showDiscountDetails(discountDetails) {

    const dialogRef = this.bottomSheet.open(DiscountDetailsBottomsheetComponent, {
      data: discountDetails,
      panelClass: 'no-padding',
    });
  }

  applyCoupon(coupen) {
    
    if (!coupen.applicable) return;
    this._couponService
      .applyCouponToUser(this.userId, coupen.discountId)
      .subscribe(
        (response: any) => {
          if (this.scrWidth > 600)
            this.goToCart();
          else {
            let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
            this.router.navigate([`cart/${foodCourtName}`]);
          }
        },
        (err) => {
          if (err.status == 404) {
            // this._couponService.openSnackBarMessage((err?.message ? err?.message : "Try another Coupon"));
            this._couponService.openSnackBarMessage("Discount not applicable on selected items");
          }
          else
            this._couponService.openSnackBarMessage('Failed to Apply Coupon');
        }
      );
  }

  toggleShowMore(coupon) {
    coupon.showMoreBtn = !coupon.showMoreBtn;
  }

  back() {
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`cart/${foodCourtName}`]);
  }

  goToCart() {
    this.landingComponentService.sidePanelContent.next("CART");
  }
}
