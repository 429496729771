<section class="subscription-view-section" [ngClass]="{'subscription-view-section__updated': isUserSubscriptionView}">
    <ng-container *ngIf="!showLoadingScreen; else LoadingScreen">

        <div class="susbscription-view-banner">
            <span (click)="navigateToViewlist()">
                <ng-container *ngIf="scrWidth > 600">
                    <img src="../../../../../../../assets/images/common/close.svg" alt="">
                </ng-container>
                <ng-container *ngIf="scrWidth <= 600">
                    <img src="../../../../../../../assets/images/food-subscription/Chevron Right 1.png" alt="">
                </ng-container>
            </span>
            <div class="items-content">
                <div class="items-content__left-section">
                    <div class="left-section__top-container">
                        <ng-container *ngIf="subscriptionPlanDetails.mealType === 'VEG'">
                            <img src="../../../../../../../assets/images/common/veg-item.svg" alt="">
                        </ng-container>
                        <ng-container *ngIf="subscriptionPlanDetails.mealType === 'NON_VEG'">
                            <img src="../../../../../../../assets/images/common/non-veg-item.svg" alt="">
                        </ng-container>
                        <div class="item-text">{{ subscriptionPlanDetails.title | titlecase }}</div>
                    </div>
                    <div class="left-section__bottom-container">
                        <img src="../../../../../../../assets/images/dummy/dummy_user.svg" alt="">
                        <div class="vendorName-text">{{ (subscriptionPlanDetails.brandName ?
                            subscriptionPlanDetails.brandName : subscriptionPlanDetails.vendorName) | titlecase }}
                        </div>
                    </div>
                </div>
                <div class="items-content__right-section">
                    <!-- <div class="paid-text">Paid</div> -->
                    <div class="amount-text">₹{{ (subscriptionPlanDetails.price ) | number:'1.1-2' }} / Month
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="duration-of-subscripton" *ngIf="isUserSubscriptionView">
                <div class="duration-of-subscription__top-section">
                    <div class="duration-of-subscription__top__left-section">Subscription Duration</div>
                    <div class="duration-of-subscription__top__right-section">{{ subscriptionPlanDetails.endDate |
                        daysLeft }}</div>
                </div>
                <div class="duration-of-subscription__bottom-section">
                    <div class="duration-of-subscription__bottom__left-section">
                        <img src="../../../../../../../assets/images/common/calendar_white.svg" alt="">
                        <div>{{ subscriptionPlanDetails.startDate | date }} - {{ subscriptionPlanDetails.endDate |
                            date
                            }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="Description">
            <!-- <div id="Description-text">Description :</div> -->
            <ul>
                <li>{{ subscriptionPlanDetails.desc | titlecase }}</li>
            </ul>
        </div>
        <div class="weeks">
            <div *ngFor="let day of subscriptionPlanDetails.dayWiseItemsList">
                <div id="days" [ngClass]="{'active': day.status}" (click)="getMenuBasedOnDay(day)">{{ day.day |
                    titlecase }}
                </div>
            </div>
        </div>
        <div class="item-container" *ngIf="selectedMenu; else showEmptyPage">
            <div class="breakfast-menu">
                <ng-container *ngFor="let menu of selectedMenu.serveWiseItemsList | keyvalue">

                    <div class="menu-container">
                        <div class="menu-container__heading">
                            <div class="menu-container__heading__top-section">
                                <div class="top-section__left-section">
                                    <div>{{ menu.key | titlecase }} Menu</div>
                                    <div class="meal-timing">( {{ serveTiming[menu.key].startTiming | convertLocalDate }} - {{ serveTiming[menu.key].endTiming | convertLocalDate }} )</div>
                                </div>
                                <div class="top-section__right-section" (click)="showQRCode(menu.key)"
                                    *ngIf="isUserSubscriptionView">
                                    <span class="material-icons">qr_code</span>
                                </div>
                            </div>
                            <!-- <div class="menu-container__heading__bottom-section">Served at {{ menu.value['startTime'] | convertLocalDate }} - {{ menu.value['endTime'] | convertLocalDate }}</div> -->
                        </div>
                        <div class="menu-container__item-list-container">
                            <ng-container *ngFor="let menuItem of menu.value">
                                <div class="menu-container__item-content">
                                    <div class="item-content__item-image">
                                        <ng-container *ngIf="menuItem.itemImgUrl; else showItemText">
                                            <img [src]="menuItem.itemImgUrl" alt="">
                                        </ng-container>
                                        <ng-template #showItemText>
                                            <div class="no-img-present-container">{{ menuItem.itemName[0] | uppercase }}</div>
                                        </ng-template>
                                    </div>
                                    <div class="item-content__item-name">{{ menuItem.itemName | titlecase }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </ng-container>
            </div>
        </div>
        <!-- change in design -->
        <!-- <div class="breakfast-menu">
                    <div id="breakfast-menu-text">{{ menu.value['serveType'] | titlecase }} Menu</div>
                    <div id="breakfast-border"></div>
                    <div class="itemsOfBreakfast">
                        <ul>
                            <li *ngFor="let items of menu.value['items']">{{items.itemName | titlecase}}</li>
                        </ul>
                    </div>
                 </div> -->

        <!-- <div class="section">
                        <div class="menu-section">
                            <div class="menu-top-section">
                                <div class="Menu-heading">{{ menu.value['serveType'] | titlecase }} Menu</div>
                                <ng-container *ngFor="let timing of subscriptionPlanDetails.fcItemsList">
                                    <div class="serve-timing">
                                        <ng-container *ngIf="timing.serveType === 'BREAKFAST'">
                                            <img src="../../../../../../../assets/images/food-subscription/timing-images.png"
                                                alt="">

                                            {{timing.startTime | convertLocalDate }} - {{timing.endTime |
                                            convertLocalDate}}
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="itemslist">
                                <img src="../../../../../../../assets/images/food-subscription/coconut-chutney.png"
                                    alt="">
                                <div *ngFor="let items of dailyItemList.MONDAY">
                                    <div *ngFor="let item of items.items">
                                        {{item.itemName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->



        <!-- <div class="foodtime">
                <div id="food-timing">Food Timings</div>
                <ng-container *ngFor="let timing of subscriptionPlanDetails.fcItemsList">
                    <div id="break-text">{{ timing.serveType | uppercase }}</div>
                    <div class="timming"><img
                            src="../../../../../../../assets/images/food-subscription/timing-images.png" alt="">
                        <div>{{ timing.startTime | convertLocalDate }} - {{ timing.endTime | convertLocalDate }}</div>
                    </div>
                </ng-container>
            </div> -->

        <div class="terms-condition">
            <div id="TermsConditions-text">Terms & Conditions</div>
            <ul>
                <li *ngFor="let text of subscriptionPlanDetails.termsAndConditions">{{ text | titlecase }}
                </li>
            </ul>
        </div>
        <ng-template #showEmptyPage>
            <div class="empty-part">
                <div class="no-image">
                    <img src="../../../../../../assets/images/cart/empty-cart.svg" alt="empty-image" />
                </div>
                <div class="empty-heading">No Menu Present on Current Day!!</div>
                <div class="empty-subtext">Fill it up with your favourate food</div>
                <!-- <div class="emptyrules-text">the menu</div> -->
            </div>
        </ng-template>

        <div id="btn" *ngIf="!isUserSubscriptionView">
            <button (click)="navigateToPlan_subscription_page()">Subscribe Now</button>
        </div>


    </ng-container>
    <ng-template #LoadingScreen>
        <div class="loader-img-container">
            <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
        </div>
    </ng-template>
</section>