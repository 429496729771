import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { LandingComponentService } from '../../../landing-component.service';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-customize-item-bottomsheet',
  templateUrl: './customize-item-bottomsheet.component.html',
  styleUrls: ['./customize-item-bottomsheet.component.scss']
})
export class CustomizeItemBottomsheetComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private otherComponentService: OtherComponentService,
    private landingService: LandingComponentService
  ) { }

  customizationList = [];                         //  
  selectedCustomization = [];                    // 
  totalPrice = 0;                               //
  shownCustomizedItemsName = '';

  ngOnInit(): void {
    
    this.totalPrice = this.data.data.markUpPrice;
    this.modifyCustomizationList();
  }

  modifyCustomizationList() {
    
    if(this.data.data.menuCustomizationList && this.data.data.menuCustomizationList.length > 0) {
      this.customizationList = this.data.data.menuCustomizationList.map((element) => {
        let obj = JSON.parse(JSON.stringify(element));   // statment to make copy of element
        obj.customizedItemsForMenuList.forEach((customItems, customItemIndex) => {
          if(this.data.data.orderedItemCustomizations) {
            const isCustomItemIdAvailable = this.data.data.orderedItemCustomizations.some(d => d.categoryId === customItems.uniqueId);
            if(isCustomItemIdAvailable) {
              customItems.status = true;
              obj.selectedCustomItemId = customItems.uniqueId;
              this.totalPrice += customItems.price;
              this.shownCustomizedItemsName += (customItems.subTitle + ', ')
            } else {
              customItems.status = false;
            }

          } else {
            if(customItems.active && customItems.isDefault) {
                customItems.status = true;
                obj.selectedCustomItemId = customItems.uniqueId;
                this.totalPrice += customItems.price;
            } else {
              customItems.status = false;
            }
          }
        })

        return obj;
      });;
    }

    console.log("customitem", this.customizationList);
  }

  noOfItemsSelected = 0;
  selectCustomItemOption(customType, customItem, customItemsId) {
    if(customType.maxOptionAllowed > 1) {
      this.noOfItemsSelected = 0;
      if(customItem.isDefault) {
        customType.customizedItemsForMenuList.forEach((element) => {
          if(element.isDefault) {
            element.status = true;
          } else {
            if(element.status) {
              element.status = false;
              this.totalPrice -= element.price;
            }
          }
        })
      } else {
        customType.customizedItemsForMenuList.forEach((element) => {
          if(element.isDefault) {
            if(element.status) {
              element.status = false;
              this.totalPrice -= element.price;
            }
          } else {
            if(element.uniqueId === customItemsId) {
              element.status = !element.status;
            }

            if(element.status) {
              this.noOfItemsSelected++;
            }
          }
        })
      }

      if(this.noOfItemsSelected === 0) {
        customType.customizedItemsForMenuList.forEach((element) => {
          if(element.isDefault && element.active) {
            if(!element.status) {
              element.status = true;
              this.totalPrice += element.price;
            }
          }
        })
      }

      if(customItem.status) {
        this.totalPrice += customItem.price;
      } else {
        this.totalPrice -= customItem.price;
      }

    } else {
      customType.customizedItemsForMenuList.forEach((element) => {
        if(element.uniqueId === customItemsId) {
          element.status = !element.status;

          if(element.status) {
            this.totalPrice += element.price;
          }
        } else {
          if(element.status) {
            this.totalPrice -= element.price;
          }
            element.status = false;
        }
    })
    }
  }

  addItemToCart() {

    this.selectedCustomization = [];

    for(let element of this.customizationList) {
      // if(element.required) {
        let selectedCustomItemsLength = 0;
        for(let customItem of element.customizedItemsForMenuList) {
          if(customItem.status) {
            let orderedItemCustomizations = {
              active: true,
              category: "CUSTOMIZE",
              categoryId: customItem.uniqueId,
              name: customItem.subTitle.trim(),
              price: customItem.price,
              packagingCharge: customItem.packingCharge
            }

            this.selectedCustomization.push(orderedItemCustomizations);
            selectedCustomItemsLength++;
          }
        }

        if(selectedCustomItemsLength < 1) {
          this.landingService.openSnackBarMessage(`Please select any custom item for ${element.customizationCategoryName}`);
          return;
        } else
         if(selectedCustomItemsLength > element.maxOptionAllowed) {
          this.landingService.openSnackBarMessage(`You can select only ${element.maxOptionAllowed} items for ${element.customizationCategoryName}`);
          return;
        }
    }

    let data = {
      selectedCustomization: this.selectedCustomization,
      totalCustomizedPrice: this.totalPrice
    }

    this.bottomSheetRef.dismiss(data);
  }

  updateCustomization() {
    this.totalPrice = this.data.data.markUpPrice;

    this.customizationList = this.data.data.menuCustomizationList.map((element) => {
      let obj = JSON.parse(JSON.stringify(element));
      obj.customizedItemsForMenuList.forEach((customItems, customItemIndex) => {
        obj.selectedCustomItemId = 'NOT_SELECTED';

        if(customItems.active && customItems.isDefault) {
          customItems.status = true;
          obj.selectedCustomItemId = customItems.uniqueId;
          this.totalPrice += customItems.price;
        } else {
          customItems.status = false;
        }
      })

      if(obj.selectedCustomItemId === 'NOT_SELECTED') {
        delete obj.selectedCustomItemId;
      }

      return obj;
    });;
    this.data.data.orderedItemCustomizations = [];
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }
}
