import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccountService } from '../../user-account.service';
import { LandingComponentService } from '../../../../landing-component.service';

@Component({
  selector: 'app-user-foodsubscription-listing',
  templateUrl: './user-foodsubscription-listing.component.html',
  styleUrls: ['./user-foodsubscription-listing.component.scss']
})
export class UserFoodsubscriptionListingComponent implements OnInit {

  constructor(
    private router: Router,
    private userAccountService: UserAccountService,
    private landingComponentService: LandingComponentService
  ) { }

  selectedSubscriptionType: string = "ALL";
  mainLoader: boolean = false;

  ngOnInit(): void {
    this.getUserFoodSubscriptionList();
  }

  subscriptionList = [];

  USER_SUBSCRIPTION_TYPE = [
    { name: 'All Subscription', value: "ALL", status: true },
    { name: 'Active Subsctiption', value: "ACTIVE", status: false },
    { name: 'Expired Subscription', value: "EXPIRED", status: false },
  ]


  getUserFoodSubscriptionList() {
    let userId = this.landingComponentService.getUserId();
    this.mainLoader = true;

    this.userAccountService.getAllUserSubscriptionList(userId, this.selectedSubscriptionType).subscribe((response:any)=> {
      this.subscriptionList = response.data;
      this.subscriptionList.forEach((subscriptionPlan)=> {
        subscriptionPlan.subscriptionStatus = ( new Date() > subscriptionPlan.endDate ) ? "EXPIRED" : "ACTIVE";
      })
      this.mainLoader = false;
    }, (err)=> {
    })
  }

  changeUserSubscriptionListing(subsctiptionType) {
    this.USER_SUBSCRIPTION_TYPE.forEach((type)=> {
      if (type.value === subsctiptionType.value) {
        type.status = true;
        this.selectedSubscriptionType = subsctiptionType.value;
      } else {
        type.status = false;
      }
    })
    this.getUserFoodSubscriptionList();
  }

  openSubscriptionDetails(subscriptionPlanDetails) {
    this.landingComponentService.setSelectedSubscriptionPlan(subscriptionPlanDetails);
    this.router.navigate(['/food-subscription/view-plan'], {queryParams: {isUserSubscriptionView: true}});
  }

  goToUserAccountPage() {
    this.router.navigate(['/profile/user']);
  }

}
