<div class="resturant-discount-screen">
    <div class="mobile-screen" *ngIf="scrWidth < 475">
        <div class="discount-header">
            <img src="../../../../../../../assets/images/home-page/net-discount.svg" alt="" *ngIf="discountType === 'FIXED_AMOUNT'">
            <img src="../../../../../../../assets/images/home-page/flat-discount.svg" alt="" *ngIf="discountType === 'PERCENTAGE'">
            <img src="../../../../../../../assets/images/home-page/freebie-discount.svg" alt="" *ngIf="discountType === 'FREEBIES'">
            <div class="discount-type" [ngClass]="{
                'net-discount': discountType === 'FIXED_AMOUNT',
                'flat-discount': discountType === 'PERCENTAGE',
                'freebie': discountType === 'FREEBIES'
            }">{{ discountType.split('_').join(' ') | titlecase }}</div>
        </div>
        <div class="discount-content-section">
            <ng-container  *ngIf="!showLoader; else showLoadingScreen">
                <div class="discount-search-container">
                    <input type="text" [(ngModel)]="searchText" placeholder="Search by vendor name">
                    <div class="search-icon" (click)="getResturantList()"><mat-icon>search</mat-icon></div>
                </div>
                <div class="toggle-section">
                    <mat-slide-toggle class="veg" [(ngModel)]="isVegOnly" (ngModelChange)="getResturantList()" color="warn">Veg</mat-slide-toggle>
                    <mat-slide-toggle class="non-veg" [(ngModel)]="isNonVegOnly" (ngModelChange)="getResturantList()" color="warn">Non Veg</mat-slide-toggle>
                </div>
                <ng-container *ngIf="resturantList.length > 0; else showEmptyResturantScreen" >
                    <div class="resturant-list-container">
                        <ng-container *ngFor="let vendor of resturantList">
                            <div class="resturant-container" (click)="navigateToVendorPage(vendor)">
                                <div class="img-container">
                                    <img [src]="vendor.vendorImgUrl" alt="" *ngIf="vendor.vendorImgUrl">
                                    <img src="../../../../../../../assets/images/common/default_restuarant.svg" alt="" *ngIf="!vendor.vendorImgUrl">

                                </div>
                                <div class="resturant-details">
                                    <div class="resturant-name">
                                        <span>{{ vendor.brandName | titlecase }}</span>
                                        <div class="vendor-menu-type">
                                            <img src="../../../../../../../assets/images/common/veg-item.svg" alt="" *ngIf="vendor.vendorMenuType === 'BOTH' || vendor.vendorMenuType === 'VEG'">
                                            <img src="../../../../../../../assets/images/common/non-veg-item.svg" alt="" *ngIf="vendor.vendorMenuType === 'BOTH' || vendor.vendorMenuType === 'NON_VEG'">
                                        </div>
                                    </div>
                                    <!-- <div class="resturant-cuisine">Snacks, Pastry</div> -->
                                    <div class="resturant-ratings"> 
                                        <img src="../../../../../../../assets/images/ratings/rating-star-fill.svg" alt="">
                                        <span class="vendor-ratings">{{ vendor.rating | number:'1.0-1' }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="tab-screen" *ngIf="scrWidth >= 475">
        <ng-container  *ngIf="!showLoader; else showLoadingScreen">
            <div class="discount-header_tab">
                <div class="discount-type_tab">
                    <img src="../../../../../../../assets/images/home-page/net-discount.svg" alt="" *ngIf="discountType === 'FIXED_AMOUNT'">
                    <img src="../../../../../../../assets/images/home-page/flat-discount.svg" alt="" *ngIf="discountType === 'PERCENTAGE'">
                    <img src="../../../../../../../assets/images/home-page/freebie-discount.svg" alt="" *ngIf="discountType === 'FREEBIES'">
                    <div class="discount-type" [ngClass]="{
                        'net-discount': discountType === 'FIXED_AMOUNT',
                        'flat-discount': discountType === 'PERCENTAGE',
                        'freebie': discountType === 'FREEBIES'
                    }">{{ discountType.split('_').join(' ') | titlecase }}</div>
                </div>
                <div class="discount-search-container_tab">
                    <input type="text" [(ngModel)]="searchText" placeholder="Search by vendor name">
                    <div class="search-icon" (click)="getResturantList()"><mat-icon>search</mat-icon></div>
                </div>
                <!-- <div class="toggle-section_tab">
                    <mat-slide-toggle class="veg" [(ngModel)]="isVegOnly" (ngModelChange)="getResturantList()" color="warn">Veg</mat-slide-toggle>
                    <mat-slide-toggle class="non-veg" [(ngModel)]="isNonVegOnly" (ngModelChange)="getResturantList()" color="warn">Non Veg</mat-slide-toggle>
                </div> -->
            </div>
            <ng-container *ngIf="resturantList.length > 0; else showEmptyResturantScreen" >
                <div class="resturant-list-container_tab">
                    <ng-container *ngFor="let vendor of resturantList">
                        <div class="resturant-container_tab" (click)="navigateToVendorPage(vendor)">
                            <div class="image-container">
                                <img [src]="vendor.vendorImgUrl" alt="" *ngIf="vendor.vendorImgUrl">
                                <img src="../../../../../../../assets/images/common/default_restuarant.svg" alt="" *ngIf="!vendor.vendorImgUrl">
                            </div>
                            <div class="vendor-details-container">
                                <div class="vendor-name">{{ vendor.brandName | titlecase }}</div>
                                <div class="vendor-rating-and-timing">
                                    <div class="vendor-ratings">
                                        <img src="../../../../../../../assets/images/ratings/rating-star-fill.svg" alt="">
                                        <span>{{ vendor.rating | number:'1.0-1' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-template #showLoadingScreen>
        <div class="loader-img-container">
            <img
            src="../../../../../../assets/images/common/burger_loader.gif"
            alt=""
            />
        </div>
    </ng-template>
    
    <ng-template #showEmptyResturantScreen>
        <div class="empty-content-container">
            <img
            src="../../../.../../../../../assets/images/common/empty-menu.svg"
            alt=""
            />
            <div class="heading-text">Ooh!</div>
            <div class="secondary-text">Resturant list is empty</div>
        </div>
    </ng-template>
</div>