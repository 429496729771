<div class="helpdesk-section">
    <ng-container *ngIf="!categoryListLoader; else showCategoryScreenLoader">
        <div class="header-section">
            <div class="back-arrow-image" (click)="goToHelpdeskListingPage()">
                <img src="./../../../../../../../../assets/images/common/back_arrow.svg" alt="arrow">
            </div>
            <div class="choose-category-text">
                Choose the category under which your issue falls
            </div>
        </div>

        <div class="category-list">
            <ng-container *ngFor="let category of helpdeskCategoryList">
        <div class="category-block" [style.background-color]="category?.backgroundColor" [style.color]="category?.color" (click)="goToRaiseTicketPage(category)">
            <div class="category-images">
                <img [src]="category.helpdeskCategoryImageUrl" *ngIf="category.helpdeskCategoryImageUrl; else showCategoryDummyImage">
                <ng-template #showCategoryDummyImage>
                    <div class="profile-photo">
                        <div class="display-letter" *ngIf="!category.helpdeskCategoryImageUrl">
                            {{ category.helpdeskCategoryName.split("")[0] | uppercase }}
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="category-text">
                {{ category.helpdeskCategoryName | titlecase }}
            </div>
        </div>
        </ng-container>
        </div>
    </ng-container>
    <ng-template #showCategoryScreenLoader>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</div>
