<ng-container *ngIf="!showLoader; else loaderScreen">
  <div class="account-page-main-section">
    <div class="user-info-card">
      <div class="user-info-card__left">
        <div class="user-img-container">
          <img loading="lazy" [src]="
              userDetails?.profileImg
                ? userDetails.profileImg
                : '../../../../../../../assets/images/dummy/dummy_user.svg'
            " alt="Profile" />
        </div>
        <div class="user-basic-details">
          <div class="user-name">{{ userDetails?.userName }}</div>
          <div class="user-mobile">
            {{ userDetails?.countryCode }} - {{ userDetails?.userMobile }}
          </div>
          <div class="user-email" *ngIf="userDetails?.userEmail">
            {{ userDetails?.userEmail }}
          </div>
        </div>
      </div>
      <div class="user-info-card__right edit-icon" (click)="goToEditPage()">
        <img src="../../../../../../../assets/images/common/edit_icon.svg" alt="" />
      </div>
    </div>
    <div class="foodcourt-options-section">
      <div class="heading-text">Food Court</div>
      <div class="foodcourt-option-list">
        <div class="foodcourt-option__orders foodcourt-option" (click)="showOrderHistory()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/common/orders.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Orders</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
        <hr />
        <!-- <div class="foodcourt-option__favourites foodcourt-option">
          <div class="foodcourt-option__icon">
            <img
              src="../../../../../../../assets/images/common/favourites.svg"
              alt=""
            />
          </div>
          <div class="foodcourt-option__text">Favourites Food</div>
          <div class="foodcourt-option__arrow">
            <img
              src="../../../../../../../assets/images/common/arrow_forward.svg"
              alt=""
            />
          </div>
        </div> -->
        <!-- <hr /> -->
        <div class="foodcourt-option__reward-point foodcourt-option" (click)="showUserPoints()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/common/reward_points.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Isthara Points</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
        <hr />
        <div class="foodcourt-option__reward-point foodcourt-option" (click)="showUserHelpdeskTicket()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/common/raise-ticket.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Raise Ticket On FoodCourt</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
        <hr />
        <div class="foodcourt-option__reward-point foodcourt-option" (click)="goToUserFoodSubscriptionList()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/common/raise-ticket.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Food Subscription</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
        <hr />
        <div class="foodcourt-option__reward-point foodcourt-option" (click)="showcontactuspage()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/account-page-tab/contact_us.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Contact Us</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
        <hr />
        <div class="foodcourt-option__reward-point foodcourt-option" (click)="goToUserSettings()">
          <div class="foodcourt-option__icon">
            <img src="../../../../../../../assets/images/common/settings.svg" alt="" />
          </div>
          <div class="foodcourt-option__text">Settings</div>
          <div class="foodcourt-option__arrow">
            <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="download-app-container">
      <a [href]="appDownloadLink">Download the App</a>
    </div>
    <div class="logout-button-container">
      <button (click)="logoutUser()">Logout</button>
    </div>
  </div>
</ng-container>
<ng-template #loaderScreen>
  <div class="loader-img-container">
    <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
  </div>
</ng-template>
