import { Component, HostListener, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-items',
  templateUrl: './filter-items.component.html',
  styleUrls: ['./filter-items.component.scss']
})
export class FilterItemsComponent implements OnInit {

  scrHeight;
  scrWidth;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<FilterItemsComponent>,
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  
  itemCategoryList;

  ngOnInit(): void {
    if(this.scrWidth > 600){
      this.dialogRef.updatePosition({
        top:this.data.top - 222 +'px',
        left:this.data.left + 'px'
      });
    }
    this.itemCategoryList = this.data.data;
  }

  toggleDropdown(toggleDetails) {
    toggleDetails.status = !toggleDetails.status;
  }
  showSubCategoryItem(subCategoryDetails) {
    // this.itemCategoryList.forEach((element)=> {
    //   if (cuisineCategory.name === element.name)
    //     element.status = true;
    //   else
    //     element.status = false;
    // });
 
    this.dialogRef.close({data: this.itemCategoryList, selectedSubCategory: subCategoryDetails});
  }
}
