import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { LandingComponentService } from '../../landing-component.service';
import { SiteFoodcourtService } from './site-foodcourt.service';

@Component({
  selector: 'app-site-foodcourt-list',
  templateUrl: './site-foodcourt-list.component.html',
  styleUrls: ['./site-foodcourt-list.component.scss']
})
export class SiteFoodcourtListComponent implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheet,
    private bottomSheetService: SiteFoodcourtService,
    private landingComponentService: LandingComponentService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) { }

  siteList: any[] = [];
  filteredSiteList: any[] = [];
  foodCourtList: any[] = [];
  foodCourtsList: any[] = [];
  filteredFoodCourtList: any[] = [];
  selectedFoodCourt: any;
  siteName: any = null;
  siteId: string = null;
  foodCourtId: string = null;
  showSearchBar: boolean = false;
  searchSiteName: string;
  searchFoodCourtName: string;
  siteImageUrl: string;

  ngOnInit(): void {
    // this.getAllSites();
 
    this.siteList = this.data.siteList;
    this.filteredSiteList = this.siteList;
    this.siteId = this.data.siteId;
    this.foodCourtId = this.data.foodCourtId;

    if (!this.foodCourtId) {
      this.siteId = this.siteList[0].siteId;
      this.foodCourtId = this.siteList[0].foodCourtList[0].foodCourtId;
      this.getAllFoodCourtsFromSites();
      // this.getFoodCourtBySiteId(this.siteId, true);
    } else {
      this.getAllFoodCourtsFromSites();
      // this.getFoodCourtBySiteId(this.siteId, false);
    }
  }

  getAllFoodCourtsFromSites() {
    this.siteList.forEach((site)=> {
      site.foodCourtList.forEach((foodcourt)=> {
        // to selected foodcourt add siteName and siteId
        foodcourt.siteId = site.siteId;
        foodcourt.siteName = (site.siteName ? site.siteName : "site");
        foodcourt.siteAddress = site.siteAddress;
        foodcourt.status = false;
        foodcourt.siteImageUrl = site.siteImageUrl;
        this.foodCourtsList.push(foodcourt);

        if (foodcourt.foodCourtId === this.foodCourtId)
          foodcourt.status = true;

      })
      // console.log(this.foodCourtsList);
    })
    this.filteredFoodCourtList = this.foodCourtsList;
  }
  

  closeBottomSheet() {
    this.bottomSheet.dismiss({foodCourtDetails: this.selectedFoodCourt});
  }
  toggleSearchBar() {
    this.showSearchBar = !this.showSearchBar;
    if (!this.showSearchBar) {
      this.searchSiteName = "";
      this.filteredSiteList = this.siteList;
    }
      
  }
  foodCourtSelect(foodcourt) {
    
    this.foodCourtsList.forEach((foodcourt)=> {
      foodcourt.status = false;
    })
    foodcourt.status = true;


    // store if current foodcourt has preorder or not
    if (foodcourt?.foodCourtOrderTypes) {
      if (foodcourt.foodCourtOrderTypes.includes("PRE_ORDER")) {
        this.landingComponentService.setFoodcourtHasPreOrdering(true);
      } else {
        this.landingComponentService.setFoodcourtHasPreOrdering(false);
      }
    } else {
      this.landingComponentService.setFoodcourtHasPreOrdering(foodcourt.preOrder);
    }

    this.bottomSheet.dismiss({foodCourtDetails: foodcourt});
  }

  _searchFoodCourtByName() {
    if (this.searchFoodCourtName.length > 2)
      this.searchFoodCourtByName();
    else if (this.searchFoodCourtName.length === 0) {
      // this.filteredSiteList = this.siteList;    
      this.filteredFoodCourtList = this.foodCourtsList;
    }
  }
  searchFoodCourtByName() {
    // this.filteredSiteList = this.siteList.filter((siteDetail)=> {
    //   return siteDetail.siteName.toLowerCase().includes(this.searchSiteName.toLowerCase());
    // })
    this.filteredFoodCourtList = this.foodCourtsList.filter((foodcourtDetails)=> {
      return foodcourtDetails.foodCourtName.toLowerCase().includes(this.searchFoodCourtName.toLowerCase())
    });
  }
  getFoodCourtBySiteId(siteId, flag: boolean = false) {

    // Cornor Scenario: 001 if scanned foodcourt is not present in the current user's location
    let siteThroughQRandLocationOn = true;

    this.siteList.forEach((element)=> {
      element.status = false;
      if (element.siteId === siteId) {
        element.status = true;
        this.siteName = element.siteName;
        this.foodCourtList = element.foodCourtList;
        this.siteId = siteId;
        this.siteImageUrl = element.siteImageUrl;
        siteThroughQRandLocationOn = false;
      }
    });

    if (siteThroughQRandLocationOn) {

      // console.log("Site is coming from QR and user have tured on the location and in his/her location scanned site is not present.");
      this.bottomSheetService.openSnackBarMessage("Current Site is not Present in your location");
    }
    else {
      if (flag) {
        this.foodCourtList[0].status = true;
        this.selectedFoodCourt = this.foodCourtList[0];
      } else {
        let insideFlag = null;
        this.foodCourtList.forEach((element)=> {
          element.status = false;
          if (element.foodCourtId === this.foodCourtId) {
            element.status = true;
            insideFlag = true;
          }
        })

        if (!insideFlag)
          this.selectFoodCourt(this.foodCourtList[0]);
      }
    }
  }
  selectFoodCourt(foodCourtDetail) {
    
    this.foodCourtList.forEach((element)=> {
      element.status = false;
      if (element.foodCourtId === foodCourtDetail.foodCourtId)
        element.status = true;
    })
    this.selectedFoodCourt = foodCourtDetail;

    // to selected foodcourt add siteName and siteId
    this.selectedFoodCourt.siteId = this.siteId;
    this.selectedFoodCourt.siteName = (this.siteName ? this.siteName : "site");
    this.selectedFoodCourt.siteImgUrl = this.siteImageUrl;

    this.closeBottomSheet();
  }

  // Helper Function for One Time Use
  
}
