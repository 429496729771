import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HelpdeskService } from '../helpdesk.service';

@Component({
  selector: 'app-helpdesk-success-popup',
  templateUrl: './helpdesk-success-popup.component.html',
  styleUrls: ['./helpdesk-success-popup.component.scss']
})
export class HelpdeskSuccessPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<HelpdeskSuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _router : Router,
    private helpdeskService: HelpdeskService
  ) { }

  reminderMessage: string;
  sendLoader: boolean = false;
  ngOnInit(): void {
  }

  closePopup(){
    this.dialogRef.close();
    this._router.navigate(['profile/user/helpdesk-list']);
  }

  closeConfirmationPopup() {
    this.dialogRef.close();
  }

  sendReminder() {
    this.sendLoader = true;
    let reminderDetails = {
      "remindingMessage": this.reminderMessage,
      "userType": "USER"
    }
    this.helpdeskService.remindTicketToAdmin(this.data.data.ticketId, reminderDetails).subscribe((response)=> {
      this.dialogRef.close("SUCCESS");
      this.sendLoader = false;
    }, (err)=> {
      this.dialogRef.close();
      this.sendLoader = false;
    })
  }

}
