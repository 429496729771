import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timeStamp } from 'console';
import { LandingComponentService } from '../../../landing-component.service';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-overall-rating-popup',
  templateUrl: './overall-rating-popup.component.html',
  styleUrls: ['./overall-rating-popup.component.scss'],
})
export class OverallRatingPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<OverallRatingPopupComponent>,
    private _otherComponentService: OtherComponentService,
    private _landingComponentService: LandingComponentService
  ) {}

  ngOnInit(): void {
    if (this.data.for === 'OVERALL_RATING') {
      this.getRatingDetailsByOrderId();
    } else {
      if (!this.data.deliverBoyDetails.rating)
        this.data.deliverBoyDetails.rating = 5;
    }
  }

  ratingDetails = null;
  showLoader: boolean = false;

  getRatingDetailsByOrderId() {
    this.showLoader = true;
    this._otherComponentService
      .getOrderReviewByOrderId(this.data.orderId)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.showLoader = false;
          this.ratingDetails = res.data;
        },
        (err) => {
          //console.log(err);
          this.showLoader = false;
          this._landingComponentService.openSnackBarMessage(
            'Failed to get review details',
            'Ok'
          );
        }
      );
  }

  // selectedIconValue: number = 5;
  giveStarRating(index: number) {
    // this.selectedIconValue = index;
    this.data.deliverBoyDetails.rating = index;
    //console.log(this.data.deliverBoyDetails.rating);
  }

  submitDeliveryBoyRating() {
    //console.log(this.data.deliverBoyDetails);
    this.dialogRef.close(this.data.deliverBoyDetails);
  }

  closePopup() {
    this.dialogRef.close();
  }
}
