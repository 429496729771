<div>
    <div class="heading-text">Choose your meal</div>
    <ng-container *ngIf="!showloader; else ShowLoadingScreen">
        <mat-radio-group [(ngModel)]="selecteditemCategory">
            <mat-radio-button *ngFor="let mealTime of mealsAvailable" [value]="mealTime.type" [color]="'primary'">
                {{ mealTime.type | titlecase }}
            </mat-radio-button>
        </mat-radio-group>
        <div class="ShowQr"><button mat-flat-button color="warn" (click)="showQRCode()" [disabled]="!selecteditemCategory">Show QR</button></div>
    </ng-container>
    <ng-template #ShowLoadingScreen>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</div>