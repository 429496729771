import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SerachRestaurantsService {

  constructor(
    private http: HttpClient
  ) { }

  searchItemsRestaurants(foodCourtId, text) {
    return this.http.post(environment.baseUrl + `/foodcourt/search/ist/suggest/${foodCourtId}?name=${text}`, {})
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }
}
