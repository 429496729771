<div class="restaurant-view-section" id="menu-popup">
  <div class="restaurant-top-section">
    <div class="heading-icon-section">
      <div class="arrow-icon" (click)="goToHome()" *ngIf="showSearchIcon">
        <img src="../../../../../../assets/images/common/back_arrow.svg" alt="back" />
      </div>
      <div class="search-favourite-container" *ngIf="showSearchIcon">
        <img src="../../../../../../assets/images/search-page/search.svg" alt="" class="search"
          (click)="toggleSearchBar()" />
        <!-- <img src="../../../../../../assets/images/common/favourite_icon.svg" alt="" class="favourite"> -->
      </div>
      <div class="search-container" *ngIf="!showSearchIcon">
        <input type="text" placeholder="Search by item name" [(ngModel)]="searchText"
          (ngModelChange)="showAllItemList()" />
        <img src="../../../../../../assets/images/common/close.svg" alt="" (click)="toggleSearchBar()" />
        <div class="divider"></div>
        <img src="../../../../../../assets/images/search-page/search.svg" alt="" (click)="searchVendorFoodItemList()" />
      </div>
    </div>
    <div class="restaurant-info" *ngIf="scrWidth < 600">
      <div class="restaurant-name extra-margin1">
        {{ vendorDetails?.vendor?.brandName | titlecase }}
      </div>
      <!-- <div class="restaurant-type extra-margin1">Snacks, Pasteries, Bakery</div> -->
      <div class="restaurant-location extra-margin" *ngIf="
          vendorDetails?.vendorBusinessInfo?.locality ||
          vendorDetails?.vendorBusinessInfo?.city
        ">
        {{ vendorDetails?.vendorBusinessInfo?.locality | titlecase }},
        {{ vendorDetails.vendorBusinessInfo.city | titlecase }}
      </div>

      <div class="bottom-restaurant-info">
        <!-- <div class="restaurant-timing">
          <img
            src="../../../../../../assets/images/common/clock.svg"
            alt="clock"
          />
          <span class="timing"
            >{{ vendorDetails?.startTime }}-{{
              vendorDetails?.endTime
            }}
            minutes</span
          >
        </div> -->
        <div class="restaurant-rating extra-margin">
          <img src="../../../../../../assets/images/home-page/star.svg" alt="" />
          <div class="rating">
            {{ vendorDetails?.vendor?.rating | number: "1.0-1" }}
          </div>
          <div class="rating-count">
            ({{ vendorDetails?.vendor?.reviewCount }}+ Ratings)
          </div>
        </div>
        <div class="veg-toggle">
          <mat-slide-toggle class="example-margin" color="warn" [disabled]="showLoadingScreen" [(ngModel)]="isVegOnly"
            (ngModelChange)="getVendorFoodItemList()">
            Veg Only
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="restaurant-info-tab" *ngIf="scrWidth >= 600">
      <div>
        <div class="restaurant-name">
          {{ vendorDetails?.vendor?.brandName | titlecase }}
        </div>
        <!-- <div class="restaurant-type-tab">Snacks, Pasteries, Bakery</div> -->
      </div>
      <div class="veg-toggle-tab">
        <mat-slide-toggle class="example-margin" color="warn" [disabled]="showLoadingScreen" [(ngModel)]="isVegOnly"
          (ngModelChange)="getVendorFoodItemList()">
          Veg Only
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="coupons-list">
    <!-- <img src="../../../../../../assets/images/restaurant-page/discount-cover-1.svg" alt=""> -->

    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="slide" *ngFor="let discount of vendorCouponList; let i = index">
        <div class="coupon-container"
          [style.background]="'url(../../../../../../assets/images/restaurant-page/discount-cover-'+ ((i % 3) +1) + '.svg)' + ' ' + 'no-repeat'"
          [style.backgroundSize]="'cover'" (click)="openDiscountDetails(discount)">
          <img src="../../../../../../assets/images/restaurant-page/discount-white.svg" alt="">
          <div class="coupon-description">
            <div class="coupon-name"> {{ discount.discountName | titlecase }} </div>
            <div class="coupon-code">{{ discount.discountCouponCode }} <span
                *ngIf="discount.discountConstraints.minimumOrderAmount"> | Above
                &#8377;{{discount.discountConstraints.minimumOrderAmount}}</span></div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="restaurant-main-section">
    <div class="meal-type-list">
      <ng-container *ngFor="let meal of MEALS">
        <div class="meal-chip" (click)="getItemsByMealType(meal)" [ngClass]="{ 'meal-chip_active': meal.status }">
          {{ meal.name }}
        </div>
      </ng-container>
    </div>
    <div class="freebie-discount-list">
      <ng-container *ngFor="let freebieCoupon of freebieTypeList">
        <div class="freebie-container" [ngClass]="{'freebie-selected': freebieCoupon.status}"
          (click)="selectFreebieType(freebieCoupon)">{{ freebieCoupon.buyType.split("_").join(" ") | titlecase }} {{
          freebieCoupon.getType.split("_").join(" ") | titlecase }}</div>
      </ng-container>
    </div>
    <ng-container *ngIf="!cuisineListAPIFailure && !itemListAPIFailure; else showFailedToGetItemContent">
      <div *ngIf="scrWidth < 600">
        <div class="menu-item-list" *ngIf="!showLoadingScreen; else loadingScreen">
          <ng-container *ngIf="resturantItems.length > 0; else emptyScreen">
            <div tabindex="0" style="outline: none;" #itemByCategoryList
              *ngFor="let itemCategory of CATEGORY_LIST; let i = index">
              <ng-container>
                <div class="category-heading-text">
                  <span class="category-text">{{ itemCategory | titlecase }} - ({{
                    resturantListByCategory[itemCategory].count }})</span>
                  <img src="../../../../../../assets/images/common/down_arrow.svg" alt="" class="arrow"
                    [ngClass]="{'rotate-arrow': !resturantListByCategory[itemCategory].status}"
                    (click)="toggleDropdown(resturantListByCategory[itemCategory])" />
                </div>
                <ng-container *ngIf="resturantListByCategory[itemCategory].status">
                  <div class="sub-category-item-container"
                    *ngFor="let itemSubCategory of resturantListByCategory[itemCategory].content | keyvalue">
                    <div class="heading-text">
                      <span class="text">{{ itemSubCategory.key | titlecase }} - ({{
                        itemSubCategory.value?.content.length }}) </span>
                      <img src="../../../../../../assets/images/common/down_arrow.svg" alt="" class="arrow"
                        [ngClass]="{'rotate-arrow': !itemSubCategory.value.status}"
                        (click)="toggleDropdown(itemSubCategory.value)" />
                    </div>
                    <ng-container *ngIf="itemSubCategory.value.status">
                      <div class="menu-items" *ngIf="itemSubCategory.value.content.length > 0">
                        <ng-container *ngFor="let item of itemSubCategory.value.content">
                          <div class="item-container">
                            <div class="trending-icon-container" *ngIf="item.trending">
                              <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                            </div>
                            <div class="item-img-container">
                              <img *ngIf="item.itemImageUrl" loading="lazy" [src]="item.itemImageUrl" alt="" />
                              <img *ngIf="!item.itemImageUrl"
                                src="../../../../../../assets/images/common/default_food_image.svg" alt="" />
                            </div>
                            <div class="item-description-container">
                              <div class="item-heading">

                                <div class="freebie-text" *ngIf="item.discountName">
                                  <img src="../../../../../../assets/images/restaurant-page/freebie-star.svg" alt="">
                                  <span>{{ item.discountName | titlecase }}</span>
                                </div>
                                <div class="detail-heading">
                                  <img *ngIf="item.itemType === 'VEG'"
                                    src="../../../../../../assets/images/common/veg-item.svg" alt="" class="veg-icon" />
                                  <img *ngIf="item.itemType === 'NON_VEG'"
                                    src="../../../../../../assets/images/common/non-veg-item.svg" alt=""
                                    class="veg-icon" />
                                  <span class="item-name" *ngIf="item.name?.length <= 28; else showTrimmedName">{{
                                    item.name | titlecase }}</span>

                                  <ng-template #showTrimmedName>
                                    <span class="item-name">{{ item.name.substring(0, 24) | titlecase }}..
                                    </span>
                                  </ng-template>
                                </div>
                                <div class="customization-text">
                                  <span *ngIf="item.customizationRequired">Customisable</span>
                                </div>
                              </div>
                              <div class="item-actions">
                                <!-- <span class="item-price">&#8377;{{ (item.price / 100) | number: "1.0-1" }}</span> -->
                                <span class="item-price">&#8377;{{ (item.markUpPrice) | number: "1.0-1" }}</span>
                                <div class="item-rating">
                                  <img src="../../../../../../assets/images/home-page/star.svg" alt="">
                                  <span>{{ item.rating | number:'1.0-1' }}</span>
                                </div>
                                <div class="add-btn" [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow}"
                                  *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OutOfStock">
                                  <ng-container
                                    *ngIf="!showRunTimeLoader || itemIdForLoading !== item.itemId;else runTimeLoading;else: runTimeLoading">
                                    <button class="add-item"
                                      *ngIf="item.showCustomQuantity === 0; else showItemIncDecResturant"
                                      (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                      Add
                                    </button>
                                    <ng-template #showItemIncDecResturant>
                                      <ng-container *ngIf="!isIntoPreOrderingFlow; else showFreezedCountResturant">
                                        <div class="add-to-cart-btn">
                                          <button class="minus" (click)="decreaseItemCount(item)">
                                            -
                                          </button>
                                          <span class="item-count">{{ item.showCustomQuantity }}</span>
                                          <button class="plus"
                                            (click)="addItemAfterCustomizationCheck(item, 'INCREASE')">
                                            +
                                          </button>
                                        </div>
                                      </ng-container>
                                      <ng-template #showFreezedCountResturant>
                                        <div class="add-to-cart-btn-freezed"
                                          (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                          <button class="minus">-</button>
                                          <span class="item-count">{{ item?.showCustomQuantity }}</span>
                                          <button class="plus">+</button>
                                        </div>
                                      </ng-template>
                                    </ng-template>
                                  </ng-container>
                                </div>
                                <ng-template #OutOfStock>
                                  <div class="out-of-stock">Out of Stock</div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #emptyScreen>
            <div class="empty-content-container">
              <img src="../../../.../../../../../assets/images/common/empty-menu.svg" alt="" />
              <div class="heading-text">Ooh!</div>
              <div class="secondary-text">Item list is empty</div>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="scrWidth >= 600">
        <div class="menu-item-list-desktop-version" *ngIf="!showLoadingScreen; else loadingScreen">
          <ng-container *ngIf="resturantItems.length > 0; else emptyScreen">
            <div tabindex="0" style="outline: none;" #itemByCategoryList
              *ngFor="let itemCategory of CATEGORY_LIST; let i = index">
              <div class="category-heading-text">
                <span class="category-text">{{ itemCategory | titlecase }} - ({{
                  resturantListByCategory[itemCategory].count }})</span>
                <img src="../../../../../../assets/images/common/down_arrow.svg" alt="" class="arrow"
                  [ngClass]="{'rotate-arrow': !resturantListByCategory[itemCategory].status}"
                  (click)="toggleDropdown(resturantListByCategory[itemCategory])" />
              </div>
              <ng-container *ngIf="resturantListByCategory[itemCategory].status">
                <ng-container *ngFor="let itemSubCategory of resturantListByCategory[itemCategory].content | keyvalue">
                  <div class="heading-text">
                    <span class="text">{{ itemSubCategory.key | titlecase }} - ({{ itemSubCategory.value?.content.length
                      }}) </span>
                    <img src="../../../../../../assets/images/common/down_arrow.svg" alt="" class="arrow"
                      [ngClass]="{'rotate-arrow': !itemSubCategory.value.status}"
                      (click)="toggleDropdown(itemSubCategory.value)" />
                  </div>
                  <div class="menu-items" *ngIf="itemSubCategory.value.status">
                    <ng-container *ngFor="let item of itemSubCategory.value.content">
                      <div class="item-container-dv">
                        <div class="trending-icon-container" *ngIf="item.trending">
                          <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                        </div>
                        <div class="item-img-container">
                          <img *ngIf="item.itemImageUrl" loading="lazy" [src]="item.itemImageUrl" alt="" />
                          <img *ngIf="!item.itemImageUrl"
                            src="../../../../../../assets/images/common/default_food_image.svg" alt="" />
                        </div>
                        <div class="item-description-container">
                          <div class="item-heading">

                            <div class="freebie-text" *ngIf="item.discountName">
                              <img src="../../../../../../assets/images/restaurant-page/freebie-star.svg" alt="">
                              <span>{{ item.discountName | titlecase }}</span>
                            </div>
                            <div class="detail-heading">
                              <img *ngIf="item.itemType === 'VEG'"
                                src="../../../../../../assets/images/common/veg-item.svg" alt="" class="veg-icon" />
                              <img *ngIf="item.itemType === 'NON_VEG'"
                                src="../../../../../../assets/images/common/non-veg-item.svg" alt="" class="veg-icon" />
                              <span class="item-name" *ngIf="item.name?.length <= 28; else showTrimmedName">{{ item.name
                                | titlecase }}</span>

                              <ng-template #showTrimmedName>
                                <span class="item-name">{{ item.name.substring(0, 24) | titlecase }}..
                                  <!-- <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon> -->
                                </span>
                              </ng-template>
                            </div>
                            <div class="customization-text">
                              <span *ngIf="item.customizationRequired">Customisable</span>
                            </div>
                          </div>
                          <div class="item-actions">
                            <span class="item-price">
                              <span *ngIf="item?.taxCategory === 'MRP'" class="mrp-text">MRP : </span>
                              <!-- &#8377;{{ (item.price / 100) | number: "1.0-1" }} -->
                              &#8377;{{ (item.markUpPrice) | number: "1.0-1" }}
                            </span>
                            <div class="item-rating">
                              <img src="../../../../../../assets/images/home-page/star.svg" alt="">
                              <span>{{ item.rating | number:'1.0-1' }}</span>
                            </div>
                            <div class="add-btn" [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow}"
                              *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OutOfStock">
                              <ng-container
                                *ngIf="!showRunTimeLoader || itemIdForLoading !== item.itemId;else runTimeLoading;else: runTimeLoading">
                                <button class="add-item"
                                  *ngIf="item.showCustomQuantity === 0; else showItemIncDecResturant"
                                  (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                  Add
                                </button>
                                <ng-template #showItemIncDecResturant>
                                  <ng-container *ngIf="!isIntoPreOrderingFlow; else showFreezedCountResturant">
                                    <div class="add-to-cart-btn">
                                      <button class="minus" (click)="decreaseItemCount(item)">
                                        -
                                      </button>
                                      <span class="item-count">{{ item.showCustomQuantity }}</span>
                                      <button class="plus" (click)="addItemAfterCustomizationCheck(item, 'INCREASE')">
                                        +
                                      </button>
                                    </div>
                                  </ng-container>
                                  <ng-template #showFreezedCountResturant>
                                    <div class="add-to-cart-btn-freezed"
                                      (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                      <button class="minus">-</button>
                                      <span class="item-count">{{ item?.showCustomQuantity }}</span>
                                      <button class="plus">+</button>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </ng-container>
                            </div>
                            <ng-template #OutOfStock>
                              <div class="out-of-stock">Out of Stock</div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #emptyScreen>
            <div class="empty-content-container">
              <img src="../../../.../../../../../assets/images/common/empty-menu.svg" alt="" />
              <div class="heading-text">Ooh!</div>
              <div class="secondary-text">Item list is empty</div>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-template #loadingScreen>
        <div class="loading-img-container">
          <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
        </div>
      </ng-template>
    </ng-container>
    <ng-template #showFailedToGetItemContent>
      <div class="api-error-container">
        <h5>Failed to get Food Item List</h5>
        <span>Plz try again in sometime.</span>
        <button (click)="getAllCuisines()">Try Again</button>
      </div>
    </ng-template>
    <div class="menu-popup" (click)="openFilterFoodType()" *ngIf="resturantItems.length > 0 && !showLoadingScreen">Menu
    </div>
  </div>

  <ng-container *ngIf="cartDetails.noOfItems > 0 && scrWidth < 600">
    <app-cart-footer [cartDetails]="cartDetails"></app-cart-footer>
  </ng-container>

  <ng-template #runTimeLoading>
    <button class="add-item">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
  </ng-template>
</div>