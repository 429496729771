import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { DiscountFlowService } from '../discount-flow.service';

@Component({
  selector: 'app-resturant-discount-screen',
  templateUrl: './resturant-discount-screen.component.html',
  styleUrls: ['./resturant-discount-screen.component.scss', './mq-resturant-discount-screen.component.scss']
})
export class ResturantDiscountScreenComponent implements OnInit {

  constructor(
    private discountFlowService: DiscountFlowService,
    private landingComponentService: LandingComponentService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  resturantList = [];
  isVegOnly: boolean = false;
  isNonVegOnly: boolean = false;
  discountType:string = '';
  searchText: string = null;
  showLoader: boolean = false;

  ngOnInit(): void {
    this.getScreenSize();

    this.activatedRoute.params.subscribe((param)=> {
      this.discountType = param.type.toUpperCase();
    })
    if (this.discountType !== "FREEBIES" && this.discountType !== "FIXED_AMOUNT" && this.discountType !== "PERCENTAGE") {
      let foodCourtUrl = this.landingComponentService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();
      this.router.navigate([`${foodCourtUrl}`]);
    }
    this.getResturantList();
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  getResturantList() {

    this.showLoader = true;

    let foodcourtId = this.landingComponentService.getFoodCourtId();
    let menuType = null;
    if (this.isVegOnly && this.isNonVegOnly) {
      menuType = "BOTH";
    } else if (!this.isNonVegOnly && !this.isVegOnly) {
      menuType = null;
    } else if (this.isNonVegOnly && !this.isVegOnly) {
      menuType = "NON_VEG";
    } else {
      menuType = "VEG";
    }


    this.discountFlowService.getResturantListByFilter(foodcourtId, menuType, this.searchText, this.discountType).subscribe((response:any)=> {
      this.resturantList = response.data;
      this.showLoader = false;
    }, (err)=> {

    })
  }

  navigateToVendorPage(vendorDetails) {
    this.landingComponentService.setVendorId(vendorDetails.vendorId);
    let vendorUrl = vendorDetails?.brandName
      ? vendorDetails?.brandName?.split(' ')?.join('-').toLowerCase()
      : ' ';
    let foodCourtUrl = this.landingComponentService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();
    this.router.navigate([
      `restaurant/${foodCourtUrl}/${vendorUrl}`,
    ]);
  }

}
