<ng-container *ngIf="!errorOnAPI; else showErrorOnAPIContent">
  <div class="section">
    <!-- <div class="header">
          <img src="./../../../../../../assets/images/common/isthara_foodcourt1.svg" />
      </div> -->
    <ng-container *ngIf="!showLoader; else loaderScreen">
      <div class="main-part" *ngIf="cartItemList?.length > 0; else emptyscreen">
        <ng-container *ngFor="let item of cartItemList">
          <div class="main">
            <div class="order-list">
              <img *ngIf="item.imgUrl" loading="lazy" [src]="item.imgUrl" />
              <img *ngIf="!item.imgUrl" src="./../../../../../../assets/images/common/default_food_image.svg" alt="" />
            </div>
            <div class="order-item-details">
              <div class="order-item">
                <div class="order-item-vendor">
                  <div class="order-item-heading">
                    <img *ngIf="item.veg" src="../../../../../../assets/images/cart/veg-item.svg" class="veg-icon"
                      alt="" />
                    <img *ngIf="!item.veg" src="../../../../../../assets/images/cart/non-veg-item.svg" class="veg-icon"
                      alt="" />
                    <span *ngIf="item?.itemName?.length < 20; else showTrimeedItemName">{{ item?.itemName | titlecase
                      }}</span>
                    <ng-template #showTrimeedItemName>
                      <span>{{ item?.itemName?.substring(0, 15) }}...</span>
                    </ng-template>
                  </div>
                  <div class="vendor-name">
                    {{ item?.vendorName.substring(0, 15) | titlecase }}
                  </div>
                </div>
                <div class="order-item-text" *ngIf="item.shownCustomizedItemsName"
                  (click)="showSelectedCustomization(item, true)">
                  <div>
                    {{ item.shownCustomizedItemsName.substring(0, 15) | titlecase}}...
                  </div>

                  <div class="customize-text">
                    Customize
                    <span class="arrow">
                      <img src="./../../../../../../assets/images/cart/arrow_forward.svg" alt="right arrow" />
                    </span>
                  </div>
                </div>

                <!-- <div class="order-item-digit"> - {{item.itemQuantity}} + </div> -->
                <!-- <div
                  class="item-status"
                  *ngIf="
                    item.itemStatus !== 'IN_STOCK' &&
                    item.itemStatus !== 'VENDOR_AVAILABLE'
                  "
                >
                  Out of stock
                </div> -->
                <div class="status-price">
                  <ng-container *ngIf="
                      item.itemStatus === 'IN_STOCK' ||
                        item.itemStatus === 'VENDOR_AVAILABLE';
                      else ItemOutOfStock
                    ">
                    <ng-container *ngIf="
                        !showRunTimeLoader || itemIdForLoading !== item.appItemId;
                        else runTimeLoading
                      ">
                      <div class="add-to-cart-btn">
                        <button class="minus" (click)="decreaseItemCount(item)">
                          -
                        </button>
                        <span class="item-count">{{ item.showCustomQuantity }}</span>
                        <button class="plus" (click)="increaseItemCount(item)">
                          +
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #ItemOutOfStock>
                    <div class="out-of-stock-status" (click)="clearItemFromCart(item)">
                      <span class="item-status">Out of Stock</span>
                      <button class="remove-item">Remove</button>
                    </div>
                  </ng-template>
                  <div class="order-amount">
                    <div class="order-amount-digit">
                      <span *ngIf="item?.taxCategory === 'MRP'" class="mrp-text">MRP : </span>
                      <!-- ₹{{ (item.totalPrice / 100) | number: "1.0-1" }} -->
                      ₹{{ (item.totalPrice) | number: "1.0-2" }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="order-amount">
                <div class="order-amount-digit">₹{{ item.price / 100 }}</div>
              </div> -->
            </div>
          </div>
        </ng-container>

        <div class="description-box">
          <textarea [(ngModel)]="orderNote" name="" id=""
            [placeholder]="(!(isBTCEnabled) && !(isBTCRequestUpdationFlow)) ? 'Any Suggestion ? We will pass it on' : 'Any Suggestion / BTC Request Reason'"></textarea>

          <!-- <div>Any Suggestion ? We Will Pass it On</div> -->
        </div>

        <div class="offer-part" [ngClass]="{ 'coupon-applied': billDetails.couponCode }"
          (click)="viewCouponsPage(billDetails.couponCode)">
          <div class="coupon-text">
            <!-- <span class="percentage">%</span> -->
            <img src="../../../../../../assets/images/cart/coupon.svg" alt="" loading="lazy" height="25px" width="25px">
            <div *ngIf="!billDetails.couponCode">Apply Coupon</div>
            <div *ngIf="billDetails.couponCode">
              Code {{ billDetails.couponCode | uppercase }} applied!
            </div>
          </div>
          <span class="arrow-right">
            <img src="./../../../../../../assets/images/cart/arrow_forward.svg" *ngIf="!billDetails.couponCode" />
            <img src="../../../../../../assets/images/cart/cancel_coupon.svg" alt="" *ngIf="billDetails.couponCode"
              (click)="removeCoupon()" />
          </span>
        </div>

        <div class="bill-details">
          <div class="order-bill">
            <div>Bill Details</div>
          </div>
          <div class="total-item">
            <div class="items-text">Item Total</div>
            <div class="items-amt">
              <!-- ₹{{ billDetails.breakup["Item Total"] / 100 | number: "1.2-2" }} -->
              ₹{{ billDetails.breakup["Item Total"] | number: "1.2-2" }}
            </div>
          </div>
          <hr class="line" />

          <div class="total-item" *ngIf="billDetails.couponCode">
            <!-- <div class="tax">Discount Aplied <span class="i-btn" (click)="openCouponDetails()"><mat-icon>info</mat-icon></span> </div> -->
            <div class="tax">Discount Applied</div>
            <div class="tax-amt discount-text">
              <!-- - ₹{{ (billDetails.breakup["Item Discount"] || 0) / 100 | number: "1.2-2" }} -->
              - ₹{{ (billDetails.breakup["Item Discount"] || 0) | number: "1.2-2" }}
            </div>
          </div>
          <hr class="line" *ngIf="billDetails.couponCode" />

          <div class="total-item" *ngIf="billDetails.breakup['Packing Charges']">
            <!-- <div class="tax">Packaging Charges <span class="i-btn" (click)="openPackagingChargesDetails()"><mat-icon>info</mat-icon></span> </div> -->
            <div class="tax">Packaging Charges</div>
            <div class="tax-amt">
              <!-- ₹{{billDetails.breakup["Packing Charges"] / 100 | number: "1.2-2"}} -->
              ₹{{billDetails.breakup["Packing Charges"] | number: "1.2-2"}}
            </div>
          </div>
          <hr class="line" *ngIf="billDetails.breakup['Packing Charges']" />

          <div class="total-item">
            <div class="tax">Taxes and Charges</div>
            <div class="tax-amt"
              *ngIf="billDetails.breakup['Delivery Charges'] || billDetails.breakup['Delivery Charges'] === 0; else showTaxAndChanges">
              <!-- ₹{{ (billDetails.breakup["Taxes & charge"] - (billDetails.breakup["Delivery Charges"] * 0.18)) / 100 | number: "1.2-2" }} -->
              ₹{{ (billDetails.breakup["Taxes & charge"] - (billDetails.breakup["Delivery Charges"] * 0.18)) | number:
              "1.2-2" }}
            </div>
            <ng-template #showTaxAndChanges>
              <div class="tax-amt">
                <!-- ₹{{ (billDetails.breakup["Taxes & charge"]) / 100 | number: "1.2-2" }} -->
                ₹{{ (billDetails.breakup["Taxes & charge"]) | number: "1.2-2" }}
              </div>
            </ng-template>
          </div>
          <hr class="line" />

          <div class="total-item"
            *ngIf="billDetails.breakup['Delivery Charges'] || billDetails.breakup['Delivery Charges'] === 0">
            <div class="tax">Delivery Charges</div>
            <div class="tax-amt">
              <!-- ₹{{ billDetails.breakup["Delivery Charges"] / 100 | number: "1.2-2" }} -->
              ₹{{ billDetails.breakup["Delivery Charges"] | number: "1.2-2" }}
            </div>
          </div>
          <hr class="line"
            *ngIf="billDetails.breakup['Delivery Charges'] || billDetails.breakup['Delivery Charges'] === 0" />

          <div class="total-item"
            *ngIf="billDetails.breakup['Delivery Charges'] || billDetails.breakup['Delivery Charges'] === 0">
            <div class="tax">GST on Delivery Charges</div>
            <div class="tax-amt">
              <!-- ₹{{ ((billDetails.breakup["Delivery Charges"] * 0.18) / 100) | number: "1.2-2"}} -->
              ₹{{ ((billDetails.breakup["Delivery Charges"] * 0.18)) | number: "1.2-2"}}
            </div>
          </div>
          <hr class="line"
            *ngIf="billDetails.breakup['Delivery Charges'] || billDetails.breakup['Delivery Charges'] === 0" />

          <div class="total-item">
            <div class="total-text">Total Payable</div>
            <div class="items-amt">
              <!-- ₹{{ billDetails.finalPrice / 100 | number: "1.2-2" }} -->
              ₹{{ billDetails.finalPrice | number: "1.2-2" }}
            </div>
          </div>
        </div>

        <div class="redeem-points">
          <div class="points-container">
            <div class="points-heading">Redeem Points</div>
            <div class="points-value">
              <span>Available : </span><span><img src="../../../../../../assets/images/home-page/isthara-points.svg"
                  alt="" /></span>{{ userPoints.istharaPoints | number: "1.1-2" }}
            </div>
          </div>
          <div class="toggle">
            <mat-slide-toggle color="warn" (change)="toggleUserPoints($event)"
              [(ngModel)]="doPayWithIstharaPoint"></mat-slide-toggle>
          </div>
        </div>

        <ng-container *ngIf="preOrderDetails">
          <div class="preordering-section">
            <div class="preordering-details-meal-type">
              <div class="preordering-primary-text">Meal Type</div>
              <div class="preordering-details">
                <div>{{ preOrderMealCategory | titlecase }}</div>
                <div class="change-preorder-details" (click)="changePreOrdering()">Change</div>
              </div>
            </div>
            <div class="preordering-details-timeslot">
              <div class="preordering-primary-text">Time Slot</div>
              <div class="preordering-details">
                <div class="preordering-time">{{ preOrderDetails.orderDate | date }} at {{ preOrderDetails.startTime |
                  date:'shortTime' }} - {{ preOrderDetails.endTime | date:'shortTime' }}</div>
                <div class="change-preorder-details" (click)="showPreOrderMenuTiming()">
                  <img src="../../../../../../assets/images/cart/preordering-timeslot.svg" alt="">
                </div>
              </div>
              <div class="menu-timing-list" *ngIf="togglePreOrderTimeSlotSelection">
                <mat-form-field appearance="fill">
                  <mat-select>
                    <mat-option *ngFor="let timeSlot of menuTimingSlot" [value]="timeslot"
                      [disabled]="timeSlot.isBlocked" (click)="changePreOrderTimeSlot(timeSlot)">
                      {{timeSlot.startTime | date:'shortTime'}} - {{timeSlot.endTime | date:'shortTime'}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="delivery-options">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="orderType" (ngModelChange)="changeOrderType()">
            <ng-container *ngFor="let order of ORDER_OPTIONS">
              <mat-radio-button [value]="order.value" [color]="'primary'"
                *ngIf="order.value === 'DELIVERY' ? fcHasDelivery : true">{{ order.name | titlecase
                }}</mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

        <div class="delivery-details" *ngIf="orderType === 'DELIVERY'">
          <div class="delivery-heading">Delivery Details</div>
          <div class="delivery-fee-detail">
            <div class="fee-heading">Delivery Fee</div>
            <div class="fee-value">
              &#8377;
              <!-- {{
                (((billDetails.breakup["Delivery Charges"]
                  ? billDetails.finalPrice -
                    billDetails.breakup["Delivery Charges"]
                  : billDetails.finalPrice) > foodcourtDetails.minOrderAmount
                  ? foodcourtDetails.deliveryCharges / 100
                  : foodcourtDetails.minDeliveryCharges / 100
                ) | number: "1.1-2") || 0
              }} -->
              <!-- {{ billDetails.breakup["Delivery Charges"] / 100 | number: "1.2-2" }} -->
              {{ billDetails.breakup["Delivery Charges"] | number: "1.2-2" }}
            </div>
          </div>
        </div>

        <div class="address" *ngIf="orderType === 'DELIVERY' && !deliveryAddress">
          <div class="address-text">You have not added any address.</div>
          <div class="address-link" (click)="addNewAddress()">
            Add New Location
          </div>
        </div>

        <div class="address-details-section" *ngIf="orderType === 'DELIVERY' && deliveryAddress">
          <div class="address-img">
            <img src="../../../../../../assets/images/cart/default-address.svg" alt="" />
          </div>
          <div class="address-details">
            <div class="address-heading">
              Deliver to
              {{ deliveryAddress.addressTitle | titlecase }}
            </div>
            <div class="address-value">
              {{ deliveryAddress.address | titlecase }}
            </div>
          </div>
          <div class="change-address" (click)="addNewAddress()">Change</div>
        </div>

        <div class="total-box-container">
          <!-- !(isBTCEnabled) && !(isBTCRequestUpdationFlow) -->
          <ng-container
            *ngIf="(!(isBTCEnabled) && !(isBTCRequestUpdationFlow)) || (preOrderDetails); else showBTCApprovalButtons">
            <button class="total-box" (click)="paymentFlow()" *ngIf="!buttonLoading; else loader"
              [disabled]="orderType === 'DELIVERY' ? !deliveryAddress : false">
              Payable Amount ₹{{ billDetails.totalPayableAmt | number: "1.1-2" }}
            </button>
          </ng-container>
          <ng-template #showBTCApprovalButtons>
            <div class="btc-payment-request-container">
              <ng-container *ngIf="!isBTCRequestUpdationFlow; else showBTCRequestUpdation">

                <button class="btc__pay-now-btn" (click)="paymentFlow()"
                  *ngIf="!payBTCRequestLoader; else showBTCRequestPayLoader"
                  [disabled]="orderType === 'DELIVERY' ? !deliveryAddress : sendBTCRequestLoader">Pay ₹{{
                  billDetails.totalPayableAmt | number: "1.1-2" }}</button>
                <button class="btc__request-corporate-order" (click)="requestCorporateOrder()"
                  *ngIf="!sendBTCRequestLoader; else showBTCRequestLoader">Request Corporate Order</button>
                <ng-template #showBTCRequestLoader>
                  <button class="btc__request-corporate-order">
                    <i class="fa fa-spinner fa-spin"></i> Loading...
                  </button>
                </ng-template>
                <ng-template #showBTCRequestPayLoader>
                  <button class="btc__pay-now-btn">
                    <i class="fa fa-spinner fa-spin"></i> Loading...
                  </button>
                </ng-template>
              </ng-container>

              <ng-template #showBTCRequestUpdation>
                <button class="btc__update-request-corporate-order" (click)="updateBTCRequest()"
                  *ngIf="!updateBTCRequestLoader; else showUpdateBTCRequestLoader">Update BTC Request</button>
                <ng-template #showUpdateBTCRequestLoader>
                  <button class="btc__update-request-corporate-order">
                    <i class="fa fa-spinner fa-spin"></i> Loading...
                  </button>
                </ng-template>
              </ng-template>
            </div>
          </ng-template>

          <ng-template #loader>
            <button class="total-box">
              <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
            </button>
          </ng-template>
        </div>
      </div>

      <!-- <form id="redirectForm" method="post" [action]="formDataView.paymentLink"> -->
      <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit

        production ===> https://www.cashfree.com/checkout/post/submit-->
      <!-- <input
          id="appId"
          type="hidden"
          name="appId"
          [value]="formDataView.appId"
        />
        <input type="hidden" name="orderId" [value]="formDataView.orderId" />
        <input
          type="hidden"
          name="orderAmount"
          [value]="formDataView.orderAmount"
        />
        <input type="hidden" name="orderCurrency" [value]="'INR'" />
        <input type="hidden" name="orderNote" [value]="'Order'" />
        <input
          type="hidden"
          name="customerName"
          [value]="formDataView.customerName"
        />
        <input
          type="hidden"
          name="customerEmail"
          [value]="formDataView.customerEmail"
        />
        <input
          type="hidden"
          name="customerPhone"
          [value]="formDataView.customerPhone"
        />
        <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
        <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
        <input type="hidden" name="signature" [value]="formDataView.signature" />
      </form> -->
    </ng-container>
    <ng-template #loaderScreen>
      <div class="loader-img-container">
        <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
      </div>
    </ng-template>
    <ng-template #runTimeLoading>
      <button class="add-item">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </ng-template>
  </div>
  <ng-template #emptyscreen>
    <div class="empty-part">
      <div class="no-image">
        <img src="../../../../../../assets/images/cart/empty-cart.svg" alt="empty-image" />
      </div>
      <div class="empty-heading">Your plate is empty!!</div>
      <div class="empty-subtext">Fill it up with your favourate food</div>
      <!-- <div class="emptyrules-text">the menu</div> -->
    </div>
  </ng-template>
</ng-container>
<ng-template #showErrorOnAPIContent>
  <div class="api-error-container">
    <h2>ERROR</h2>
    <span class="primary-text">Failed to get your cart data</span>
    <span class="secondary-text">Plz try again after sometime</span>
    <button class="try-again-btn" (click)="reloadPage()">Try Again</button>
  </div>
</ng-template>