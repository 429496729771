import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { PopupDbService } from './popup-db.service';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../../../environments/environment';

interface ErrorObj {
  status: number | null;
  message: string | null;
  request: any
}

@Injectable()
export class ErpMainInterceptor implements HttpInterceptor {
  constructor(
    private dbStorageService: PopupDbService,
    private http: HttpClient
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const API_KEY = this.dbStorageService.getToken();
    if (API_KEY) {
      return next.handle(
        request.clone({
          setHeaders: { Authorization: `${API_KEY}` },
        })
      ).pipe(
        catchError((error:HttpErrorResponse) => {

          let errorMsgObj: ErrorObj;
          errorMsgObj = {
            status: error.status,
            message: error.message || null,
            request: request
          }
          if(error.status === 401){
            this.handle401Error(request, next);
          }

          // console.log(error.message)
          // return Observable.throw(err);
          return throwError(errorMsgObj);
          
        })
      )
    } else {
      return next.handle(request);
    }
  }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().subscribe((token: any) => {
        console.log('refreshed token: ', token);
        this.dbStorageService.setToken(token.data);
        window.location.reload();
        // information on token as header has to come from chaithanya he is checking it.
        // this.isRefreshing = false;
        // this.refreshTokenSubject.next(token.jwt);
        // return next.handle(this.addToken(request, token.jwt));
      });
    } else {
      return this.refreshTokenSubject.pipe();
    }
  }

  refreshToken() {
    return this.http.post(
      environment.baseUrl + `/gateway/token`,
      null
    );
  }
}
