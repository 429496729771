import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LandingComponentService } from '../../../landing-component.service';
import { OtherComponentService } from '../other-component.service';
@Component({
  selector: 'app-userpointsfilter',
  templateUrl: './userpointsfilter.component.html',
  styleUrls: ['./userpointsfilter.component.scss']
})
export class UserpointsfilterComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<UserpointsfilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private otherComponentService: OtherComponentService,
    private landingComponentService: LandingComponentService
  ) { }

  startDate;
  endDate;
  rewardPointType;

  seasons: any = [
    // { name: 'Cancel Order', value: "CANCELLED_ORDER", status: false },
    // { name: 'Item Out Of Stock', value: "OUT_OF_STOCK", status: false },
    // { name: 'Payment Failure', value: "PAYMENT_FAILURE", status: false },
    // { name: 'Recharge', value: "POINTS_RECHARGE", status: false }

    { name: 'Paid', value: "PAID", status: false },
    { name: 'Refund', value: "REFUND", status: false },
    { name: 'Recharge', value: "RECHARGE", status: false }
  ];


  ngOnInit(): void {
    
    if (this.data.filterFor === "POINTS_HISTORY") {
      this.seasons = [    
        { name: 'Paid', value: "PAID", status: false },
        { name: 'Refund', value: "REFUND", status: false },
        { name: 'Recharge', value: "RECHARGE", status: false }
      ]
    } else if (this.data.filterFor === "BANK_REFUNDS") {
      this.seasons = [
        { name: 'All', value: null, status: false },
        { name: 'In Progress', value: "REFUND_INITIATED", status: false },
        { name: "Refunded", value: "REFUND_COMPLETED", status: false }
      ]
    }
  }
  selectRewardPointType() {

  }
  closebtn() {
    this.dialogRef.close();
  }
  displayMe = true;
  employmentdownarrow() {
    this.displayMe = !this.displayMe;
  }

  isLoading: boolean = false;
  applyFilter() {
    this.isLoading = true;

    let userId = this.otherComponentService.getUserId();

    let startDate;
    let year;
    let month;
    let date
    if(this.startDate) {
      startDate = new Date(this.startDate);
      year = startDate.getFullYear();
      month = startDate.getMonth();
      date = startDate.getDate();
      date = date + 1;

      startDate = new Date(year, month, date);
    }

    let endDate;
    if(this.endDate) {
      endDate = new Date(this.endDate);
      year = endDate.getFullYear();
      month = endDate.getMonth();
      date = endDate.getDate();
      date = date + 1;

      endDate = new Date(year, month, date);
    }

    let data = {
      "endDateTime": this.endDate ? endDate : null,
      "startDateTime": this.startDate ? startDate : null,
      "userId": userId
    }

    if (this.data.filterFor === "POINTS_HISTORY") {
      data["paymentType"] = this.rewardPointType;
    } else if (this.data.filterFor === "BANK_REFUNDS") {
      data["refundStatus"] = this.rewardPointType;
    }

    this.otherComponentService.filterUserIstharaPoints(data).subscribe((response: any)=> {
      this.isLoading = false;
      this.dialogRef.close({filterData: response.data});
    }, (err)=> {
      this.isLoading = false;
      this.landingComponentService.openSnackBarMessage("Uh oh, something went wrong, please try again");
    })
  }
}
