import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  constructor(
    private http: HttpClient
  ) { }

  getHomePageData(foodCourtId, userId = null) {
    if (userId)
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}?userid=${userId}`, {})
    else
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}`, {})
  }
  getFoodCourtDetails(foodCourtId) {
    return this.http.get(environment.baseUrl + `/foodcourt/foodCourt/ist/${foodCourtId}`);
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }
}
