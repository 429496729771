import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-successfully-subscribed-page',
  templateUrl: './successfully-subscribed-page.component.html',
  styleUrls: ['./successfully-subscribed-page.component.scss']
})
export class SuccessfullySubscribedPageComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SuccessfullySubscribedPageComponent>,
  ) {}

  ngOnInit(): void {
  }

  closeDialogBox() {
    this.dialogRef.close();
  }

  viewSubscriptionDetails() {
    this.dialogRef.close();
  }
}
