import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-recharge-summary',
  templateUrl: './recharge-summary.component.html',
  styleUrls: ['./recharge-summary.component.scss']
})
export class RechargeSummaryComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private userAccountService: UserAccountService
  ) { }


  showLoader: boolean = false;
  rechargeDetails = {
    rechargeAmount: 0,
    rechargeDate: null
  }

  cashFreeId;

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.cashFreeId = this.landingComponentService.getCashFreeId();
    this.getRewardPointsSummary();
  }
  ngOnDestroy() {
    this.landingComponentService.setCashFreeId(null);
  }

  goToPointsHistoryPage() {
    if (this.scrWidth > 600) {
      this.router.navigate(['account/user'], {queryParams: {path: 'points_history'}});
    } else {
      this.router.navigate(['profile/user/points']);
    }
  }
  getRewardPointsSummary() {
    this.showLoader = true;
    this.userAccountService.getUserRewardPointsSummary(this.cashFreeId).subscribe((response:any)=> {
      this.rechargeDetails.rechargeAmount = response.data.orderAmount;
      this.rechargeDetails.rechargeDate = response.data.createdDateTimeStamp;
      this.showLoader = false;
    }, (err)=> {
      this.showLoader = false;
    })
  }

}
