import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { IndexDBService } from '../../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../../landing-component.service';
import { UserpointsfilterComponent } from '../../other-components/userpointsfilter/userpointsfilter.component';
import { AccountEditComponent } from '../account-edit/account-edit.component';
import { HelpdeskSuccessPopupComponent } from '../helpdesk-flow/helpdesk-success-popup/helpdesk-success-popup.component';
import { HelpdeskService } from '../helpdesk-flow/helpdesk.service';
import { RateServiceComponent } from '../helpdesk-flow/rate-service/rate-service.component';
import { OrderSummaryService } from '../order-summary/order-summary.service';
import { UserAccountService } from '../user-account.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { environment } from "../../../../../../../environments/environment";

export interface FoodCourt {
  name: string;
  id: string;
  siteName: string;
  siteId: string;
}

@Component({
  selector: 'app-account-page-tab',
  templateUrl: './account-page-tab.component.html',
  styleUrls: ['./account-page-tab.component.scss']
})
export class AccountPageTabComponent implements OnInit {
  details: any;
  name: any;
  email: any;
  phonenumber: any;
  constructor(
    private userAccountService: UserAccountService,
    private landingComponentService: LandingComponentService,
    private storageService: IndexDBService,
    private dialog: MatDialog,
    private _popUpDbService: PopupDbService,
    private _orderSummaryService: OrderSummaryService,
    private router: Router,
    private helpdeskService: HelpdeskService,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    this.userId = this.landingComponentService.getUserId();
    this.getScreenSize();
    this.getUserDetails();
    this.getOrderHistoryOfUser();
    this.gettingfcdetails();

    this.selectSideMenu(this.selectedType);


    this.activatedRoute.queryParams.subscribe((queryParam)=> {
      
        if (queryParam.path) {
          this.selectedType = queryParam.path?.toUpperCase();
          
          this.selectSideMenu(this.selectedType);
          
          if (this.selectedType === 'ORDERS') {
            this.getOrderHistoryOfUser();
          } else if (this.selectedType === 'POINTS_HISTORY') {
            this.getUserIstharaPoinst();
          } else if (this.selectedType === 'HELPDESK') {
            this.getAllUserIssues('');
          } else if (this.selectedType === 'FOOD_SUBSCRIPTION') {
            this.getUserFoodSubscriptionList();
          } else if (this.selectedType === 'CONTACT_US') {
            this.gettinginfo(0);
          }
        } 
    })
  }

  isFilteredData: boolean = false;
  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    if (this.scrWidth < 600) {
      this.router.navigate(["profile/user"]);
    }
  }

  userDetails;
  orderId: string;
  userId: string;
  selectedType: string = "ORDERS";
  showCancelButton: boolean = true;
  orderDetails;
  orderType;
  showLoader: boolean = false;

  userPointsList = [];

  orderListingLoader: boolean = false;
  pointsHistoryLoader: boolean = false;


  paymentAmount: number;
  rechargePointsSelectionList = [
    { value: 100, status: false },
    { value: 500, status: false },
    { value: 1000, status: false },
    { value: 2000, status: false },
  ]

  sidePanel = [
    { name: "Orders", imgName: "orders", value: "ORDERS", status: true },
    { name: "Isthara Points", imgName: "reward_points", value: "POINTS_HISTORY", status: false },
    { name: "Helpdesk", imgName: "raise_ticket", value: "HELPDESK", status: false },
    { name: "Contact us", imgName: "contact_us", value: "CONTACT_US", status: false },
    { name: "Food Subscription", imgName: "raise_ticket", value: "FOOD_SUBSCRIPTION", status: false },
    // { name: "Favourites", imgName: "favourite", value: "FAVOURITES", status: false },
    // { name: "Reviews", imgName: "reviews", value: "REVIEWS", status: false },
    // { name: "Offers", imgName: "offers",  value: "OFFERS", status: false },
    // { name: "Settings", imgName: "settings",  value: "SETTINGS", status: false }
  ];
  ordersList;

  transactionHistoryPanel = [
    { name: "Points History", value: "POINTS_HISTORY", status: true },
    { name: "Refund History", value: "BANK_REFUNDS", status: false }
  ];
  selectedTransactionHistoryPanel: string = "POINTS_HISTORY";

  ORDER_TYPE = [
    { name: "Self Orders", value: "SELF_ORDERS", status: true },
    { name: "Pre Orders", value: "PRE_ORDERS", status: false },
    { name: "Corporate Orders", value: "BTC_ORDERS", status: false },
    { name: "Guest Orders", value: "GUEST_ORDERS", status: false },
    { name: "Guest Request", value: "GUEST_REQUEST", status: false }
  ];
  issueTypeListing = [
    { name: "All Issues", value: "ALL_ISSUES", status: true },
    { name: "Open Issues", value: "OPEN_ISSUES", status: false },
    { name: "Inprogress Issues", value: "INPROGRESS_ISSUES", status: false },
    { name: "Resolved Issues", value: "RESOLVED_ISSUES", status: false },
    { name: "Closed Issues", value: "CLOSED_ISSUES", status: false },
  ];

  selectedIssueType = "ALL_ISSUES";
  selectedOrderType = "SELF_ORDERS";
  issueListing = [];
  showRechageWalletPage: boolean = false;
  selectedIssueInOpenIssue: string = "OPEN_ISSUES";

  openIssueTypeListing = [
    { name: "Open Issues", value: "OPEN_ISSUES", status: true },
    { name: "Reopened Issues", value: "REOPENED_ISSUES", status: false },
  ]

  filterbtn() {

    const dialogRef = this.dialog.open(UserpointsfilterComponent, {
      width: '80%',
      height: 'auto',
      data: { filterFor: this.selectedTransactionHistoryPanel }
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
          this.userPointsHistory = response.filterData;

        } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
          this.userPointsHistory = response.filterData.filter((pointsDetail) => {
            return pointsDetail.refundStatus === "REFUND_INITIATED" || pointsDetail.refundStatus === "REFUND_COMPLETED";
          });
        }

        this.isFilteredData = true;
      }
    })
  }

  closeFilter() {

    this.isFilteredData = false;
    if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
      this.getUserPointsHistory();
    } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
      this.getBankRefundPointsHistory();
    }
  }

  changeOpenIssue() {
    if (this.selectedIssueInOpenIssue === "REOPENED_ISSUES") {
      this.getAllUserIssues('RE_OPENED');
    } else if (this.selectedIssueInOpenIssue === "OPEN_ISSUES") {
      this.getAllUserIssues('OPEN');
    }
  }
  changeIssueType(issueType) {

    this.issueTypeListing.forEach((issue) => {
      if (issue.value === issueType.value)
        issue.status = true;
      else
        issue.status = false;
    })
    this.selectedIssueType = issueType.value;

    this.issueListing = [];

    if (issueType.value === "ALL_ISSUES") {
      this.getAllUserIssues('');
    } else if (issueType.value === "OPEN_ISSUES") {
      this.getAllUserIssues('NEW');
    } else if (issueType.value === "INPROGRESS_ISSUES") {
      this.getAllUserIssues('IN_PROGRESS');
    } else if (issueType.value === 'RESOLVED_ISSUES') {
      this.getAllUserIssues('RESOLVED');
    } else if (issueType.value === 'CLOSED_ISSUES') {
      this.getAllUserIssues('CLOSED');
    }


  }

  helpdeskCategoryList = [];
  categoryListLoader: boolean = false;

  showHelpdeskListing: boolean = true;
  showHelpdeskCategoryListing: boolean = false;
  showHelpdeskRaiseTicket: boolean = false;
  showHelpdeskTicketDetails: boolean = false;


  reopenIssueLoader: boolean = false;
  closeIssueLoader: boolean = false;
  reopenTicket() {

    this.reopenIssueLoader = true;
    this.closeIssueLoader = false;

    this.helpdeskService.reopenTicketById(this.helpDeskDetails.helpdeskTicketId).subscribe((response) => {

      this.reopenIssueLoader = false;
      this.closeIssueLoader = false;

      this.showHelpdeskListing = true;
      this.showHelpdeskCategoryListing = false;
      this.showHelpdeskRaiseTicket = false;
      this.showHelpdeskTicketDetails = false;
      this.router.navigate(['account/user'], { queryParams: { path: 'helpdesk' } });
    }, (err) => {

      this.reopenIssueLoader = false;
      this.closeIssueLoader = false;

      this.showHelpdeskListing = true;
      this.showHelpdeskCategoryListing = false;
      this.showHelpdeskRaiseTicket = false;
      this.showHelpdeskTicketDetails = false;
      this.landingComponentService.openSnackBarMessage("Failed to Reopen the Ticket");
    })
  }

  rateResolvedTicket(helpdeskDetails) {
    // this.helpdeskService.setHelpdeskTicketId(this.helpDeskTicketId);
    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: { ticketId: helpdeskDetails.helpdeskTicketId },
    });
    _bottomSheetRef.afterDismissed().subscribe((data) => {
      if (data) {

        this.showHelpdeskListing = true;
        this.showHelpdeskCategoryListing = false;
        this.showHelpdeskRaiseTicket = false;
        this.showHelpdeskTicketDetails = false;
        this.router.navigate(['account/user'], { queryParams: { path: 'helpdesk' } });
      }
    })
  }

  closeTicket(helpdeskDetails) {

    let _bottomSheetRef = this.bottomSheet.open(RateServiceComponent, {
      data: { ticketId: helpdeskDetails.helpdeskTicketId },
    });
    _bottomSheetRef.afterDismissed().subscribe((data) => {
      if (data) {

        this.reopenIssueLoader = false;
        this.closeIssueLoader = true;

        this.helpdeskService.closeTicketById(this.helpDeskDetails.helpdeskTicketId).subscribe((response) => {

          this.reopenIssueLoader = false;
          this.closeIssueLoader = false;

          this.showHelpdeskListing = true;
          this.showHelpdeskCategoryListing = false;
          this.showHelpdeskRaiseTicket = false;
          this.showHelpdeskTicketDetails = false;

          if (this.selectedIssueType === "ALL_ISSUES") {
            this.getAllUserIssues('');
          } else if (this.selectedIssueType === "RESOLVED_ISSUES") {
            this.getAllUserIssues('RESOLVED');
          }

          // this.router.navigate(['account/user'], {queryParams: {path: 'helpdesk'}});
        }, (err) => {

          this.reopenIssueLoader = false;
          this.closeIssueLoader = false;

          this.showHelpdeskListing = true;
          this.showHelpdeskCategoryListing = false;
          this.showHelpdeskRaiseTicket = false;
          this.showHelpdeskTicketDetails = false;
          this.landingComponentService.openSnackBarMessage("Error while Closing the Ticket");
        })
      }

    })

  }

  getAllHelpDeskCategories() {
    this.categoryListLoader = true;
    this.userAccountService.getAllHelpDeskCategories().subscribe((response: any) => {
      this.helpdeskCategoryList = response.data.filter((category) => {
        return category.status;
      });
      this.categoryListLoader = false;
    }, (err) => {
      this.categoryListLoader = false;
    })
  }
  goToCategoryListingPage() {
    this.showHelpdeskListing = false;
    this.showHelpdeskCategoryListing = true;
    this.showHelpdeskRaiseTicket = false;
    this.showHelpdeskTicketDetails = false;
    this.getAllHelpDeskCategories();
  }
  goToHelpDeskListingPage() {

    this.showHelpdeskListing = true;
    this.showHelpdeskCategoryListing = false;
    this.showHelpdeskRaiseTicket = false;
    this.showHelpdeskTicketDetails = false;
    if (this.selectedIssueType === 'ALL_ISSUES') {
      this.getAllUserIssues('');
    } else if (this.selectedIssueType === 'OPEN_ISSUES') {
      this.getAllUserIssues('NEW');
    } else if (this.selectedIssueType === 'RESOLVED_ISSUES') {
      this.getAllUserIssues('RESOLVED');
    } else if (this.selectedIssueType === 'INPROGRESS_ISSUES') {
      this.getAllUserIssues('IN_PROGRESS');
    } else if (this.selectedIssueType === 'CLOSED_ISSUE') {
      this.getAllUserIssues('CLOSED');
    }
  }
  goToTicketDetailPage(ticketDetails) {

    this.showHelpdeskListing = false;
    this.showHelpdeskCategoryListing = false;
    this.showHelpdeskRaiseTicket = false;
    this.showHelpdeskTicketDetails = true;
    this.getTicketDetailsById(ticketDetails);
  }

  helpdeskLoader: boolean = false;
  helpdeskCategoryId;
  helpdeskFoodCourtId;
  helpdeskCategoryName;
  helpDeskDetails;

  myControl = new FormControl('');
  options = [];
  filteredOptions: Observable<FoodCourt[]>

  goToRaiseTicketPage(category) {
    this.showHelpdeskListing = false;
    this.showHelpdeskCategoryListing = false;
    this.showHelpdeskRaiseTicket = true;
    this.showHelpdeskTicketDetails = false;

    this.userId = this.landingComponentService.getUserId();

    this.helpdeskCategoryName = category.helpdeskCategoryName;
    this.helpdeskCategoryId = category.helpdeskCategoryId;
    this.helpdeskFoodCourtId = this.helpdeskService.getHelpdeskFoodCourtId();

    this.getAllFoodcourtList();
    this.getAllSubCategories();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

  }

  showReopenButton: boolean = true;
  getTicketDetailsById(ticketDetails) {
    this.helpdeskService.getHelpDeskDetails(ticketDetails.helpdeskTicketId).subscribe((response: any) => {
      this.helpDeskDetails = response.data;
      this.helpDeskDetails.timeline?.forEach((timeline) => {
        if (timeline.actionType.toLowerCase().includes("re-opened")) {
          this.showReopenButton = false;
        }
      })
      // this.ticketDetailScreenLoader = false;
    }, (err) => {
      // this.ticketDetailScreenLoader = false;
    })
  }



  ngOnDestroy() {
    this.helpdeskService.setHelpdeskFoodCourtId(null);
  }

  getAllSubCategories() {
    // this.raiseIssueLoader = true;

    this.helpdeskService.getAllFoodCourtSubCategoryById(this.helpdeskCategoryId).subscribe((response: any) => {
      this.issueSubTypeList = response.data.map((category) => {
        return {
          helpdeskSubCategoryName: category.helpdeskSubCategoryName,
          status: false
        }
      });
      // this.raiseIssueLoader = false;
    }, (err) => {
      // this.raiseIssueLoader = false;
    })
  }

  selectedFoodCourt: FoodCourt = {
    name: null,
    id: null,
    siteName: null,
    siteId: null
  };
  selectFoodCourt(foodcourtDetails) {
    this.selectedFoodCourt = foodcourtDetails;
  }

  getAllFoodcourtList() {
    // this.raiseIssueLoader = true;
    this.helpdeskService.getAllFoodCourtList().subscribe((response: any) => {
      this.options = response.data.content.map((foodcourt) => {
        return {
          name: foodcourt.foodCourtName,
          id: foodcourt.foodCourtId,
          siteId: foodcourt.siteId,
          siteName: foodcourt.siteName
        }
      });

      if (this.helpdeskFoodCourtId) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id === this.helpdeskFoodCourtId) {
            this.selectedFoodCourt = this.options[i];
          }
        }
      }

      // this.raiseIssueLoader = false;
    }, (err) => {
      // this.raiseIssueLoader = false;
      console.log(err);
    })
  }
  displayFn(foodcourt: FoodCourt): string {
    return foodcourt && foodcourt.name ? foodcourt.name : '';
  }

  private _filter(name: string): FoodCourt[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  loaderSpinner: boolean = false;
  helpdeskOrderId;

  issueImage;
  issueImages;

  issueDescription;

  updatedImage = [];
  issueSubTypeList = []

  async addMore(e) {
    this.loaderSpinner = true;
    for (let i = 0; i < e.srcElement.files.length; i++) {

      this.issueImage = await this.userAccountService.uploadFile(
        e.srcElement.files[i], "images"
      );

      if (this.issueImage?.url) {
        this.updatedImage.push({ imageUrl: this.issueImage.url });
      } else {
      }
    }
    let data = await this.updatedImage;
    this.loaderSpinner = false;
  }

  submitButtonLoader: boolean = false;
  createHelpDeskTicketForUser() {
    this.submitButtonLoader = true;

    let userName = this.landingComponentService.getUserName();
    let userMobileNumber = this.landingComponentService.getUserMobile();

    let sendingImageUrl = [];
    this.updatedImage.forEach((image) => {
      sendingImageUrl.push(image.imageUrl)
    })
    let data = {
      "foodCourtUserInfo": {
        "foodCourtId": this.selectedFoodCourt.id,
        "foodCourtName": this.selectedFoodCourt.name,
        "orderId": this.helpdeskOrderId,
        "siteId": this.selectedFoodCourt.siteId,
        "siteName": this.selectedFoodCourt.siteName,
        "userId": this.userId,
        "userMobileNumber": userMobileNumber,
        "userName": userName
      },
      "helpdeskDetails": {
        "category": this.helpdeskCategoryName,
        "categoryId": this.helpdeskCategoryId,
        "helpdeskIssueImages": sendingImageUrl,
        "helpdeskTicketType": "FOOD_COURT",
        "issueDescription": this.issueDescription,
        "issueTitle": "string", // need to change
        "subCategory": this.selectedIssueType,
      },
      "raisedByUserType": "FOODCOURT_USER"
    }

    this.helpdeskService.createHelpDeskTicket(data).subscribe((response) => {
      // this.submitButtonLoader = false;
      // this.landingComponentService.openSnackBarMessage("Ticket Raised Successfully!!");
      // this.router.navigate(["profile/user"]);
      // this.helpdeskService.openDialog(
      //   HelpdeskSuccessPopupComponent,
      //   '80vw',
      //   'auto',
      //   '100%',
      //   {data:''}
      // )
      this.showHelpdeskListing = true;
      this.showHelpdeskCategoryListing = false;
      this.showHelpdeskRaiseTicket = false;
      this.showHelpdeskTicketDetails = false;

      this.submitButtonLoader = false;
      this.router.navigate(['account/user'], { queryParams: { path: 'helpdesk' } });
    }, (err) => {
      this.submitButtonLoader = false;
      this.landingComponentService.openSnackBarMessage("Failed to Raise Ticket.");
    })
  }


  refundToWallet(pointsDetail) {
    let userEmail = this.landingComponentService.getUserEmail();
    let userMobile = this.landingComponentService.getUserMobile();
    let userId = this.landingComponentService.getUserId();
    let userName = this.landingComponentService.getUserName();

    let refundDetails = {
      "email": userEmail,
      "mobile": userMobile,
      "userId": userId,
      "username": userName
    }
    this.userAccountService.initiateRefundToWallet(pointsDetail.orderId, pointsDetail.pointHistoryId, refundDetails).subscribe((response) => {

      this.getUserIstharaPoinst();
      this.landingComponentService.openSnackBarMessage("Refund Successfull");
    }, (err) => {
      this.landingComponentService.openSnackBarMessage("Refund Failed");
    })
  }

  getAllUserIssues(status) {

    this.helpdeskLoader = true;
    this.userAccountService.getAllHelpDeskTickets(this._popUpDbService.getUserId(), 0, 500, status).subscribe(
      (res: any) => {
        this.issueListing = res.data;
        this.helpdeskLoader = false;
        // this.getAllLoader = false;
      },
      (err) => {
        this.helpdeskLoader = false;
        // this.getAllLoader = false;
        // this._snackbar.open(
        //   `${err.error.message}`,
        //   'Close',
        //   {
        //     duration:1500
        //   }
        // )
      }
    )
  }

  changeOrderType(order) {

    if (this.showLoader)
      return;

    this.ORDER_TYPE.forEach((type) => {
      if (type.value === order.value)
        type.status = true;
      else
        type.status = false;
    })
    this.selectedOrderType = order.value;

    this.ordersList = [];
    // this.pageNo = -1;
    // this.size = 10;

    if (order.value === "SELF_ORDERS") {
      this.getOrderHistoryOfUser();
    } else if (order.value === "PRE_ORDERS") {
      this.getMorePreOrderHistoryOfUser();
    } else if (order.value === "GUEST_ORDERS") {
      // this.getGuestOrderHistoryOfUser();
      this.getGuestPendingOrderHistoryOfUser();
    } else if (order.value === "BTC_ORDERS") {
      this.getBTCRequestListing();
    }
    else {
      // this.getGuestPendingOrderHistoryOfUser();
      this.getGuestOrderHistoryOfUser();
    }
  }


  getBTCRequestListing() {

    this.showLoader = true;
    this.orderListingLoader = true;

    this.userAccountService
      .getBTCRequestListing(this.userId)
      .subscribe(
        (res: any) => {

          this.showLoader = false;
          this.orderListingLoader = false;

          //console.log(res);
          if (res.data.content.length > 0) {
            res.data.content.forEach((element) => {
              this.ordersList.push(element);
            });
          } else {
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.orderListingLoader = false;
          this.landingComponentService.openSnackBarMessage('Not able to fetch orders', 'Close');
        }
      );
  }
  getMorePreOrderHistoryOfUser() {

    this.showLoader = true;
    this.orderListingLoader = true;

    this.userAccountService
      .getUserPreOrderHistory(this.userId, 0, 10000)
      .subscribe(
        (res: any) => {

          this.showLoader = false;
          this.orderListingLoader = false;
          //console.log(res);
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              if (element.preOrderStartTime) {
                let hour = element.preOrderStartTime.split(":")[0];
                let minute = element.preOrderStartTime.split(":")[1];
                element.preOrderStartTime = new Date(new Date(element?.preOrderDate).setHours(hour, minute, 0, 0));
              }
              if (element.preOrderEndTime) {
                let hour = element.preOrderEndTime.split(":")[0];
                let minute = element.preOrderEndTime.split(":")[1];
                element.preOrderEndTime = (new Date(element?.preOrderDate).setHours(hour, minute, 0, 0));
              }

              this.ordersList.push(element);
            });
          } else {
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.orderListingLoader = false;
          this.landingComponentService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );

  }
  getGuestOrderHistoryOfUser() {

    this.showLoader = true;
    this.orderListingLoader = true;

    this.userAccountService
      .getGuestOrderHistoryOfUser(this.userId, 0, 10000)
      .subscribe(
        (res: any) => {

          this.showLoader = false;
          this.orderListingLoader = false;

          //console.log(res);
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.ordersList.push(element);
            });
          } else {
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.orderListingLoader = false;
          this.landingComponentService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }
  getGuestPendingOrderHistoryOfUser() {

    this.showLoader = true;
    this.orderListingLoader = true;

    this.userAccountService
      .getGuestPendingOrderHistoryOfUser(this.userId, 0, 10000)
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          this.orderListingLoader = false;

          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.ordersList.push(element);
            });
          } else {
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.orderListingLoader = false;
          this.landingComponentService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }



  selectSideMenu(item) {
    this.selectedType = item;

    this.sidePanel.forEach((element) => {
      if (element.value === this.selectedType) {
        element.status = true;
      } else {
        element.status = false;
      }
    })

    if (this.selectedType === 'ORDERS') {
      this.router.navigate(['account/user'], { queryParams: { path: 'orders' } });
    } else if (this.selectedType === 'POINTS_HISTORY') {
      this.router.navigate(['account/user'], { queryParams: { path: 'points_history' } });
    } else if (this.selectedType === 'HELPDESK') {
      this.router.navigate(['account/user'], { queryParams: { path: 'helpdesk' } });
    }
    else if (this.selectedType === 'CONTACT_US') {
      this.router.navigate(['account/user'], { queryParams: { path: 'contact_us' } });
    }
    else if (this.selectedType === 'FOOD_SUBSCRIPTION') {
      this.router.navigate(['account/user'], {queryParams: {path: 'food_subscription'}});
    }
  }


  userRewardPoints = {
    istharaPoints: 0,
  };
  userPointsHistory: any[] = [];
  getUserIstharaPoinst() {
    this.pointsHistoryLoader = true;
    this.userAccountService.getUserRewardPoints(this.userId).subscribe(
      (res: any) => {
        this.userRewardPoints.istharaPoints = res.data;
        this.pointsHistoryLoader = false;
      },
      (err) => {
        this.landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
      }, () => {
        if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
          this.getUserPointsHistory();
        } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
          this.getBankRefundPointsHistory();
        }
      }
    );
  }
  getBankRefundPointsHistory() {
    this.userAccountService.getUserPoints(this.userId).subscribe(
      (res: any) => {
        this.userPointsHistory = res.data.filter((pointsDetail) => {
          return pointsDetail.refundStatus === "REFUND_INITIATED" || pointsDetail.refundStatus === "REFUND_COMPLETED";
        });
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
        this.landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
        // this.router.navigate(["profile/user"]);
      }
    );
  }
  getUserPointsHistory() {

    this.pointsHistoryLoader = true;
    this.userAccountService.getUserPoints(this.userId).subscribe(
      (res: any) => {
        this.userPointsHistory = res.data;
        this.showLoader = false;
        this.pointsHistoryLoader = false;
      },
      (err) => {
        this.showLoader = false;
        this.landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
        // this.router.navigate(["profile/user"]);
      }
    );
  }
  changeTransactionHistoryPanel(panelDetails) {
    this.transactionHistoryPanel.forEach((panel) => {
      if (panel.value === panelDetails.value) {
        panel.status = true;
      } else {
        panel.status = false;
      }
    })

    this.selectedTransactionHistoryPanel = panelDetails.value;

    if (panelDetails.value === "POINTS_HISTORY") {
      this.getUserPointsHistory();
    } else if (panelDetails.value === "BANK_REFUNDS") {
      this.getBankRefundPointsHistory();
    }
  }
  getOrderHistoryOfUser() {

    this.showLoader = true;
    this.orderListingLoader = true;

    this.userAccountService
      .getOrdersListByUserId(this.userId, 0, 10000)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.showLoader = false;
          this.orderListingLoader = false;
          this.ordersList = res.data;
          this.ordersList.forEach((element) => {
            element.isOrderComplete = true;
            if (element.orderType === 'DELIVERY') {
              if (element.orderdeliveryStatus !== 'DELIVERED') {
                element.isOrderComplete = false;
              }
            } else {
              element.vendorOrderDetails.forEach((vendor) => {
                if (vendor.orderStatus !== 'PAST')
                  element.isOrderComplete = false;
              });
            }
          });
        },
        (err) => {
          this.showLoader = false;
          // this._popUpDbService.openSnackBarMessage(
          //   'Not able to fetch orders',
          //   'Close'
          // );
        }
      );
  }

  getUserDetails() {

    this.userAccountService.getUserDetailsByUserId(this.userId).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.userDetails = {
          userName: res.data.fullName,
          countryCode: res.data.countryCode,
          userMobile: res.data.mobile,
          userEmail: res.data?.email,
          userId: res.data.userId,
          profileImg: res.data.profilePicture,
        };
        //console.log(this.userDetails);
      },
      (err) => {
        //console.log('Error while getting user details');
      }
    );
  }

  editProfile() {
    const dialogRef = this.dialog.open(AccountEditComponent, {
      width: '50vw',
      height: '80vh',
      data: { type: "FROM_TAB" },
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.getUserDetails();
    });
  }

  showPointsSummary(points) {

    if (points.paymentType !== "RECHARGE") {
      this.landingComponentService.setOrderId(points.orderId);
      this.router.navigate(["profile/user/order-summary/self-order"], { state: { fromPage: "USER_POINTS" } });
    } else {
      this.landingComponentService.setCashFreeId(points.cashFreeId);
      this.router.navigate(["profile/user/recharge-summary"]);
    }
  }
  showOrderSummary(order) {


    if (this.selectedOrderType === "SELF_ORDERS") {

      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/self-order']);
    }
    else if (this.selectedOrderType === "GUEST_ORDERS") {

      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/guest-order']);
    }
    else if (this.selectedOrderType === "GUEST_REQUEST") {

      this._popUpDbService.setRequestId(order.requestId);
      this.router.navigate(['profile/user/order-summary/guest-request']);
    }
    else if (this.selectedOrderType === "PRE_ORDERS") {

      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/pre-order']);
    }
    else if (this.selectedOrderType === "BTC_ORDERS") {

      this._popUpDbService.setRequestId(order.requestId);
      this.router.navigate(['profile/user/order-summary/corporate-order']);
    }

    // if (!order?.orderId) {
    //   if (this.userId) {
    //     this._popUpDbService.setRequestId(order.requestId);
    //     this.router.navigate(['profile/user/order-summary/guest-request']);
    //   } else {
    //     this._popUpDbService.openSnackBarMessage(
    //       'You are not logged in, please login',
    //       'Ok'
    //     );
    //   }
    // }
    // else {
    //   if (this.userId) {
    //     this._popUpDbService.setOrderId(order.orderId);
    //     if (this.selectedOrderType === "SELF_ORDERS")
    //       this.router.navigate(['profile/user/order-summary/self-order']);
    //     else if (this.selectedOrderType === "GUEST_ORDERS")
    //       this.router.navigate(['profil/usere/order-summary/guest-order']);
    //     else if (this.selectedOrderType === "PRE_ORDERS")
    //       this.router.navigate(['profile/user/order-summary/pre-order']);
    //   } else {
    //     this._popUpDbService.openSnackBarMessage(
    //       'You are not logged in, please login',
    //       'Ok'
    //     );
    //   }
    // }
  }

  getOrderSummaryOfOrder() {
    this._orderSummaryService.getOrderDetailByOrderId(this.orderId).subscribe(
      (res: any) => {
        //console.log(res);
        this.orderDetails = res.data;
        this.orderType = res.data.orderType;

        this.orderDetails.paidAmount = 0;
        for (let points of Object.keys(this.orderDetails.paymentDetails)) {
          if (points === 'Delivery Charges') {
            // this.orderDetails.paidAmount += Number(
            //   this.orderDetails.paymentDetails[points] / 100
            // );
            this.orderDetails.paidAmount += Number(
              this.orderDetails.paymentDetails[points]
            );
            continue;
          }
          this.orderDetails.paidAmount += Number(
            this.orderDetails.paymentDetails[points]
          );
        }

        if (
          this.orderDetails.vendorOrderDetails.every(
            (element) =>
              element.orderStatus === 'NEW' ||
              element.orderStatus === 'PRE_ORDER'
          )
        ) {
          this.showCancelButton = true;
        } else {
          this.showCancelButton = false;
        }
      },
      (err) => {
        // this._popUpDbService.openSnackBarMessage(
        //   'Error while loading order summary',
        //   'Close'
        // );
      },
      () => {
        if (
          this.orderDetails.orderType === 'PICK_UP' ||
          this.orderDetails.orderType === 'DINE_IN'
        ) {
          if (
            this.orderDetails.vendorOrderDetails.every(
              (element) =>
                element.orderStatus === 'NEW' ||
                element.orderStatus === 'PRE_ORDER'
            )
          ) {
            // this.getFoodcourtDetails();
          }
          // this.orderDetails.vendorOrderDetails.forEach((element) => {
          //   if (
          //     element.orderStatus === 'NEW' &&
          //     element.orderStatus !== 'PRE_ORDER'
          //   ) {
          //     this.getFoodcourtDetails();
          //   }
          // });
        }
      }
    );
  }

  rechargeWallet() {
    this.showRechageWalletPage = true;
  }
  goToPointsHistoryPage() {
    this.showRechageWalletPage = false;
  }
  selectPaymentAmountFromList(amount) {
    this.rechargePointsSelectionList.forEach((point) => {
      if (point.value === amount.value) {
        point.status = true;
      } else {
        point.status = false;
      }
    })

    this.paymentAmount = amount.value;
  }

  formDataView: any = {};
  payWithCashFree() {

    let userEmailId = this.landingComponentService.getUserEmail();
    let userMobileName = this.landingComponentService.getUserMobile();
    let userId = this.landingComponentService.getUserId();
    let userName = this.landingComponentService.getUserName();

    let paymentDetails = {
      "email": (userEmailId ? userEmailId : "test@gmail.com"),
      "mobile": userMobileName,
      // "orderAmount": this.paymentAmount * 100,
      "orderAmount": this.paymentAmount * 100,
      "orderNote": "string",
      "pointsToBePaid": 0,
      "userId": userId,
      "username": userName
    }

    this.userAccountService.createWalletPaymentToken(paymentDetails).subscribe(
      (pay: any) => {

        this._popUpDbService.setPaymentFor('WALLET_PAYMENT');
        this._popUpDbService.setOrderId(pay.data.fcOrderId);

        // this.formDataView.paymentLink = pay.data.paymentLink;
        // this.formDataView.orderId = pay.data.orderId;
        // this.formDataView.orderAmount = pay.data.orderAmount / 100;
        // this.formDataView.customerName = pay.data.payeeName;
        // this.formDataView.customerPhone = pay.data.payeeMobile;
        // this.formDataView.customerEmail = pay.data.payeeEmail;
        // this.formDataView.returnUrl = pay.data.returnUrl;
        // this.formDataView.notifyUrl = pay.data.notifyUrl;
        // this.formDataView.appId = pay.data.appId;
        // this.formDataView.signature = pay.data.orderSignature; //paymentToken
        // this.formDataView.orderCurrency = pay.data.paymentCurrency;
        // this.formDataView.orderNote = 'Order';

        // //console.log(this.formDataView);

        const paymentSessionId = pay.data.orderSignature;
        let cashFree;
        if (environment.production) {
          cashFree = new cashfreeProd.Cashfree(paymentSessionId);
        } else {
          cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
        }
        cashFree.redirect();

        // setTimeout(() => {
        //   // this.buttonLoading = false;
        //   document.forms['redirectForm'].submit();
        // }, 100);
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  logoutUser() {
    let userId = this.landingComponentService.getUserId();
    let fb_token = this.landingComponentService.getFirebaseToken();
    let userDeviceId = this.landingComponentService.getUserDeviceId();

    let deviceInfo;
    if (fb_token) {
      deviceInfo = {
        brand: null,
        deviceId: userDeviceId,
        firebaseToken: fb_token,
        heightPixels: 0,
        model: null,
        os: null,
        osVersion: null,
        widthPixels: 0,
      };

      this.userAccountService.logoutUser(userId, deviceInfo).subscribe(
        (res: any) => {
          this.landingComponentService.removeFromLocalStorage('userId');
          this.landingComponentService.removeFromLocalStorage('userName');
          this.landingComponentService.removeFromLocalStorage('userMobile');
          this.landingComponentService.removeFromLocalStorage('token');
          this.landingComponentService.removeFromLocalStorage('fb_token');
          this.landingComponentService.openSnackBarMessage(
            'Succesfully Logout',
            'Cancel'
          );
          // this.storageService.clearAllItemsFromCart();
          // this.logoutLoading = false;

          let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
          this.router.navigate([`${foodCourtName}`]);
        },
        (err) => {
          // this.logoutLoading = false;
          this.landingComponentService.openSnackBarMessage(
            'Failed to Logout',
            'Cancel'
          );
        }
      );
    } else {
      this.landingComponentService.removeFromLocalStorage('userId');
      this.landingComponentService.removeFromLocalStorage('userName');
      this.landingComponentService.removeFromLocalStorage('userMobile');
      this.landingComponentService.removeFromLocalStorage('token');
      this.landingComponentService.removeFromLocalStorage('fb_token');
      this.landingComponentService.openSnackBarMessage(
        'Succesfully Logout',
        'Cancel'
      );
      // this.storageService.clearAllItemsFromCart();

      let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
      this.router.navigate([`${foodCourtName}`]);
    }
  }


  sitelist: any;
  fcdetails: any[] = [];
  selected_name: any;
  gettingfcdetails() {
    this.sitelist = this._popUpDbService.getsiteList();
    this.sitelist.forEach((element: any) => {
      element.foodCourtList.forEach((item: any) => {
        this.fcdetails.push({ ...item, siteid: element.siteId })
      })
      // this.fcdetails.push(...element.foodCourtList);

    })

    this.selected_name = this.fcdetails[0].foodCourtId;
    this.gettinginfo(0)

  }

  gettinginfo(i: any) {
    this.landingComponentService.getSPOClist(this.fcdetails[i].foodCourtId, this.fcdetails[i].siteid).subscribe(
      (response: any) => {
        this.details = response.data

      }

    )
    // console.log(this.fcdetails[i])
  }

  
  subscriptionList = [];
  
  selectedSubscriptionType: string = "ALL";
  mainLoader: boolean = false;

  USER_SUBSCRIPTION_TYPE = [
    { name: 'All Subscription', value: "ALL", status: true },
    { name: 'Active Subsctiption', value: "ACTIVE", status: false },
    { name: 'Expired Subscription', value: "EXPIRED", status: false },
  ]


  getUserFoodSubscriptionList() {
    let userId = this.landingComponentService.getUserId();
    // this.mainLoader = true;

    this.userAccountService.getAllUserSubscriptionList(userId, this.selectedSubscriptionType).subscribe((response:any)=> {
      this.subscriptionList.forEach((subscriptionPlan)=> {
        subscriptionPlan.subscriptionStatus = ( new Date() > subscriptionPlan.endDate ) ? "EXPIRED" : "ACTIVE";
      })
      // this.mainLoader = false;
    }, (err)=> {
    })
  }

  changeUserSubscriptionListing(subsctiptionType) {
    this.USER_SUBSCRIPTION_TYPE.forEach((type)=> {
      if (type.value === subsctiptionType.value) {
        type.status = true;
        this.selectedSubscriptionType = subsctiptionType.value;
      } else {
        type.status = false;
      }
    })
    this.getUserFoodSubscriptionList();
  }

  openSubscriptionDetails(subscriptionPlanDetails) {
    this.landingComponentService.setSelectedSubscriptionPlan(subscriptionPlanDetails);
    this.router.navigate(['/food-subscription/view-plan'], {queryParams: {isUserSubscriptionView: true}});
  }



}
