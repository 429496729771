<section class="total-section">
  <div class="close-img">
    <img src="../../../../../../../assets/images/common/close.svg" alt="Close" (click)="closeBottomSheet()">
  </div>
  <div class="notification-img">
    <img src="../../../../../../../assets/images/dummy/notification_default.svg" alt="Notification Image"
      *ngIf="!dataSource.notificationImageUrl">
    <img [src]="dataSource.notificationImageUrl" alt="Notification Image" *ngIf="dataSource.notificationImageUrl">
  </div>
  <div class="notification-details">
    <h1 class="notification-title">{{ dataSource.title | titlecase }}</h1>
    <ng-container *ngIf="dataSource.notificationType === 'EVENTS'">
      <p class="event-date">{{ dataSource.eventDate | date:'mediumDate' }} - {{ dataSource.eventEndDate |
        date:'mediumDate' }}</p>
      <h2 class="heading-event-timing">Timings</h2>
      <p class="event-timing">{{ dataSource.eventTime | date:'shortTime' }} - {{ dataSource.eventEndTime |
        date:'shortTime' }}</p>
      <p class="about-event">About Event</p>
    </ng-container>
    <p class="notification-text">{{ dataSource.message | titlecase }}</p>
    <button class="notification-action" *ngIf="dataSource.notificationType === 'APP_UPDATES'"
      (click)="goToStore()">Update</button>
    <button class="notification-action" *ngIf="dataSource.notificationType === 'EVENTS'"
      (click)="goToHomePage()">Explore</button>
  </div>
</section>
