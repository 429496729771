import { Platform } from '@angular/cdk/platform';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { GlobalCartServiceService } from '../../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../../landing-component.service';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private storageService: IndexDBService,
    private landingComponentService: LandingComponentService,
    private commonService: OtherComponentService,
    private globalCartService: GlobalCartServiceService,
    private _popupDbService: PopupDbService,
    private router: Router,
    public platform: Platform
  ) {}

  fb_token: string;
  couponDetails: any;
  userName: string;

  ngOnInit(): void {
    this.userName = this.landingComponentService.getUserName();
    this.landingComponentService.setIsFirstTimeLoading(false);
    this.fb_token = this.landingComponentService.getFirebaseToken();
    if (this.data?.type === 'COUPON_DETAILS') this.getCouponDetails();
    if (this.data?.type === 'DOWNLOAD_APP') {
      if (this.platform.ANDROID) {
        this.appDownloadLink =
          'https://play.google.com/store/apps/details?id=com.tao.isthara';
      } else if (this.platform.IOS) {
        // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
        this.appDownloadLink = 'https://tinyurl.com/5n7vn985';
      }
    }

    // if (this.data?.type) {
    //   setTimeout(() => {
    //     this.closePopUp();
    //   }, 2000);
    // }
  }

  getCouponDetails() {
    this.commonService.getCouponDetails(this.data.couponId).subscribe(
      (response: any) => {
        this.couponDetails = response.data;
      },
      (err) => {
        //console.log(err);
      }
    );
  }
  closePopup() {
    this.dialogRef.close();
  }
  clearCart() {
    let userId = this.commonService.getUserId();
    if (userId) {
      this.commonService.clearCartDataAPI(userId).subscribe(
        (response: any) => {
          this.storageService.clearAllItemsFromCart();
          
          this.landingComponentService.setIsOrderPreOrderType(null);
          this.landingComponentService.setPreOrderDetails(null);
          this.landingComponentService.setPreOrderMenuType(null);
          this.landingComponentService.setOrderFoodCourtId(null);
          this.landingComponentService.setOrderFoodCourtName(null);

          this.landingComponentService.itemQuantityChange.next(true);

          this.globalCartService.getAllCartData().then(()=> {
            this.dialogRef.close({ isSuccess: true });
          })
        },
        (err) => {
          //console.log(err);
          this.dialogRef.close({ isSuccess: false });
        }
      );
    } else {
      this.storageService
        .clearAllItemsFromCart()
        .then((response) => {
          
          this.landingComponentService.setIsOrderPreOrderType(null);
          this.landingComponentService.setPreOrderDetails(null);
          this.landingComponentService.setPreOrderMenuType(null);
          this.landingComponentService.setOrderFoodCourtId(null);
          this.landingComponentService.setOrderFoodCourtName(null);
          
          this.landingComponentService.itemQuantityChange.next(true);
          
          this.globalCartService.getAllCartData().then(()=> {
            
            this.dialogRef.close({ isSuccess: true });
          })
        })
        .catch((err) => {
          this.dialogRef.close({ isSuccess: false });
        });
    }
  }

  logoutLoading: boolean = false;
  logoutUser() {
    this.logoutLoading = true;
    let userId = this.commonService.getUserId();
    let deviceId = this.landingComponentService.getUserDeviceId();

    let deviceInfo;
      if (this.fb_token) {
        deviceInfo = {
          brand: null,
          deviceId: deviceId,
          firebaseToken: this.fb_token,
          heightPixels: 0,
          model: null,
          os: null,
          osVersion: null,
          widthPixels: 0,
        };

        this.commonService.logoutUser(userId, deviceInfo).subscribe(
          (res: any) => {
            this.landingComponentService.removeFromLocalStorage('userId');
            this.landingComponentService.removeFromLocalStorage('userName');
            this.landingComponentService.removeFromLocalStorage('userMobile');
            this.landingComponentService.removeFromLocalStorage('token');
            this.landingComponentService.removeFromLocalStorage('fb_token');
            this._popupDbService.openSnackBarMessage(
              'Succesfully Logout',
              'Cancel'
            );
            // this.storageService.clearAllItemsFromCart();
            this.logoutLoading = false;

            let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
            this.router.navigate([`${foodCourtName}`]);
            this.closePopUp();
          },
          (err) => {
            this.logoutLoading = false;
            this._popupDbService.openSnackBarMessage(
              'Failed to Logout',
              'Cancel'
            );
          }
        );
      } else {
        this.landingComponentService.removeFromLocalStorage('userId');
        this.landingComponentService.removeFromLocalStorage('userName');
        this.landingComponentService.removeFromLocalStorage('userMobile');
        this.landingComponentService.removeFromLocalStorage('token');
        this.landingComponentService.removeFromLocalStorage('fb_token');
        this._popupDbService.openSnackBarMessage(
          'Succesfully Logout',
          'Cancel'
        );
        this.storageService.clearAllItemsFromCart();

        let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
        this.closePopUp();
      }
  }

  goToCart() {
    let foodCourtName = this.landingComponentService.getFoodCourtName();
    let siteName = this.landingComponentService.getSiteName();

    let foodCourtNameUrl = foodCourtName
      ? foodCourtName?.split(' ').join('-').toLowerCase()
      : ' ';

    this.router.navigate([
      `cart/${foodCourtNameUrl}`,
    ]);

    this.closePopUp();
  }

  confirmAddress() {
    this.dialogRef.close('CONFIRMED');
  }


  closePopUp() {
    this.dialogRef.close();
  }
  appDownloadLink: string =
    'https://play.google.com/store/apps/details?id=com.tao.isthara';
}
