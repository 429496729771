<section class="recharge-summary-section" *ngIf="!showLoader; else showMainLoader">
  <div class="recharge-summary-header">
    <div class="recharge-summary__img-container" (click)="goToPointsHistoryPage()">
      <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
    </div>
    <div class="recharge-summary__heading-text">Recharge Summary</div>
  </div>
  <div class="recharge-summary-content">
    <div class="content-heading">
      <div class="content__img-container">
        <img src="../../../../../../../assets/images/common/recharge_successfull.svg" alt="">
      </div>
      <div class="content__primary-text">Recharged Successfully</div>
      <div class="content__secondary-text">Payment Information</div>
    </div>
    <div class="content__recharge-info">
      <div class="info-section">
        <div class="info-section__key">Amount Recharged</div>
        <div class="info-section__value">&#8377;
          <!-- {{ (rechargeDetails.rechargeAmount / 100) | number:'1.0-2' }} -->
          {{ (rechargeDetails.rechargeAmount) | number:'1.0-2' }}
        </div>
      </div>
      <div class="info-section">
        <div class="info-section__key">{{ rechargeDetails.rechargeDate | date }}</div>
        <div class="info-section__value">Wallet</div>
      </div>
    </div>
  </div>
</section>
<ng-template #showMainLoader>
  <div class="loader-img-container">
    <img src="../../../../assets/images/common/burger_loader.gif" alt="">
  </div>
</ng-template>