import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalCartServiceService } from '../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { ConfirmationPopupComponent } from '../other-components/confirmation-popup/confirmation-popup.component';
import { CustomizeItemBottomsheetComponent } from '../other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';
import { ChooseMealService } from './choose-meal.service';

@Component({
  selector: 'app-choose-meal-view',
  templateUrl: './choose-meal-view.component.html',
  styleUrls: ['./choose-meal-view.component.scss', './mq-choose-meal-view.component.scss'],
})
export class ChooseMealViewComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private chooseMealService: ChooseMealService,
    private landingComponentService: LandingComponentService,
    private globalCartService: GlobalCartServiceService,
    private indexDbService: IndexDBService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {}

  showMainLoadingScreen = false;
  showItemLoadingScreen = false;
  MEALTYPE: string = null;
  CUISINE_LIST = [];
  isVegOnly: boolean = false;
  selectedCuisineType: string = null;
  foodItemList: any[] = [];
  userCartDetails = [];
  searchText: string;
  failedToGetItemList: boolean = false;
  failedToGetCuisineList: boolean = false;
  isComingFromPreOrderingPage: boolean = false;
  
  isIntoPreOrderingFlow: boolean = false;

  ngOnInit(): void {

    this.isIntoPreOrderingFlow = this.landingComponentService.getIsOrderPreOrderType();

    this.getScreenSize();
    if (this.scrWidth > 600) {
      this.landingComponentService.itemQuantityChangeFromCart.subscribe((response)=> {

        this.globalCartService.getUserCartData().then((res: any) => {
          this.userCartDetails = res.userCartData;
          this.cartDetails = res.cartPreviewData;
          this._getItemListByMealType_();
        });
      })
    }

    this.globalCartService.getUserCartData().then((res: any) => {
      this.userCartDetails = res.userCartData;
      this.cartDetails = res.cartPreviewData;
    });

    this.activatedRouter.params.subscribe((params) => {
      this.MEALTYPE = params.type;
      this.isComingFromPreOrderingPage = (params.isPreOrderingType === "true" ? true : false);

      if (this.isComingFromPreOrderingPage && !(this.landingComponentService.preOrderDetails)) {
        this.landingComponentService.openSnackBarMessage("Plz do not refresh in Meal Page with Preordering Item");
        let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
      }
      this.getAllCuisines();
    });
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  getAllCuisines() {
    this.failedToGetCuisineList = false;
    this.showMainLoadingScreen = true;
    this.chooseMealService.getAllCuisines().subscribe(
      (response: any) => {
        this.CUISINE_LIST = [{
          name: "All",
          value: null,
          status: false
        }];

        this.CUISINE_LIST = [...this.CUISINE_LIST, ...response.data.map((element) => {
          return {
            name: (element.cuisine ? element.cuisine : "Other"),
            value: (element.cuisine ? element.cuisine.toUpperCase() : "Other"),
            status: false,
          };
        })];
        this.CUISINE_LIST[0].status = true;
        this.selectedCuisineType = this.CUISINE_LIST[0].value;
        this.showMainLoadingScreen = false;
        this._getItemListByMealType_();
      },
      (err) => {
        //console.log(err);
        this.failedToGetCuisineList = true;
        this.showMainLoadingScreen = false;
        this.landingComponentService.openSnackBarMessageIMP("Something went wrong. Plz try again in sometime.");
      }
    );
  }
  // getItemListByMealType() {
  //   this.globalCartService.getUserCartData().then((res: any) => {
  //     this.userCartDetails = res.userCartData;
  //     this.cartDetails = res.cartPreviewData;
  //     this._getItemListByMealType_();
  //   });
  // }
  _getItemListByMealType_() {

    this.failedToGetItemList = false;
    this.searchText = "";
    this.showItemLoadingScreen = true;
    let siteId = this.landingComponentService.getSiteId();
    let foodCourtId = this.landingComponentService.getFoodCourtId();
    let foodCourtName = this.landingComponentService.getFoodCourtName();

    if (this.isComingFromPreOrderingPage) {
      let data = {
          "category": this.MEALTYPE,
          "cuisineCategory": this.selectedCuisineType ? this.selectedCuisineType.toLowerCase() : null,
          "fcId": foodCourtId,
          "itemType": (this.isVegOnly ? "VEG" : null)
      }
      this.chooseMealService.getPreOrderingItemList(data).subscribe((response:any)=> {
        this.foodItemList = this.seperateOUTOFSTOCKitems(response.data);

        this.globalCartService
          .getUserCartData()
          .then((data : any) => {
            this.userCartDetails = data.userCartData;
            this.cartDetails = data.cartPreviewData;

            this.foodItemList.forEach((element) => {
              element.showCustomQuantity = 0;
              element.itemId = element.id;
              this.userCartDetails?.forEach((cartItem) => {
                if (element.id === cartItem.itemId) {
                  element.showCustomQuantity += cartItem.itemQuantity;
                  element.appItemId = cartItem.appItemId;
                  element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              });

              let url = element.itemImageUrl;
              let pattern = /^((http|https|ftp):\/\/)/;
              if (pattern.test(url)) {
                element.itemImageUrl = url;
              } else {
                element.itemImageUrl = '';
              }
            });
            this.showItemLoadingScreen = false;
          })
          .catch((err) => {
            //console.log(err);
          });
      }, (err)=> {
        this.failedToGetItemList = true;
        this.showItemLoadingScreen = false;
      })
    } else {
      
      let data = {
        "category": this.MEALTYPE,
        "cuisineCategory": this.selectedCuisineType ? this.selectedCuisineType.toLowerCase() : null,
        "foodCourtId": [ foodCourtId ],
        "foodCourtName": [ foodCourtName ],
        "item_type": this.isVegOnly ? 'VEG' : null,
        "siteId": siteId,
      }

      this.chooseMealService.getItemListByType(data).subscribe(
        (response: any) => {
          this.foodItemList = this.seperateOUTOFSTOCKitems(response.data);

          this.globalCartService
            .getUserCartData()
            .then((data : any) => {
              this.userCartDetails = data.userCartData;
              this.cartDetails = data.cartPreviewData;

              this.foodItemList.forEach((element) => {
                element.showCustomQuantity = 0;
                element.itemId = element.id;
                this.userCartDetails?.forEach((cartItem) => {
                  if (element.id === cartItem.itemId) {
                    element.showCustomQuantity += cartItem.itemQuantity;
                    element.appItemId = cartItem.appItemId;
                    element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                  }
                });

                let url = element.itemImageUrl;
                let pattern = /^((http|https|ftp):\/\/)/;
                if (pattern.test(url)) {
                  element.itemImageUrl = url;
                } else {
                  element.itemImageUrl = '';
                }
              });
              this.showItemLoadingScreen = false;
            })
            .catch((err) => {
              //console.log(err);
            });
        },
        (err) => {
          this.failedToGetItemList = true;
          this.showItemLoadingScreen = false;
        }
      );
    }
  }

  showRunTimeLoader: boolean = false;
  cartDetails = {
    noOfItems: 0,
    totalAmount: 0,
  };

  itemIdForLoading: string = '';

  seperateOUTOFSTOCKitems(data) {

    let Out_Of_Stock_item = [];

    data = data.filter((element)=> {
      if (element.itemStatus === "OUT_OF_STOCK" || element.itemStatus === "VENDOR_NOT_AVAILABLE")
        Out_Of_Stock_item.push(element);
      else
        return true;
    });

    return [...data, ...Out_Of_Stock_item];
  }

  addItemAfterMultipleFoodcourtCheck(item) {
    // add only when item belong to same food court condition check

    if (this.isComingFromPreOrderingPage) {
      let preOrderingMenuType = this.landingComponentService.preOrderMenuType;
      let preOrderingDetails = this.landingComponentService.preOrderDetails;
      
      this.landingComponentService.setIsOrderPreOrderType(true);
      this.landingComponentService.setPreOrderDetails(preOrderingDetails);
      this.landingComponentService.setPreOrderMenuType(preOrderingMenuType);

      let currentOrderFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
      if (currentOrderFoodCourtId && currentOrderFoodCourtId !== item.foodCourtId) {
        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '320px',
          height: 'auto',
          data: { type: '' },
        });
        dialogRef.afterClosed().subscribe((data) => {
          if (data?.isSuccess === true) {
            this.globalCartService.getAllCartData().then(()=> {
              this.addItemAfterCustomizationCheck(item, "ADD");
            })
          } else if (data?.isSuccess === false) {
            this.landingComponentService.openSnackBarMessage('Error Occured');
          }
        });
      } else {
        this.addItemAfterCustomizationCheck(item, "ADD");
      }

    } else {
        const isPreOrderingType = this.landingComponentService.getIsOrderPreOrderType();
        if (isPreOrderingType) {
          const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
            width: '380px',
            height: 'auto',
            data: { type: 'PREORDER_CONFIRMATION' },
          });
          dialogRef.afterClosed().subscribe((data) => {
            if (data) {
              this.isIntoPreOrderingFlow = null;
              this.isComingFromPreOrderingPage = false;
              this._getItemListByMealType_();
            }
          });
        }
        else {
          
          let currentOrderFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
          if (currentOrderFoodCourtId && currentOrderFoodCourtId !== item.foodCourtId) {
            const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
              width: '320px',
              height: 'auto',
              data: { type: '' },
            });
            dialogRef.afterClosed().subscribe((data) => {
              if (data?.isSuccess === true) {
                this.globalCartService.getAllCartData().then(()=> {
                  this.addItemAfterCustomizationCheck(item, "ADD");
                })
              } else if (data?.isSuccess === false) {
                this.landingComponentService.openSnackBarMessage('Error Occured');
              }
            });
          } else {
            this.addItemAfterCustomizationCheck(item, "ADD");
          }

        } 
    }
  }

  addItemAfterCustomizationCheck(item, actionType) {
    // console.log(item);

    // Item will be added after checking for customization required
    if (item.customizationRequired) {
      if(!item.menuCustomizationList || item.menuCustomizationList.length === 0) {

        // If menuCustomizationList is null or [] but customizationRequired is true then I am getting menuCustomizationList by calling api

        this.chooseMealService.getAllCustomizationsOfItem(item.id).subscribe((response:any) => {
          if(response.data && response.data.length > 0) {
            item.menuCustomizationList = response.data.map((element) => {
              let obj = Object.assign({}, element);
              obj.customizedItemsForMenuList.forEach((customItems) => {
                customItems.status = false;
              })

              return obj;
            });
          }

          this.openCustomizationBottomSheet(item, actionType);
        })
      } else {
        item.menuCustomizationList.forEach((element) => {
          element.customizedItemsForMenuList.forEach((customItems) => {
            customItems.status = false;
          })
        })

        if(actionType === 'ADD') {
          item.orderedItemCustomizations = null;
        }

        this.openCustomizationBottomSheet(item, actionType);
      }
    } else {
      if(actionType === 'ADD') {
          this.addItem(item)
        } else if(actionType === 'INCREASE') {
          this.increaseItemCount(item);
        }
    }
  }

  openCustomizationBottomSheet(item, actionType) {
    const dialogRef = this.bottomSheet.open(CustomizeItemBottomsheetComponent, {
      data: {data: item, edit: false },
    });
    dialogRef.afterDismissed().subscribe((response)=> {
      if(!response) return;
      item.orderedItemCustomizations = response.selectedCustomization;
      if(actionType === 'ADD') {
        this.addItem(item)
      } else if(actionType === 'INCREASE') {
        this.increaseItemCount(item);
      }
    });
  }

  addItem(item) {
    this.itemIdForLoading = item.id;
    item.showCustomQuantity++;
    item.itemQuantity = item.showCustomQuantity;
    this.showRunTimeLoader = true;

    let foodCourtId = this.landingComponentService.getFoodCourtId();
    let foodCourtName = this.landingComponentService.getFoodCourtName();
    this.landingComponentService.setOrderFoodCourtId(foodCourtId);
    this.landingComponentService.setOrderFoodCourtName(foodCourtName);

    if (this.isComingFromPreOrderingPage) {
 
      let preOrderingEndDate = new Date(this.landingComponentService.getPreOrderDetails());

      item.preOrderDate =  preOrderingEndDate;
      
      item.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0"+preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
                             (preOrderingEndDate.getMinutes() < 10 ? ("0"+preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes() ) + ":" + 
                             (preOrderingEndDate.getSeconds() < 10 ? ("0"+preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

      item.preOrderStartTime = ((preOrderingEndDate.getHours() -1) < 10 ? ("0"+(preOrderingEndDate.getHours() -1)) : preOrderingEndDate.getHours() -1) + ":" +
                               (preOrderingEndDate.getMinutes() < 10 ? ("0"+preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes() ) + ":" + 
                               (preOrderingEndDate.getSeconds() < 10 ? ("0"+preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";;
    }

    // Now everytime item will be added to indexedDB and again user cart is updated with items present indexedDB cart

      this.globalCartService
      .addItemToIndexedDB(item, false, this.isComingFromPreOrderingPage)
      .then((response: any) => {

        // Function calling for updating user cart with indexedDB cart and updating count of items in ui
        this.getUserCartDataIndexDB();

        // this.landingComponentService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.quantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
  }

  increaseItemCount(item) {
    this.itemIdForLoading = item.id;
    this.showRunTimeLoader = true;

    item.showCustomQuantity++;
    
    // item.quantity = item.showCustomQuantity;
    // item.itemQuantity = item.showCustomQuantity;

    // Now everytime item will be added to indexedDB and again user cart is updated with items present indexedDB cart
    this.globalCartService.checkForCustomizedItemInIndexDb(item)
        .then((result: any) => {
          this.globalCartService
          .addItemToIndexedDB(item, result.isSameItemPresent, this.isComingFromPreOrderingPage)
          .then((response: any) => {

            // Function calling for updating user cart with indexedDB cart and updating count of items in ui
            this.getUserCartDataIndexDB();

            // this.landingComponentService.itemQuantityChangeFromCart.next(true);
          })
          .catch((err) => {
            this.showRunTimeLoader = false;
            item.showCustomQuantity -= 1;
            item.itemQuantity -= 1;
            // this.landingConsoleService.openSnackBarMessage(err);
          });
        })
    // this.globalCartService
    //   .increaseItemCount(item)
    //   .then((response: any) => {
    //     this.cartDetails = response;
    //     this.landingComponentService.itemQuantityChange.next(true);
    //     this.showRunTimeLoader = false;
    //   })
    //   .catch((err) => {
    //     item.quantity -= 1;
    //     this.showRunTimeLoader = false;
    //     this.landingComponentService.openSnackBarMessage(err);
    //   });
  }

  decreaseItemCount(item) {
    this.globalCartService.checkForCustomizedItemInIndexDb(item)
    .then((result: any) => {
      if(item.customizationRequired && result.customizedItemCount > 1) {
        this.dialog.open(ConfirmationPopupComponent, {
          width: '90vw',
          height: 'auto',
          maxWidth: '100vw',
          maxHeight: '50vh',
          data: { type: 'REMOVE_CUSTOMIZATION' },
        });
      } else {
        this.itemIdForLoading = item.id;
        item.showCustomQuantity--;
        // item.quantity = item.showCustomQuantity;
        item.itemQuantity = item.showCustomQuantity;
        this.showRunTimeLoader = true;
        // if (item.quantity > 0) {
          if(item.itemQuantity > 0) {
            this.globalCartService
              .addItemToIndexedDB(item, true, this.isComingFromPreOrderingPage)
              .then((response: any) => {
                // Function calling for updating user cart with indexedDB cart and updating count of items in ui

                this.getUserCartDataIndexDB();

                // this.landingComponentService.itemQuantityChangeFromCart.next(true);
              })
              .catch((err) => {
                this.showRunTimeLoader = false;
                item.quantity += 1;
                // this.landingConsoleService.openSnackBarMessage(err);
            });
          } else {
            this.globalCartService.removeItemFromCart(item)
                .then(response => {
                  
                  let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();

                  if(orderedFoodCourtId) {
                    this.getUserCartDataIndexDB();
                  } else {
                    this.showRunTimeLoader = false;
                  }
                  // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                })
                .catch(error => {
                  this.showRunTimeLoader = false;
                  item.quantity += 1;
                })
          }
        // this.globalCartService
        //   .decreaseItemCount(item)
        //   .then((response: any) => {
        //     this.cartDetails = response;
        //     this.landingComponentService.itemQuantityChange.next(true);
        //     this.showRunTimeLoader = false;
        //   })
        //   .catch((err) => {
        //     item.quantity += 1;
        //     this.showRunTimeLoader = false;
        //     this.landingComponentService.openSnackBarMessage(err);
        //   });
        // } else {
        // this.globalCartService
        //   .removeItemFromCart(item)
        //   .then((response) => {
        //     this.showRunTimeLoader = false;
        //   })
        //   .catch((err) => {
        //     this.showRunTimeLoader = false;
        //     this.landingComponentService.openSnackBarMessage(err);
        //   });
        // }
      }
    })
  }

  getUserCartDataIndexDB() {
    // this.showLoader = true;
    this.indexDbService.getAllItemFromCart().then((response) => {

      return new Promise((resolve, reject) => {
        resolve(response);
      })
    })
    .then((response: any) => {
      let userName = this.landingComponentService.getUserName();
      let userMobile = this.landingComponentService.getUserMobile();
      let userId = this.landingComponentService.getUserId();
      let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
      let btcRequestId = this.landingComponentService.getBTCRequestId();
      
      let deliveryAddress = this.landingComponentService.getUserAddressForBTC();
      let orderType = this.landingComponentService.getUserOrderTypeForBTC();

      if(orderedFoodCourtId) {
        if(userId) {
          
          let data = {
            
            address: deliveryAddress,
            orderType: orderType,
            foodCourtId: orderedFoodCourtId,
            userId: userId,
            userName: userName,
            mobile: userMobile,
            platform: "WEB",
            itemsList: {},
            mealCategory: this.MEALTYPE,

            btcRequestId: btcRequestId,

            preOrder: false,
            preOrderDate: null,
            preOrderEndTime: null,
            preOrderStartTime: null
          };
          
          if (this.isComingFromPreOrderingPage) {
      
            let preOrderingEndDate = new Date(this.landingComponentService.getPreOrderDetails());

            data.preOrder = true;
            data.preOrderDate =  preOrderingEndDate,
            
            data.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0"+preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
                                      (preOrderingEndDate.getMinutes() < 10 ? ("0"+preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes() ) + ":" + 
                                      (preOrderingEndDate.getSeconds() < 10 ? ("0"+preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            data.preOrderStartTime = ((preOrderingEndDate.getHours() -1) < 10 ? ("0"+(preOrderingEndDate.getHours() -1)) : preOrderingEndDate.getHours() -1) + ":" +
                                      (preOrderingEndDate.getMinutes() < 10 ? ("0"+preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes() ) + ":" + 
                                      (preOrderingEndDate.getSeconds() < 10 ? ("0"+preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";


          }
          data.itemsList = response;

          this.landingComponentService.addItemToCartAPI(data).subscribe(
            (res) => {

              this.foodItemList.forEach((element) => {
                element.showCustomQuantity = 0;
                element.itemId = element.id;
                response.forEach((cartItem) => {
                  if (element.id === cartItem.itemId) {
                    element.showCustomQuantity += cartItem.itemQuantity;
                    element.appItemId = cartItem.appItemId;
                    element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                  }
                });
              })

              this.landingComponentService.itemQuantityChange.next(true);
              this.showRunTimeLoader = false;
            },
            (err) => {
              // this.showRunTimeLoader = false;
              // this.showLoader = false;
              //console.log(err);
              this.handleErrorForAddingItems();
              this.landingComponentService.openSnackBarMessage(
                'Failed to add item to Cart'
              );
            }
          );
        } else {
          this.foodItemList.forEach((element) => {
            element.showCustomQuantity = 0;
            element.itemId = element.id;
            response.forEach((cartItem) => {
              if (element.id === cartItem.itemId) {
                element.showCustomQuantity += cartItem.itemQuantity;
                element.appItemId = cartItem.appItemId;
                element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            });
          })
          
          this.landingComponentService.itemQuantityChange.next(true);
          this.showRunTimeLoader = false;
        }
      } else {
        this.landingComponentService.itemQuantityChange.next(true);
        this.showRunTimeLoader = false;
      }
    })
  }

  handleErrorForAddingItems() {
    let userId = this.landingComponentService.getUserId();

    // Clearing local db and getting user cart data from backend and adding back to local db and maintaining the count of items
    this.indexDbService.clearAllItemsFromCart()
        .then((result) => {
          this.landingComponentService.getCartData(userId).subscribe(async (response : any) => {
            let orderFoodCourtId = response.data.foodCourtId;
            this.landingComponentService.setOrderFoodCourtId(orderFoodCourtId);

            console.log("user cart from api", response);
            for(let item of response.data.itemsList) {
              await this.globalCartService.addItemToIndexedDB(item, false);
              // console.log("items adding in process");
            }

            this.foodItemList.forEach((element) => {
              element.showCustomQuantity = 0;
              element.itemId = element.id;
              response.forEach((cartItem) => {
                if (element.id === cartItem.itemId) {
                  element.showCustomQuantity += cartItem.itemQuantity;
                  element.appItemId = cartItem.appItemId;
                  element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              });
            })

            this.showRunTimeLoader = false;
            // console.log("cart added to items complete");
          }, error => {
            this.landingComponentService.openSnackBarMessage("Failed to add item to cart, please refresh once")
          })
        })
        .catch((error) => {
          this.showRunTimeLoader = false;
        })
  }

  openItemDetailBox(item) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '320px',
      height: 'auto',
      data: { type: 'ITEM_DETAILS', itemDetails: item },
    });
    dialogRef.afterClosed().subscribe((data) => {});
  }

  back() {
    if (this.isComingFromPreOrderingPage && this.scrWidth<600) {
      this.router.navigate(['user/preordering']);
    } else {
      let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
      this.router.navigate([`${foodCourtName}`]);
    }
  }
  getitems(value) {

    if (value === this.selectedCuisineType)
      return;

    this.CUISINE_LIST.forEach((element) => {
      if (element.value !== value) element.status = false;
      else element.status = true;
    });
    this.selectedCuisineType = value;

    this._getItemListByMealType_();
  }

  _searchFoodItem() {
    if (this.searchText.length > 2) this.searchFoodItem();
    else if (this.searchText.length === 0) this._getItemListByMealType_();
  }
  searchFoodItem() {
    let foodCourtId = this.landingComponentService.getFoodCourtId();

    let itemDetails = {
      cuisineCategory: this.selectedCuisineType ? this.selectedCuisineType.toLowerCase() : null,
      foodCourtId: foodCourtId,
      itemType: this.isVegOnly ? 'VEG' : null,
      mealCategory: this.MEALTYPE,
      name: this.searchText,
    };

    this.showItemLoadingScreen = true;
    this.chooseMealService.searchFoodItemByText(itemDetails).subscribe(
      (response: any) => {
        this.foodItemList = response.data;
        this.globalCartService
          .getUserCartData()
          .then((data: any) => {
            this.userCartDetails = data.userCartData;
            this.cartDetails = data.cartPreviewData;

            // this.foodItemList.forEach((element) => {
            //   this.userCartDetails?.forEach((cartItem) => {
            //     if (element.id === cartItem.itemId) {
            //       element.quantity = cartItem.itemQuantity;
            //     }
            //   });

            //   let url = element.itemImageUrl;
            //   let pattern = /^((http|https|ftp):\/\/)/;
            //   if (pattern.test(url)) {
            //     element.itemImageUrl = url;
            //   } else {
            //     element.itemImageUrl = '';
            //   }
            // });

            this.foodItemList.forEach((element) => {
              element.showCustomQuantity = 0;
              element.itemId = element.id;
              this.userCartDetails.forEach((cartItem) => {
                if (element.id === cartItem.itemId) {
                  element.showCustomQuantity += cartItem.itemQuantity;
                  element.appItemId = cartItem.appItemId;
                  element.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              });
            })

            this.showItemLoadingScreen = false;
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      (err) => {
        this.landingComponentService.openSnackBarMessageIMP(
          'Failed to Search! Plz try again later'
        );
        //console.log(err);
      }
    );
  }

  showAllItemList() {
    if (this.searchText.length === 0) this._getItemListByMealType_();
  }
}
