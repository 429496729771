<section class="customization-section">
  <div class="close-img">
    <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closeBottomSheet()">
  </div>
  <ng-container
    *ngIf="(!data.data.orderedItemCustomizations || data.data.orderedItemCustomizations.length === 0) || data.edit; else repeatCustomization">
    <div class="item-name">{{ customizationList[0]?.menuItemName | titlecase }}</div>
    <div class="primary-text">Customize your food</div>
    <ng-container *ngFor="let customType of customizationList">
      <div class="customization-card">
        <div class="secondary-text">{{ customType.customizationCategoryName }} <span
            *ngIf="customType.maxOptionAllowed > 1">(Max Option Allowed - {{ customType.maxOptionAllowed }})</span>
        </div>
        <mat-radio-group class="example-radio-group" [(ngModel)]="customType.selectedCustomItemId"
          *ngIf="customType.maxOptionAllowed === 1">
          <ng-container *ngFor="let customItem of customType.customizedItemsForMenuList">
            <div class="custom-item-container">
              <div class="custom" [ngStyle]="{color : !customItem.active ? 'gray': 'black'}">{{ customItem.subTitle |
                titlecase }} <span *ngIf="!customItem.active">(Not Available)</span></div>
              <div class="price">
                <!-- <div class="price-value" [ngStyle]="{color : !customItem.active ? 'gray': 'black'}">&#8377;{{ ( customItem.price / 100 ) | number:"1.0-2" }}</div> -->
                <div class="price-value" [ngStyle]="{color : !customItem.active ? 'gray': 'black'}">&#8377;{{ (
                  customItem.price) | number:"1.0-2" }}</div>
                <div class="radio-btn">
                  <mat-radio-button color="warn" [value]="customItem.uniqueId"
                    (change)="selectCustomItemOption(customType, customItem, customItem.uniqueId)"
                    [disabled]="!customItem.active"></mat-radio-button>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-radio-group>
        <ng-container *ngIf="customType.maxOptionAllowed > 1">
          <ng-container *ngFor="let customItem of customType.customizedItemsForMenuList">
            <div class="custom-item-container" *ngIf="customType.maxOptionAllowed > 1">
              <div class="custom">{{ customItem.subTitle | titlecase }} <span *ngIf="!customItem.active">(Not
                  Available)</span></div>
              <div class="price">
                <!-- <div class="price-value">&#8377;{{ ( customItem.price / 100 ) | number:"1.0-2" }}</div> -->
                <div class="price-value">&#8377;{{ ( customItem.price) | number:"1.0-2" }}</div>
                <div class="radio-btn">
                  <mat-checkbox color="warn" [checked]="customItem.status"
                    (change)="selectCustomItemOption(customType, customItem, customItem.uniqueId)" [disabled]="
                    !customItem.active ||
                    (customItem.isDefault ? customItem.status : false) ||
                    (noOfItemsSelected + 1 > customType.maxOptionAllowed ? (!customItem.isDefault && !customItem.status) : false)
                    "></mat-checkbox>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <div class="btns-container" (click)="addItemToCart()">
      <!-- <div class="total-price">Total &#8377; {{ totalPrice/100 | number:"1.0-2"}}</div> -->
      <div class="total-price">Total &#8377; {{ totalPrice | number:"1.0-2"}}</div>
      <div class="add-text" *ngIf="!data.edit">Add to cart</div>
      <div class="add-text" *ngIf="data.edit">Update Item</div>
    </div>
  </ng-container>
  <ng-template #repeatCustomization>
    <div class="primary-text">Repeat last item customization</div>
    <div class="menu-item-container">
      <div class="secondary-text">
        <img *ngIf="data.data.veg" src="../../../../../../../assets/images/cart/veg-item.svg" class="veg-icon" alt="" />
        <img *ngIf="!data.data.veg" src="../../../../../../../assets/images/cart/non-veg-item.svg" class="veg-icon"
          alt="" />
        {{ customizationList[0]?.menuItemName }}
      </div>
      <div class="custom">{{ shownCustomizedItemsName | titlecase }}</div>
    </div>
    <div class="btns">
      <div class="add-btn" (click)="updateCustomization()">Add New</div>
      <div class="repeat-btn" (click)="addItemToCart()">Repeat Last</div>
    </div>
  </ng-template>
</section>