<section class="food-subscription-listing-section">
    <div class="foodsubcription-heading" [ngStyle]="{'border-top': (showBackBtn ? 'none': '')}">
        <div class="back-arrow-container" (click)="goToHomePage()" *ngIf="showBackBtn">
            <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
        </div>
        <div class="heading-text">Food Subscription</div>
    </div>
    <div class="foodsubscription-checkbox-container">
        <mat-radio-group [(ngModel)]="selectedSubscriptionMealType" (ngModelChange)="modifySubscriptionList()">
            <mat-radio-button [color]="'primary'" *ngFor="let subscriptionType of subscriptionTypeList"
                [value]="subscriptionType.value">{{ subscriptionType.name | titlecase }}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="foodsubscription-list" *ngIf="filteredSubscriptionPlanList.length > 0; else showEmptyScreen">
        <ng-container *ngFor="let subscriptionPlan of filteredSubscriptionPlanList">
            <div class="subscription-card" (click)="openSubscriptionDetails(subscriptionPlan)">
                <div class="subscription-card-text">
                    <div>
                        <ng-container *ngIf="subscriptionPlan.mealType === 'VEG' ">
                            <img src="../../../../../../../assets/images/common/veg-item.svg" alt="">
                        </ng-container>
                        <ng-container *ngIf="subscriptionPlan.mealType === 'NON_VEG' ">
                            <img src="../../../../../../../assets/images/common/non-veg-item.svg" alt="">
                        </ng-container>
                        <div> {{ subscriptionPlan.title | uppercase }}</div>
                    </div>

                </div>
                <div class="subscription-card-description">
                    <ul>
                        <li>
                            <div *ngIf="subscriptionPlan.desc.length > 60 && !subscriptionPlan.showSeeMoreBtn; else showTrimmedDescription">
                                {{ subscriptionPlan.desc.substring(0, 60) | titlecase }}... 
                                <span class="see-more-btn" (click)="toggleSeeMoreBtn(subscriptionPlan)" *ngIf="!subscriptionPlan.showSeeMoreBtn">see more</span>
                            </div>
                            <ng-template #showTrimmedDescription>
                                {{ subscriptionPlan.desc | titlecase }} 
                                <span class="see-more-btn" (click)="toggleSeeMoreBtn(subscriptionPlan)" *ngIf="subscriptionPlan.showSeeMoreBtn">see less</span>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div class="subscription-card-timing-list">
                    <ng-container *ngFor="let timing of subscriptionPlan.fcItemsList">
                        <div class="subscription-card-timing">
                            <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                            <span>{{ timing.startTime | convertLocalDate }} - {{ timing.endTime | convertLocalDate }} (
                                {{ timing.serveType | titlecase }} )</span>
                        </div>
                    </ng-container>
                </div>
                <div class="subscription-card-footer">
                    <div class="subscription-price">&#8377; {{ (subscriptionPlan.price ) | number: '1.0-2'}} / Month </div>
                    <button class="subscribe-btn" (click)="subscribePlan(subscriptionPlan)">Subscribe</button>
                </div>
            </div>

        </ng-container>
    </div>
    <ng-template #showEmptyScreen>
        <div class="empty-part">
            <div class="no-image">
                <img src="../../../../../../assets/images/cart/empty-cart.svg" alt="empty-image" />
            </div>
            <div class="empty-heading">No Subscription Plans</div>
            <div class="empty-subtext">There are no active subscription plans for your selected category</div>
        </div>
    </ng-template>
</section>