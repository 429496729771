import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { Platform } from '@angular/cdk/platform';
@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private router: Router,
    public platform: Platform,
  ) { }

  ngOnInit(): void {
    this.dataSource = this.data.notificationData;

    if (this.dataSource.notificationType === 'EVENTS') {
      if (this.dataSource.eventTime) {
        const eventTime = this.dataSource.eventTime.split(':');

        this.dataSource.eventTime = new Date(0, 0, 0, eventTime[0], eventTime[1], eventTime[2]);
      }

      if (this.dataSource.eventEndTime) {
        const eventTime = this.dataSource.eventEndTime.split(':');

        this.dataSource.eventEndTime = new Date(0, 0, 0, eventTime[0], eventTime[1], eventTime[2]);
      }
    }
  }

  dataSource: any;

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  goToStore() {
    let appDownloadLink = '';

    if (this.platform.ANDROID) {
      appDownloadLink =
        'https://play.google.com/store/apps/details?id=com.tao.isthara';
    } else if (this.platform.IOS) {
      // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
      appDownloadLink = 'https://tinyurl.com/5n7vn985';
    }

    const aElement = document.createElement('a');
    aElement.href = appDownloadLink;
    aElement.click();
    this.closeBottomSheet();
  }

  goToHomePage() {
    const fcName = String(Object.values(this.dataSource.foodcourtIdAndName)[0]);

    this.router.navigate([`${fcName.toLowerCase().trim().split(' ').join('-')}`]);
    this.closeBottomSheet();
  }
}
