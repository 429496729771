import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { LandingComponentService } from '../../landing-component.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  foodCourtName: string = null;
  siteName: string = null;
  currentUrl: string = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private landingComponentService: LandingComponentService,
    private popUpDbService: PopupDbService
  ) {}

  ngOnInit(): void {
    // this.currentUrl = this.router.url.split("/")[2].split("?")[0];
    this.currentUrl = (this.router.url.split('/').length > 2 ? this.router.url.split('/')[1] : '');
    this.checkForCurrentPage();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = (this.router.url.split('/').length > 2 ? this.router.url.split('/')[1] : '');;
        this.checkForCurrentPage();
      }
    });
  }

  footerList = [
    { name: 'home', value: '', status: true },
    { name: 'search', value: 'SEARCH', status: false },
    { name: 'cart', value: 'CART', status: false },
    { name: 'profile', value: 'PROFILE', status: false },
  ];

  checkForCurrentPage() {
    
    this.footerList.forEach((element) => {
      if (element.value === this.currentUrl.toUpperCase()) element.status = true;
      else element.status = false;
    });
  }
  redirect(element) {
    this.footerList.forEach((element) => {
      element.status = false;
    });
    element.status = true;

    this.foodCourtName = this.landingComponentService.getFoodCourtName();
    this.siteName = this.landingComponentService.getSiteName();

    let foodCourtNameUrl = this.foodCourtName
      ? this.foodCourtName?.split(' ').join('-').toLowerCase()
      : ' ';
    let siteNameUrl = this.siteName
      ? this.siteName?.split(' ').join('-').toLowerCase()
      : ' ';

    if (element.value === '')
      this.router.navigate([
        `/${foodCourtNameUrl}`,
      ]);
    else if (element.value === 'SEARCH')
      this.router.navigate([
        `search/${foodCourtNameUrl}`,
      ]);
    else if (element.value === 'CART') {
      this.router.navigate([
        `cart/${foodCourtNameUrl}`,
      ]);
    } else {
      const userId = this.popUpDbService.getUserId();
      if (userId) {
        this.router.navigate(['profile/user']);
      } else {
        this.router.navigate(['auth']);
      }
    }
  }
}
