export const maritalStatus = ['SINGLE', 'MARRIED'];

export const genderList = ['MALE', 'FEMALE', 'OTHER'];

export const occupationList = ['STUDENT', 'IT'];
export const employmentType = ['work1', 'work2', 'work3'];
export const propertyTypeList = [
  { name: 'boy', value: 'MENS' },
  { name: 'girl', value: 'WOMENS' },
  { name: 'coliving', value: 'COLIVING' },
];

export const VerificationStatus = [
  { value: 'VERIFIED', viewValue: 'Verified' },
  { value: 'PENDING', viewValue: 'Unverified' },
];

export const MRP = [
  { value: true, viewValue: 'Yes' },
  { value: false, viewValue: 'No' },
];

export const sharingTypeList = [
  { name: 'single', value: 'SINGLE', studVal: 'ONE' },
  { name: 'double', value: 'DOUBLE', studVal: 'TWO' },
  { name: 'triple', value: 'TRIPLE', studVal: 'THREE' },
  { name: 'four', value: 'FOUR', studVal: 'FOUR' },
  { name: 'five', value: 'FIVE', studVal: 'FIVE' },
  { name: 'six', value: 'SIX', studVal: 'SIX' },
  { name: 'hybrid', value: 'HYBRID', studVal: 'HYBRID' },
];

export const sourcesList = [
  { name: 'website', value: 'WEBSITE' },
  { name: 'facebook', value: 'FACEBOOK' },
  { name: 'google ads', value: 'GOOGLE_ADS' },
  { name: 'instagram', value: 'INSTAGRAM' },
  { name: 'campaign', value: 'CAMPAIGN' },
  { name: 'walk in', value: 'WALK_IN' },
  { name: 'mobile', value: 'MOBILE' },
];
export const bookingSource = [
  { name: 'Tele-Booking', value: 'TELE_BOOKING' },
  { name: 'Walk-In', value: 'WALK_IN' },
  { name: 'Sales Manger', value: 'SALES_MANAGER' },
];

export const typeofstay = [
  { name: 'Fixed-Stay', value: 'FIXED_STAY' },
  { name: 'Long-Stay', value: 'LONG_STAY' },
];

export const institutionType = [
  { name: 'School', value: 'SCHOOL' },
  { name: 'College', value: 'COLLEGE' },
];

export const KycDocumentType = [
  { value: 'VOTER_ID', viewValue: 'Voter ID' },
  { value: 'AADHAAR', viewValue: 'Aadhaar Card' },
  { value: 'PASSPORT', viewValue: 'Passport' },
  { value: 'DRIVING_LICENCE', viewValue: 'Driving License' },
  { value: 'PAN', viewValue: 'Pan Card' },
];
export const statusList = [
  { name: 'new', value: 'NEW' },
  { name: 'Casual Browsing', value: 'CASUAL_BROWSING' },
  { name: 'Not Rechable/Callback', value: 'NOT_REACHABLE_CALLBACK' },
  { name: 'Assigned To Sales Team', value: 'ASSIGNED_SALES' },
  { name: 'Site Visit Planned', value: 'VISIT_PLANNED' },
  { name: 'Site Visit Completed', value: 'VISIT_COMPLETED' },
  { name: 'In Followup', value: 'IN_FOLLOWUP' },
  { name: 'Ready For Booking', value: 'READY_FOR_BOOKING' },
  { name: 'Not Intrested', value: 'NOT_INTERESTED' },
  { name: 'Closed/Not Answer', value: 'CLOSED_NO_ANSWER' },
  { name: 'Lost', value: 'LOST' },
  { name: 'Repeated Lead', value: 'REPEATED_LEAD' },
  { name: 'Prospect', value: 'PROSPECT' },
];

export const siteType = [
  {
    name: 'Educational Institution',
  },
  {
    name: 'Medical Institution',
  },
  {
    name: 'Corporate Organisation',
  },
];

export const cityList = ['1', '2', '3'];

export const callLogs = [
  {
    text: 'Not picked call',
    status: false,
    value: 'NOT_PICKED_CALL',
    subAction: [],
  },
  {
    text: 'Not Interested',
    status: false,
    value: 'NOT_INTERESTED',
    subAction: [
      {
        name: 'Reason',
        values: [
          { name: 'Plan Cancelled', status: false, value: 'PLAN_CANCELLED' },
          {
            name: 'Looking for flat',
            status: false,
            value: 'LOOKING_FOR_FLAT',
          },
          { name: 'Need co-living', status: false, value: 'NEED_COLIVING' },
          { name: 'Prices are high', status: false, value: 'PRICES_ARE_HIGH' },
          {
            name: 'Location not Matched',
            status: false,
            value: 'location_not_matched',
          },
          { name: 'Already booked', status: false, values: 'already_booked' },
        ],
      },
    ],
  },
  {
    text: 'Schedule call for later',
    status: false,
    value: 'SCHEDULE_CALL_FOR_LATER',
  },
  { text: 'Interested', status: false, value: 'INTERESTED' },
  // { text: 'Visit booked', status: false, value: 'VISIT_BOOKING' },
  { text: 'Already booked', status: false, value: 'ALREADY_BOOKED' },
];
// export const notIntrestedResons = [
//   { name: 'Plan cancelled', status: false, value: 'PLAN_CANCELLED' },
//   { name: 'Looking for flat', status: false, value: 'LOOKING_FOR_FLAT' },
//   { name: 'Need co-living', status: false, value: 'NEED_COLIVING' },
//   { name: 'Prices are high', status: false, value: 'PRICES_ARE_HIGH' },
//   {
//     name: 'Location not matched',
//     status: false,
//     value: 'LOCATION_NOT_MATCHED',
//   },
//   { name: 'Already booked', status: false, value: 'ALREADY_BOOKED' },
// ];

export const bookedWith = [
  { name: 'Stanza', status: false, value: 'STANZA' },
  { name: 'Zolo', status: false, value: 'ZOLO' },
  { name: 'Colive', status: false, value: 'COLIVE' },
  { name: 'Botson', status: false, value: 'BOTSON' },
  { name: 'Local Pg', status: false, value: 'LOCAL_PG' },
];

export const resonBooking = [
  { name: 'Price', status: false, value: 'PRICE' },
  { name: 'Location', status: false, value: 'LOCATION' },
  { name: 'Amenities', status: false, value: 'AMENITIES' },
  { name: 'Offers', status: false, value: 'OFFERS' },
  { name: 'Friends', status: false, value: 'FRIENDS' },
  { name: 'Others', status: false, value: 'OTHERS' },
];

export const citiesList = [
  { city: 'Hyderabad' },
  { city: 'Gurgoan' },
  { city: 'Bangalore' },
  { city: 'Chennai' },
  { city: 'Pune' },
];

export const tasks = [
  { name: 'Task', value: 'TASK' },
  { name: 'Issue', value: 'ISSUE' },
  { name: 'Reccuring Task', value: 'RECURRING_TASK' },
  { name: 'Adhoc Task', value: 'ADHOC_TASK' },
];

export const bloodGroup = [
  { name: 'O+ve', value: 'O+ve' },
  { name: 'O-ve', value: 'O-ve' },
  { name: 'AB+ve', value: 'AB+ve' },
  { name: 'AB-ve', value: 'AB-ve' },
  { name: 'A+ve', value: 'A+ve' },
  { name: 'A-ve', value: 'A-ve' },
  { name: 'B+ve', value: 'B+ve' },
  { name: 'B-ve', value: 'B-ve' },
];

export const rejectReason = [
  { name: 'Exams are Near', value: 'EXAMS' },
  { name: 'Limit is Reached', value: 'LIMITS' },
  { name: 'Placement drive ', value: 'PLACEMENTS' },
  { name: 'Other', value: 'OTHER' },
];

export const leaveReasonType = [
  // { name: 'All', value: 'ALL', status: false },
  { name: 'Sick Leave', value: 'SICK_LEAVE', status: false },
  { name: 'Festival', value: 'FESTIVAL', status: false },
  { name: 'Sports Leave', value: 'SPORTS_LEAVE', status: false },
  { name: 'Other', value: 'OTHERS', status: false },
];

export const rejectedLatePassReason = [
  {
    name: 'Library is already closed',
    value: 'Library is already closed',
    status: false,
  },
  { name: "it's raining", value: 'its raining', status: false },
  {
    name: 'Not accepted at this time',
    value: 'not accepted at this time',
    status: false,
  },
  { name: 'Other', value: 'other', status: false },
];

export const mealCategory = [
  { name: 'Breakfast', value: 'BREAKFAST', checkactive: false },
  { name: 'Lunch', value: 'LUNCH', checkactive: false },
  { name: 'Snacks', value: 'SNACKS', checkactive: false },
  { name: 'Dinner', value: 'DINNER', checkactive: false },
  // { name: 'Cold Beverages', value: 'COLD_BEVERAGES ' },
  // { name: 'Hot Beverages', value: 'HOT_BEVERAGES ' },
];
export const foodType = [
  { name: 'veg', value: 'VEG', active: false },
  { name: 'Non-Veg', value: 'NON_VEG', active: false },
];
export const vendorType = [
  { name: 'Coliving', value: 'COLIVING', checkactive: false },
  { name: 'Student-living', value: 'STUDENT_LIVING', checkactive: false },
  { name: 'Food Court', value: 'FOOD_COURT', checkactive: false },
];
export const orderstate = [
  { name: 'Ready to Serve', value: 'READY_TO_SERVE', checkactive: false },
  { name: 'Need to prepare', value: 'NEED_TO_PREPARE', checkactive: false },
  { name: 'Served as Buffet', value: 'SERVED_AS_BUFFET', checkactive: false },
];

export const studentTypes = [
  { name: 'Day Scholar', value: 'DAY_SCHOLAR' },
  { name: 'Hosteller', value: 'HOSTELLER' },
];

export const attendanceType = [
  { name: 'Regular Attendance', value: 'REGULAR_ATTENDANCE' },
  { name: 'Food Attendance', value: 'FOOD_ATTENDANCE' },
  { name: 'Late Night Attendance', value: 'LATE_NIGHT_ATTENDANCE' },
];

export const deviceType = [
  { name: 'Biometric', value: 'BIOMETRIC' },
  { name: 'QR Code', value: 'QR_CODE' },
];

export const days = [
  { value: 'SUNDAY', checkactive: false },
  { value: 'MONDAY', checkactive: false },
  { value: 'TUESDAY', checkactive: false },
  { value: 'WEDNESDAY', checkactive: false },
  { value: 'THURSDAY', checkactive: false },
  { value: 'FRIDAY', checkactive: false },
  { value: 'SATURDAY', checkactive: false },
];

export const TIME_IN_MILLISECONDS = {
  DAY: 8.64*10000000,
  MONTH: 30*8.64*10000000
}