import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpdeskService } from '../helpdesk.service';

@Component({
  selector: 'app-helpdesk-category-list',
  templateUrl: './helpdesk-category-list.component.html',
  styleUrls: ['./helpdesk-category-list.component.scss']
})
export class HelpdeskCategoryListComponent implements OnInit {

  constructor(
    private router: Router,
    private helpdeskService: HelpdeskService
  ) { }

  ngOnInit(): void {
    this.getAllHelpDeskCategories();
  }

  helpdeskCategoryList=[];
  categoryListLoader: boolean = false;

  getAllHelpDeskCategories() {
    this.categoryListLoader = true;
    this.helpdeskService.getAllHelpDeskCategories().subscribe((response:any)=> {
      this.helpdeskCategoryList = response.data.filter((category)=> {
        return category.status;
      });
      this.categoryListLoader = false;
    }, (err)=> {
      this.categoryListLoader = false;
    })
  }

  goToRaiseTicketPage(category) {
    let sendingCategoryName = category.helpdeskCategoryName.replace(" ", "_");
    this.helpdeskService.setHelpdeskCategoryId(category.helpdeskCategoryId);
    this.router.navigate([`profile/user/helpdesk-raise-issue/${sendingCategoryName}`]);
  }
  goToHelpdeskListingPage() {
    this.router.navigate(['profile/user/helpdesk-list']);
  }

}
