<section class="discount-details-section">
    <!-- <div class="discount-image-section" [style.background]="'url('+data?.discountImageUrl+')'">
        <div class="discount-name">{{ data.discountName | uppercase }}</div>
        <div class="discount-description">{{ data.discountdesc | titlecase }}</div>
        <div class="vendor-name">{{ data.vendorName | titlecase }}</div>
    </div> -->
    <div class="discount-details__header">
      <div>{{ data?.discountName }}</div>
      <hr />
    </div>
    <div class="discount-terms-and-condition-section">
        <div class="primary-text">Terms & Condition</div>
        <!-- <ul> -->
            <!-- <li *ngIf="data.termsAndConditions?.applicableOnlyOnce">Applicable Only Once</li>
            <li *ngIf="data.termsAndConditions?.onlyNewUsers">Applicable Only For New User</li>
            <li *ngIf="data.termsAndConditions?.applicableOnSpecificTime">Applicable Only On {{ data.termsAndConditions?.applicableOnSpecificTime }}</li>
            <li *ngIf="data.termsAndConditions?.applicableOnSpecificDays.length > 0">
                Applicable Only On 
                <ng-container *ngFor="let days of data.termsAndConditions?.applicableOnSpecificDays">
                    <span>{{ days }}, </span>
                </ng-container>
            </li> -->
<!--             
            <li *ngIf="data.termsAndConditions.Days?.length > 0">
                Applicable on 
                <ng-container *ngIf="data.termsAndConditions.Days?.length === 7; else showDaysForOrders">
                  <span>All Days</span>
                </ng-container>
                <ng-template #showDaysForOrders>
                  <ng-container *ngFor="let day of data.termsAndConditions.Days">
                    <span>{{ day | titlecase }}, </span>
                  </ng-container>
                </ng-template>
              </li>
              <li *ngIf="data.termsAndConditions['Start Time'] && data.termsAndConditions['End Time']">
                Applicable from {{ data.termsAndConditions['Start Time'] }} - {{ data.termsAndConditions['End Time'] }}
              </li>
            <li *ngIf="data.termsAndConditions['Only Once'] === true">
                Applicable Only Once
            </li>
            <li *ngIf="data.minimumOrderAmount">
                Applicable on order above &#8377;{{ data.minimumOrderAmount }}
            </li>
        </ul> -->
        <ul>

          <li *ngIf="data.discountConstraints.limitedUse">
              Coupon code can be applied only once.
          </li>
          <!-- <li *ngIf="data.forMrpItems && data.forNonMrp; else showOnlyApplicableMRPItem">
              Coupon code is applicable for both MRP & NON-MRP Items.
          </li> -->

          <!-- <ng-template #showOnlyApplicableMRPItem>
            <li *ngIf="data.forMrpItems">Coupon code is applicable for MRP items.</li>
            <li *ngIf="data.forNonMrp">Coupon code is applicable for NON-MRP items.</li>
          </ng-template> -->
          <li>
            Coupons Availabe for 
            <ng-container *ngFor="let tax of data.taxCategoryList; let i = index">
              <span>{{ tax.split("_").join(" ") | titlecase }}<ng-container *ngIf="i !== 2">, </ng-container> </span>
            </ng-container>
             items
          </li>
          <li *ngIf="data.termsAndConditions.Days?.length > 0">
            Offer valid on
            <ng-container *ngIf="data.termsAndConditions.Days?.length === 7; else showDaysForOrders">
              <span>All Days.</span>
            </ng-container>
            <ng-template #showDaysForOrders>
              <ng-container *ngFor="let day of data.termsAndConditions.Days">
                <span>{{ day | titlecase }}, </span>
              </ng-container>
            </ng-template>
          </li>
          <li *ngIf="data.discountConstraints.applicableUsers === 'FIRST_TIME'">
            Offer is valid for first time users.
          </li>
          <li *ngIf="data.discountConstraints.applicableUsers === 'REGULAR'">
            Offer is valid for regular users.
          </li>
          <!-- <li *ngIf="data.discountConstraints.applicableUsers === 'ONCE_IN_A_WHILE'">
            Offer is valid for first time users.
          </li> -->
          <li *ngIf="data.discountConstraints.applicableUsers === 'ALL'">
            Offer is valid for all users.
          </li>
          <li *ngIf="data.discountConstraints.minimumOrderAmount">
            Offer valid on minimum net transaction amount of &#8377;{{ data.discountConstraints.minimumOrderAmount }}.
          </li>
          <li *ngIf="data.termsAndConditions['Start Time'] && data.termsAndConditions['End Time']">
            Coupon code can be applied only at {{ data.termsAndConditions['Start Time'] }} - {{ data.termsAndConditions['End Time'] }}.
          </li>
          <li *ngIf="data?.validTo">
            Offer valid till {{ data?.validTo | date }}.
          </li>
          <li>
            Other T&C may apply.
          </li>
          <!-- <ng-container
            *ngIf="
              data?.termsAndConditions.length > 0 &&
              data?.termsAndConditions[0]
            "
          >
            <ng-container
              *ngFor="let term_condition of data?.termsAndConditions"
            >
              <li>{{ term_condition | titlecase }}</li>
            </ng-container>
          </ng-container> -->
        </ul>
    </div>
</section>