<section class="reward-points-section">

  <div class="reward-points-container" *ngIf="!showLoader; else loadingScreen">
    <div class="header-container">
      <div class="reward-points-header">
        <div class="back-btn">
          <img src="../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToProfilePage()" />
        </div>
        <div class="heading-text">Points</div>
        <div class="header__action-btn">
          <span class="i-btn" (click)="toggleRefundMessage()"><mat-icon>info</mat-icon></span>
          <div class="refresh-icon">
            <img src="../../../../../../assets/images/common/refresh-page.svg" alt="Refresh Icon"
              (click)="refreshPage()" />
          </div>
        </div>
        <div class="refund-message" *ngIf="showRefundMessage">
          If you opted for both points and other payment method, bank refund option will be enabled once the refund
          value exceeds Isthara points payment value.
        </div>
      </div>
      <div class="points-main-section">
        <div class="points-container">
          <div class="points-value">
            {{ userRewardPoints.istharaPoints | number: "1.2-2" }}
          </div>
          <div class="points-text">Isthara Points</div>
        </div>
      </div>
    </div>
    <div class="transaction-history-section">
      <div class="transaction-history-top-section">
        <div class="header-text">Transaction History <span *ngIf="isFilteredData" class="primary-text">(Filtered)</span>
        </div>
        <img src="../../../../../../../assets/images/common/filter-icon.svg" alt="" (click)="filterbtn()"
          *ngIf="!isFilteredData">
        <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closeFilter()"
          *ngIf="isFilteredData">
      </div>
      <div class="transaction-history-panel">
        <ng-container *ngFor="let panel of transactionHistoryPanel">
          <div class="panel-container" [ngClass]="{'panel-selected': panel.status}"
            (click)="changeTransactionHistoryPanel(panel)">{{ panel.name | titlecase }}</div>
        </ng-container>
        <hr />
      </div>
      <ng-container *ngIf="userPointsHistory.length > 0; else emptyUserPointHistory">
        <div class="transaction-history-list-section">
          <ng-container *ngFor="let userHistory of userPointsHistory">
            <div class="user-points-history-container">
              <div class="main-container" (click)="showPointsSummary(userHistory)">
                <img src="../../../../../../../assets/images/common/wallet-icon.svg" alt="">
                <div class="points-details">
                  <div class="points-desc">
                    <span *ngIf="userHistory.paymentType === 'RECHARGE'">Recharged Points</span>
                    <span *ngIf="userHistory.paymentType === 'BANK_REFUND'">Refund back on payment source</span>
                    <span *ngIf="userHistory.paymentType === 'REFUND'">Refund received against order : {{
                      userHistory.orderNumber ? userHistory.orderNumber :'N/A' }}</span>
                    <span *ngIf="userHistory.paymentType === 'PAID'">Paid against order : {{ userHistory.orderNumber ?
                      userHistory.orderNumber :'N/A' }}</span>
                  </div>
                  <div class="date-time">{{userHistory.createdDateTime | date:'mediumDate'}}
                    {{userHistory.createdDateTime | date:'mediumTime'}} </div>
                </div>
                <div class="points-amount"
                  [ngClass]="{'refund': (userHistory.paymentType === 'REFUND' || userHistory.paymentType === 'RECHARGE' || userHistory.paymentType === 'BANK_REFUND'), 'paid': userHistory.paymentType === 'PAID'}">
                  <div *ngIf="selectedTransactionHistoryPanel === 'POINTS_HISTORY'">
                    <!-- <span
                      *ngIf="(userHistory.refundStatus !== 'REFUND_INITIATED' && userHistory.refundStatus !== 'REFUND_COMPLETED'); else showBankRefund">{{
                      (userHistory?.istharaRewardPoints / 100) | number:'1.1-2' }}</span> -->
                    <span
                      *ngIf="(userHistory.refundStatus !== 'REFUND_INITIATED' && userHistory.refundStatus !== 'REFUND_COMPLETED'); else showBankRefund">{{
                      (userHistory?.istharaRewardPoints) | number:'1.1-2' }}</span>
                    <ng-template #showBankRefund>
                      <!-- <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount * 100)) / 100) | number:'1.1-2' }}</span> -->
                      <!-- <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount)) / 100) |
                        number:'1.1-2' }}</span> -->
                      <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount))) |
                        number:'1.1-2' }}</span>
                    </ng-template>
                  </div>
                  <div *ngIf="selectedTransactionHistoryPanel === 'BANK_REFUNDS'">
                    <span *ngIf="userHistory?.displayRefundableAmount > 0">
                      {{ (userHistory?.displayRefundableAmount) | number:'1.1-2' }}
                    </span>
                    <span *ngIf="userHistory?.displayRefundableAmount <= 0">
                      <!-- {{ (userHistory?.istharaRewardPoints / 100) | number:'1.1-2' }} -->
                      {{ (userHistory?.istharaRewardPoints) | number:'1.1-2' }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="action-btn"
                *ngIf="userHistory.refundable && userHistory.showButton && userHistory.transactionType !== 'BANK_REFUNDED' && userHistory.refundStatus === 'NEW' ">
                <button (click)="refundToWallet(userHistory)">Refund back to Source &#8377;{{
                  userHistory?.displayRefundableAmount | number: '1.1-2' }}</button>
              </div>
              <div class="cashfree-points-refund-message"
                *ngIf="(userHistory.refundStatus === 'REFUND_INITIATED' || userHistory.refundStatus === 'REFUND_COMPLETED') && userHistory?.displayRefundableAmount > 0">
                Your refund of &#8377;{{ userHistory?.displayRefundableAmount | number:'1.1-2' }} is <span
                  *ngIf="userHistory.refundStatus === 'REFUND_INITIATED'">initiated</span> <span
                  *ngIf="userHistory.refundStatus === 'REFUND_COMPLETED'">completed</span>.
              </div>
            </div>
            <hr class="horizontal-line" />
          </ng-container>
        </div>
      </ng-container>
      <ng-template #emptyUserPointHistory>
        <div class="empty-user-point-section">
          <div class="empty-points-container">
            <img src="../../../../../../../assets/images/cart/empty-cart.svg" alt="">
          </div>
          <div class="text-container">
            <div class="heading-text">No Reward Points History Available</div>

          </div>
        </div>
      </ng-template>
    </div>
    <div class="recharge-points-footer">
      <button (click)="goToRechargeWalletPage()">Recharge Wallet</button>
    </div>
  </div>
  <ng-template #loadingScreen>
    <div class="loading-img-container">
      <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="" />
    </div>
  </ng-template>

</section>