import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LandingComponentService } from '../../landing-component/landing-component.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(
    private landingComponentService: LandingComponentService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // //console.log(route);
    // //console.log(state);
    const userId = this.landingComponentService.getUserId();

    if (route.url[0].path === 'auth') {
      if (userId) {
        this.landingComponentService.openSnackBarMessage(
          'You are already logged in',
          'Close'
        );
        let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
        return false;
      } else {
        return true;
      }
    } else {
      if (userId) {
        return true;
      } else {
        this.landingComponentService.openSnackBarMessage(
          'You are not logged in, please login',
          'Ok'
        );
        let foodCourtName = this.landingComponentService.getFoodCourtName()?.split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
        return false;
      }
    }
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // console.log('currentState', currentState);
    // console.log('currentRoute', currentRoute);
    // console.log('nextState', nextState);
    // console.log('nextState', nextState.url);
    // if (currentState.url === 'profile/user/order-summary') {
    //   this.router.navigate(['profile/user/order-history']);
    // } else {
    //   if (nextState.url.split('?')[0] === '/order/payment') {
    //     if (currentState.url === '/profile/user/order-history') {
    //       this.router.navigate(['profile/user']);
    //     }
    //   }
    // }

    return true;
  }
}
