import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { UserAccountService } from '../user-account.service';
import { genderList } from '../../../../../../constants/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { LandingComponentService } from '../../../landing-component.service';
import { MatDialogRef } from '@angular/material/dialog';

interface UserData {
  userName: string;
  countryCode: string;
  userMobile: string;
  userEmail: string;
  userId: string;
  profileImg: any;
  gender: string;
}
@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss'],
})
export class AccountEditComponent implements OnInit {
  constructor(
    private router: Router,
    private ds : DomSanitizer,
    private landingComponentService: LandingComponentService,
    private _userAccountService: UserAccountService,
    private _popupDbService: PopupDbService,
    @Optional() private dialogRef: MatDialogRef<AccountEditComponent>,
  ) {}

  // isTab: boolean = false;

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.userId = this._popupDbService.getUserId();

    this.showLoader = true;
    this.getUserDetails();

    // if (this.data?.type === 'FROM_TAB')
    //   this.isTab = true;
  }

  showLoader: boolean = false;
  genderList = genderList;
  userId: string = '';
  userDetails: UserData;

  getUserDetails() {
    this.showLoader = true;
    this._userAccountService.getUserDetailsByUserId(this.userId).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.userDetails = {
          userName: res.data.fullName,
          countryCode: res.data.countryCode,
          userMobile: res.data.mobile,
          userEmail: res.data.email.replace(" ", ""),
          userId: res.data.userId,
          profileImg: res.data.profilePicture || null,
          gender: res.data.gender || 'OTHER',
        };
        this.file = res.data.profilePicture;
        this.showLoader = false;
        //console.log(this.userDetails);
      },
      (err) => {
        this.showLoader = false;
        //console.log('Error while getting user details');
      }
    );
  }

  file;
  fileChange(e) {
    // //console.log(e);
    const file = e.srcElement.files[0];
    this.file = file;
    this.userDetails.profileImg = window.URL.createObjectURL(file);
  }

  loading: boolean = false;
  async updateProfile() {
    this.loading = true;

    let pattern = /^((http|https|ftp):\/\/)/;
    if (this.file) {
      if (pattern.test(this.file)) {
      } else {
        this.userDetails.profileImg =
          await this._userAccountService.uploadFile(this.file, "images");
        this.userDetails.profileImg = this.userDetails.profileImg.url;
      }
    }

    const data = {
      email: this.userDetails.userEmail.replace(/\s/g, ""),
      fullName: this.userDetails.userName,
      gender: this.userDetails.gender,
      mobile: this.userDetails.userMobile,
      profilePicture: this.userDetails.profileImg,
      userId: this.userDetails.userId,
    };

    this._userAccountService.updateUserProfile(data).subscribe(
      (res: any) => {
        //console.log(res);
        this.loading = false;
        this.landingComponentService.setUserName(this.userDetails.userName);
        this.landingComponentService.setUserEmail(this.userDetails.userEmail);
        this._popupDbService.openSnackBarMessage('Profile successfully updated','close');

        if (this.scrWidth < 600) {
          this.router.navigate(['profile/user']);
        } else {
          this.dialogRef.close();
        }
      },
      (err) => {
        this.loading = false;
        this._popupDbService.openSnackBarMessage(
          'Profile not updated, try again later',
          'close'
        );
      }
    );
  }

  backToProfilePage() {
    // if (this.isTab) {
    //   this.dialogRef.close();
    //   this.router.navigate(['account/user']);
    // }
    // else {
    if (this.scrWidth < 600) {
      this.router.navigate(['profile/user']);
    } else {
      this.dialogRef.close();
    }
    // }
  }
}
