import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import * as AWS from 'aws-sdk';
import SecureLS from 'secure-ls';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BlobServiceClient, BlockBlobClient, HttpHeaders } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  constructor(private http: HttpClient, private _snackBar : MatSnackBar) { }

  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });

  private typeOfOrderSummary = "SELF_ORDERS";

  setTypeOfOrderSummary(orderSummarytype) {
    this.typeOfOrderSummary = orderSummarytype;
  }
  getTypeOfOrderSummary() {
    return this.typeOfOrderSummary;
  }

  setReviewOrderId(body: any) {
    this._lsService.set('reviewOrderId', body);
  }
  getReviewOrderId() {
    const orderId = this._lsService.get('reviewOrderId');
    if (orderId) {
      return orderId;
    }
    return null;
  }

  getUserDetailsByUserId(userId: string) {
    return this.http.get(environment.baseUrl + `/user/user/${userId}`);
  }

  updateUserProfile(data) {
    return this.http.put(environment.baseUrl + '/user/user/', data);
  }

  getOrdersListByUserId(userId: string, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/ist/order/v1/${userId}?pageNo=${pageNo}&size=${size}`
    );
  }

  addNewAddressOfuser(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/user/ist/settings/delivery/address`,
      data
    );
  }

  getOrderDetailsById(userId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/getOrder/${userId}`
    );
  }

  getSavedAddressesOfUser(userId) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    );
  }

  getUserRewardPoints(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  getUserPreOrderHistory(userId: string, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/v1/preorders/${userId}?pageNo=${pageNo}&size=${size}`
    )
  }

  submitUserReview(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/ratingReview/ist/create`,
      data
    );
  }

  getUserPoints(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/ist/points/${userId}`
    )
  }

  getGuestOrderHistoryOfUser(userId: string, pageNo: number, size: number) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/guestOrder/user/requests?pageNo=${pageNo}&size=${size}&userId=${userId}`)
  }

  getGuestPendingOrderHistoryOfUser(userId: string, pageNo: number, size: number) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/guestOrder/user/orders?pageNo=${pageNo}&size=${size}&userId=${userId}`)
  }

  sendRemainderForGuestOrderRequest(employerId, requestId) {
    return this.http.post(environment.baseUrl + `/foodcourt/ist/guestOrder/request/reminder?employerId=${employerId}&requestId=${requestId}`, {})
  }
  sendRemainderForBTCRequest(employerId, requestId) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/ist/guestOrder/request/reminder?employerId=${employerId}&requestId=${requestId}`, {}
    )
  }

  getBTCRequestListing(userId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/guestOrder/${userId}/list/all?pageNo=0&pageSize=10&requestType=BTC_REQUEST`)
  }
  createWalletPaymentToken(data) {
    return this.http.post(environment.baseUrl + `/foodcourt/payment/ist/wallet/token/web`, data)
  }

  getUserRewardPointsSummary(cashFreeId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/recharge/ist/cashFree/details?cashFreeId=${cashFreeId}`)
  }

  logoutUser(userId, deviceInfo) {
    return this.http.put(
      environment.baseUrl + `/user/user/${userId}/logout`,
      deviceInfo
    );
  }

  getAllHelpDeskTickets(userId, pageNo, size, status) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/ist/v1/fc/${userId}/tickets?pageNo=${pageNo}&pageSize=${size}&status=${status}`
    )
  }
  getAllHelpDeskCategories() {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/category/ist/v1/fc/list?vertical=Food_Court`
    )
  }

  initiateRefundToWallet(orderId, pointsHistoryId, orderDetails) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/refund/bank?orderId=${orderId}&pointHistoryId=${pointsHistoryId}`, orderDetails
    )
  }

  getUserSettingsByUserId(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/settings/user/${userId}`
    )
  }

  toggleNotificationForUser(userId: string, status: boolean) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/notification/custom/ist/toggle/notification/permission/${userId}?status=${status}`, {}
    )
  }
  Order:any;
  setinfo_order(order:any){
    this.Order=order;
  }
  getinfo_order(){
    return this.Order;
  }

  // For uploading images to s3 bucket
  imageObject;

  
  async uploadFile(file: File, fileName: string) {
    const sasToken = environment.sasToken;
    const accountName = environment.accountName;
    const containerName = environment.containerName;

    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName + "/" + blobName);
  
    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });
    return blockBlobClient;
  }

  async uploadFoodcourtImages(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element.size < environment.imageUploadSize) {
        if (element) {
          const reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onload = () => {
            s3.upload(
              {
                Key:
                  'images/' +
                  Math.floor(100000 + Math.random() * 900000) +
                  'c' +
                  new Date().getTime() +
                  element.name,
                Bucket: bucketName,
                Body: element,
                ACL: 'public-read',
              },
              function (err: any, data: any) {
                if (err) {
                  rej(err);
                } else {
                  res(data);
                }
              }
            );
          };
        }
      } else {
        this._snackBar.open('File Size Greater than 1Mb!', 'Close');
        rej('File Size Greater than 1Mb!');
      }

    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        //console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        //console.log(err);
        return err;
      });
  }

  getAllUserSubscriptionList(userId: string, userSubscriptionType: string) {
    
    return this.http.get(
      environment.baseUrl + `/foodcourt/subscription/ist/user/plan?status=${userSubscriptionType}&userId=${userId}`
    )
  }

}
