import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalCartServiceService } from '../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { ConfirmationPopupComponent } from '../other-components/confirmation-popup/confirmation-popup.component';
import { CustomizeItemBottomsheetComponent } from '../other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';
import { SerachRestaurantsService } from './serach-restaurants.service';

@Component({
  selector: 'app-search-restaurants',
  templateUrl: './search-restaurants.component.html',
  styleUrls: ['./search-restaurants.component.scss'],
})
export class SearchRestaurantsComponent implements OnInit {
  constructor(
    private activeRouter: ActivatedRoute,
    private searchService: SerachRestaurantsService,
    private landingComponentService: LandingComponentService,
    private storageService: IndexDBService,
    private router: Router,
    private dialog: MatDialog,
    private globalCartService: GlobalCartServiceService,
    private bottomSheet: MatBottomSheet,
  ) {}

  recentSearchesList: any[] = [];
  searchText: string = null;
  foodCourtId: string = null;
  searchVendorList: any[] = [];
  searchFoodItemList: any[] = [];
  showSearchResult: boolean = false;
  userCartData;
  cartDetails = {
    noOfItems: 0,
    totalAmount: 0,
  };
  showLoader:boolean = false;

  isIntoPreOrderingFlow: boolean = false;

  ngOnInit(): void {
    // this.activeRouter.queryParams.subscribe((response:any)=> {
    //   this.foodCourtId = this.landingComponentService.getFoodCourtId();
    // })
    
    this.isIntoPreOrderingFlow = this.landingComponentService.getIsOrderPreOrderType();

    this.getScreenSize();
    if (this.scrWidth > 600) {
      this.landingComponentService.itemQuantityChangeFromCart.subscribe((response)=> {
        this.serachItemsResturants();
      })
    }

    this.recentSearchesList =
      this.landingComponentService.getAllRecentSearchesList();
    this.activeRouter.params.subscribe((response: any) => {
      this.searchFoodItemList = [];
      this.searchVendorList = [];
      this.showSearchResult = false;
      this.foodCourtId = this.landingComponentService.getFoodCourtId();

      this.globalCartService.getUserCartData().then((response: any) => {
        this.userCartData = response.userCartData;
        this.cartDetails = response.cartPreviewData;
      });
    });

  }

  scrHeight: number;
  scrWidth: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  itemIdForLoading: string = '';
  showRunTimeLoader: boolean = false;

  addItemAfterMultipleFoodcourtCheck(item) {
    // add only when item belong to same food court condition check

    const isPreOrderingType = this.landingComponentService.getIsOrderPreOrderType();
    if (isPreOrderingType) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.isIntoPreOrderingFlow = null;
          this.serachItemsResturants();
          // this.addItemAfterMultipleFoodcourtCheck(item);
        }
      });
    } else {

        let currentOrderFoodCourtId =
        this.landingComponentService.getOrderFoodCourtId();
      if (
        currentOrderFoodCourtId &&
        currentOrderFoodCourtId !== item.foodCourtId
      ) {
        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '320px',
          height: 'auto',
          data: { type: '' },
        });
        dialogRef.afterClosed().subscribe((data) => {
          if (data?.isSuccess === true) {
            this.globalCartService.getAllCartData().then(()=> {
              this.addItemAfterCustomizationCheck(item, "ADD");
            })
          } else if (data?.isSuccess === false) {
            this.landingComponentService.openSnackBarMessage('Error Occured');
          }
        });
      } else {
        this.addItemAfterCustomizationCheck(item, "ADD");
      }
    }
  }

  addItemAfterCustomizationCheck(item, actionType) {
    console.log(item);

    // Item will be added after checking for customization required
    if (item.customizationRequired) {
      if(!item.menuCustomizationList || item.menuCustomizationList.length === 0) {
        this.searchService.getAllCustomizationsOfItem(item.id).subscribe((response:any) => {
          if(response.data && response.data.length > 0) {
            item.menuCustomizationList = response.data.map((element) => {
              let obj = Object.assign({}, element);
              obj.customizedItemsForMenuList.forEach((customItems) => {
                customItems.status = false;
              })

              return obj;
            });
          }

          this.openCustomizationBottomSheet(item, actionType);
        })
      } else {
        item.menuCustomizationList.forEach((element) => {
          element.customizedItemsForMenuList.forEach((customItems) => {
            customItems.status = false;
          })
        })

        if(actionType === 'ADD') {
          item.orderedItemCustomizations = null;
        }

        this.openCustomizationBottomSheet(item, actionType);
      }
    } else {
      if(actionType === 'ADD') {
          this.addItem(item)
        } else if(actionType === 'INCREASE') {
          this.increaseItemCount(item);
        }
    }
  }

  openCustomizationBottomSheet(item, actionType) {
    const dialogRef = this.bottomSheet.open(CustomizeItemBottomsheetComponent, {
      data: {data: item, edit: false },
    });
    dialogRef.afterDismissed().subscribe((response)=> {
      if(!response) return;
      item.orderedItemCustomizations = response.selectedCustomization;
      if(actionType === 'ADD') {
        this.addItem(item)
      } else if(actionType === 'INCREASE') {
        this.increaseItemCount(item);
      }
    });
  }

  addItem(item) {
    this.itemIdForLoading = item.id;

      this.showRunTimeLoader = true;

      item.showCustomQuantity++;
      // item.quantity = item.showCustomQuantity;
      item.itemQuantity = item.showCustomQuantity;
      let foodCourtId = this.landingComponentService.getFoodCourtId();
      let foodCourtName = this.landingComponentService.getFoodCourtName();
      this.landingComponentService.setOrderFoodCourtId(foodCourtId);
      this.landingComponentService.setOrderFoodCourtName(foodCourtName);

      this.landingComponentService.addItemToRecentSearches(item.name);
      this.globalCartService
      .addItemToIndexedDB(item, false)
      .then((response: any) => {
        this.getUserCartDataIndexDB();

        // this.landingComponentService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.quantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
      // this.globalCartService
      //   .addItemToCart(item)
      //   .then((response: any) => {
      //     this.showRunTimeLoader = false;
      //     this.landingComponentService.itemQuantityChange.next(true);
      //   })
      //   .catch((err) => {
      //     item.quantity -= 1;
      //     this.showRunTimeLoader = false;
      //   });

  }

  increaseItemCount(item) {
    this.itemIdForLoading = item.id;
    this.showRunTimeLoader = true;

    item.showCustomQuantity++;
    // item.quantity = item.showCustomQuantity;
    // item.itemQuantity = item.showCustomQuantity;

    this.globalCartService.checkForCustomizedItemInIndexDb(item)
    .then((result: any) => {
      this.globalCartService
      .addItemToIndexedDB(item, result.isSameItemPresent)
      .then((response: any) => {
        this.getUserCartDataIndexDB();

        // this.landingComponentService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.showCustomQuantity -= 1;
        item.itemQuantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
    })
    // this.globalCartService
    //   .increaseItemCount(item)
    //   .then((response: any) => {
    //     this.showRunTimeLoader = false;
    //     this.landingComponentService.itemQuantityChange.next(true);
    //     this.cartDetails = response;
    //   })
    //   .catch((err) => {
    //     item.quantity -= 1;
    //     this.showRunTimeLoader = false;
    //   });
  }
  decreaseItemCount(item) {
    this.globalCartService.checkForCustomizedItemInIndexDb(item)
        .then((result: any) => {
          if(item.customizationRequired && result.customizedItemCount > 1) {
            this.dialog.open(ConfirmationPopupComponent, {
              width: '90vw',
              height: 'auto',
              maxWidth: '100vw',
              maxHeight: '50vh',
              data: { type: 'REMOVE_CUSTOMIZATION' },
            });
          } else {
            this.itemIdForLoading = item.id;
            item.showCustomQuantity--;
            // item.quantity = item.showCustomQuantity;
            item.itemQuantity = item.showCustomQuantity;
            this.showRunTimeLoader = true;
            // if (item.quantity > 0) {
              if(item.itemQuantity > 0) {
                this.globalCartService
                  .addItemToIndexedDB(item, true)
                  .then((response: any) => {
                    this.getUserCartDataIndexDB();

                    // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                  })
                  .catch((err) => {
                    this.showRunTimeLoader = false;
                    item.quantity += 1;
                    // this.landingConsoleService.openSnackBarMessage(err);
                });
              } else {
                this.globalCartService.removeItemFromCart(item)
                    .then(response => {
                      let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();

                      if(orderedFoodCourtId) {
                        this.getUserCartDataIndexDB();
                      } else {
                        this.showRunTimeLoader = false;
                      }
                      // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                    })
                    .catch(error => {
                      this.showRunTimeLoader = false;
                      item.quantity += 1;
                    })
              }
            // this.globalCartService
            //   .decreaseItemCount(item)
            //   .then((response: any) => {
            //     this.showRunTimeLoader = false;
            //     this.landingComponentService.itemQuantityChange.next(true);
            //     this.cartDetails = response;
            //   })
            //   .catch((err) => {
            //     item.quantity += 1;
            //     this.showRunTimeLoader = false;
            //   });
            // }
            // else {
            //     this.globalCartService
            //       .removeItemFromCart(item)
            //       .then((response) => {
            //         this.showRunTimeLoader = false;
            //       })
            //       .catch((err) => {
            //         this.showRunTimeLoader = false;
            //       });
            //   }
          }
        })
  }

  getUserCartDataIndexDB() {
    // this.showLoader = true;
    this.storageService.getAllItemFromCart().then((response) => {

      return new Promise((resolve, reject) => {
        resolve(response);
      })
    })
    .then((response: any) => {
      let userName = this.landingComponentService.getUserName();
      let userMobile = this.landingComponentService.getUserMobile();
      let userId = this.landingComponentService.getUserId();
      let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
      let btcRequestId = this.landingComponentService.getBTCRequestId();

      let deliveryAddress = this.landingComponentService.getUserAddressForBTC();
      let orderType = this.landingComponentService.getUserOrderTypeForBTC();

      if(orderedFoodCourtId) {
        if(userId) {
        let data = {
      
          address: deliveryAddress,
          orderType: orderType,
          foodCourtId: orderedFoodCourtId,
          userId: userId,
          userName: userName,
          mobile: userMobile,
          platform: "WEB",
          btcRequestId: btcRequestId,
          itemsList: {},
        };
          data.itemsList = response;
          console.log("cart item list", response);

          this.landingComponentService.addItemToCartAPI(data).subscribe(
            (res) => {
              this.searchFoodItemList.forEach((item) => {
                item.showCustomQuantity = 0;
                item.itemId = item.id;
                response.forEach((cartItem) => {
                  if (item.id === cartItem.itemId)
                    item.showCustomQuantity += cartItem.itemQuantity;
                    item.appItemId = cartItem.appItemId;
                    item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                });
              })

              this.landingComponentService.itemQuantityChange.next(true);
              this.showRunTimeLoader = false;
            },
            (err) => {
              // this.showRunTimeLoader = false;
              // this.showLoader = false;
              //console.log(err);
              this.handleErrorForAddingItems();
              this.landingComponentService.openSnackBarMessage(
                'Failed to add item to Cart'
              );
            }
          );
      } else {
        this.searchFoodItemList.forEach((item) => {
          item.showCustomQuantity = 0;
          item.itemId = item.id;
          response.forEach((cartItem) => {
            if (item.id === cartItem.itemId)
              item.showCustomQuantity += cartItem.itemQuantity;
              item.appItemId = cartItem.appItemId;
              item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
          });
        })
        this.landingComponentService.itemQuantityChange.next(true);
        this.showRunTimeLoader = false;
      }
      } else {
        this.landingComponentService.itemQuantityChange.next(true);
        this.showRunTimeLoader = false;
      }
    })
  }

  handleErrorForAddingItems() {
    let userId = this.landingComponentService.getUserId();

    // Clearing local db and getting user cart data from backend and adding back to local db and maintaining the count of items
    this.storageService.clearAllItemsFromCart()
        .then((result) => {
          this.landingComponentService.getCartData(userId).subscribe(async (response : any) => {
            let orderFoodCourtId = response.data.foodCourtId;
            this.landingComponentService.setOrderFoodCourtId(orderFoodCourtId);

            console.log("user cart from api", response);
            for(let item of response.data.itemsList) {
              await this.globalCartService.addItemToIndexedDB(item, false);
              // console.log("items adding in process");
            }

            this.searchFoodItemList.forEach((item) => {
              item.showCustomQuantity = 0;
              item.itemId = item.id;
              response.forEach((cartItem) => {
                if (item.id === cartItem.itemId)
                  item.showCustomQuantity += cartItem.itemQuantity;
                  item.appItemId = cartItem.appItemId;
                  item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              });
            })

            this.showRunTimeLoader = false;
            // console.log("cart added to items complete");
          }, error => {
            this.landingComponentService.openSnackBarMessage("Failed to add item to cart, please refresh once")
          })
        })
        .catch((error) => {
          this.showRunTimeLoader = false;
        })
  }

  searchFromRecentSearches(itemName) {
    this.searchText = itemName;
    this.serachItemsResturants();
  }
  _serachItemsResturants() {
    if (this.searchText.length > 2) this.serachItemsResturants();
    else if (this.searchText.length === 0) {
      this.searchFoodItemList = [];
      this.showSearchResult = false;
    }
  }
  serachItemsResturants() {
    this.showLoader = true;

    this.searchService
      .searchItemsRestaurants(this.foodCourtId, this.searchText)
      .subscribe(
        (response: any) => {
          this.searchFoodItemList = response.data?.items;
          this.searchVendorList = response.data?.vendors;
          // console.log("Vendor List : ", this.searchVendorList);

          if (this.searchFoodItemList.length > 0) {
            this.globalCartService.getUserCartData().then((data: any) => {
              this.userCartData = data.userCartData;
              this.cartDetails = data.cartPreviewData;

              this.searchFoodItemList.forEach((item) => {
                item.showCustomQuantity = 0;
                item.itemId = item.id;
                this.userCartData?.forEach((cartItem) => {
                  if (item.id === cartItem.itemId)
                    item.showCustomQuantity += cartItem.itemQuantity;
                    item.appItemId = cartItem.appItemId;
                    item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                });

                let url = item.itemImageUrl;
                let pattern = /^((http|https|ftp):\/\/)/;
                if (pattern.test(url)) {
                  item.itemImageUrl = url;
                } else {
                  item.itemImageUrl = '';
                }
              });
            });
          }

          this.searchFoodItemList = this.seperateOUTOFSTOCKitems(this.searchFoodItemList);

          if (this.searchFoodItemList.length > 0 || this.searchVendorList.length > 0) {
            this.showSearchResult = true;
          } else {
            this.showSearchResult = false;
          }
          setTimeout(() => {
            this.showLoader = false;
          }, 500);
        },
        (err) => {
          this.landingComponentService.openSnackBarMessageIMP("Failed to Search!! Plz try again Later.");
          this.showLoader = false;
        }
      );
  }

  seperateOUTOFSTOCKitems(data) {

    let Out_Of_Stock_item = [];

    data = data.filter((element)=> {
      if (element.itemStatus === "OUT_OF_STOCK" || element.itemStatus === "VENDOR_NOT_AVAILABLE")
        Out_Of_Stock_item.push(element);
      else
        return true;
    });

    return [...data, ...Out_Of_Stock_item];
  }


  removeItemFromRecentSearch(index) {
    this.landingComponentService.removeRecentSearchItem(index);
  }

  openItemDetailBox(item) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '320px',
      height: 'auto',
      data: { type: 'ITEM_DETAILS', itemDetails: item },
    });
    dialogRef.afterClosed().subscribe((data) => {});
  }

  viewResturantDetails(vendorDetails) {
    this.landingComponentService.setVendorId(vendorDetails.vendorId);
    let vendorUrl = vendorDetails?.brandName
      ? vendorDetails?.brandName?.split(' ')?.join('-').toLowerCase()
      : ' ';
    let siteUrl = this.landingComponentService.getSiteName()?.split(' ')?.join('-').toLowerCase();
    let foodCourtUrl = this.landingComponentService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();
    // this.router.navigate(["restaurant"], {queryParams: {vendor: }});
    this.router.navigate([`restaurant/${foodCourtUrl}/${vendorUrl}`]);
  }
}
