<section class="loading-section">
  <div class="loading-container">
    <div class="loading-text">{{ loadingText }}</div>
    <div class="loading-img">
      <img
        src="../../../../../../../assets/images/common/burger_loader.gif"
        alt=""
      />
    </div>
  </div>
</section>
