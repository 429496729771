import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuestOrderingService {
  constructor(private http: HttpClient) {}

  getHomePageData(foodCourtId, userId = null) {
    if (userId)
      return this.http.get(
        environment.baseUrl +
          `/foodcourt/ist/ist/foodMenu/home?foodCourtId=${foodCourtId}&userId=${userId}`
      );
    else
      return this.http.post(
        environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}`,
        {}
      );
  }

  createGuestOrderRequest(data) {
    return this.http.post(
      environment.baseUrl + '/foodcourt/ist/guestOrder/request',
      data
    );
  }
}
