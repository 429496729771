<section *ngIf="!showErrorOnAPI; else showErrorOnAPIContent">
  <div [ngClass]="
      cartDetails.noOfItems > 0 ? 'home-section-updated' : 'home-section'
    " *ngIf="!showEmptyScreen; else emptyscreen">
    <ng-container *ngIf="!showLoader; else loaderScreen">

      <!-- <div class="preorder-container" (click)="goToDownloadAppPage()">
        <img src="../../../../../../assets/images/home-page/home-page-banner.svg" alt="">
      </div> -->
      <ng-container *ngIf="foodCourtDetails?.foodProvidingType !== 'SUBSCRIPTION_ONLY'">

        <div class="food-subscription-banner" (click)="goToFoodSubscriptionListingPage()"
          *ngIf="foodCourtDetails?.fcSubscriptionPlans?.length > 0">
          <ng-container *ngIf="scrWidth >= 1000">
            <img src="../../../../../../assets/images/food-subscription/food-subcription-banner (1).png" alt="">
          </ng-container>
          <ng-container *ngIf="scrWidth < 1000">
            <img src="../../../../../../assets/images/food-subscription/food-susbscription-banner.png" alt="">
          </ng-container>

        </div>

        <div class="preorder-container" (click)="goToPreOrderingPage()" *ngIf="foodCourtHasPreOrdering">
          <img src="../../../../../../assets/images/home-page/preorder-image.png" alt="" *ngIf="scrWidth < 600">
          <img src="../../../../../../assets/images/home-page/pre-order-banner.png" alt="" *ngIf="scrWidth >= 600">
        </div>

        <div class="discount-carousel-container" *ngIf="foodCourtDetails?.discountHomeResponses.length > 0">
          <div class="carousel">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" (afterChange)="afterChange($event)"
              [config]="slideConfig">
              <div ngxSlickItem class="slide"
                *ngFor="let discount of foodCourtDetails?.discountHomeResponses; let i = index">
                <div class="discount-container" [style.background]="'url('+discount?.discountImageUrl+')'"
                  (click)="goToVendorPage(discount)">
                  <div class="discount-price">{{ discount.discountdesc }}</div>
                  <div class="description">Use code <span>{{ discount.discountName | uppercase }}</span> and get
                    &#8377;40 off on your order above &#8377;100.</div>
                  <div class="vendor-name">{{ discount.vendorName | titlecase }}</div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="dots-container">
            <ng-container *ngFor="let discount of foodCourtDetails?.discountHomeResponses; let i = index">
              <span class="dot" *ngIf="i !== carouselIndex; else showActiveDot"></span>
              <ng-template #showActiveDot>
                <span class="dot dot-active"></span>
              </ng-template>
            </ng-container>
          </div>
        </div>

        <div class="one-click-order-section" *ngIf="foodCourtDetails?.menuDataForEmployees?.length > 0">
          <div class="guest-order-card" (click)="goToGuestOrderPage()">
            <img src="../../../../../../assets/images/common/guest-orders.svg" alt="">
          </div>

          <div class="one-click-section-heading">One-Click Order</div>

          <div class="one-click__card-section">
            <ng-container *ngFor="let order of foodCourtDetails?.menuDataForEmployees">
              <div class="one-click__card" [ngClass]="(order?.mealCategory[0] | lowercase) + '-theme'">
                <div class="meal-img">
                  <img src="../../../../../../assets/images/home-page/{{
                      order.mealCategory[0] | lowercase
                    }}.png" alt="" />
                </div>
                <div class="item-type-container">
                  <div class="image-container">
                    <img src="
                      ../../../../../../assets/images/home-page/{{
                        order.itemType.split('_').join('-') | lowercase
                      }}-item.svg
                    " alt="" />
                  </div>
                  <div class="item-type">
                    {{ order.itemType.split("_").join("-") | titlecase }}
                  </div>
                </div>
                <div class="card-content">
                  <div class="card__heading-one">
                    {{ order?.mealCategory[0] | uppercase }}
                  </div>
                  <div class="card__item-list">
                    <ul>
                      <li>{{ order.name | titlecase }}</li>
                    </ul>
                  </div>
                  <div class="card__price-section" *ngIf="order?.employerMenu?.length > 0">
                    <ng-container *ngIf="order?.employerMenu[0].employeeType; else contractualEmployeePrice">
                      <div class="price-section" [ngStyle]="{
                          visibility:
                            ((order?.price - order?.employerMenu[0].paidByEmployer) !== 0)||(order?.orderCount >= order?.employerMenu[0].threshold)
                              ? 'visible'
                              : 'hidden'
                        }">
                        <span class="discount-price" *ngIf="order?.employerMenu">&#8377;{{
                          (order.orderCount >= order?.employerMenu[0].threshold
                          ? order?.price
                          : order?.price -
                          order?.employerMenu[0].paidByEmployer)
                          | number: "1.1-2"
                          }}</span>
                        <span class="actual-price" *ngIf="order?.price">&#8377;{{ order?.price | number: "1.1-2"
                          }}</span>
                      </div>
                    </ng-container>
                    <ng-template #contractualEmployeePrice>
                      <span class="discount-price" *ngIf="order?.price">&#8377;{{ order?.price | number: "1.1-2"
                        }}</span>
                    </ng-template>
                    <div class="pay-now-btn" *ngIf="
                        order.itemStatus === 'IN_STOCK' ||
                          order.itemStatus === 'VENDOR_AVAILABLE';
                        else QBOutOfStock
                      ">
                      <button *ngIf="
                          ((order?.price - order?.employerMenu[0].paidByEmployer) === 0)&&(order?.orderCount < order?.employerMenu[0].threshold)&&(order?.employerMenu[0].employeeType); else showPayNowBtn
                        " (click)="showQR(order)">
                        Show QR
                      </button>

                      <ng-template #showPayNowBtn>
                        <button (click)="showOneClickOrderPayment(order)">
                          Pay Now
                        </button>
                      </ng-template>
                    </div>
                    <ng-template #QBOutOfStock>
                      <div class="out-of-stock">Out of Stock</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="discount-type-container">
          <div class="type-container">
            <ng-container *ngFor="let discount of discountTypeList">
              <div class="discount-container" (click)="redirectToVendorDiscountPage(discount)" *ngIf="discount.img">
                <img src="../../../../../../assets/images/home-page/{{ discount.img }}.svg" alt="">
              </div>
            </ng-container>
          </div>

        </div>

        <div class="trending-today-section" *ngIf="foodCourtDetails?.trendingtodayList.length > 0">
          <div class="heading-text">Trending Today</div>
          <div class="trending-card-section">
            <ng-container *ngFor="let trendingItem of foodCourtDetails?.trendingtodayList">
              <div class="trending-card">
                <div class="item-img">
                  <img *ngIf="!trendingItem.itemImageUrl"
                    src="../../../../../../assets/images/common/default_food_image.svg" alt="" />
                  <img *ngIf="trendingItem.itemImageUrl" [src]="trendingItem.itemImageUrl" alt="" />
                </div>
                <div class="card-middle-section">
                  <div class="item-name-type">
                    <div class="item-type">
                      <img *ngIf="trendingItem.itemType" src="../../../../../../assets/images/home-page/veg-item.svg"
                        alt="" />
                      <img *ngIf="!trendingItem.itemType"
                        src="../../../../../../assets/images/home-page/non-veg-item.svg" alt="" />
                    </div>

                    <div class="item-name">
                      <span *ngIf="
                          trendingItem.name.length < 25;
                          else showTrimmedNameTT
                        ">{{ trendingItem.name | titlecase }}</span>
                      <ng-template #showTrimmedNameTT>
                        <span>{{
                          trendingItem.name.substring(0, 24) | titlecase
                          }}..</span>
                        <!-- <mat-icon (click)="openItemDetailBox(trendingItem)"
                          >add_box</mat-icon
                        > -->
                      </ng-template>
                    </div>
                  </div>
                  <div class="vendor-name">
                    <span *ngIf="trendingItem?.vendorName.length < 25">{{ trendingItem?.vendorName | titlecase }}</span>
                    <span *ngIf="trendingItem?.vendorName.length >= 25">{{ trendingItem?.vendorName.substring(0, 25) |
                      titlecase }}...</span>
                    <span class="customization-text" *ngIf="trendingItem.customizationRequired">- Customisable</span>
                  </div>
                </div>
                <div class="card-bottom-section">
                  <div class="item-price">
                    <span *ngIf="trendingItem?.taxCategory === 'MRP'" class="mrp-text">MRP : </span> &#8377;{{
                    (trendingItem.markUpPrice) | number: "1.0-2" }}
                  </div>
                  <!-- <ng-template #showAddForTT> -->
                  <div class="add-btn" [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow}" *ngIf="
                      trendingItem.itemStatus === 'IN_STOCK' ||
                        trendingItem.itemStatus === 'VENDOR_AVAILABLE';
                      else outOfStock
                    ">
                    <ng-container *ngIf="
                        !showRunTimeLoader ||
                          itemIdForLoading !== trendingItem.appItemId;
                        else runTimeLoading;
                        else: runTimeLoading
                      ">
                      <button class="add-item"
                        *ngIf="trendingItem.showCustomQuantity === 0; else showItemIncDecTrending"
                        (click)="addItemAfterMultipleFoodcourtCheck(trendingItem)">
                        Add
                      </button>
                      <ng-template #showItemIncDecTrending>
                        <ng-container *ngIf="!isIntoPreOrderingFlow; else showFreezedCountTrending">
                          <div class="add-to-cart-btn">
                            <button class="minus" (click)="decreaseItemCount(trendingItem)">
                              -
                            </button>
                            <span class="item-count">{{ trendingItem.showCustomQuantity }}</span>
                            <button class="plus" (click)="addItemAfterCustomizationCheck(trendingItem, 'INCREASE')">
                              +
                            </button>
                          </div>
                        </ng-container>
                        <ng-template #showFreezedCountTrending>
                          <div class="add-to-cart-btn-freezed"
                            (click)="addItemAfterMultipleFoodcourtCheck(trendingItem)">
                            <button class="minus">-</button>
                            <span class="item-count">{{ trendingItem?.showCustomQuantity }}</span>
                            <button class="plus">+</button>
                          </div>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </div>
                  <ng-template #outOfStock>
                    <div class="out-of-stock">Out of Stock</div>
                  </ng-template>
                  <!-- </ng-template> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="trending-today-section" *ngIf="foodCourtDetails?.quickBitesList.length > 0">
          <div class="heading-text">Quick Bites</div>
          <div class="trending-card-section">
            <ng-container *ngFor="
                let quickBite of foodCourtDetails?.quickBitesList;
                index as quickBiteIndex
              ">
              <div class="trending-card">
                <div class="trending-icon-container" *ngIf="quickBite.trending">
                  <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                </div>
                <div class="item-img">
                  <img *ngIf="!quickBite.itemImageUrl"
                    src="../../../../../../assets/images/common/default_food_image.svg" alt="" />
                  <img loading="lazy" *ngIf="quickBite.itemImageUrl" [src]="quickBite.itemImageUrl" alt="" />
                </div>
                <div class="card-middle-section">
                  <div class="item-name-type">
                    <div class="item-type">
                      <img *ngIf="quickBite.itemType" src="../../../../../../assets/images/home-page/veg-item.svg"
                        alt="" />
                      <img *ngIf="!quickBite.itemType" src="../../../../../../assets/images/home-page/non-veg-item.svg"
                        alt="" />
                    </div>
                    <div class="item-name">
                      <span *ngIf="quickBite.name.length < 25; else showTrimmedNameQB">{{ quickBite.name | titlecase
                        }}</span>
                      <ng-template #showTrimmedNameQB>
                        <span>{{ quickBite.name.substring(0, 24) | titlecase }}..</span>
                        <!-- <mat-icon (click)="openItemDetailBox(trendingItem)"
                          >add_box</mat-icon
                        > -->
                      </ng-template>
                    </div>
                  </div>
                  <div class="vendor-name">
                    <span *ngIf="quickBite?.vendorName.length < 25">{{ quickBite?.vendorName | titlecase }}</span>
                    <span *ngIf="quickBite?.vendorName.length >= 25">{{ quickBite?.vendorName.substring(0, 25) |
                      titlecase }}...</span>
                    <span class="customization-text" *ngIf="quickBite.customizationRequired">- Customisable</span>
                  </div>
                </div>
                <div class="card-bottom-section">
                  <div class="item-price">
                    <span *ngIf="quickBite?.taxCategory === 'MRP'" class="mrp-text">MRP : </span> &#8377;{{
                    (quickBite.markUpPrice) | number: "1.0-2" }}
                  </div>

                  <!-- <ng-template #showAddForQB> -->
                  <div class="add-btn" [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow}" *ngIf="
                      quickBite.itemStatus === 'IN_STOCK' ||
                        quickBite.itemStatus === 'VENDOR_AVAILABLE';
                      else QBOutOfStock
                    ">
                    <ng-container *ngIf="
                        !showRunTimeLoader || itemIdForLoading !== quickBite.appItemId;
                        else runTimeLoading
                      ">
                      <button class="add-item" *ngIf="quickBite.showCustomQuantity === 0; else showItemIncDecQB"
                        (click)="addItemAfterMultipleFoodcourtCheck(quickBite)">
                        Add
                      </button>
                      <ng-template #showItemIncDecQB>
                        <ng-container *ngIf="!isIntoPreOrderingFlow; else showFreezedCountQB">
                          <div class="add-to-cart-btn">
                            <button class="minus" (click)="decreaseItemCount(quickBite)">
                              -
                            </button>
                            <span class="item-count">{{ quickBite.showCustomQuantity }}</span>
                            <button class="plus" (click)="addItemAfterCustomizationCheck(quickBite, 'INCREASE')">
                              +
                            </button>
                          </div>
                        </ng-container>
                        <ng-template #showFreezedCountQB>
                          <div class="add-to-cart-btn-freezed">
                            <button class="minus">-</button>
                            <span class="item-count">{{ quickBite.showCustomQuantity }}</span>
                            <button class="plus">+</button>
                          </div>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </div>
                  <ng-template #QBOutOfStock>
                    <div class="out-of-stock">Out of Stock</div>
                  </ng-template>
                  <!-- </ng-template> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="choose-meal-section" *ngIf="foodCourtDetails?.itemCategory.length > 0">
          <div class="heading-text">Choose Meal</div>
          <div class="meal-card-section">
            <ng-container *ngFor="let meal of MEALS">
              <div *ngIf="meal" class="meal-card" (click)="viewChooseMealDetails(meal.value)">
                <div class="meal-type">{{ meal.name | titlecase }}</div>
                <div class="meal-duration">{{ meal.servingType }}</div>
                <div class="meal-img">
                  <img loading="lazy" src="../../../../../../assets/images/home-page/{{
                      meal.name | lowercase
                    }}.png" alt="" />
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="vendor-section" *ngIf="foodCourtDetails?.vendordata.length > 0">
          <div class="heading-text">All Restaurants</div>
          <div class="sub-heading-text">Discover unique taste</div>
          <div class="vendor-card-section">
            <ng-container *ngFor="let vendorData of foodCourtDetails?.vendordata">
              <div class="vendor-card" (click)="viewResturantDetails(vendorData)">
                <div class="vendor-img">
                  <img *ngIf="
                      !vendorData?.imgUrl || vendorData.imgUrl === 'string';
                      else showVendorImage
                    " src="../../../../../../assets/images/common/default_restuarant.svg" alt=""
                    class="vendor-default-image" />
                  <ng-template #showVendorImage>
                    <img *ngIf="vendorData?.imgUrl" [src]="vendorData?.imgUrl" alt="" />
                  </ng-template>
                </div>
                <div class="vendor-name">
                  {{ vendorData.brandName | titlecase }}
                </div>
                <!-- <div class="vendor-type">Snacks, Pastries, Bakery</div> -->
                <div class="bottom-section">
                  <div class="rating">
                    <img src="../../../../../../assets/images/home-page/star.svg" alt="" />
                    <span>{{ vendorData?.rating | number: '1.0-1' }}</span>
                  </div>
                  <!-- <div class="duration" *ngIf="vendorData?.startTime && vendorData?.endTime">
                    <img
                      src="../../../../../../assets/images/common/clock.svg"
                      alt=""
                    />
                    <span
                      >{{ vendorData?.startTime }}-{{
                        vendorData?.endTime
                      }}
                      mins</span
                    >
                  </div> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="foodCourtDetails?.foodProvidingType === 'SUBSCRIPTION_ONLY'">
      <app-subcription-plan-listing
        [subscriptionPlanListing]="foodCourtDetails.fcSubscriptionPlans"></app-subcription-plan-listing>
    </ng-container>

    <ng-template #loaderScreen>
      <div class="loader-img-container">
        <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
      </div>
    </ng-template>

    <ng-template #runTimeLoading>
      <button class="add-item">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </ng-template>
  </div>
  <ng-template #emptyscreen>
    <div class="empty-part" *ngIf="!showLoader; else loaderScreen">
      <div class="no-image">
        <img src="../../../../../../assets/images/cart/empty-cart.svg" alt="empty-image" />
      </div>
      <div class="empty-heading">No item found!!</div>
      <!-- <div class="empty-heading">But good food is always cooking</div> -->
      <!-- <div class="empty-subtext">We are updating the menu</div> -->
      <div class="emptyrules-text">Please select another food court.</div>
    </div>

    <ng-template #loaderScreen>
      <div class="loader-img-container">
        <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
      </div>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="cartDetails.noOfItems > 0 && scrWidth < 600">
    <app-cart-footer [cartDetails]="cartDetails"></app-cart-footer>
  </ng-container>
  <!-- <ng-container *ngIf="status">
    <mat-sidenav mode="side" [(ngModel)]="drawer">
      <app-food-preorder></app-food-preorder>
    </mat-sidenav>
  </ng-container> -->

</section>
<ng-template #showErrorOnAPIContent>
  <app-apierror-handling></app-apierror-handling>
</ng-template>