import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  details:any;
  name:any;
  email:any;
  phonenumber:any;
  constructor( private router: Router,private landingComponentService: LandingComponentService, private popupService:PopupDbService) { }

  ngOnInit(): void {
    this.gettingfcdetails();
    // console.log(this.selected_name=this.fcdetails[0].foodCourtId);
    
  }
  goToProfilePage(){
    this.router.navigate(['profile/user'])
  }

  
  sitelist:any;
  fcdetails:any[] = [];
  selected_name:any;
  gettingfcdetails(){
    this.sitelist=this.popupService.getsiteList();
    this.sitelist.forEach((element:any)=>{
      element.foodCourtList.forEach((item:any)=>{
        this.fcdetails.push({...item,siteid:element.siteId})
      })
      // this.fcdetails.push(...element.foodCourtList);
      
    })

    this.selected_name=this.fcdetails[0].foodCourtId;
    this.gettinginfo(0)
    
  }

  gettinginfo(i:any){
    this.landingComponentService.getSPOClist(this.fcdetails[i].foodCourtId,this.fcdetails[i].siteid).subscribe(
      (response:any)=>{
        this.details=response.data
        
      }
      
    )
    // console.log(this.fcdetails[i])
  }
 
}
