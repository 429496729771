import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDbService } from '../../../authentication/services/popup-db.service';
import { GlobalCartServiceService } from '../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { CartService } from './cart.service';
import { environment } from '../../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ListUserAddressComponent } from '../user-account/list-user-address/list-user-address.component';
import { LandingComponentService } from '../../landing-component.service';
import { ConfirmationPopupComponent } from '../other-components/confirmation-popup/confirmation-popup.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomizeItemBottomsheetComponent } from '../other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';
import { PreorderConfirmationPopupComponent } from '../food-preorder/preorder-confirmation-popup/preorder-confirmation-popup.component';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { RatingComponent } from '../other-components/rating/rating.component';
import { UserAccountService } from '../user-account/user-account.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss', './mq-cart.component.scss'],
})
export class CartComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private cartService: CartService,
    private storageService: IndexDBService,
    private globalCartService: GlobalCartServiceService,
    private dbStorageService: PopupDbService,
    private landingComponentService: LandingComponentService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private _userAccountService: UserAccountService,
  ) { }

  cashFree = environment.cashFree;
  showLoader: boolean = false;
  userName: string = null;
  userMobileNumber: string = null;
  foodCourtId: string = null;
  userId: string = null;
  fcHasDelivery: boolean = false;
  cartItemList: any = [];
  billDetails: any = {};
  userPoints: any = {
    istharaPoints: 0,
  };
  doPayWithIstharaPoint: boolean = true;
  orderNote: string = '';
  errorOnAPI: boolean = false;

  menuTimingSlot = [];
  selectedPreOrderTimeSlot;

  isBTCEnabled: boolean = false;
  updateBTCRequestLoader: boolean = false;
  sendBTCRequestLoader: boolean = false;
  payBTCRequestLoader: boolean = false;

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  preOrderDetails = null;
  preOrderMealCategory = null;
  togglePreOrderTimeSlotSelection: boolean = false;
  isBTCRequestUpdationFlow: boolean = false;

  preOrderTimeSlotSecond = [];

  ngOnInit(): void {

    this.getScreenSize();
    this.userId = this.cartService.getUserId();
    this.foodCourtId = this.cartService.getFoodCourtId();

    this.isBTCEnabled = this.landingComponentService.getOrderingFoodcourtHasBTC();
    this.isBTCRequestUpdationFlow = (this.landingComponentService.getBTCRequestId() ? true : false);

    this.getUserPreOrderingDetails();

    if (this.userId) {
      this.userName = this.cartService.getUserName();
      this.userMobileNumber = this.cartService.getUserMobile();

      this.getUserCartData();
    } else {
      this.getUserCartDataIndexDB();
    }

    if (this.scrWidth > 600) {

      this.landingComponentService.itemQuantityChange.subscribe((response) => {

        this.isBTCRequestUpdationFlow = (this.landingComponentService.getBTCRequestId() ? true : false);
        this.getUserPreOrderingDetails();
        if (this.userId) {
          this.userName = this.cartService.getUserName();
          this.userMobileNumber = this.cartService.getUserMobile();

          this.getUserCartData();
        } else {
          this.getUserCartDataIndexDB();
        }
      })
    } else {
      // for small screen first check whether rating is required or not
      this.showRatingFlow();
    }
  }


  getUserPreOrderingDetails() {

    let orderDate = this.landingComponentService.getPreOrderDetails();

    if (orderDate) {

      let preOrderEndTime = new Date(orderDate);
      let preOrderStartTime = new Date();
      preOrderStartTime.setTime(preOrderEndTime.getTime() - 1 * 60 * 60 * 1000);
      this.preOrderMealCategory = this.landingComponentService.getPreOrderMenuType();

      this.preOrderDetails = {
        orderDate: orderDate,
        orderMealType: null,
        startTime: preOrderStartTime,
        endTime: preOrderEndTime
      }

      // this.getPreOrderingTimeSlotByMenu();
      // this.getPreOrderingTimeSlot();
      this.getPreOrderingTimeSlotByMenu();

    } else {
      this.preOrderDetails = null;
    }
  }

  updateBTCRequest() {

    this.updateBTCRequestLoader = true;

    let userMobileNumber = this.landingComponentService.getUserMobile();
    let orderFcID = this.landingComponentService.getOrderFoodCourtId();
    let userName = this.landingComponentService.getUserName();
    let btcRequestId = this.landingComponentService.getBTCRequestId();

    let billDetails = JSON.parse(JSON.stringify(this.billDetails));

    // Special scenario to convert values such as 100 to decimal value 100.0000000001 since backend require in float
    // if (this.billDetails.breakup['Item Total'])
    billDetails.breakup["Item Discount"] = (billDetails.breakup["Item Discount"] ? billDetails.breakup["Item Discount"] : 0) + 0.00000000001;
    billDetails.breakup['Item Total'] = (billDetails.breakup['Item Total'] - billDetails.breakup["Item Discount"] + 0.0000000001);
    // if (this.billDetails.breakup['Taxes & charge'])
    billDetails.breakup['Taxes & charge'] = billDetails.breakup['Taxes & charge'] + 0.0000000001;
    billDetails.breakup['Packing Charges'] = (billDetails.breakup['Packing Charges'] ? billDetails.breakup['Packing Charges'] : 0) + 0.0000000001;
    billDetails.breakup['Delivery Charges'] = (billDetails.breakup['Delivery Charges'] ? billDetails.breakup['Delivery Charges'] : 0) + 0.0000000001;


    let data = {
      "address": this.deliveryAddress,
      "orderType": this.orderType,
      "fcId": orderFcID,
      "requestId": btcRequestId,
      "employerId": null,
      "requestStatus": "NEW",
      "orderedItemsList": this.cartItemList,
      "guestDetails": [

      ],
      "userId": this.userId,
      "userName": userName,
      "mobile": userMobileNumber,
      "platform": "WEB",
      "mealCategory": null,
      "requestType": "BTC_REQUEST",
      "billDetails": billDetails
    }

    this.cartService.updateBTCOrderRequest(data).subscribe((response) => {

      if (this.scrWidth > 600) {
        this.router.navigate(["account/user"], { queryParams: { path: 'orders' } });
      } else {
        this.router.navigate(["profile/user/order-history"]);
      }
      this.landingComponentService.openSnackBarMessage("BTC Request Successfully Updated");
    }, (err) => {
      this.landingComponentService.openSnackBarMessage("Failed to Update BTC Request");
    }).add(() => {

      this.storageService.clearAllItemsFromCart();

      this.landingComponentService.setBTCRequestId(null);
      this.updateBTCRequestLoader = false;
      this.isBTCRequestUpdationFlow = false;
      this.getUserCartData();
    })
  }

  getPreOrderingTimeSlot() {

    let preOrderDateTimeInMS = new Date(this.preOrderDetails.orderDate).setHours(0, 0, 0, 0); // Preorder Date Time in milliseconds
    let todayDateTime = new Date();

    let hours = 0;
    if (preOrderDateTimeInMS === todayDateTime.setHours(0, 0, 0, 0)) {
      hours = 0;
    } else if (preOrderDateTimeInMS === (todayDateTime.setHours(0, 0, 0, 0) * 60 * 60 * 1000 * 24)) {
      hours = 24;
    } else {
      hours = 48;
    }

    this.preOrderTimeSlotSecond = [];

    for (let i = 0; i <= 15; i++) {
      let startTime = new Date();
      let endTime = new Date();

      startTime.setHours(0)
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);

      endTime.setHours(0);
      endTime.setMinutes(0);
      endTime.setSeconds(0);
      endTime.setMilliseconds(0);

      startTime.setTime(startTime.getTime() + ((hours + 7 + i) * 60 * 60 * 1000));
      endTime.setTime(endTime.getTime() + ((hours + 8 + i) * 60 * 60 * 1000));

      this.preOrderTimeSlotSecond.push({
        startTime: startTime,
        endTime: endTime,
        isBlocked: (startTime < new Date()),
        status: false
      });
    }
  }
  getPreOrderingTimeSlotByMenu() {

    let todayDateTime = new Date();
    let preOrderingDate = this.landingComponentService.getPreOrderDetails();

    if (this.preOrderMealCategory === "breakfast") {

      for (let i = 0; i < 4; i++) {
        let startTime = new Date(preOrderingDate);
        let endTime = new Date(preOrderingDate);

        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        startTime.setTime(startTime.setHours(0, 0, 0, 0) + ((7 + i) * 60 * 60 * 1000));
        endTime.setTime(endTime.setHours(0, 0, 0, 0) + ((8 + i) * 60 * 60 * 1000));

        this.menuTimingSlot.push({
          startTime: startTime,
          endTime: endTime,
          isBlocked: startTime.getTime() < (new Date().getTime() + 2 * 1.8 * 1000000),
          status: false
        });
      }
    }
    else if (this.preOrderMealCategory === "lunch") {

      for (let i = 0; i < 4; i++) {
        let startTime = new Date(preOrderingDate);
        let endTime = new Date(preOrderingDate);

        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        startTime.setTime(startTime.setHours(0, 0, 0, 0) + ((11 + i) * 60 * 60 * 1000));
        endTime.setTime(endTime.setHours(0, 0, 0, 0) + ((12 + i) * 60 * 60 * 1000));

        this.menuTimingSlot.push({
          startTime: startTime,
          endTime: endTime,
          // isBlocked: ((new Date(preOrderingDate).toLocaleDateString() > new Date().toLocaleDateString()) ? false: ((endTime.toLocaleTimeString() < new Date().toLocaleTimeString()))),
          isBlocked: startTime.getTime() < (new Date().getTime() + 2 * 1.8 * 1000000),
          status: false
        });
      }
    }
    else if (this.preOrderMealCategory === "snacks") {

      for (let i = 0; i < 3; i++) {
        let startTime = new Date(preOrderingDate);
        let endTime = new Date(preOrderingDate);

        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        startTime.setTime(startTime.setHours(0, 0, 0, 0) + ((15 + i) * 60 * 60 * 1000));
        endTime.setTime(endTime.setHours(0, 0, 0, 0) + ((16 + i) * 60 * 60 * 1000));

        this.menuTimingSlot.push({
          startTime: startTime,
          endTime: endTime,
          isBlocked: startTime.getTime() < (new Date().getTime() + 2 * 1.8 * 1000000),
          status: false
        });

      }
    } else if (this.preOrderMealCategory === "dinner") {

      for (let i = 0; i < 5; i++) {
        let startTime = new Date(preOrderingDate);
        let endTime = new Date(preOrderingDate);

        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        startTime.setTime(startTime.setHours(0, 0, 0, 0) + ((18 + i) * 60 * 60 * 1000));
        endTime.setTime(endTime.setHours(0, 0, 0, 0) + ((19 + i) * 60 * 60 * 1000));

        this.menuTimingSlot.push({
          startTime: startTime,
          endTime: endTime,
          isBlocked: startTime.getTime() < (new Date().getTime() + 2 * 1.8 * 1000000),
          status: false
        });
      }
    }
  }

  changePreOrderTimeSlot(timeslot) {

    this.selectedPreOrderTimeSlot = timeslot;
    this.togglePreOrderTimeSlotSelection = !this.togglePreOrderTimeSlotSelection;

    this.landingComponentService.setPreOrderDetails(this.selectedPreOrderTimeSlot.endTime);
    this.preOrderDetails.startTime = this.selectedPreOrderTimeSlot.startTime;
    this.preOrderDetails.endTime = this.selectedPreOrderTimeSlot.endTime;

    // change each item timeslot in db and through api
    if (this.userId) {
      let selectedTimeSlotStartTime = this.selectedPreOrderTimeSlot.startTime;
      let selectedTimeSlotEndTime = this.selectedPreOrderTimeSlot.endTime;

      selectedTimeSlotStartTime = ((selectedTimeSlotStartTime.getHours()) < 10 ? ("0" + selectedTimeSlotStartTime.getHours()) : (selectedTimeSlotStartTime.getHours())) + ":" +
        (selectedTimeSlotStartTime.getMinutes() < 10 ? ("0" + selectedTimeSlotStartTime.getMinutes()) : selectedTimeSlotStartTime.getMinutes()) + ":" +
        (selectedTimeSlotStartTime.getSeconds() < 10 ? ("0" + selectedTimeSlotStartTime.getSeconds()) : selectedTimeSlotStartTime.getSeconds()) + ".000";

      selectedTimeSlotEndTime = ((selectedTimeSlotEndTime.getHours()) < 10 ? ("0" + selectedTimeSlotEndTime.getHours()) : selectedTimeSlotEndTime.getHours()) + ":" +
        (selectedTimeSlotEndTime.getMinutes() < 10 ? ("0" + selectedTimeSlotEndTime.getMinutes()) : selectedTimeSlotEndTime.getMinutes()) + ":" +
        (selectedTimeSlotEndTime.getSeconds() < 10 ? ("0" + selectedTimeSlotEndTime.getSeconds()) : selectedTimeSlotEndTime.getSeconds()) + ".000";



      let data = {
        "endTime": selectedTimeSlotEndTime,
        "startTime": selectedTimeSlotStartTime
      }

      this.cartService.changePreOrderItemsTiming(this.userId, data).subscribe((response) => {

        this.storageService.getAllItemFromCart().then((response: any) => {

          response.forEach((itemDetails) => {

            let preOrderingEndDate = this.selectedPreOrderTimeSlot.endTime;

            itemDetails.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
              (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
              (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            itemDetails.preOrderStartTime = ((preOrderingEndDate.getHours() - 1) < 10 ? ("0" + (preOrderingEndDate.getHours() - 1)) : preOrderingEndDate.getHours() - 1) + ":" +
              (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
              (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            this.storageService.addItemToCart(itemDetails.appItemId, itemDetails);
          })
          // this.storageService.addItemToCart();
        })
      }, (err) => {

      })

    } else {
      this.storageService.getAllItemFromCart().then((response: any) => {
        this.storageService.getAllItemFromCart().then((response: any) => {

          response.forEach((itemDetails) => {

            let preOrderingEndDate = this.selectedPreOrderTimeSlot.endTime;

            itemDetails.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
              (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
              (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            itemDetails.preOrderStartTime = ((preOrderingEndDate.getHours() - 1) < 10 ? ("0" + (preOrderingEndDate.getHours() - 1)) : preOrderingEndDate.getHours() - 1) + ":" +
              (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
              (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            this.storageService.addItemToCart(itemDetails.appItemId, itemDetails);
          })
          // this.storageService.addItemToCart();
        })

      })
    }
  }

  orderType: string = 'DINE_IN';
  ORDER_OPTIONS = [
    { name: 'Self Pickup', value: 'PICK_UP', status: false },
    { name: 'Dine In', value: 'DINE_IN', status: true },
    { name: 'Delivery', value: 'DELIVERY', status: false },
  ];

  deliveryType: string = 'NORMAL_DELIVERY';
  DELIVERY_OPTIONS = [
    { name: 'Normal Delivery', value: 'NORMAL_DELIVERY', status: true },
    { name: 'Express Delivery', value: 'EXPRESS_DELIVERY', status: false },
  ];

  getUserPoints() {
    this.cartService.getUserRewardPoints(this.userId).subscribe(
      (data: any) => {
        this.userPoints.istharaPoints = data.data;
        if (this.userPoints.istharaPoints) {
          this.doPayWithIstharaPoint = true;
          this.billDetails.totalPayableAmt -= this.userPoints.istharaPoints;
          if (this.billDetails.totalPayableAmt < 0) {
            this.billDetails.totalPayableAmt = 0;
          }
        } else {
          this.doPayWithIstharaPoint = false;
          // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
          this.billDetails.totalPayableAmt = this.billDetails.finalPrice;
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  openPackagingChargesDetails() {
    let chargesDetails = [];
    this.cartItemList.forEach((item) => {
      if (chargesDetails[item.vendorName] !== undefined)
        chargesDetails[item.vendorName] += item.itemQuantity * item.packagingCharge;
      else
        chargesDetails[item.vendorName] = item.itemQuantity * item.packagingCharge;
    })
    console.log(chargesDetails);
    let packagingChargesDetails = this.dialog.open(ConfirmationPopupComponent, {
      width: '90vw',
      height: 'auto',
      maxWidth: '100vw',
      data: { type: 'PACKAGING_CHARGES_DETAILS', chargesDetails: chargesDetails },
    });
  }

  discountDetails: any = {};
  getCouponDetails(couponId) {
    this.cartService.getCouponDetails(couponId).subscribe(
      (response: any) => {
        this.discountDetails = response?.data;

        if (this.discountDetails.discountType === 'FIXED_AMOUNT') {
          // this.billDetails.finalPrice -= this.discountDetails.discountValue;
          this.billDetails.discountAmount = this.discountDetails.discountValue;
        } else {
          // this.billDetails.finalPrice -= ((this.billDetails.breakup["Item Total"] * (this.discountDetails.discountValue/100)));
          // this.billDetails.discountAmount =
          //   (this.billDetails.breakup['Item Total'] *
          //     (this.discountDetails.discountValue / 100)) /
          //   100;
          this.billDetails.discountAmount =
            (this.billDetails.breakup['Item Total'] *
              (this.discountDetails.discountValue / 100));
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  showSelectedCustomization(item, edit = true) {
    // console.log(item);

    this.cartService.getAllCustomizationsOfItem(item.id).subscribe((response: any) => {
      if (response.data && response.data.length > 0) {
        item.menuCustomizationList = response.data.map((element) => {
          let obj = Object.assign({}, element);
          obj.customizedItemsForMenuList.forEach((customItems) => {
            customItems.status = false;
          })

          return obj;
        });

        const dialogRef = this.bottomSheet.open(CustomizeItemBottomsheetComponent, {
          data: { data: item, edit: edit },
        });
        dialogRef.afterDismissed().subscribe((response) => {
          if (!response) return;
          item.orderedItemCustomizations = response.selectedCustomization;
          this.updateOrderedItemCustomizations(item);
        });
      }

    }, (err) => {
      console.log(err);
    })
  }

  updateOrderedItemCustomizations(item) {
    this.showLoader = true;
    this.globalCartService.checkForCustomizedItemInIndexDb(item, 'CART')
      .then((result: any) => {
        console.log(item);

        if (result.isSameItemPresent) {
          this.globalCartService
            .addItemToIndexedDB(item, true)
            .then((response: any) => {
              this.globalCartService.removeItemFromCart(result.dbItem)
                .then(response => {
                  this.getUserCartDataIndexDB(true);

                  // this.landingComponentService.itemQuantityChangeFromCart.next(true);
                  // this.showLoader = false;
                })
                .catch(error => {
                  this.showLoader = false;
                  // item.showCustomQuantity += 1;
                  // item.itemQuantity += 1;
                })
            })
            .catch((err) => {
              item.itemQuantity -= item.showCustomQuantity;
              this.showLoader = false;
              // this.landingConsoleService.openSnackBarMessage(err);
            });
        } else {
          this.globalCartService
            .addItemToIndexedDB(item, true)
            .then((response: any) => {
              this.getUserCartDataIndexDB(true);

              // this.landingComponentService.itemQuantityChangeFromCart.next(true);
              this.showLoader = false;
            })
            .catch((err) => {
              item.itemQuantity -= item.showCustomQuantity;
              this.showLoader = false;
              // this.landingConsoleService.openSnackBarMessage(err);
            });
        }
      })
  }

  itemIdForLoading: string = '';
  showRunTimeLoader: boolean = false;

  increaseItemCount(item) {
    this.itemIdForLoading = item.appItemId;

    this.showRunTimeLoader = true;

    item.showCustomQuantity += 1;
    item.itemQuantity = item.showCustomQuantity;

    // this.globalCartService
    //   .increaseItemCount(item, true)
    //   .then((response: any) => {
    //     this.showRunTimeLoader = false;

    //     if (this.userId) this.getUserCartData();
    //     else this.getUserCartDataIndexDB();

    //     this.landingComponentService.itemQuantityChangeFromCart.next(true);
    //   })
    //   .catch((err) => {
    //     this.showRunTimeLoader = false;
    //     item.quantity -= 1;
    //     // this.landingConsoleService.openSnackBarMessage(err);
    //   });
    // console.log("itemjhbshdg", item);

    this.globalCartService
      .addItemToIndexedDB(item, true, (this.preOrderMealCategory ? true : false))
      .then((response: any) => {

        this.getUserCartDataIndexDB(true);

        // this.landingComponentService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.showCustomQuantity -= 1;
        item.itemQuantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
  }
  decreaseItemCount(item) {
    this.itemIdForLoading = item.appItemId;
    this.showRunTimeLoader = true;
    // item.quantity -= 1;
    item.showCustomQuantity -= 1;
    item.itemQuantity = item.showCustomQuantity;
    // if (item.quantity > 0) {
    // this.globalCartService
    //   .decreaseItemCount(item, true)
    //   .then((response: any) => {
    //     this.showRunTimeLoader = false;
    //     if (this.userId) this.getUserCartData();
    //     else this.getUserCartDataIndexDB();

    //     this.landingComponentService.itemQuantityChangeFromCart.next(true);
    //   })
    //   .catch((err) => {
    //     item.quantity += 1;
    //     this.showRunTimeLoader = false;
    //     this.cartService.openSnackBarMessage(err);
    //   });
    if (item.itemQuantity > 0) {
      this.globalCartService
        .addItemToIndexedDB(item, true, (this.preOrderMealCategory ? true : false))
        .then((response: any) => {
          this.getUserCartDataIndexDB(true);

          // this.landingComponentService.itemQuantityChangeFromCart.next(true);
        })
        .catch((err) => {
          this.showRunTimeLoader = false;
          item.showCustomQuantity += 1;
          item.itemQuantity += 1;
          // this.landingConsoleService.openSnackBarMessage(err);
        });
    } else {
      this.globalCartService.removeItemFromCart(item)
        .then(response => {
          this.getUserCartDataIndexDB(true);

          // this.landingComponentService.itemQuantityChangeFromCart.next(true);
        })
        .catch(error => {
          this.showRunTimeLoader = false;
          item.showCustomQuantity += 1;
          item.itemQuantity += 1;
        })
    }
    // } else
    // this.globalCartService
    //   .removeItemFromCart(item)
    //   .then((response) => {
    //     this.showRunTimeLoader = false;
    //     this.cartItemList = this.cartItemList.filter((cartItem) => {
    //       return cartItem.id !== item.id;
    //     });
    //     if (this.userId) this.getUserCartData();
    //     else this.getUserCartDataIndexDB();
    //     //console.log('Cart item: ', this.cartItemList);
    //   })
    //   .catch((err) => {
    //     this.showRunTimeLoader = false;
    //     this.cartService.openSnackBarMessage(err);
    //   });
  }
  clearItemFromCart(item) {
    // if (this.userId) {
    //   this.cartService.removeItemFromCart(this.userId, item.id).subscribe(
    //     (response) => {
    //       this.storageService
    //     .removeItemFromCart(item.appItemId)
    //     .then((response) => {
    //       // //console.log(response);
    //       this.getUserCartDataIndexDB(true);
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     });
    //     },
    //     (err) => {
    //       //console.log(err);
    //       this.cartService.openSnackBarMessage('Failed to remove Out of Stock Item');
    //     }
    //   );
    // } else {
    this.storageService
      .removeItemFromCart(item.appItemId)
      .then((response) => {
        // //console.log(response);
        this.getUserCartDataIndexDB();
      })
      .catch((err) => {
        //console.log(err);
      });
    // }
  }

  getUserCartData() {

    this.showLoader = true;
    this.cartService.getCartData(this.userId).subscribe(
      async (response: any) => {
        this.orderType = response?.data?.orderType;
        if (!this.orderType) {
          this.orderType = 'DINE_IN';
        }
        this.deliveryAddress = response?.data?.address;

        if (this.deliveryAddress) {
          if (this.deliveryAddress.addressId) {
            if (!this.deliveryAddress.address) {
              this.deliveryAddress = null;
            }
          }
        }

        this.cartItemList = response?.data?.itemsList
          ? response?.data?.itemsList
          : [];

        if (this.cartItemList && this.cartItemList.length > 0) {
          await this.storageService.clearAllItemsFromCart();
          this.cartItemList.forEach(async (item) => {
            await this.storageService.addItemToCart(item.appItemId, item);
          })

          this.storageService.getAllItemFromCart().then((cartData: any) => {
            // this.cartItemList = cartData || [];

            if (this.cartItemList && this.cartItemList.length > 0) {
              cartData.forEach((cartItem) => {
                this.cartItemList.forEach((res) => {
                  // if (cartItem.itemId === res.itemId) {
                  //   res.showCustomQuantity = cartItem.itemQuantity;
                  //   res.appItemId = cartItem.appItemId;
                  // }

                  if (cartItem.itemId === res.itemId) {
                    if (res.orderedItemCustomizations && res.orderedItemCustomizations.length > 0) {
                      if (cartItem.orderedItemCustomizations.length === res.orderedItemCustomizations.length) {
                        let dbItemMergeCategoryIds = '';
                        let itemMergeCategoryIds = '';
                        res.orderedItemCustomizations.forEach((customItems) => {
                          itemMergeCategoryIds += customItems.categoryId;
                        })
                        cartItem.orderedItemCustomizations.forEach((customItems) => {
                          dbItemMergeCategoryIds += customItems.categoryId;
                        })

                        if (itemMergeCategoryIds === dbItemMergeCategoryIds) {
                          res.appItemId = cartItem.appItemId;
                          res.showCustomQuantity = cartItem.itemQuantity;
                          res.orderedItemCustomizations = cartItem.orderedItemCustomizations;
                        }
                      }
                    } else {
                      res.appItemId = cartItem.appItemId;
                      res.showCustomQuantity = cartItem.itemQuantity;
                    }
                  }

                  res.mealCategory = res.itemMealCategory;
                  res.name = res.itemName;
                  res.foodCourtId = response?.data?.foodCourtId;
                  this.landingComponentService.setOrderFoodCourtId(res.foodCourtId);
                  res.id = res?.itemId;

                  let url = res.imgUrl;
                  let pattern = /^((http|https|ftp):\/\/)/;
                  if (pattern.test(url)) {
                    res.imgUrl = url;
                  } else {
                    res.imgUrl = '';
                  }

                  if (res.orderedItemCustomizations && res.orderedItemCustomizations.length > 0) {
                    res.shownCustomizedItemsName = '';
                    res.orderedItemCustomizations.forEach((element) => {
                      res.shownCustomizedItemsName += (element.name + ' ' + res.itemName + ', ')
                    })
                  }
                })
              });
              this.getFoodcourtDetails();
            }
          })

          this.billDetails = response?.data?.billDetails
            ? response?.data?.billDetails
            : [];

          // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
          this.billDetails.totalPayableAmt = this.billDetails.finalPrice;
          // this.billDetails.totalPrice = this.billDetails.finalPrice;
          //console.log(this.billDetails);

          // Api call for getting user points
          if (this.doPayWithIstharaPoint) this.getUserPoints();

          // Api call for getting user coupon details
          if (this.billDetails?.couponId)
            this.getCouponDetails(this.billDetails?.couponId);


          this.showLoader = false;
        } else {
          this.storageService.clearAllItemsFromCart()
          this.showLoader = false;
        }
      },
      (err) => {
        //console.log(err);
        this.showLoader = false;
        this.errorOnAPI = true;
      }
    );
  }

  getUserIndexDBData() {
    this.storageService.getAllItemFromCart().then((response) => {
      this.cartItemList = response;
      this.billDetails = {
        breakup: {
          'Item Total': 0,
          'Taxes & charge': 0,
        },
        finalPrice: 0,
      };

      this.cartItemList.forEach((item) => {
        item.totalCustomzedItemsPrice = 0;

        if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
          item.shownCustomizedItemsName = '';
          item.orderedItemCustomizations.forEach((element) => {
            item.shownCustomizedItemsName += (element.name + ' ' + item.itemName + ', ')
            item.totalCustomzedItemsPrice += element.markUpPrice;
          })
        }

        item.totalPrice = 0;
        item.totalPrice = (item?.markUpPrice + item.totalCustomzedItemsPrice) * item?.itemQuantity;

        this.billDetails.breakup['Item Total'] +=
          (item?.markUpPrice + item.totalCustomzedItemsPrice) * item?.itemQuantity;

        this.billDetails.breakup['Packing Charges'] = 0;
        // this.cartItemList.forEach((element)=> {
        //   this.billDetails.breakup['packagingCharges'] += (element?.packagingCharge) * (element?.itemQuantity);
        // })
        // this.billDetails.breakup['Taxes & charge'] +=
        //   !item.mrpItem ? ((item.taxPerItem || item.taxPerItem !== 0) ? Number(item.taxPerItem*item.itemQuantity):Number(((item?.price + item.totalCustomzedItemsPrice) *0.05 )*item.itemQuantity)):0
        this.billDetails.breakup['Taxes & charge'] +=
          item.taxCategory !== "MRP" ? ((item.taxPercentPerItem && item.taxPercentPerItem !== 0) ? Number(((item?.markUpPrice + item.totalCustomzedItemsPrice) * (item.taxPercentPerItem / 100)) * item.itemQuantity) : Number(((item?.markUpPrice + item.totalCustomzedItemsPrice) * 0.05) * item.itemQuantity)) : 0
        // item.itemQuantity = item.itemQuantity;
        // item.itemName = item.itemName;
        item.itemType = item.veg ? 'VEG' : 'NON_VEG';
        item.mealCategory = item.itemMealCategory;
        item.id = item.itemId;
        item.appItemId = item.appItemId;
        item.showCustomQuantity = item.itemQuantity;
        item.itemImageUrl = item?.imgUrl;


        let url = item.imgUrl;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (pattern.test(url)) {
          item.imgUrl = url;
        } else {
          item.imgUrl = '';
        }

      });
      // console.log(this.billDetails);

      this.billDetails.finalPrice =
        this.billDetails.breakup['Item Total'] +
        this.billDetails.breakup['Taxes & charge'];

      // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
      this.billDetails.totalPayableAmt = this.billDetails.finalPrice;
      // this.billDetails.totalPrice = this.billDetails.finalPrice;

      if (this.cartItemList.length > 0) {
        this.getFoodcourtDetails();
      }
      this.landingComponentService.itemQuantityChangeFromCart.next(true);
      this.showRunTimeLoader = false;
      this.showLoader = false;
    })
    // data.itemsList = this.cartItemList;

  }

  getUserCartDataIndexDB(callAddAllItemApi = false) {

    let userName = this.landingComponentService.getUserName();
    let userMobile = this.landingComponentService.getUserMobile();
    let orderedFoodCourtId = this.landingComponentService.getOrderFoodCourtId();
    let btcRequestId = this.landingComponentService.getBTCRequestId();

    if (orderedFoodCourtId) {
      if (this.userId) {
        let data = {
          address: this.deliveryAddress,
          orderType: this.orderType,
          foodCourtId: orderedFoodCourtId,
          userId: this.userId,
          userName: userName,
          mobile: userMobile,
          platform: "WEB",
          itemsList: {},
          mealCategory: null, // need to change
          btcRequestId: btcRequestId,
          preOrder: false,
          preOrderDate: null,
          preOrderEndTime: null,
          preOrderStartTime: null
        };

        if (this.landingComponentService.getIsOrderPreOrderType()) {

          data.mealCategory = (this.landingComponentService.getPreOrderMenuType()).toUpperCase();

          let preOrderingEndDate = new Date(this.landingComponentService.getPreOrderDetails());

          data.preOrder = true;
          data.preOrderDate = preOrderingEndDate,

            data.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
            (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
            (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

          data.preOrderStartTime = ((preOrderingEndDate.getHours() - 1) < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours() - 1) + ":" +
            (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
            (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";


        }
        this.storageService.getAllItemFromCart().then((response) => {

          data.itemsList = response;

          this.landingComponentService.addItemToCartAPI(data).subscribe(
            (response) => {
              this.landingComponentService.itemQuantityChangeFromCart.next(true);
              this.getUserCartData();

              this.showRunTimeLoader = false;
            },
            (err) => {
              // this.showRunTimeLoader = false;
              // this.showLoader = false;
              //console.log(err);
              this.handleErrorForAddingItems();
              this.landingComponentService.openSnackBarMessage(
                'Failed to add item to Cart'
              );
            }
          );
        })
      } else {
        this.storageService.getAllItemFromCart().then((response) => {
          this.cartItemList = response;
          this.billDetails = {
            breakup: {
              'Item Total': 0,
              'Taxes & charge': 0,
            },
            finalPrice: 0,
          };

          this.cartItemList.forEach((item) => {
            item.totalCustomzedItemsPrice = 0;

            if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
              item.shownCustomizedItemsName = '';
              item.orderedItemCustomizations.forEach((element) => {
                item.shownCustomizedItemsName += (element.name + ' ' + item.itemName + ', ')
                item.totalCustomzedItemsPrice += element.price;
              })
            }

            item.totalPrice = 0;
            item.totalPrice = (item?.markUpPrice + item.totalCustomzedItemsPrice) * item?.itemQuantity;

            this.billDetails.breakup['Item Total'] +=
              (item?.markUpPrice + item.totalCustomzedItemsPrice) * item?.itemQuantity;

            this.billDetails.breakup['Packing Charges'] = 0;
            // this.cartItemList.forEach((element)=> {
            //   this.billDetails.breakup['packagingCharges'] += (element?.packagingCharge) * (element?.itemQuantity);
            // })
            // this.billDetails.breakup['Taxes & charge'] +=
            //   !item.mrpItem ? ((item.taxPerItem || item.taxPerItem !== 0) ? Number(item.taxPerItem*item.itemQuantity):Number(((item?.price + item.totalCustomzedItemsPrice) *0.05 )*item.itemQuantity)):0
            // this.billDetails.breakup['Taxes & charge'] +=
            //   item.taxCategory !== "MRP" ? ((item.taxPercentPerItem && item.taxPercentPerItem !== 0) ? Number(((item?.markUpPrice + item.totalCustomzedItemsPrice) * (item.taxPercentPerItem / 100)) * item.itemQuantity) : Number(((item?.markUpPrice + item.totalCustomzedItemsPrice) * 0.05) * item.itemQuantity)) : 0;
            
            this.billDetails.breakup['Taxes & charge'] +=
              item.taxCategory !== "MRP" ? ((item.markUpTotalTax && item.markUpTotalTax !== 0) ? item.markUpTotalTax : Number(((item?.markUpPrice + item.totalCustomzedItemsPrice) * 0.05) * item.itemQuantity)) : 0;
            
            // item.itemQuantity = item.itemQuantity;
            // item.itemName = item.itemName;
            item.itemType = item.veg ? 'VEG' : 'NON_VEG';
            item.mealCategory = item.itemMealCategory;
            item.id = item.itemId;
            item.appItemId = item.appItemId;
            item.showCustomQuantity = item.itemQuantity;
            item.itemImageUrl = item?.imgUrl;


            let url = item.imgUrl;
            let pattern = /^((http|https|ftp):\/\/)/;
            if (pattern.test(url)) {
              item.imgUrl = url;
            } else {
              item.imgUrl = '';
            }

          });
          // console.log(this.billDetails);

          this.billDetails.finalPrice =
            this.billDetails.breakup['Item Total'] +
            this.billDetails.breakup['Taxes & charge'];

          // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
          this.billDetails.totalPayableAmt = this.billDetails.finalPrice;
          // this.billDetails.totalPrice = this.billDetails.finalPrice;

          if (this.cartItemList.length > 0) {
            this.getFoodcourtDetails();
          }
          this.landingComponentService.itemQuantityChangeFromCart.next(true);
          this.showRunTimeLoader = false;
          this.showLoader = false;
        })

      }
      // data.itemsList = this.cartItemList;
      console.log("cart item list", this.cartItemList);


    } else if (!orderedFoodCourtId) {
      // in some scenario orderedFoodcourtId is being null but cart contain items. In such scenario remove item from cart
      this.storageService.getAllItemFromCart().then((response: any) => {
        if (response?.length > 0) {
          this.storageService.clearAllItemsFromCart().then((response) => {
            this.landingComponentService.openSnackBarMessage("Failed to get items of cart, please add item")
          })
        }
      })

      // need to change temp sol for desktop view
      if (this.userId) {
        this.getUserCartData();
      } else {
        this.getUserIndexDBData();
      }
    } else {
      this.showRunTimeLoader = false;
      this.showLoader = false;

    }
    // this.showLoader = true;
    // this.storageService.getAllItemFromCart().then((response) => {
    //   this.cartItemList = response;
    //   this.billDetails = {
    //     breakup: {
    //       'Item Total': 0,
    //       'Taxes & charge': 0,
    //     },
    //     finalPrice: 0,
    //   };

    //   this.cartItemList.forEach((item) => {
    //     this.billDetails.breakup['Item Total'] +=
    //       item?.price * item?.itemQuantity;
    //     this.billDetails.breakup['Taxes & charge'] +=
    //       !item.mrpItem ? ((item.taxPerItem || item.taxPerItem !== 0) ? Number(item.taxPerItem*item.itemQuantity):Number((item.price *0.05 )*item.itemQuantity)):0
    //     item.quantity = item.itemQuantity;
    //     item.name = item.itemName;
    //     item.itemType = item.veg ? 'VEG' : 'NON_VEG';
    //     item.mealCategory = item.itemMealCategory;
    //     item.id = item.itemId;
    //     item.appItemId = item.appItemId;
    //     item.itemImageUrl = item?.imgUrl;

    //     if(item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
    //       item.shownCustomizedItemsName = '';
    //       item.orderedItemCustomizations.forEach((element) => {
    //         item.shownCustomizedItemsName += (element.name + ' ' + item.itemName + ', ')
    //       })
    //     }
    //   });
    //   console.log(this.billDetails);

    //   this.billDetails.finalPrice =
    //     this.billDetails.breakup['Item Total'] +
    //     this.billDetails.breakup['Taxes & charge'];

    //   this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
    //   // this.billDetails.totalPrice = this.billDetails.finalPrice;

    //   if (this.cartItemList.length > 0) {
    //     this.getFoodcourtDetails();
    //   }

    //   if(callAddAllItemApi) {
    //     return new Promise((resolve, reject) => {
    //       resolve(response);
    //     })
    //   } else {
    //     this.showLoader = false;
    //   }

    // })
    // .then((result) => {
    //   if(callAddAllItemApi) {

    //   }
    // })
  }

  handleErrorForAddingItems() {
    let userId = this.landingComponentService.getUserId();

    // Clearing local db and getting user cart data from backend and adding back to local db and maintaining the count of items
    this.storageService.clearAllItemsFromCart()
      .then((result) => {
        this.landingComponentService.getCartData(userId).subscribe(async (response: any) => {
          let orderFoodCourtId = response.data.foodCourtId;
          this.landingComponentService.setOrderFoodCourtId(orderFoodCourtId);

          console.log("user cart from api", response);
          for (let item of response.data.itemsList) {
            await this.globalCartService.addItemToIndexedDB(item, false);
            // console.log("items adding in process");
          }

          this.getUserCartData();

          this.showRunTimeLoader = false;
          // console.log("cart added to items complete");
        }, err => {
          this.landingComponentService.openSnackBarMessage("Failed to add item to cart, please refresh once")
        })
      })
      .catch((error) => {
        this.showRunTimeLoader = false;
        this.showLoader = false;
      })
  }

  viewCouponsPage(coupon) {
    if (this.userId) {
      if (this.scrWidth > 600) {
        this.landingComponentService.sidePanelContent.next("COUPON");
      }
      else if (!coupon) {
        this.router.navigate(['user-cart/coupons']);
      }
    } else {
      this.dbStorageService.openSnackBarMessage(
        'For applying coupon, you need to login',
        'Ok'
      );
    }
  }
  removeCoupon() {
    this.showLoader = true;
    this.cartService.removeCouponFromUserCart(this.userId).subscribe(
      (response: any) => {
        this.billDetails = response?.data?.billDetails || [];
        // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
        this.billDetails.totalPayableAmt = this.billDetails.finalPrice;

        this.showLoader = false;
      },
      (err) => {
        //console.log(err);
        this.cartService.openSnackBarMessage('Failed to remove Coupon Code');
      }
    );
  }

  toggleUserPoints(value?) {
    //console.log(this.doPayWithIstharaPoint);
    if (this.doPayWithIstharaPoint) {
      if (this.userPoints.istharaPoints) {
        // this.billDetails.totalPayableAmt =
        //   this.billDetails.finalPrice / 100 - this.userPoints.istharaPoints;
        this.billDetails.totalPayableAmt =
          this.billDetails.finalPrice - this.userPoints.istharaPoints;
        if (this.billDetails.totalPayableAmt < 0) {
          this.billDetails.totalPayableAmt = 0;
        }
      } else {
        this.doPayWithIstharaPoint = false;
        this.dbStorageService.openSnackBarMessage(
          "You don't have points",
          'Ok'
        );
      }
    } else {
      // this.billDetails.totalPayableAmt = this.billDetails.finalPrice / 100;
      this.billDetails.totalPayableAmt = this.billDetails.finalPrice;
    }
  }

  buttonLoading: boolean = false;
  formDataView: any = {};

  ratingdetails: any;
  showRatingFlow(isPaymentBtnClicked = false) {

    let fcId = this.landingComponentService.getOrderFoodCourtId();
    let userId = this.landingComponentService.getUserId();

    let fcHasRating = this.landingComponentService.getIsRatingCompulsoryInOrderingFC();
    if (fcHasRating) {

      this.landingComponentService.getratingdata(fcId, userId).subscribe(
        (response: any) => {
          this.ratingdetails = response.data

          if (this.ratingdetails?.ratingRequired) {
            this._userAccountService.setinfo_order("CART");
            let orderid = this.ratingdetails.vendorwiseOrders.orderId;
            this.landingComponentService.setReviewOrderId(orderid);
            this.bottomSheet.open(RatingComponent, { data: { orderNum: this.ratingdetails.vendorwiseOrders.orderNum } });
          }
          else {
            if (isPaymentBtnClicked)
              this.doPayment();
          }
        }, (err) => {

        })
    } else {
      // if (this.scrWidth > 600)
      if (isPaymentBtnClicked)
        this.doPayment();
    }
  }


  paymentFlow() {

    let userId = this.landingComponentService.getUserId();

    if (!userId) {
      this.router.navigate(['auth'], { state: { isFromCart: true } });
    }

    this.showRatingFlow(true);
  }
  doPayment() {

    let preOrderEndDateTime = this.landingComponentService.getPreOrderDetails();
    if (((new Date()).getTime() > (new Date(preOrderEndDateTime).getTime() - 2 * 1.8 * 1000000)) && preOrderEndDateTime) {

      let ConfirmationPoup = this.dialog.open(PreorderConfirmationPopupComponent, {
        width: '350px',
        height: 'auto',
        maxWidth: '100vw',
        data: { type: 'PREORDER_TIME_EXCEEDED' }
      });
      ConfirmationPoup.afterClosed().subscribe((data) => {
        if (data) {
          if (this.userId) {
            this.userName = this.cartService.getUserName();
            this.userMobileNumber = this.cartService.getUserMobile();

            this.getUserCartData();
          } else {
            this.getUserCartDataIndexDB();
          }
        }
      })
    }
    else if (this.userId && this.orderType === 'DELIVERY') {
      let ConfirmationPoup = this.dialog.open(ConfirmationPopupComponent, {
        width: '350px',
        height: 'auto',
        maxWidth: '100vw',
        data: {
          deliveryAddress: this.deliveryAddress,
          type: 'CONFIRM_LOCATION',
        },
      });

      ConfirmationPoup.afterClosed().subscribe((data) => {
        if (data) {
          this.buttonLoading = true;
          this.finalUserCartCheck();
        }
      });
    } else if (this.userId && this.orderType !== 'DELIVERY') {
      this.buttonLoading = true;
      this.finalUserCartCheck();
    } else {
      this.router.navigate(['auth'], { state: { isFromCart: true } });
    }
  }

  requestCorporateOrder() {

    this.sendBTCRequestLoader = true;

    if (!this.userId) {
      this.router.navigate(['auth'], { state: { isFromCart: true } });
    } else {
      let userMobileNumber = this.landingComponentService.getUserMobile();
      let orderFcID = this.landingComponentService.getOrderFoodCourtId();
      let userName = this.landingComponentService.getUserName();

      let billDetails = JSON.parse(JSON.stringify(this.billDetails));

      // Special scenario to convert values such as 100 to decimal value 100.0000000001 since backend require in float
      // if (this.billDetails.breakup['Item Total'])
      billDetails.breakup["Item Discount"] = (billDetails.breakup["Item Discount"] ? billDetails.breakup["Item Discount"] : 0) + 0.00000000001;
      billDetails.breakup['Item Total'] = (billDetails.breakup['Item Total'] - billDetails.breakup["Item Discount"] + 0.0000000001);
      // if (this.billDetails.breakup['Taxes & charge'])
      billDetails.breakup['Taxes & charge'] = billDetails.breakup['Taxes & charge'] + 0.0000000001;
      billDetails.breakup['Packing Charges'] = (billDetails.breakup['Packing Charges'] ? billDetails.breakup['Packing Charges'] : 0) + 0.0000000001;
      billDetails.breakup['Delivery Charges'] = (billDetails.breakup['Delivery Charges'] ? billDetails.breakup['Delivery Charges'] : 0) + 0.0000000001;

      let data = {
        "address": this.deliveryAddress,
        "orderType": this.orderType,
        "fcId": orderFcID,
        "employerId": null,
        "requestStatus": "NEW",
        "orderedItemsList": this.cartItemList,
        "guestDetails": [

        ],
        "userId": this.userId,
        "userName": userName,
        "mobile": userMobileNumber,
        "platform": "WEB",
        "mealCategory": null,
        "requestType": "BTC_REQUEST",
        "billDetails": billDetails
      }

      this.cartService.sendBTCOrderRequest(data).subscribe((response) => {
        // this.landingComponentService.clearCartDataAPI(this.userId).subscribe(()=> {
        this.storageService.clearAllItemsFromCart();

        this.sendBTCRequestLoader = false;
        if (this.scrWidth > 600) {
          this.router.navigate(["account/user"], { queryParams: { path: 'orders' } });
        } else {
          this.router.navigate(["profile/user/order-history"]);
        }
        // }, (err)=> {
        //   this.sendBTCRequestLoader = false;
        // })
      }, (err) => {
        this.sendBTCRequestLoader = false;
      })
    }
  }

  payWithIstharaPoints() {
    this.payBTCRequestLoader = true;
    this.buttonLoading = true;

    let data = {
      orderNote: this.orderNote,
    };
    this.cartService.payWithIstharaPoints(this.userId, data).subscribe(
      (response: any) => {
        this.dbStorageService.setOrderId(response.data.orderId);
        this.globalCartService.getUserCartData();

        this.storageService.clearAllItemsFromCart().then((result) => {

          this.showPaymentStatusPopup('SUCCESS', response.data.orderId);
          this.payBTCRequestLoader = false;

          this.landingComponentService.setUserAddressForBTC(null);
          this.landingComponentService.setUserOrderTypeForBTC(null);

          if (this.landingComponentService.getIsOrderPreOrderType()) {
            this.router.navigate(['profile/user/order-summary/pre-order']);
          } else {
            this.router.navigate(['profile/user/order-summary/self-order']);
          }
        })
      },
      (err) => {
        // this.cartService.op
        this.buttonLoading = false;
        this.payBTCRequestLoader = false;

        if (err.status === 404) {
          this.cartService.openSnackBarMessage('Items present in the cart are out of stock. Please Remove the Items');
          this.getUserCartData();
        }
        else {
          this.cartService.openSnackBarMessage('Something went wrong!! plz try again later');
        }
        this.showPaymentStatusPopup('FAILURE');
      }
    );
  }
  payWithCashFree() {

    this.payBTCRequestLoader = true;
    this.buttonLoading = true;

    let userEmailId = this.landingComponentService.getUserEmail();
    let orderDetails = {
      email: (userEmailId ? userEmailId : 'test@gmail.com'),
      mobile: this.userMobileNumber,
      // orderAmount: Number((this.billDetails.totalPayableAmt * 100).toFixed(2)),
      orderAmount: Number((this.billDetails.totalPayableAmt * 100).toFixed(2)),
      // pointsToBePaid: ((this.billDetails.finalPrice / 100) - this.billDetails.totalPayableAmt),
      pointsToBePaid: ((this.billDetails.finalPrice) - this.billDetails.totalPayableAmt),
      userId: this.userId,
      userName: this.userName,
      orderNote: this.orderNote,
    };

    this.cartService.createOrder(orderDetails).subscribe(
      (pay: any) => {
        if (this.landingComponentService.getIsOrderPreOrderType()) {
          this.dbStorageService.setPaymentFor('PRE_ORDER_PAYMENT');
        } else {
          this.dbStorageService.setPaymentFor('CART_PAYMENT');
        }
        this.dbStorageService.setOrderId(pay.data.fcOrderId);

        this.landingComponentService.setUserAddressForBTC(null);
        this.landingComponentService.setUserOrderTypeForBTC(null);

        // this.formDataView.paymentLink = pay.data.paymentLink;
        // this.formDataView.payments = pay.data.paymentLink;
        // this.formDataView.orderId = pay.data.orderId;
        // this.formDataView.orderAmount = pay.data.orderAmount / 100;
        // this.formDataView.customerName = pay.data.payeeName;
        // this.formDataView.customerPhone = pay.data.payeeMobile;
        // this.formDataView.customerEmail = pay.data.payeeEmail;
        // this.formDataView.returnUrl = pay.data.returnUrl; //pay.data.returnUrl; //http://localhost:4200/#/amount/${this.paymentObj.bookingId}
        // this.formDataView.notifyUrl = pay.data.notifyUrl;
        // this.formDataView.appId = pay.data.appId;
        // this.formDataView.signature = pay.data.orderSignature; //paymentToken
        // this.formDataView.orderCurrency = pay.data.paymentCurrency;
        // this.formDataView.orderNote = 'Order';

        const paymentSessionId = pay.data.orderSignature;
        let cashFree;
        if (environment.production) {
          cashFree = new cashfreeProd.Cashfree(paymentSessionId);
        } else {
          cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
        }
        cashFree.redirect();

        // const dropinConfig = {
        //   // orderToken
        //   components: [
        //       "order-details",
        //       "card",
        //       "netbanking",
        //       "app",
        //       "upi",
        //   ],
        //   onSuccess: function(data){
        //
        //      this.router.navigate(['/foodcourt/order/payment']);
        //   },
        //   onFailure: function(data){
        //      //on success
        //   },
        //   style: {
        //         //to be replaced by the desired values
        //         backgroundColor: "#ffffff",
        //         color: "#11385b",
        //         fontFamily: "Lato",
        //         fontSize: "14px",
        //         errorColor: "#ff0000",
        //         theme: "light"
        //   }
        // }
        // let parent = document.getElementById("drop_in_container");
        // cashfree.drop(parent, dropinConfig);
        // setTimeout(() => {
        //   this.buttonLoading = false;
        //   document.forms['redirectForm'].submit();
        //   this.globalCartService.getUserCartData();
        // }, 100);

        this.payBTCRequestLoader = false;

      },
      (err) => {
        this.buttonLoading = false;
        this.payBTCRequestLoader = false;

        if (err.status === 404) {
          this.cartService.openSnackBarMessage('Some Cart Item is Out Of Stock Please Remove the Items');
          this.getUserCartData();
        }
        else {
          this.cartService.openSnackBarMessage('Something went wrong!! plz try again later');
        }
        this.dbStorageService.removeFromLocalStorage('paymentFor');
        this.dbStorageService.setOrderId(null);
        //console.log(err);
      }
    );
  }

  finalUserCartCheck() {

    this.payBTCRequestLoader = true;
    this.buttonLoading = true;

    this.cartService.getCartData(this.userId).subscribe(
      (response: any) => {

        let tempBillDetails = response?.data?.billDetails
          ? response?.data?.billDetails
          : [];

        if (this.billDetails?.finalPrice === tempBillDetails?.finalPrice) {
          if (!this.billDetails.totalPayableAmt) this.payWithIstharaPoints();
          else this.payWithCashFree();
        }
        else {
          this.payBTCRequestLoader = false;
          this.buttonLoading = false;
          this.landingComponentService.openSnackBarMessageIMP("Some error occured! Getting your latest cart data");
          this.getUserCartData();
        }

      },
      (err) => {

        this.buttonLoading = false;
        //console.log(err);
        this.landingComponentService.openSnackBarMessageIMP("Something went wrong! Plz try again later.");
      }
    );
  }

  foodcourtDetails = {
    minOrderAmount: 0,
    minDeliveryCharges: 0,
    deliveryCharges: 0,
  };
  getFoodcourtDetails() {
    this.showLoader = true;
    let orderFcID = this.landingComponentService.getOrderFoodCourtId();

    this.cartService
      .getFoodocurtByFoodcourtId(orderFcID)
      .subscribe((res: any) => {
        this.fcHasDelivery = res.data.hasDelivery;
        if (res.data.hasDelivery) {
          this.foodcourtDetails.minOrderAmount = res.data.minOrderAmount;
          this.foodcourtDetails.minDeliveryCharges =
            res.data.minDeliveryCharges;
          this.foodcourtDetails.deliveryCharges = res.data.deliveryCharges;
        }
        this.showLoader = false;
      }, (err) => {
        this.showLoader = false;
        this.cartService.openSnackBarMessage("Could not get delivery info of the foodcourt");
      });
  }

  changeOrderType() {
    if (!this.userId) {
      this.orderType = 'DINE_IN';
      this.ORDER_OPTIONS.forEach((type) => {
        if (type.value === 'DINE_IN')
          type.status = true;
        else
          type.status = false;
      })
      this.router.navigate(['auth']);
      return;
    }

    if (this.orderType === 'DELIVERY') {
      // this.billDetails.finalPrice +=
      //   this.billDetails.finalPrice > this.foodcourtDetails.minOrderAmount
      //     ? this.foodcourtDetails.deliveryCharges
      //     : this.foodcourtDetails.minDeliveryCharges;
      // //console.log(this.billDetails);
      // this.toggleUserPoints();
      if (this.deliveryAddress) {
        this.updateToDeliveryOption(this.deliveryAddress, true);
      } else {
        this.cartService.getSavedAddressesOfUser(this.userId).subscribe(
          (res: any) => {
            if (res.data && res.data.length > 0) {
              this.deliveryAddress = res.data[0];
            } else {
              this.deliveryAddress = null;
            }
          },
          (err) => {
            //console.log(err);
          },
          () => {
            this.updateToDeliveryOption(this.deliveryAddress, true);
          }
        );
      }
    } else {
      // if (
      //   this.fcHasDelivery &&
      //   this.billDetails.totalPrice !== this.billDetails.finalPrice
      // ) {
      //   this.billDetails.finalPrice -=
      //     this.billDetails.finalPrice > this.foodcourtDetails.minOrderAmount
      //       ? this.foodcourtDetails.deliveryCharges
      //       : this.foodcourtDetails.minDeliveryCharges;
      // }

      // this.toggleUserPoints();
      // this.deliveryAddress = null;
      this.updateToDeliveryOption(null, false);
    }
  }

  deliveryAddress = null;
  addNewAddress() {
    // this.router.navigate(['list-user-address']);
    const dialogRef = this.dialog.open(ListUserAddressComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: 'no-padding',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
        this.getUserPoints();
        this.updateToDeliveryOption(this.deliveryAddress, true);
        //console.log(data);
      }
    });
  }

  updateToDeliveryOption(address, isDelivery) {
    this.showLoader = true;
    let data = {
      address: address,
      delivery: isDelivery,
      orderType: this.orderType,
      userId: this.userId,
    };

    this.cartService.updateDeliveryOptionAndAddress(data).subscribe(
      (res: any) => {
        this.showLoader = false;

        this.getUserCartData();

        // //console.log(res);
      },
      (err) => {
        this.showLoader = false;
        this.dbStorageService.openSnackBarMessage(
          'Failed to change order type',
          'Close'
        );
        //console.log(err);
      }
    );
  }

  showPaymentStatusPopup(status, orderId?) {
    let PaymentStatusPopup = this.dialog.open(ConfirmationPopupComponent, {
      width: '350px',
      height: 'auto',
      maxWidth: '100vw',
      data: { type: status },
    });

    PaymentStatusPopup.afterClosed().subscribe((data) => {
      if (status === 'SUCCESS') {
        this.dbStorageService.setOrderId(orderId);

        if (this.landingComponentService.getIsOrderPreOrderType()) {
          this.router.navigate(['profile/user/order-summary/pre-order']);
        } else {
          this.router.navigate(['profile/user/order-summary/self-order']);
        }
      }
    });
  }

  openCouponDetails() {
    let couponDetail = this.dialog.open(ConfirmationPopupComponent, {
      width: '350px',
      height: 'auto',
      maxWidth: '100vw',
      data: { type: 'COUPON_DETAILS', couponId: this.billDetails.couponId },
      panelClass: 'no-padding',
    });
  }

  changePreOrdering() {
    this.router.navigate(["user/preordering"]);
  }
  showPreOrderMenuTiming() {
    this.togglePreOrderTimeSlotSelection = !this.togglePreOrderTimeSlotSelection;
  }

  reloadPage() {
    window.location.reload();
  }
}
