<div class="order-history-main-section">
  <div class="order-history__header">
    <div class="back-btn">
      <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToProfilePage()" />
    </div>
    <div class="heading-text">Your Orders</div>
    <div class="refresh-icon">
      <img src="../../../../../../assets/images/common/refresh-page.svg" alt="Refresh Icon" (click)="refreshPage()" />
    </div>
  </div>
  <div class="order-type-list-container">
    <ng-container *ngFor="let ORDER of ORDER_TYPE">
      <div class="order-type-container" [ngClass]="{'active': ORDER.status}" (click)="changeOrderListingType(ORDER)">{{
        ORDER.name | titlecase }}</div>
    </ng-container>
  </div>
  <div class="order-history-list-container">
    <ng-container *ngIf="!showLoader; else loaderScreen">
      <ng-container *ngIf="ordersList.length > 0; else emptyscreen">
        <!-- ordersList.length > 0 -->
        <ng-container *ngFor="let order of ordersList">
          <div class="order-container">
            <div class="order-header">
              <div class="order-id-sponsor">
                <div class="order-id"
                  *ngIf="selectedOrderType === 'SELF_ORDERS' || selectedOrderType === 'GUEST_ORDERS' || selectedOrderType === 'PRE_ORDERS'; else showOrderStatus">
                  Order Id: {{ order?.orderNum }}</div>
                <ng-template #showOrderStatus>
                  <div class="order-status">{{ order?.requestStatus }}</div>
                </ng-template>
                <div class="sponsor-text" *ngIf="
                    order.paymentDetails &&
                    order.paymentDetails['Employer Points'] &&
                    order.paymentDetails['Employer Points'] !== '0.0'
                  ">
                  <ng-container *ngIf="order.oneclickOrderType === 'BTC'; else showSponseredMeal">
                    Corporate Order
                  </ng-container>
                  <ng-template #showSponseredMeal>
                    Sponsored Meal
                  </ng-template>
                </div>
              </div>
              <div class="order-price">
                <!-- &#8377;{{ ( order?.totalPrice ? order?.totalPrice : order?.billDetails?.finalPrice ) / 100 | number: "1.1-2" }} -->
                &#8377;{{ ( order?.totalPrice ? order?.totalPrice : order?.billDetails?.finalPrice ) | number: "1.1-2"
                }}
              </div>
            </div>
            <hr />
            <div class="order-details" (click)="showOrderSummary(order)">
              <div class="small-heading">
                <div class="item">Items</div>
                <!-- <div class="order-status pre-order">{{ order?.orderStatus }}</div> -->
              </div>
              <div class="order-list-container">
                <div class="order-list">
                  <ng-container *ngFor="
                      let item of (order?.vendorOrderDetails?.length > 0 ? order?.vendorOrderDetails[0]?.items : order?.orderedItemsList);
                      index as i
                    ">
                    <div class="order__item" *ngIf="i < 4">
                      <div class="order-quantity">
                        {{ item?.itemQuantity }}x
                      </div>
                      <div class="order-name">{{ item?.itemName | titlecase }}</div>
                    </div>
                  </ng-container>
                </div>
                <div class="view-more">
                  <div class="view-more-text">...See More</div>
                </div>
              </div>
              <div class="order-date-status">
                <div class="order-date">
                  <div class="order-on">Ordered On</div>
                  <div class="date">
                    {{ order.createdTimestamp | date: "mediumDate":"UTC+0" }} at
                    {{ order.createdTimestamp | date: "shortTime":"UTC+0" }}
                  </div>
                </div>
                <!-- <div class="order-status">
                  <div [class]="order.orderdeliveryStatus">{{ order.orderdeliveryStatus }}</div>
                </div> -->
              </div>
            </div>
            <hr *ngIf="order.isOrderComplete" class="remove-margin" />
            <div *ngIf="order.isOrderComplete && !order.ratingPoints" class="rate-btn" (click)="goToRatingPage(order)">
              Rate Order
            </div>
            <div class="remaind-approval"
              *ngIf="(selectedOrderType === 'GUEST_REQUEST' || selectedOrderType === 'BTC_ORDERS') && order?.requestStatus === 'NEW'"
              (click)="sendApprovalNotificationToEmployer(order)">
              Remind Approval
            </div>

            <div class="rating-container" *ngIf="order.isOrderComplete && order.ratingPoints"
              (click)="showOverallRatingPopup(order)">
              <div class="rating-heading">Your rating for food</div>
              <div class="rating-value">
                <img src="../../../../../../../assets//images/home-page/star.svg" alt="Star Rating" /><span>{{
                  order.ratingPoints | number: "1.1-1" }}</span>
              </div>
            </div>
            <ng-container *ngIf="selectedOrderType === 'PRE_ORDERS'">
              <hr />
              <div class="schedule-date">
                <div class="order-on">Scheduled On</div>
                <div class="date">
                  {{ order?.preOrderDate | date }} at {{ order?.preOrderStartTime | date:"shortTime" }} - {{
                  order?.preOrderEndTime | date:"shortTime" }}
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="more-data-loader" *ngIf="showLoaderForMoreData">
          <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
        </div>
      </ng-container>
    </ng-container>
    <ng-template #loaderScreen>
      <div class="loader-img-container">
        <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
      </div>
    </ng-template>
    <ng-template #emptyscreen>
      <div class="empty-part">
        <div class="no-image">
          <img src="../../../../../../assets/images/common/empty-order-list.svg" alt="empty-image" />
        </div>
        <div class="empty-heading">Order list empty</div>
        <div class="empty-subtext">You can see your orders history here</div>
        <!-- <div class="emptyrules-text">the menu</div> -->
      </div>
    </ng-template>
  </div>
</div>