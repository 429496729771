import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalCartServiceService } from '../../../cart-services/global-cart-service.service';
import { LandingComponentService } from '../../../landing-component.service';
import { GuestOrderingService } from '../guest-ordering.service';

@Component({
  selector: 'app-guest-ordering-flow',
  templateUrl: './guest-ordering-flow.component.html',
  styleUrls: ['./guest-ordering-flow.component.scss'],
})
export class GuestOrderingFlowComponent implements OnInit {
  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private guestOrderingService: GuestOrderingService,
    private globalCartService: GlobalCartServiceService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userId = this.landingComponentService.getUserId();
    //console.log(this.userId);

    this.foodcourtId = this.landingComponentService.getFoodCourtId();
    if (this.userId) {
      this.shownSection = 'GUEST_ORDERING';
      this.getMenuList();
    } else {
      this.goToPreviousPage();
    }
  }

  shownSection: string = 'GUEST_ORDERING';
  userId: string;
  foodcourtId: string;
  showLoader: boolean = false;

  // -----------Guest ordering page related data and functions--------------- //
  menuDataForEmployees = [];

  getMenuList() {
    this.showLoader = true;
    this.guestOrderingService
      .getHomePageData(this.foodcourtId, this.userId)
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          let tempMenuDataForEmployees = res.data.map((element) => {
            return {
              ...element,
              quantity: 0,
            };
          });

          this.menuDataForEmployees = [];
          if (this.shownSection === 'GUEST_ORDER_PREVIEW') {
            tempMenuDataForEmployees.forEach((element) => {
              this.selectedItems.forEach((item) => {
                if (element.id === item.id) {
                  element.quantity = item.quantity;
                  this.menuDataForEmployees.push(element);
                }
              });
            });
          } else {
            if (this.selectedItems.length > 0) {
              tempMenuDataForEmployees.forEach((element) => {
                this.selectedItems.forEach((item) => {
                  if (element.id === item.id) {
                    element.quantity = item.quantity;
                  }
                });
                this.menuDataForEmployees.push(element);
              });
            } else {
              this.menuDataForEmployees = tempMenuDataForEmployees;
            }
          }
        },
        (err) => {
          this.showLoader = false;
          //console.log('Error while fetching menu data for employees');
        }
      );
  }

  itemIdForLoading: string = '';
  showRunTimeLoader: boolean = false;
  cartDetails = [];
  selectedItems = [];

  addItem(item, index) {
    if (this.shownSection === 'GUEST_ORDER_PREVIEW') return;

    this.showRunTimeLoader = true;
    this.itemIdForLoading = item.id;
    item.quantity += 1;
    this.selectedItems.push({ id: item.id, quantity: item.quantity });

    // setTimeout(() => {
    this.showRunTimeLoader = false;
    // }, 1500);
  }
  increaseItemCount(item) {
    if (this.shownSection === 'GUEST_ORDER_PREVIEW') return;

    this.showRunTimeLoader = true;
    this.itemIdForLoading = item.id;
    item.quantity += 1;

    this.selectedItems.forEach((ele) => {
      if (ele.id === item.id) {
        ele.quantity = item.quantity;
      }
    });

    // setTimeout(() => {
    this.showRunTimeLoader = false;
    // }, 1500);
  }
  decreaseItemCount(item) {
    if (this.shownSection === 'GUEST_ORDER_PREVIEW') return;

    this.showRunTimeLoader = true;
    this.itemIdForLoading = item.id;
    item.quantity -= 1;

    if (item.quantity <= 0) {
      this.selectedItems.forEach((ele, i) => {
        if (ele.id === item.id) {
          this.selectedItems.splice(i, 1);
        }
      });
      item.quantity = 0;
      this.showRunTimeLoader = false;
      return;
    }
    this.selectedItems.forEach((ele) => {
      if (ele.id === item.id) {
        ele.quantity = item.quantity;
      }
    });
    // setTimeout(() => {
    this.showRunTimeLoader = false;
    // }, 1500);
  }

  proceedToAddGuestPage() {
    // this.router.navigate([`guest-order/guest-details`]);
    this.shownSection = 'ADD_GUESTS';
  }
  // -----------End of guest ordering page related data and functions--------------- //

  // -----------Add guest details page related data and functions--------------- //

  guestCameReasonList = [
    { name: 'Came for Interview', value: 'Came for Interview' },
    { name: 'Came for Client Meeting', value: 'Came for Client Meeting' },
    { name: 'Came for Workshop', value: 'Came for Workshop' },
    { name: 'Other', value: 'Other' },
  ];

  guestDetailsFormValues = {
    noOfGuests: null,
    selectedReason: 'Came for Interview',
    otherReason: '',
    guestDetails: [
      {
        name: '',
        mobile: null,
        email: '',
      },
    ],
  };

  addAnotherGuestBlock() {
    this.guestDetailsFormValues.guestDetails.push({
      name: '',
      mobile: null,
      email: '',
    });
  }

  deleteGuestDetailBlock(index) {
    this.guestDetailsFormValues.guestDetails.splice(index, 1);
  }

  checkGuestDetailsFill() {
    for (let element of this.guestDetailsFormValues.guestDetails) {
      if (element.name === '' || element.mobile === null || element.mobile < 999999999) {
        return true;
      }
    }
    // if (this.guestDetailsFormValues.guestDetails.length <= this.guestDetailsFormValues.noOfGuests)
    //   return false;

    return false;
  }

  proceedToNextPage() {
    // this.router.navigate(['guest-order/']);
    if (
      this.guestDetailsFormValues.guestDetails.length >
      this.guestDetailsFormValues.noOfGuests
    ) {
      this.landingComponentService.openSnackBarMessage(
        'Check guest count',
        'Ok'
      );
      return;
    }
    this.getMenuList();
    this.shownSection = 'GUEST_ORDER_PREVIEW';
  }
  // -----------End of add guest details page related data and functions--------------- //

  goToPreviousPage() {
    // this.router.navigate(['guest-order/menu-list']);
    if (this.shownSection === 'GUEST_ORDERING') {
      const foodCourtName = this.landingComponentService
        .getFoodCourtName()
        ?.split(' ')
        .join('-');
      const siteName = this.landingComponentService
        .getSiteName()
        ?.split(' ')
        .join('-');
      this.router.navigate([`${foodCourtName}`]);
    } else if (this.shownSection === 'ADD_GUESTS') {
      this.shownSection = 'GUEST_ORDERING';
      this.getMenuList();
    } else if (this.shownSection === 'GUEST_ORDER_PREVIEW') {
      this.shownSection = 'ADD_GUESTS';
    }
  }

  editDetails(section: string) {
    if (section === 'GUEST_ORDERING') {
      this.shownSection = section;
      this.getMenuList();
    } else {
      this.shownSection = section;
    }
  }

  showBtnLoader: boolean = false;
  raiseGuestRequest() {
    //console.log('menuData', this.menuDataForEmployees);
    //console.log('guest', this.guestDetailsFormValues);

    this.showBtnLoader = true;

    const userName = this.landingComponentService.getUserName();
    const userMobile = this.landingComponentService.getUserMobile();
    const foodcourtId = this.menuDataForEmployees[0].foodCourtId;
    const employerId = this.menuDataForEmployees[0].employerMenu[0].employerId;

    const orderedItemsList = this.menuDataForEmployees.map((element) => {
      return {
        discountAmount: 0,
        finalBillPrice: 0,
        imgUrl: element.itemImageUrl,
        inStock: true,
        istharaMarginAmountPerItem: 0,
        istharaTotalMarginAmount: 0,
        itemCategory: element.itemCategory,
        itemId: element.itemId,
        itemMealCategory: element.mealCategory[0],
        itemName: element.name,
        itemQuantity: element.quantity,
        itemStatus: element.itemStatus,
        // mrpItem: element.mrpItem,
        markUpPrice: element.markUpPrice,
        markUpTaxPerItem: (element.markUpTaxValue ? element.markUpTaxValue : element.markUpTaxPerItem),
        markUpTotalPrice: element.markUpPrice * element.itemQuantity,
        markUpTotalTax: (element.markUpTaxValue ? element.markUpTaxValue : element.markUpTaxPerItem) * element.itemQuantity,
        taxCategory: element.taxCategory,
        price: element.price,
        rating: element.rating,
        taxPerItem: element.taxValue, // element.taxPercentPerItem
        totalPrice: element.price * element.quantity,
        // totalTax: 0.05 * element.quantity * element.price,
        totalTax: element.taxValue * element.quantity,
        veg: element.itemType === 'VEG' ? true : false,
        vendorId: element.vendorId,
        vendorMarginAmountPerItem: null,
        vendorName: element.vendorName,
        vendorTotalMarginAmount: null,
      };
    });

    const billDetails = {
      breakup: {
        'Taxes & charge': 0,
        'Item Total': 0,
        'Item Discount': null,
        'Delivery Charges': 0,
      },
      couponCode: null,
      couponId: null,
      employeePrice: 0,
      finalPrice: 0,
    };

    orderedItemsList.forEach((element) => {
      billDetails.breakup['Item Total'] += element.totalPrice;
      billDetails.breakup['Taxes & charge'] += element.totalTax;
    });

    billDetails.finalPrice =
      billDetails.breakup['Item Total'] + billDetails.breakup['Taxes & charge'];

    let data = {
      billDetails: billDetails,
      designation: null,
      employerId: employerId,
      fcId: foodcourtId,
      guestDetails: this.guestDetailsFormValues.guestDetails,
      imgUrl: null,
      mobile: userMobile,
      orderedItemsList: orderedItemsList,
      pointsToBeUsed: 0,
      reason: this.guestDetailsFormValues.selectedReason,
      rejectReason: null,
      staffId: null,
      staffName: null,
      userId: this.userId,
      userName: userName,
      requestStatus: "NEW",
      platform: "WEB"
    };

    //console.log(data);

    this.guestOrderingService.createGuestOrderRequest(data).subscribe(
      (res: any) => {
        //console.log(res);
        this.showBtnLoader = false;
        this.landingComponentService.openSnackBarMessage(
          'Order request raised',
          'Ok'
        );
        let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
      },
      (err) => {
        //console.log(err);
        this.showBtnLoader = false;
        this.landingComponentService.openSnackBarMessage(
          'Error while raising request',
          'Ok'
        );
      }
    );
  }
}
