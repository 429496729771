<section class="guest-ordering-qrcode-container">
    <div class="qr-code" *ngIf="showQR">
        <div class="heading-text">Guest Ordering QR for order no. {{ orderDetails.orderNum }}</div>
        <qrcode
            #qrcodeprint
            [qrdata]="sendingData"
            [errorCorrectionLevel]="'M'"
            [margin]="5"
            [scale]="50"
            [width]="300"
        ></qrcode>
    </div>
</section>