import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IndexDBService } from '../indexDB-storage/index-db.service';
import { GlobalCartApiService } from './global-cart-api.service';
import { GlobalCartLocalstorageService } from './global-cart-localstorage.service';
import { v1 as uuidv1 } from 'uuid';
import { LandingComponentService } from '../landing-component.service';
@Injectable({
  providedIn: 'root',
})
export class GlobalCartServiceService {
  constructor(
    private globalLSCartService: GlobalCartLocalstorageService,
    private globalCartServiceAPI: GlobalCartApiService,
    private landingComponentService: LandingComponentService,
    private storageService: IndexDBService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  private userCartData;
  private cartPreviewData = {
    noOfItems: 0,
    totalAmount: 0,
  };

  openSnackBarMessage(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  setUserCartData(userCartData) {
    this.userCartData = userCartData;
  }
  async getUserCartData() {
    await this.getAllCartData();
    return {
      userCartData: this.userCartData,
      cartPreviewData: this.cartPreviewData,
    };
  }
  getCartPreviewData() {
    return this.cartPreviewData;
  }

  // Using for adding items to indexedDB
  addItemToIndexedDB(item, isSameItemPresent = false, isPreOrdering = false) {
    // console.log("item from global cart", item);

    this.globalLSCartService.setOrderingFoodcourtHasBTC(this.globalLSCartService.getHasUserBTCAccessInCurrFC());
    this.landingComponentService.setIsRatingCompulsoryInOrderingFC(this.landingComponentService.getIsRatingCompulsoryInCurrentFC());

    // if same item is present then do not create new id
    if (!isSameItemPresent) {
      if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
        item.appItemId = uuidv1();
        item.itemQuantity = 1;
      } else {
        // for item without customization
        item.appItemId = item.itemId;
      }
    }

    if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
      item.customizedItemsPrice = 0;
      item.orderedItemCustomizations.forEach(element => {
        item.customizedItemsPrice += element.markUpPrice;
      });
    } else {
      item.customizedItemsPrice = 0;
    }

    let itemTodDB = {
      discountAmount: 0,
      finalBillPrice: 0,
      imgUrl: (item.itemImageUrl ? item.itemImageUrl : item.imgUrl),
      inStock: true,
      itemCategory: item.itemCategory,
      itemId: item.id || item.itemId,
      appItemId: item.appItemId,
      itemMealCategory: Array.isArray(item.mealCategory)
        ? item.mealCategory[0]
        : item.mealCategory, // need to change
      itemName: item.name || item.itemName,
      itemQuantity: item.itemQuantity,
      orderedItemCustomizations: item.orderedItemCustomizations,
      itemStatus: item.itemStatus,
      // mrpItem: item.mrpItem,
      price: item.markUpPrice,
      
      markUpPrice: item.markUpPrice,
      markUpTaxPerItem: (item.markUpTaxValue ? item.markUpTaxValue : item.markUpTaxPerItem),
      markUpTotalPrice: item.markUpPrice * item.itemQuantity,
      markUpTotalTax: (item.markUpTaxValue ? item.markUpTaxValue : item.markUpTaxPerItem) * item.itemQuantity,

      packagingCharge: item.packagingCharge,
      // totalPrice: (item.price + item.customizedItemsPrice) * item.itemQuantity,  // Total price should not send in payload
      veg: (item.itemType ? item.itemType === 'VEG' : item.veg),
      vendorId: item.vendorId,
      vendorName: item.vendorName,
      // taxPerItem: item.mrpItem ? '0' : '5',
      taxPerItem: (item?.taxPerItem ? item?.taxPerItem : item?.taxValue),
      taxPercentPerItem: item.taxPercentPerItem,
      taxCategory: item.taxCategory,

      // pre-ordering
      preOrder: false,
      preOrderDate: null,
      preOrderStartTime: null,
      preOrderEndTime: null

    };

    if (isPreOrdering) {
      let preOrderingEndDate = new Date(this.globalLSCartService.getPreOrderDetails());

      itemTodDB.preOrderDate = preOrderingEndDate,

        itemTodDB.preOrder = true;
      itemTodDB.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
        (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
        (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

      itemTodDB.preOrderStartTime = ((preOrderingEndDate.getHours() - 1) < 10 ? ("0" + (preOrderingEndDate.getHours() - 1)) : preOrderingEndDate.getHours() - 1) + ":" +
        (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
        (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

    }

    return new Promise((resolve, reject) => {
      this.storageService
        .addItemToCart(itemTodDB.appItemId, itemTodDB)
        .then((response: any) => {
          this.getAllCartData();
          resolve(this.cartPreviewData);
        })
        .catch((err: any) => {
          reject('Failed to add item to cart');
          // this.landingConsoleService.openSnackBarMessage(err);
        });
    })
  }

  removeItemFromCart(item) {

    return new Promise((resolve, reject) => {
      let userId = this.globalLSCartService.getUserId();

      if (userId) {

        this.globalCartServiceAPI
          .removeItemFromCartAPI(userId, item.itemId)
          .subscribe(
            (response: any) => {
              // resolve({
              //   cartPreviewData: this.cartPreviewData,
              //   userCartData: this.userCartData,
              // });
              this.storageService
                .removeItemFromCart(item.appItemId)
                .then((response) => {
                  this.getAllCartData();
                  // resolve({
                  //   cartPreviewData: this.cartPreviewData,
                  //   userCartData: this.userCartData,
                  // });
                  resolve(this.cartPreviewData);
                })
                .catch((err) => {
                  reject('Failed to remove the item');
                  // this.landingConsoleService.openSnackBarMessage(err);
                  //console.log(err);
                });

              // resolve(this.cartPreviewData);
            },
            (err) => {
              reject('Failed to remove the item');
            }
          );
      } else {
        this.storageService
          .removeItemFromCart(item.appItemId)
          .then((response) => {
            this.getAllCartData();
            // resolve({
            //   cartPreviewData: this.cartPreviewData,
            //   userCartData: this.userCartData,
            // });
            resolve(this.cartPreviewData);
          })
          .catch((err) => {
            reject('Failed to remove the item');
            // this.landingConsoleService.openSnackBarMessage(err);
            //console.log(err);
          });
      }
    });
  }

  // Function of below method is to update the cartPreview object and to get all the item of indexDB into userCartData
  async getAllCartData() {
    if (!window.navigator.onLine) {
      this.router.navigate(['no-internet']);
      return;
    }

    // Items details always taking from local db because items are always added to local db whether user login or not
    await this.storageService
      .getAllItemFromCart()
      .then((response: any) => {
        this.userCartData = response;

        this.cartPreviewData.totalAmount = 0;

        // If there is customized item present in local db then those items price will be added in this.cartPreviewData.totalAmount
        response?.forEach((item) => {
          if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
            item.customizedItemsPrice = 0;
            item.orderedItemCustomizations.forEach((element) => {
              item.customizedItemsPrice += element.price;
            })
          } else {
            item.customizedItemsPrice = 0;
          }

          // this.cartPreviewData.totalAmount +=
          // (item.itemQuantity * (item.customizedItemsPrice + item.price)) / 100;
          this.cartPreviewData.totalAmount +=
            (item.itemQuantity * (item.customizedItemsPrice + item.markUpPrice));
        });

        this.cartPreviewData.noOfItems = response?.length
          ? response?.length
          : 0;

        if (this.cartPreviewData.noOfItems === 0) {
          this.globalLSCartService.setOrderFoodCourtId(null);
          this.globalLSCartService.setOrderFoodCourtName(null);

          // this.globalLSCartService.setBTCRequestId(null);

          const isPreOrderingType = this.globalLSCartService.getIsOrderTypePreOrder();
          if (isPreOrderingType) {

            this.globalLSCartService.setIsOrderTypePreOrder(false);
            this.globalLSCartService.setPreOrderDetails(null);
            this.globalLSCartService.setPreOrderMenuType(null);
          }

        }

        return new Promise((resolve, reject) => {
          resolve("SUCCESS");
        })
      })
      .catch((err) => {
        return new Promise((resolve, reject) => {
          reject("FAILED");
        })
        //console.log('Failed to get all cart data');
        // this.landingConsoleService.openSnackBarMessage(err);
      });
    // }
  }

  // Check for same customization of a particular item in local db
  checkForCustomizedItemInIndexDb(item, source = 'NOT_CART') {
    return new Promise((resolve, reject) => {
      let customizedItemCount = 0;
      let isSameItemPresent = false;
      let matchedItem;

      if (item.orderedItemCustomizations && item.orderedItemCustomizations.length > 0) {
        this.storageService.getAllItemFromCart().then((response) => {

          return new Promise((resolve, reject) => {
            resolve(response);
          })
        })
          .then((response: any) => {
            response.forEach((dbItem) => {
              if (dbItem.itemId === (item.id || item.itemId)) {
                customizedItemCount++;

                if (dbItem.orderedItemCustomizations.length === item.orderedItemCustomizations.length) {
                  let dbItemMergeCategoryIds = '';
                  let itemMergeCategoryIds = '';
                  item.orderedItemCustomizations.forEach((customItems) => {
                    itemMergeCategoryIds += customItems.categoryId;
                  })
                  dbItem.orderedItemCustomizations.forEach((customItems) => {
                    dbItemMergeCategoryIds += customItems.categoryId;
                  })

                  if (itemMergeCategoryIds === dbItemMergeCategoryIds) {
                    isSameItemPresent = true;

                    if (source === 'CART') {
                      item.itemQuantity = item.showCustomQuantity + dbItem.itemQuantity;
                      matchedItem = dbItem;
                    } else {
                      item.appItemId = dbItem.appItemId;
                      item.itemQuantity = dbItem.itemQuantity + 1;
                    }
                  }
                }
              }
            })

            // console.log("item from cart increase", item);
            const result = {
              customizedItemCount: customizedItemCount,
              isSameItemPresent: isSameItemPresent,
              dbItem: matchedItem
            }

            resolve(result);
          })
      } else {
        item.itemQuantity = item.showCustomQuantity;
        // console.log("item from cart increase", item);
        const result = {
          customizedItemCount: customizedItemCount,
          isSameItemPresent: isSameItemPresent,
        }

        resolve(result);
      }
    })
  }
}
