<section class="recharge-points-section">
    <div class="recharge-points-header">
        <div class="recharge-points__img-container" (click)="goToPointsHistoryPage()">
            <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
        </div>
        <div class="recharge-points__heading-text">Recharge Points</div>
    </div>
    <div class="recharge-points-content-container">
        <div class="recharge-points__input-field">
            <!-- <mat-form-field appearance="outline"> -->
                <!-- <div class="ruppe-symbol">&#8377;</div> -->
                <!-- <mat-icon matPrefix>people</mat-icon> -->
                <input type="number" onkeydown="if(event.key==='.'){event.preventDefault();}" class="input-field" min="0" matInput placeholder="0.00" [(ngModel)]="paymentAmount" (ngModelChange)="removeSelectedFieldStatus()">
            <!-- </mat-form-field> -->
        </div>
        <div class="recharge-points__selection-field">
            <ng-container *ngFor="let point of rechargePointsSelectionList">
                <div class="recharge-points__field" (click)="selectPaymentAmountFromList(point)" [ngClass]="{'point-active': point.status}">+&#8377; {{ point.value }}</div>
            </ng-container>
        </div>
        <div class="recharge-points-payment-btn">
            <button [disabled]="paymentAmount <= 0 || paymentAmount === undefined" (click)="payWithCashFree()" *ngIf="!paymentLoader; else showPaymentLoader">Proceed To Add Money</button>
            <ng-template #showPaymentLoader>
                <button>
                    <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                </button>
            </ng-template>
        </div>
    </div>


    
    <!-- <form id="redirectForm" method="post" [action]="formDataView.paymentLink"> -->
        <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit

        production ===> https://www.cashfree.com/checkout/post/submit-->
        <!-- <input
          id="appId"
          type="hidden"
          name="appId"
          [value]="formDataView.appId"
        />
        <input type="hidden" name="orderId" [value]="formDataView.orderId" />
        <input
          type="hidden"
          name="orderAmount"
          [value]="formDataView.orderAmount"
        />
        <input type="hidden" name="orderCurrency" [value]="'INR'" />
        <input type="hidden" name="orderNote" [value]="'Order'" />
        <input
          type="hidden"
          name="customerName"
          [value]="formDataView.customerName"
        />
        <input
          type="hidden"
          name="customerEmail"
          [value]="formDataView.customerEmail"
        />
        <input
          type="hidden"
          name="customerPhone"
          [value]="formDataView.customerPhone"
        />
        <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
        <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
        <input type="hidden" name="signature" [value]="formDataView.signature" />
    </form> -->

</section>