<div class="filter-item-section">
    <ng-container *ngFor="let itemCategory of itemCategoryList | keyvalue">
        <div class="item-category-container">
            <div class="name" [ngClass]="{'selected': itemCategory.value.status}" (click)="showCusineItem(itemCategory)">
                <span>{{ itemCategory.key | titlecase }}</span>
                <img
                    src="../../../../../../assets/images/common/down_arrow.svg"
                    alt=""
                    class="arrow"
                    [ngClass]="{'rotate-arrow': !itemCategory.value.status}"
                    (click)="toggleDropdown(itemCategory.value)"
                />
            </div>
            <ng-container *ngIf="itemCategory.value.status">
                <ng-container *ngFor="let itemSubCategory of itemCategory.value.content | keyvalue">
                    <div class="sub-name" (click)="showSubCategoryItem(itemCategory)">
                        <span>{{ itemSubCategory.key | titlecase }} - ({{ itemSubCategory.value.count }})</span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>