import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FoodSubscriptionService {

  constructor(
    private http: HttpClient
  ) { }

  // openPlanSubscriptionPage : boolean = false;

  subscribePlanForUser(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/subscription/ist/payment/token`, data      
    )
  }

  subscribePlanForUserByIstharaPoints(data) {
    return this.http.post(environment.baseUrl + `/foodcourt/subscription/ist/payment/points`, data)
  }

  generateUserKOT(mealCategory: string, userPlanId: string, todayDate: string =  moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')) {
    return this.http.put(environment.baseUrl + `/foodcourt/subscription/ist/plan/kot/generate?category=${mealCategory}&strDate=${todayDate}&userPlanId=${userPlanId}` , {})
  }

  getUserPoints(userId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`)
  }


}
