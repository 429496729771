import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteFoodcourtService {

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
  ) { }

  openSnackBarMessage(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 2 * 1000,
    });
  }

  getAllSites() {
    return this.http.get(environment.baseUrl + '/foodcourt/site/v1/ist/all/sites');
  }
  getSitesByLocation() {

  }
  getFoodCourtBySiteId(siteId) {
    return this.http.get(environment.baseUrl + `/foodcourt/foodCourt/ist/list/${siteId}/foodCourt`);
  }
}
