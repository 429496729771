import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { IndexDBService } from '../../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../../landing-component.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { OtherComponentService } from '../other-component.service';
import { SuccessfullySubscribedPageComponent } from '../successfully-subscribed-page/successfully-subscribed-page.component';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _otherComponentService: OtherComponentService,
    private dialog: MatDialog,
    private dbStorageService: PopupDbService,
    private landingComponentService: LandingComponentService,
    private IndexDbStorageService: IndexDBService
  ) {}

  ngOnInit(): void {
    this.paymentType = this.dbStorageService.getPaymentFor();

    this.getScreenSize();

    if (this.paymentType) {
      this.userName = this.dbStorageService.getUserName();
      this.foodCourtName = this.landingComponentService
        .getFoodCourtName()
        ?.split(' ')
        .join('-');
      this.siteName = this.landingComponentService
        .getSiteName()
        ?.split(' ')
        .join('-');

      this.route.queryParams.subscribe((data) => {
        if (data?.order_id) {
          this.showLoader = true;
          this.order_Id = data?.order_id;
          // this.order_token = data.order_token;
          this.showTextWhileLoading();
        }
      });

      const paymentType = this.dbStorageService.getPaymentFor();

      if (paymentType === 'DELIVERY_PAYMENT') {
        const addressId = this.dbStorageService.getAddressId();
        this.userId = this.dbStorageService.getUserId();

        this._otherComponentService
          .getSavedAddressesOfUser(this.userId)
          .subscribe((res: any) => {
            res.data.forEach((element) => {
              if (element.addressId === addressId) {
                this.deliveryAddress = element;
              }
            });
          });
      }
    } else {
      let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
      this.router.navigate([`${foodCourtName}`]);
    }
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  status = null;
  showLoader: boolean = false;
  order_Id: string; // orderId for cashfree only
  order_token: string;
  userName: string;
  siteName: string;
  foodCourtName: string;
  deliveryAddress: any;
  userId: string;

  noOfAPICalls: number = 0;

  loadingText: string;

  showTextWhileLoading() {
    setTimeout(() => {
      this.loadingText = `Hey ${this.userName}, Glad to see you!!`;
      setTimeout(() => {
        if (this.paymentType === "WALLET_PAYMENT") {
          this.loadingText = `Please wait, while we check for your payment status`;
        } else {
          this.loadingText = `Please wait, while we check for your order status`;
        }
        setTimeout(() => {
          this.loadingText = `Hold on! We are just there`;
          setTimeout(() => {
            this.loadingText = `Here we go!!!`;
            this.checkForOrderStatus();
          }, 1500);
        }, 1500);
      }, 1500);
    }, 1500);
  }

  paymentType: string;
  
  checkForOrderStatus() {
    this.paymentType = this.dbStorageService.getPaymentFor();
    this.dbStorageService.removeFromLocalStorage('paymentFor');
    this.dbStorageService.removeFromLocalStorage('addressId');

    // let intervalRef = setInterval(()=> {
      if (this.paymentType === 'CART_PAYMENT') 
        this.checkOrderStatusOfCartPayment();
      else if (this.paymentType === 'PRE_ORDER_PAYMENT')
        this.checkOrderStatusOfCartPayment();
      else if (this.paymentType === 'DELIVERY_PAYMENT' || this.paymentType === 'SELF_PICKUP_PAYMENT')
        this.checkOrderStatusOfDeliveryPayment();
      else if (this.paymentType === 'ONE_CLICK_PAYMENT')
        this.checkOrderStatusOfOnClickPayment();
      else if (this.paymentType === 'WALLET_PAYMENT')
        this.checkWalletPaymentStatus();
      else if (this.paymentType === 'FOOD_SUBSCRIPTION')
        this.checkFoodSubscriptionStatus();
    
    //   this.noOfAPICalls++;
    // }, 3000);
  }

  checkWalletPaymentStatus() {
    this._otherComponentService.getWalletPaymentStatus(this.order_Id).subscribe((response: any)=> {
      this.status = 'SUCCESS';
      this.openPaymentStatusPopup(this.status);
      if (this.scrWidth < 600) {
        this.router.navigate(['profile/user/points']);
      } else {
        this.router.navigate(['account/user']);
      }
    }, (err)=> {
      if (this.noOfAPICalls > 20) {
        if (err.error.errorCode === 100) {
          this.status = 'FAILURE';
          this.openPaymentStatusPopup(this.status);
        } else {
          this.dbStorageService.openSnackBarMessage(
            'Failed to get order status',
            'Close'
          );
        }
        
      } else {
        this.noOfAPICalls++;
        setTimeout(()=> {
          this.checkWalletPaymentStatus();
        }, 2000);
      }
    })
  }

  checkFoodSubscriptionStatus() {
    
    let userId = this.landingComponentService.getUserId();
    let planId = this.landingComponentService.getUserSubscriptionPlanId();
    let subscriptionDate = this.landingComponentService.getSubscriptionPlanOrderingDate();

    let data = {
      // "bedName": "string",
      // "cf_payment_id": "string",
      "endDate": subscriptionDate.endDate,
      "orderId": this.order_Id,
      "paymentFor": "ORDER",
      // "paymentSessionId": "string",
      // "payment_amount": "string",
      // "payment_message": "string",
      // "payment_method": "string",
      // "payment_status": "string",
      // "payment_time": "string",
      "planId": planId,
      "pointsUsed": 0,
      // "referenceId": "string",
      // "signature": "string",
      "startDate": subscriptionDate.startDate,
      "userId": userId
    }
    this._otherComponentService.checkUserFoodSubscriptionPaymentStatus(data).subscribe(()=> {
      
      const dialogRef = this.dialog.open(SuccessfullySubscribedPageComponent);
      dialogRef.afterClosed().subscribe(()=> {
          
        this.router.navigate(['profile/user/user-foodsubscription-list']);
      })
    }, (err)=> {

    }, ()=> {
      this.landingComponentService.setUserSubscriptionPlanId(null);
      this.landingComponentService.setSubscriptionPlanOrderingDate(null, null)
    })
  }

  checkOrderStatusOfCartPayment() {
    this._otherComponentService
      .getOrderPaymentStatusForCartPayment(this.order_Id)
      .subscribe(
        (res: any) => {
          //console.log('order response', res);
          if (res.data.paymentStatus && res.data.paymentStatus === 'SUCCESS') {
            this.IndexDbStorageService.clearAllItemsFromCart()
                .then((result) => {
                  this.status = 'SUCCESS';
                  this.openPaymentStatusPopup(this.status);
                  if (this.paymentType === "CART_PAYMENT") {
                    this.router.navigate(['profile/user/order-summary/self-order']);
                  } else {
                    this.router.navigate(['profile/user/order-summary/pre-order']);
                  }
                })
          }
          // else if (res.data.paymentStatus === 'ACTIVE') {
          //   this.status = 'ACTIVE';
          //   this.router.navigate([
          //     `cart/${this.siteName}/${this.foodCourtName}`,
          //   ]);
          // }

          // if (this.status === 'SUCCESS') {
          // this.dbStorageService.setOrderId(this.order_Id);
          // this.router.navigate(['profile/user/order-history']);
          // }
        },
        (err) => {
          //console.log(err);
          
          // if (this.noOfAPICalls < 10) {
          //   this.noOfAPICalls++;
          //   this.checkOrderStatusOfCartPayment();
          // }
          if (this.noOfAPICalls > 20) {
            if (err.error.errorCode === 100) {
              this.status = 'FAILURE';
              this.openPaymentStatusPopup(this.status);
            } else {
              this.dbStorageService.openSnackBarMessage(
                'Failed to get order status',
                'Close'
              );
            }

            this.router.navigate([`cart/${this.foodCourtName}`]);    
          } else {
            this.noOfAPICalls++;
            setTimeout(()=> {
                this.checkOrderStatusOfCartPayment();
            }, 2000);
          }
        }
      );
  }

  checkOrderStatusOfDeliveryPayment() {
    const fcOrderId = this.dbStorageService.getOrderId();
    let pointsToBeDeducted = this.landingComponentService.getPointsToBeDeducted();

    this._otherComponentService
      .getOrderPaymentStatusForDeliveryPayment(
        this.order_Id,
        // this.order_token,
        fcOrderId,
        this.deliveryAddress ? this.deliveryAddress : {},
        this.deliveryAddress ? "DELIVERY" : "PICK_UP",
        (pointsToBeDeducted ? pointsToBeDeducted : 0 )
      )
      .subscribe(
        (res: any) => {
          //console.log('order response', res);

          if (res.data.paymentStatus && res.data.paymentStatus === 'SUCCESS') {
            
            this.IndexDbStorageService.clearAllItemsFromCart()
                .then((result) => {
                  this.status = 'SUCCESS';
                  this.openPaymentStatusPopup(this.status);
                  this.router.navigate(['profile/user/order-summary/self-order']);
            })
          }
          
        },
        (err) => {
          //console.log(err);
          if (this.noOfAPICalls > 20) {
            if (err.error.errorCode === 100) {
              this.status = 'FAILURE';
              this.openPaymentStatusPopup(this.status);
            } else {
              this.dbStorageService.openSnackBarMessage(
                'Failed to get order status',
                'Close'
              );
            }
            this.router.navigate(['profile/user/order-summary/self-order']);
          }  else {
            this.noOfAPICalls++;
            setTimeout(()=> {
              this.checkOrderStatusOfDeliveryPayment();
            }, 2000);
          }
        }
      );
  }

  checkOrderStatusOfOnClickPayment() {
    this._otherComponentService
      .getOrderPaymentStatusForOneClick(
        this.order_Id
        // this.order_token
      )
      .subscribe(
        (res: any) => {
          // console.log('order response', res);

          if (res.data.paymentStatus && res.data.paymentStatus === 'SUCCESS') {
            this.status = 'SUCCESS';
            this.openPaymentStatusPopup(this.status);
            this.router.navigate(['profile/user/order-summary/self-order']);
          }
        },
        (err) => {
          if (this.noOfAPICalls > 20) {
            if (err.error.errorCode === 100) {
              this.status = 'FAILURE';
              this.openPaymentStatusPopup(this.status);
            } else {
              this.dbStorageService.openSnackBarMessage(
                'Failed to get order status',
                'Close'
              );
            }
            let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
            
            this.router.navigate([`${foodCourtName}`]);
          } else {
            this.noOfAPICalls++;
            setTimeout(()=> {
              this.checkOrderStatusOfOnClickPayment();
            }, 2000);
          } 
        }
      );
  }

  openPaymentStatusPopup(status) {
    let PaymentStatusPopup = this.dialog.open(ConfirmationPopupComponent, {
      width: '350px',
      height: 'auto',
      maxWidth: '100vw',
      data: { type: status, paymentType: this.paymentType },
    });
  }
}
