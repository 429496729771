
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { PreorderConfirmationPopupComponent } from './preorder-confirmation-popup/preorder-confirmation-popup.component';

@Component({
  selector: 'app-food-preorder',
  templateUrl: './food-preorder.component.html',
  styleUrls: ['./food-preorder.component.scss']
})
export class FoodPreorderComponent implements OnInit {

  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService,
    private indexDBService: IndexDBService,
    private dialog: MatDialog
  ) { }

  // Preordering Date
  preOrderingDays = [
    { name: "Today", value: "TODAY", status: false },
    { name: "Tommorow", value: "TOMMOROW", status: false },
    { name: "Day After Tommorow", value: "DAT", status: false },
  ];
  preOrderingTimeSlot = [];   // preordering timeslot based on Today, Tommorow and Day after tommorow

  // meal date and timing BREAKFAST: 07AM - 11AM, LUNCH: 11AM - 04PM, SNACKS: 04PM - 07PM, DINNER: 07PM - 11PM
  mealTypeList = [
    { name: "Breakfast", value: "breakfast", startTime: "7", endTime: "11", status: false, isBlocked: true},
    { name: "Lunch", value: "lunch", startTime: "11", endTime: "15", status: false, isBlocked: true},
    { name: "Snacks", value: "snacks", startTime: "15", endTime: "18", status: false, isBlocked: true},
    { name: "Dinner", value: "dinner", startTime: "18", endTime: "23", status: false, isBlocked: true},
  ];
  selectedPreOrderingDate;  // preordering date selected by user i.e. Today, Tommorow and Day after tommorow
  userId;

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit(): void {

    this.getScreenSize();
    // get if user have already selected preordering items
    this.selectedPreOrderingDate = this.landingComponentService.getPreOrderDetails();
    this.userId = this.landingComponentService.getUserId();
    
    
    // if user have already added item in preordering
    if (this.selectedPreOrderingDate) {
      
      let preOrderingDate = (new Date(this.selectedPreOrderingDate)).setHours(0, 0, 0, 0);
      let todayDateTime = (new Date()).setHours(0, 0, 0, 0);
      let tommorowDateTime = todayDateTime + (86400000); // 1 day = 8.64*10e^4

      if (preOrderingDate === todayDateTime) {    // check if selectedPreorderingDate is same as todayDate
        this.selectPreOrderingDay(this.preOrderingDays[0]);
      } else if (preOrderingDate === tommorowDateTime){   // check if selectedPreorderingDate is same as tommorow's date
        this.selectPreOrderingDay(this.preOrderingDays[1]);
      } else {
        this.selectPreOrderingDay(this.preOrderingDays[2]);
      }
    }
    else {
      this.selectPreOrderingDay(this.preOrderingDays[0]);
    }
  }

  goToHomePage() {
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    if(this.scrWidth < 600){
      this.router.navigate([`${foodCourtName}`]);
    }
    else{
      this.landingComponentService.sidePanelContent.next("CART");
    }
    
  }


  generateDaySpecificTimeSlot(hours) {

    let hasSomeTimeSlotToShow = false;
    let todayDateTime = new Date();
    this.preOrderingTimeSlot = [];    

    for (let i=0; i<=15; i++) {
      let startTime = new Date();
      let endTime = new Date();
      
      // ------------------------ MEATHOD 1 ---------------------------

      // startTime.setTime( startTime.getTime() + ((hours - todayDateTime.getHours()) + 7+i)*60*60*1000 );
      // endTime.setTime( endTime.getTime() + ((hours - todayDateTime.getHours()) + 8+i)*60*60*1000 );
      
      // startTime.setMinutes(0);
      // startTime.setSeconds(0);
      // startTime.setMilliseconds(0);

      // endTime.setMinutes(0);
      // endTime.setSeconds(0);
      // endTime.setMilliseconds(0);

      // ----------------------- MEATHOD 2 -------------------------------
      startTime.setHours(0)
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);

      endTime.setHours(0);
      endTime.setMinutes(0);
      endTime.setSeconds(0);
      endTime.setMilliseconds(0);
      
      startTime.setTime( startTime.getTime() + ((hours + 7+i)*60*60*1000) );
      endTime.setTime( endTime.getTime() + ((hours  + 8+i)*60*60*1000) );
      
      this.preOrderingTimeSlot.push({
        startTime: startTime,
        endTime: endTime,
        isBlocked: ((startTime.getTime()) < (todayDateTime.getTime() + 2*1.8*1000000)), // add 1 hr time difference
        status: false
      });
      
      if (!this.preOrderingTimeSlot[i].isBlocked) {
        hasSomeTimeSlotToShow = true;
      }
      // startTime < (new Date(todayDateTime).setTime(new Date(todayDateTime).getTime() + 0.3*60*60*1000))
    }

    if (!hasSomeTimeSlotToShow) {
      this.mealTypeList.forEach((meal)=> {
        meal.isBlocked = true;
      })
    }

    // if preordering date is available select the preselected timeslot
    if (this.selectedPreOrderingDate) {

      let isTimeSlotMatch = false;
      let preOrderEndTime = new Date(this.selectedPreOrderingDate).setMilliseconds(0);

      for (let i=0; i<this.preOrderingTimeSlot.length; i++) {
        let endTime = this.preOrderingTimeSlot[i].endTime.setMilliseconds(0);
        if (endTime === preOrderEndTime) {
          isTimeSlotMatch = true;
          this.selectTimeSlot(this.preOrderingTimeSlot[i]);
          break;
        }
      }

      if (!isTimeSlotMatch) {
        for (let i=0; i<this.preOrderingTimeSlot.length; i++) {
          if (!this.preOrderingTimeSlot[i].isBlocked) {
            this.selectTimeSlot(this.preOrderingTimeSlot[i]);
            break;
          }
        }
      }

    } else {

      for (let i=0; i<this.preOrderingTimeSlot.length; i++) {
        if (!this.preOrderingTimeSlot[i].isBlocked) {
          this.selectTimeSlot(this.preOrderingTimeSlot[i]);
          break;
        }
      }
    }
  }

  // timeslot is created for a given day i.e. today, tommorow or day after tommorow
  selectPreOrderingDay(selectedDay) {

    let preOrderingDay = this.landingComponentService.getPreOrderDetails();
    // console.log("PREORDERING : ",new Date(preOrderingDay))
    // console.log("SELECTED : ", selectedDay)

    let hours = 0;
    if (selectedDay.value === 'TOMMOROW')
      hours = 24;
    else if (selectedDay.value === 'DAT')
      hours = 48;

    
    let selectedDateTime = (new Date().setHours(0, 0, 0, 0) + (hours*60*60*1000));

    if (new Date(preOrderingDay).setHours(0, 0, 0, 0) !== selectedDateTime && preOrderingDay) {
      const dialogRef = this.dialog.open(PreorderConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data)=> {
        if (data) {
          
          this.preOrderingDays.forEach((day)=> {
            day.status = false;
          })
          selectedDay.status = true;
          this.generateDaySpecificTimeSlot(hours);
        }
      })
    } else {
      
      this.preOrderingDays.forEach((day)=> {
        day.status = false;
      })
      selectedDay.status = true;
      this.generateDaySpecificTimeSlot(hours);
    }
    
  }
  selectTimeSlot(selectedTimeSlot) {

    let preOrderEndTime = this.landingComponentService.getPreOrderDetails()
    
    if (new Date(preOrderEndTime).getTime() !== selectedTimeSlot.endTime.getTime() && preOrderEndTime) {

      const dialogRef = this.dialog.open(PreorderConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data)=> {
        if (data) {

          this.preOrderingTimeSlot.forEach((slot)=> {
            if (selectedTimeSlot.endTime === slot.endTime)
              slot.status = true;
            else
              slot.status = false;
          })
        
          this.mealTypeList.forEach((meal)=> {
            if ((selectedTimeSlot.startTime).getHours() >= Number(meal.startTime) && (selectedTimeSlot.endTime).getHours() <= Number(meal.endTime))
              meal.isBlocked = false;
            else
              meal.isBlocked = true;
          });

          this.landingComponentService.preOrderDetails = selectedTimeSlot.endTime;
      
        }
      })
    } else {
      this.preOrderingTimeSlot.forEach((slot)=> {
        if (selectedTimeSlot.endTime === slot.endTime)
          slot.status = true;
        else
          slot.status = false;
      })
    
      this.mealTypeList.forEach((meal)=> {
        if ((selectedTimeSlot.startTime).getHours() >= Number(meal.startTime) && (selectedTimeSlot.endTime).getHours() <= Number(meal.endTime))
          meal.isBlocked = false;
        else
          meal.isBlocked = true;
      });

      this.landingComponentService.preOrderDetails = selectedTimeSlot.endTime;
  
    }
    // this.mealTypeList.forEach((meal)=> {
    //   let time = new Date(selectedTimeSlot.endTime);
    //   if (((new Date()).setHours(0, 0, 0, 0) < time.setHours(0, 0, 0, 0)) ? true : (new Date()).getHours() < Number(meal.endTime))
    //     meal.isBlocked = false;
    //   else 
    //     meal.isBlocked = true;
    // })

    // this.landingComponentService.setPreOrderDetails(selectedTimeSlot.endTime);
  }
  goToMealsPage(meal) {

    if (!meal.isBlocked) {

      let siteName = this.landingComponentService.getSiteName()?.split(' ')?.join('-').toLowerCase();;
      let fcName = this.landingComponentService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();

      const preOrderMealType = this.landingComponentService.getPreOrderMenuType();
      
      if (preOrderMealType !== meal.value && preOrderMealType) {
        
        // this.landingComponentService.setPreOrderMenuType(meal.value);
        const dialogRef = this.dialog.open(PreorderConfirmationPopupComponent, {
          width: '380px',
          height: 'auto',
          data: { type: 'PREORDER_CONFIRMATION' },
        });
        dialogRef.afterClosed().subscribe((data)=> {
          if (data) {
            if (this.userId) {
              this.landingComponentService.clearCartDataAPI(this.userId).subscribe(()=> {
                this.indexDBService.clearAllItemsFromCart();
                this.landingComponentService.preOrderMenuType = meal.value;
                this.router.navigate([`choose-meal/${fcName}/${meal.value.toUpperCase()}/true`]);
              }, (err)=> {
                this.landingComponentService.openSnackBarMessage("Failed to clear cart item..");
              });
            } else {
              this.indexDBService.clearAllItemsFromCart();
              this.landingComponentService.preOrderMenuType = meal.value;
              this.router.navigate([`choose-meal/${fcName}/${meal.value.toUpperCase()}/true`]);
              this.landingComponentService.sidePanelContent.next("CART");
            }
          }
        })
      } else {
        
        this.landingComponentService.preOrderMenuType = meal.value;
        this.router.navigate([`choose-meal/${fcName}/${meal.value.toUpperCase()}/true`]);
        this.landingComponentService.sidePanelContent.next("CART");
      }
    }
  }

}
