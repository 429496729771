import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../authentication/services/notification.service';
import { GlobalCartServiceService } from '../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../landing-component.service';
import { ConfirmationPopupComponent } from '../other-components/confirmation-popup/confirmation-popup.component';
import { CustomizeItemBottomsheetComponent } from '../other-components/customize-item-bottomsheet/customize-item-bottomsheet.component';
import { DiscountDetailsBottomsheetComponent } from '../other-components/discount-details-bottomsheet/discount-details-bottomsheet.component';
import { DisplayQrCodeComponent } from '../other-components/display-qr-code/display-qr-code.component';
import { OneClickPaymentComponent } from '../other-components/one-click-payment/one-click-payment.component';
import { HomePageService } from './home-page.service';
import { MatDrawer } from '@angular/material/sidenav/drawer';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss', './mq-home-page.component.scss'],
})
export class HomePageComponent implements OnInit {

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 2500,
    "dots": true,
    "arrows": true,
    "pauseOnHover": false
  };
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private landingConsoleService: LandingComponentService,
    private homePageService: HomePageService,
    private globalCartService: GlobalCartServiceService,
    private notificationService: NotificationService,
    private indexDbService: IndexDBService,
    private dialog: MatDialog,
    private platform: Platform
  ) { }

  discountTypeList = [
    { name: "FIXED_AMOUNT" },
    { name: "PERCENTAGE" },
    { name: "FREEBIES" },
  ]

  userId: string = '';
  siteId: string = '';
  siteName: string = '';
  foodCourtId: string = '';
  foodCourtDetails: any;
  showLoader: boolean = true;
  foodCourtName: string = '';
  showRunTimeLoader: boolean = false;
  showEmptyScreen: boolean = false;
  showErrorOnAPI: boolean = false;

  cartDetails = {
    noOfItems: 0,
    totalAmount: 0,
  };
  userCartData;
  showCartDetails: boolean = false;
  isIntoPreOrderingFlow: boolean = false;

  foodCourtHasPreOrdering: boolean = false;

  ngOnInit(): void {

    this.getScreenSize();
    // get the useId to test if user is loggedIn or not
    this.userId = this.landingConsoleService.getUserId();
    this.isIntoPreOrderingFlow = this.landingConsoleService.getIsOrderPreOrderType();

    if (this.scrWidth > 600) {
      // show the cart items of user in right-side panel for tablet view
      this.landingConsoleService.itemQuantityChange.next(true);
      this.landingConsoleService.itemQuantityChangeFromCart.subscribe((response) => {

        this.globalCartService
          .getUserCartData()
          .then((response: any) => {
            this.userCartData = response.userCartData;
            this.cartDetails = response.cartPreviewData;
            this.showLoader = false;
            // this.landingConsoleService.itemQuantityChange.next(true);
            this.getHomePageData();
          })
          .catch((err) => {
            //console.log(err);
          });
      })
    }

    this.activeRouter.params.subscribe((response: any) => {

      // check if fooodcourt has preordering or not
      this.foodCourtHasPreOrdering = this.landingConsoleService.getFoodcourtHasPreOrdering();

      // whenever we change foodcourt in landingcomponent we gets here

      // below local storage data were stored in landing component service
      this.foodCourtId = this.landingConsoleService.getFoodCourtId();
      this.foodCourtName = this.landingConsoleService.getFoodCourtName();
      this.siteId = this.landingConsoleService.getSiteId();
      this.siteName = this.landingConsoleService.getSiteName();

      // In All back buttons we are redirecting to 'home', since we are not passing site and foodcourt name
      // since we need to change the url i.e. url need to containe site and foodcourt name, we need below condition
      if (Object.keys(response).length === 0) {

        let siteNameUrl = this.siteName
          ? this.siteName.split(' ').join('-').toLowerCase()
          : ' ';
        let foodcourtNameUrl = this.foodCourtName
          ? this.foodCourtName.split(' ').join('-').toLowerCase()
          : ' ';
        this.router.navigate([
          `/${foodcourtNameUrl}`,
        ]);
      } else {
        this.showLoader = true;
        this.globalCartService
          .getUserCartData()
          .then((response: any) => {
            this.userCartData = response.userCartData;
            this.cartDetails = response.cartPreviewData;
            this.showLoader = false;
            this.getHomePageData();
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    });
  }

  scrHeight: number;
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  redirectToVendorDiscountPage(discount) {
    this.landingConsoleService.setDiscountType(discount.name);
    let discountType = discount.name.split(" ").join("-").toLowerCase();
    this.router.navigate([`discount/${discountType}`]);
  }

  // Below is the code for Dots shown in discounts
  carouselIndex: number = 0;
  discountCounts: number = 0;
  afterChange(event) {
    this.carouselIndex++;
    this.carouselIndex = (this.carouselIndex % this.discountCounts);
  }
  // ----------- Discounts ---------------

  getFoodCourtDetails() {
    let foodCourtDetails;
    this.homePageService.getFoodCourtDetails(this.foodCourtId).subscribe(
      (response: any) => {
        foodCourtDetails = response.data;
        let siteNameUrl = foodCourtDetails.siteName
          ? foodCourtDetails.siteName.split(' ').join('-').toLowerCase()
          : ' ';
        let foodCourtNameUrl = foodCourtDetails.foodCourtName
          ? foodCourtDetails.foodCourtName.split(' ').join('-').toLowerCase()
          : ' ';
        this.router.navigate([
          `/${foodCourtNameUrl}`,
        ]);
        // this.router.navigate(["home"], {queryParams: {site: , foodcourt: }});
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  // Get All Data of HomeScreen then check from the items stored in indexDB when both item matches show the count
  getHomePageData() {

    this.isIntoPreOrderingFlow = this.landingConsoleService.getIsOrderPreOrderType();

    this.showLoader = true;
    this.homePageService
      .getHomePageData(this.foodCourtId, this.userId)
      .subscribe(
        (response: any) => {
          this.foodCourtDetails = response.data;

          this.discountCounts = this.foodCourtDetails?.discountHomeResponses.length;

          this.foodCourtDetails.trendingtodayList.forEach((item) => {
            item.showCustomQuantity = 0;
            item.itemId = item.id;
            this.userCartData?.forEach((cartItem) => {
              if (cartItem.itemId === item.id) {
                item.showCustomQuantity += cartItem.itemQuantity;
                item.appItemId = cartItem.appItemId;
                item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            });

            let url = item.itemImageUrl;
            let pattern = /^((http|https|ftp):\/\/)/;
            if (pattern.test(url)) {
              item.itemImageUrl = url;
            } else {
              item.itemImageUrl = '';
            }
          });

          this.foodCourtDetails.quickBitesList.forEach((item) => {
            item.showCustomQuantity = 0;
            item.itemId = item.id;
            this.userCartData?.forEach((cartItem) => {
              if (cartItem.itemId === item.id) {
                item.showCustomQuantity += cartItem.itemQuantity;
                item.appItemId = cartItem.appItemId;
                item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            });

            let url = item.itemImageUrl;
            let pattern = /^((http|https|ftp):\/\/)/;
            if (pattern.test(url)) {
              item.itemImageUrl = url;
            } else {
              item.itemImageUrl = '';
            }
          });

          this.MEALS = this.foodCourtDetails?.itemCategory?.map((element) => {
            if (!element) return null;
            return {
              name: element?.toLowerCase(),
              servingType: 'Serves all day',
              value: element?.toUpperCase(),
            };
          });

          if (
            this.MEALS.length === 0 &&
            this.foodCourtDetails.quickBitesList.length === 0 &&
            this.foodCourtDetails.trendingtodayList.length === 0 &&
            this.foodCourtDetails.vendordata.length === 0
          )
            this.showEmptyScreen = true;
          else this.showEmptyScreen = false;

          this.showLoader = false;

          let discountList = this.foodCourtDetails.availableDiscountType;
          this.discountTypeList.forEach((discount) => {

            if (discountList?.includes(discount.name))
              discount['img'] = discount.name + "_" + discountList.length;
            else
              discount['img'] = null;
          })
          // this.discounttypecheck();

        },
        (err) => {
          this.showLoader = false;
          // this.landingConsoleService.openSnackBarMessageIMP("Some error occured while getting the items. Plz try again in sometime..");
          this.showErrorOnAPI = true;
          //console.log(err);
        }
      );
  }

  itemIdForLoading: string = '';

  addItemAfterMultipleFoodcourtCheck(item) {
    // add only when item belong to same food court condition check

    // Below Code Check if user cart already contains preOrdering Items or not, if present then show popup that you need to clear items to continue
    const isPreOrderingType = this.landingConsoleService.getIsOrderPreOrderType();
    if (isPreOrderingType) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data) => {
        this.globalCartService
          .getUserCartData()
          .then((response: any) => {
            this.userCartData = response.userCartData;
            this.cartDetails = response.cartPreviewData;
            this.showLoader = false;
            this.getHomePageData();
          })
      })
    }
    // ---------------- Preordering Checks --------------
    else {

      let currentOrderFoodCourtId =
        this.landingConsoleService.getOrderFoodCourtId();

      // User cannot order item from multiple foodcourt, below if checks that condition
      if (currentOrderFoodCourtId && currentOrderFoodCourtId !== item.foodCourtId) {
        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '320px',
          height: 'auto',
          data: { type: '' },
        });
        dialogRef.afterClosed().subscribe((data) => {
          if (data?.isSuccess === true) {
            this.globalCartService.getAllCartData().then(() => {
              this.addItemAfterCustomizationCheck(item, "ADD");
            })
          } else if (data?.isSuccess === false) {
            this.landingConsoleService.openSnackBarMessage('Error Occured');
          }
        });
      } else {
        this.addItemAfterCustomizationCheck(item, "ADD");
      }
    }
  }

  addItemAfterCustomizationCheck(item, actionType) {
    // console.log(item);

    // Item will be added after checking for customization required
    if (item.customizationRequired) {
      if (!item.menuCustomizationList || item.menuCustomizationList.length === 0) {
        this.homePageService.getAllCustomizationsOfItem(item.id).subscribe((response: any) => {
          if (response.data && response.data.length > 0) {
            item.menuCustomizationList = response.data.map((element) => {
              let obj = Object.assign({}, element);
              obj.customizedItemsForMenuList.forEach((customItems) => {
                customItems.status = false;
              })

              return obj;
            });
          }

          this.openCustomizationBottomSheet(item, actionType);
        })
      } else {
        item.menuCustomizationList.forEach((element) => {
          element.customizedItemsForMenuList.forEach((customItems) => {
            customItems.status = false;
          })
        })

        if (actionType === 'ADD') {
          item.orderedItemCustomizations = null;
        }

        this.openCustomizationBottomSheet(item, actionType);
      }

    } else {
      if (actionType === 'ADD') {
        this.addItem(item)
      } else if (actionType === 'INCREASE') {
        this.increaseItemCount(item);
      }
    }
  }

  openCustomizationBottomSheet(item, actionType) {

    const dialogRef = this.bottomSheet.open(CustomizeItemBottomsheetComponent, {
      data: { data: item, edit: false },
    });
    dialogRef.afterDismissed().subscribe((response) => {
      if (!response) return;
      item.orderedItemCustomizations = response.selectedCustomization;
      if (actionType === 'ADD') {
        this.addItem(item)
      } else if (actionType === 'INCREASE') {
        this.increaseItemCount(item);
      }
    });
  }

  addItem(item) {
    this.itemIdForLoading = item.id;

    item.showCustomQuantity++;
    // item.quantity = item.showCustomQuantity;
    item.itemQuantity = item.showCustomQuantity;

    this.showRunTimeLoader = true;

    let foodCourtId = this.landingConsoleService.getFoodCourtId();
    let foodCourtName = this.landingConsoleService.getFoodCourtName();
    this.landingConsoleService.setOrderFoodCourtId(foodCourtId);
    this.landingConsoleService.setOrderFoodCourtName(foodCourtName);

    // Add item to IndexDB regardless user is logged in or not
    this.globalCartService
      .addItemToIndexedDB(item, false)
      .then((response: any) => {
        this.getUserCartDataIndexDB();

        // this.landingConsoleService.itemQuantityChangeFromCart.next(true);
      })
      .catch((err) => {
        this.showRunTimeLoader = false;
        item.quantity -= 1;
        // this.landingConsoleService.openSnackBarMessage(err);
      });
  }

  increaseItemCount(item) {
    this.itemIdForLoading = item.id;
    this.showRunTimeLoader = true;

    item.showCustomQuantity++;

    this.globalCartService.checkForCustomizedItemInIndexDb(item)
      .then((result: any) => {
        this.globalCartService
          .addItemToIndexedDB(item, result.isSameItemPresent)
          .then((response: any) => {
            this.getUserCartDataIndexDB();

            // this.landingConsoleService.itemQuantityChangeFromCart.next(true);
          })
          .catch((err) => {
            this.showRunTimeLoader = false;
            item.showCustomQuantity -= 1;
            item.itemQuantity -= 1;
            // this.landingConsoleService.openSnackBarMessage(err);
          });
      })
  }

  decreaseItemCount(item) {

    this.globalCartService.checkForCustomizedItemInIndexDb(item)
      .then((result: any) => {
        if (item.customizationRequired && result.customizedItemCount > 1) {
          this.dialog.open(ConfirmationPopupComponent, {
            width: '90vw',
            height: 'auto',
            maxWidth: '100vw',
            maxHeight: '50vh',
            data: { type: 'REMOVE_CUSTOMIZATION' },
          });
        } else {

          this.itemIdForLoading = item.id;
          item.showCustomQuantity--;
          // item.quantity = item.showCustomQuantity;
          item.itemQuantity = item.showCustomQuantity;

          this.showRunTimeLoader = true;
          // if (item.quantity > 0) {
          if (item.itemQuantity > 0) {
            this.globalCartService
              .addItemToIndexedDB(item, true)
              .then((response: any) => {
                this.getUserCartDataIndexDB();

                // this.landingConsoleService.itemQuantityChangeFromCart.next(true);
              })
              .catch((err) => {
                this.showRunTimeLoader = false;
                item.quantity += 1;
                // this.landingConsoleService.openSnackBarMessage(err);
              });
          } else {
            this.globalCartService.removeItemFromCart(item)
              .then(response => {
                let orderedFoodCourtId = this.landingConsoleService.getOrderFoodCourtId();

                if (orderedFoodCourtId) {
                  this.getUserCartDataIndexDB();
                } else {
                  this.showRunTimeLoader = false;
                }
                // this.landingConsoleService.itemQuantityChangeFromCart.next(true);
              })
              .catch(error => {
                this.showRunTimeLoader = false;
                item.quantity += 1;
              })
          }
        }
      })
  }

  getUserCartDataIndexDB() {
    // this.showLoader = true;
    this.indexDbService.getAllItemFromCart().then((response) => {

      return new Promise((resolve, reject) => {
        resolve(response);
      })
    })
      .then((response: any) => {

        let userName = this.landingConsoleService.getUserName();
        let userMobile = this.landingConsoleService.getUserMobile();
        let orderedFoodCourtId = this.landingConsoleService.getOrderFoodCourtId();
        let btcRequestId = this.landingConsoleService.getBTCRequestId();

        let deliveryAddress = this.landingConsoleService.getUserAddressForBTC();
        let orderType = this.landingConsoleService.getUserOrderTypeForBTC();

        if (orderedFoodCourtId) {
          if (this.userId) {
            let data = {
              address: deliveryAddress,
              orderType: orderType,
              foodCourtId: orderedFoodCourtId,
              userId: this.userId,
              userName: userName,
              mobile: userMobile,
              platform: "WEB",
              btcRequestId: btcRequestId,
              itemsList: {},
            };
            data.itemsList = response;

            this.landingConsoleService.addItemToCartAPI(data).subscribe(
              (res) => {

                this.foodCourtDetails.trendingtodayList.forEach((item) => {
                  item.showCustomQuantity = 0;
                  item.itemId = item.id;
                  response.forEach((cartItem) => {
                    if (cartItem.itemId === item.id) {
                      item.showCustomQuantity += cartItem.itemQuantity;
                      item.appItemId = cartItem.appItemId;
                      item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                    }
                  });
                })

                this.foodCourtDetails.quickBitesList.forEach((item) => {
                  item.showCustomQuantity = 0;
                  item.itemId = item.id;
                  response.forEach((cartItem) => {
                    if (cartItem.itemId === item.id) {
                      item.showCustomQuantity += cartItem.itemQuantity;
                      item.appItemId = cartItem.appItemId;
                      item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                    }
                  });
                })

                this.landingConsoleService.itemQuantityChange.next(true);
                this.showRunTimeLoader = false;
              },
              (err) => {

                this.handleErrorForAddingItems();
                // this.showLoader = false;
                //console.log(err);
                this.landingConsoleService.openSnackBarMessage(
                  'Failed to add item to Cart'
                );
              }
            );
          } else {
            this.foodCourtDetails.trendingtodayList.forEach((item) => {
              item.showCustomQuantity = 0;
              item.itemId = item.id;
              response.forEach((cartItem) => {
                if (cartItem.itemId === item.id) {
                  item.showCustomQuantity += cartItem.itemQuantity;
                  item.appItemId = cartItem.appItemId;
                  item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              });
            })

            this.foodCourtDetails.quickBitesList.forEach((item) => {
              item.showCustomQuantity = 0;
              item.itemId = item.id;
              response.forEach((cartItem) => {
                if (cartItem.itemId === item.id) {
                  item.showCustomQuantity += cartItem.itemQuantity;
                  item.appItemId = cartItem.appItemId;
                  item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
                }
              });
            })

            this.landingConsoleService.itemQuantityChange.next(true);
            this.showRunTimeLoader = false;
          }
        } else {

          this.landingConsoleService.itemQuantityChange.next(true);
          this.showRunTimeLoader = false;
        }
      })
  }

  handleErrorForAddingItems() {
    // Clearing local db and getting user cart data from backend and adding back to local db and maintaining the count of items
    this.indexDbService.clearAllItemsFromCart()
      .then((result) => {
        this.landingConsoleService.getCartData(this.userId).subscribe(async (response: any) => {
          let orderFoodCourtId = response.data.foodCourtId;
          this.landingConsoleService.setOrderFoodCourtId(orderFoodCourtId);

          console.log("user cart from api", response);
          for (let item of response.data.itemsList) {
            await this.globalCartService.addItemToIndexedDB(item, false);
            // console.log("items adding in process");
          }

          this.foodCourtDetails.trendingtodayList.forEach((item) => {
            item.showCustomQuantity = 0;
            item.itemId = item.id;
            response.forEach((cartItem) => {
              if (cartItem.itemId === item.id) {
                item.showCustomQuantity += cartItem.itemQuantity;
                item.appItemId = cartItem.appItemId;
                item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            });
          })

          this.foodCourtDetails.quickBitesList.forEach((item) => {
            item.showCustomQuantity = 0;
            item.itemId = item.id;
            response.forEach((cartItem) => {
              if (cartItem.itemId === item.id) {
                item.showCustomQuantity += cartItem.itemQuantity;
                item.appItemId = cartItem.appItemId;
                item.orderedItemCustomizations = cartItem.orderedItemCustomizations || null;
              }
            });
          })
          this.showRunTimeLoader = false;
          // console.log("cart added to items complete");
        }, error => {
          this.landingConsoleService.openSnackBarMessage("Failed to add item to cart, please refresh once")
        })
      })
      .catch((error) => {
        this.showRunTimeLoader = false;
      })
  }

  MEALS = [];

  showOneClickOrderPayment(order) {
    const billDetails = {
      // actualPrice: order.price / 100,
      actualPrice: order.markUpPrice,
      // empDiscount:
      //   (order.orderCount >= order?.employerMenu[0].threshold || !order?.employerMenu[0].employeeType
      //     ? 0
      //     : order?.employerMenu[0].paidByEmployer) / 100,
      empDiscount:
        (order.orderCount >= order?.employerMenu[0].threshold || !order?.employerMenu[0].employeeType
          ? 0
          : order?.employerMenu[0].paidByEmployer),
      // tax: (order.price / 100) * 0.05,
      // tax: (order?.taxValue / 100),
      tax: (order?.taxValue),
      // totalPrice:
      //   (order.price / 100) -
      //   ((order.orderCount >= order?.employerMenu[0].threshold || !order?.employerMenu[0].employeeType
      //     ? 0
      //     : order?.employerMenu[0].paidByEmployer) / 100 ) + (order?.taxValue / 100),
      // packagingCharges: order.packagingCharge
      totalPrice:
        (order.markUpPrice) -
        ((order.orderCount >= order?.employerMenu[0].threshold || !order?.employerMenu[0].employeeType
          ? 0
          : order?.employerMenu[0].paidByEmployer)) + (order?.taxValue),
      packagingCharges: order.packagingCharge
    };

    const dialogRef = this.bottomSheet.open(OneClickPaymentComponent, {
      data: {
        userId: this.userId,
        billDetails: billDetails,
        orderDetails: order,
      },
    });

    dialogRef.afterDismissed().subscribe((data) => {
      if (data) {
        this.getHomePageData();
        this.showQR(null, data);
      }
    });
  }
  viewResturantDetails(vendorDetails) {
    this.landingConsoleService.setVendorId(vendorDetails.vendorId);
    let vendorUrl = vendorDetails?.brandName
      ? vendorDetails?.brandName?.split(' ')?.join('-').toLowerCase()
      : ' ';
    let siteUrl = this.landingConsoleService
      .getSiteName()
      ?.split(' ')
      ?.join('-')
      .toLowerCase();
    let foodCourtUrl = this.landingConsoleService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();
    // this.router.navigate(["restaurant"], {queryParams: {vendor: }});
    this.router.navigate([
      `restaurant/${foodCourtUrl}/${vendorUrl}`,
    ]);
  }
  viewChooseMealDetails(selectedMealType) {
    let siteUrl =
      this.landingConsoleService
        .getSiteName()
        ?.split(' ')
        ?.join('-')
        .toLowerCase() || ' ';
    this.router.navigate([
      `choose-meal/${this.foodCourtName
        .split(' ')
        .join('-').toLowerCase()}/${selectedMealType}`,
    ]);
  }

  goToFoodSubscriptionListingPage() {
    this.landingConsoleService.setSubscriptionPlanListing(this.foodCourtDetails.fcSubscriptionPlans);
    this.router.navigate(['food-subscription/list-plan']);
  }

  showQR(element, dataForQr = null) {
    let data;
    if (element) {
      const userName = this.landingConsoleService.getUserName();
      const userMobile = this.landingConsoleService.getUserMobile();
      const userEmail = this.landingConsoleService.getUserEmail();

      data = {
        foodCourtId: element.foodCourtId,
        itemsList: [
          {
            imgUrl: null,
            inStock: null,
            itemId: element.id,
            itemName: element.name,
            price: element.markUpPrice,
            veg: null,
            // mrpItem: element.mrpItem,
            taxCategory: element.taxCategory,
            vendorId: element.vendorId,
            vendorName: element.vendorName,
            itemQuantity: 1,
            foodCourtId: element.foodCourtId,
            itemStatus: element.itemStatus,
            itemCategory: element.itemCategory,
            deliveryAvailable: this.foodCourtDetails.hasDelivery,
          },
        ],
        userId: this.userId,
        userName: userName,
        orderType: 'DINE_IN',
        mobile: userMobile,
        email: userEmail,
        deliveryAvailable: this.foodCourtDetails.hasDelivery,
        paymentBY: 'EMPLOYER_PAYMENT',
        mealCategory: element.mealCategory[0],
        billDetails: {
          breakup: {
            "Taxes & charge": 0,
            "Item Total": element.markUpPrice,
            "Item Discount": 0,
            "Delivery Charges": 0,
          },
          finalPrice: element.markUpPrice,
          couponCode: null,
          employeePrice: 0,
          couponId: null,
        },
        address: null,
      };
    } else {
      data = {
        WARNING: 'SCAN IN ISTHARA APP ONLY',
        ERROR_ID: dataForQr.orderId,
        ERROR_CODE: dataForQr.vendorOrderDetails[0].vendorId,
        KOT: String(dataForQr.vendorOrderDetails[0].kot),
        ORDER_NUM: dataForQr.orderNum,
      };
    }

    this.notificationService.currentMessage.next(null);

    const dialogRef = this.dialog.open(DisplayQrCodeComponent, {
      width: '80vw',
      maxWidth: '100vw',
      height: 'auto',
      panelClass: 'no-padding',
      data: {
        data: data,
        mealCategory: element ? element.mealCategory[0] : null,
        vendorName: element ? null : dataForQr.vendorOrderDetails[0].vendorName,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.getHomePageData();
    });
  }

  openItemDetailBox(item) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '320px',
      height: 'auto',
      data: { type: 'ITEM_DETAILS', itemDetails: item },
    });
    dialogRef.afterClosed().subscribe((data) => { });
  }

  showDiscountDetails(discountDetails) {

    const dialogRef = this.bottomSheet.open(DiscountDetailsBottomsheetComponent, {
      data: discountDetails,
      panelClass: 'no-padding',
    });
  }
  goToVendorPage(discountDetails) {

    this.landingConsoleService.setVendorId(discountDetails.vendorId);
    let vendorUrl = discountDetails?.brandName
      ? discountDetails?.brandName?.split(' ')?.join('-').toLowerCase()
      : ' ';

    let foodCourtUrl = this.landingConsoleService.getFoodCourtName()?.split(' ')?.join('-').toLowerCase();
    // this.router.navigate(["restaurant"], {queryParams: {vendor: }});
    this.router.navigate([
      `restaurant/${foodCourtUrl}/${vendorUrl}`,
    ]);
  }

  goToPreOrderingPage() {
    const isPreOrderType = this.landingConsoleService.getIsOrderPreOrderType();
    const orderFoodCourtId = this.landingConsoleService.getOrderFoodCourtId();

    if (isPreOrderType && orderFoodCourtId !== this.foodCourtId) {

      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'PREORDERING_FROM_DIFFERENT_FOODCOURT' },
      });
      dialogRef.afterClosed().subscribe((data) => {
        this.globalCartService
          .getUserCartData()
          .then((response: any) => {
            this.userCartData = response.userCartData;
            this.cartDetails = response.cartPreviewData;
            this.showLoader = false;
            this.getHomePageData();
          })
      });
    }

    else if (!isPreOrderType && orderFoodCourtId) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: '380px',
        height: 'auto',
        data: { type: 'GO_PREORDER_CONFIRMATION' },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          // this.isIntoPreOrderingFlow = null;

          this.globalCartService.getUserCartData().then((response: any) => {
            this.userCartData = response.userCartData;
            this.cartDetails = response.cartPreviewData;
            this.showLoader = false;
            this.getHomePageData();
          })
        }
      });

    } else {
      if (this.scrWidth < 600) {
        this.router.navigate(['user/preordering']);
      }
      else {
        this.landingConsoleService.sidePanelContent.next("PRE_ORDER");

      }

    }
  }

  goToGuestOrderPage() {
    this.router.navigate(['user/guest-order']);
  }

  goToDownloadAppPage() {
    if (this.platform.ANDROID) {
      window.open('https://play.google.com/store/apps/details?id=com.tao.isthara');
    } else if (this.platform.IOS) {
      // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
      window.open('https://tinyurl.com/5n7vn985');
    }
  }
}

