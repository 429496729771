<div class="footer-cart-section" (click)="viewCart()" [ngClass]="{'adjustMargin': changeBottomMargin}">
    <div class="left-section">
        <span class="item">{{ cartDetails.noOfItems }} item</span>
        <span class="divider"></span>
        <span class="amount">Total: {{ cartDetails.totalAmount | number:'1.0-2' }}</span>
    </div>
    <div class="right-section">
        <span class="text">View Cart</span>
        <img src="../../../../../../../assets/images/common/arrow_forward.svg" alt="">
    </div>
</div>