<section>
  <div class="popup-main-container" *ngIf="!data.type">
    <div class="primary-text">Items of another foodcourt is already present in your cart</div>
    <div class="secondary-text">Do you want to replace your current cart items?</div>
    <div class="action-btn">
      <button class="no-btn button" (click)="closePopup()">No</button>
      <button class="yes-btn button" (click)="clearCart()">Yes</button>
    </div>
  </div>

  <section class="payment-success-section" *ngIf="data?.type === 'SUCCESS'">
    <div class="payment-container">
      <div class="close-img">
        <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closePopUp()" />
      </div>
      <div class="success-heading">Successfully
        <span *ngIf="data.paymentType !== 'WALLET_PAYMENT'">Ordered</span>
        <span *ngIf="data.paymentType === 'WALLET_PAYMENT'">Recharged</span>
      </div>
      <div class="success-img">
        <img src="../../../../../../../assets/images/common/success-icon.svg" alt="" />
      </div>
      <!-- <p class="success-main-text" *ngIf="data.paymentType === 'WALLET_PAYMENT'">
        Points credited successfully
      </p> -->
      <p class="success-main-text" *ngIf="data.paymentType === 'CART_PAYMENT'">
        Your order is successfully placed
      </p>
      <p class="success-main-text" *ngIf="data.paymentType === 'PRE_ORDER_PAYMENT'">
        Your Pre-Order is successfully placed
      </p>
      <p class="success-main-text" *ngIf="data.paymentType === 'DELIVERY_PAYMENT'">
        Your order is successfully changed to delivery
      </p>
      <p class="success-sub-text" *ngIf="data.paymentType !== 'WALLET_PAYMENT'">We'll update your order status shortly
      </p>
      <p class="success-sub-text_points" *ngIf="data.paymentType === 'WALLET_PAYMENT'">Hey {{ userName | titlecase }},
        your points is credited successful</p>
    </div>
  </section>

  <section class="payment-success-section" *ngIf="data?.type === 'FAILURE'">
    <div class="payment-container">
      <div class="failure-heading">Payment Failed</div>
      <div class="failure-img">
        X
        <!-- <img
          src="../../../../../../../assets/images/common/success-icon.svg"
          alt=""
        /> -->
      </div>
      <p class="failure-main-text">
        If your amount is debited we will add isthara points to your wallet in
        24-48 hours
      </p>
      <div class="close-btn"><button (click)="closePopUp()">Close</button></div>
    </div>
  </section>

  <section class="item-description-container" *ngIf="data?.type === 'ITEM_DETAILS'">
    <div class="img-container">
      <img *ngIf="
          data.itemDetails?.itemImageUrl || data?.itemDetails?.imgUrl;
          else showDummyImg
        " [src]="data.itemDetails?.itemImageUrl ? data.itemDetails?.itemImageUrl : data?.itemDetails?.imgUrl" alt="" />
      <ng-template #showDummyImg>
        <img src="../../../../../../../assets/images/common/default_food_image.svg" alt="" />
      </ng-template>
    </div>
    <div class="item-name">
      {{ data.itemDetails.name || data.itemDetails.itemName }}
    </div>
    <div class="item-type-price-container">
      <div class="item-type">
        <div class="veg-item" *ngIf="data.itemDetails.itemType || data.itemDetails.veg">
          <img src="../../../../../../../assets/images/home-page/veg-item.svg" alt="" />
          <span class="item-type">Veg</span>
        </div>
        <div class="non-veg-item" *ngIf="
            data.itemDetails.itemType
              ? !data.itemDetails.itemType
              : !data.itemDetails.veg
          ">
          <img src="../../../../../../../assets/images/home-page/non-veg-item.svg" alt="" />
          <span class="item-type">Non-Veg</span>
        </div>
      </div>
      <!-- <div class="item-price">&#8377;{{ data.itemDetails.price / 100 }}</div> -->
      <div class="item-price">&#8377;{{ data.itemDetails.markUpPrice | number:'1.0-2'}}</div>
    </div>
  </section>

  <section class="main-section" *ngIf="data?.type === 'LOGOUT'">
    <h3 class="main-heading">Logout</h3>
    <p class="main-text">Are you sure you want to logout ?</p>
    <div class="close-btns">
      <button class="no-btn" (click)="closePopUp()" [disabled]="logoutLoading">
        No
      </button>
      <button class="yes-btn" (click)="logoutUser()" *ngIf="!logoutLoading">
        Yes
      </button>
      <button class="yes-btn" *ngIf="logoutLoading">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;
      </button>
    </div>
  </section>

  <section class="main-section" *ngIf="data?.type === 'CONFIRM_LOCATION'">
    <p class="main-text">Please confirm your delivery location</p>
    <div class="address-details-section">
      <div class="address-img">
        <img src="../../../../../../../assets/images/cart/default-address.svg" alt="" />
      </div>
      <div class="address-details">
        <div class="address-heading">
          Deliver to
          {{ data.deliveryAddress.addressTitle | titlecase }}
        </div>
        <div class="address-value">
          {{ data.deliveryAddress.address | titlecase }}
        </div>
      </div>
    </div>
    <div class="confirmation-btns">
      <button class="no-btn" (click)="closePopUp()">
        No, I want to change
      </button>
      <button class="yes-btn" (click)="confirmAddress()">Yes, I Confirm</button>
    </div>
  </section>

  <section class="coupon-main-container" *ngIf="data?.type === 'COUPON_DETAILS'">
    <div class="reedem-code">
      <div class="main-coupon">
        <div class="reedem-text">
          <div class="main">
            <img *ngIf="!couponDetails?.discountImageUrl; else showAvatar"
              src="./../../../../../../assets/images/common/isthara-mobile-logo.png" />
            <ng-template #showAvatar>
              <img [src]="couponDetails?.discountImageUrl" alt="" />
            </ng-template>
          </div>
          <div class="reedem-text-text">
            {{ couponDetails?.discountName | uppercase }}
          </div>
        </div>
        <div (click)="closePopUp()" class="close">
          <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="" />
        </div>
      </div>

      <div class="apply-heading-code">
        <div class="apply-heading-main" *ngIf="couponDetails?.discountType === 'PERCENTAGE'">
          Got {{ couponDetails?.discountValue }}% Discount Using
          {{ couponDetails?.discountName | uppercase }}
        </div>
        <div class="apply-heading-main" *ngIf="couponDetails?.discountType === 'FIXED_AMOUNT'">
          Got Flat ₹{{ couponDetails?.discountValue }} Off by Using
          {{ couponDetails?.discountName | uppercase }}
        </div>
        <div class="apply-heading-sub" *ngIf="couponDetails?.discountConstraints?.maxRedemptions">
          Maximum discount up to ₹{{
          couponDetails?.discountConstraints?.maxRedemptions
          }}
          on orders Above ₹{{
          couponDetails?.discountConstraints?.minimumOrderAmount
          }}
        </div>
        <div class="terms-and-condition">
          <span class="heading-text">Terms & Conditions</span>
          <ul>
            <li>
              Applicable to orders from
              {{ couponDetails?.vendorName | titlecase }} only
            </li>
            <ng-container *ngIf="
                couponDetails?.termsAndConditions.length > 0 &&
                couponDetails?.termsAndConditions[0]
              ">
              <ng-container *ngFor="let term_condition of couponDetails?.termsAndConditions">
                <li>{{ term_condition | titlecase }}</li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="download-app-main-container" *ngIf="data?.type === 'DOWNLOAD_APP'">
    <div class="download-app-container">
      <div class="image-container">
        <img src="../.../../../../../../../../assets/images/common/isthara_foodcourt1.svg" alt="">
      </div>
      <div class="first-heading-text">Isthara App</div>
      <div class="second-heading-text">Download the app for better experience</div>
      <div class="sample-text"></div>
      <a class="download-app" [href]="appDownloadLink">Download</a>
    </div>
    <div class="close-img" (click)="closePopup()">
      <img src="../../../../../../../assets/images/common/close.svg">
    </div>
  </section>

  <section class="popup-main-container" *ngIf="data.type === 'REMOVE_CUSTOMIZATION'">
    <div class="primary-text">Remove item from cart</div>
    <p></p>
    <div class="secondary-text">The Item has multiple customizations added, proceed to the cart if you want to remove
      the item</div>
    <div class="action-btn">
      <button class="cancel-btn button" (click)="closePopup()">Close</button>
      <button class="go-to-cart-btn button" (click)="goToCart()">Go to cart</button>
    </div>
  </section>

  <section class="popup-main-container packaging-charges-section" *ngIf="data.type === 'PACKAGING_CHARGES_DETAILS'">
    <div class="header">
      <div class="primary-text">Packaging Charges Details</div>
      <div class="close-img">
        <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closePopUp()" />
      </div>
    </div>
    <ng-container *ngFor="let vendorCharges of data.chargesDetails | keyvalue">
      <div class="packaging-charges-detail">
        <span>{{ vendorCharges.key }}</span>
        <!-- <span>{{ (vendorCharges.value / 100 ) | number:'1.2-2'}}</span> -->
        <span>{{ (vendorCharges.value) | number:'1.2-2'}}</span>
      </div>
    </ng-container>
  </section>

  <section class="preorder-confirmation-section" *ngIf="data.type === 'PREORDER_CONFIRMATION'">
    <div class="primary-text">Are you Sure ?</div>
    <div class="secondary-text">You want to continue with normal order flow, this will clear your preorder items in
      cart?</div>
    <div class="action-btn">
      <button class="close-btn" (click)="closePopUp()">Close</button>
      <button class="continue-btn" (click)="clearCart()">Continue</button>
    </div>
  </section>

  <section class="preorder-confirmation-section" *ngIf="data.type === 'GO_PREORDER_CONFIRMATION'">
    <div class="primary-text">Are you Sure ?</div>
    <div class="secondary-text">You want to continue with preorder, this will clear your previous added items?</div>
    <div class="action-btn">
      <button class="close-btn" (click)="closePopUp()">Close</button>
      <button class="continue-btn" (click)="clearCart()">Continue</button>
    </div>
  </section>

  <section class="preorder-confirmation-section" *ngIf="data.type === 'PREORDERING_FROM_DIFFERENT_FOODCOURT'">
    <div class="primary-text">Are you Sure ?</div>
    <div class="secondary-text">You already have Preordering items in another FC? Do you want to Clear those Items.
    </div>
    <div class="action-btn">
      <button class="close-btn" (click)="closePopUp()">Close</button>
      <button class="continue-btn" (click)="clearCart()">Continue</button>
    </div>
  </section>

  <section class="btc-edit-request-section" *ngIf="data.type === 'BTC_CONFIRMATION_EDIT'">
    <div class="primary-text">Are you Sure ?</div>
    <div class="secondary-text">You already have item in your cart. You have to clear the Cart to edit Request?</div>
    <div class="action-btn">
      <button class="close-btn" (click)="closePopUp()">Close</button>
      <button class="continue-btn" (click)="clearCart()">Continue</button>
    </div>
  </section>

</section>