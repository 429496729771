<section class="preorder-confirmation-popup" *ngIf="data.type === 'PREORDER_CONFIRMATION'">
    <div class="popup-header">
        <div class="header-primary-text">CONFIRMATION</div>
        <div class="header-img-container" (click)="closePopup()">
            <img src="../../../../../../../assets/images/common/close.svg" alt="">
        </div>
    </div>
    <div class="popup-main-content">
        You already have item with different timeslot, to continue adding item with different timeslot you need to clear Cart.
    </div>
    <div class="popup-action-btn">
        <button class="cancel-btn" (click)="closePopup()">Cancel</button>
        <button class="proceed-btn" (click)="proceedToClearCart()">Proceed</button>
    </div>
</section>
<section class="preorder-confirmation-popup" *ngIf="data.type === 'PREORDER_TIME_EXCEEDED'">
    <div class="popup-header">
        <div class="header-primary-text">CONFIRMATION</div>
        <div class="header-img-container" (click)="closePopup()">
            <img src="../../../../../../../assets/images/common/close.svg" alt="">
        </div>
    </div>
    <div class="popup-main-content">
        Time Limit Exceeded for Preordering Please clear your Cart
    </div>
    <div class="popup-action-btn">
        <button class="cancel-btn" (click)="closePopup()">Cancel</button>
        <button class="proceed-btn" (click)="proceedToClearCart()">Proceed</button>
    </div>
</section>