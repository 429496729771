<section>
  <section class="url-not-found-container" *ngIf="!showErrorPage">
      <div class="empty-part" *ngIf="!isOffline">
        <div class="no-image">
          <img
            src="../../../../../../assets/images/cart/empty-cart.svg"
            alt="empty-image"
          />
        </div>
        <div class="empty-heading">Page not found</div>
        <!-- <div class="empty-heading">But good food is always cooking</div> -->
        <div class="empty-subtext">Plz click on below button</div>
        <button class="go-to-home" (click)="goToHome()">Take me to Home</button>
      </div>
      <div class="offline-part" *ngIf="isOffline">
        <!-- <div class="empty-heading">You are Offline</div>
        <div class="empty-subtext">Plz find better net connection</div>   -->
        <!-- <img src="../../../../../../../assets/images/cart/empty-cart.svg" height="100px" width="100px" alt=""> -->
        <h2>Oh Shucks!</h2>
        <h4 class="empty-subtext-big">It's just that you lost your internet connection.</h4>
        <button class="go-to-home" (click)="goToHome()">Retry</button>
      </div>
  </section>
  <section class="error-container" *ngIf="showErrorPage">
      <div class="no-image">
        <img
          src="../../../../../../assets/images/cart/empty-cart.svg"
          alt="empty-image"
        />
      </div>
      <div class="empty-heading">Hmm!! Something went wrong</div>
      <!-- <div class="empty-heading">But good food is always cooking</div> -->
      <div class="empty-subtext">Please try again in some time</div>
      <button class="go-to-home" (click)="goToHome()">Take Again</button>
  </section>
</section>  