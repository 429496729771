<section class="qr-code-section">
  <div class="close-img" (click)="closeQrPopup()">
    <img src="../../../../../../../assets/images/common/close.svg" alt="Close" />
  </div>

  <div class="guest-ordering-qr-code" *ngIf="data.type !== 'FOOD_SUBSCRIPTION'">
    <div class="qr-code">
      <div class="vendor-name" *ngIf="data.vendorName">{{ data.vendorName }}</div>
      <div class="vendor-name" *ngIf="data.mealCategory">
        {{ data.mealCategory }} QR Code
      </div>
      <qrcode #qrcodeprint [qrdata]="dataSending" [errorCorrectionLevel]="'M'" [margin]="5" [scale]="50" [width]="280">
      </qrcode>
      <ng-container *ngIf="data?.showQR">
        <div class="share-qr" (click)="copyQRCode()">Click to Copy QR Code</div>
      </ng-container>
    </div>
  </div>
  <div class="food-subscription-qr-code" *ngIf="data.type === 'FOOD_SUBSCRIPTION'">
    <div class="qr-code">
      <div class="heading-text">QR Code</div>
      <qrcode #qrcodeprint [qrdata]="dataSending" [errorCorrectionLevel]="'M'" [margin]="5" [scale]="50" [width]="280">
      </qrcode>
    </div>
  </div>

</section>