<div class="section">
  <ng-container>
    <div class="header">
      <div class="header-menu">
        <div class="back-btn">
          <img src="./../../../../../../assets/images/common/back_arrow.svg" (click)="back()" />
        </div>
        <div class="header-left">
          <div class="main-path">{{ MEALTYPE | titlecase }}</div>
          <div class="sub-path">Serves All Day</div>
        </div>
        <div class="header-right">
          <span class="veg-item">Veg Only</span>
          <mat-slide-toggle [(ngModel)]="isVegOnly" (ngModelChange)="_getItemListByMealType_()"></mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="search-container">
      <div class="input-container">
        <input type="text" placeholder="Search for Items" [(ngModel)]="searchText" (ngModelChange)="showAllItemList()">
        <!-- (ngModelChange)="_searchFoodItem()" -->
      </div>
      <button class="search-btn" (click)="searchFoodItem()"><mat-icon>search</mat-icon></button>
    </div>

    <!--Menu type-->
    <div class="item-list-container">
      <ng-container *ngIf="!failedToGetCuisineList; else showCuisineAPIFailureContent">
        <ng-container *ngIf="!showMainLoadingScreen; else mainLoadingScreen">
          <!--Cusine type-->
          <div class="meal-main-section">
            <div class="meal-type-list">
              <ng-container *ngFor="let cuisine of CUISINE_LIST">
                <div class="meal-chip" (click)="getitems(cuisine.value)"
                  [ngClass]="{ 'meal-chip_active': cuisine.status }">
                  {{ cuisine.name | titlecase }}
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="!failedToGetItemList; else showItemListAPIFailureContent">
            <ng-container *ngIf="!showItemLoadingScreen; else itemLoadingScreen">
              <div *ngIf="scrWidth < 600">
                <div class="main-item-list" *ngIf="foodItemList.length > 0; else showEmptyItemList">
                  <ng-container *ngFor="let item of foodItemList">
                    <div class="main">
                      <div class="trending-icon-container" *ngIf="item.trending">
                        <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                      </div>
                      <div class="order-list">
                        <img *ngIf="item.itemImageUrl" loading="lazy" [src]="item.itemImageUrl" />
                        <img *ngIf="!item.itemImageUrl"
                          src="./../../../../../../assets/images/common/default_food_image.svg" />
                      </div>
                      <div class="order-item">
                        <div class="order-item-heading">
                          <img *ngIf="item.itemType === 'VEG'" src="../../../../../../assets/images/cart/veg-item.svg"
                            class="veg-icon" alt="" />
                          <img *ngIf="item.itemType === 'NON_VEG'"
                            src="../../../../../../assets/images/cart/non-veg-item.svg" class="veg-icon" alt="" />
                          <span *ngIf="item?.name.length < 25; else showTrimmedName">{{
                            item?.name | titlecase
                            }}</span>

                          <ng-template #showTrimmedName>
                            <span>{{ item.name.substring(0, 20) | titlecase }}..</span>
                            <!-- <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon> -->
                          </ng-template>
                          <div class="customization-text">
                            <span *ngIf="item.customizationRequired">Customisable</span>
                          </div>
                        </div>
                        <div class="order-item-description">
                          <div class="order-item-text">
                            {{ item?.vendorName | titlecase }}
                          </div>
                          <div class="restaurant-rating extra-margin">
                            <img src="../../../../../../assets/images/home-page/star.svg" alt="" />
                            <div class="rating">{{ item?.rating | number: "1.0-1" }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="order-amount">
                        <div class="order-amount-digit">
                          <span *ngIf="item?.taxCategory === 'MRP'" class="mrp-text">MRP </span>
                          <!-- ₹{{ (item?.price / 100) | number: "1.0-1" }} -->
                          ₹{{ (item?.markUpPrice) | number: "1.0-1" }}
                        </div>
                        <div class="add-btn"
                          [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow && !isComingFromPreOrderingPage}"
                          *ngIf="
                        item.itemStatus === 'IN_STOCK' ||
                          item.itemStatus === 'VENDOR_AVAILABLE';
                        else QBOutOfStock
                      ">
                          <ng-container *ngIf="
                          !showRunTimeLoader || itemIdForLoading !== item.itemId;
                          else runTimeLoading;
                          else: runTimeLoading
                        ">
                            <button class="add-item"
                              *ngIf="item.showCustomQuantity === 0 || item.showCustomQuantity === NaN; else showItemIncDecMeal"
                              (click)="addItemAfterMultipleFoodcourtCheck(item)">
                              Add
                            </button>
                            <ng-template #showItemIncDecMeal>
                              <ng-container
                                *ngIf="!isIntoPreOrderingFlow || isComingFromPreOrderingPage; else showFreezedCountMeal">
                                <div class="add-to-cart-btn">
                                  <button class="minus" (click)="decreaseItemCount(item)">
                                    -
                                  </button>
                                  <span class="item-count">{{ item.showCustomQuantity }}</span>
                                  <button class="plus" (click)="addItemAfterCustomizationCheck(item, 'INCREASE')">
                                    +
                                  </button>
                                </div>
                              </ng-container>
                              <ng-template #showFreezedCountMeal>
                                <div class="add-to-cart-btn-freezed" (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                  <button class="minus">-</button>
                                  <span class="item-count">{{ item?.showCustomQuantity }}</span>
                                  <button class="plus">+</button>
                                </div>
                              </ng-template>
                            </ng-template>
                          </ng-container>
                        </div>
                        <ng-template #QBOutOfStock>
                          <div class="out-of-stock">Out of Stock</div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="cartDetails.noOfItems > 0 && scrWidth < 600">
                    <app-cart-footer [cartDetails]="cartDetails"></app-cart-footer>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="scrWidth > 600">
                <div class="main-item-list" *ngIf="foodItemList.length > 0; else showEmptyItemList">
                  <ng-container *ngFor="let item of foodItemList">
                    <div class="main-desktop-version">
                      <div class="trending-icon-container" *ngIf="item.trending">
                        <img src="../../../../../../assets/images/common/trending-icon.svg" alt="">
                      </div>
                      <div class="order-list">
                        <img *ngIf="item.itemImageUrl" loading="lazy" [src]="item.itemImageUrl" />
                        <img *ngIf="!item.itemImageUrl"
                          src="./../../../../../../assets/images/common/default_food_image.svg" />
                      </div>
                      <div class="order-item">
                        <div class="order-item-heading">
                          <img *ngIf="item.itemType === 'VEG'" src="../../../../../../assets/images/cart/veg-item.svg"
                            class="veg-icon" alt="" />
                          <img *ngIf="item.itemType === 'NON_VEG'"
                            src="../../../../../../assets/images/cart/non-veg-item.svg" class="veg-icon" alt="" />
                          <span *ngIf="item?.name.length < 25; else showTrimmedName">{{
                            item?.name | titlecase
                            }}</span>

                          <ng-template #showTrimmedName>
                            <span>{{ item.name.substring(0, 20) | titlecase }}..</span>
                            <!-- <mat-icon (click)="openItemDetailBox(item)">add_box</mat-icon> -->
                          </ng-template>
                          <div class="customization-text">
                            <span *ngIf="item.customizationRequired">Customisable</span>
                          </div>
                        </div>
                        <div class="order-item-description">
                          <div class="order-item-text">
                            {{ item?.vendorName | titlecase }}
                          </div>
                          <div class="restaurant-rating extra-margin">
                            <img src="../../../../../../assets/images/home-page/star.svg" alt="" />
                            <div class="rating">{{ item?.rating | number: "1.0-1" }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="order-amount">
                        <div class="order-amount-digit">
                          <!-- ₹{{ (item?.price / 100) | number: "1.0-1" }} -->
                          ₹{{ (item?.markUpPrice) | number: "1.0-1" }}
                        </div>
                        <div class="add-btn"
                          [ngClass]="{'freeze-item-container': isIntoPreOrderingFlow && !isComingFromPreOrderingPage}"
                          *ngIf="
                        item.itemStatus === 'IN_STOCK' ||
                          item.itemStatus === 'VENDOR_AVAILABLE';
                        else QBOutOfStock
                      ">
                          <ng-container *ngIf="
                          !showRunTimeLoader || itemIdForLoading !== item.itemId;
                          else runTimeLoading;
                          else: runTimeLoading
                        ">
                            <button class="add-item"
                              *ngIf="item.showCustomQuantity === 0 || item.showCustomQuantity === NaN; else showItemIncDecMeal"
                              (click)="addItemAfterMultipleFoodcourtCheck(item)">
                              Add
                            </button>
                            <ng-template #showItemIncDecMeal>
                              <ng-container
                                *ngIf="!isIntoPreOrderingFlow || isComingFromPreOrderingPage; else showFreezedCountMeal">
                                <div class="add-to-cart-btn">
                                  <button class="minus" (click)="decreaseItemCount(item)">
                                    -
                                  </button>
                                  <span class="item-count">{{ item.showCustomQuantity }}</span>
                                  <button class="plus" (click)="addItemAfterCustomizationCheck(item, 'INCREASE')">
                                    +
                                  </button>
                                </div>
                              </ng-container>
                              <ng-template #showFreezedCountMeal>
                                <div class="add-to-cart-btn-freezed" (click)="addItemAfterMultipleFoodcourtCheck(item)">
                                  <button class="minus">-</button>
                                  <span class="item-count">{{ item?.showCustomQuantity }}</span>
                                  <button class="plus">+</button>
                                </div>
                              </ng-template>
                            </ng-template>
                          </ng-container>
                        </div>
                        <ng-template #QBOutOfStock>
                          <div class="out-of-stock">Out of Stock</div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="cartDetails.noOfItems > 0 && scrWidth < 600">
                    <app-cart-footer [cartDetails]="cartDetails"></app-cart-footer>
                  </ng-container>
                </div>
              </div>
              <ng-template #showEmptyItemList>
                <div class="empty-screen-container">
                  <img src="../../../../../../assets/images/common/empty-menu.svg" alt="" />
                  <div class="heading-text">Ooh!</div>
                  <div class="secondary-text">Item list is empty</div>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #itemLoadingScreen>
              <div class="loading-screen-container item-listing-loader">
                <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
              </div>
            </ng-template>
          </ng-container>
          <ng-template #showItemListAPIFailureContent>
            <div class="api-error-container">
              <h5>Failed to get Food Item List</h5>
              <span>Plz try again in sometime.</span>
              <button (click)="_getItemListByMealType_()">Try Again</button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #mainLoadingScreen>
          <div class="loading-screen-container main-loader">
            <img src="../../../../../../assets/images/common/burger_loader.gif" alt="" />
          </div>
        </ng-template>
      </ng-container>
      <ng-template #showCuisineAPIFailureContent>
        <div class="api-error-container">
          <h5>Failed to get Cuisine List</h5>
          <span>Plz try again in sometime.</span>
          <button (click)="getAllCuisines()">Try Again</button>
        </div>
      </ng-template>
    </div>

  </ng-container>
  <ng-template #runTimeLoading>
    <button class="add-item-loading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
  </ng-template>
</div>