import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { OverallRatingPopupComponent } from '../../other-components/overall-rating-popup/overall-rating-popup.component';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-order-rating-page',
  templateUrl: './order-rating-page.component.html',
  styleUrls: ['./order-rating-page.component.scss'],
})
export class OrderRatingPageComponent implements OnInit {
  constructor(
    private userService: UserAccountService,
    private landingComponentService: LandingComponentService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  orderId: string;
  foodCourtId: string;
  orderNumber: string;
  vendorList;
  itemList;
  foodCourtName: string = '';
  orderType: string;
  userId: string;
  showLoader: boolean = false;

  ngOnInit(): void {
    this.userId = this.landingComponentService.getUserId();

    this.orderId = this.userService.getReviewOrderId();
    this.getOrderDetails();
    this.getScreenSize();
  }

  scrHeight;
  scrWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }


  getOrderDetails() {
    this.showLoader = true;
    this.userService.getOrderDetailsById(this.orderId).subscribe(
      (response: any) => {
        this.orderNumber = response.data.orderNum;
        this.vendorList = response.data.vendorOrderDetails;
        this.foodCourtId = response.data.foodCourtId;
        this.orderType = response.data.orderType;

        if(this.vendorList && this.vendorList.length > 0) {
          this.vendorList.forEach((element) => {
            let url = element.vendorImgUrl;
            let pattern = /^((http|https|ftp):\/\/)/;
            if (pattern.test(url)) {
              element.vendorImgUrl = url;
            } else {
              element.vendorImgUrl = '';
            }
          })
        }

        if (this.orderType === 'DELIVERY') {
          this.reviewContainer.deliveryBoyDetails =
            response.data.deliveryBoyDetails;
        }

        this.vendorList.forEach((vendor, vendorIndex) => {
          vendor.ratingIcons = this.ratingIcons.map((icon) => {
            return { ...icon };
          });
          vendor.reasonIcons = this.reasonIcons.map((icon) => {
            return { ...icon };
          });

          vendor.reasonIcons.splice(3, 1);

          this.reviewContainer.vendorReviews[vendorIndex] = {
            vendorId: vendor.vendorId,
            vendorName: vendor.vendorName,
            ratingCount: '',
            reason: [],
            itemWiseRatingList: [],
          };

          vendor.items.forEach((item, itemIndex) => {
            item.ratingIcons = this.ratingIcons.map((icon) => {
              return { ...icon };
            });
            item.giveRating = false;

            this.reviewContainer.vendorReviews[vendorIndex].itemWiseRatingList[
              itemIndex
            ] = {
              itemId: item.itemId,
              itemName: item.itemName,
              rating: '',
              review: ''
            };
          });
        });

        this.selectFCRatingIcon(this.ratingIcons[4]);

        this.reasonIcons.forEach((element) => {
          this.selectOrderReviewType(element);
        });

        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
        //console.log(err);
      }
    );
  }
  selectedIcon: any = '';
  ratingIcons: any = [
    { name: 'Very Bad', value: 1, src: 'poor-quality', status: false },
    { name: 'Bad', value: 2, src: 'not-good', status: false },
    { name: 'Average', value: 3, src: 'average', status: false },
    { name: 'Very Good', value: 4, src: 'very-good', status: false },
    { name: 'Loved It', value: 5, src: 'love-it', status: false },
  ];
  reasonIcons: any = [
    { name: 'Taste', value: 'TASTE', src: 'tasty', status: false },
    { name: 'Package', value: 'PACKAGE', src: 'package', status: false },
    { name: 'Portion', value: 'PORTION', src: 'portion', status: false },
    { name: 'Delivery', value: 'DELIVERY', src: 'delivery', status: false },
  ];

  selectFCRatingIcon(icon) {
    this.ratingIcons.forEach((element) => {
      element.status = false;
    });
    icon.status = true;
    this.selectedIcon = icon;
    this.reviewContainer.orderRating = icon.value;

    this.reviewContainer.vendorReviews.forEach((vendor, vendorIndex) => {
      vendor.ratingCount = icon.value;
      this.vendorList[vendorIndex].ratingIcons.forEach(
        (icon) => (icon.status = false)
      );
      this.vendorList[vendorIndex].ratingIcons[icon.value - 1].status = true;

      vendor.itemWiseRatingList.forEach((item, itemIndex) => {
        item.rating = icon.value;
        this.vendorList[vendorIndex].items[itemIndex].ratingIcons.forEach(
          (icon) => (icon.status = false)
        );
        this.vendorList[vendorIndex].items[itemIndex].ratingIcons[
          icon.value - 1
        ].status = true;
      });
    });
  }

  selectOrderReviewType(icon) {
    if (this.orderType !== 'DELIVERY') {
      if (icon.value === 'DELIVERY') {
        return;
      }
    }

    this.reasonIcons.forEach((element) => {
      if (icon.value === element.value) {
        icon.status = !icon.status;
      }
    });

    if (icon.status) {
      this.reviewContainer.reason.push(icon.value);
    } else {
      this.reviewContainer.reason.forEach((reason, index) => {
        if (reason === icon.value) {
          this.reviewContainer.reason.splice(index, 1);
        }
      });
    }

    if (icon.status && icon.value !== 'DELIVERY') {
      this.reviewContainer.vendorReviews.forEach((vendor, vendorIndex) => {
        vendor.reason = this.reviewContainer.reason.map((element) => {
          return element;
        });
        this.vendorList[vendorIndex].reasonIcons.forEach((vendorIcon) => {
          if (vendor.reason.includes(vendorIcon.value))
            vendorIcon.status = true;
        });
      });
    } else if (!icon.status && icon.value !== 'DELIVERY') {
      this.reviewContainer.vendorReviews.forEach((vendor, vendorIndex) => {
        vendor.reason.forEach((reason, i) => {
          if (reason === icon.value) {
            vendor.reason.splice(i, 1);
          }
        });
        this.vendorList[vendorIndex].reasonIcons.forEach((vendorIcon) => {
          if (vendorIcon.value === icon.value) vendorIcon.status = false;
        });
      });
    } else if (icon.status && icon.value === 'DELIVERY') {
      let RatingDialog = this.dialog.open(OverallRatingPopupComponent, {
        width: '90vw',
        height: 'auto',
        maxWidth: '100vw',
        maxHeight: '80vh',
        data: {
          for: 'DELIVERY_RATING',
          deliverBoyDetails: this.reviewContainer.deliveryBoyDetails,
        },
        panelClass: 'no-padding',
      });

      RatingDialog.afterClosed().subscribe((data) => {
        if (data) {
          this.reviewContainer.deliveryBoyDetails = data;
        }
      });
    }
  }

  selectVendorRating(icon, vendorDetails, index) {
    vendorDetails.ratingIcons.forEach((element) => {
      element.status = false;
    });
    icon.status = true;

    this.reviewContainer.vendorReviews[index].vendorId = vendorDetails.vendorId;
    this.reviewContainer.vendorReviews[index].vendorName =
      vendorDetails.vendorName;
    this.reviewContainer.vendorReviews[index].ratingCount = icon.value;
  }

  selectVendorOrderReviewType(icon, vendorDetails, index) {
    this.reviewContainer.vendorReviews[index].vendorId = vendorDetails.vendorId;
    this.reviewContainer.vendorReviews[index].vendorName =
      vendorDetails.vendorName;

    vendorDetails.reasonIcons.forEach((element) => {
      if (icon.value === element.value) icon.status = !icon.status;
    });

    if (icon.status) {
      this.reviewContainer.vendorReviews[index].reason.push(icon.value);
    } else {
      this.reviewContainer.vendorReviews[index].reason.forEach((reason, i) => {
        if (reason === icon.value) {
          this.reviewContainer.vendorReviews[index].reason.splice(i, 1);
        }
      });
    }
  }

  selectItemRating(icon, itemDetails, vendorIndex, itemIndex) {
    itemDetails.ratingIcons.forEach((element) => {
      element.status = false;
    });
    icon.status = true;
    this.reviewContainer.vendorReviews[vendorIndex].itemWiseRatingList[
      itemIndex
    ].itemId = itemDetails.itemId;
    this.reviewContainer.vendorReviews[vendorIndex].itemWiseRatingList[
      itemIndex
    ].itemName = itemDetails.itemName;
    this.reviewContainer.vendorReviews[vendorIndex].itemWiseRatingList[
      itemIndex
    ].rating = icon.value;
  }

  reviewContainer = {
    foodCourtId: '',
    orderId: '',
    deliveryBoyDetails: null,
    userName: '',
    userId: '',
    orderRating: 0,
    reason: [],
    review: '',
    vendorReviews: [],
  };

  buttonLoading: boolean = false;
  submitReview() {
    this.buttonLoading = true;

    this.reviewContainer.foodCourtId = this.foodCourtId;
    this.reviewContainer.orderId = this.orderId;
    this.reviewContainer.userId = this.landingComponentService.getUserId();
    this.reviewContainer.userName = this.landingComponentService.getUserName();

    //console.log(this.reviewContainer);
    this.userService.submitUserReview(this.reviewContainer).subscribe(
      (response) => {
        this.buttonLoading = false;
        this.landingComponentService.openSnackBarMessage(
          'Thanks for your feedback!!',
          'Close'
        );
        let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
        this.router.navigate([`${foodCourtName}`]);
      },
      (err) => {
        this.buttonLoading = false;
        this.landingComponentService.openSnackBarMessage(
          'Error while submitting your review',
          'Close'
        );
        //console.log(err);
      }
    );
  }

  goToOrderHistoryPage() {
    
    if (this.userService.getinfo_order()==="CART"){
      let foodcourtNameUrl = this.foodCourtName ? this.foodCourtName.split(' ').join('-').toLowerCase() : ' ';
      if (this.scrWidth > 600) {
          this.router.navigate([`/${foodcourtNameUrl}`]);
      } else {
        
        this.router.navigate([`cart/${foodcourtNameUrl}`]);
      }
    }
    else {
      this.router.navigate(['profile/user/order-history']);
    }
    
  }
  showItemReviewFeild(item) {
    item.giveRating = !item.giveRating;
  }
}
