<section>
  <div class="account-page-section">
    <div class="account-page-main-header">
      <div class="left-section">
        <img loading="lazy" [src]="
                  userDetails?.profileImg
                    ? userDetails.profileImg
                    : '../../../../../../../assets/images/dummy/dummy_user.svg'
                " alt="Profile" alt="">
        <div class="user-info">
          <div class="user-name">{{ userDetails?.userName | titlecase }}</div>
          <div class="user-mobile">{{ userDetails?.userMobile }}</div>
          <!-- <div class="user-reviews">Reviews (1)</div> -->
        </div>
      </div>
      <div class="right-section">
        <button class="edit-profile-btn" (click)="editProfile()">Edit Profile</button>
        <button class="logout-btn" (click)="logoutUser()">Logout</button>
      </div>
    </div>
    <div class="account-page-main-content">
      <div class="side-bar">
        <ul>
          <ng-container *ngFor="let item of sidePanel">
            <li [ngClass]="{'selected': item.status}" (click)="selectSideMenu(item.value)">
              <img src="../../../../../../../assets/images/account-page-tab/{{item.imgName}}.svg" alt="">
              <span>{{ item.name }}</span>
            </li>
          </ng-container>
        </ul>
        <!-- <div class="recharge-points-button">
                    <button class="recharge-points-btn" (click)="rechargeWallet()">Recharge Points</button>
                </div> -->
      </div>
      <div class="content-page">
        <!-- <div [ngSwitch]="selectedType">
                    <div *NgSwitchCase="ORDERS">ORDER</div>
                    <div *NgSwitchCase="SETTINGS">Settings</div>
                </div> -->
        <ng-container *ngIf="selectedType === 'ORDER_SUMMARY'">

        </ng-container>
        <ng-container *ngIf="selectedType === 'ORDERS'">
          <ng-container *ngIf="!orderListingLoader; else showOrderListingLoader">
            <div class="order-type-list">
              <ng-container *ngFor="let order of ORDER_TYPE">
                <div class="order-type-container" [ngClass]="{'active': order?.status}"
                  (click)="changeOrderType(order)">{{ order?.name }}</div>
              </ng-container>
            </div>
            <hr />
            <div class="order-history-list" *ngIf="ordersList?.length > 0; else emptyOrderHistory">
              <ng-container *ngFor="let order of ordersList">
                <div class="order-container" (click)="showOrderSummary(order)">
                  <div class="order-container-top-section">
                    <div class="order-container-left-section">
                      <div class="order-id" *ngIf="order?.orderNum">Order Id: {{ order.orderNum }}</div>
                      <div class="request-id" *ngIf="order?.btcRequestDisplayId">Request Id: {{
                        order.btcRequestDisplayId }}</div>
                      <ng-template #showOrderStatus>
                        <div class="order-status">{{ order?.requestStatus }}</div>
                      </ng-template>
                      <div class="sponsor-text" *ngIf="
                                                order.paymentDetails &&
                                                order.paymentDetails['Employer Points'] &&
                                                order.paymentDetails['Employer Points'] !== '0.0'
                                                ">
                        <ng-container *ngIf="order.oneclickOrderType === 'BTC'; else showSponseredMeal">
                          Corporate Order
                        </ng-container>
                        <ng-template #showSponseredMeal>
                          Sponsored Meal
                        </ng-template>
                      </div>
                      <div class="item-list-container">
                        <div class="text">Items</div>
                        <ng-container *ngIf="order?.vendorOrderDetails; else showRequestItemList">
                          <ng-container *ngFor="let vendor of order.vendorOrderDetails">
                            <ng-container *ngFor="let item of vendor.items">
                              <div>{{ item.itemQuantity }} x {{ item.itemName | titlecase }}</div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-template #showRequestItemList>
                          <ng-container *ngFor="let item of order.orderedItemsList">
                            <div>{{ item.itemQuantity }} x {{ item.itemName | titlecase }}</div>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="order-date">
                        <span>Ordered On: </span>
                        <span>
                          {{ order.createdTimestamp | date: "mediumDate":"UTC+0" }} at
                          {{ order.createdTimestamp | date: "shortTime":"UTC+0" }}
                        </span>
                      </div>
                    </div>
                    <div class="order-container-right-section">
                      <div class="total-paid">
                        <span *ngIf="order.orderNum">Total Paid: </span>
                        <!-- <span>&#8377; {{ ( order?.totalPrice ? order?.totalPrice : order?.billDetails?.finalPrice ) /
                          100 | number: "1.1-2" }}</span> -->
                        <span>&#8377; {{ ( order?.totalPrice ? order?.totalPrice : order?.billDetails?.finalPrice ) |
                          number: "1.1-2" }}</span>
                      </div>
                      <!-- <div class="order-status">
                                                {{ order?.orderdeliveryStatus }}
                                            </div> -->
                    </div>
                  </div>
                  <div class="order-container-bottom-section" *ngIf="selectedOrderType === 'PRE_ORDERS'">
                    <hr />
                    <div class="scheduled-on-text">Scheduled On</div>
                    <div class="scheduled-on-date">{{ order?.preOrderDate | date }} at {{ order?.preOrderStartTime |
                      date:'shortTime' }} - {{ order?.preOrderEndTime | date:'shortTime' }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
            <ng-template #emptyOrderHistory>
              <div class="order-list-empty">
                <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
                <span class="heading-text">Order List is Empty</span>
                <div class="secondary-text">You have not ordered any item.</div>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #showOrderListingLoader>
            <div class="loader-img-container">
              <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="">
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="selectedType === 'FAVOURITES'">
          <div class="order-list-empty">
            <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
            <span class="heading-text">Favourite List is Empty</span>
            <div class="secondary-text">You have not added any item as favourite.</div>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedType === 'REVIEWS'">
          <div class="order-list-empty">
            <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
            <span class="heading-text">No Review Given</span>
            <div class="secondary-text">You have not given any review.</div>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedType === 'OFFERS'">
          <div class="order-list-empty">
            <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
            <span class="heading-text">Offer List is Empty</span>
            <div class="secondary-text">You do not have any offers.</div>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedType === 'POINTS_HISTORY'">
          <ng-container *ngIf="!pointsHistoryLoader; else showPointsHistoryLoader">
            <div class="points-history-section" *ngIf="!showRechageWalletPage">
              <div class="points-history__dashboard">
                <div class="dashboard-points">{{ userRewardPoints.istharaPoints }}</div>
                <div class="dashboard-text">Isthara Points</div>
              </div>
              <div class="points-history__history-container">
                <div class="history-container__section">
                  <div class="history-container__primary-text">Transaction History <span *ngIf="isFilteredData"
                      class="primary-text">(Filtered)</span> </div>
                  <div class="filter-container">
                    <img src="../../../../../../../assets/images/common/filter-icon.svg" alt="" (click)="filterbtn()"
                      class="filter-icon" *ngIf="!isFilteredData">
                    <img src="../../../../../../../assets/images/common/close.svg" alt="" (click)="closeFilter()"
                      class="close-icon" *ngIf="isFilteredData">
                  </div>
                  <div class="i-btn-container">
                    <mat-icon>info</mat-icon>
                    <div class="refund-message">
                      If you opted for both points and other payment method, bank refund option will be enabled once the
                      refund value exceeds Isthara points payment value.
                    </div>
                  </div>
                </div>
                <div class="transaction-history-panel">
                  <ng-container *ngFor="let panel of transactionHistoryPanel">
                    <div class="panel-container" [ngClass]="{'panel-selected': panel.status}"
                      (click)="changeTransactionHistoryPanel(panel)">{{ panel.name | titlecase }}</div>
                  </ng-container>
                  <hr />
                </div>

                <div class="history-container__points-list">
                  <ng-container *ngFor="let userHistory of userPointsHistory">
                    <div class="user-points-history-container">
                      <div class="main-container" (click)="showPointsSummary(userHistory)">
                        <img src="../../../../../../../assets/images/common/wallet-icon.svg" alt="">
                        <div class="points-details">
                          <div class="points-desc">
                            <span *ngIf="userHistory.paymentType === 'RECHARGE'">Recharged Wallet</span>
                            <span *ngIf="userHistory.paymentType === 'BANK_REFUND'">Refund back on payment source</span>
                            <span *ngIf="userHistory.paymentType === 'REFUND'">Refund received against order : {{
                              userHistory.orderNumber ? userHistory.orderNumber :'N/A' }}</span>
                            <span *ngIf="userHistory.paymentType === 'PAID'">Paid against order : {{
                              userHistory.orderNumber ? userHistory.orderNumber :'N/A' }}</span>
                          </div>
                          <div class="date-time">{{userHistory.createdDateTime | date:'mediumDate'}}
                            {{userHistory.createdDateTime | date:'mediumTime'}} </div>
                        </div>
                        <div class="points-amount"
                          [ngClass]="{'refund': (userHistory.paymentType === 'REFUND' || userHistory.paymentType === 'RECHARGE' || userHistory.paymentType === 'BANK_REFUND'), 'paid': userHistory.paymentType === 'PAID'}">
                          <div *ngIf="selectedTransactionHistoryPanel === 'POINTS_HISTORY'">
                            <span
                              *ngIf="(userHistory.refundStatus !== 'REFUND_INITIATED' && userHistory.refundStatus !== 'REFUND_COMPLETED'); else showBankRefund">
                              <!-- {{(userHistory?.istharaRewardPoints / 100) | number:'1.1-2' }} -->
                              {{(userHistory?.istharaRewardPoints) | number:'1.1-2' }}
                            </span>
                            <ng-template #showBankRefund>
                              <!-- <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount * 100)) / 100) | number:'1.1-2' }}</span> -->
                              <!-- <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount)) /
                                100) | number:'1.1-2' }}</span> -->
                              <span>{{ ((userHistory?.istharaRewardPoints - (userHistory?.displayRefundableAmount))) |
                                number:'1.1-2' }}</span>
                            </ng-template>
                          </div>
                          <div *ngIf="selectedTransactionHistoryPanel === 'BANK_REFUNDS'">
                            <span *ngIf="userHistory?.displayRefundableAmount > 0">
                              {{ (userHistory?.displayRefundableAmount) | number:'1.1-2' }}
                            </span>
                            <span *ngIf="userHistory?.displayRefundableAmount <= 0">
                              <!-- {{ (userHistory?.istharaRewardPoints / 100) | number:'1.1-2' }} -->
                              {{ (userHistory?.istharaRewardPoints) | number:'1.1-2' }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="action-btn"
                        *ngIf="userHistory.refundable && userHistory.showButton && userHistory.transactionType !== 'BANK_REFUNDED' && userHistory.refundStatus === 'NEW' ">
                        <button (click)="refundToWallet(userHistory)">Refund back to Source &#8377;{{
                          userHistory?.displayRefundableAmount | number: '1.1-2' }}</button>
                      </div>
                      <div class="cashfree-points-refund-message"
                        *ngIf="(userHistory.refundStatus === 'REFUND_INITIATED' || userHistory.refundStatus === 'REFUND_COMPLETED') && userHistory?.displayRefundableAmount > 0">
                        Your refund of &#8377;{{ userHistory?.displayRefundableAmount | number:'1.1-2' }} is <span
                          *ngIf="userHistory.refundStatus === 'REFUND_INITIATED'">initiated</span> <span
                          *ngIf="userHistory.refundStatus === 'REFUND_COMPLETED'">completed</span>. </div>

                    </div>
                    <hr class="horizontal-line" />
                  </ng-container>
                </div>
              </div>
              <div class="recharge-wallet-btn">
                <button (click)="rechargeWallet()">Recharge Points</button>
              </div>
            </div>
            <div class="recharge-wallet-section" *ngIf="showRechageWalletPage">
              <div class="recharge-points__input-field">
                <!-- <div class="ruppe-symbol">&#8377;</div> -->
                <input type="number" onkeydown="if(event.key==='.'){event.preventDefault();}" class="input-field"
                  matInput min="0" placeholder="0.00" [(ngModel)]="paymentAmount"
                  (ngModelChange)="removeSelectedFieldStatus()">
              </div>
              <div class="recharge-points__selection-field">
                <ng-container *ngFor="let point of rechargePointsSelectionList">
                  <div class="recharge-points__field" (click)="selectPaymentAmountFromList(point)"
                    [ngClass]="{'point-active': point.status}">+&#8377; {{ point.value }}</div>
                </ng-container>
              </div>
              <div class="recharge-points-payment-btn">
                <button class="go-back-btn" (click)="goToPointsHistoryPage()">Go Back</button>
                <button class="pay-recharge-btn" [disabled]="paymentAmount <= 0 || paymentAmount === undefined"
                  (click)="payWithCashFree()" *ngIf="!paymentLoader; else showPaymentLoader">Proceed To Add
                  Money</button>
                <ng-template #showPaymentLoader>
                  <button>
                    <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                  </button>
                </ng-template>


                <!-- <form id="redirectForm" method="post" [action]="formDataView.paymentLink"> -->
                <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit

                                    production ===> https://www.cashfree.com/checkout/post/submit-->
                <!-- <input
                                    id="appId"
                                    type="hidden"
                                    name="appId"
                                    [value]="formDataView.appId"
                                    />
                                    <input type="hidden" name="orderId" [value]="formDataView.orderId" />
                                    <input
                                    type="hidden"
                                    name="orderAmount"
                                    [value]="formDataView.orderAmount"
                                    />
                                    <input type="hidden" name="orderCurrency" [value]="'INR'" />
                                    <input type="hidden" name="orderNote" [value]="'Order'" />
                                    <input
                                    type="hidden"
                                    name="customerName"
                                    [value]="formDataView.customerName"
                                    />
                                    <input
                                    type="hidden"
                                    name="customerEmail"
                                    [value]="formDataView.customerEmail"
                                    />
                                    <input
                                    type="hidden"
                                    name="customerPhone"
                                    [value]="formDataView.customerPhone"
                                    />
                                    <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
                                    <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
                                    <input type="hidden" name="signature" [value]="formDataView.signature" />
                                </form> -->

              </div>
            </div>
          </ng-container>
          <ng-template #showPointsHistoryLoader>
            <div class="loader-img-container">
              <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="">
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="selectedType === 'HELPDESK'">
          <ng-container *ngIf="!helpdeskLoader; else showHelpdeskLoader">
            <div class="helpdesk-main-listing" *ngIf="showHelpdeskListing">
              <div class="order-type-list">
                <ng-container *ngFor="let issue of issueTypeListing">
                  <div class="order-type-container" [ngClass]="{'active': issue?.status}"
                    (click)="changeIssueType(issue)">{{ issue?.name }}</div>
                </ng-container>
              </div>
              <hr />
              <!-- <div class="open-issue-dropdown" *ngIf="selectedIssueType === 'OPEN_ISSUES'">
                                <mat-form-field appearance="outline" >
                                    <mat-select [(ngModel)]="selectedIssueInOpenIssue" (ngModelChange)="changeOpenIssue()">
                                        <ng-container *ngFor="let openIssueType of openIssueTypeListing">
                                            <mat-option [value]="openIssueType.value">{{ openIssueType.name }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->
              <div class="helpdesk-issue-listing">
                <ng-container *ngIf="issueListing.length > 0; else showEmptyIssueListing">
                  <ng-container *ngFor="let item of issueListing">
                    <div class="single-ticket-container">
                      <div class="single_ticket" (click)="goToTicketDetailPage(item)">
                        <div class="ticket_action_id">
                          <div class="ticket_action" [ngStyle]="{
                                                    color:
                                                    item?.ticketStatus === 'NEW'
                                                    ? '#FF74AA'
                                                    : item?.ticketStatus === 'IN_PROGRESS'
                                                    ? '#FD9357'
                                                    : item?.ticketStatus ==='RESOLVED'
                                                    ? '#46948F'
                                                    : item?.ticketStatus === 'CLOSED'
                                                    ? '#28706C'
                                                    : item?.ticketStatus === 'ASSIGNED'
                                                    ? '#FFD32D'
                                                    : item?.ticketStatus === 'RE_OPENED'
                                                    ? '#FF74AA'
                                                    :null
                                                    }
                                                    ">&#x2022; {{item?.ticketStatus.split('_').join(' ') | titlecase}}
                          </div>
                          <div class="ticket_id">
                            <span class="ticket_text">Ticket Id: </span>&nbsp;
                            <span class="id">{{item?.helpdeskDisplayId}}</span>
                          </div>
                        </div>
                        <div class="img_issue-title_issue-description">
                          <div class="issue_image">
                            <img
                              src="./../../../../../../../../assets/images/help_desk/{{item?.category.split(' ').join('_')}}.svg"
                              alt="delivery_related">
                          </div>
                          <div class="title_description">
                            <div class="title">{{item?.subCategory | titlecase}}</div>
                            <div class="description">{{item?.issueDescription | titlecase}}</div>
                          </div>
                        </div>
                        <div class="issue_generated_date"
                          *ngIf="(!item.resolvedDesc || item.ticketStatus === 'RE_OPENED')">
                          {{item?.createdTimestamp | date:'medium'}}
                        </div>
                        <div class="issue_resolved_description"
                          *ngIf="item.resolvedDesc && item.ticketStatus !== 'RE_OPENED'">
                          {{ item.resolvedDesc.split('UTC')[0] | titlecase }}
                        </div>
                        <div class="rating-action-btn">
                          <ng-container *ngIf="(item?.ticketStatus ==='RESOLVED' || item?.ticketStatus === 'CLOSED')">
                            <hr />
                            <ng-container *ngIf="!item.serviceRating">
                              <div class="rate-service-btn" (click)="rateResolvedTicket(item)">
                                <button>Rate Service</button>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.serviceRating">
                              <div class="rating-container">
                                <div class="rating-text">Rated: </div>
                                <div class="rating-star-container">
                                  <ng-container *ngFor="let i of [].constructor(item.serviceRating)">
                                    <img src="../../../../../../../../assets/images/ratings/rating-star-fill.svg"
                                      alt="">
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </ng-container>
                <div class="go-to-raise-ticket" (click)="goToCategoryListingPage()">
                  <img src="./../../../../../../../../assets/images/help_desk/add_ticket.svg" alt="add"
                    class="add-ticket" (click)="goToCategoryListingPage()">
                </div>
                <ng-template #showEmptyIssueListing>
                  <div class="empty-part">
                    <div class="no-image">
                      <img src="./../../../../../../../../assets/images/help_desk/empty_screen.svg" alt="empty-image" />
                    </div>
                    <div class="empty-heading" *ngIf="selectedIssueType === 'ALL_ISSUES'">No Issues</div>
                    <div class="empty-heading" *ngIf="selectedIssueType === 'OPEN_ISSUES'">No Opened Issues</div>
                    <div class="empty-heading" *ngIf="selectedIssueType === 'INPROGRESS_ISSUES'">No Inprogress Issues
                    </div>
                    <div class="empty-heading" *ngIf="selectedIssueType === 'RESOLVED_ISSUES'">No Resolved Issues</div>
                    <div class="empty-subtext" *ngIf="selectedIssueType === 'ALL_ISSUES'">You have not added any issue
                      yet.</div>
                    <div class="empty-subtext" *ngIf="selectedIssueType === 'RESOLVED_ISSUES'">You don't have any opened
                      tickets.</div>
                    <div class="empty-subtext" *ngIf="selectedIssueType === 'OPEN_ISSUES'">You don't have any resolved
                    </div>
                    <div class="empty-subtext" *ngIf="selectedIssueType === 'INPROGRESS_ISSUES'">You don't have any
                      inprogress tickets.</div>
                    <!-- <div class="emptyrules-text">the menu</div> -->
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="helpdesk-category-listing" *ngIf="showHelpdeskCategoryListing">
              <div class="category-listing-header">
                <div class="back-btn_container" (click)="goToHelpDeskListingPage()">
                  <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
                </div>
                <div class="category-listing__header">Helpdesk Category</div>
              </div>
              <div class="category-list">
                <ng-container *ngFor="let category of helpdeskCategoryList">
                  <div class="category-block" [style.color]="category?.color" (click)="goToRaiseTicketPage(category)">
                    <div class="category-images">
                      <img [src]="category.helpdeskCategoryImageUrl">
                    </div>
                    <div class="category-text">
                      {{ category.helpdeskCategoryName | titlecase }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="helpdesk-raise-ticket" *ngIf="showHelpdeskRaiseTicket">
              <div class="raise-issue__content">
                <div class="issue-type-container">
                  <div class="issue-type">{{ helpdeskCategoryName | uppercase }}</div>
                  <!-- <div class="change-category-type" (click)="goToCategoryListingPage()">Change</div> -->
                </div>
                <div class="search-foodcourt-container">
                  <ng-container *ngIf="!helpdeskFoodCourtId; else raiseTicketWithFC">
                    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                      placeholder="Search Foodcourt">
                    <div class="search-icon-container">
                      <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="">
                    </div>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                        (click)="selectFoodCourt(option)">
                        {{option.name | titlecase}}
                      </mat-option>
                    </mat-autocomplete>
                  </ng-container>
                  <ng-template #raiseTicketWithFC>
                    <span class="selected-foodcourt">{{ selectedFoodCourt.name | titlecase }}</span>
                  </ng-template>
                </div>
                <div class="issue-sub-type-container">
                  <div class="issue-sub-type__primary-text">Tell us your problem?</div>
                  <div class="issue-sub-type__secondary-text">You can select from below</div>
                  <div class="issue-sub-type__selection-container">
                    <mat-radio-group [(ngModel)]="selectedIssueType" [color]="'primary'">
                      <mat-radio-button *ngFor="let issueType of issueSubTypeList"
                        [value]="issueType.helpdeskSubCategoryName">
                        {{ issueType.helpdeskSubCategoryName | titlecase }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="issue-description-container">
                  <div class="issue-description__primary-text">Addd Details</div>
                  <div class="issue-description__secondary-text">Details you think are important for us to know</div>
                  <div class="issue-description-field">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <textarea matInput placeholder="Write about your issue" [(ngModel)]="issueDescription"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="issue-pictures-container">
                  <div class="issue-picture__primary-text">Add Photo</div>
                  <div class="issue-picture__secondary-text">Photos help us to find best staff and tools for your need
                    as soon as possible</div>
                  <div class="issue-picture__list-container">
                    <!-- <div class="add-image-container">
                                            <img src="../../../../../../../../assets/images/common/empty-menu.svg" alt="">
                                        </div> -->
                    <div class="image-sub-box">
                      <label for="picUpload">
                        <img *ngIf="!loaderSpinner; else loaderSpinners"
                          src="../../../../../../../../assets/images/common/upload-pics.svg" alt="upload-pic"
                          class="img-box" />
                        <ng-template #loaderSpinners>
                          <div class="spinner">
                            <mat-spinner></mat-spinner>
                          </div>
                        </ng-template>
                      </label>

                      <input style="visibility: hidden;" type="file" class="pic-upload" id="picUpload"
                        (change)="addMore($event)" accept="image/x-png,image/gif,image/jpeg" multiple />
                    </div>
                    <div class="list-image-container">
                      <ng-container *ngFor="let image of updatedImage">
                        <div class="issue-image-container">
                          <img [src]="image.imageUrl" alt="">
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="raise-issue__submit-btn-container">
                <button class="go-back" (click)="goToCategoryListingPage()">Go Back</button>
                <button class="submit-btn" (click)="createHelpDeskTicketForUser()"
                  [disabled]="!(selectedFoodCourt.id && selectedIssueType)">
                  <span *ngIf="!submitButtonLoader">Submit</span>
                  <span *ngIf="submitButtonLoader"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
                </button>
              </div>
            </div>
            <div class="helpdesk-view-ticket" *ngIf="showHelpdeskTicketDetails">
              <div class="helpdesk-view-content">
                <div class="ticket-details__header-container">
                  <div class="header-container__left-section">
                    <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt=""
                      (click)="goToHelpDeskListingPage()">
                    <div class="header__primary-text">Track Issue</div>
                  </div>
                  <div class="header-container__right-section">
                    <div class="ticket-status" [ngStyle]="{
                                            color:
                                            helpDeskDetails.helpdeskDetails?.ticketStatus === 'NEW'
                                            ? '#FF74AA'
                                            : helpDeskDetails.helpdeskDetails?.ticketStatus === 'IN_PROGRESS'
                                            ? '#FD9357'
                                            : helpDeskDetails.helpdeskDetails?.ticketStatus ==='RESOLVED'
                                            ? '#46948F'
                                            : helpDeskDetails.helpdeskDetails?.ticketStatus === 'CLOSED'
                                            ? '#28706C'
                                            : helpDeskDetails.helpdeskDetails?.ticketStatus === 'RE_OPENED'
                                            ? '#FF74AA'
                                            : helpDeskDetails.helpdeskDetails?.ticketStatus === 'ASSIGNED'
                                            ? '#FFD32D'
                                            :null
                                            }">
                      <img src="../../../../../../../../assets/images/help_desk/resolved-check.svg" alt=""
                        class="issue-status-icon"
                        *ngIf="helpDeskDetails.helpdeskDetails?.ticketStatus ==='RESOLVED' || helpDeskDetails.helpdeskDetails?.ticketStatus ==='CLOSED'">
                      <img src="../../../../../../../../assets/images/help_desk/inprogress-check.svg" alt=""
                        class="issue-status-icon"
                        *ngIf="helpDeskDetails.helpdeskDetails?.ticketStatus ==='IN_PROGRESS'">
                      {{ helpDeskDetails.helpdeskDetails.ticketStatus.split('_').join(' ') }}
                    </div>
                  </div>
                </div>
                <div class="ticket-overview-details">
                  <div class="ticket-overview__left-section">
                    <div class="ticket-image">
                      <img [src]="helpDeskDetails?.helpdeskDetails?.categoryImageUrl" alt=""
                        *ngIf="helpDeskDetails?.helpdeskDetails?.categoryImageUrl">

                      <div class="profile-photo">
                        <div class="display-letter" *ngIf="!helpDeskDetails?.helpdeskDetails.categoryImageUrl">
                          {{ helpDeskDetails?.helpdeskDetails?.category?.split("")[0] | uppercase }}
                        </div>
                      </div>
                    </div>
                    <div class="ticket-type-container">
                      <div class="ticket-type">{{ helpDeskDetails?.helpdeskDetails?.category | uppercase }}</div>
                      <div class="ticket-title">{{ helpDeskDetails?.helpdeskDetails?.subCategory | titlecase }}</div>
                    </div>
                  </div>
                  <div class="ticket-overview__right-section">
                    <span class="ticket-overview__ticket-id">Ticket Id: #{{
                      helpDeskDetails?.helpdeskDetails?.helpdeskDisplayId }}</span>
                  </div>
                </div>
                <div class="ticket-issue-details">
                  <div class="ticket-issue__description-container"
                    *ngIf="helpDeskDetails?.helpdeskDetails?.issueDescription">
                    <div class="issue-details__primary-text">Issue Details</div>
                    <hr />
                    <div class="issue-details__secondary-text">{{ helpDeskDetails?.helpdeskDetails?.issueDescription |
                      titlecase }}</div>
                  </div>
                  <div class="ticket-issue__image-container"
                    *ngIf="helpDeskDetails?.helpdeskDetails?.helpdeskIssueImages.length > 0">
                    <div class="issue-details__primary-text">Issue Images</div>
                    <hr />
                    <div class="issue-image-list-container">
                      <ng-container *ngFor="let image of helpDeskDetails?.helpdeskDetails?.helpdeskIssueImages">
                        <div class="issue-image-container">
                          <img [src]="image" alt="">
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="ticket-timeline" *ngIf="helpDeskDetails?.timeline.length > 0">
                    <div class="ticket-timeline__primary-text">Issue Progress</div>
                    <ng-container class="check">
                      <div class="history-tl-container">
                        <ul class="tl">
                          <ng-container *ngFor="let timeline of helpDeskDetails?.timeline">
                            <li class="tl-item">
                              <div class="ticket-top-section">
                                <div class="ticket-heading">{{ timeline.heading | titlecase }}</div>
                                <!-- - <div class="ticket-created-on">{{ timeline.actionTimestamp | date }}</div> -->
                              </div>
                              <div class="ticket-bottom-section ticket-description">{{ timeline.description | titlecase
                                }}</div>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="ticket-action-btn">
                  <div class="ticket-open-close-btn">
                    <!-- <div class="reopen-ticket-btn" *ngIf="helpDeskDetails?.helpdeskDetails?.ticketStatus === 'RESOLVED' && showReopenButton">
                                            <button (click)="reopenTicket()" *ngIf="!reopenIssueLoader" [disabled]="closeIssueLoader">Reopen Issue</button>
                                            <button *ngIf="reopenIssueLoader">
                                                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                                            </button>
                                        </div> -->
                    <!-- <div class="close-ticket-btn" *ngIf="helpDeskDetails?.helpdeskDetails?.ticketStatus === 'RESOLVED' || helpDeskDetails?.helpdeskDetails?.ticketStatus === 'RE_OPENED'" [ngStyle]="{width: (helpDeskDetails?.helpdeskDetails?.ticketStatus === 'RE_OPENED') || (helpDeskDetails?.helpdeskDetails?.ticketStatus === 'RE_OPENED' && !showReopenButton)
                                        ? '100%'
                                        :'48%'}">
                                            <button (click)="closeTicket(helpDeskDetails)" *ngIf="!closeIssueLoader" [disabled]="reopenIssueLoader">Close Issue</button>
                                            <button *ngIf="closeIssueLoader">
                                                <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                                            </button>
                                        </div> -->
                  </div>
                  <!-- <div class="remind-ticket-btn" *ngIf="helpDeskDetails.helpdeskDetails.ticketStatus === 'NEW'">
                                        <button (click)="remindTicketAction()">Remind</button>
                                    </div> -->
                  <div class="rate-issue-btn"
                    *ngIf="(helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' || helpDeskDetails.helpdeskDetails.ticketStatus === 'CLOSED')">
                    <ng-container *ngIf="helpDeskDetails?.serviceRating === 0">
                      <!-- *ngIf="(helpDeskDetails.helpdeskDetails.ticketStatus === 'RESOLVED' || helpDeskDetails.helpdeskDetails.ticketStatus === 'CLOSED')" -->
                      <!-- <button (click)="rateResolvedTicket(helpDeskDetails)">Rate Now</button> -->
                    </ng-container>
                    <ng-template #showHelpdeskLoader>
                        <div class="loader-img-container">
                            <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="">
                        </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectedType === 'FOOD_SUBSCRIPTION'">
            <section class="user-foodsubscription-listing">
                <div class="listing-heading">
                    <!-- <div class="back-arrow-container" (click)="goToUserAccountPage()">
                        <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
                    </div> -->
                    <div class="heading-text">Food Subscription</div>
                </div>
                <div class="foodsubsription-plan-type-list">
                    <ng-container *ngFor="let subscriptionType of USER_SUBSCRIPTION_TYPE">
                        <div class="plan-type" [ngClass]="{'active': subscriptionType.status}" (click)="changeUserSubscriptionListing(subscriptionType)">{{ subscriptionType.name | titlecase }}</div>
                    </ng-container>
                </div>
                <div class="subscription-plan-list-container">
                    <ng-container *ngIf="!mainLoader; else showLoadingScreen">
                        <ng-container *ngIf="subscriptionList.length > 0; else emptyScreen">
                            <ng-container *ngFor="let subscriptionPlan of subscriptionList">
                                <div class="subscription-card" (click)="openSubscriptionDetails(subscriptionPlan)">
                                    <div class="subscription-card-text">
                                        <img src="../../../../../../../assets/images/common/veg-item.svg" alt="">
                                        {{ subscriptionPlan.title | uppercase }}
                                    </div>
                                    <div class="subscription-card-description">
                                        <ul>
                                            <li>{{ subscriptionPlan.desc | titlecase }}</li>
                                        </ul>
                                    </div>
                                    <!-- <div class="subscription-card-timing">
                                        <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                                        <span>{{ subscriptionPlan.startTime | date:'shortTime' }} - {{ subscriptionPlan.endTime | date:'shortTime' }}</span>
                                    </div> -->
                                    <div class="subscription-card-footer">
                                        <div class="subscription-price"> 
                                            <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                                            {{ subscriptionPlan.startDate | date }} - {{ subscriptionPlan.endDate | date }}
                                        </div>
                                        <button class="subscribe-btn" [ngClass]="{'expired': subscriptionPlan.subscriptionStatus === 'EXPIRED', 'active': subscriptionPlan.subscriptionStatus === 'ACTIVE'}">
                                            <span>{{ subscriptionPlan.subscriptionStatus | titlecase }}</span>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #emptyScreen>
                            <div class="empty-part">
                                <div class="no-image">
                                <img
                                    src="../../../../../../assets/images/common/empty-order-list.svg"
                                    alt="empty-image"
                                />
                                </div>
                                <div class="empty-heading">Subscription list empty</div>
                                <div class="empty-subtext">You can see your Subscription List here</div>
                                <!-- <div class="emptyrules-text">the menu</div> -->
                            </div>
                        </ng-template>
                    </ng-container>
                    <ng-template #showLoadingScreen>
                        <div class="loader-img-container">
                            <img
                              src="../../../../../../assets/images/common/burger_loader.gif"
                              alt=""
                            />
                        </div>
                    </ng-template>
                </div>
            </section>
        </ng-container>
        <ng-container *ngIf="selectedType === 'CONTACT_US'">
            <ng-container *ngIf="!contactusLoader; else showcontactusLoader">
                <div class="contact-us-container"> 
                    <div class="header">
                        <!-- <div class="back-arrow">
                            <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="Back Arrow" (click)="goToProfilePage()"/>
                        </div> -->
                        <div class="contact">
                            Contact Us
                        </div>
                    </div>
                    <div class="list">
                        <mat-form-field appearance="outline" class="formfill">
                            <mat-select [(ngModel)]="selected_name">
                                <mat-option [value]="item.foodCourtId" *ngFor="let item of fcdetails;let i =index" (click)="gettinginfo(i)">{{item.foodCourtName | titlecase}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="detailinfo" *ngIf="details.length>0">
                        <div class="detailslist" *ngFor="let item of details">
                            <div class="detail-container">
                                <div class="information">
                                    <div class="info">{{item.staffName | titlecase}}</div>
                                    <div class="image">
                                        <img  src="../../../../../../../assets/images/common/user 1.svg">
                                    </div>
                                </div>
                                <hr>
                                <div class="information">
                                    <div class="info">{{item.mobile.mobile}}</div>
                                <div class="image">
                                    <img src="../../../../../../../assets/images/common/phonecall.svg">
                                </div>
                                </div>
                                <hr>
                                <div class="information">
                                    <div class="info">{{item.emailId}}</div>
                                    <div class="image">
                                        <img src="../../../../../../../assets/images/common/email.svg">
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="nulldetailsdiv" *ngIf="details.length===0">
                        <div class="detailslist">
                            <div class="detail-container">
                                <div class="information">
                                    <div class="info">Isthara Food Court</div>
                                    <div class="image">
                                        <img  src="../../../../../../../assets/images/common/user 1.svg">
                                    </div>
                                </div>
                                <hr>
                                <div class="information">
                                    <div class="info">8790858581</div>
                                <div class="image">
                                    <img src="../../../../../../../assets/images/common/phonecall.svg">
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #showcontactusLoader>
                <div class="loader-img-container">
                    <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="">
                </div>
            </ng-template>
        </ng-container>
              <!-- <ng-container *ngIf="selectedType === 'PAYMENTS'">
                  <div class="order-list">
                      <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
                      <span class="heading-text">Order List is Empty</span>
                      <div class="secondary-text">You have not ordered any item.</div>
                  </div>
              </ng-container> -->
        <ng-container *ngIf="selectedType === 'SETTINGS'">
          <div class="order-list-empty">
            <img src="../../../../../../../assets/images/account-page-tab/to-do-list.svg" alt="" />
            <span class="heading-text">No Configuration Done</span>
            <div class="secondary-text">No configuration needed.</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>