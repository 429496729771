import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IndexDBService } from './indexDB-storage/index-db.service';
import { LandingComponentService } from './landing-component.service';
import { SiteFoodcourtListComponent } from './majorFoodCourt-components/site-foodcourt-list/site-foodcourt-list.component';
import { v1 as uuidv1 } from 'uuid';
import { PopupDbService } from '../authentication/services/popup-db.service';
@Component({
  selector: 'app-landing-component',
  templateUrl: './landing-component.component.html',
  styleUrls: ['./landing-component.component.scss', './mq-landing-component.component.scss'],
  providers: [DatePipe]
})
export class LandingComponentComponent implements OnInit {
  constructor(
    private bottomSheet: MatBottomSheet,
    private landingConsoleService: LandingComponentService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private storageService: IndexDBService,
    private datePipe: DatePipe,
    private popupService:PopupDbService
  ) { 
    
    if (router.url.includes("discount"))
      this.inDiscountFlow = true;

    router.events.subscribe((event)=> {
      if (event instanceof NavigationStart) {
        if (event.url.includes('discount')) {
          this.inDiscountFlow = true;
        } else {
          this.inDiscountFlow = false;
        }
      }
    })
  }
  inDiscountFlow: boolean = false;

  showLoader: boolean = true;
  userPosition = {
    userLatitute: 0,
    userLongitute: 0,
  };
  currentPage: string = null;
  siteId: string = null;
  userId: string = null;
  siteErrorOccured: boolean = false;
  sidePanelType: string = "CART";
  activeTab: string = "HOME";
  currentUrl;

  async ngOnInit() {
    // For notification count that are not viewed by user
    // We need api for this but right now, there is no api
    // In future, make sure to ask for api for notifications count
    this.getUserNotifications();

    let deviceId = this.landingConsoleService.getUserDeviceId();
    if (!deviceId) {
      deviceId = uuidv1();
      this.landingConsoleService.setUserDeviceId(deviceId);
    }

    let foodcourtName = this.router.url;
    this.foodCourtId = this.landingConsoleService.getFoodCourtId();

    if (foodcourtName.includes('%20')) {
      let foodcourtUrl = foodcourtName.replace('%20', '-').toLowerCase();
      this.router.navigate([`${foodcourtUrl}`]);
    }

    this.getScreenSize();
    // For tablet screen
    if (this.scrWidth > 600) {

      this.currentUrl = (this.router.url.split('/').length > 2 ? this.router.url.split('/')[1] : '');
      this.checkForCurrentPage();

      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {
          this.currentUrl = (this.router.url.split('/').length > 2 ? this.router.url.split('/')[1] : '');
          this.checkForCurrentPage();
        }
      });
      this.landingConsoleService.sidePanelContent.subscribe((response) => {
        this.sidePanelType = response.toString();
      })
    }

    // this sets the local storage variable so that we know where user is visiting first time in our site or not and can show
    // Download button if it is first time
    this.landingConsoleService.setIsFirstTimeLoading(true);
    // if user added item to indexDB then login, we add the item to its cart
    this.userId = this.landingConsoleService.getUserId();
    if (this.userId) {

      const paymentType = this.landingConsoleService.getPaymentFor();

      if (!paymentType) {
        // we store all the cart data of user to indexDB storage
        
        this.getAllCartDataFromIndexDB();
      } else {
        this.getUserCartData();
      }
    }

    if (!foodcourtName.includes('?siteId=')) {
      
      if (foodcourtName.split('?')[0] !== "/foodcourt/order/payment") {
        if (foodcourtName.split('/').length === 2) {
          foodcourtName = foodcourtName.split("/")[1].replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
          await this.matchFoodcoutName(foodcourtName);
          this.getLocation();
        } else {
          this.getLocation();
        }
        // this.siteId = this.landingConsoleService.getSiteId();
        // this.foodCourtId = this.landingConsoleService.getFoodCourtId();
        // if (this.siteId && this.foodCourtId) {
        //   this.siteId = this.landingConsoleService.getSiteId();
        //   this.foodCourtId = this.landingConsoleService.getFoodCourtId();
        //   this.getLocation();
        // } else {
        // }
      }
    } else {
      this.activatedRouter.queryParams.subscribe(async (qParams) => {
        // For QR based landing
        this.foodCourtId = qParams?.foodCourtId;
        this.siteId = qParams?.siteId;

        if (this.foodCourtId && this.siteId) {
          this.landingConsoleService.setFoodCourtId(this.foodCourtId);
          this.landingConsoleService.setSiteId(this.siteId);
          this.getFoodCourtDetails();
        } else {
          this.siteId = this.landingConsoleService.getSiteId();
          this.foodCourtId = this.landingConsoleService.getFoodCourtId();
          this.getLocation();

          // if (!(this.siteId && this.foodCourtId)) {

          // }
        }
        // End of QR based landing code
      });
    }

  }

  foodCourtList: any[] = [];
  foodCourtDetails: any;
  foodCourtId: string = null;
  foodCourtName: string = null;
  siteName: string = null;
  siteList: any[] = [];
  scrHeight: number;
  scrWidth: number;
  siteImageUrl: string;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  headerList = [
    { name: "home", value: "", status: false },
    { name: "search", value: "SEARCH", status: false },
    { name: "account", value: "ACCOUNT", status: false }
  ]
  checkForCurrentPage() {

    this.headerList.forEach((element) => {
      if (element.value === this.currentUrl.toUpperCase()) element.status = true;
      else element.status = false;
    });
  }
  navigatePage(icon) {
    if (icon.name === 'home') {
      this.navigateToHome();
    } else if (icon.name === 'search') {
      this.navigateToSearch();
    } else if (icon.name === 'account') {
      this.goToAccountPage();
    }
  }

  goToHomePage() {
    let foodcourtName = this.landingConsoleService.getFoodCourtName();
    let foodCourtNameUrl = foodcourtName
      ? foodcourtName?.split(' ').join('-').toLowerCase()
      : ' ';

    this.router.navigate([`/${foodCourtNameUrl}`]);
  }

  async matchFoodcoutName(foodCourtName) {

    return new Promise(async (resolve, reject) => {
      await this.landingConsoleService.getAllFoodcourts().toPromise().then((response: any) => {

        for (let i = 0; i < response.data.length; i++) {

          let currFoodcourtName = response.data[i].foodCourtName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

          if (currFoodcourtName.includes(foodCourtName)) {

            let foodCourtDetails = response.data[i];
            this.storeInLocalStorage(foodCourtDetails);

            let foodCourtNameUrl = foodCourtDetails.foodCourtName
              ? foodCourtDetails.foodCourtName?.split(' ').join('-').toLowerCase()
              : ' ';

            this.router.navigate([`/${foodCourtNameUrl}`]);
            break;
          }
        }

        resolve("Success");
      })
    })


  }

  searchFoodcourtByName(foodcourtName) {

    let foodCourtDetails;
    this.landingConsoleService.searchFoodCourtByName(foodcourtName).subscribe((response: any) => {
      foodCourtDetails = response.data[0];
      if (foodCourtDetails) {
        this.storeInLocalStorage(foodCourtDetails);

        let foodCourtNameUrl = foodCourtDetails.foodCourtName
          ? foodCourtDetails.foodCourtName?.split(' ').join('-').toLowerCase()
          : ' ';

        this.router.navigate([`/${foodCourtNameUrl}`]);
      }
    }, (err) => {
      this.landingConsoleService.openSnackBarMessage("Failed to get Foodcourt Details");
    })
  }

  // If item is already present in the IndexDB
  // 1> Put all item to User Cart using API or else
  // 2> Get the UserCart Data from API and store to indexDB and updated LocalStorage like add variable OrderFoodCourtId in local storage etc.
  getAllCartDataFromIndexDB() {

    this.storageService.getAllItemFromCart().then((indexDBCart: any) => {

      if (indexDBCart && indexDBCart.length > 0) {

        this.landingConsoleService.clearCartDataAPI(this.userId).subscribe(
          (response) => {
            //console.log(response);
            let userName = this.landingConsoleService.getUserName();
            let userMobile = this.landingConsoleService.getUserMobile();
            let orderedFoodCourtId = this.landingConsoleService.getOrderFoodCourtId();

            let data = {
              foodCourtId: orderedFoodCourtId,
              userId: this.userId,
              userName: userName,
              mobile: userMobile,
              platform: "WEB",
              itemsList: {},

              mealCategory: null,
              preOrder: false,
              preOrderDate: null,
              preOrderEndTime: null,
              preOrderStartTime: null
            };

            let isPreOrdering = this.landingConsoleService.getIsOrderPreOrderType();
            if (isPreOrdering) {

              data.mealCategory = (this.landingConsoleService.getPreOrderMenuType()).toUpperCase();

              let preOrderingEndDate = new Date(this.landingConsoleService.getPreOrderDetails());

              data.preOrderDate = preOrderingEndDate,

                data.preOrder = true;
              data.preOrderEndTime = (preOrderingEndDate.getHours() < 10 ? ("0" + preOrderingEndDate.getHours()) : preOrderingEndDate.getHours()) + ":" +
                (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
                (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

              data.preOrderStartTime = ((preOrderingEndDate.getHours() - 1) < 10 ? ("0" + (preOrderingEndDate.getHours() - 1)) : preOrderingEndDate.getHours() - 1) + ":" +
                (preOrderingEndDate.getMinutes() < 10 ? ("0" + preOrderingEndDate.getMinutes()) : preOrderingEndDate.getMinutes()) + ":" +
                (preOrderingEndDate.getSeconds() < 10 ? ("0" + preOrderingEndDate.getSeconds()) : preOrderingEndDate.getSeconds()) + ".000";

            }

            data.itemsList = indexDBCart;
            this.landingConsoleService.addItemToCartAPI(data).subscribe(
              (response) => {
                // this.storageService.clearAllItemsFromCart(); // remove all item from indexDB when user is sign in

              },
              (err) => {
                //console.log(err);
                this.landingConsoleService.openSnackBarMessage(
                  'Failed to add item to Cart'
                );

                this.clearCartDataAPI();
                this.storageService.clearAllItemsFromCart();
              }
            );
          },
          (err) => {
            //console.log(err);
          }
        );

      } else {
        this.landingConsoleService.getCartData(this.userId).subscribe(
          async (response: any) => {
            if (response.data?.foodCourtId) {
              let orderFoodCourtId = response.data.foodCourtId;
              this.landingConsoleService.setOrderFoodCourtId(orderFoodCourtId);
            }

            if (response.data?.itemsList && response.data?.itemsList.length > 0) {

              response.data.itemsList.forEach(async (item) => {
                await this.storageService.addItemToCart(item.appItemId, item);
              })
            }
          },
          (err) => {
            console.log(err);
            this.landingConsoleService.openSnackBarMessageIMP("Failed to get your cart data. Plz try again in sometime.");
            this.landingConsoleService.removeFromLocalStorage('userId');
            this.landingConsoleService.removeFromLocalStorage('userName');
            this.landingConsoleService.removeFromLocalStorage('userMobile');
            this.landingConsoleService.removeFromLocalStorage('token');
            this.landingConsoleService.removeFromLocalStorage('fb_token');
            window.location.reload();
          }
        );
      }
    });
  }

  clearCartDataAPI() {

    this.landingConsoleService.clearCartDataAPI(this.userId).subscribe(
      (response) => {
        //console.log(response);
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getFoodCourtDetails() {
    let foodCourtDetails;
    this.landingConsoleService
      .getFoodCourtDetailsById(this.foodCourtId)
      .subscribe(
        (response: any) => {
          foodCourtDetails = response.data;
          this.storeInLocalStorage(foodCourtDetails);

          let siteNameUrl = foodCourtDetails.siteName
            ? foodCourtDetails.siteName?.split(' ').join('-').toLowerCase()
            : ' ';
          let foodCourtNameUrl = foodCourtDetails.foodCourtName
            ? foodCourtDetails.foodCourtName?.split(' ').join('-').toLowerCase()
            : ' ';

          this.router.navigate([
            `/${foodCourtNameUrl}`,
          ]);
        },
        (err) => {
          //console.log(err);
        }
      );
  }

  // Function to show Bottom sheet i.e. Listing of Foodcourts and on selection of foodcourt redirect to that foodcourt
  showBottomSheet() {
    let siteId = this.landingConsoleService.getSiteId();
    let foodCourtId = this.landingConsoleService.getFoodCourtId();

    let BTCRequestId = this.landingConsoleService.getBTCRequestId();
    if (BTCRequestId) {
      this.landingConsoleService.openSnackBarMessage("Please Update the BTC Request before Changing Foodcourt");
      return;
    }

    let _bottomSheetRef = this.bottomSheet.open(SiteFoodcourtListComponent, {
      data: {
        siteList: this.siteList,
        siteId: siteId,
        foodCourtId: foodCourtId,
      },
    });
    _bottomSheetRef.afterDismissed().subscribe((response: any) => {
      if (response) {

        this.foodCourtDetails = response.foodCourtDetails;
        this.landingConsoleService.setIsRatingCompulsoryInCurrentFC(this.foodCourtDetails.ratingCompulsory);
        
        this.storeInLocalStorage(this.foodCourtDetails);

        let currentUrl = this.router.url.split('/');
        let currentPage = currentUrl[0];
        let foodCourtNameUrl = this.foodCourtDetails.foodCourtName
          ?.split(' ')
          .join('-')
          .toLowerCase();

        if (this.userId) {

          this.landingConsoleService.getHasUserBTCAccessInFoodcourt(this.userId, this.foodCourtId).subscribe((response: any) => {

            this.landingConsoleService.setHasUserBTCAccessInCurrFC(response.data);
            this.landingConsoleService.itemQuantityChange.next(true);

            this.router.navigate([`${currentPage}/${foodCourtNameUrl}`]);
          }, (err) => {
            this.router.navigate([`${currentPage}/${foodCourtNameUrl}`]);
          })
        } else {

          this.router.navigate([`${currentPage}/${foodCourtNameUrl}`]);
        }
      }
    });
  }

  // Get user permission to access their device location
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userPosition.userLongitute = position.coords.longitude;
          this.userPosition.userLatitute = position.coords.latitude;
          this.getSitesByLocation();
        },
        () => {
          //console.log('IMPORTANT: User has blocked geolocation');
          this.userPosition.userLatitute = 0;
          this.userPosition.userLongitute = 0;
          this.getSitesByLocation();
        }
      );
    } else {
      //console.log('No support for geolocation');
    }
  }

  // Function to get Sites Listing based on User Location
  getSitesByLocation() {

    let data = {
      geolocation: {
        latitude: this.userPosition.userLatitute,
        longitude: this.userPosition.userLongitute,
      },
    };
    this.showLoader = true;
    this.landingConsoleService.getSitesByLocation(data).subscribe(
      (response: any) => {
        this.siteList = response.data;

        this.popupService.storesiteList(this.siteList);
        // do not call foodcourt detail we already have foodcourtId
        if (!this.foodCourtId) {
          this.foodCourtId = this.siteList[0]?.foodCourtList[0].foodCourtId;
         
          this.siteName = this.siteList[0].siteName;
          this.siteImageUrl = this.siteList[0].siteImageUrl;
          this.landingConsoleService.setIsRatingCompulsoryInCurrentFC(this.siteList[0]?.foodCourtList[0].ratingCompulsory);
          // console.log(this.siteImageUrl);
        } else {
          this.siteName = this.landingConsoleService.getSiteName();
        }
        this.getFoodCourtDetailsById();
      },
      (err) => {
        //console.log(err);
        this.landingConsoleService.openSnackBarMessage(
          'Failed to get sites based on location, trying to get all sites..'
        );
        this.getAllSites();
      }
    );
  }

  getFoodCourtDetailsById() {
    this.landingConsoleService
      .getFoodCourtDetailsById(this.foodCourtId)
      .subscribe(
        (response: any) => {

          this.foodCourtDetails = response.data;

          // set whether foodcourt has preordering or not
          if (this.foodCourtDetails?.foodCourtOrderTypes) {

            if (this.foodCourtDetails?.foodCourtOrderTypes?.includes("PRE_ORDER")) {
              this.landingConsoleService.setFoodcourtHasPreOrdering(true);
            } else {
              this.landingConsoleService.setFoodcourtHasPreOrdering(false);
            }
          } else {
            this.landingConsoleService.setFoodcourtHasPreOrdering(this.foodCourtDetails?.preOrder);
          }

          this.foodCourtDetails.siteImgUrl = this.siteImageUrl;
          if (!this.foodCourtDetails.siteName)
            this.foodCourtDetails.siteName = this.siteName;

          this.storeInLocalStorage(this.foodCourtDetails);
          this.landingConsoleService.setIsRatingCompulsoryInCurrentFC(this.foodCourtDetails?.ratingCompulsory);

          this.showLoader = false;

          this.landingConsoleService.getHasUserBTCAccessInFoodcourt(this.userId, this.foodCourtId).subscribe((response: any) => {

            this.landingConsoleService.setHasUserBTCAccessInCurrFC(response.data);

          }, (err) => {

            this.landingConsoleService.setHasUserBTCAccessInCurrFC(false);
          })

        },
        (err) => {
          //console.log(err);
          if (err.status === 404) {
            this.landingConsoleService.setFoodCourtId(null);
            window.location.reload();
          }
          this.landingConsoleService.openSnackBarMessageIMP("Some error occured!! Plz try again in sometime.");
        }
      );
  }

  storeInLocalStorage(foodCourtDetails) {

    this.foodCourtId = foodCourtDetails.foodCourtId;
    this.landingConsoleService.setSiteId(foodCourtDetails.siteId);
    this.landingConsoleService.setSiteName(foodCourtDetails.siteName);

    this.landingConsoleService.setFoodCourtId(foodCourtDetails.foodCourtId);
    this.landingConsoleService.setFoodCourtName(foodCourtDetails.foodCourtName.replace("-", "").replace("  ", " "));
  }

  getAllSites() {
    this.landingConsoleService.getAllSites().subscribe(
      (response: any) => {
        this.foodCourtId = response.data[0].foodCourtId;
        this.getFoodCourtDetailsById();
      },
      (err) => {
        // this.landingConsoleService.openSnackBarMessage(
        //   'Failed to get all Sites'
        // );

        if (err.status === 401) {
          this.clearLocalStorageData();
          this.router.navigate(['auth']);
        }
        else {
          this.landingConsoleService.openSnackBarMessageIMP(
            'Some Error Occured!! Please try again later'
          );
          this.siteErrorOccured = true;
        }
        this.showLoader = false;
      }
    );
  }

  clearLocalStorageData() {

    this.storageService.clearAllItemsFromCart();
    this.landingConsoleService.setOrderFoodCourtId(null);
    this.landingConsoleService.setOrderFoodCourtName(null);
    this.landingConsoleService.setIsOrderPreOrderType(null);
    this.landingConsoleService.setPreOrderDetails(null);
    this.landingConsoleService.setPreOrderMenuType(null);
    this.landingConsoleService.setBTCRequestId(null);

    this.landingConsoleService.setVendorId(null);

    this.landingConsoleService.setUserId(null);
    this.landingConsoleService.setUserName(null);
    this.landingConsoleService.setUserEmail(null);
    this.landingConsoleService.setUserMobile(null);
    this.landingConsoleService.setOrderId(null);
    this.landingConsoleService.setToken(null);

    // window.location.reload();
  }
  getUserCartData() {
    // this function is specific to cornor senerio when a loggedin user add an item from a foodcourt then by another device add an item from another foodcourt then he should not be able to add that item because in its cart there is an item from diffrent foodcourt alread
    this.landingConsoleService.getCartData(this.userId).subscribe(
      async (response: any) => {

        if (response.data?.preOrder) {
          let preOrderMenuType = "BREAKFAST";
          let preOrderMenuStartTime = response.data.preOrderStartTime.split(':')[0];
          let preOrderMenuEndTime = response.data.preOrderEndTime.split(':')[0];
          let preOrderEndDateTime = new Date(new Date(response.data.preOrderDate).setHours(preOrderMenuEndTime, 0));

          if (Number(preOrderMenuStartTime) >= 11 && Number(preOrderMenuEndTime) <= 15) {
            preOrderMenuType = "SNACK";
          }
          else if (Number(preOrderMenuStartTime) >= 15 && Number(preOrderMenuEndTime) <= 18) {
            preOrderMenuType = "LUNCH";
          }
          else if (Number(preOrderMenuStartTime) >= 18 && Number(preOrderMenuEndTime) <= 23) {
            preOrderMenuType = "DINNER";
          }

          this.landingConsoleService.setPreOrderDetails(preOrderEndDateTime);
          this.landingConsoleService.setIsOrderPreOrderType(true);
          this.landingConsoleService.setPreOrderMenuType(preOrderMenuType);

        }
        if (response.data?.foodCourtId) {
          let orderFoodCourtId = response.data.foodCourtId;
          this.landingConsoleService.setOrderFoodCourtId(orderFoodCourtId);
          this.landingConsoleService.getFoodCourtDetailsById(orderFoodCourtId).subscribe((response:any)=> {
            this.landingConsoleService.setIsRatingCompulsoryInOrderingFC(response.data.ratingCompulsory);
          }, (err)=> {

          })

          this.landingConsoleService.getHasUserBTCAccessInFoodcourt(this.userId, response.data.foodCourtId).subscribe((response: any) => {

            this.landingConsoleService.setOrderingFoodcourtHasBTC(response.data);
          }, (err) => {

          })
          // for(let item of response.data.itemsList) {
          //   await this.globalService.addItemToIndexedDB(item);
          // }
        }
        if (response.data?.btcRequestId) {
          this.landingConsoleService.setBTCRequestId(response.data?.btcRequestId);
        }
      },
      (err) => {
        console.log(err);
        this.landingConsoleService.openSnackBarMessageIMP("Failed to get your cart data. Plz try again in sometime.");
        this.landingConsoleService.removeFromLocalStorage('userId');
        this.landingConsoleService.removeFromLocalStorage('userName');
        this.landingConsoleService.removeFromLocalStorage('userMobile');
        this.landingConsoleService.removeFromLocalStorage('token');
        this.landingConsoleService.removeFromLocalStorage('fb_token');
        window.location.reload();
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }
  navigateToSearch() {
    let foodCourtName = this.landingConsoleService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`search/${foodCourtName}`]);
  }
  navigateToHome() {
    let foodCourtName = this.landingConsoleService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`${foodCourtName}`]);
  }

  goToAccountPage() {
    let userId = this.landingConsoleService.getUserId();
    if (userId) {
      this.router.navigate(['account/user']);
    }
    else
      this.router.navigate(['auth']);
  }

  goToNotificationPage() {
    this.router.navigate(['user/notifications']);
  }

  unseenNotificationCount: number = 0;
  getUserNotifications() {
    this.landingConsoleService.getNotificationClickObs().subscribe((_) => {
      this.unseenNotificationCount--;
    })

    const userId = this.landingConsoleService.getUserId();

    if (userId) {
      const currentDate = new Date().getTime();

      const payload = {
        userId: this.landingConsoleService.getUserId(),
        startDate: this.datePipe.transform(currentDate - (7 * 24 * 60 * 60 * 1000), 'dd-MM-yyyy'),
        endDate: this.datePipe.transform(currentDate, 'dd-MM-yyyy')
      }

      this.landingConsoleService.getUserNotifications(payload).subscribe((response: any) => {
        if (response.data && response.data.length > 0) {
          response.data.forEach((element) => {
            if (!element.clicked) {
              this.unseenNotificationCount++;
            }
          })
        }
      })
    }
  }
}
