<section class="plan-subscription-section">
    <div class="section-heading">
        <div class="back-arrow-container" (click)="goToSubscriptionDetailsPage()">
            <ng-container *ngIf="scrWidth < 600">
                <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="">
            </ng-container>
            <ng-container *ngIf="scrWidth > 600">
                <img src="../../../../../../../assets/images/common/close.svg" alt="">
            </ng-container>
        </div>

        <div class="heading-text">Food Subscription Plan</div>
    </div>
    <div class="plan-details-section">
        <div class="plan-overwiew-section">
            <div class="overwiew-heading">
                <img src="../../../../../.././../assets/images/common/veg-item.svg" alt="">
                <div>{{ subscriptionPlanDetails.title | titlecase }}</div>
            </div>
            <div class="overwiew-description">
                <ul>
                    <li>{{ subscriptionPlanDetails.desc | titlecase }}</li>
                </ul>
            </div>
            <div class="meal-timing-list">
                <div class="meal-timing" *ngFor="let timing of subscriptionPlanDetails.fcItemsList">
                    <img src="../../../../../../../assets/images/food-subscription/timer-image.svg" alt="">
                    <div>{{ timing.startTime | convertLocalDate }} - {{ timing.endTime | convertLocalDate }}</div>
                </div>
            </div>
            <div class="meal-price">
                &#8377;{{ (subscriptionPlanDetails.price) | number:'1.0-2' }} / Month
            </div>
        </div>

        <div class="plan-duration-section">
            <div class="section__sub-heading">
                <div class="sub-heading__left-section">Subscription Duration</div>
                <div class="sub-heading__right-section min-days">Min Days: {{ subscriptionPlanDetails.noOfDays }}</div>
            </div>

            <!-- Below Code is not working (UI Issue) -->
            <!-- <mat-form-field appearance="outline">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="subscriptionDateRange">
                  <input matStartDate placeholder="Start date">
                  <input matEndDate placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="subscriptionDateRange"></mat-datepicker-toggle>
                <mat-date-range-picker #subscriptionDateRange></mat-date-range-picker>
            </mat-form-field> -->

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="subscriptionDateRange">
                    <input matStartDate placeholder="Start date" [(ngModel)]="subscriptionStartDate"
                        (ngModelChange)="calculateSubscriptionPrice()" />
                    <input matEndDate placeholder="End date" [(ngModel)]="subscriptionEndDate"
                        (ngModelChange)="calculateSubscriptionPrice()" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="subscriptionDateRange"></mat-datepicker-toggle>
                <mat-date-range-picker #subscriptionDateRange></mat-date-range-picker>
            </mat-form-field>

            <div class="user-subscription-days">Subscription Days : {{ subscriptionPlanDetails.noOfDays }}</div>

        </div>

        <div class="plan-bill-details-section">
            <div class="section__sub-heading">Bill Details</div>
            <div class="bill-details__key-value-section">
                <div class="key-text">Item Total</div>
                <div class="value-text">&#8377;{{ (subscriptionPlanDetails.totalPrice) | number:'1.0-2' }}</div>
            </div>
            <div class="bill-details__key-value-section">
                <div class="key-text">Taxes & Charges</div>
                <div class="value-text">&#8377;{{ (subscriptionPlanDetails.totalTax) | number:'1.0-2' }}</div>
            </div>
            <div class="bill-details__key-value-section">
                <div class="key-text">Total Payable</div>
                <div class="value-text">&#8377;{{ ((subscriptionPlanDetails.totalPrice +
                    subscriptionPlanDetails.totalTax)) | number:'1.0-2' }}</div>
            </div>
        </div>

        <div class="redeem-points">
            <div class="points-container">
                <div class="points-heading">Redeem Points</div>
                <div class="points-value">
                    <span>Available : </span><span><img
                            src="../../../../../../assets/images/home-page/isthara-points.svg" alt="" /></span>{{
                    userPoints.redeemIstharaPoints | number: "1.1-2" }}
                </div>
            </div>
            <div class="toggle">
                <mat-slide-toggle color="warn" (change)="toggleUserPoints()"
                    [(ngModel)]="doPayWithIstharaPoint"></mat-slide-toggle>
            </div>
        </div>
    </div>

    <div class="plan-details-footer">
        <mat-checkbox [color]="'primary'" [(ngModel)]="isTermsAndConditionChecked || !isTermsAndConditionChecked">
            <mat-option [value]="true">I have read & understood all the <span class="terms_and_condition"
                    (click)="showTermsAndCondition()">terms &
                    conditions</span></mat-option>
        </mat-checkbox>
        <button [disabled]="!isTermsAndConditionChecked" class="payment-btn" (click)="subscribePlan()">Proceed to Pay
            &#8377;{{ ((subscriptionPlanDetails.totalPayableAmount)) | number:'1.0-2'
            }}</button>
    </div>
</section>