import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChooseMealService {
  constructor(private http: HttpClient) {}

  getItemListByType(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/menu/ist/menu/filter`,
      data
    );
  }
  getPreOrderingItemList(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/menu/ist/menu/preordermenu/filter`, data
    )
  }
  getAllCuisines() {
    return this.http.get(
      environment.baseUrl + `/property/property/v1/ist/get/cuisines/find/all`
    );
  }
  searchFoodItemByText(itemDetails) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/menu/ist/v1/search/items`, itemDetails
    )
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }
}
