import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../../../../environments/environment';
import SecureLS from 'secure-ls';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) { }

  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });

  openDialog(componentName, maxWidth, height, width, dataModel) {
    return this.dialog.open(componentName, {
      maxWidth: maxWidth,
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }

  setHelpdeskTicketId(body: any) {
    this._lsService.set('helpdeskTicketId', body);
  }
  getHelpdeskTicketId() {
    const helpdeskTicketId = this._lsService.get('helpdeskTicketId');
    if (helpdeskTicketId) {
      return helpdeskTicketId;
    }
    return null;
  }

  setHelpdeskFoodCourtId(body: any) {
    this._lsService.set('helpdeskFoodCourtId', body);
  }
  getHelpdeskFoodCourtId() {
    const helpdeskFoodCourtId = this._lsService.get('helpdeskFoodCourtId');
    if (helpdeskFoodCourtId) {
      return helpdeskFoodCourtId;
    }
    return null;
  }

  setHelpdeskCategoryId(body: any) {
    this._lsService.set('helpdeskCategoryId', body);
  }
  getHelpdeskCategoryId() {
    const helpdeskCategoryId = this._lsService.get('helpdeskCategoryId');
    if (helpdeskCategoryId) {
      return helpdeskCategoryId;
    }
    return null;
  }

  getAllHelpDeskTickets(userId,pageNo,size,status){
    return this.http.get(
      environment.baseUrl+`/property/helpdesk/ist/v1/fc/${userId}/tickets?pageNo=${pageNo}&pageSize=${size}&status=${status}`
    )
  }

  getAllFoodCourtList() {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list?pageNo=0&size=1000`
    )
  }

  getAllFoodCourtSubCategoryById(helpdeskCategoryId: string) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/sub-category/ist/v1/fc/${helpdeskCategoryId}/issue/all?vertical=Food_Court`
    )
  }

  createHelpDeskTicket(data) {
    return this.http.post(
      environment.baseUrl + `/property/helpdesk/ist/v1/ticket/create`, data
    )
  }

  getHelpDeskDetails(helpdeskId) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/ist/v1/${helpdeskId}`
    )
  }

  getAllHelpDeskCategories() {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/category/ist/v1/fc/list?vertical=Food_Court`
    )
  }

  reopenTicketById(ticketId: string) {
    return this.http.put(
      environment.baseUrl + `/property/helpdesk/ist/v1/ticket/${ticketId}/re-open`, {}
    )
  }

  closeTicketById(ticketId: string) {
    return this.http.put(
      environment.baseUrl + `/property/helpdesk/ist/v1/${ticketId}/close?ticketType=FOOD_COURT`, {})
  }

  rateTicketService(ticketId: string, serviceRating: number) {
    return this.http.put(
      environment.baseUrl + `/property/helpdesk/ist/v1/ticket/service-rating?helpdeskTicketId=${ticketId}&serviceRating=${serviceRating}`, {}
    )
  }

  remindTicketToAdmin(ticketId: string, reminderDetails) {
    return this.http.put(
      environment.baseUrl + `/property/helpdesk/ist/v1/${ticketId}/remind`, reminderDetails
    )
  }
}
