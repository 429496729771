<div class="one-click-payment-main-section">
  <div class="header-section">
    <span>Bill Details</span>
    <img
      src="../../../../../../../assets/images/common/close.svg"
      alt=""
      (click)="closeOneClickPayment()"
    />
  </div>
  <div class="content-section">
    <div class="item-total">
      <span>Item Total</span>
      <span>&#8377; {{ data.billDetails.actualPrice | number: "1.1-2" }}</span>
    </div>
    <hr />
    <div class="employer-discount">
      <span>Employer Discount</span>
      <span>&#8377; {{ data.billDetails.empDiscount | number: "1.1-2" }}</span>
    </div>
    <hr />
    <div class="taxes_and_charges">
      <span>Taxes & Charges</span>
      <span>&#8377; {{ data.billDetails.tax | number: "1.1-2" }}</span>
    </div>
    <hr />
    <div class="total-payable">
      <span>Total Payable</span>
      <span>&#8377; {{ data.billDetails.totalPrice | number: "1.1-2" }}</span>
    </div>
  </div>
  <div class="points-section">
    <div class="point-section__left-section">
      <div class="point-type">Redeem Isthara Point</div>
      <div class="points">
        <span>Available:</span>
        <img
          src="../../../../../../../assets/images/home-page/isthara-points.svg"
          alt=""
        />
        <span class="points-value">{{
          userData.istharaPoints | number: "1.1-2"
        }}</span>
      </div>
    </div>
    <div class="points-section__right-section">
      <mat-slide-toggle
        color="warn"
        [(ngModel)]="pointsToggleCheck"
        (change)="togglePointsValue()"
      ></mat-slide-toggle>
    </div>
  </div>
  <div class="payment-button">
    <button (click)="doPaymentForOneClick()" *ngIf="!loading; else loader">
      Proceed to Pay &#8377;{{ userData.amtPayable | number: "1.1-2" }}
    </button>
    <ng-template #loader>
      <button><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</button>
    </ng-template>
  </div>
</div>

<!-- <form id="redirectForm" method="post" [action]="formDataView.paymentLink"> -->
  <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit

  production ===> https://www.cashfree.com/checkout/post/submit-->
  <!-- <input id="appId" type="hidden" name="appId" [value]="formDataView.appId" />
  <input type="hidden" name="orderId" [value]="formDataView.orderId" />
  <input type="hidden" name="orderAmount" [value]="formDataView.orderAmount" />
  <input type="hidden" name="orderCurrency" [value]="'INR'" />
  <input type="hidden" name="orderNote" [value]="'Order'" />
  <input
    type="hidden"
    name="customerName"
    [value]="formDataView.customerName"
  />
  <input
    type="hidden"
    name="customerEmail"
    [value]="formDataView.customerEmail"
  />
  <input
    type="hidden"
    name="customerPhone"
    [value]="formDataView.customerPhone"
  />
  <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
  <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
  <input type="hidden" name="signature" [value]="formDataView.signature" />
</form> -->
