import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { NotificationDetailsComponent } from '../notification-details/notification-details.component';
import { PushNotificationService } from '../push-notification.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [DatePipe]
})
export class NotificationsListComponent implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private landingService: LandingComponentService,
    private notificationService: PushNotificationService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      if (param['id']) {
        // If notification is clicked we have to hit api to update whther user clicked on notification ot not
        this.notificationClicked(param['id']);
      } else {
        this.showLoader = true;
        this.getUserNotificationsList();
      }
    })
  }

  @HostListener('document:click', ['$event'])
  documentClicked(event: any) {
    const neglectClasses = ['dots-icon'];

    if (!neglectClasses.includes(event.target.className)) {
      this.notificationData.forEach((element) => {
        element.list.forEach((item, i) => {
          item.showActionIcon = false;
        })
      })
    }
  }

  toggleActionPopup(index: number, title: string) {
    this.notificationData.forEach((element) => {
      if (element.title === title) {
        element.list.forEach((item, i) => {
          if (index === i) {
            item.showActionIcon = !item.showActionIcon;
          } else {
            item.showActionIcon = false;
          }
        })
      }

    })

  }

  notificationData = [
    { title: 'Today', list: [] },
    { title: 'Yesterday', list: [] },
    { title: 'Older', list: [] },
  ];
  showLoader: boolean = false;

  getUserNotificationsList() {
    const currentDate = new Date().getTime();

    const payload = {
      userId: this.landingService.getUserId(),
      startDate: this.datePipe.transform(currentDate - (7 * 24 * 60 * 60 * 1000), 'dd-MM-yyyy'),
      endDate: this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    }

    this.notificationService.getUserNotifications(payload).subscribe((response: any) => {
      if (response.data && response.data.length > 0) {
        const todayDate = new Date().setHours(0, 0, 0, 0); // returns in milliseconds

        this.notificationData[0].list = [];
        this.notificationData[1].list = [];
        this.notificationData[2].list = [];

        response.data.forEach((element) => {
          element.updateTimeStamp = new Date(new Date(element.updateTimeStamp).getTime() - 19800000);
          const gettingDate = new Date(element.updateTimeStamp).setHours(0, 0, 0, 0);

          if (todayDate === gettingDate) {
            this.notificationData[0].list.push(element);
          } else if ((todayDate - (24 * 60 * 60 * 1000)) === gettingDate) {
            this.notificationData[1].list.push(element);
          } else {
            this.notificationData[2].list.push(element);
          }
        })
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.landingService.openSnackBarMessage("Failed to get notifications, please try again", "Ok");
      })
  }

  notificationClicked(notificationId: string, showLoader = true) {
    const userId = this.landingService.getUserId();

    if (userId) {
      const userName = this.landingService.getUserName();

      let payload = {
        notificationId: notificationId,
        userId: userId,
        date: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      }

      this.notificationService.updateNotificationClicked(payload).subscribe((response: any) => {
        this.landingService.setNotificationClick();

        // this.landingService.openSnackBarMessage(`Welcome ${userName ? userName : ''}`);
      },
        (error) => {
        },
        () => {
          if (this.showLoader) this.showLoader = true;
          this.getUserNotificationsList();
        })
    }
  }

  showNotificationDetails(item: any) {
    if (!item.clicked) {
      this.notificationClicked(item.customNotificationRequest.notificationId, false);
    }

    this.bottomSheet.open(NotificationDetailsComponent, {
      data: { notificationData: item.customNotificationRequest },
      panelClass: 'custom-notification-bottom-sheet',
    });
  }

  deleteNotification(notificationHistoryId: string) {
    this.notificationService.deleteNotification(notificationHistoryId).subscribe((response: any) => {
      this.landingService.openSnackBarMessage("Successfully Deleted", "Ok");
      this.getUserNotificationsList();
    },
      (error) => {
        this.landingService.openSnackBarMessage("Failed to delete, please try again", "Ok");
      })
  }

  goToHomePage() {
    const fcName = this.landingService.getFoodCourtName().trim();
    this.router.navigate([`${fcName}`]);
  }
}

