import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GlobalCartApiService {

  constructor(
    private http: HttpClient
  ) { }
  
  addItemToCartAPI(data) {
    return this.http.put(environment.baseUrl + `/foodcourt/user/ist/cart`, data);
  }
  getAllCartDataAPI(userId) {
    return this.http.get(environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`)
  }
  removeItemFromCartAPI(userId, itemId) {
    return this.http.delete(environment.baseUrl + `/foodcourt/user/ist/cart?itemId=${itemId}&userId=${userId}`);
  }
}
