<section class="rate-service-section">
    <ng-container *ngIf="!ratingLoader; else showRatingLoader">
        <!-- <div class="ticket-details__header-container">
            <div class="header-container__left-section">
                <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt="" (click)="goToHelpdeskListingPage()">
                <div class="header__primary-text">Rate the Service</div>
            </div>
        </div> -->
        <div class="overall-service-rating">
            <div class="service-type-img-container">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'staff behaviour'" src="../../../../../../../../assets/images/help_desk/staff_behaviour_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'hygiene'" src="../../../../../../../../assets/images/help_desk/hygiene_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'delivery related'" src="../../../../../../../../assets/images/help_desk/delivery_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'food quality'" src="../../../../../../../../assets/images/help_desk/food_quality_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'food quantity'" src="../../../../../../../../assets/images/help_desk/food_quantity_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'app issue'" src="../../../../../../../../assets/images/help_desk/app_issues_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'order issue'" src="../../../../../../../../assets/images/help_desk/order_issue_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'payments'" src="../../../../../../../../assets/images/help_desk/payment_category.svg" alt="">
                <img *ngIf="helpDeskDetails.helpdeskDetails.category === 'others'" src="../../../../../../../../assets/images/help_desk/others_category.svg" alt="">
            </div>
            <div class="primary-text">Rate the Service</div>
            <div class="ternary-text">How much would you rate the</div>
            <div class="secondary-text">{{ helpDeskDetails.helpdeskDetails.category | uppercase }}</div>

            <div class="rating-section">
                <ng-container *ngFor="let icon of serviceRatingIcons">
                    <img *ngIf="!icon.status" src="../../../../../../../../assets/images/ratings/{{ icon.src }}.svg" alt="" (click)="selectServiceRating(icon)">
                    <img *ngIf="icon.status" src="../../../../../../../../assets/images/ratings/{{ icon.src }}-selected.svg" alt="" (click)="selectServiceRating(icon)">
                </ng-container>
            </div>

        </div>
        <!-- <div class="staff-service-rating">
            <div class="primary-text">Rate Our Staff</div>
            <div class="ternary-text">How would you rate the communication between you and the staff. Your rating help our staff to improve better.</div>
            <img src="../../../../../../../../assets/images/dummy/dummy_user.svg" alt="">
            <div class="secondary-text">{{ helpDeskDetails.staffDetails[0].staffName | titlecase }}</div>
            <div class="staff-rating-section">
                <ng-container *ngFor="let icon of staffRatingIcons">
                    <img *ngIf="!icon.status" src="../../../../../../../../assets/images/ratings/{{ icon.src }}.svg" alt="" (click)="selectStaffRating(icon)" >
                    <img *ngIf="icon.status" src="../../../../../../../../assets/images/ratings/{{ icon.src }}-selected.svg" alt="" (click)="selectStaffRating(icon)" >
                </ng-container>
            </div>
            <div class="staff-review-section">
                <mat-form-field appearance="fill" *ngIf="showReviewStaffField">
                    <textarea
                    [(ngModel)]="staffDetails[0].comment"
                    matInput 
                    placeholder="Review the behaviour of staff"></textarea>
                </mat-form-field>
            </div>
        </div> -->
        <div class="rating-action-btn">
            <button [disabled]="!(serviceRating)" (click)="submitTicketReview()" *ngIf="!submitLoader; else showSubmitBtnLoader">Submit</button>
            <ng-template #showSubmitBtnLoader>
                <button>
                    <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
                </button>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #showRatingLoader>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</section>