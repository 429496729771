<section *ngIf="data.data.type === 'SUCCESS'" class="success-message">
    <div class="cross-btn" (click)="closePopup()">X</div>
    <section>
        <div class="success_heading">Issue Raised Successfully</div>
        <div class="success_img">
            <img src="./../../../../../../../../assets/images/help_desk/check_animation.gif" alt="check_animation">
        </div>
        <div class="bottom_text">
            We want you to sit back and relax. Resolving your issue will be our top priority.
        </div>
    </section>
</section>
<section class="reminder-section" *ngIf="data.data.type === 'REMINDER'">
    <div class="header-section">
        <div class="header-primary-text">Reminder</div>
        <div class="popup-close-btn" (click)="closeConfirmationPopup()">
            <img src="../../../../../../../../assets/images/common/close.svg" alt="">
        </div>
    </div>
    <div class="content-section">
        <div class="issue-title">
            <div class="issue-key">Issue : </div>
            <div class="issue-value">Staff Behaviour</div>
        </div>
        <mat-form-field appearance="fill">
            <mat-label>Message</mat-label>
            <input type="text" matInput placeholder="Default Message" [(ngModel)]="reminderMessage">
        </mat-form-field>
    </div>
    <div class="action-btn-section">
        <button class="cancel" (click)="closeConfirmationPopup()">Cancel</button>
        <button class="send" (click)="sendReminder()" [disabled]="!reminderMessage" *ngIf="!sendLoader">Send</button>
        <button class="send" *ngIf="sendLoader">
            <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
        </button>
    </div>
</section>