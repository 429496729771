<section class="raise-issue-section">
    <ng-container *ngIf="!raiseIssueLoader; else showRaiseIssueScreenLoader">
        <div class="raise-issue__header-container">
            <div class="header__left-arrow-contaier" (click)="goToCategoryListingPage()">
                <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt="">
            </div>
            <div class="header__primary-text">Raise Issue</div>
        </div>
        <div class="raise-issue__content">
            <div class="issue-type-container">
                <div class="issue-type">{{ helpdeskCategoryName | uppercase }}</div>
                <!-- <div class="change-category-type" (click)="goToCategoryListingPage()">Change</div> -->
            </div>
            <div class="search-foodcourt-container">
                <ng-container *ngIf="!helpdeskFoodCourtId; else raiseTicketWithFC">
                    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" placeholder="Search Foodcourt">
                    <div class="search-icon-container">
                        <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="">
                    </div>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="selectFoodCourt(option)">
                        {{option.name | titlecase}}
                        </mat-option>
                    </mat-autocomplete>
                </ng-container>
                <ng-template #raiseTicketWithFC>
                    <span class="selected-foodcourt">{{ selectedFoodCourt.name | titlecase }}</span>
                </ng-template>
            </div>
            <div class="issue-sub-type-container">
                <div class="issue-sub-type__primary-text">Tell us your problem?</div>
                <div class="issue-sub-type__secondary-text">You can select from below</div>
                <div class="issue-sub-type__selection-container">
                    <ng-container *ngIf="helpdeskCategoryName !== 'others'">
                        <mat-radio-group [(ngModel)]="selectedIssueType" [color]="'primary'">
                            <mat-radio-button *ngFor="let issueType of issueSubTypeList" [value]="issueType.helpdeskSubCategoryName">
                                {{ issueType.helpdeskSubCategoryName | titlecase }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                    <ng-container *ngIf="helpdeskCategoryName === 'others'">
                        <mat-form-field appearance="outline">
                            <input type="text" matInput placeholder="Enter Your Problem" [(ngModel)]="selectedIssueType">
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
            <div class="issue-description-container">
                <div class="issue-description__primary-text">Add Details</div>
                <div class="issue-description__secondary-text">Details you think are important for us to know</div>
                <div class="issue-description-field">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <textarea matInput placeholder="Write about your issue" [(ngModel)]="issueDescription"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="issue-pictures-container">
                <div class="issue-picture__primary-text">Add Photo</div>
                <div class="issue-picture__secondary-text">Photos help us to find best staff and tools for your need as soon as possible</div>
                <div class="issue-picture__list-container">
                    <!-- <div class="add-image-container">
                        <img src="../../../../../../../../assets/images/common/empty-menu.svg" alt="">
                    </div> -->
                    <div class="image-sub-box">
                        <label for="picUpload">
                        <img *ngIf="!loaderSpinner; else loaderSpinners"
                            src="../../../../../../../../assets/images/common/upload-pics.svg" alt="upload-pic"
                            class="img-box" />
                        <ng-template #loaderSpinners>
                            <div class="spinner">
                            <mat-spinner></mat-spinner>
                            </div>
                        </ng-template>
                        </label>
                
                        <input style="visibility: hidden;" type="file" class="pic-upload" id="picUpload" (change)="addMore($event)"
                        accept="image/x-png,image/gif,image/jpeg" multiple />
                    </div>
                    <div class="list-image-container">
                        <ng-container *ngFor="let image of updatedImage">
                            <div class="issue-image-container">
                                <img [src]="image.imageUrl" alt="">
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="raise-issue__submit-btn-container">
            <button class="submit-btn" (click)="createHelpDeskTicketForUser()"
                [disabled]="!(selectedFoodCourt.id && selectedIssueType)">
               <span *ngIf="!submitButtonLoader">Submit</span>
               <span *ngIf="submitButtonLoader"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
            </button>
        </div>
    </ng-container>
    <ng-template #showRaiseIssueScreenLoader>
        <div class="loader-img-container">
            <img
              src="../../../../../../assets/images/common/burger_loader.gif"
              alt=""
            />
        </div>
    </ng-template>
</section>