import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-qr-code-page',
  templateUrl: './qr-code-page.component.html',
  styleUrls: ['./qr-code-page.component.scss']
})
export class QrCodePageComponent implements OnInit {

  constructor(
    private otherComponentService: OtherComponentService,
    private activatedRouter: ActivatedRoute  
  ) { }

  orderDetails;
  sendingData;
  showQR: boolean = false;

  ngOnInit(): void {
    this.activatedRouter.params.subscribe((response)=> {
      if (response?.orderId) {
        const orderId = atob(response.orderId);
        // console.log(orderId);
        // const orderId = this.otherComponentService.getDecryptedOrderId(response?.orderId);
        // console.log(orderId);
        this.getOrderDetails(orderId);
      }
    })
  }

  getOrderDetails(orderId) {
    this.otherComponentService.getOrderDetailsById(orderId).subscribe((response:any)=> {
      this.orderDetails = response.data;
      
      this.sendingData = {
        WARNING: 'SCAN IN ISTHARA APP ONLY',
        ERROR_ID: this.orderDetails.orderId,
        ERROR_CODE: this.orderDetails.vendorOrderDetails[0].vendorId,
        KOT: String(this.orderDetails.vendorOrderDetails[0].kot),
        ORDER_NUM: this.orderDetails.orderNum,
      };
      this.sendingData = JSON.stringify(this.sendingData);

      this.showQR = true;

    }, (err)=> {
      console.log("Failed to get QR Code");
    })
  }
}
