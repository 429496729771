import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RestaurantViewService {

  constructor(
    private http: HttpClient
  ) { }

  getVendorFoodItemList(data) {

    return this.http.put(environment.baseUrl + `/foodcourt/menu/ist/v1/search/items`, data);
    // return this.http.put(environment.baseUrl + `/foodcourt/menu/item/temp/ist/search/menu/items`, data); // Need to comment 
  }
  getVendorDetails(vendorId) {
    return this.http.get(environment.baseUrl + `/foodcourt/vendor/ist/vendor/${vendorId}`);
  }
  getAllCuisines() {
    return this.http.get(
      environment.baseUrl + `/property/property/ist/v1/cuisines?pageNo=0&size=100`
    );
  }
  getAllCategories() {
    return this.http.get(
      environment.baseUrl + `/property/property/v1/ist/list/all/category/pagination?pageNo=0&size=1000`
    );
  }
  getAllSubCategories() {
    return this.http.get(
      environment.baseUrl + `/property/property/v1/ist/list/all/subCategory/pagination?pageNo=0&size=1000`
    )
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }

  getAllCouponsForVendor(fcId:string, vendorId:string) {
    return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/all/status/mobile?foodCourtId=${fcId}&status=ACTIVE&vendorId=${vendorId}`)
  }
}
