import { Component, HostListener, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { DisplayQrCodeComponent } from '../../other-components/display-qr-code/display-qr-code.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChoosemealOptionComponent } from '../../other-components/choosemeal-option/choosemeal-option.component';
import { FoodSubscriptionService } from '../food-subscription.service';
import { element } from 'protractor';
import { PlanSubscriptionPageComponent } from '../plan-subscription-page/plan-subscription-page.component';
import { max } from 'rxjs/operators';


@Component({
  selector: 'app-subcription-plan-view',
  templateUrl: './subcription-plan-view.component.html',
  styleUrls: ['./subcription-plan-view.component.scss']
})
export class SubcriptionPlanViewComponent implements OnInit {

  constructor(
    private route : Router,
    private landingComponentService: LandingComponentService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private foodSubscriptionService: FoodSubscriptionService,
   @Optional() public dialogRef: MatDialogRef<SubcriptionPlanViewComponent>
  ) { }

  subscriptionPlanDetails;
  selectedMenu;
  showLoadingScreen: boolean = false;
  dailyItemList: any = {};
  isUserSubscriptionView: boolean = false;
  weeklyItemsData;
   
  
  scrWidth: number;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
  
    this.scrWidth = window.innerWidth;
  }
 

  serveTiming = {};

  ngOnInit(): void {
    
    this.getScreenSize();

    this.showLoadingScreen = true;

    this.subscriptionPlanDetails = this.landingComponentService.getSelectedSubscriptionPlan();

    if (!this.subscriptionPlanDetails) {
      let foodcourtName = this.landingComponentService.getFoodCourtName();
      this.landingComponentService.openSnackBarMessage("Please do not refresh the page!!");
      this.route.navigate([`${foodcourtName}`]);
    }

    this.subscriptionPlanDetails?.dayWiseItemsList.forEach((dayData)=> {
      dayData.status = false;
    })
    this.subscriptionPlanDetails.serveWiseTimings.forEach((meal)=> {
      this.serveTiming[meal.serveType] = { startTiming: meal.startTime, endTiming: meal.endTime }
    })
    
    this.getMenuBasedOnDay(this.subscriptionPlanDetails.dayWiseItemsList[0]);

    // this.subscriptionPlanDetails.fcItemsList.forEach(element => {
    //   this.weeklyItemsData =  element.weeklyItemsData;
    // })
    

    // this.subscriptionPlanDetails?.fcItemsList.forEach((mealTypeItemList:any)=> {
    //   // console.log(mealTypeItemList.weeklyItemsData)
      
    //   Object.keys(mealTypeItemList.weeklyItemsData).forEach((mealDay)=> {
    //     if (!this.dailyItemList[mealDay])
    //       this.dailyItemList[mealDay] = [];

    //     this.dailyItemList[mealDay].push({ serveType: mealTypeItemList.serveType, startTime: mealTypeItemList.startTime, endTime: mealTypeItemList.endTime, items: mealTypeItemList.weeklyItemsData[mealDay]});
    //   })
    //   // console.log(this.dailyItemList)
    // })

    // this.activatedRoute.queryParams.subscribe((queryParams)=> {
    //   this.isUserSubscriptionView = queryParams.isUserSubscriptionView;
    //   console.log(queryParams.isUserSubscriptionView)
    // })
  }

  breakfastMenu = []

  getMenuBasedOnDay(selectedDay) {
   
    this.showLoadingScreen = true;
    this.selectedMenu = selectedDay;
    
    this.subscriptionPlanDetails.dayWiseItemsList.forEach((day)=> {
      if (selectedDay.day === day.day) {
        day.status = true;
      } else {
        day.status = false;
      }
    })

    this.showLoadingScreen = false;
  }

  navigateToViewlist(){
    this.landingComponentService.setSelectedSubscriptionPlan(null);
    if(this.scrWidth >= 600){
      this.dialogRef.close();
    }else{
      this.route.navigate(['food-subscription/list-plan'])
    }
    
  }
  navigateToPlan_subscription_page(){
    this.landingComponentService.setSelectedSubscriptionPlan(this.subscriptionPlanDetails);
    
    if(this.scrWidth >= 600){
      this.dialogRef.close();
     this.dialog.open(PlanSubscriptionPageComponent,{
      width : "70vw",
      maxWidth : "100vw",
      panelClass: 'no-padding',
      minHeight: "92vh"
      
             });
      }else {
        this.route.navigate(['food-subscription/plan-subscription']);
      }

     
    
      
    
      
    
  }

  
  showQRCode(mealCategory: string) {
    
    // const dialogRef = this.dialog.open(ChoosemealOptionComponent,{
    //   width: '80vw',
    //   height: '30vh',
    //   data : {
    //     userPlanId : this.subscriptionPlanDetails.id,
    //     strDate : this.subscriptionPlanDetails.startDate
    //   },

    // })
    // dialogRef.afterClosed().subscribe((response:any)=> {
      // if (response.selectedMealCategory) {
        this.foodSubscriptionService.generateUserKOT(mealCategory, this.subscriptionPlanDetails.userSubscriptionId).subscribe((response : any)=>{
      
          const qrCodeDialogBox = this.dialog.open(DisplayQrCodeComponent, {
            width: '80vw',
            maxWidth: '100vw',
            height: '50vh',
            panelClass: 'no-padding',
            data: {
              data: response.data,
              type: "FOOD_SUBSCRIPTION"
            },
          });

        }, (err)=> {

        })
      }
    // })
  // }
}
