import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { OverallRatingPopupComponent } from '../../other-components/overall-rating-popup/overall-rating-popup.component';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-user-order-history',
  templateUrl: './user-order-history.component.html',
  styleUrls: ['./user-order-history.component.scss'],
})
export class UserOrderHistoryComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private _userAccountService: UserAccountService,
    private _popUpDbService: PopupDbService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userId = this._popUpDbService.getUserId();

    let orderType = this._userAccountService.getTypeOfOrderSummary();
    
    if (orderType === "SELF_ORDERS")
      this.changeOrderListingType(this.ORDER_TYPE[0]);
    else if (orderType === "PRE_ORDERS")
      this.changeOrderListingType(this.ORDER_TYPE[1]);
    else if (orderType === "BTC_ORDERS")
      this.changeOrderListingType(this.ORDER_TYPE[2]);
    else if (orderType === "GUEST_ORDERS")
      this.changeOrderListingType(this.ORDER_TYPE[3]);
    else 
      this.changeOrderListingType(this.ORDER_TYPE[4]);
    // this.getOrderHistoryOfUser();
  }
  ngOnDestroy(): void {
    // this._userAccountService.setGuestOrderType(null);
  }

  ORDER_TYPE = [
    { name: "Self Orders", value: "SELF_ORDERS", status: true },
    { name: "Pre Orders", value: "PRE_ORDERS", status: false },
    { name: "Corporate Orders", value: "BTC_ORDERS", status: false },
    { name: "Guest Orders", value: "GUEST_ORDERS", status: false },
    { name: "Guest Request", value: "GUEST_REQUEST", status: false }
  ];
  selectedOrderType = "SELF_ORDERS";

  userId: string = '';

  goToProfilePage() {
    this.router.navigate(['profile/user']);
  }

  ordersList = [];
  pageNo: number = 0;
  size: number = 10;
  showLoader: boolean = false;
  getOrderHistoryOfUser() {
    this.pageNo = 0;
    this.callMoreOrderHistory = true;
    this.ordersList = [];
    window.scrollTo(0, 0);
    this.showLoader = true;
    this._userAccountService
      .getOrdersListByUserId(this.userId, this.pageNo, this.size)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.showLoader = false;
          this.ordersList = res.data;
          this.ordersList.forEach((element) => {
            element.isOrderComplete = true;
            if (element.orderType === 'DELIVERY') {
              if (element.orderdeliveryStatus !== 'DELIVERED') {
                element.isOrderComplete = false;
              }
            } else {
              element.vendorOrderDetails.forEach((vendor) => {
                if (vendor.orderStatus !== 'PAST')
                  element.isOrderComplete = false;
              });
            }
          });
        },
        (err) => {
          this.showLoader = false;
          this._popUpDbService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }

  showLoaderForMoreData: boolean = false;
  callMoreOrderHistory: boolean = true;
  getMoreOrderHistoryOfUser() {
    this.showLoaderForMoreData = true;
    this.callMoreOrderHistory = false;
    this.pageNo += 1;
    this._userAccountService
      .getOrdersListByUserId(this.userId, this.pageNo, this.size)
      .subscribe(
        (res: any) => {
          //console.log(res);
          
          this.showLoader = false;
          this.showLoaderForMoreData = false;
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.ordersList.push(element);
              this.callMoreOrderHistory = true;
            });

            this.ordersList.forEach((element) => {
              element.isOrderComplete = true;
              if (element.orderType === 'DELIVERY') {
                if (element.orderdeliveryStatus !== 'DELIVERED') {
                  element.isOrderComplete = false;
                }
              } else {
                element.vendorOrderDetails.forEach((vendor) => {
                  if (vendor.orderStatus !== 'PAST')
                    element.isOrderComplete = false;
                });
              }
            });

          } else {
            this.callMoreOrderHistory = false;
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.showLoaderForMoreData = false;
          this._popUpDbService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }
  getGuestOrderHistoryOfUser() {

    this.showLoaderForMoreData = true;
    this.callMoreOrderHistory = false;
    this.pageNo += 1;
    this._userAccountService
      .getGuestOrderHistoryOfUser(this.userId, this.pageNo, this.size)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.showLoader = false;
          this.showLoaderForMoreData = false;
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.ordersList.push(element);
              this.callMoreOrderHistory = true;
            });
          } else {
            this.callMoreOrderHistory = false;
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.showLoader = false;
          this.showLoaderForMoreData = false;
          this._popUpDbService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }
  getGuestPendingOrderHistoryOfUser() {
    this.showLoaderForMoreData = true;
    this.callMoreOrderHistory = false;
    this.pageNo += 1;
    this._userAccountService
      .getGuestPendingOrderHistoryOfUser(this.userId, this.pageNo, this.size)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.showLoader = false;
          this.showLoaderForMoreData = false;
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.ordersList.push(element);
              this.callMoreOrderHistory = true;
            });
          } else {
            this.callMoreOrderHistory = false;
            // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
          }
        },
        (err) => {
          this.showLoader = false;
          this.showLoaderForMoreData = false;
          this._popUpDbService.openSnackBarMessage(
            'Not able to fetch orders',
            'Close'
          );
        }
      );
  }

  getMorePreOrderHistoryOfUser() {
    this.showLoaderForMoreData = true;
    this.callMoreOrderHistory = false;
    this.pageNo += 1;
    this._userAccountService.getUserPreOrderHistory(this.userId, this.pageNo, this.size).subscribe((response:any)=> {

      this.showLoader = false;
      this.showLoaderForMoreData = false;
      if (response.data.length > 0) {
        response.data.forEach((element) => {

          if (element.preOrderStartTime) {
            let hour = element.preOrderStartTime.split(":")[0];
            let minute = element.preOrderStartTime.split(":")[1];
            element.preOrderStartTime = new Date(new Date(element?.preOrderDate).setHours(hour, minute, 0, 0));
          } 
          if (element.preOrderEndTime) {
            let hour = element.preOrderEndTime.split(":")[0];
            let minute = element.preOrderEndTime.split(":")[1];
            element.preOrderEndTime = (new Date(element?.preOrderDate).setHours(hour, minute, 0, 0));
          }

          this.ordersList.push(element);
          this.callMoreOrderHistory = true;
        });
      } else {
        this.callMoreOrderHistory = false;
      }
    }, (err)=> {
      this.showLoader = false;
      this.showLoaderForMoreData = false;
      this._popUpDbService.openSnackBarMessage('Not able to fetch your pre-orders list','Close');
    })
  }

  showOrderSummary(order) {

    this._userAccountService.setTypeOfOrderSummary(this.selectedOrderType);

    if (this.selectedOrderType === "SELF_ORDERS") {
      
      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/self-order']);
    }
    else if (this.selectedOrderType === "GUEST_ORDERS") {

      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/guest-order']);
    }
    else if (this.selectedOrderType === "GUEST_REQUEST") {

      this._popUpDbService.setRequestId(order.requestId);
      this.router.navigate(['profile/user/order-summary/guest-request']);
    }
    else if (this.selectedOrderType === "PRE_ORDERS") {
      
      this._popUpDbService.setOrderId(order.orderId);
      this.router.navigate(['profile/user/order-summary/pre-order']);
    } 
    else if (this.selectedOrderType === "BTC_ORDERS") {

      this._popUpDbService.setRequestId(order.requestId);
      this.router.navigate(['profile/user/order-summary/corporate-order']);
    }
    

    // if (!order?.orderId) {
    //   if (this.userId) {
    //     this._popUpDbService.setRequestId(order.requestId);
    //     this.router.navigate(['profile/user/order-summary/guest-request']);
    //   } else {
    //     this._popUpDbService.openSnackBarMessage(
    //       'You are not logged in, please login',
    //       'Ok'
    //     );
    //   }
    // }
    // else {
    //   if (this.userId) {
    //     this._popUpDbService.setOrderId(order.orderId);
    //     if (this.selectedOrderType === "SELF_ORDERS")
    //       this.router.navigate(['profile/user/order-summary/self-order']);
    //     else if (this.selectedOrderType === "GUEST_ORDERS")
    //       this.router.navigate(['profile/user/order-summary/guest-order']);
    //     else if (this.selectedOrderType === "PRE_ORDERS")
    //       this.router.navigate(['profile/user/order-summary/pre-order']);
    //   } else {
    //     this._popUpDbService.openSnackBarMessage(
    //       'You are not logged in, please login',
    //       'Ok'
    //     );
    //   }
    // }
  }
  getBTCRequestListing() {

    this.showLoaderForMoreData = true;
    this.callMoreOrderHistory = false;
    this.pageNo += 1;
    this._userAccountService.getBTCRequestListing(this.userId).subscribe((response:any)=> {
      this.showLoader = false;
      this.showLoaderForMoreData = false;
      if (response.data.content.length > 0) {
        response.data.content.forEach((element) => {
          this.ordersList.push(element);
          this.callMoreOrderHistory = true;
        });
      } else {
        this.callMoreOrderHistory = false;
        // this._popUpDbService.openSnackBarMessage('No more orders', 'Close');
      }
    }, (err)=> {
      this.showLoader = false;
      this.showLoaderForMoreData = false;
      this._popUpDbService.openSnackBarMessage('Not able to fetch your corporate list','Close');
    })
  }

  // On Window scroll to bottom get more data
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    //In chrome and some browser scroll is given to body tag

    let pos = document.documentElement.scrollTop || document.body.scrollTop;
    let max = document.documentElement.scrollHeight - window.innerHeight;

    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.

    if (pos >= max - 60) {
      //Do your action here
      if (this.ordersList.length >= 10 && this.callMoreOrderHistory) {
        
        if (this.selectedOrderType === "SELF_ORDERS") {
          this.getMoreOrderHistoryOfUser();
        }
        else if (this.selectedOrderType === "GUEST_ORDERS") {
          // this.getGuestOrderHistoryOfUser();
          this.getGuestPendingOrderHistoryOfUser();
        } 
        else if (this.selectedOrderType === "BTC_ORDERS") {
          this.getBTCRequestListing();
        } else if (this.selectedOrderType === "PRE_ORDERS") {
          this.getMorePreOrderHistoryOfUser();
        }
        else {
          // this.getGuestPendingOrderHistoryOfUser();
          this.getGuestOrderHistoryOfUser();
        }
      }
      // //console.log('bottom');
      // alert('bottom');
    }
  }

  showOverallRatingPopup(order: any) {
    this.dialog.open(OverallRatingPopupComponent, {
      width: '95vw',
      height: 'auto',
      maxWidth: '100vw',
      maxHeight: '80vh',
      data: { orderId: order.orderId, for: 'OVERALL_RATING' },
      panelClass: 'no-padding',
    });
  }

  changeOrderListingType(orderType) {
    
    if (this.showLoader)
      return;

    this.showLoader = true;
    this.ORDER_TYPE.forEach((type)=> {
        if (type.value === orderType.value)
          type.status = true;
        else 
          type.status = false;
    });
    this.selectedOrderType = orderType.value;

    this.ordersList = [];
    this.pageNo = -1;
    this.size = 10;

    if (orderType.value === "SELF_ORDERS") {
      this.getMoreOrderHistoryOfUser();
    } else if (orderType.value === "PRE_ORDERS") {
      this.getMorePreOrderHistoryOfUser();
    } else if (orderType.value === "GUEST_ORDERS") {
      this.getGuestPendingOrderHistoryOfUser();
    } else if (orderType.value === "BTC_ORDERS") {
      this.getBTCRequestListing();
    } else {
      this.getGuestOrderHistoryOfUser();
    }
  }

  refreshPage() {
    
    this.ordersList = [];
    this.pageNo = -1;
    this.size = 10;
    this.showLoader = true;

    if (this.selectedOrderType === "SELF_ORDERS") {
      this.getMoreOrderHistoryOfUser();
    } else if (this.selectedOrderType === "PRE_ORDERS") {
      this.getMorePreOrderHistoryOfUser();
    } else if (this.selectedOrderType === "GUEST_ORDERS") {
      this.getGuestPendingOrderHistoryOfUser();
    } else if (this.selectedOrderType === "BTC_ORDERS") {
      this.getBTCRequestListing();
    } else {
      this.getGuestOrderHistoryOfUser();
    }

    // this.getOrderHistoryOfUser();
  }
  sendApprovalNotificationToEmployer(requestDetails) {
    if (this.selectedOrderType === "BTC_ORDERS") {

      this._userAccountService.sendRemainderForBTCRequest(requestDetails.employerId, requestDetails.requestId).subscribe((response)=> {
        this._popUpDbService.openSnackBarMessage("Request Sent", "CLOSE");
      }, (err)=> {
        this._popUpDbService.openSnackBarMessage("Failed to Send Request", "CLOSE");
      })

    } else if (this.selectedOrderType === "GUEST_REQUEST") {
      this._userAccountService.sendRemainderForGuestOrderRequest(requestDetails.employerId, requestDetails.requestId).subscribe((response)=> {
        this._popUpDbService.openSnackBarMessage("Request Sent", "CLOSE");
      }, (err)=> {
        this._popUpDbService.openSnackBarMessage("Failed to Send Request", "CLOSE");
      })
    }
  }

  goToRatingPage(order) {
    this._userAccountService.setReviewOrderId(order.orderId);
    this._userAccountService.setinfo_order("ORDER");
    this.router.navigate(['profile/user/order-history/rating']);
  }
}
