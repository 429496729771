import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { LandingComponentService } from '../../../../landing-component.service';
import { UserAccountService } from '../../user-account.service';
import { HelpdeskSuccessPopupComponent } from '../helpdesk-success-popup/helpdesk-success-popup.component';
import { HelpdeskService } from '../helpdesk.service';

export interface FoodCourt {
  name: string;
  id: string;
  siteName: string;
  siteId: string;
}
@Component({
  selector: 'app-raise-issue',
  templateUrl: './raise-issue.component.html',
  styleUrls: ['./raise-issue.component.scss']
})
export class RaiseIssueComponent implements OnInit, OnDestroy {

  constructor(
    private userAccountService: UserAccountService,
    private helpdeskService: HelpdeskService,
    private landingComponentService: LandingComponentService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  helpdeskCategoryId: string;
  myControl = new FormControl('');
  options = [];
  filteredOptions: Observable<FoodCourt[]>

  userId: string;
  helpdeskOrderId: string;
  foodcourtId: string;
  siteId: string;

  selectedFoodCourt : FoodCourt = {
    name: null,
    id: null,
    siteName: null,
    siteId: null
  }; 
  submitButtonLoader: boolean = false;
  helpdeskCategoryName;
  raiseIssueLoader: boolean = false;
  helpdeskFoodCourtId: string;

  ngOnInit(): void {

    this.userId = this.landingComponentService.getUserId();
    this.helpdeskCategoryId = this.helpdeskService.getHelpdeskCategoryId();
    this.helpdeskFoodCourtId = this.helpdeskService.getHelpdeskFoodCourtId();

    this.activatedRoute.params.subscribe((params)=> {
      
      this.helpdeskCategoryName = params.type.split("_").join(" ");
    })

    this.getAllFoodcourtList();
    this.getAllSubCategories();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  ngOnDestroy() {
    this.helpdeskService.setHelpdeskFoodCourtId(null);
  }

  getAllSubCategories() {
    this.raiseIssueLoader = true;
    this.helpdeskService.getAllFoodCourtSubCategoryById(this.helpdeskCategoryId).subscribe((response: any)=> {
        this.issueSubTypeList = response.data.map((category)=> {
          return {
            helpdeskSubCategoryName: category.helpdeskSubCategoryName,
            status: false
          }
        });
        this.raiseIssueLoader = false;
    }, (err)=> {
        this.raiseIssueLoader = false;
    })
  }

  selectFoodCourt(foodcourtDetails) {
    this.selectedFoodCourt = foodcourtDetails;
  }

  getAllFoodcourtList() {
    this.raiseIssueLoader = true;
    this.helpdeskService.getAllFoodCourtList().subscribe((response:any)=> {
      this.options = response.data.content.map((foodcourt)=> {
        return {
          name: foodcourt.foodCourtName,
          id: foodcourt.foodCourtId,
          siteId: foodcourt.siteId,
          siteName: foodcourt.siteName
        }
      });

      if (this.helpdeskFoodCourtId) {
        for (let i=0; i < this.options.length; i++) {
          if (this.options[i].id === this.helpdeskFoodCourtId) {
            this.selectedFoodCourt = this.options[i];
          }
        }
      }

      this.raiseIssueLoader = false;
    }, (err)=> {
      this.raiseIssueLoader = false;
      console.log(err);
    })
  }
  displayFn(foodcourt: FoodCourt): string {
    return foodcourt && foodcourt.name ? foodcourt.name : '';
  }

  private _filter(name: string): FoodCourt[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  
  loaderSpinner: boolean = false;

  issueImage;
  issueImages;

  issueDescription;

  updatedImage = [];
  selectedIssueType;
  issueSubTypeList = []

  async addMore(e) {
    this.loaderSpinner = true;
    for (let i = 0; i < e.srcElement.files.length; i++) {
      
      this.issueImage = await this.userAccountService.uploadFile(
        e.srcElement.files[i], "images"
      );
      
      if (this.issueImage?.url) {
        this.updatedImage.push({imageUrl: this.issueImage.url});
      } else {
      }
    }
    let data = await this.updatedImage;
    this.loaderSpinner = false;
  }

  createHelpDeskTicketForUser() {
    this.submitButtonLoader = true;

    let userName = this.landingComponentService.getUserName();
    let userMobileNumber = this.landingComponentService.getUserMobile();

    let sendingImageUrl = [];
    this.updatedImage.forEach((image)=> {
      sendingImageUrl.push(image.imageUrl)
    })
    let data = {
      "foodCourtUserInfo": {
        "foodCourtId": this.selectedFoodCourt.id,
        "foodCourtName": this.selectedFoodCourt.name,
        "orderId": this.helpdeskOrderId,
        "siteId": this.selectedFoodCourt.siteId,
        "siteName": this.selectedFoodCourt.siteName,
        "userId": this.userId,
        "userMobileNumber": userMobileNumber,
        "userName": userName
      },
      "helpdeskDetails": {
        "category": this.helpdeskCategoryName,
        "categoryId": this.helpdeskCategoryId,
        "helpdeskIssueImages": sendingImageUrl,
        "helpdeskTicketType": "FOOD_COURT",
        "issueDescription": this.issueDescription,
        "issueTitle": "string", // need to change
        "subCategory": this.selectedIssueType,
      },
      "raisedByUserType": "FOODCOURT_USER"
    }

    this.helpdeskService.createHelpDeskTicket(data).subscribe((response)=> {
      this.submitButtonLoader = false;
      // this.landingComponentService.openSnackBarMessage("Ticket Raised Successfully!!");
      // this.router.navigate(["profile/user"]);
      this.helpdeskService.openDialog(
        HelpdeskSuccessPopupComponent,
        '80vw',
        'auto',
        '100%',
        {data: {type: 'SUCCESS'}}
      )
    }, (err)=> {
      this.submitButtonLoader = false;
      this.landingComponentService.openSnackBarMessage("Failed to Raise Ticket.");
    })
  }

  // routing functions
  goToCategoryListingPage() {
    this.router.navigate(['profile/user/helpdesk-category']);
  }

}
