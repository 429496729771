import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';
import { UserAccountService } from '../user-account.service';
import { MatDialog } from '@angular/material/dialog';
import { UserpointsfilterComponent } from '../../other-components/userpointsfilter/userpointsfilter.component';
import { SuccessPopupComponent } from './success-popup/success-popup.component';

@Component({
  selector: 'app-user-points',
  templateUrl: './user-points.component.html',
  styleUrls: ['./user-points.component.scss'],
})
export class UserPointsComponent implements OnInit {
  constructor(
    private router: Router,
    private _userAccountService: UserAccountService,
    private _landingComponentService: LandingComponentService,
    private dialog: MatDialog
  ) {}

  showLoader: boolean = true;
  ngOnInit(): void {
    this.userId = this._landingComponentService.getUserId();

    this.getUserIstharaPoints();
  }

  userId: string;
  userRewardPoints = {
    istharaPoints: 0,
  };
  showRefundMessage: boolean = false;
  userPointsHistory: any[] = [];
  isFilteredData: boolean = false;
  transactionHistoryPanel = [
    { name: "Points History", value: "POINTS_HISTORY", status: true },
    { name: "Refund History", value: "BANK_REFUNDS", status: false }
  ];
  selectedTransactionHistoryPanel: string = "POINTS_HISTORY";

  getUserIstharaPoints() {
    this.showLoader = true;
    this._userAccountService.getUserRewardPoints(this.userId).subscribe(
      (res: any) => {
        this.userRewardPoints.istharaPoints = res.data;
      },
      (err) => {
        this._landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
        this.router.navigate(["profile/user"]);
      }, () => {
        if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
          this.getUserPointsHistory();
        } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
          this.getBankRefundPointsHistory();
        }
      }
    );
  }

  showPointsSummary(points) {
    
    if (points.paymentType !== "RECHARGE") {
    this._landingComponentService.setOrderId(points.orderId);
    this.router.navigate(["profile/user/order-summary/self-order"], {state: { fromPage: "USER_POINTS" }});
    } else {
      this._landingComponentService.setCashFreeId(points.cashFreeId);
      this.router.navigate(["profile/user/recharge-summary"]);
    }
  }

  toggleRefundMessage() {
    this.showRefundMessage = !this.showRefundMessage;
  }

  changeTransactionHistoryPanel(panelDetails) {

    if (this.isFilteredData) {
      this.isFilteredData = false;
    }

    this.transactionHistoryPanel.forEach((panel)=> {
      if (panel.value === panelDetails.value) {
        panel.status = true;
      } else {
        panel.status = false;
      }
    })
    this.selectedTransactionHistoryPanel = panelDetails.value;

    if (panelDetails.value === "POINTS_HISTORY") {
      this.getUserPointsHistory();
    } else if (panelDetails.value === "BANK_REFUNDS") {
      this.getBankRefundPointsHistory();
    }
  }

  getBankRefundPointsHistory() {
    this.showLoader = true;
    this._userAccountService.getUserPoints(this.userId).subscribe(
      (res: any) => {
        this.userPointsHistory = res.data.filter((pointsDetail)=> {
          return pointsDetail.refundStatus === "REFUND_INITIATED" || pointsDetail.refundStatus === "REFUND_COMPLETED";
        });
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
        this._landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
        // this.router.navigate(["profile/user"]);
      }
    );
  }

  goToProfilePage() {
    this.router.navigate(['profile/user']);
  }

  getUserPointsHistory() {
    this.showLoader = true;
    this._userAccountService.getUserPoints(this.userId).subscribe(
      (res: any) => {
        this.userPointsHistory = res.data;
        
        // this.userPointsHistory = res.data.filter((pointsDetail)=> {
          
        //   return pointsDetail.refundStatus !== "REFUND_INITIATED" && pointsDetail.refundStatus !== "REFUND_COMPLETED";
        // });
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
        this._landingComponentService.openSnackBarMessage(
          'Failed to get points',
          'Ok'
        );
        // this.router.navigate(["profile/user"]);
      }
    );
  }

  showOrderSummary(orderId: string) {
    this._landingComponentService.setOrderId(orderId);

    this.router.navigate(["profile/user/order-summary/self-order"], {state: { fromPage: "USER_POINTS" }});
  }

  goToRechargeWalletPage() {
    
    this.router.navigate(['profile/user/recharge-points'], {state: { fromPage: "USER_POINTS" }})
  }

  filterbtn(){

    const dialogRef = this.dialog.open(UserpointsfilterComponent, {
      width: '80%',
      height: 'auto',
      data: {filterFor: this.selectedTransactionHistoryPanel}
    });
    dialogRef.afterClosed().subscribe((response: any)=> {
        if (response) {
          if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
            this.userPointsHistory = response.filterData;

          } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
            this.userPointsHistory = response.filterData.filter((pointsDetail)=> {
              return pointsDetail.refundStatus === "REFUND_INITIATED" || pointsDetail.refundStatus === "REFUND_COMPLETED";
            });
          }
      
          this.isFilteredData = true;
        }
    })
  }

  closeFilter() {

    this.isFilteredData = false;
    if (this.selectedTransactionHistoryPanel === "POINTS_HISTORY") {
      this.getUserPointsHistory();
    } else if (this.selectedTransactionHistoryPanel === "BANK_REFUNDS") {
      this.getBankRefundPointsHistory();
    }
  }

  refundToWallet(pointsDetail) {

    let userEmail = this._landingComponentService.getUserEmail();
    let userMobile = this._landingComponentService.getUserMobile();
    let userId = this._landingComponentService.getUserId();
    let userName = this._landingComponentService.getUserName();

    let refundDetails = {
      "email": userEmail,
      "mobile": userMobile,
      "refundNote": "string",
      "userId": userId,
      "username": userName
    }
    this._userAccountService.initiateRefundToWallet(pointsDetail.orderId, pointsDetail.pointHistoryId, refundDetails).subscribe((response)=> {
        
      const dialogRef = this.dialog.open(SuccessPopupComponent, {
        width: '80%',
        height: 'auto',
      });
      dialogRef.afterClosed().subscribe(()=> {
        this.getUserIstharaPoints();
        this._landingComponentService.openSnackBarMessage("Refund Successfull");
      })
    }, (err)=> {
      this._landingComponentService.openSnackBarMessage("Refund Failed");
    })
  }

  refreshPage() {
    this.getUserIstharaPoints();
  }
}
