<div class="account-edit-section">
  <ng-container *ngIf="!showLoader; else loaderScreen">
    <div class="edit-page-main-section" [ngClass]="{'updated-edit-page-main-section': isTab}">
      <div class="edit-page-header">
        <div class="back-arrow" (click)="backToProfilePage()">
          <img
            src="../../../../../../../assets/images/common/back_arrow.svg"
            alt="Back Arrow"
          />
        </div>
        <div class="edit-profile-text">Edit Profile</div>
      </div>

      <!-- <img [src]="ds.bypassSecurityTrustUrl(profileImg)" /> -->

      <div class="profile-and-form">
        <div class="user-profile-section">
          <div class="profile-img">
            <img
              [src]="
                userDetails.profileImg
                  ? ds.bypassSecurityTrustUrl(userDetails.profileImg)
                  : '../../../../../../../assets/images/dummy/dummy_user.svg'
              "
              alt=""
              class="profile-img-element"
            />
            <div class="add-image-btn">
              <img
                src="../../../../../../../assets/images/common/edit_icon.svg"
                alt=""
              />
              <input
                type="file"
                name="myfile"
                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                (change)="fileChange($event)"
              />
            </div>
          </div>
        </div>

        <div class="user-details-form-section">
          <div class="user-detail-form">
            <div class="form-input">
              <div class="input-heading">User Name</div>
              <mat-form-field class="sfull-width">
                <div style="display: flex">
                  <input
                    matInput
                    [(ngModel)]="userDetails.userName"
                    type="text"
                    required
                  />
                </div>
              </mat-form-field>
            </div>

            <div class="form-input">
              <div class="input-heading">Mobile Number</div>
              <mat-form-field class="sfull-width">
                <div style="display: flex">
                  <input
                    matInput
                    readonly
                    [(ngModel)]="userDetails.userMobile"
                    type="number"
                    required
                  />
                </div>
              </mat-form-field>
            </div>

            <div class="form-input">
              <div class="input-heading">Email Id</div>
              <mat-form-field class="sfull-width">
                <div style="display: flex">
                  <input
                    matInput
                    [(ngModel)]="userDetails.userEmail"
                    type="email"
                    required
                  />
                </div>
              </mat-form-field>
            </div>

            <div class="form-radio-input">
              <div class="input-heading">Gender</div>

              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="userDetails.gender"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let gender of genderList; let i = index"
                  [value]="gender"
                  color="primary"
                >
                  {{ gender | titlecase }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="update-btn-section">
        <button
          class="update-btn"
          *ngIf="!loading; else loader"
          (click)="updateProfile()"
        >
          Save
        </button>
        <ng-template #loader>
          <button class="update-btn">
            <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-template #loaderScreen>
    <div class="loader-img-container">
      <img
        src="../../../../../../assets/images/common/burger_loader.gif"
        alt=""
      />
    </div>
  </ng-template>
</div>
