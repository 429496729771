import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../authentication/services/notification.service';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-display-qr-code',
  templateUrl: './display-qr-code.component.html',
  styleUrls: ['./display-qr-code.component.scss'],
})
export class DisplayQrCodeComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DisplayQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notificationService: NotificationService,
    private router: Router,
    private otherComponentService: OtherComponentService
  ) {}
  
  public dataSending: String ;
 

  ngOnInit(): void {
  
  
    
    // this.dataSending = this.data;
    if (this.data?.data) {
      this.dataSending = JSON.stringify(this.data.data);
    } else {
      this.dataSending = JSON.stringify(this.data);
    }
    // console.log(this.dataSending);
    
    console.log(this.data)

    this.notificationService.currentMessage.subscribe((data) => {
      //console.log('Notification working');
      if (data && (this.router.url.includes('profile/order-summary') || this.router.url.includes('home'))) {
        this.closeQrPopup();
      }
    });
  
  }

  

  closeQrPopup() {
    this.dialogRef.close();
  }

  copyQRCode() {

    console.log(this.data.data.ERROR_ID);
    const encryptedOrderId = btoa(this.data.data.ERROR_ID);
    // const encryptedOrderId = this.otherComponentService.getEncryptedOrderId(this.data.data.ERROR_ID);
    // console.log("Encrypted Order Id : ", encryptedOrderId);
    // TODO: Encrypt the orderId
    navigator.clipboard.writeText(`https://foodcourt.isthara.com/guest-ordering-qr/${encryptedOrderId}`);
    // navigator.clipboard.writeText(`https://isthara-food-court-dev.web.app/guest-ordering-qr/${encryptedOrderId}`);
    this.dialogRef.close("copied");
  }
}
