<section class="total-section">
  <div class="header_total-section">
    <div>
      <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="Back" (click)="goToHomePage()">
    </div>
    <h1> Notifications</h1>
  </div>

  <main class="main-section">
    <ng-container *ngIf="!showLoader; else loaderScreen">
      <ng-container
        *ngIf="notificationData[0].list.length > 0 || notificationData[1].list.length > 0 || notificationData[2].list.length > 0; else emptyScreen">
        <ng-container *ngFor="let element of notificationData">
          <ng-container *ngIf="element.list.length > 0">
            <h1>{{element.title | titlecase }}</h1>
            <ng-container *ngFor="let item of element.list; index as notifyIndex">
              <div class="card-section" [ngStyle]="{'background-color' : item.clicked ? 'white' : '#f0f0f0'}">
                <div class="card-image">
                  <img src="../../../../../../../assets/images/dummy/notification_default.svg" alt="Notification Image"
                    *ngIf="!item.customNotificationRequest.notificationImageUrl">
                  <img [src]="item.customNotificationRequest.notificationImageUrl" alt="Notification Image"
                    *ngIf="item.customNotificationRequest.notificationImageUrl">
                  <span *ngIf="!item.clicked"></span>
                </div>
                <div class="card-main">
                  <div class="card-top">
                    <h2 class="card-title">{{ item.customNotificationRequest.title | titlecase }}</h2>
                    <span class="card-timing">{{ item.updateTimeStamp | date:'shortTime' }}</span>
                  </div>
                  <div class="card-middle">
                    <p class="card-content">
                      {{ (item.customNotificationRequest.message.substring(0, 99) | titlecase) +
                      (item.customNotificationRequest.message.length > 100 ? '...' : '')}}
                    </p>
                    <span class="card-action">
                      <img src="../../../../../../../assets/images/common/three-dots.svg" alt="Action"
                        (click)="toggleActionPopup(notifyIndex, element.title)" class="dots-icon">

                      <div class="action-popup" *ngIf="item.showActionIcon">
                        <div class="action-icons" (click)="deleteNotification(item.id)">
                          <span><img src="../../../../../../../assets/images/common/delete.svg" alt="Delete"></span>
                          <span>Delete</span>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="card-bottom" (click)="showNotificationDetails(item)">
                    <span>View More</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </main>
</section>

<ng-template #emptyScreen>
  <div class="empty-part">
    <div class="no-image">
      <img src="../../../../../../../assets/images/common/bell-icon.svg" alt="empty-image" />
    </div>
    <div class="empty-heading">Looks like you do not have any Notifications!!</div>
    <!-- <div class="emptyrules-text">Please select another food court.</div> -->
  </div>
</ng-template>

<ng-template #loaderScreen>
  <div class="loader-img-container">
    <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="" />
  </div>
</ng-template>
