import { Component, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SuccessPopupComponent>,
  ) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.dialogRef.close();
  }
}
