// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://newdev-api.isthara.com',
  // baseUrl: 'http://192.168.101.50:8765',
  cashFree: 'https://test.cashfree.com/billpay/checkout/post/submit',
  localIp: 'http://localhost:8765',
  testUrl: '/ist', //testing purpose

  sasToken: "?sp=racwli&st=2023-07-06T05:50:23Z&se=2030-07-06T13:50:23Z&sv=2022-11-02&sr=c&sig=xzzIxmfnGiKYdMaiacsfo%2Bps%2Bfc4R94U6BrzRNAUeFw%3D",
  accountName: "istharadev",
  containerName: "dev-isthara",

  firebase: {
    apiKey: 'AIzaSyD20ru8QZUy34tkgcEFkl8EcWLDJp9HQjg',
    authDomain: 'isthara-7eae1.firebaseapp.com',
    projectId: 'isthara-7eae1',
    storageBucket: 'isthara-7eae1.appspot.com',
    messagingSenderId: '1005405471256',
    appId: '1:1005405471256:web:9847e18b512a180cb3396d',
  },
  application_identity_Key:
    'BGMjIKCVnZt3PG-kqqvPIZ_HoaN5PJaFqCHfRO7aXg0xPsAKEd87FDpElkvkAR67fbQ7PM3b_4HqP05PpLf-O94',
  googleMapsKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
  imageUploadSize: 1000000,
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: ' us-east-1:39c0b6a2-7c1e-4452-ab8c-c3e9a79aeafe',
          // PoolId: 'us-east-1:a50ade6f-3713-48a8-a8c4-15ce1237ac6d',
          // PoolId: 'ap-south-1:521f51d4-7401-4952-a089-12dd84828d42', // Migratuib PoolId
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'dev-isthara', // dev-isthara
        // Bucket: 'newstage-isthara', // Migration Bucket
        Region: 'us-east-1', 
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
