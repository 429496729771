import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { PopupDbService } from '../services/popup-db.service';
import { SigninSignupServicesService } from '../services/signin-signup-services.service';
// import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { CountdownComponent } from 'ngx-countdown';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LandingComponentService } from '../../landing-component/landing-component.service';
import { NotificationService } from '../services/notification.service';
import { v1 as uuidv1 } from 'uuid';

@Component({
  selector: 'user-flow-signin-signup-flow',
  templateUrl: './signin-signup-flow.component.html',
  styleUrls: [
    './signin-signup-flow.component.scss',
    './mq-signin-signup-flow.component.scss',
  ],
})
export class SigninSignupFlowComponent implements OnInit {
  viewMode = 'signIn';
  // viewMode = 'set-password';
  errorMessage: boolean = false;
  placeholder: string = 'Mobile Number Or Email Id';
  loader: boolean = false;
  staffpassword = '';
  staffusername = '';
  isLoginWithPassword: boolean = false;
  isInLoginPage: boolean = true;
  newUserpassword: string;
  confirmUserPassword: string;

  constructor(
    private apiService: SigninSignupServicesService,
    private router: Router,
    private PopupDbService: PopupDbService,
    private landingComponentService: LandingComponentService,
    private _notificationService: NotificationService
  ) {
    this.getScreenSize();
    this.isNavigationFromCart =
      this.router.getCurrentNavigation().extras?.state?.isFromCart;
  }

  scrHeight: number;
  scrWidth: number;
  isForgotPasswordFlow: boolean = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  
  isNavigationFromCart: boolean = false;
  mobileExp = '^[5-9][0-9]{9}$';
  mailExp =
    '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$';
  typefUserAuth;
  otpValue: any = null;

  user = {
    countryCode: '91',
    mobile: '',
    email: '',
    value: '',
    roles: ['USER'],
    fullName: '',
    otp: '',
  };

  // keywords
  login = {
    countryCode: '91',
    mobile: '',
    email: '',
    value: '',
  };
  signUp = {
    fullname: '',
    mobile: '',
    email: '',
    countryCode: '91',
    roles: ['USER'],
  };

  verifyotp = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  };
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '30px',
      height: '30px',
      background: '#f8fffe',
      border: '1px solid gray',
      padding: '10px',
      'margin-top': '10px',
      'text-align': 'center',
      'font-size': '18px',
      'font-weight': '900',
      outline: 'none',
      color: '#ff1872',
      'font-family': 'nunitoBold',
      'margin-right': 'calc((100% - 276px)/9)',
    },
  };
  desktopConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '45px',
      height: '45px',
      background: '#f8fffe',
      border: '1px solid gray',
      padding: '10px',
      'margin-top': '10px',
      'text-align': 'center',
      'font-size': '18px',
      'font-weight': '900',
      outline: 'none',
      color: '#ff1872',
      'font-family': 'nunitoBold',
      'margin-right': 'calc((100% - 276px)/9)',
    },
  };

  counterAction = 'start';
  @ViewChild('countdown', { static: false })
  private countdown: CountdownComponent;
  fb_token: any;

  ngOnInit(): void {
    // //console.log(this.PopupDbService.getToken());
    // this.landingComponentService.setIsFirstTimeLoading(true);

    this.fb_token = this.landingComponentService.getFirebaseToken();
    this.deviceId = this.landingComponentService.getUserDeviceId();
    if (!this.deviceId) {
      let deviceId = uuidv1();
      this.landingComponentService.setUserDeviceId(deviceId);
    }
    if (this.fb_token) {
    } else {
      this.fb_token = this._notificationService.requestPermission();
    }
  }

  goToHome() {
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`${foodCourtName}`]);
  }
  handleCounter(ev) {
    this.counterAction = ev.action;
  }

  // //otp fields onfocus
  // move(fromnum, tonum, event) {
  //   var charCode = event.which ? event.which : event.keyCode;
  //   // Only Numbers 0-9
  //   if (charCode < 48 || charCode > 57) {
  //     event.preventDefault();
  //     fromnum.style.border = '2px solid red';
  //     fromnum.style.backgroundColor = '#ff1872';
  //     fromnum.style.color = '#fff';
  //     // return false;
  //   }
  //   else {
  //     fromnum.style.border = '1px solid gray';
  //     fromnum.style.backgroundColor = '#fff';
  //     fromnum.style.color = '#ff1872';
  //     var length = fromnum.length;
  //     var maxlength = fromnum.getAttribute(maxlength);
  //     if (length == maxlength) {
  //       tonum.focus();
  //     }
  //   }
  // }
  // back(fromnum, tonum, event) {
  //   var key = event.keyCode || event.charCode;
  //   if (key == 8) {
  //     var length = fromnum.length;
  //     var maxlength = fromnum.getAttribute(maxlength)
  //     if (length == maxlength) {
  //       tonum.focus()
  //     }
  //   }
  // }
  onOtpEnter(otp) {
    this.otpValue = otp;
    // //console.log(this.otpValue)
  }

  //api integrations
  signUpdata;
  signInCheckBox: boolean = true;
  signUpCheckBox: boolean = true;
  registerBtn() {

    this.signUpdata = {
      email: this.user.email ? this.user.email.replace(/\s/g, "") : '',
      fullName: this.user.fullName,
      mobile: this.user.mobile,
      countryCode: '91',
    };
    if (
      this.user.fullName === '' ||
      this.user.mobile === '' ||
      this.user.email === this.user.mobile ||
      this.user.mobile.length !== 10
    ) {
      this.PopupDbService.openSnackBarMessage(
        'Please fill appropriate details',
        'Close'
      );
    } else {
      this.apiService.signUp(this.signUpdata).subscribe(
        (signUpdata: any) => {
          if (signUpdata.error) {
            this.PopupDbService.openSnackBarMessage(signUpdata.message, 'OK');
          } else {
            this.PopupDbService.openSnackBarMessage(
              `OTP sent to your mobile number`,
              'close'
            );
            this.isInLoginPage = false;
            this.viewMode = 'otp';
          }
        },
        (err) => {
          this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );
    }
  }
  signIndata;
  expTestSignUp(e) {
    this.errorMessage = false;
    const mExp = new RegExp(this.mobileExp);
    const emailExp = new RegExp(this.mailExp);
    if (mExp.test(e)) {
      this.typefUserAuth = 'mobile';
      this.user.mobile = this.user.value;
      this.signIndata = {
        mobile: this.user.mobile,
        countryCode: this.user.countryCode,
      };
    } else {
      this.errorMessage = true;
    }
  }

  //login api

  deviceId;
  loginBtn() {
    this.loader = true;

    this.fb_token = this.landingComponentService.getFirebaseToken();
    this.deviceId = this.landingComponentService.getUserDeviceId();

    let deviceInfo = {
      brand: null,
      deviceId: this.deviceId,
      firebaseToken: this.fb_token,
      heightPixels: 0,
      model: null,
      os: null,
      osVersion: null,
      widthPixels: 0,
    };
    let data;
    
    if (this.isLoginWithPassword && this.viewMode !== 'forgot-password') {
      data = {
        countryCode: '91',
        loginString: this.staffusername,
        deviceInfo: deviceInfo,
        password: this.staffpassword
      }
    } else {
      data = {
        countryCode: '91',
        loginString: this.staffusername,
        deviceInfo: deviceInfo
      };
    }
    this.apiService.signIn(data).subscribe(
      (response: any) => {
        //console.log(response);
        
        this.isInLoginPage = true;
        
        if (!this.isLoginWithPassword || this.viewMode === 'forgot-password') { 
          this.changeViewMode('otp');
        }
        else {
          this.PopupDbService.setUserId(response.data.user.userId);
          this.PopupDbService.setUserName(response.data.user.fullName);
          this.PopupDbService.setUserMobile(response.data.user.mobile);
          this.PopupDbService.setUserEmail(response.data.user.email);
          this.PopupDbService.setToken(response.data.token);
          
          // if (this.viewMode !== 'forgot-password') {
            let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
            this.router.navigate([`${foodCourtName}`]);
          // } else {
          //   this.changeViewMode('set-password');
          // }
        }
        this.loader = false;
      },
      (err) => {
        //console.log(err);
        if (err.status === 404) {
          this.apiService.openSnackBarMessage('User not registered', 'Close');
          if (!this.isLoginWithPassword) this.changeViewMode('signUp');
        } else {
          this.apiService.openSnackBarMessage(
            'Something went wrong, please try again',
            'Ok'
          );
        }
        this.loader = false;
      }
    );

    // if (this.user.value == null || this.user.value == '') {
    //   // //console.log('please fill all mandatory fileds', this.signIndata);
    //   this.PopupDbService.openSnackBarMessage(
    //     'Please Enter Mobile / Email id',
    //     'Cancel'
    //   );
    // } else {
    // let details = {
    //   userName: this.staffusername
    // };
    // this.apiService.stafflogin(details).subscribe(
    //   (res: any) => {
    //     this.router.navigate(['/erp/erp-isthara/enquiry'], {
    //       queryParams: {
    //         subPath: 'allenquiry',
    //         main: 'Tenant Leads',
    //         config: 'main',
    //       },
    //     });
    //     // //console.log('stafff', res.data);
    //     this.PopupDbService.setStaffId(res.data.staffId);
    //     this.PopupDbService.setToken(res.data.token);
    //     this.PopupDbService.setUserName(res.data.contactDetails.name);
    //     this.PopupDbService.setPermissions(res.data.permissions);
    //   },
    //   (err: any) => {
    //     // //console.log("not correct",err)
    //     this.PopupDbService.openSnackBarMessage(
    //       'Invalid credentials',
    //       'Cancel'
    //     );
    //     this.loader = false;
    //   }
    // );
    // }
  }

  //verification for otp

  verifyLoader: boolean = false;
  verifyBtn() {

    this.loader = true;
    let verifyOtpObj = null;
    this.fb_token = this.landingComponentService.getFirebaseToken();
    this.deviceId = this.landingComponentService.getUserDeviceId();

    let deviceInfo;
    // if (this.fb_token) {
      deviceInfo = {
        brand: null,
        deviceId: this.deviceId,
        firebaseToken: this.fb_token,
        heightPixels: 0,
        model: null,
        os: null,
        osVersion: null,
        widthPixels: 0,
      };
    // } else {
    //   deviceInfo = null;
    // }

    // if (this.typefUserAuth === 'mobile') {
      verifyOtpObj = {
        countryCode: '91',
        forgotString: (this.isInLoginPage ? this.staffusername : this.user.mobile),
        otp: Number(this.otpValue),
        deviceInfo: deviceInfo,
      };
      this.verifyService(verifyOtpObj);
    // } else if (this.typefUserAuth === 'email') {
    //   verifyOtpObj = {
    //     email: this.staffusername,
    //     otp: Number(this.otpValue),
    //     deviceInfo: deviceInfo,
    //   };
    //   this.verifyService(verifyOtpObj);
    // }
  }

  resetPassword() {
    this.apiService.forgotPassword(this.staffusername).subscribe((response:any)=> {
      this.changeViewMode('otp');
    }, (err)=> {
      this.landingComponentService.openSnackBarMessage("Failed to Send OTP.");
    })
  }

  verifyService(verifyOtpObj) {

    let isPasswordRequired = true;
    this.apiService.verifyNow(verifyOtpObj).subscribe(
      (response: any) => {
        if (response.error) {
          this.PopupDbService.openSnackBarMessage(response.messasge, 'Cancel');
          this.viewMode = 'signIn';
        } else {
          //console.log(response);
          let userName = response.data.user.fullName;
          let userId = response.data.user.userId;
          let userMobile = response.data.user.mobile;
          let tokenId = response.data.token;
          let userEmail = response.data.user.email;
          isPasswordRequired = (!response.data.user.passwordPresent || this.isForgotPasswordFlow);

          this.PopupDbService.setUserId(userId);
          this.PopupDbService.setUserName(userName);
          this.PopupDbService.setUserMobile(userMobile);
          this.PopupDbService.setUserEmail(userEmail);
          this.PopupDbService.setToken(tokenId);

          this.apiService.getHasUserBTCAccessInFoodcourt(userId).subscribe((response: any)=> {

            this.PopupDbService.setHasUserBTCAccessInCurrFC(response.data);
          }, (err)=> {

            this.PopupDbService.setHasUserBTCAccessInCurrFC(false);
          }).add( ()=> {

            // this.PopupDbService.openSnackBarMessage('Succesfully Logged In','Cancel');

            if (isPasswordRequired) {
              this.viewMode = 'set-password';
            }
            else if (this.isNavigationFromCart) {
              const foodCourtName =
                this.landingComponentService.getFoodCourtName();
  
              let foodCourtNameUrl = foodCourtName
                ? foodCourtName?.split(' ').join('-').toLowerCase()
                : ' ';

              if (this.scrWidth < 600) {
                this.router.navigate([`cart/${foodCourtNameUrl}`],{state: { initiatePayment: true }});
              } else {
                this.router.navigate([`${foodCourtNameUrl}`]);
              }
            } else {
              let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
              this.router.navigate([`${foodCourtName}`]);
            }
          })
        }

        this.loader = false;
      },
      (err) => {
        // //console.log('something went wrong', err.error);
        this.loader = false;
        this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
      }
    );
  }

  loginWithPassword() {
    let userId = this.PopupDbService.getUserId();
    
    this.apiService.updateUserPassword(userId, this.newUserpassword).subscribe(()=> {
      let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
      this.router.navigate([`${foodCourtName}`]);
    }, (err)=> {

    })
  }


  skipEnteringPassword() {
    
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`${foodCourtName}`]);
  }
  //Resend Otp

  resend() {
    let resendotp = {
      mobile: this.staffusername || this.user.mobile,
      countryCode: '91',
    };
    this.apiService.resendOtp(resendotp).subscribe(
      (response: any) => {
        if (response.error) {
          this.PopupDbService.openSnackBarMessage(response.messasge, 'Cancel');
        } else {
          // this.PopupDbService.openSnackBarMessage(
          //   `${response.data} to ${this.user.value}`,
          //   'close'
          // );
          this.PopupDbService.openSnackBarMessage(
            'Otp has been sent to your mobile number and email',
            'Ok'
          );
        }
      },
      (err) => {
        this.PopupDbService.openSnackBarMessage(err.error.message, 'Cancel');
        // //console.log('error message', err);
      }
    );
  }

  changeViewMode(value) {
    this.viewMode = value;
    if (this.viewMode === 'forgot-password') {
      this.isForgotPasswordFlow = true;
    }
  }
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  s;
  hide = true;
  get passwordInput() {
    return this.signin.get('password');
  }

  toggleLoginWithPassword() {
    this.placeholder = 'Enter Username';
    this.isLoginWithPassword = !this.isLoginWithPassword;
  }
}
