<div class="order-cancellation-main-section">
  <div class="order-cancellation-header">
    <div class="heading-text">Are you sure, you want to cancel the order?</div>
    <!-- <img
      (click)="goToOrderSummaryPage()"
      src="../../../../../../../assets/images/common/close.svg"
      alt=""
    /> -->
  </div>
  <div class="order-cancellation-reason">
    <div class="sub-heading-text">
      If you cancel order the amount will be refunded in wallet by 24 hours
    </div>
    <!-- <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedReason" placeholder="- Select reason -">
        <ng-container *ngFor="let reason of cancellationReasonList">
          <mat-option [value]="reason.value">{{
            reason.name | titlecase
          }}</mat-option>
        </ng-container>
      </mat-select>

    </mat-form-field> -->
    <div class="description-box">
      <textarea
        matInput
        placeholder="Please specify the reason"
        [(ngModel)]="reasonForCancel"
      ></textarea>
    </div>
  </div>
  <div class="action-buttons">
    <button class="cancel-btn act-btn" (click)="goToOrderSummaryPage()">
      Close
    </button>
    <button
      class="submit-btn act-btn"
      *ngIf="!loading; else loader"
      (click)="cancelOrderOfUser()"
      [disabled]="!reasonForCancel"
    >
      Cancel order
    </button>
    <ng-template #loader>
      <button class="submit-btn act-btn">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
      </button>
    </ng-template>
  </div>
</div>
