<div class="footer-section">
    <!-- <div class="home-page icon-container" (click)="redirectHomePage()">
        <img src="../../../../../../assets/images/footer/home.svg" alt="">
        <div>Isthara</div>
    </div>
    <div class="search-page icon-container" (click)="redirectSearchPage()">
        <img src="../../../../../../assets/images/footer/search.svg" alt="">
        <div>Search</div>
    </div>
    <div class="cart-page icon-container" (click)="redirectCartPage()">
        <img src="../../../../../../assets/images/footer/cart.svg" alt="">
        <div>Cart</div>
    </div>
    <div class="account-page icon-container" (click)="redirectAccountPage()">
        <img src="../../../../../../assets/images/footer/account.svg" alt="">
        <div>Account</div>
    </div> -->
    <ng-container *ngFor="let footer of footerList">
        <div class="account-page icon-container" (click)="redirect(footer)">
            <img *ngIf="!footer.status" src="../../../../../../assets/images/footer/{{ footer.name }}.svg" alt="">
            <img *ngIf="footer.status" src="../../../../../../assets/images/footer/{{ footer.name }}_active.svg" alt="">
            <div [ngClass]="{'active': footer.status}">{{ footer.name | titlecase }}</div>
        </div>
    </ng-container>
</div>