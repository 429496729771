import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { UserAccountService } from '../user-account.service';
import { UserAddressComponent } from '../user-address/user-address.component';

@Component({
  selector: 'app-list-user-address',
  templateUrl: './list-user-address.component.html',
  styleUrls: ['./list-user-address.component.scss'],
})
export class ListUserAddressComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ListUserAddressComponent>,
    private _userAccountService: UserAccountService,
    private _popUpDbService: PopupDbService
  ) {}

  ngOnInit(): void {
    this.userId = this._popUpDbService.getUserId();

    this.getAddressDetailsOfUser();
  }

  addressList = [];
  userId: string = '';

  getAddressDetailsOfUser() {
    this._userAccountService.getSavedAddressesOfUser(this.userId).subscribe(
      (res: any) => {
        //console.log(res);
        this.addressList = res.data;
      },
      (err) => {
        this._popUpDbService.openSnackBarMessage(
          'Failed to load address',
          'Ok'
        );
      }
    );
  }

  selectAddress(address) {
    this.addressList.forEach((add) => {
      add.isSelected = false;
    });
    address.isSelected = true;
    this.dialogRef.close(address);
  }

  goToaddNewAddressPage() {
    const dialogRef = this.dialog.open(UserAddressComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: 'no-padding',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getAddressDetailsOfUser();
      }
    });
  }
  goToPreviousPage() {
    this.dialogRef.close(null);
  }
}
