import { Injectable } from '@angular/core';
import SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class GlobalCartLocalstorageService {

  constructor() { }
  private _lsService:any = new SecureLS({encodingType:'rc4', isCompression: false});

  
  setOrderFoodCourtId(body: any) {
    this._lsService.set('orderFoodCourtId', body);
  }
  setOrderFoodCourtName(body: any) {
    this._lsService.set('orderFoodCourtName', body);
  }

  setIsOrderTypePreOrder(body: any) {
    this._lsService.set('isPreOrder', body);
  }
  getIsOrderTypePreOrder() {
    const isPreOrderType = this._lsService.get('isPreOrder');
    if (isPreOrderType)
      return isPreOrderType;
    return null;
  }

  getFoodCourtId() {
    const foodCourtId = this._lsService.get('foodCourtId');
    if (foodCourtId) {
      return foodCourtId;
    }
    return null;
  }
  getFoodCourtName() {
    const foodCourtName = this._lsService.get('foodCourtName');
    if (foodCourtName) {
      return foodCourtName;
    }
    return null;
  }
  getUserMobile() {
    const userMobile = this._lsService.get('userMobile');
    if (userMobile) {
      return userMobile;
    }
    return null;
  }
  getUserName() {
    const UserName = this._lsService.get('userName');
    if (UserName) {
      return UserName;
    }
    return null;
  }
  getUserId() {
    const userId = this._lsService.get('userId');
    if (userId) {
      return userId;
    }
    return null;
  }

  setBTCRequestId(body: any) {
    this._lsService.set('btcRequestId', body);
  }

  setHasUserBTCAccessInCurrFC(body: any) {
    this._lsService.set('hasBTCAccessInCurrFC', body);
  }
  getHasUserBTCAccessInCurrFC() {
    const hasBTCAccessInCurrFC = this._lsService.get('hasBTCAccessInCurrFC');
    if (hasBTCAccessInCurrFC) {
      return hasBTCAccessInCurrFC;
    }
    return null;
  }

  setOrderingFoodcourtHasBTC(body: any) {
    this._lsService.set('orderingFoodCourtHasBTC', body);
  }
  getOrderingFoodcourtHasBTC() {
    const orderingFoodCourtHasBTC = this._lsService.get('orderingFoodCourtHasBTC');
    if (orderingFoodCourtHasBTC) {
      return orderingFoodCourtHasBTC;
    }
    return null;
  }

  setPreOrderMenuType(body: any) {
    this._lsService.set('preOrderingMenuType', body);
  }
  getPreOrderMenuType() {
    const preOrderingMenuType = this._lsService.get('preOrderingMenuType');
    if (preOrderingMenuType) {
      return preOrderingMenuType;
    }
    return null;
  }

  setPreOrderDetails(body: any) {
    this._lsService.set('preOrderDetails', body);
  }
  getPreOrderDetails() {
    const preOrderDetails = this._lsService.get('preOrderDetails');
    if (preOrderDetails) {
      return preOrderDetails;
    }
    return null;
  }

}
