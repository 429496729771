<div class="helpdesk-listing-section">
    <div class="helpdesk-listing__header-section" (click)="goToBack()" >
        <div class="header__left-arrow-contaier">
            <img src="../../../../../../../../assets/images/common/back_arrow.svg" alt="">
        </div>
        <div class="header__primary-text">My Issues</div>
    </div>
    <div class="helpdesk-listing__content-section">
        <div class="issue-type-selection__listing-container">
            <ng-container *ngFor="let issueType of issueTypeListing">
                <div class="issue-type-container" [ngClass]="{'issue-type-active': issueType.status}" (click)="selectIssueType(issueType)">{{ issueType.name }}</div>
            </ng-container>
        </div>
        <div class="issue-listing__container-section" *ngIf="!getAllLoader">
            <!-- <div class="open-issue-dropdown" *ngIf="selectedIssueType === 'OPEN_ISSUES'">
                <mat-form-field appearance="outline" >
                    <mat-select [(ngModel)]="selectedIssueInOpenIssue" (ngModelChange)="changeOpenIssue()">
                        <ng-container *ngFor="let openIssueType of openIssueTypeListing">
                            <mat-option [value]="openIssueType.value">{{ openIssueType.name }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <ng-container *ngIf="issueListing.length > 0; else emptyscreen">
                <ng-container *ngFor="let item of issueListing">
                    <div class="single-ticket-container" >
                        <div class="single_ticket" (click)="goToTicketDetailPage(item)">
                            <div class="ticket_action_id">
                                <div class="ticket_action"
                                [ngStyle]="{
                                color: 
                                item?.ticketStatus === 'NEW'
                                ? '#FF74AA'
                                : item?.ticketStatus === 'IN_PROGRESS'
                                ? '#FD9357'
                                : item?.ticketStatus ==='RESOLVED'
                                ? '#46948F'
                                : item?.ticketStatus === 'CLOSED'
                                ? '#28706C'
                                : item?.ticketStatus === 'RE_OPENED'
                                ? '#FF74AA'
                                : item?.ticketStatus === 'ASSIGNED'
                                ? '#FFD32D'
                                :null
                                }
                                ">&#x2022; {{item?.ticketStatus.split('_').join(' ') | titlecase}}</div>
                                <div class="ticket_id">
                                    <span class="ticket_text">Ticket Id: </span>&nbsp;
                                    <span class="id">{{item?.helpdeskDisplayId}}</span>
                                </div>
                            </div>
                            <div class="img_issue-title_issue-description">
                                <div class="issue_image">
                                    <img src="./../../../../../../../../assets/images/help_desk/{{item?.category.split(' ').join('_')}}.svg" onerror="this.src='./../../../../../../../../assets/images/help_desk/food_quality_category.svg'" alt="delivery_related">
                                </div>
                                <div class="title_description">
                                    <div class="title">{{item?.subCategory | titlecase}}</div>
                                    <div class="description">{{item?.issueDescription | titlecase}}</div>
                                </div>
                            </div>
                            <div class="issue_generated_date" *ngIf="(!item.resolvedDesc || item.ticketStatus === 'RE_OPENED')">
                                {{item?.createdTimestamp | date:'medium'}}
                            </div>
                            <div class="issue_resolved_description" *ngIf="item.resolvedDesc && item.ticketStatus !== 'RE_OPENED'">
                                <span class="dot"></span>
                                {{ item.resolvedDesc.split('UTC')[0] | titlecase }}
                            </div>
                        </div>
                        <div class="rating-action-btn">
                            <ng-container *ngIf="(item?.ticketStatus ==='RESOLVED' && item.serviceRating === 0)">
                                <hr />
                                <!-- <div class="rate-service-btn" (click)="closeTicket(item)">
                                    <button>Close Ticket</button>
                                </div> -->
                                <div class="rate-service-btn" (click)="rateResolvedTicket(item)">
                                    <button>Rate Ticket</button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(item?.ticketStatus ==='RESOLVED' && item.serviceRating > 0)">
                                <hr />
                                <div class="rating-container">
                                    <div class="rating-container__left-section">
                                        <div class="rating-text">Rated</div>
                                        <div class="dots">:</div>
                                        <div class="rating-star-text">
                                            <span *ngIf="item.serviceRating === 1">Poor Performance</span>
                                            <span *ngIf="item.serviceRating === 2">Not Good</span>
                                            <span *ngIf="item.serviceRating === 3">Need Improvement</span>
                                            <span *ngIf="item.serviceRating === 4">Very Good</span>
                                            <span *ngIf="item.serviceRating === 5">Excellent Work</span>
                                        </div>
                                    </div>
                                    <div class="rating-container__right-section">
                                        <div class="rating-star-container">
                                            <ng-container *ngFor="let i of [].constructor(5); let index = index">
                                                <img src="../../../../../../../../assets/images/ratings/rating-star-fill.svg" alt="" *ngIf="index < item.serviceRating">
                                                <img src="../../../../../../../../assets/images/ratings/rating-star-empty.svg" alt="" *ngIf="index >= item.serviceRating">
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="getAllLoader">
            <div class="loader-img-container">
                <img
                    src="../../../../../../assets/images/common/burger_loader.gif"
                    alt=""
                />
                </div>
        </div>
        <ng-template #emptyscreen>
            <div class="empty-part">
              <div class="no-image">
                <img
                  src="./../../../../../../../../assets/images/help_desk/empty_screen.svg"
                  alt="empty-image"
                />
              </div>
              <div class="empty-heading" *ngIf="selectedIssueType === 'ALL_ISSUES'">No Issues</div>
              <div class="empty-heading" *ngIf="selectedIssueType === 'OPEN_ISSUES'">No Opened Issues</div>
              <div class="empty-heading" *ngIf="selectedIssueType === 'RESOLVED_ISSUES'">No Resolved Issues</div>
              <div class="empty-heading" *ngIf="selectedIssueType === 'INPROGRESS_ISSUES'">No Inprogress Issues</div>
              <div class="empty-heading" *ngIf="selectedIssueType === 'CLOSED_ISSUES'">No Closed Issues</div>
              <div class="empty-subtext" *ngIf="selectedIssueType === 'ALL_ISSUES'">You have not added any issue yet.</div>
              <div class="empty-subtext" *ngIf="selectedIssueType === 'RESOLVED_ISSUES'">You don't have any opened tickets.</div>
              <div class="empty-subtext" *ngIf="selectedIssueType === 'INPROGRESS_ISSUES'">You don't have any inprogress tickets.</div>
              <div class="empty-subtext" *ngIf="selectedIssueType === 'OPEN_ISSUES'">You don't have any resolved.</div>
              <div class="empty-subtext" *ngIf="selectedIssueType === 'CLOSED_ISSUES'">You don't have any closed ticket.</div>
              <!-- <div class="emptyrules-text">the menu</div> -->
            </div>
          </ng-template>
    </div>
</div>
<div>
    <img src="./../../../../../../../../assets/images/help_desk/add_ticket.svg" alt="add" class="add-ticket" (click)="goToCategoryListingPage()">
</div>