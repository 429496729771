import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { OtherComponentService } from '../other-component.service';

@Component({
  selector: 'app-order-cancellation',
  templateUrl: './order-cancellation.component.html',
  styleUrls: ['./order-cancellation.component.scss'],
})
export class OrderCancellationComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<OrderCancellationComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private _otherComponentService: OtherComponentService,
    private _popUpDbService: PopupDbService
  ) {}

  timeout;
  ngOnInit(): void {
    
    if (this.data.type === "NORMAL_ORDER") {
      this.countDown = this.data?.countDown;
      this.timeout = setInterval(()=> {
        this.countDown--;
        if (this.countDown <= 0) {
          clearTimeout(this.timeout);
          this.dialogRef.close();
        }
      }, 1000)
    }
  }
  ngOnDestroy() {
    clearTimeout(this.timeout);
  }
  // selectedReason: string = null;

  // cancellationReasonList = [
  //   { name: 'Change my Mind', value: 'CHANGE_MY_MIND', status: false },
  //   {
  //     name: 'Want to Order Other',
  //     value: 'WANT_TO_ORDER_OTHER',
  //     status: false,
  //   },
  //   { name: 'Placed by Mistake', value: 'PLACED_BY_MISTAKE', status: false },
  //   { name: 'Other', value: 'OTHER', status: false },
  // ];

  reasonForCancel: string = '';
  loading: boolean = false;
  countDown: number;

  cancelOrderOfUser() {
    this.loading = true;
    if (this.data.type === "NORMAL_ORDER") {
      this._otherComponentService
        .cancelOrderByOrderId(this.data.orderId)
        .subscribe(
          (res: any) => {
            this.loading = false;
            //console.log(res);
            this._popUpDbService.openSnackBarMessage(
              'Successfully cancelled',
              'Ok'
            );
            this.dialogRef.close('success');
          },
          (err) => {
            this.loading = false;
            //console.log(err);
            this._popUpDbService.openSnackBarMessage(err.error.message, 'Close');
            this.dialogRef.close("failure");
          }
        );
    } else {
      this._otherComponentService
        .cancelPreOrderByOrderId(this.data.orderId)
        .subscribe(
          (res: any) => {
            this.loading = false;
            //console.log(res);
            this._popUpDbService.openSnackBarMessage(
              'Successfully cancelled',
              'Ok'
            );
            this.dialogRef.close('success');
          },
          (err) => {
            this.loading = false;
            //console.log(err);
            this._popUpDbService.openSnackBarMessage(err.error.message, 'Close');
            this.dialogRef.close("failure");
          }
        );
    }
  }
  cancelPreOrderOfUser() {
    this.loading = true;
    this._otherComponentService.cancelPreOrderByOrderId(this.data.orderId).subscribe((response)=> {
      this.loading = false;
      this._popUpDbService.openSnackBarMessage('Successfully cancelled', 'Ok');
      this.dialogRef.close('success');
    }, (err)=> {
      this.loading = false;
      //console.log(err);
      this._popUpDbService.openSnackBarMessage(err.error.message, 'Close');
      this.dialogRef.close("failure");
    })
  }

  goToOrderSummaryPage() {
    this.dialogRef.close();
  }
}
