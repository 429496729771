import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../../landing-component.service';
import { HelpdeskService } from '../helpdesk.service';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helpdeskService: HelpdeskService
  ) { }

  helpdeskTicketId: string;
  ticketDetailScreenLoader: boolean = false;
  helpdeskDetails;
  issueImagesList:any = [];
  selectedImageUrl;
  imageType;

  ngOnInit(): void {
    this.helpdeskTicketId = this.helpdeskService.getHelpdeskTicketId();
    this.getHelpdeskTicketDetails();

    this.activatedRoute.params.subscribe((params)=> {
      this.imageType = params.image_type;
      if (this.imageType === 'resolved-image') {
        this.imageType = "RESOLVED_IMAGE";
      } else if (this.imageType === 'issue-image') {
        this.imageType = "ISSUE_IMAGE";
      } else {
        this.router.navigate(['/profile/user/view-ticket']);
      }
    })
  }

  getHelpdeskTicketDetails() {
    this.ticketDetailScreenLoader = true;
    this.helpdeskService.getHelpDeskDetails(this.helpdeskTicketId).subscribe((response: any)=> {
      this.helpdeskDetails = response.data;
      if (this.imageType === 'RESOLVED_IMAGE') {
        this.helpdeskDetails.helpdeskDetails?.helpdeskIssueResolvedImages?.forEach((image, index)=> {
          this.issueImagesList.push({
            id: index,
            image: image,
            status: false
          });
        });
      } else if (this.imageType === 'ISSUE_IMAGE') {
        this.helpdeskDetails.helpdeskDetails?.helpdeskIssueImages?.forEach((image, index)=> {
          this.issueImagesList.push({
            id: index,
            image: image,
            status: false
          });
        });
      }

      this.selectedImageUrl = this.issueImagesList[0].image;
      this.issueImagesList[0].status = true;

      this.ticketDetailScreenLoader = false;
    }, (err)=> {
      this.ticketDetailScreenLoader = false;
    })
  }
  
  selectImage(imageDetails) {
    this.issueImagesList.forEach((image)=> {
      if (image.id === imageDetails.id) {
        image.status = true;
        this.selectedImageUrl = image.image;
      } else {
        image.status = false;
      }
    }) 
  }

  goToTicketView() {
    this.router.navigate(['profile/user/view-ticket']);
  }
}
