<section class="total-section">
  <div class="header_total-section">
    <div>
      <img src="../../../../../../../assets/images/common/back_arrow.svg" alt="Back" (click)="goToUserProfile()">
    </div>
    <h1>Settings</h1>
  </div>

  <main class="main-section">
    <ng-container *ngIf="!showLoader; else loaderScreen">
      <div class="card-section">
        <div class="card-left">
          <h2 class="card-title">Promos and Offers</h2>
          <p class="card-sub-title">Recieve updates about coupons, promotions and money saving offers</p>
        </div>
        <div class="card-right">
          <div class="toggle-btn">
            <mat-slide-toggle color="warn" [(ngModel)]="settingsData.promoAlerts"
              (change)="updatePermissionForNotification(permissionToggle)" #permissionToggle></mat-slide-toggle>
          </div>
        </div>
      </div>
    </ng-container>
  </main>
</section>

<ng-template #loaderScreen>
  <div class="loader-img-container">
    <img src="../../../../../../../assets/images/common/burger_loader.gif" alt="" />
  </div>
</ng-template>
