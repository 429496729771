<!-- <ng-container *ngIf="!loadingRouteConfig">
  <router-outlet></router-outlet>
</ng-container>
<ng-container align="center" *ngIf="loadingRouteConfig">
  <div class="img-main-loader">
    <img src="./../assets/images/common/istharaloader.gif" alt="lodaer" />
  </div>
</ng-container> -->

<router-outlet></router-outlet>
