<section>
  <div class="main-container" *ngIf="!siteErrorOccured">
    <ng-container *ngIf="!showLoader; else showLoadingScreen">
      <div class="header-section">
        <div class="left-section">
          <div class="img-container" (click)="navigateToHome()" *ngIf="!inDiscountFlow">
            <img *ngIf="!foodCourtDetails?.siteImageUrl" src="../../../../assets/images/dummy/dummy_img.png" alt="">
            <img *ngIf="foodCourtDetails?.siteImageUrl" [src]="foodCourtDetails?.siteImageUrl" alt="">
          </div>
          <div class="img-container__back" *ngIf="inDiscountFlow" (click)="goToHomePage()">
            <img src="../../../../assets/images/common/back_arrow.svg" alt="">
          </div>
          <div class="text-content" (click)="showBottomSheet()">
            <div class="site-name">
              <span *ngIf="foodCourtDetails?.siteName?.length < 38; else trimedSiteName">{{ foodCourtDetails?.siteName |
                titlecase }}</span>
              <ng-template #trimedSiteName>
                <span>{{ foodCourtDetails?.siteName.substring(0, 35) | titlecase }}...</span>
              </ng-template>
            </div>
            <div class="foodcourt-name">
              <div class="name">
                <span *ngIf="foodCourtDetails?.foodCourtName?.length < 38; else trimedFCName">{{
                  foodCourtDetails?.foodCourtName | titlecase }}</span>
                <ng-template #trimedFCName>
                  <span>{{ foodCourtDetails?.foodCourtName.substring(0, 35) | titlecase }}...</span>
                </ng-template>
              </div>
              <img src="../../../../assets/images/header/down_arrow.svg" alt="">
            </div>
          </div>
        </div>
        <div class="right-section" *ngIf="scrWidth < 600 && userId">
          <div class="notification-icon">
            <img src="../../../../assets/images/common/bell-icon.svg" alt="Notification"
              (click)="goToNotificationPage()">
            <span *ngIf="unseenNotificationCount > 0">{{ unseenNotificationCount }}</span>
          </div>
        </div>
        <div class="right-section" *ngIf="scrWidth > 600">
          <ng-container *ngFor="let icon of headerList">
            <div class="cart-container header-icon" (click)="navigatePage(icon)">
              <img *ngIf="!icon.status" src="../../../../assets/images/footer/{{ icon.name }}.svg" alt="">
              <img *ngIf="icon.status" src="../../../../assets/images/footer/{{ icon.name }}_active.svg" alt="">
              <span [ngClass]="{'active-tab_large-screen': icon.status}">{{ icon.name | titlecase }}</span>
            </div>
          </ng-container>
          <!-- <div class="cart-container header-icon" (click)="navigateToHome()">
                    <img *ngIf="activeTab !== 'HOME'" src="../../../../assets/images/footer/home.svg" alt="">
                    <img *ngIf="activeTab === 'HOME'" src="../../../../assets/images/footer/home_active.svg" alt="">
                    <span [ngClass]="{'active-tab_large-screen': activeTab === 'HOME'}">Home</span>
                </div>
                <div class="search-container header-icon" (click)="navigateToSearch()">
                    <img *ngIf="activeTab !== 'SEARCH'" src="../../../../assets/images/footer/search.svg" alt="">
                    <img *ngIf="activeTab === 'SEARCH'" src="../../../../assets/images/footer/search_active.svg" alt="">
                    <span [ngClass]="{'active-tab_large-screen': activeTab === 'SEARCH'}">Search</span>
                </div> -->
          <!-- <div class="cart-container header-icon">
                        <img src="../../../../assets/images/footer/cart.svg" alt="">
                        <span>Cart</span>
                </div> -->
          <!-- <div class="account-container header-icon" (click)="goToAccountPage()">
                        <img *ngIf="activeTab !== 'ACCOUNT'" src="../../../../assets/images/footer/profile.svg" alt="">
                        <img *ngIf="activeTab === 'ACCOUNT'" src="../../../../assets/images/footer/profile_active.svg" alt="">
                        <span [ngClass]="{'active-tab_large-screen': activeTab === 'ACCOUNT'}">Account</span>
                    </div>  -->
        </div>
      </div>

      <ng-container *ngIf="!isAPIError">
        <div class="main-section">
          <div class="left-section">
            <router-outlet></router-outlet>
          </div>
          <div class="right-section" *ngIf="scrWidth > 600" [ngStyle]="{
                        visibility:
                        router.url.includes('order-summary')
                           ? 'hidden'
                            : 'visible'
                      }">
            <ng-container *ngIf="sidePanelType === 'CART'">
              <app-cart></app-cart>
            </ng-container>
            <ng-container *ngIf="sidePanelType === 'COUPON'">
              <app-coupens></app-coupens>
            </ng-container>
            <ng-container *ngIf="sidePanelType==='PRE_ORDER'">
              <app-food-preorder></app-food-preorder>
            </ng-container>

          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isAPIError">
        <app-apierror-handling></app-apierror-handling>
      </ng-container>

      <ng-container *ngIf="scrWidth < 600">
        <app-footer></app-footer>
      </ng-container>
    </ng-container>
    <ng-template #showLoadingScreen>
      <div class="loader-img-container">
        <img src="../../../../assets/images/common/burger_loader.gif" alt="">
      </div>
    </ng-template>
  </div>
  <div class="error-container" *ngIf="siteErrorOccured">
    <h2 class="heading-text">Error</h2>
    <div class="primary-text">Some error occured while finding the Sites</div>
    <div class="secondary-text">We are looking into it</div>
    <button class="try-again" (click)="reloadPage()">Try Again</button>
  </div>
</section>
