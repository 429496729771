import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root',
})
export class PopupDbService {
  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) {}
  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });

  private sitelist:any[]=[];

  openSnackBarMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  setUserId(body: any) {
    this._lsService.set('userId', body);
  }
  getUserId() {
    const userId = this._lsService.get('userId');
    if (userId) {
      return userId;
    }
    return null;
  }

  setUserMobile(body: any) {
    this._lsService.set('userMobile', body);
  }
  getUserMobile() {
    const userMobile = this._lsService.get('userMobile');
    if (userMobile) {
      return userMobile;
    }
    return null;
  }

  setUserEmail(body: any) {
    this._lsService.set('userEmail', body);
  }
  getUserEmail() {
    const userEmail = this._lsService.get('userEmail');
    if (userEmail) {
      return userEmail;
    }
    return null;
  }

  
  setUserDeviceId(body: any) {
    this._lsService.set('userDeviceId', body);
  }
  getUserDeviceId() {
    const userDeviceId = this._lsService.get('userDeviceId');
    if (userDeviceId) {
      return userDeviceId;
    }
    return null;
  }

  //set token
  setToken(body: any) {
    localStorage.setItem('token', JSON.stringify(body));
  }

  // * Get user opted Filter information
  getToken() {
    // const token = "token";
    // return token;
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      return token;
    }
    return null;
  }
  //set userName
  setUserName(body: any) {
    this._lsService.set('userName', body);
  }

  getUserName() {
    const UserName = this._lsService.get('userName');
    if (UserName) {
      return UserName;
    }
    return null;
  }

// Set RequestId
setRequestId(body: any) {
  this._lsService.set('requestId', body);
}

// Get RequestId
getRequestId() {
  const requestId = this._lsService.get('requestId');
  if (requestId) {
    return requestId;
  }
  return null;
}

  // Set OrderId
  setOrderId(body: any) {
    this._lsService.set('orderId', body);
  }

  // Get OrderId
  getOrderId() {
    const orderId = this._lsService.get('orderId');
    if (orderId) {
      return orderId;
    }
    return null;
  }

  setHasUserBTCAccessInCurrFC(body: any) {
    this._lsService.set('hasBTCAccessInCurrFC', body);
  }
  getHasUserBTCAccessInCurrFC() {
    const hasBTCAccessInCurrFC = this._lsService.get('hasBTCAccessInCurrFC');
    if (hasBTCAccessInCurrFC) {
      return hasBTCAccessInCurrFC;
    }
    return null;
  }

  // set paymentFor

  // Body value as given below -

  // For CartPayment : 'CART_PAYMENT'
  // For DeliveryCharges : 'DELIVERY_PAYMENT'
  // For One-click payment : 'ONE_CLICK_PAYMENT'

  setPaymentFor(body: any) {
    this._lsService.set('paymentFor', body);
  }

  // Get paymentFor
  getPaymentFor() {
    const paymentFor = this._lsService.get('paymentFor');
    if (paymentFor) {
      return paymentFor;
    }
    return null;
  }

  // Set AddressId
  setAddressId(body: any) {
    this._lsService.set('addressId', body);
  }

  // Get AddressId
  getAddressId() {
    const addressId = this._lsService.get('addressId');
    if (addressId) {
      return addressId;
    }
    return null;
  }

  removeFromLocalStorage(body: any) {
    this._lsService.remove(body);
  }

  storesiteList(siteDetails: any[]) {
    this.sitelist=siteDetails;
  }

  getsiteList() {
    return this.sitelist;
  }
}
