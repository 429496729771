import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalCartServiceService } from '../../../cart-services/global-cart-service.service';
import { IndexDBService } from '../../../indexDB-storage/index-db.service';
import { LandingComponentService } from '../../../landing-component.service';

@Component({
  selector: 'app-preorder-confirmation-popup',
  templateUrl: './preorder-confirmation-popup.component.html',
  styleUrls: ['./preorder-confirmation-popup.component.scss']
})
export class PreorderConfirmationPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PreorderConfirmationPopupComponent>,
    private landingComponentService: LandingComponentService,
    private storageService: IndexDBService,
    private globalCartService: GlobalCartServiceService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
  }

  proceedToChangeMeal() {
    this.dialogRef.close("success");
  }
  closePopup() {
    this.dialogRef.close();
  }

  proceedToClearCart() {
    let userId = this.landingComponentService.getUserId();
    if (userId) {
      this.landingComponentService.clearCartDataAPI(userId).subscribe(
        (response: any) => {
          this.storageService.clearAllItemsFromCart();
          
          this.landingComponentService.setIsOrderPreOrderType(null);
          this.landingComponentService.setPreOrderDetails(null);
          this.landingComponentService.setPreOrderMenuType(null);
          this.landingComponentService.setOrderFoodCourtId(null);
          this.landingComponentService.setOrderFoodCourtName(null);
          this.landingComponentService.itemQuantityChange.next(true);

          this.globalCartService.getAllCartData().then(()=> {
            this.dialogRef.close({ isSuccess: true });
          })
        },
        (err) => {
          //console.log(err);
          this.dialogRef.close({ isSuccess: false });
        }
      );
    } else {
      this.storageService
        .clearAllItemsFromCart()
        .then((response) => {
          
          this.landingComponentService.setIsOrderPreOrderType(null);
          this.landingComponentService.setPreOrderDetails(null);
          this.landingComponentService.setPreOrderMenuType(null);
          this.landingComponentService.setOrderFoodCourtId(null);
          this.landingComponentService.setOrderFoodCourtName(null);
          
          this.landingComponentService.itemQuantityChange.next(true);

          this.globalCartService.getAllCartData().then(()=> {
            
            this.dialogRef.close({ isSuccess: true });
          })
        })
        .catch((err) => {
          this.dialogRef.close({ isSuccess: false });
        });
    }
  }
}
