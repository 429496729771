import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingComponentService } from '../../../landing-component.service';

@Component({
  selector: 'app-url-not-found-page',
  templateUrl: './url-not-found-page.component.html',
  styleUrls: ['./url-not-found-page.component.scss']
})
export class UrlNotFoundPageComponent implements OnInit {

  constructor(
    private router: Router,
    private landingComponentService: LandingComponentService
  ) { }

  isOffline: boolean = false;
  showErrorPage: boolean = false;
  ngOnInit(): void {
    
    if (this.router.url.includes("error"))
      this.showErrorPage = true;
    else {
      if (!window.navigator.onLine) {
        this.isOffline = true;
      }
    }
  }

  goToHome() {
    
    let foodCourtName = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
    this.router.navigate([`${foodCourtName}`]);
  }
}
