import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import SecureLS from 'secure-ls';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OtherComponentService {
  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });
  constructor(private http: HttpClient) {}

  // getEncryptedOrderId(orderId) {
  //   this._lsService.set('encryptedOrderId', orderId);
  //   const encryptedOrderId = localStorage.getItem('encryptedOrderId');
  //   return encryptedOrderId;
  // }
  // getDecryptedOrderId(encryptedOrderId) {
  //   localStorage.setItem('encryptedOrderId', encryptedOrderId);
  //   const orderId = this._lsService.get('encryptedOrderId');
  //   return orderId;
  // }

  getUserId() {
    const userId = this._lsService.get('userId');
    if (userId) {
      return userId;
    }
    return null;
  }

  clearCartDataAPI(userId) {
    return this.http.delete(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }

  cancelOrderByOrderId(orderId: string) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/ist/order/cancel?orderId=${orderId}`,
      null
    );
  }

  cancelPreOrderByOrderId(orderId: string) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/ist/order/preorder/cancel/${orderId}`, {}
    )
  }

  getUserRewardPoints(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  payForOneClickOrderWithPoints(data, isPointsPayment) {
    return this.http.post(
      environment.baseUrl +
        `/foodcourt/ist/order/create/?isPointsPayment=${isPointsPayment}`,
      data
    );
  }

  payForOneClickOrderWithCashfree(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/employee/token/web`,
      data
    );
  }



  getOrderPaymentStatusForCartPayment(orderId: string) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/payment/ist/order/status?orderId=${orderId}`
    );
  }

  getOrderPaymentStatusForDeliveryPayment(
    orderId: string,
    // orderToken: string,
    fcOrderId: string,
    deliveryAddress,
    orderType,
    pointsToBeDeducted
  ) {
    return this.http.post(
      environment.baseUrl +
        `/foodcourt/payment/ist/delivery-order/status?fcOrderId=${fcOrderId}&orderId=${orderId}&orderType=${orderType}&pointsToBeDeducted=${pointsToBeDeducted}`,
      deliveryAddress
    );
  }

  getOrderPaymentStatusForOneClick(
    orderId: string
    // orderToken: string
    ) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/payment/ist/order/status/one-click?orderId=${orderId}`
    );
  }

  getWalletPaymentStatus(orderId: string) {
    return this.http.post( environment.baseUrl + `/foodcourt/payment/ist/wallet/payment/status?orderId=${orderId}`, {} )
  }

  getOrderReviewByOrderId(orderId: string) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/ratingReview/ist/getbyorderId/${orderId}`
    );
  }

  logoutUser(userId, deviceInfo) {
    return this.http.put(
      environment.baseUrl + `/user/user/${userId}/logout`,
      deviceInfo
    );
  }

  getCouponDetails(couponId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/discount/ist/v1/${couponId}`
    );
  }

  getSavedAddressesOfUser(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    );
  }

  filterUserIstharaPoints(data) {
    return this.http.put(environment.baseUrl + `/foodcourt/ist/points/filter`, data);
  }
  
  getOrderDetailsById(orderId) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/order/getOrder/${orderId}`);
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }

  getUserKOTDetails(planId:string, date: Date) {
    return this.http.put(environment.baseUrl + `strDate=${date}&userPlanId=${planId}`, {})
  }

  checkUserFoodSubscriptionPaymentStatus(data) {
    return this.http.post(environment.baseUrl + `/foodcourt/subscription/ist/payment/status`, data)
  }


  getUserAvailableMeals(payload){
    
    return this.http.put(environment.baseUrl + `/foodcourt/subscription/ist/plan/available/meals?strDate=${payload.strDate}&userPlanId=${payload.planId}`,{})
  }
} 
