import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysLeft'
})
export class DaysLeftPipe implements PipeTransform {

  transform(date: Date): string {
    
    let todayDate = new Date();
    let daysLeft = Math.round((new Date(date).getTime() - todayDate.getTime()) / (1000 * 3600 * 24)) +1;

    if (daysLeft > 0)
      return daysLeft + " days left";
    else 
      return "Expired";
  }

}
