import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}

  openSnackBarMessage(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 2 * 1000,
    });
  }

  getCouponsByUserId(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/discount/ist/v1/cart?userId=${userId}`
    );
  }

  applyCouponToUser(userId, discountId) {
    return this.http.put(
      environment.baseUrl +
        `/foodcourt/discount/ist/v1/${userId}/apply/${discountId}`,
      {}
    );
  }

  searchCouponsForUser(foodCourtId, couponName, pageNo, size) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt//discount/ist/v1/search/${foodCourtId}?name=${couponName}&pageNo=${pageNo}&size=${size}`
    );
  }
}
