<section class="rating-details-section" *ngIf="!showLoader; else burgerLoader">
  <div class="close-img">
    <img
      src="../../../../../../../assets/images/common/close.svg"
      alt="Close"
      (click)="closePopup()"
    />
  </div>

  <ng-container *ngIf="data.for === 'OVERALL_RATING'">
    <div class="rating-details-heading">
      Overall Rating (<span class="overall-rating">
        <img
          src="../../../../../../../assets/images/home-page/star.svg"
          alt="Star Rating"
        />
        <span>{{ ratingDetails.orderRating | number: "1.1-1" }}</span></span
      >)
    </div>
    <div class="overall-rating-reason">
      <div class="reason-heading">You liked the most</div>
      <div class="rating-reason">
        <span *ngFor="let reason of ratingDetails.reason">{{ reason }}</span>
      </div>
    </div>

    <div class="vendor-wise-rating-details">
      <ng-container *ngFor="let vendorReviews of ratingDetails.vendorReviews">
        <div class="vendor-wise-rating-container">
          <div class="vendor-details">
            <div class="vendor-img">
              <img
                src="../../../../../../../assets/images/common/default_food_image.svg"
                alt=""
              />
            </div>
            <div class="vendor-name-and-rating">
              <div class="vendor-name">{{ vendorReviews.vendorName }}</div>
              <div class="vendor-rating">
                <img
                  src="../../../../../../../assets/images/home-page/star.svg"
                  alt="Star Rating"
                />
                <span>{{ vendorReviews.ratingCount | number: "1.1-1" }}</span>
              </div>
            </div>
          </div>
          <div class="vendor-rating-reason">
            <span *ngFor="let reason of vendorReviews.reason">{{
              reason
            }}</span>
          </div>
          <div
            class="item-wise-rating-details"
            *ngFor="let itemReviews of vendorReviews.itemWiseRatingList"
          >
            <div class="item-name">
              <span
                *ngIf="itemReviews.itemName.length < 25; else showTrimmedNameQB"
                >{{ itemReviews.itemName | titlecase }}</span
              >
              <ng-template #showTrimmedNameQB>
                <span
                  >{{
                    itemReviews.itemName.substring(0, 24) | titlecase
                  }}..</span
                >
              </ng-template>
            </div>
            <div class="item-rating">
              <img
                src="../../../../../../../assets/images/home-page/star.svg"
                alt="Star Rating"
              />
              <span> {{ itemReviews.rating | number: "1.1-1" }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data.for === 'DELIVERY_RATING'">
    <div class="rating-details-heading">Rate Delivery Experience</div>
    <p class="rating-text">
      How would you rate our delivery partner, your rating will help our staff
      to improve better.
    </p>
    <div class="delivery-boy-img">
      <img
        src="../../../../../../../assets/images/ratings/delivery_boy.svg"
        alt="Delivery Boy"
      />
    </div>
    <div class="rating-stars">
      <div class="rating-stars-img">
        <ng-container *ngFor="let starNo of [1, 2, 3, 4, 5]">
          <img
            src="../../../../../../../assets/images/ratings/rating-star-empty.svg"
            alt="Stars"
            *ngIf="starNo > data.deliverBoyDetails.rating"
            (click)="giveStarRating(starNo)"
          />
          <img
            src="../../../../../../../assets/images/ratings/rating-star-fill.svg"
            alt="Stars"
            *ngIf="starNo <= data.deliverBoyDetails.rating"
            (click)="giveStarRating(starNo)"
          />
        </ng-container>
      </div>
    </div>

    <div class="review-block">
      <textarea
        [(ngModel)]="data.deliverBoyDetails.review"
        name=""
        id=""
        placeholder="Add review for delivery"
      ></textarea>
    </div>

    <div class="submit-btn">
      <button (click)="submitDeliveryBoyRating()">Submit</button>
    </div>
  </ng-container>
</section>
<ng-template #burgerLoader>
  <div class="loader-img-container">
    <img
      src="../../../../../../../assets/images/common/burger_loader.gif"
      alt="Loader"
    />
  </div>
</ng-template>
