<div class="section">
  <div class="header">
    <div class="header-menu">
      <!-- <div class="back-btn"><img src="./../../../../../../assets/images/common/back_arrow.svg" (click)="back()" />
            </div> -->
      <div class="header-left">
        <div class="main-path">Add Location</div>
      </div>
      <div class="close-button">
        <img
          src="../../../../../../../assets/images/common/close.svg"
          alt=""
          (click)="closePopUp()"
        />
      </div>
    </div>
  </div>
  <div class="main-section">
    <div class="form-container">
      <div class="field">
        <div class="lead-heading">Name<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
          <input
            matInput
            placeholder="Name"
            type="text"
            [(ngModel)]="customerName"
          />
        </mat-form-field>
      </div>
      <div class="field">
        <div class="lead-heading">Mobile Number<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
          <input
            matInput
            placeholder="Please enter your mobile no"
            type="number"
            [(ngModel)]="customerMobile"
          />
        </mat-form-field>
      </div>
      <div class="field">
        <div class="lead-heading">Address<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
          <input
            matInput
            placeholder="Please enter your address"
            type="text"
            [(ngModel)]="locationDetail.addressLine"
            #addresstext
          />
        </mat-form-field>
      </div>
      <div class="address-location">
        <div class="fields">
          <div class="lead-headings">Latitude<sup>*</sup></div>
          <mat-form-field class="sfull-width" appearance="outline">
            <input
              matInput
              placeholder="Latitude"
              type="text"
              [(ngModel)]="locationDetail.geoLocation.latitude"
              readonly
            />
          </mat-form-field>
        </div>
        <div class="fields">
          <div class="lead-headings">Longitude<sup>*</sup></div>
          <mat-form-field class="sfull-width" appearance="outline">
            <input
              matInput
              placeholder="Longitude"
              type="text"
              [(ngModel)]="locationDetail.geoLocation.longitude"
              readonly
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="user-chips">
      <div class="user-chips-heading">Save As<sup>*</sup></div>
      <div class="property-chips">
        <ng-container *ngFor="let title of addressTitles">
          <div
            [ngStyle]="{
              'background-color': title.active ? 'orangered' : '#c4c4c4'
            }"
            (click)="selectAddressTitle(title.name)"
          >
            {{ title.name }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="full-width">
      <agm-map
        [latitude]="locationDetail.geoLocation.latitude"
        [longitude]="locationDetail.geoLocation.longitude"
        [zoom]="zoom"
        [disableDefaultUI]="true"
        [usePanning]="true"
        (mapReady)="onMapReady($event)"
        #AgmMap
      >
        <agm-marker
          [latitude]="locationDetail.geoLocation.latitude"
          [longitude]="locationDetail.geoLocation.longitude"
          [label]="locationDetail.geoLocation.label"
          [markerDraggable]="locationDetail.geoLocation.draggable"
          [iconUrl]="icon"
          (dragEnd)="markerDragEnd($event)"
        >
        </agm-marker>
      </agm-map>
    </div>
  </div>
  <div class="total-box">
    <button
      *ngIf="!loading; else loader"
      [disabled]="
        !customerName ||
        !customerMobile ||
        !locationDetail.addressLine ||
        !locationDetail.geoLocation.latitude ||
        !locationDetail.geoLocation.longitude ||
        !locationDetail.geoLocation.title
      "
      (click)="addNewAddress()"
    >
      Confirm Location
    </button>
    <ng-template #loader>
      <button><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</button>
    </ng-template>
  </div>
</div>
