import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDbService } from '../../../../authentication/services/popup-db.service';
import { ConfirmationPopupComponent } from '../../other-components/confirmation-popup/confirmation-popup.component';
import { DisplayQrCodeComponent } from '../../other-components/display-qr-code/display-qr-code.component';
import { OrderCancellationComponent } from '../../other-components/order-cancellation/order-cancellation.component';
import { ListUserAddressComponent } from '../list-user-address/list-user-address.component';
import { OrderSummaryService } from './order-summary.service';
import { environment } from '../../../../../../../environments/environment';
import { NotificationService } from '../../../../authentication/services/notification.service';
import * as AWS from 'aws-sdk';
import { LandingComponentService } from '../../../landing-component.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";

import { HelpComponent } from '../../other-components/help/help.component';
import { UserAccountService } from '../user-account.service';
@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _orderSummaryService: OrderSummaryService,
    private _popUpDbService: PopupDbService,
    private landingComponentService: LandingComponentService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private userService: UserAccountService,
  ) {
    this.path = this.router?.getCurrentNavigation()?.extras?.state?.fromPage;
  }

  scrHeight: number;
  scrWidth: number;
  interval;
  isGuestOrder;
  requestId: string;
  showInvoiceDownload: boolean = false;
  path: string;
  pathParam;

  btcRemindApprovalBtnLoader: boolean = false;
  btcCancelRequestBtnLoader: boolean = false;
  btcEditRequestBtnLoader: boolean = false;

  isOrderDelivered: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit(): void {

    this.landingComponentService.setBTCRequestId(null);

    this.orderId = this._popUpDbService.getOrderId();
    this.requestId = this._popUpDbService.getRequestId();

    if (this.orderId === null && this.requestId === null) {
      this.router.navigate(['profile/user/order-history']);
    }
    if (this.orderId && this.pathParam === undefined) {
      this.router.navigate(['profile/user/order-summary/self-order']);
    }

    // check the path
    this.activatedRoute.params.subscribe((param) => {
      this.pathParam = param.summary_type;
    })

    // get orderId inorder to get Order Summary By Id
    this.getScreenSize();

    if (this.pathParam === "self-order") {

      this.getOrderSummaryOfOrder();
    } else if (this.pathParam === "pre-order") {

      this.getPreOrderSummaryOfOrder();
    } else if (this.pathParam === "guest-request") {

      this.getRequestSummaryOfRequest();
    } else if (this.pathParam === "guest-order") {

      this.getOrderSummaryOfOrder();
    } else if (this.pathParam === "corporate-order") {

      this.getCorporateOrderSummary();
    }


    // if (this.orderId) {
    //   if (this.pathParam === "self-order") {
    //     this.getOrderSummaryOfOrder();
    //   } else if (this.pathParam === "pre-order") {
    //     this.getPreOrderSummaryOfOrder();
    //   }
    // } else {

    //   // If OrderId is not present check for requestId for guest Ordering
    //   if (this.requestId) {
    //     this.getRequestSummaryOfRequest();
    //   }
    //   else {
    //     // If requestId or OrderId is not present go to previous page i.e. listing page
    //     this.goToPreviousPage();
    //   }
    // }

    this.userId = this._popUpDbService.getUserId();

    this.notificationService.currentMessage.subscribe((data) => {
      //console.log('Notification working');
      if (data) {
        this.refreshPage();
      }
    });
  }
  ngOnDestroy() {
    clearTimeout(this.interval);
  }

  orderType: string = 'PICK_UP';
  DELIVERY_OPTIONS = [
    { name: 'Self Pickup', value: 'PICK_UP' },
    { name: 'Dine In', value: 'DINE_IN' }
  ];

  getCorporateOrderSummary() {
    this.showLoader = true;
    this._orderSummaryService.getBTCOrderSummary(this.requestId).subscribe((response: any) => {
      this.showLoader = false;

      this.requestDetails = response.data;

      // Cluster Items Vendor Wise
      // this.requestDetails.orderedItemsList.forEach((orderItem)=> {
      //   if (!this.vendorDetailsForGuestOrdering[orderItem.vendorName])
      //     this.vendorDetailsForGuestOrdering[orderItem.vendorName] = [];

      //   this.vendorDetailsForGuestOrdering[orderItem.vendorName].push(orderItem);
      // })

      // Get Vendor List
      this.vendorsList = Object.keys(this.vendorDetailsForGuestOrdering);


    }, (err) => {

    })
  }

  getPreOrderSummaryOfOrder() {
    this.showLoader = true;
    this._orderSummaryService.getPreOrderDetailsByOrderId(this.orderId).subscribe((response: any) => {
      this.showLoader = false;
      this.orderDetails = response.data;
      this.orderType = response?.data.orderType;

      this.orderDetails.paidAmount = 0;
      for (let points of Object.keys(this.orderDetails.paymentDetails)) {
        // if (points === 'Delivery Charges') {  // Special Condition for Delivery Charges because it always comes in paisa not in ruppes
        //   // this.orderDetails.paidAmount += Number(
        //   //   this.orderDetails.paymentDetails[points] / 100
        //   // );
        //   continue;
        // }
        this.orderDetails.paidAmount += Number(
          this.orderDetails.paymentDetails[points]
        );
      }

      if (this.orderDetails.preOrderStartTime) {
        let hour = this.orderDetails.preOrderStartTime.split(":")[0];
        let minute = this.orderDetails.preOrderStartTime.split(":")[1];
        this.orderDetails.preOrderStartTime = new Date(new Date(this.orderDetails?.preOrderDate).setHours(hour, minute, 0, 0));
      }
      if (this.orderDetails.preOrderEndTime) {
        let hour = this.orderDetails.preOrderEndTime.split(":")[0];
        let minute = this.orderDetails.preOrderEndTime.split(":")[1];
        this.orderDetails.preOrderEndTime = (new Date(this.orderDetails?.preOrderDate).setHours(hour, minute, 0, 0));
      }

      // need to match date
      // let currentDate = new Date();
      // let currentTime = new Date().toLocaleTimeString();

      // let preOrderDateStartTime = new Date(this.orderDetails.preOrderDate).setHours();

      // if (this.orderDetails.preOrderStartTime.getTime() > (currentDate.getTime() + 4*60*60*1000)) {
      //   this.showCancelButton = true;
      // }
      // else {
      //   this.showCancelButton = false;
      // }

      // ------------------------- METHOD 1 to disable Cancel Button for 4 hours ---------------
      // if (this.orderDetails.vendorOrderDetails.every((element)=> element.orderStatus === 'CANCELLED' || element.orderStatus === 'NEW')) {
      //   this.showInvoiceDownload = false;
      // } else {
      //     this.showInvoiceDownload = true;
      // }

      // do not show cancel button if item is cancelled
      if (this.orderDetails.vendorOrderDetails.every((element) => element.preOrderStatus === 'PENDING' || element.preOrderStatus === 'REJECTED')) {
        this.showCancelButton = true;
      } else {
        this.showCancelButton = false;
      }
      if (this.orderDetails.vendorOrderDetails.every((element) => element.orderStatus === 'CANCELLED' || element.orderStatus === 'OUT_OF_STOCK')) {
        this.showCancelButton = false;
      }

    }, (err) => {

    })
  }

  timeLeftForCancel = 60;
  userId: string = '';
  orderId: string = '';
  orderDetails: any;
  showLoader: boolean = false;
  showDeliverOrderOption: boolean = false;
  showCancelButton: boolean = false;
  deliveryAddress = null;
  userPoints: any = {
    istharaPoints: 0,
  };
  isFirstTime: boolean = true;
  showPointsRefunded: boolean = false;
  ordersummarydetails: any;
  getOrderSummaryOfOrder() {

    this.showLoader = true;
    this.ordersummarydetails = this._orderSummaryService.getOrderDetailByOrderId(this.orderId).subscribe(
      (res: any) => {
        this.showLoader = false;
        //console.log(res);
        this.orderDetails = res.data;

        debugger;
        this.orderType = res.data.orderType;

        this.orderDetails.paidAmount = 0;
        for (let points of Object.keys(this.orderDetails.paymentDetails)) {
          // if (points === 'Delivery Charges') {  // Special Condition for Delivery Charges because it always comes in paisa not in ruppes
          //   // this.orderDetails.paidAmount += Number(
          //   //   this.orderDetails.paymentDetails[points] / 100
          //   // );
          //   continue;
          // }
          this.orderDetails.paidAmount += Number(
            this.orderDetails.paymentDetails[points]
          );
        }

        if (this.orderDetails.preOrderStartTime) {
          let hour = this.orderDetails.preOrderStartTime.split(":")[0];
          let minute = this.orderDetails.preOrderStartTime.split(":")[1];
          this.orderDetails.preOrderStartTime = new Date(new Date(this.orderDetails?.preOrderDate).setHours(hour, minute, 0, 0));
        }
        if (this.orderDetails.preOrderEndTime) {
          let hour = this.orderDetails.preOrderEndTime.split(":")[0];
          let minute = this.orderDetails.preOrderEndTime.split(":")[1];
          this.orderDetails.preOrderEndTime = (new Date(this.orderDetails?.preOrderDate).setHours(hour, minute, 0, 0));
        }

        // If OrderStatus has changed then do not show cancel button
        if (
          this.orderDetails.vendorOrderDetails.every(
            (element) =>
              element.orderStatus === 'NEW' ||
              element.orderStatus === 'PRE_ORDER'
          )
        ) {
          this.showCancelButton = true;
        } else {
          this.showCancelButton = false;
        }
        // Do not show Invoice if OrderStatus is either CANCELLED or NEW
        // if (this.orderDetails.vendorOrderDetails.every((element)=> element.orderStatus === 'CANCELLED' || element.orderStatus === 'NEW')) {
        //   this.showInvoiceDownload = false;
        // } else {
        //     this.showInvoiceDownload = true;
        // }

        this.orderDetails.vendorOrderDetails.forEach((vendor) => {
          if (vendor.items.some((element) => element.itemStatus === 'OUT_OF_STOCK')) {
            this.showPointsRefunded = true;
          }
        })
        if (this.orderDetails.vendorOrderDetails.every((vendor) => (vendor.orderStatus === 'PAST' || vendor.orderStatus === 'CANCELLED'))) {
          this.isOrderDelivered = true;
        }
      },
      (err) => {
        this.showLoader = false;
        this._popUpDbService.openSnackBarMessage(
          'Error while loading order summary',
          'Close'
        );
      },
      () => {

        this.isGuestOrder = this.orderDetails?.oneclickOrderType === "GUEST";
        if (
          this.orderDetails.orderType === 'PICK_UP' ||
          this.orderDetails.orderType === 'DINE_IN'
        ) {
          if (
            this.orderDetails.vendorOrderDetails.every(
              (element) =>
                element.orderStatus === 'NEW' ||
                element.orderStatus === 'PRE_ORDER'
            )
          ) {
            this.getFoodcourtDetails();
          }
          // this.orderDetails.vendorOrderDetails.forEach((element) => {
          //   if (
          //     element.orderStatus === 'NEW' &&
          //     element.orderStatus !== 'PRE_ORDER'
          //   ) {
          //     this.getFoodcourtDetails();
          //   }
          // });
        }

        // Code for 60sec timer for order cancellation
        if (this.isFirstTime && !this.orderDetails.preOrderEndTime) {

          this.timeLeftForCancel = 61 - (((new Date()).getTime() - ((new Date(this.orderDetails.createdTimestamp)).getTime() - 11 * 1.8 * 1000000)) / 1000); // 5.30 Hr diffrence
          if (this.timeLeftForCancel > 0) {
            this.interval = setInterval(() => {
              this.timeLeftForCancel--;
              // console.log(this.timeLeftForCancel);
              if (this.timeLeftForCancel <= 0) {
                clearTimeout(this.interval);
              }
            }, 1000);
          }
          this.isFirstTime = false;
        } else {
          if (this.orderDetails.vendorOrderDetails.every((element) => element.preOrderStatus === 'PENDING' || element.preOrderStatus === 'REJECTED')) {
            this.showCancelButton = true;
          } else {
            this.showCancelButton = false;
          }
        }

      }
    );
  }

  requestDetails;
  vendorDetailsForGuestOrdering = [];
  vendorsList = [];

  // Get Order Request Details
  getRequestSummaryOfRequest() {

    this.vendorDetailsForGuestOrdering = [];
    this.showLoader = true;
    this._orderSummaryService.getRequestDetailByRequestId(this.requestId).subscribe(
      (res: any) => {
        this.showLoader = false;
        // console.log(res);
        this.requestDetails = res.data;

        // Cluster Items Vendor Wise
        this.requestDetails.orderedItemsList.forEach((orderItem) => {
          if (!this.vendorDetailsForGuestOrdering[orderItem.vendorName])
            this.vendorDetailsForGuestOrdering[orderItem.vendorName] = [];

          this.vendorDetailsForGuestOrdering[orderItem.vendorName].push(orderItem);
        })

        // Get Vendor List
        this.vendorsList = Object.keys(this.vendorDetailsForGuestOrdering);
        // console.log(this.vendorDetailsForGuestOrdering);
        // console.log(Object.keys(this.vendorDetailsForGuestOrdering));
        // console.log(typeof({}));
      },
      (err) => {
        this.showLoader = false;
        this._popUpDbService.openSnackBarMessage(
          'Error while loading order summary',
          'Close'
        );
      })
  }

  getFoodcourtDetails() {
    this._orderSummaryService
      .getFoodocurtByFoodcourtId(this.orderDetails.foodCourtId)
      .subscribe((res: any) => {
        if (res.data.hasDelivery) {

          this.showDeliverOrderOption = true;
          this.orderDetails.minOrderAmount = res.data.minOrderAmount;
          this.orderDetails.minDeliveryCharges = res.data.minDeliveryCharges;
          this.orderDetails.deliveryCharges = res.data.deliveryCharges;


          // let deliveryCharges = this.orderDetails.totalPrice > this.orderDetails.minOrderAmount
          //   ? this.orderDetails.deliveryCharges / 100
          //   : this.orderDetails.minDeliveryCharges / 100;

          let deliveryCharges = this.orderDetails.totalPrice > this.orderDetails.minOrderAmount
            ? this.orderDetails.deliveryCharges
            : this.orderDetails.minDeliveryCharges;

          this.orderDetails.payableAmount = (deliveryCharges + deliveryCharges * 0.18);

          if (this.DELIVERY_OPTIONS.length === 2) {
            this.DELIVERY_OPTIONS.push({ name: 'Deliver Order', value: 'DELIVERY' });
          }

        } else {
          this.showDeliverOrderOption = false;
        }
      });
  }

  onAddLocation() {
    const dialogRef = this.dialog.open(ListUserAddressComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: 'no-padding',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
        // Get User Points only when user have entered the Address
        this.getUserPoints();

        //console.log(data);
      }
    });
  }

  showPaymentBtn: boolean = false;
  getUserPoints() {
    this._orderSummaryService.getUserRewardPoints(this.userId).subscribe(
      (data: any) => {
        this.userPoints.istharaPoints = data.data;
        // if user has the isthara points then subtract the points from totalPayableAmount
        if (this.userPoints.istharaPoints) {
          this.orderDetails.payableAmount -= this.userPoints.istharaPoints;
          if (this.orderDetails.payableAmount < 0) {
            this.orderDetails.payableAmount = 0;
          }
          this.pointsToggleChecked = true;
        }
        this.showPaymentBtn = true;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  pointsToggleChecked: boolean = true;
  toggleUserPoints(value) {
    //console.log(this.pointsToggleChecked);
    if (this.pointsToggleChecked) {
      this.orderDetails.payableAmount -= this.userPoints.istharaPoints;
      if (this.orderDetails.payableAmount < 0) {
        this.orderDetails.payableAmount = 0;
      }
    }
    else {
      // let deliveryCharges = this.orderDetails.totalPrice > this.orderDetails.minOrderAmount
      //   ? this.orderDetails.deliveryCharges / 100
      //   : this.orderDetails.minDeliveryCharges / 100;

      let deliveryCharges = this.orderDetails.totalPrice > this.orderDetails.minOrderAmount
        ? this.orderDetails.deliveryCharges
        : this.orderDetails.minDeliveryCharges;

      // this.getPackagingCharges();
      if (this.orderType === 'PICK_UP') {
        // this.orderDetails.payableAmount = this.packagingCharges / 100;
        this.orderDetails.payableAmount = this.packagingCharges;
      } else if (this.orderType === 'DELIVERY' && this.orderDetails.orderType === 'PICK_UP') {
        this.orderDetails.payableAmount = (deliveryCharges + deliveryCharges * 0.18);
      } else {
        // this.orderDetails.payableAmount = (deliveryCharges + deliveryCharges * 0.18) + (this.packagingCharges / 100);
        this.orderDetails.payableAmount = (deliveryCharges + deliveryCharges * 0.18) + (this.packagingCharges);
      }

    }
  }

  packagingCharges: number = 0;
  deliveryCharges: number = 0;
  getPackagingCharges() {
    this._orderSummaryService.getPackagingCharges(this.orderId, this.orderDetails.foodCourtId, this.orderType).subscribe((response: any) => {
      this.packagingCharges = response.data.packagingCharge;
      this.deliveryCharges = response.data.deliveryCharge;
      // this.orderDetails.payableAmount = (response.data.packagingCharge / 100) + ((response.data.deliveryCharge + (response.data.deliveryCharge * 0.18)) / 100);
      this.orderDetails.payableAmount = (response.data.packagingCharge) + ((response.data.deliveryCharge + (response.data.deliveryCharge * 0.18)));
      this.getUserPoints();

    }, (err) => {
      console.log(err);
    })
  }

  remindBTCOrderApproval() {

    this.btcCancelRequestBtnLoader = false;
    this.btcEditRequestBtnLoader = false;
    this.btcRemindApprovalBtnLoader = true;

    this._orderSummaryService.sendReminderBTCOrderRequest(this.requestDetails.employerId, this.requestDetails.requestId).subscribe((response: any) => {
      this._popUpDbService.openSnackBarMessage("Request Sent", "CLOSE");
      this.btcCancelRequestBtnLoader = false;
      this.btcEditRequestBtnLoader = false;
      this.btcRemindApprovalBtnLoader = false;
    }, (err) => {
      this._popUpDbService.openSnackBarMessage("Failed to Sent Request", "CLOSE");
      this.btcCancelRequestBtnLoader = false;
      this.btcEditRequestBtnLoader = false;
      this.btcRemindApprovalBtnLoader = false;
    })
  }
  cancelBTCOrderRequest() {

    this.btcCancelRequestBtnLoader = true;
    this.btcEditRequestBtnLoader = false;
    this.btcRemindApprovalBtnLoader = false;
    this._orderSummaryService.cancelBTCOrderRequest(this.requestDetails.requestId).subscribe((response: any) => {

      this.btcCancelRequestBtnLoader = false;
      this.btcEditRequestBtnLoader = false;
      this.btcRemindApprovalBtnLoader = true;
      this.refreshPage();

    }, (err) => {

      this.landingComponentService.openSnackBarMessage("Failed to Cancel Corporate Order");
      this.btcCancelRequestBtnLoader = false;
      this.btcEditRequestBtnLoader = false;
      this.btcRemindApprovalBtnLoader = false;
    })
  }
  editBTCOrderRequest() {

    this.btcCancelRequestBtnLoader = false;
    this.btcEditRequestBtnLoader = true;
    this.btcRemindApprovalBtnLoader = false;

    this.landingComponentService.getCartData(this.userId).subscribe((response: any) => {

      if (response.data?.itemsList.length > 0) {

        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '380px',
          height: 'auto',
          data: { type: 'BTC_CONFIRMATION_EDIT' },
        });
        dialogRef.afterClosed().subscribe((res) => {

          this.btcCancelRequestBtnLoader = false;
          this.btcEditRequestBtnLoader = false;
          this.btcRemindApprovalBtnLoader = false;
          if (res.isSuccess) {
            this.addRequestItemListToUserCart();
          } else {

          }
        })
      } else {

        this.btcCancelRequestBtnLoader = false;
        this.btcEditRequestBtnLoader = false;
        this.btcRemindApprovalBtnLoader = false;
        this.addRequestItemListToUserCart();
      }
    }, (err) => {

    })
  }

  addRequestItemListToUserCart() {

    this.btcCancelRequestBtnLoader = false;
    this.btcEditRequestBtnLoader = true;
    this.btcRemindApprovalBtnLoader = false;

    let userMobile = this.landingComponentService.getUserMobile();
    let userName = this.landingComponentService.getUserName();

    this.landingComponentService.setUserAddressForBTC(this.requestDetails.address);
    this.landingComponentService.setUserOrderTypeForBTC(this.requestDetails.orderType);

    let data = {
      address: this.requestDetails.address,
      foodCourtId: this.requestDetails.fcId,
      userId: this.userId,
      userName: userName,
      mobile: userMobile,
      platform: "WEB",
      itemsList: {},
      orderType: this.requestDetails.orderType,
      billDetails: this.requestDetails.billDetails,
      mealCategory: null, // need to change
      btcRequestId: this.requestId,
      preOrder: false,
      preOrderDate: null,
      preOrderEndTime: null,
      preOrderStartTime: null
    };
    data.itemsList = this.requestDetails?.orderedItemsList;

    this.landingComponentService.addItemToCartAPI(data).subscribe((response) => {

      this.landingComponentService.setBTCRequestId(this.requestId);

      this.btcCancelRequestBtnLoader = false;
      this.btcEditRequestBtnLoader = false;
      this.btcRemindApprovalBtnLoader = false;


      let foodCourtNameUrl = this.landingComponentService.getFoodCourtName().split(' ').join('-').toLowerCase();
      if (this.scrWidth > 600) {
        this.router.navigate([`${foodCourtNameUrl}`]);
      } else {
        this.router.navigate([`cart/${foodCourtNameUrl}`]);
      }
    }, (err) => {

    })
  }

  changeOrderType() {

    this.showPaymentBtn = false;
    let canChangeToDelivery = true;
    if (this.orderType === 'PICK_UP') {
      this.getPackagingCharges();
    }

    else {
      this._orderSummaryService.getOrderDetailByOrderId(this.orderId).subscribe(
        (res: any) => {

          res.data.vendorOrderDetails.forEach((element) => {
            if (element.orderStatus !== 'NEW') {
              canChangeToDelivery = false;
            }
          });

          if (canChangeToDelivery) {
            if (this.orderType === 'DELIVERY') {
              if (this.deliveryAddress) {
                this.getPackagingCharges();
              } else {
                this._orderSummaryService
                  .getSavedAddressesOfUser(this.userId)
                  .subscribe(
                    (res: any) => {

                      if (res.data && res.data.length > 0) {
                        this.deliveryAddress = res.data[0];
                        // this.deliveryCharges = res.data
                        this.showDeliverOrderOption = true;
                        this.getPackagingCharges();
                      } else {
                        this.deliveryAddress = null;
                      }
                    },
                    (err) => {
                      //console.log(err);
                    }
                  );
              }
            } else {
              // this.showDeliverOrderOption = false;
              this.userPoints.istharaPoints = 0;
              this.deliveryAddress = null;
            }
          } else {
            this._popUpDbService.openSnackBarMessage(
              'Sorry, Order cannot be changed. The order status has been changed',
              'Ok'
            );
            this.getOrderSummaryOfOrder();
          }
        },
        (err) => {
          this._popUpDbService.openSnackBarMessage(
            'Something went wrong, please try again',
            'Ok'
          );
        }
      );
    }
  }

  buttonLoading: boolean = false;
  doPaymentForDelivery() {
    // Show Confirmation Popup for selecting the address
    let ConfirmationPoup = this.dialog.open(ConfirmationPopupComponent, {
      width: '95vw',
      height: 'auto',
      maxWidth: '100vw',
      data: {
        deliveryAddress: this.deliveryAddress,
        type: 'CONFIRM_LOCATION',
      },
    });

    ConfirmationPoup.afterClosed().subscribe((data) => {
      if (data) {
        this.buttonLoading = true;
        if (this.orderDetails.payableAmount === 0)
          this.payDeliveryFeeWithPoints();
        else this.payDeliveryFeeWithCashfree('DELIVERY_CHARGES');
      }
    });
  }
  doPaymentForSelfPickup() {

    if (this.orderDetails.payableAmount === 0)
      this.payPackagingFeeWithPoints();
    else
      this.payDeliveryFeeWithCashfree('PICK_UP');
  }

  payPackagingFeeWithPoints() {
    this.showDeliverOrderOption = false;
    // this.orderDetails.payableAmount -= this.userPoints.istharaPoints;
    // let pointsToBeDeducted = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges))) / 100;
    let pointsToBeDeducted = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges)));

    this._orderSummaryService
      .changePickupToDelivery(this.orderDetails.orderId, {}, "PICK_UP", pointsToBeDeducted)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.buttonLoading = false;
          this.getOrderSummaryOfOrder();
          this._popUpDbService.openSnackBarMessage(
            'Order successfully changed to Self Pickup',
            'Ok'
          );
        },
        (err) => {
          this.buttonLoading = false;
          this._popUpDbService.openSnackBarMessage(
            'Payment failed, please try again',
            'Close'
          );
        }
      );
  }
  payDeliveryFeeWithPoints() {
    this.showDeliverOrderOption = false;

    let pointsToBeDeducted = 0;
    if (this.pointsToggleChecked) {
      // let pointsRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges))) / 100;
      let pointsRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges)));
      if (pointsRequired <= this.userPoints.istharaPoints) {
        pointsToBeDeducted = pointsRequired;
      } else {
        pointsToBeDeducted = this.userPoints.istharaPoints;
      }
    }

    this._orderSummaryService
      .changePickupToDelivery(this.orderDetails.orderId, this.deliveryAddress, "DELIVERY", pointsToBeDeducted)
      .subscribe(
        (res: any) => {
          //console.log(res);
          this.buttonLoading = false;
          this.getOrderSummaryOfOrder();
          this._popUpDbService.openSnackBarMessage(
            'Order successfully changed to Delivery',
            'Ok'
          );
        },
        (err) => {
          this.buttonLoading = false;
          this._popUpDbService.openSnackBarMessage(
            'Payment failed, please try again',
            'Close'
          );
        }
      );
  }

  cashFree = environment.cashFree;
  formDataView: any = {};
  payDeliveryFeeWithCashfree(type) {

    const userName = this._popUpDbService.getUserName();
    const userEmail = this._popUpDbService.getUserEmail();
    const userMobile = this._popUpDbService.getUserMobile();

    let amountToBeDeducted = 0;
    // let amountRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges))) / 100;
    let amountRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges)));
    if (this.pointsToggleChecked) {
      if (this.userPoints.istharaPoints === 0) {
        amountToBeDeducted = amountRequired;
      } else {
        amountToBeDeducted = amountRequired - this.userPoints.istharaPoints;
      }
    } else {
      amountToBeDeducted = amountRequired;
    }

    let data = {
      email: (userEmail ? userEmail : "test@gmail.com"),
      mobile: userMobile,
      orderId: this.orderDetails.orderId,
      paymentFor: type,
      userId: this.userId,
      userName: userName,
      // orderAmount: amountToBeDeducted*100
      orderAmount: amountToBeDeducted * 100
    };

    // this._popUpDbService.openSnackBarMessage(
    //   'pay through isthara points only',
    //   'Close'
    // );
    // this.buttonLoading = false;
    this._orderSummaryService.createPaymentTokenForDelivery(data).subscribe(
      (pay: any) => {

        this.buttonLoading = false;
        //console.log(pay);
        let pointsToBeDeducted = 0;
        if (this.pointsToggleChecked) {
          // let pointsRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges))) / 100;
          let pointsRequired = ((this.packagingCharges + (this.deliveryCharges + 0.18 * this.deliveryCharges)));
          if (pointsRequired <= this.userPoints.istharaPoints) {
            pointsToBeDeducted = pointsRequired;
          } else {
            pointsToBeDeducted = this.userPoints.istharaPoints;
          }
        }

        if (type === "DELIVERY_CHARGES") {
          this._popUpDbService.setPaymentFor('DELIVERY_PAYMENT');
          this.landingComponentService.setPointsToBeDeducted(pointsToBeDeducted);
        }
        else {
          this._popUpDbService.setPaymentFor('SELF_PICKUP_PAYMENT');
          this.landingComponentService.setPointsToBeDeducted(pointsToBeDeducted);
        }

        this._popUpDbService.setOrderId(pay.data.fcOrderId);
        if (type === "DELIVERY_CHARGES")
          this._popUpDbService.setAddressId(this.deliveryAddress.addressId);

        // this.formDataView.paymentLink = pay.data.paymentLink;
        // this.formDataView.orderId = pay.data.orderId;
        // this.formDataView.orderAmount = pay.data.orderAmount / 100;
        // this.formDataView.customerName = pay.data.payeeName;
        // this.formDataView.customerPhone = pay.data.payeeMobile;
        // this.formDataView.customerEmail = pay.data.payeeEmail;
        // this.formDataView.returnUrl = pay.data.returnUrl; //pay.data.returnUrl; //http://localhost:4200/#/amount/${this.paymentObj.bookingId}
        // this.formDataView.notifyUrl = pay.data.notifyUrl;
        // this.formDataView.appId = pay.data.appId;
        // this.formDataView.signature = pay.data.orderSignature; //paymentToken
        // this.formDataView.orderCurrency = pay.data.paymentCurrency;
        // this.formDataView.orderNote = 'Order';

        const paymentSessionId = pay.data.orderSignature;
        let cashFree;
        if (environment.production) {
          cashFree = new cashfreeProd.Cashfree(paymentSessionId);
        } else {
          cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
        }
        cashFree.redirect();

        // //console.log(this.formDataView);

        // setTimeout(() => {
        //   this.buttonLoading = false;
        //   document.forms['redirectForm'].submit();
        // }, 100);
      },
      (err) => {
        this.buttonLoading = false;
        this._popUpDbService.removeFromLocalStorage('paymentFor');

        //console.log(err);
      }
    );
  }

  displayQrCode(vendor) {

    vendor.kot =
      this.orderDetails.orderType === 'DELIVERY'
        ? this.orderDetails.deliveryKot
        : vendor.kot;

    let data = {
      WARNING: 'SCAN IN ISTHARA APP ONLY',
      ERROR_ID: this.orderDetails.orderId,
      ERROR_CODE: vendor.vendorId,
      KOT: String(vendor.kot),
      ORDER_NUM: this.orderDetails.orderNum,
      FOODCOURTID: this.orderDetails.foodCourtId
    };

    this.notificationService.currentMessage.next(null);

    const dialogRef = this.dialog.open(DisplayQrCodeComponent, {
      width: '350px',
      maxWidth: '100vw',
      height: 'auto',
      panelClass: 'no-padding',
      data: { data: data, vendorName: vendor.vendorName, showQR: this.isGuestOrder },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === "copied") {
        this._popUpDbService.openSnackBarMessage("Link Copied", "Close");
      }
    })
  }

  goToPreviousPage() {

    this._popUpDbService.setRequestId(null);
    this._popUpDbService.setOrderId(null);
    this.landingComponentService.setPreOrderDetails(null);
    this.landingComponentService.setPreOrderMenuType(null);
    this.landingComponentService.setIsOrderPreOrderType(null);

    if (this.scrWidth > 600) {
      this.router.navigate(['account/user']);
    } else {
      if (this.path === 'USER_POINTS') {
        this.router.navigate(['profile/user/points']);
      } else {
        this.router.navigate(['profile/user/order-history']);
      }
    }
  }
  cancelOrderPopup(cancelType) {
    const dialogRef = this.dialog.open(OrderCancellationComponent, {
      width: '300px',
      height: 'auto',
      data: { type: cancelType, orderId: this.orderDetails.orderId, countDown: this.timeLeftForCancel },
    });

    dialogRef.afterClosed().subscribe((data) => {
      //console.log(data);
      if (data === 'success') {
        this.refreshPage();
      } else if (data === "failure") {
        this.showCancelButton = false;
      }
    });
  }

  openItemDetailBox(item) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '320px',
      height: 'auto',
      data: { type: 'ITEM_DETAILS', itemDetails: item },
    });
    dialogRef.afterClosed().subscribe((data) => { });
  }

  refreshPage() {

    if (this.showLoader)
      return;

    this.userPoints.istharaPoints = 0;
    this.deliveryAddress = null;
    this.showDeliverOrderOption = false;

    // if (this.requestId)
    //   this.getRequestSummaryOfRequest();
    // else {
    //   if (this.pathParam === 'self-order')
    //     this.getOrderSummaryOfOrder();
    //   else if (this.pathParam === 'pre-order')
    //     this.getPreOrderSummaryOfOrder();
    // }

    if (this.pathParam === "self-order") {

      this.orderId = this._popUpDbService.getOrderId();
      this.getOrderSummaryOfOrder();
    } else if (this.pathParam === "pre-order") {

      this.orderId = this._popUpDbService.getOrderId();
      this.getPreOrderSummaryOfOrder();
    } else if (this.pathParam === "guest-request") {

      this.requestId = this._popUpDbService.getRequestId();
      this.getRequestSummaryOfRequest();
    } else if (this.pathParam === "guest-order") {

      this.orderId = this._popUpDbService.getOrderId();
      this.getOrderSummaryOfOrder();
    } else if (this.pathParam === "corporate-order") {

      this.requestId = this._popUpDbService.getRequestId();
      this.getCorporateOrderSummary();
    }
  }

  invoiceLink: string = "";
  downloadInvoice() {

    this._orderSummaryService.getInvoiceURL(this.orderId).subscribe((response: any) => {
      this.invoiceLink = response.data;
      window.open(this.invoiceLink);
      this.deleteInvoiceReport(this.invoiceLink.split("/")[4]);
    }, (err) => {

    })
  }

  async deleteInvoiceReport(link) {
    // console.log(link);
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    let imgPromise = new Promise((res, rej) => {
      // imageList.forEach((img) => {
      //   let imgname = img.split('/');
      const s3 = new AWSService.S3({
        apiVersion: '2020-01-18',
        params: { Bucket: bucketName },
      });
      let params = {
        Bucket: bucketName,
        Key: `order_invoice/${link}`,
      };
      s3.deleteObject(params, (err, result) => {
        if (err) {
          rej('Unable to delete!');
          //  this.ErrorPopsService.open('Image Not Deleted', 'Close', {
          //    duration: 5000,
          //  });
        } else {
          res('Removed Selected Report!');
          console.log("Invoice Report Deleted Successfully");
          //  this.ErrorPopsService.open(`Removed Selected Image!`, 'Close', {
          //    duration: 5000,
          //  });
        }
      });
      // });
    });

    return imgPromise
      .then((data: any) => {
        return { data: data, res: true };
      })
      .catch((err) => {
        return { data: err, res: false };
      });
  }

  goToHelpDeskCategoryListingPage() {

    this._orderSummaryService.setHelpdeskFoodCourtId(this.orderDetails.foodCourtId);
    this.router.navigate(['profile/user/helpdesk-category']);
  }


  gotohelpinfo(fcId: any, siteid: any) {
    this.dialog.open(HelpComponent, { data: { fcid: fcId, siteid: siteid } });
  }

}
