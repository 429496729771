import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import SecureLS from 'secure-ls';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _lsService: any = new SecureLS({
    encodingType: 'rc4',
    isCompression: false,
  });
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}

  openSnackBarMessage(message: string, action: string = 'CLOSE') {
    this._snackBar.open(message, action, {
      duration: 5 * 1000,
    });
  }

  getUserId() {
    const userId = this._lsService.get('userId');
    if (userId) {
      return userId;
    }
    return null;
  }

  getFoodCourtId() {
    const foodCourtId = this._lsService.get('foodCourtId');
    if (foodCourtId) {
      return foodCourtId;
    }
    return null;
  }
  getUserMobile() {
    const userMobile = this._lsService.get('userMobile');
    if (userMobile) {
      return userMobile;
    }
    return null;
  }
  getUserName() {
    const UserName = this._lsService.get('userName');
    if (UserName) {
      return UserName;
    }
    return null;
  }

  getCartData(userId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }

  addItemToCartAPI(data) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/user/ist/cart`,
      data
    );
  }

  removeItemFromCart(userId, itemId) {
    return this.http.delete(
      environment.baseUrl +
        `/foodcourt/user/ist/cart?itemId=${itemId}&userId=${userId}`
    );
  }

  createOrder(data) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/order`,
      data
    );
  }

  getUserRewardPoints(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  payWithIstharaPoints(userId, note) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/ist/order/create/${userId}`,
      note
    );
  }

  getFoodocurtByFoodcourtId(foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }

  removeCouponFromUserCart(userId) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/discount/ist/v1/${userId}/remove`,
      {}
    );
  }

  updateDeliveryOptionAndAddress(data) {
    return this.http.put(
      environment.baseUrl + '/foodcourt/user/ist/cart/delivery',
      data
    );
  }

  getCouponDetails(couponId) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/discount/ist/v1/${couponId}`
    );
  }

  getSavedAddressesOfUser(userId) {
    return this.http.get(
      environment.baseUrl +
        `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    );
  }

  getAllCustomizationsOfItem(itemId) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/customization/${itemId}/all`);
  }

  changePreOrderItemsTiming(userId, data) {
    return this.http.put(environment.baseUrl + `/foodcourt/ist/order/changePreOrderTime/${userId}`, data)
  }

  sendBTCOrderRequest(data) {
    return this.http.post(environment.baseUrl + `/foodcourt/ist/guestOrder/btc/create`, data)
  }

  updateBTCOrderRequest(data) {
    return this.http.put(environment.baseUrl + `/foodcourt/ist/guestOrder/edit/btc`, data)
  }

}
