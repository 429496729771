import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { HelpdeskService } from '../helpdesk.service';

@Component({
  selector: 'app-rate-service',
  templateUrl: './rate-service.component.html',
  styleUrls: ['./rate-service.component.scss']
})
export class RateServiceComponent implements OnInit, OnDestroy {

  constructor(
    private helpdeskService: HelpdeskService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) { }

  helpDeskTicketId: string;
  helpDeskDetails;
  serviceRating: number = 0;
  showReviewStaffField: boolean = true;
  ratingLoader: boolean = false;
  // staffDetails = [
  //   {
  //     "comment": "",
  //     "rating": 0,
  //     "staffId": ""
  //   }
  // ]

  submitLoader: boolean = false;
  ngOnInit(): void {

    this.helpDeskTicketId = this.data.ticketId;
    this.getTicketDetailsById();
  }
  ngOnDestroy(): void {
      
    this.helpdeskService.setHelpdeskTicketId(null);
  }

  toggleShowReviewStaffField() {
    this.showReviewStaffField = !this.showReviewStaffField;
  }
  getTicketDetailsById() {
    // this.ticketDetailScreenLoader = true;
    this.ratingLoader = true;
    this.helpdeskService.getHelpDeskDetails(this.helpDeskTicketId).subscribe((response: any)=> {
      this.helpDeskDetails = response.data;
      // this.staffDetails[0].staffId = this.helpDeskDetails.staffDetails[0].staffId;
      this.ratingLoader = false;
      // this.ticketDetailScreenLoader = false;
    }, (err)=> {
      this.ratingLoader = false;
      // this.ticketDetailScreenLoader = false;
    })
  }
  
  goToHelpdeskListingPage() {
    this.router.navigate(['profile/user/helpdesk-list']);
  }

  submitTicketReview() {

    this.submitLoader = true;
    this.helpdeskService.rateTicketService(this.helpDeskTicketId, this.serviceRating).subscribe((response)=> {
      this.bottomSheet.dismiss("SUCCESS");
      this.submitLoader = false;
    }, (err)=> {
      this.bottomSheet.dismiss();
      this.submitLoader = false;
    })
  }

  serviceRatingIcons: any = [
    { name: 'Very Bad', value: 1, src: 'poor-quality', status: false },
    { name: 'Bad', value: 2, src: 'not-good', status: false },
    { name: 'Average', value: 3, src: 'average', status: false },
    { name: 'Very Good', value: 4, src: 'very-good', status: false },
    { name: 'Loved It', value: 5, src: 'love-it', status: false },
  ];
  
  staffRatingIcons: any = [
    { name: 'Very Bad', value: 1, src: 'poor-quality', status: false },
    { name: 'Bad', value: 2, src: 'not-good', status: false },
    { name: 'Average', value: 3, src: 'average', status: false },
    { name: 'Very Good', value: 4, src: 'very-good', status: false },
    { name: 'Loved It', value: 5, src: 'love-it', status: false },
  ];

  selectServiceRating(ratingIcon) {
    
    this.serviceRatingIcons.forEach((icon)=> {
      if (icon.value === ratingIcon.value) {
        icon.status = true;
      } else {
        icon.status = false;
      }
    })
    this.serviceRating = ratingIcon.value;
  }

  selectStaffRating(ratingIcon) {
    this.staffRatingIcons.forEach((icon)=> {
      if (icon.value <= ratingIcon.value) {
        icon.status = true;
      } else {
        icon.status = false;
      }
    })
    
    // this.staffDetails[0].rating = ratingIcon.value;
  }

}
